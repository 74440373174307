import {
  Box,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';

import contrast from 'contrast';
import NextImage from 'shared/misc/components/image-wrapper';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { useAuth } from '../../../hooks/useAuth';
import { gql } from '../../../../__generated__/gql';
import { safeSendWebViewMessage } from '../../../../webview';
import { GlobalWebViewMessages } from '../../../../webview/messages';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useThemeColors } from '../../../hooks/useThemeColors';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

type Props = {
  isEndorsed: boolean;
  size?: number;
  endorsementCount: number;
  unendorse: () => any;
  endorse: () => any;
  targetRecId?: string;
  targetCommentId?: string;
  targetPromptId?: string;
  targetArticleId?: string;
  ownerId: string;
  withText: boolean;
  withCount?: boolean;
  classes?: string;
};

const GET_INTERACTIONS = gql(/* GraphQL */ `
  query getInteractions(
    $targetRecId: String
    $targetCommentId: String
    $targetPromptId: String
    $targetArticleId: String
  ) {
    interactionConnection(
      targetRecId: $targetRecId
      targetCommentId: $targetCommentId
      targetPromptId: $targetPromptId
      targetArticleId: $targetArticleId
      type: "ENDORSE"
    ) {
      edges {
        node {
          id
          type
          originUser {
            id
            username
            firstName
            lastName
            avatarPhotoSrc
          }
        }
      }
    }
  }
`);

export default function LikeStars({
  isEndorsed,
  endorsementCount,
  unendorse,
  endorse,
  size = 30,
  targetRecId,
  targetCommentId,
  targetPromptId,
  targetArticleId,
  ownerId,
  withText,
  withCount,
  classes = '',
}: Props) {
  const auth = useAuth();
  const popoverState = useDisclosure();

  const { trackEvent } = useAnalytics();
  const themeColors = useThemeColors();
  const backgroundColor = themeColors.background;

  const isDark = contrast(backgroundColor) === 'dark';

  const [loadInteractions, { data }] = useLazyQuery(GET_INTERACTIONS, {
    variables: { targetRecId, targetCommentId, targetPromptId, targetArticleId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
  });

  const breakpoint = useBreakpoint();
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const imageProps = useMemo(() => {
    let res = 'one-endorsed';
    const width = size;
    const height = size;
    switch (endorsementCount) {
      case 0:
        res = isEndorsed ? 'one-endorsed' : 'no-endorsements';
        break;
      case 1:
        res = `one-endorsed`;
        break;
      case 2:
        res = `two-endorsed`;
        break;
      case 3:
      default:
        res = `three-endorsed`;
        break;
    }
    return {
      className: classes,
      width,
      height,
      src: `https://files.pi.fyi/${res}${isEndorsed ? '-user-endorsed' : ''}.png`,
    };
  }, [size, isEndorsed, endorsementCount]);

  const tooltip = useMemo(() => {
    const count = isEndorsed ? endorsementCount - 1 : endorsementCount;

    if (!auth.user) {
      return 'Log in to like this.';
    }

    if (isEndorsed && count > 0) {
      return `Liked by you & ${count} other user${count > 1 ? 's' : ''}.`;
    } else if (isEndorsed && count === 0) {
      return 'Liked by you.';
    } else if (count > 0) {
      return `Liked by ${count} user${count > 1 ? 's' : ''}.`;
    } else {
      return 'Be the first to like this.';
    }
  }, [auth.user, isEndorsed, endorsementCount]);

  const router = useRouter();

  const onClick = async () => {
    if (
      (isMobile &&
        (popoverState.isOpen || (!isEndorsed && auth.user && auth.user.id !== ownerId))) ||
      !isMobile
    ) {
      if (auth.user) {
        try {
          safeSendWebViewMessage(GlobalWebViewMessages.HAPTIC_LIGHT);
        } catch (e) {}

        if (isEndorsed) {
          await unendorse();
          loadInteractions();
          trackEvent('Click', 'Unendorse', {
            targetRecId,
            targetCommentId,
            targetPromptId,
            targetArticleId,
          });
        } else {
          await endorse();
          loadInteractions();
          trackEvent('Click', 'Endorse', {
            targetRecId,
            targetCommentId,
            targetPromptId,
            targetArticleId,
          });
        }
      } else {
        const type = targetRecId
          ? 'Rec'
          : targetCommentId
          ? 'Comment'
          : targetPromptId
          ? 'Ask'
          : 'Unknown';
        trackEvent('Click', 'Redirect_To_Sign_In', {
          targetType: type,
          targetId: targetRecId || targetCommentId || targetPromptId || targetArticleId,
          clickTarget: 'Endorse',
        });
        router.navigate({
          to: '/sign-in',
        });
      }
    }
  };

  return (
    <Popover
      {...popoverState}
      isLazy
      trigger={isMobile ? 'click' : 'hover'}
      onOpen={() => {
        popoverState.onOpen();
        loadInteractions();
      }}
    >
      <PopoverTrigger>
        <HStack spacing="6px" flexDirection={{ base: 'row-reverse', md: 'row' }}>
          {withCount && endorsementCount > 2 && (
            <Text textStyle="brand.headingSm" color="brand.main" cursor="pointer">
              {endorsementCount}
            </Text>
          )}
          <IconButton
            aria-label="Endorse"
            variant="icon"
            icon={
              <img
                alt="endorsements"
                {...imageProps}
                style={
                  isDark && !isEndorsed && endorsementCount > 0
                    ? {
                        WebkitFilter: `
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                        `,
                        filter: `
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                        `,
                      }
                    : {}
                }
              />
            }
            onClick={onClick}
          />
          {withText && (
            <Text textStyle="brand.actionSm" color="brand.main" cursor="pointer">
              see likes
            </Text>
          )}
        </HStack>
      </PopoverTrigger>
      {popoverState.isOpen && (
        <PopoverContent>
          <PopoverCloseButton />
          <PopoverHeader>LIKES ({endorsementCount})</PopoverHeader>
          <PopoverBody>
            <Stack maxHeight="200px" overflowY="scroll">
              {data?.interactionConnection?.edges.length === 0 && (
                <Text fontWeight="normal">Be the first to like this.</Text>
              )}
              {data?.interactionConnection?.edges?.map(({ node }) => (
                <HStack as={Link} key={node.id} href={`/u/${node.originUser?.username}`}>
                  <NextImage
                    src={
                      node.originUser?.avatarPhotoSrc ||
                      `/default-profile-photo-${
                        themeColors.main === '#ffffff' || themeColors.main === 'white'
                          ? 'white'
                          : 'blue'
                      }-small.png`
                    }
                    width={20}
                    height={20}
                    alt="image"
                    quality={20}
                    style={{
                      minWidth: '20px',
                      minHeight: '20px',
                    }}
                  />
                  <Text textStyle="brand.actionSm">{node.originUser.username}</Text>
                </HStack>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
}
