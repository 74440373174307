import { TWLink } from 'web2/app/components/link';
import { Tooltip, TooltipTrigger, TooltipContent } from 'web2/app/components/ui/tooltip';
import { Button } from 'web2/app/components/button';
import _ from 'lodash';
import { useRouter } from '@tanstack/react-router';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { gql } from 'shared/__generated__/gql';
import useFollowUser from 'shared/misc/hooks/useFollowUser';
import IsLoggedInTooltip from 'shared/misc/components/gates/IsLoggedInTooltip';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { UserOnlineIndicator } from './user-online-indicator';
import { getEmojisAsArrayFromString } from 'shared/emoji';
import { UserCoreFragment, UserDirectoryItemFragment } from 'shared/__generated__/graphql';
import { UserAvatar } from './user-avatar';

gql(/* GraphQL */ `
  fragment UserDirectoryItem on User {
    ...UserCore
    amIFollowing
    areTheyFollowing
    avatarPhotoFile {
      ...PerfImageFromFile
    }
    recs(first: 5, includePromptRecs: true) {
      id
      emoji
    }
  }
`);

type Props = {
  user: UserDirectoryItemFragment | UserCoreFragment;
  recsWhereBadgeIs?: boolean;
  noLink?: boolean;
  isSmall?: boolean;
  showMutuals?: boolean;
  withBorder?: boolean;
  withActiveIndicator?: boolean;
};

export default function UserDirectoryItem({
  user,
  recsWhereBadgeIs = true,
  noLink = false,
  isSmall = false,
  showMutuals = true,
  withBorder = true,
  withActiveIndicator = false,
}: Props) {
  const auth = useAuth();

  const { followUser, unfollowUser } = useFollowUser({ user });

  // Type guard to check if user has recs property
  const hasRecs = (
    user: UserDirectoryItemFragment | UserCoreFragment,
  ): user is UserDirectoryItemFragment => {
    return 'recs' in user && user.recs !== undefined;
  };

  const recSummaryNode = hasRecs(user) ? (
    <Tooltip>
      <TooltipTrigger>
        <div className="typography-brand-body text-right w-fit whitespace-nowrap text-base">
          {getEmojisAsArrayFromString(
            user.recs.reduce((acc: string, r: any) => `${acc}${r.emoji?.trim()}`, ''),
          )
            .slice(0, 5)
            .join(' ')
            .trim()}
        </div>
      </TooltipTrigger>
      <TooltipContent className="text-brand-main typography-action-sm">
        Summary of recent recs.
      </TooltipContent>
    </Tooltip>
  ) : null;

  const showUsernameOnTopLine = isSmall || (!user.firstName && !user.lastName) || true;

  const router = useRouter();

  // console.log('user', auth.user);

  const userItemNode = (
    <div
      className={`flex items-center p-2 max-w-full ${
        withBorder ? 'border border-dashed border-brand-main' : ''
      }`}
    >
      <div className="h-[75px] mr-2">
        <UserAvatar
          // withActiveIndicator={withActiveIndicator}
          user={user}
          avatarSize={isSmall ? 40 : 75}
          withPopover={false}
        />
      </div>
      <div className="h-[75px] flex-1 flex justify-between items-end">
        <div className="self-center space-y-3 justify-start mr-2">
          <div
            className={`typography-heading-sm ${isSmall ? 'text-base' : ''} truncate max-w-full`}
          >
            {_.truncate(
              showUsernameOnTopLine ? user.username : [user.firstName, user.lastName].join(' '),
              { length: 15 },
            )}
          </div>
          {hasRecs(user) && (
            <div
              className={`typography-brand-body uppercase whitespace-normal truncate ${
                user.recs?.length ? 'opacity-100' : 'opacity-20'
              }`}
            >
              {user.recs?.length ? recSummaryNode : 'NO RECS YET'}
            </div>
          )}
        </div>
        <div className="self-center space-y-3 items-end justify-end">
          {withActiveIndicator && <UserOnlineIndicator showOffline={false} user={user} />}
          {/* {showMutuals && auth.user ? (
            <div className="typography-action-sm uppercase self-end">
              {user.relatedFollowersConnection?.edges.length === 0
                ? 'NO'
                : user.relatedFollowersConnection?.edges.length}{' '}
              MUTUAL
              {user.relatedFollowersConnection?.edges.length !== 1 ? 'S' : ''}
            </div>
          ) : (
            <div className="h-[15px] self-end" />
          )} */}
          <IsLoggedInTooltip>
            <div className="w-full">
              {' '}
              {auth.user &&
              auth.user?.id !== user.id &&
              'amIFollowing' in user &&
              'areTheyFollowing' in user ? (
                <>
                  {user.amIFollowing ? (
                    <Button
                      variant="secondary"
                      className="text-xs w-fit self-end w-full"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (auth.user) {
                          unfollowUser();
                        } else {
                          router.navigate({
                            to: '/sign-in',
                          });
                        }
                      }}
                    >
                      Following
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="text-xs w-fit self-end disabled:opacity-50 w-full"
                      disabled={!auth.user}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (auth.user) {
                          followUser();
                        } else {
                          router.navigate({
                            to: '/sign-in',
                          });
                        }
                      }}
                    >
                      {user.areTheyFollowing ? 'Follow back' : 'Follow'}
                    </Button>
                  )}
                </>
              ) : null}
              {!auth.user || auth.user?.id === user.id ? <div /> : null}
            </div>
          </IsLoggedInTooltip>
        </div>
      </div>
    </div>
  );

  return !noLink ? (
    <TWLink to={'/u/$username'} params={{ username: user.username }} key={user.id}>
      {userItemNode}
    </TWLink>
  ) : (
    userItemNode
  );
}
