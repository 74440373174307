import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/download/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI',
        description: 'DOWNLOAD THE IOS APP',
        openGraph: {
          images: ['https://files.pi.fyi/cdn-cgi/image/quality=50,format=auto/pifyi-banner.png'],
        },
      },
    ],
  }),
  loader: () => {
    throw redirect({
      href: 'https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926',
    });
  },
});

function RouteComponent() {
  return null;
}
