import { createFileRoute } from '@tanstack/react-router';
import AddRecommendationModal from 'web2/app/components/modals/add-recommendation-modal';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import {
  GlobalWebViewObjects,
  getGlobalWebViewObject,
  safeSendWebViewMessage,
} from 'shared/webview/index';
import {
  ConversationCoreFragment,
  ProfileThemeType,
  PromptItemFragment,
  RecommendationItemFragment,
  UserCoreFragment,
} from 'shared/__generated__/graphql';
import type { RecFormArgs } from 'shared/misc/components/by-type/recommendations/useRecForm';
import { AddRecommendationMessages } from '../../../../../../packages/shared/webview/AddRecommendationMessages';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';

export const Route = createFileRoute('/mobile/add-recommendation/')({
  component: RouteComponent,
  ssr: false,
});

function RouteComponent() {
  const modalState = useDisclosure();

  const onClose = () => {
    safeSendWebViewMessage(AddRecommendationMessages.CLOSE);
  };

  const WEB_VIEW_PROPS = getGlobalWebViewObject(GlobalWebViewObjects.AddRecommendationProps);

  const repostRec = WEB_VIEW_PROPS?.repostRec as RecommendationItemFragment | undefined;
  const targetPrompt = WEB_VIEW_PROPS?.targetPrompt as PromptItemFragment | undefined;

  // Conversation-specific
  const targetUser = WEB_VIEW_PROPS?.targetUser as UserCoreFragment | undefined;
  const targetConversation = WEB_VIEW_PROPS?.targetConversation as
    | ConversationCoreFragment
    | undefined;
  const complexConvoRecipients = WEB_VIEW_PROPS?.complexConvoRecipients as
    | {
        userIds: string[];
        conversationIds: string[];
      }
    | undefined;

  const defaultValues = WEB_VIEW_PROPS?.defaultValues as RecFormArgs['defaultValues'] | undefined;

  useAutoSetTheme(ProfileThemeType.Default);

  return (
    <AddRecommendationModal
      repostRec={repostRec}
      targetPrompt={targetPrompt}
      targetUser={targetUser}
      targetConversation={targetConversation}
      complexConvoRecipients={complexConvoRecipients}
      defaultValues={defaultValues}
      modalState={{ ...modalState, isOpen: true, onClose }}
    />
  );
}
