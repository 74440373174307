import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/app-store/')({
  component: RouteComponent,
  loader: () => {
    throw redirect({
      href: 'https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926',
    });
  },
});

function RouteComponent() {
  return null;
}
