import { createServerFn } from '@tanstack/react-start';
import { getHeader } from '@tanstack/react-start/server';
import MobileDetect from 'mobile-detect';
import { breakpointManager } from 'shared/misc/providers/breakpoint-provider';

const getSSRBreakpointFromUAStringServer = createServerFn({ method: 'GET' }).handler(async () => {
  const ua = getHeader('User-Agent') || '';
  const md = new MobileDetect(ua);

  if (md.mobile()) {
    return md.tablet() ? 'md' : ('sm' as const); // sm for mobile, md for tablets
  }
  return 'lg' as const; // lg for desktop
});

function getSSRBreakpointFromUAStringClient() {
  return breakpointManager?.getCurrent() || 'lg';
}

export function getSSRBreakpointFromUAString() {
  if (typeof window === 'undefined') {
    return getSSRBreakpointFromUAStringServer();
  }

  return getSSRBreakpointFromUAStringClient();
}
