import React, { useLayoutEffect, useRef, useState } from 'react';
import { Tooltip, TooltipTrigger, TooltipContent } from 'web2/app/components/ui/tooltip';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  noOfLines?: number;
}

function ExpandableTextContainer({ children, noOfLines, className = '', ...props }: Props) {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const element = textRef.current;

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
        : false,
    );
  }, []);

  const interactableProps = {
    onClick: (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsExpanded((prev) => !prev);
    },
  };

  if (!noOfLines) {
    return <>{children}</>;
  }

  const contentNode = (
    <div
      ref={textRef}
      {...props}
      {...(isEllipsisActive ? interactableProps : {})}
      className={`grow ${className} ${isEllipsisActive ? 'cursor-pointer' : ''}`}
      style={
        !isExpanded
          ? {
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: noOfLines,
              overflow: 'hidden',
            }
          : {}
      }
    >
      {children}
    </div>
  );

  return isEllipsisActive ? (
    <Tooltip>
      <TooltipTrigger asChild>{contentNode}</TooltipTrigger>
      <TooltipContent className="z-[500] typography-action-sm" withPortal>
        {isExpanded ? 'Click to collapse' : 'Click to expand'}
      </TooltipContent>
    </Tooltip>
  ) : (
    contentNode
  );
}

export default ExpandableTextContainer;
