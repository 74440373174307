import {
  EditorState,
  EditorThemeClasses,
  LexicalEditor,
  $nodesOfType,
  COMMAND_PRIORITY_EDITOR,
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_HIGH,
} from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { Box, chakra, BoxProps } from '@chakra-ui/react';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { readOnlyTheme as defaultReadOnlyTheme } from 'shared/misc/theme/selectors/lexicalStyles';
import ExpandableTextContainer from 'web2/app/components/by-type/text/expandable-text-container';
import basicEditorConfig from 'shared/misc/components/rich-text/basic/basicEditorConfig';
import FloatingLinkEditorPlugin from 'shared/misc/components/rich-text/basic/plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from 'shared/misc/components/rich-text/basic/plugins/FloatingTextFormatToolbarPlugin';
import NewMentionsPlugin from 'shared/misc/components/rich-text/basic/plugins/MentionsPlugin';
import { PastePlugin } from 'shared/misc/components/rich-text/basic/plugins/PastePlugin';

// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
// function onChange(editorState) {
//   editorState.read(() => {
//     // Read the contents of the EditorState here.
//     const root = $getRoot();
//     const selection = $getSelection();

//     console.log(root, selection);
//   });
// }

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function AutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

function LinkTargetBlankNodePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) return;

    editor.update(() => {
      const allLinks = [...$nodesOfType(LinkNode), ...$nodesOfType(AutoLinkNode)];
      allLinks.forEach((node) => {
        if (node.__target !== '_blank') node.setTarget('_blank');
        if (node.__rel !== 'noopener noreferrer') node.setRel('noopener noreferrer');
      });
    });

    const cb = (node) => {
      if (!node) return;
      const dom = editor.getElementByKey(node.__key);
      if (!dom) return;
      dom.setAttribute('target', '_blank');
    };
    const removeNodeListener1 = editor.registerNodeTransform(LinkNode, cb);
    const removeNodeListener2 = editor.registerNodeTransform(AutoLinkNode, cb);
    return () => {
      removeNodeListener1();
      removeNodeListener2();
    };
  }, [editor]);

  return null;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

interface BasicEditorProps extends BoxProps {
  shouldAutoFocus?: boolean;
  isError?: boolean;
  isReadOnly?: boolean;
  placeholder?: string | React.ReactNode;
  maxLength?: number;
  initialEditorState?: EditorState | {} | null;
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
  containerProps?: BoxProps;
  contentEditableProps?: BoxProps;
  placeholderProps?: BoxProps;
  editorTheme?: EditorThemeClasses;
  readOnlyEditorTheme?: EditorThemeClasses;
  isPlainText?: boolean;
  includeMentions?: boolean;
  includeHashTags?: boolean;
  withBorder?: boolean;
  shiftForNewLine?: boolean;
  enableCmdEnter?: boolean;
  onEnter?: () => any;
  uploadImageOnRichTextPaste?: boolean;
  uploadImageOnFilePaste?: boolean;
}

function BasicEditor(
  {
    onChange,
    placeholder,
    initialEditorState,
    shouldAutoFocus = true,
    isReadOnly = false,
    includeMentions = false,
    includeHashTags = true,
    withBorder = true,
    shiftForNewLine = false,
    enableCmdEnter = false,
    noOfLines,
    containerProps,
    contentEditableProps,
    editorTheme,
    readOnlyEditorTheme = defaultReadOnlyTheme,
    placeholderProps,
    isPlainText,
    onEnter,
    uploadImageOnRichTextPaste,
    uploadImageOnFilePaste,
    ...boxProps
  }: BasicEditorProps,
  ref,
) {
  const editorState = useMemo(() => {
    return initialEditorState ? safeStringify(initialEditorState) : null;
  }, []);

  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const initialConfig = useMemo(
    () => ({
      namespace: isReadOnly ? 'pi-read-only-editor' : 'pi-base-editor',
      editorState,
      editable: true,
      ...basicEditorConfig,
      theme:
        readOnlyEditorTheme && isReadOnly
          ? readOnlyEditorTheme
          : editorTheme || basicEditorConfig.theme,
      ...(isReadOnly ? { editable: false } : {}),
      onError,
    }),
    [isReadOnly, editorTheme],
  );

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const ContentEditableChakra = useMemo(() => {
    return chakra(ContentEditable, {
      baseStyle: {
        w: '100%',
        h: '100%',
        display: 'block',
        // position: 'relative',
        outline: 0,
        p: '8px',
        pl: '13px',
        color: 'brand.black',
      },
    });
  }, []);

  const ContentEditableReadOnlyChakra = useMemo(() => {
    return chakra(ContentEditable, {
      baseStyle: {
        w: '100%',
        h: '100%',
      },
    });
  }, []);

  const EditorPlugin = isPlainText ? PlainTextPlugin : RichTextPlugin;

  return (
    <Box
      position="relative"
      // border="1px solid"
      w="100%"
      h="100%"
      boxSizing="border-box"
      {...containerProps}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <EditorPlugin
          contentEditable={
            <Box w="100%" height="100%" ref={ref} fontWeight="normal">
              {isReadOnly ? (
                <ExpandableTextContainer noOfLines={noOfLines}>
                  <ContentEditableReadOnlyChakra className="content-editable" {...boxProps} />
                </ExpandableTextContainer>
              ) : (
                <Box
                  w="100%"
                  height="100%"
                  minHeight="100%"
                  display="flex"
                  position="relative"
                  overflow="auto"
                  resize="vertical"
                  {...(withBorder
                    ? {
                        _focus: { borderColor: 'brand.highlight', borderWidth: '1px' },
                        border: '1px solid',
                        borderColor: 'brand.main',
                        borderRadius: 'none',
                      }
                    : {})}
                  {...boxProps}
                  ref={onRef}
                >
                  <ContentEditableChakra className="content-editable" {...contentEditableProps} />
                </Box>
              )}
            </Box>
          }
          placeholder={
            !isReadOnly ? (
              <Box
                position="absolute"
                textOverflow="ellipsis"
                overflow="hidden"
                userSelect="none"
                // whiteSpace="nowrap"
                maxWidth="100%"
                pointerEvents="none"
                // layerStyle="secondaryText"
                color="brand.lightgrey"
                opacity={0.5}
                className={initialConfig.theme.placeholder}
                {...placeholderProps}
              >
                {typeof placeholder === 'undefined' ? 'Enter some text...' : placeholder}
              </Box>
            ) : null
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <>
          {!!onChange && !isReadOnly && (
            <>
              <CallOnChangeWithRealStatePlugin onChange={onChange} />
              <OnChangePlugin onChange={onChange} />
              <ClearEditorPlugin />
              <HistoryPlugin />
              {onEnter && (
                <OnEnterPlugin
                  onEnter={onEnter}
                  shiftForNewLine={shiftForNewLine}
                  enableCmdEnter={enableCmdEnter}
                />
              )}
              <PastePlugin
                autoGenerateRecs={false}
                uploadImageOnRichTextPaste={uploadImageOnRichTextPaste}
                uploadImageOnFilePaste={uploadImageOnFilePaste}
              />
            </>
          )}
          {!isPlainText && (
            <>
              <LinkTargetBlankNodePlugin />
              <AutoLinkPlugin matchers={MATCHERS} />
              <LinkPlugin />
              {!isReadOnly && (
                <>
                  {includeMentions && <NewMentionsPlugin isReadOnly={isReadOnly} />}
                  {includeHashTags && <HashtagPlugin />}
                  {floatingAnchorElem && (
                    <FloatingLinkEditorPlugin
                      // anchorElem={floatingAnchorElem}
                      isLinkEditMode={isLinkEditMode}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                  )}
                  {typeof window !== 'undefined' && <FloatingTextFormatToolbarPlugin />}
                </>
              )}
            </>
          )}
          {shouldAutoFocus && <AutoFocusPlugin />}
          {/* <LexicalClickableLinkPlugin /> */}
          {/* {!!maxLength && <MaxLengthPlugin maxLength={30} />} */}
        </>
      </LexicalComposer>
    </Box>
  );
}

function OnEnterPlugin({ onEnter, shiftForNewLine, enableCmdEnter }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (e) => {
        if (
          e &&
          e.key === 'Enter' &&
          ((shiftForNewLine && !e.shiftKey) || (enableCmdEnter && e.metaKey))
        ) {
          e.preventDefault();
          e.stopPropagation();
          onEnter();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [editor]);

  return null;
}

function CallOnChangeWithRealStatePlugin({
  onChange,
}: {
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (onChange) {
      onChange(editor.getEditorState(), editor);
    }
  }, []);

  return null;
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
    };
  },
];

export function safeStringify(maybeObject?: Object | null) {
  if (typeof maybeObject === 'string') {
    return maybeObject;
  }

  try {
    // @ts-ignore
    return JSON.stringify(maybeObject);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function safeParse(maybeJson?: string | null) {
  if (typeof maybeJson === 'object') {
    return maybeJson;
  }

  try {
    // @ts-ignore
    return JSON.parse(maybeJson);
  } catch (e) {
    return null;
  }
}

export default React.forwardRef(BasicEditor);
