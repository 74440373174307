import { createFileRoute } from '@tanstack/react-router';
import { GetArticlesViewDocument } from 'shared/__generated__/graphql';
import ArticlesView from '../features/-articles-view';
import { serverOnly } from '@tanstack/react-start';

export const Route = createFileRoute('/read/')({
  component: RouteComponent,
  staleTime: 1000 * 60,
  loader: async ({ context }) => {
    const result = await context.apolloClient.query({
      query: GetArticlesViewDocument,
      variables: {},
    });
    return result;
  },
});

function RouteComponent() {
  const result = Route.useLoaderData();

  return <ArticlesView prefetchedArticleViewQueryData={result?.data} />;
}
