'use client';
import React, { useContext } from 'react';
import { SignedInUserFragment } from '../../__generated__/graphql';

export enum AnnouncementId {
  REFERRALS_1 = 'REFERRALS_1',
}

export interface AnnouncementConfig {
  id: AnnouncementId;
  title: string;
  body: string;
  body2?: string;
  cta: string;
  ctaOnClick?: () => void | Promise<void>;
  ctaLink?: string;
  cta2?: string;
  cta2Link?: string;
  cta2OnClick?: () => void | Promise<void>;
  shareSheet?: {
    content: (props: Record<string, any>) => React.ReactNode;
    title?: string;
    snapPoints?: number[] | string[];
  };
}

type AuthContextProps<T extends boolean> = {
  user: T extends true ? SignedInUserFragment : SignedInUserFragment | null;
  token: string | null;
  isFullyLoaded: boolean;
  isInMaintenance: boolean;
  signIn: (token: string) => Promise<SignedInUserFragment>;
  signOut: Function;
  refetchUser: () => Promise<any>;
  announcements?: AnnouncementConfig[];
  setAnnouncements?: (announcements: AnnouncementConfig[]) => any;
};

export const AuthContext = React.createContext<AuthContextProps<boolean>>(
  {} as AuthContextProps<boolean>,
);

// If you know the user will be authenticated pass in true here.
export function useAuth<T extends boolean>(): AuthContextProps<T> {
  type ContextType = React.Context<AuthContextProps<T>>;
  return useContext(AuthContext as ContextType);
}
