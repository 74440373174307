'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
  $setSelection,
  COMMAND_PRIORITY_LOW,
  CLICK_COMMAND,
  KEY_ESCAPE_COMMAND,
  $getNodeByKey,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useLexicalEditable from '@lexical/react/useLexicalEditable';

type Props = {
  nodeKey: string;
  children: React.ReactNode;
};

export default function LexicalSelectionHandler({ nodeKey, children }: Props) {
  const [isSelected, setSelected] = useState(false);
  const [editor] = useLexicalComposerContext();

  const [_, _setSelection] = useLexicalNodeSelection(nodeKey);

  const isEditable = useLexicalEditable();

  const ref = useRef<HTMLDivElement>(null);

  const onClick = useCallback(
    (payload: MouseEvent) => {
      const event = payload;
      const isInside = ref.current && ref.current.contains(event.target as Node);
      if (isInside) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          setSelected(true);
          _setSelection(true);
        }
        return true;
      } else if (isSelected) {
        setSelected(false);
      }

      return false;
    },
    [isSelected, setSelected],
  );

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (isSelected) {
        $setSelection(null);
        editor.update(() => {
          setSelected(true);
          const parentRootElement = editor.getRootElement();
          if (parentRootElement !== null) {
            parentRootElement.focus();
          }
        });
        return true;
      }
      return false;
    },
    [isSelected, editor, setSelected],
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const node = $getNodeByKey(nodeKey);
          const newIsSelected = node && node.isSelected()!;
          if (newIsSelected !== isSelected) {
            setSelected(newIsSelected!);
          }
        });
      }),
      editor.registerCommand<MouseEvent>(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ESCAPE_COMMAND, onEscape, COMMAND_PRIORITY_LOW),
    );
    return () => {
      unregister();
    };
  }, [editor, isSelected, nodeKey, onClick, setSelected]);

  if (!isEditable) {
    return children;
  }

  return (
    <Flex
      width="100%"
      maxWidth="100%"
      justifyContent="center"
      border={isSelected ? '1px solid blue' : undefined}
      p={isSelected ? 2 : undefined}
      ref={ref}
    >
      {children}
    </Flex>
  );
}
