import type { User } from 'database';
import { IronSessionOptions } from 'iron-session';

// if (process.env.JWT_SECRET == null) {
//   throw new Error('JWT SECRET REQUIRED');
// }

declare module 'iron-session' {
  interface IronSessionData {
    user?: User;
  }
}

export const COOKIE_NAME = 'PerfectlyImperfectAuthCookie';

export interface CookieOptions {
  /** Convenient option for setting the expiry time relative to the current time in **milliseconds**. */
  maxAge?: number | undefined;
  /** Indicates if the cookie should be signed. */
  signed?: boolean | undefined;
  /** Expiry date of the cookie in GMT. If not specified or set to 0, creates a session cookie. */
  expires?: Date | undefined;
  /** Flags the cookie to be accessible only by the web server. */
  httpOnly?: boolean | undefined;
  /** Path for the cookie. Defaults to “/”. */
  path?: string | undefined;
  /** Domain name for the cookie. Defaults to the domain name of the app. */
  domain?: string | undefined;
  /** Marks the cookie to be used with HTTPS only. */
  secure?: boolean | undefined;
  /** A synchronous function used for cookie value encoding. Defaults to encodeURIComponent. */
  encode?: ((val: string) => string) | undefined;
  /**
   * Value of the “SameSite” Set-Cookie attribute.
   * @link https://tools.ietf.org/html/draft-ietf-httpbis-cookie-same-site-00#section-4.1.1.
   */
  sameSite?: boolean | 'lax' | 'strict' | 'none' | undefined;
  /**
   * Value of the “Priority” Set-Cookie attribute.
   * @link https://datatracker.ietf.org/doc/html/draft-west-cookie-priority-00#section-4.3
   */
  priority?: 'low' | 'medium' | 'high';
  /** Marks the cookie to use partioned storage. */
  partitioned?: boolean | undefined;
}

export const cookieOptions: CookieOptions = {
  domain: process.env.NODE_ENV === 'production' ? 'pi.fyi' : undefined,
  sameSite: 'lax',
  maxAge: 2147483647 - 60,
};

export const expireCookieOptions: CookieOptions = {
  domain: process.env.NODE_ENV === 'production' ? 'pi.fyi' : undefined,
  sameSite: 'lax',
  maxAge: 0,
  expires: new Date(0),
};

export const ironOptions: IronSessionOptions = {
  cookieName: COOKIE_NAME,
  password: process.env.JWT_SECRET!,
  ttl: 0,
  cookieOptions,
};
