import { ApolloClient, InMemoryCache } from '@apollo/client-integration-tanstack-start';

import { GRAPHQL_SERVER_URL } from 'shared/siteconfig';
import { HttpLink, NormalizedCacheObject, ApolloLink, split } from '@apollo/client/index.js';

import { ironOptions } from 'shared/misc/iron';
import { setContext } from '@apollo/link-context';
import { typePolicies } from 'shared/misc/apollo/typePolicies';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getAuthCookie } from './cookies';
import { RetryLink } from '@apollo/client/link/retry';
import { sneakySneaky } from 'shared/misc/apollo/base';

// Create a singleton client instead of recreating it each time
let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getClient = (): ApolloClient<NormalizedCacheObject> => {
  if (apolloClient && typeof window !== 'undefined') return apolloClient;

  function getQueryParam(param) {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    }
    return null;
  }

  const sharedHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Access-Control-Allow-Credentials': 'true',
  };

  const getClientHeaders = (): Record<string, string> => {
    const key = getQueryParam('key');
    if (key) return { ...sharedHeaders, Authorization: `Key ${key}` };
    const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
    return token ? { ...sharedHeaders, Authorization: `JWT ${token}` } : {};
  };

  const getServerHeaders = async () => {
    const sessionCookie = await getAuthCookie();
    return sessionCookie ? { [ironOptions.cookieName]: sessionCookie } : {};
  };

  const uri = GRAPHQL_SERVER_URL;

  const httpLink = new HttpLink({
    uri,
    credentials: 'include',
    fetchOptions: {
      credentials: 'include',
      mode: 'cors',
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 15,
      retryIf: (error, operation) => {
        if (error?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED') return false;
        return !!error;
      },
    },
  });

  const authCtx = setContext(async (_, { headers }) => {
    const updatedHeaders = {
      ...sharedHeaders,
      ...headers,
      ...(typeof window === 'undefined' ? await getServerHeaders() : {}),
      ...(typeof window !== 'undefined' ? getClientHeaders() : {}),
    };
    return {
      headers: updatedHeaders,
    };
  });

  const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;

  const wsLink =
    typeof window !== 'undefined'
      ? new GraphQLWsLink(
          createClient({
            url:
              process.env.NODE_ENV === 'development'
                ? 'ws://localhost:9000/api/graphql'
                : 'wss://ws.pi.fyi/api/graphql',
            connectionParams: {
              authentication: token,
            },
          }),
        )
      : null;

  const link =
    typeof window !== 'undefined' && wsLink != null
      ? split(
          ({ query }) => {
            const def = getMainDefinition(query);
            return def.kind === 'OperationDefinition' && def.operation === 'subscription';
          },
          wsLink,
          httpLink,
        )
      : httpLink;

  const allLinks = ApolloLink.from([authCtx, retryLink, link]);

  const cache = new InMemoryCache({
    typePolicies: typePolicies,
  });

  // if (typeof window !== 'undefined') {
  //   // persistCache({
  //   //   cache,
  //   //   storage: new LocalForageWrapper(localForage),
  //   // });
  // }

  apolloClient = new ApolloClient({
    cache,
    link: allLinks,
    credentials: 'include',
  });

  try {
    sneakySneaky(cache, apolloClient);
  } catch (e) {
    console.error(e);
  }

  return apolloClient;
};
