import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/terms/')({
  component: RouteComponent,
  loader: () => {
    throw redirect({ href: 'https://files.pi.fyi/terms.pdf' });
  },
});

function RouteComponent() {
  return null;
}
