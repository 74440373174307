import { ComponentStyleConfig, defineStyleConfig } from '@chakra-ui/react';

export function getCommonProps(uniqueStyles) {
  return {
    ...uniqueStyles,
    borderRadius: 0,
    textStyle: 'brand.actionSm',
    fontSize: '13.5px',
    _disabled: {
      pointerEvents: 'disabled',
      cursor: 'not-allowed',
      ...uniqueStyles,
    },
    _hover: {
      textDecoration: 'none',
      opacity: 1,
      border: uniqueStyles.border || '1px solid transparent',
      color: uniqueStyles.color || 'brand.secondary',
      borderWidth: uniqueStyles.borderWidth || undefined,
      borderColor: uniqueStyles.borderColor || undefined,
      _disabled: {
        pointerEvents: 'disabled',
        cursor: 'not-allowed',
        ...uniqueStyles,
      },
    },
  };
}

export const Button: ComponentStyleConfig = defineStyleConfig({
  // style object for base or default style
  baseStyle: {
    borderRadius: 0,
    width: 'fit-content',
    padding: '8px',
    textStyle: 'brand.actionSm',
    fontSize: '13.5px',
    lineHeight: '100%',
    fontStretch: 'normal',
    lettingSpacing: '1%',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    sm: {},
    md: {
      // padding: '24px',
      // width: '100%',
    },
    lg: {
      padding: '64px',
      width: '100%',
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    link: {
      padding: '0px !important',
      color: 'brand.main',
      borderBottom: '1px dashed',
      borderColor: 'brand.main',
      width: 'fit-content',
      _hover: {
        opacity: 1,
        color: 'brand.highlight',
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.highlight',
      },
    },
    linkNoDefaultBorder: {
      padding: '0px !important',
      color: 'inherit',
      borderBottom: '1px dashed',
      borderBottomColor: 'transparent',
      width: 'fit-content',
      _hover: {
        opacity: 1,
        color: 'brand.highlight',
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.highlight',
      },
    },
    linkNoBorder: {
      padding: '0px !important',
      color: 'inherit',
      borderBottomColor: 'transparent',
      width: 'fit-content',
      _hover: {
        padding: '0px !important',
        opacity: 1,
        color: 'brand.highlight',
        textDecoration: 'none',
        borderBottomColor: 'transparent',
      },
    },
    secondaryLink: {
      padding: '0px !important',
      color: 'brand.lightgrey',
      borderBottomWidth: '1px',
      borderBottomStyle: 'dashed',
      borderBottomColor: 'transparent',
      height: 'fit-content',
      _hover: {
        color: 'brand.highlight',
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.highlight',
      },
    },
    linkNoHover: {
      padding: '0px !important',
      color: 'inherit',
      borderBottomWidth: '1px',
      borderBottomStyle: 'dashed',
      borderBottomColor: 'black',
      height: 'fit-content',
      _disabled: {
        opacity: 1,
        color: 'inherit',
        borderBottomColor: 'black'
      }
    },
    textOnly: {
      padding: '0px !important',
      color: 'inherit',
      height: 'fit-content',
    },
    textOnlyHighlight: {
      padding: '0px !important',
      color: 'brand.highlight',
      fontFamily: 'brand.times',
      height: 'fit-content',
      textTransform: 'none',
      fontSize: '24px',
      fontWeight: 'normal',
    },
    primary: {
      ...getCommonProps({
        color: 'brand.background',
        backgroundColor: 'brand.highlight',
        border: '1px solid transparent',
      }),
    },
    primaryBlack: {
      ...getCommonProps({
        color: 'brand.white',
        backgroundColor: 'brand.black',
        border: '1px solid transparent',
      }),
    },
    primaryBlackText: {
      ...getCommonProps({
        color: 'brand.black',
        backgroundColor: 'brand.highlight',
        border: '1px solid transparent',
        fontFamily: 'brand.abcFavorite',
      }),
    },
    alt: {
      ...getCommonProps({
        color: 'brand.menuText',
        backgroundColor: 'brand.menuBackground',
        border: '1px solid transparent',
      }),
    },
    tertiary: {
      ...getCommonProps({
        color: 'brand.white',
        backgroundColor: 'brand.blue',
        border: '1px solid transparent',
      }),
    },
    secondary: {
      ...getCommonProps({
        color: 'brand.highlight',
        borderWidth: '1px',
        backgroundColor: 'brand.transparent',
        borderColor: 'brand.highlight',
      }),
    },
    secondaryBlack: {
      ...getCommonProps({
        color: 'brand.black',
        backgroundColor: 'brand.background',
        borderWidth: '1px',
        borderColor: 'brand.black',
      }),
    },
    secondaryDanger: {
      ...getCommonProps({
        color: 'brand.red',
        borderWidth: '1px',
        backgroundColor: 'brand.secondary',
        borderColor: 'brand.red',
      }),
    },
    icon: {
      borderRadius: 0,
      borderWidth: 0,
      textStyle: 'brand.actionSm',
      fontSize: '20.5px',
      padding: 0,
      margin: 0,
      width: 'fit-content',
      minWidth: 'fit-content',
    },
    outline: {
      ...getCommonProps({
        color: 'brand.main',
        backgroundColor: 'brand.background',
        border: '1px dashed',
        borderColor: 'brand.highlight',
      }),
      padding: '6px',
      _hover: {
        color: 'brand.highlight',
        backgroundColor: 'brand.background',
        textDecoration: 'none',
        border: '1px dashed',
        borderColor: 'brand.highlight',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'primary',
  },
});
