import React, { useEffect, useState } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { CATEGORIES } from 'shared/data/signup';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'web2/app/components/button';

const INTRO_TEXT = 'Which categories are you most interested in?';

export const Route = createFileRoute('/sign-up/categories/')({
  component: SignUpCategories,
});

function SignUpCategories() {
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.SHARE] });
  const { setNavigation } = useSignUpNavigation();
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();
  const [displayText] = useTypewriter(INTRO_TEXT, 50);
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(new Set());

  useEffect(() => {
    setNavigation({
      showLeftArrow: false,
      showRightArrow: true,
      leftArrowDisabled: false,
      rightArrowDisabled: selectedCategories.size === 0,
      yPosition: 150,
      onLeftPress: () => updateStep(USER_SIGN_UP_STEP.FEATURES),
      onRightPress: handleDone,
      showSkip: false,
      signupStep: USER_SIGN_UP_STEP.CATEGORIES,
    });

    return () => {
      setNavigation({
        showLeftArrow: false,
        showRightArrow: false,
        yPosition: 150,
        leftArrowDisabled: false,
        rightArrowDisabled: false,
      });
    };
  }, [setNavigation, selectedCategories]);

  const handleDone = async () => {
    await trackEvent('Click', 'Categories_Selected', {
      userId: user?.id,
      categories: Array.from(selectedCategories),
    });
    await updateStep(USER_SIGN_UP_STEP.SHARE);
  };

  const toggleCategory = (categoryId: string) => {
    setSelectedCategories((prev) => {
      const newSet = new Set(prev);
      newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
      return newSet;
    });
  };

  return (
    <div className="min-h-full">
      <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
        {displayText}
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        Select as many as you want.
      </div>
      <div className="flex justify-start items-start flex-row flex-wrap mt-[80px]">
        {CATEGORIES.map((category) => (
          <div key={category.id}>
            <Button
              key={category.id}
              variant="outline"
              className={`px-[12px] py-[4px] mr-[8px] mb-[8px] ${
                selectedCategories.has(category.id)
                  ? 'bg-white border-black'
                  : 'bg-brand-transparent border-brand-main'
              } hover:${
                selectedCategories.has(category.id) ? 'bg-white' : 'bg-brand-transparent'
              } flex`}
              onClick={() => toggleCategory(category.id)}
            >
              <div
                className={`typography-heading-sm text-none normal-case ${
                  selectedCategories.has(category.id) ? 'text-black' : 'text-brand-main'
                } hover:${selectedCategories.has(category.id) ? '' : 'text-brand-highlight'}`}
              >
                {category.emoji} {category.label}
              </div>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
