import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__/gql';
import { InView } from 'react-intersection-observer';
import { useBackButton } from 'shared/misc/hooks/useBackButton';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import UserDirectoryItem from 'web2/app/components/by-type/user/user-directory-item';
import { Button as BasicButton } from 'web2/app/components/button';
import Spinner from 'web2/app/components/spinner';

gql(/* GraphQL */ `
  fragment ProfileFollowingView on User {
    id
    email
    firstName
    lastName
    username
    profileTheme
    followingCount
    followingConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          targetUser {
            ...UserDirectoryItem
          }
        }
      }
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getProfileFollowingView($username: String!, $first: Int, $after: String) {
    user(username: $username) {
      ...ProfileFollowingView
    }
  }
`);

const PAGE_LENGTH = 20;

export const Route = createFileRoute('/u/$username/following/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { username } = Route.useParams();
  const router = useRouter();
  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    loading,
  } = useQuery(PageQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      username,
      first: PAGE_LENGTH,
    },
  });

  const getNextPage = async () => {
    if (loading || !data?.user?.followingConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        first: PAGE_LENGTH,
        after: data?.user?.followingConnection.pageInfo.endCursor,
      },
    });
  };

  const auth = useAuth();
  console.log('data', auth);
  const profile = data?.user;

  const { getCanGoBack, goBack } = useBackButton();

  useAutoSetTheme(profile?.profileTheme || null);

  if (loading && !profile) {
    return null;
  }

  if (!profile) {
    router.navigate({ to: '/not-found' });
    return null;
  }

  const following = profile?.followingConnection?.edges || [];

  return (
    <div className="flex justify-center p-6 mb-[100px]">
      <div className="flex flex-col gap-6 w-full md:w-[600px] lg:w-[700px]">
        {getCanGoBack() && (
          <BasicButton variant="link" onClick={goBack} className="fit-content">
            Back
          </BasicButton>
        )}
        <div className="flex flex-col gap-6 justify-center">
          <h1 className="uppercase typography-heading-lg lg:typography-heading-xl text-brand-highlight text-center mt-[48px] flex flex-col gap-2">
            <span>FOLLOWING</span>
            <span className="lg:text-[54px]">({profile.followingCount})</span>
          </h1>
          {following.length === 0 && (
            <div className="flex flex-col gap-3 w-full justify-center items-center">
              {profile?.id === auth.user?.id ? (
                <>
                  <div>
                    <p className="typography-heading-sm text-center">
                      You're not following anyone.
                    </p>
                  </div>
                </>
              ) : (
                <div>
                  <p className="typography-heading-sm text-center">Nobody...</p>
                </div>
              )}
            </div>
          )}
          {following?.map(({ node }) => (
            <UserDirectoryItem key={node.id} noLink user={node.targetUser} />
          ))}
          <InView
            as="div"
            rootMargin="400px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  );
}
