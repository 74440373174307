import { SignedInUserFragment } from './__generated__/graphql';

export type SanitizedUser = Partial<
  Pick<
    SignedInUserFragment,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'username'
    | 'role'
    | 'isPremium'
    | 'premiumTier'
    | 'profileTheme'
    | 'preferences'
    | 'flag'
    | 'values'
    | 'createdAt'
    | 'updatedAt'
    | 'avatarPhotoFile'
    | 'profilePhotoFile'
    | 'followingCount'
    | 'pinnedRecCount'
  >
> & {
  avatarPhotoUrl?: string | null;
  profilePhotoUrl?: string | null;
  isUserDeleted: boolean;
};

export const sanitizeUser = (
  user: SignedInUserFragment,
  isUserDeleted: boolean,
): SanitizedUser => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  username: user.username,
  role: user.role,
  isPremium: user.isPremium,
  premiumTier: user.premiumTier,
  profileTheme: user.profileTheme,
  preferences: user.preferences,
  flag: user.flag,
  values: user.values,
  followingCount: user.followingCount,
  pinnedRecCount: user.pinnedRecCount,
  createdAt: user.createdAt,
  updatedAt: user.updatedAt,
  avatarPhotoUrl: user.avatarPhotoFile?.url || null,
  profilePhotoUrl: user.profilePhotoFile?.url || null,
  isUserDeleted,
});
