import { ProfileThemeType } from 'shared/__generated__/graphql';
import { gql } from 'graphql-tag';

import { ApolloClientRouterContext } from '@apollo/client-integration-tanstack-start';

let themeCache = new Map<string, ProfileThemeType>();

export async function getInitialTheme(
  client: ApolloClientRouterContext['apolloClient'],
  user: any | null,
  pathname: string | null,
): Promise<ProfileThemeType> {
  if (themeCache.has(pathname!)) {
    return themeCache.get(pathname!)!;
  }

  if (pathname?.startsWith('/u/')) {
    const username = pathname?.split('/')[2];
    const { data } = await client.query({
      query: gql`
        query getProfileTheme($username: String!) {
          user(username: $username) {
            profileTheme
          }
        }
      `,
      variables: { username },
    });
    themeCache.set(pathname!, data.user?.profileTheme!);
    return data.user?.profileTheme!;
  }

  if (pathname?.startsWith('/rec/')) {
    const id = pathname?.split('/')[2];
    const { data } = await client.query({
      query: gql`
        query getRecViewTheme($id: String!) {
          rec(id: $id) {
            user {
              profileTheme
            }
          }
        }
      `,
      variables: { id },
    });
    themeCache.set(pathname!, data.rec?.user?.profileTheme!);
    return data.rec?.user?.profileTheme!;
  }

  if (pathname?.startsWith('/ask/')) {
    const id = pathname?.split('/')[2];
    const { data } = await client.query({
      query: gql`
        query getAskViewTheme($id: String!) {
          prompt(id: $id) {
            user {
              profileTheme
            }
          }
        }
      `,
      variables: { id },
    });
    themeCache.set(pathname!, data.prompt?.user?.profileTheme!);
    return data.prompt?.user?.profileTheme!;
  }

  const isPiClassic =
    pathname &&
    [
      '/welcome',
      '/what-is-this',
      '/sign-up',
      '/sign-in',
      '/p',
      '/read',
      '/subscribe/referral',
    ].some((pattern) => pathname?.startsWith(pattern));

  if (isPiClassic) {
    return ProfileThemeType.PiClassic;
  }

  if (pathname?.startsWith('/sxsw-2025')) {
    return ProfileThemeType.Dark;
  }

  if (!user) return ProfileThemeType.Default;

  return user.profileTheme!;
}
