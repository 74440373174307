import _ from 'lodash';
import ordinal from 'ordinal';
import { USER_SIGN_UP_STEP } from '../../../../../data/user';
import {
  FeedItemType,
  RecType,
  SidebarFeedItemFragment,
  FullFeedItemFragment,
  PerfImageFromFileFragment,
} from '../../../../../__generated__/graphql';
import { MergableFeedItemResult, MergedFeedItemType } from './feedUtils';

const SECRET_FEED_TYPES = new Set<FeedItemType | string>([FeedItemType.NewBookmark]);

export interface GetFeedItemData {
  referenceUserId?: string | null;
  includeOriginUsername?: boolean;
  feedItem: MergableFeedItemResult | SidebarFeedItemFragment | FullFeedItemFragment;
  hideTypes?: Set<string | FeedItemType>;
  originUserFallbackLogoColor?: string;
  isGeneric?: boolean | ((type: string) => boolean);
}

export interface FeedItemDisplayData {
  imageSrc?: string | null;
  imageFile?: PerfImageFromFileFragment | null;
  mainLink?: string | null;
  canOpenLinkOnMobile?: boolean | null;
  mainContent?: string | null;
  imageLink?: string | null;
  imageTooltip?: string | null;
  mainTooltip?: string | null;
}

export function getFeedItemData({
  feedItem,
  referenceUserId,
  originUserFallbackLogoColor = 'blue',
  includeOriginUsername = false,
  hideTypes = new Set([]),
  isGeneric: isGenericFnOrNot,
}: GetFeedItemData): FeedItemDisplayData | null {
  const {
    originUser,
    targetRec,
    targetUser,
    originGuest,
    targetFeature,
    targetArticle,
    targetList,
    targetComment,
    targetCommentId,
    targetPrompt,
  } = feedItem;

  const isGeneric = _.isFunction(isGenericFnOrNot)
    ? isGenericFnOrNot(feedItem.type as FeedItemType)
    : isGenericFnOrNot;

  let canOpenLinkOnMobile: boolean | null = null;

  const logoColor = originUserFallbackLogoColor;

  let imageSrc: string | null = null;

  let imageFile: PerfImageFromFileFragment | null = originUser?.avatarPhotoFile || null;

  let mainLink: string | null = null;

  const shouldHide = hideTypes.has(feedItem.type);

  let imageLink: string | null | undefined = SECRET_FEED_TYPES.has(feedItem.type)
    ? null
    : originUser && `/u/${originUser.username}`;

  let mainContent: string | null = null;

  const mainTooltip: string | null | undefined = SECRET_FEED_TYPES.has(feedItem.type)
    ? 'SOMEONE'
    : originUser
    ? `@${originUser.username}`
    : originGuest
    ? originGuest.name
    : null;

  const imageTooltip: string | null | undefined = mainTooltip;

  // Start text.
  const sText =
    includeOriginUsername && !SECRET_FEED_TYPES.has(feedItem.type)
      ? `@${originUser?.username} `
      : '';

  if (shouldHide) {
    return null;
  }

  switch (feedItem.type) {
    case FeedItemType.NewRePost:
    case FeedItemType.NewRePostWithContent:
    case FeedItemType.AddRecommendation: {
      if (!targetRec || targetRec?.isHidden) return null;

      imageSrc =
        originUser?.avatarPhotoSrc ||
        targetFeature?.thumbnailFile?.file?.url ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;

      // switch (feedItem.type) {
      //   case FeedItemType.NewRePost:
      //   case FeedItemType.NewRePostWithContent:
      //     mainLink = `/rec/${targetRec?.repostTargetRec?.id}`;
      //     break;
      //   case FeedItemType.AddRecommendation:
      //     mainLink = `/rec/${targetRec.id}`;
      //     break;
      // }

      mainLink = `/rec/${targetRec.id}`;

      canOpenLinkOnMobile = true;

      if (targetRec.type === RecType.RePost || targetRec.type === RecType.RePostWithContent) {
        mainContent = `${sText} ${targetRec.type === RecType.RePost ? `RE-REC'D` : `QUOTE REC'D`} ${
          targetRec.repostTargetRec
            ? `${targetRec.repostTargetRec.emoji} ${targetRec.repostTargetRec.title}`
            : `${targetRec.emoji} ${targetRec.title}`
        }`;
      } else {
        mainContent = `${sText}REC'D ${targetRec.emoji} ${targetRec.title}`;
      }

      break;
    }
    case FeedItemType.NewShareRec:
    case FeedItemType.NewShareOriginalRec:
    case FeedItemType.NewRecommendationShare: {
      if (!targetRec || targetRec?.isHidden) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}${
        feedItem.type === FeedItemType.NewShareOriginalRec ? 'WROTE' : 'SENT'
      } YOU ${targetRec.emoji} ${targetRec.title}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewArticle: {
      const article = targetArticle;
      imageSrc = article.thumbnailFile?.file?.url || null;
      // `/default-profile-photo-${logoColor}-small.png`
      mainLink = `/p/${article.urlSlug}`;
      mainContent = article.title;
      break;
    }
    case FeedItemType.NewFeature: {
      const feature = targetArticle?.feature || targetFeature;
      imageSrc = feature.thumbnailFile?.file?.url || null;
      // `/default-profile-photo-${logoColor}-small.png`
      mainLink = feature.url;
      mainContent = feature.title;
      break;
    }

    case FeedItemType.NewBookmark: {
      if (!targetRec) return null;
      imageSrc = `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      imageFile = null;
      mainContent = `${sText}SOMEONE BOOKMARKED ${targetRec.emoji} ${targetRec.title}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewShareEndorse:
    case FeedItemType.NewRePostEndorse:
    case FeedItemType.NewRecEndorse: {
      if (!originUser || !targetRec || targetRec?.isHidden) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;

      if (feedItem.type === FeedItemType.NewShareEndorse) {
        mainContent = `${sText}LIKED ✉️${targetRec.emoji} ${targetRec.title}`;
      } else {
        mainContent = isGeneric
          ? `${sText}LIKED A REC`
          : `${sText}LIKED${
              feedItem.type === FeedItemType.NewRePostEndorse
                ? targetUser?.id === referenceUserId
                  ? ' YOUR RE-REC'
                  : ''
                : ''
            } ${targetRec.emoji} ${targetRec.title}`;
      }

      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewPromptEndorse: {
      if (!originUser || !targetPrompt || !targetUser || targetPrompt?.isHidden) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = isGeneric
        ? `${sText}LIKED A PROMPT`
        : `${sText}LIKED ${targetPrompt.emoji} ${targetPrompt.title}`;
      mainLink = `/ask/${targetPrompt.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewArticleEndorse: {
      if (!originUser || !targetArticle || targetArticle?.isHidden) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = isGeneric
        ? `${sText}LIKED AN ARTICLE`
        : `${sText}LIKED AN ARTICLE: ${targetArticle?.title}`;
      mainLink = `/p/${targetArticle.urlSlug}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewUserFollow: {
      if (!originUser || !targetUser) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}FOLLOWED ${
        referenceUserId && targetUser?.id === referenceUserId ? 'YOU' : `@${targetUser?.username}`
      }`;
      mainLink = `/u/${
        referenceUserId && targetUser?.id === referenceUserId
          ? originUser.username
          : targetUser?.username
      }`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.CreateList: {
      if (targetList == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}SHARED A LIST`;
      mainLink = `/list/${targetList.id}`;
      break;
    }

    case FeedItemType.NewUser: {
      if (
        originUser == null ||
        (originUser?.signUpStep !== null && originUser?.signUpStep !== USER_SIGN_UP_STEP.DONE)
      )
        return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `@${originUser.username} JOINED US`;
      mainLink = `/u/${originUser.username}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewShareComment:
    case FeedItemType.NewComment: {
      if (
        originUser == null ||
        targetRec == null ||
        (targetComment == null && targetCommentId == null)
      ) {
        return null;
      }
      const isPrivate = targetRec?.type === RecType.Private;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;

      if (feedItem.type === FeedItemType.NewShareComment && targetComment != null) {
        mainContent = `${sText}REPLIED "${_.truncate(targetComment.content, { length: 20 })}"`;
      } else {
        mainContent = `${sText}${isPrivate ? 'REPLIED TO' : 'COMMENTED ON'} ${targetRec.emoji} ${
          targetRec.title
        }`;
      }

      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewCommentFromFriend: {
      if (originUser == null || targetRec == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}ALSO COMMENTED ON ${targetRec.emoji} ${targetRec.title}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewShareCommentEndorse:
    case FeedItemType.NewCommentEndorse: {
      if (originUser == null || targetRec == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}LIKED ${
        referenceUserId && targetUser?.id === referenceUserId ? 'YOUR' : `A`
      } ${feedItem.type === FeedItemType.NewShareCommentEndorse ? 'REPLY' : 'COMMENT'} ON ${
        targetRec.emoji
      } ${targetRec.title}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewMention: {
      if (originUser == null || targetRec == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `${sText}MENTIONED ${
        referenceUserId && targetUser?.id === referenceUserId ? 'YOU' : `SOMEONE`
      } IN A ${targetCommentId ? 'COMMENT' : 'REC'}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.NewPrompt: {
      if (originUser == null || targetPrompt == null || targetPrompt?.isHidden) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = isGeneric
        ? `${sText}CREATED AN ASK`
        : `${sText}ASKED FOR ${targetPrompt.emoji} ${targetPrompt.title}`;
      mainLink = `/ask/${targetPrompt.id}`;
      canOpenLinkOnMobile = true;

      break;
    }

    case FeedItemType.NewPromptSubscription: {
      if (originUser == null || targetPrompt == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = isGeneric
        ? `${sText}SUBSCRIBED TO`
        : `${sText}SUBSCRIBED TO ${targetPrompt.emoji} ${targetPrompt.title}`;
      mainLink = `/ask/${targetPrompt.id}`;
      canOpenLinkOnMobile = true;

      break;
    }

    case FeedItemType.TopRecOfDay: {
      if (targetRec == null) return null;
      imageSrc = `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `YOU HAD ${
        'placement' in feedItem?.extra || {}
          ? `THE ${ordinal(feedItem.extra.placement)} HOTTEST`
          : ' A TOP'
      } REC YESTERDAY`;
      mainLink = `/`;
      canOpenLinkOnMobile = true;
      break;
    }

    case FeedItemType.TopUserOfWeek: {
      if (targetUser == null) return null;
      imageSrc = `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = `YOU WERE ${
        'placement' in feedItem?.extra || {}
          ? `THE ${ordinal(feedItem.extra.placement)} HOTTEST`
          : ' A TOP'
      } USER LAST WEEK`;
      mainLink = `/`;
      canOpenLinkOnMobile = true;
      break;
    }

    // case FeedItemType.NewPromptSubscriptionRecReply: {
    //   if (originUser == null || targetPrompt == null) return null;
    //   imageSrc =
    //     originUser?.avatarPhotoSrc ||
    //     `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
    //   mainContent = mainContent = isGeneric
    //     ? `${sText}ANSWERED ${
    //         referenceUserId && targetUser?.id === referenceUserId ? 'YOUR' : `AN`
    //       } ASK`
    //     : `${sText}ANSWERED ${targetPrompt.emoji} ${targetPrompt.title}`;
    //   mainLink = `/ask/${targetPrompt.id}`;
    //   break;
    // }

    case FeedItemType.NewPromptSubscriptionRecReply:
    case FeedItemType.NewPromptRecReply: {
      if (originUser == null || targetPrompt == null || targetRec == null) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      mainContent = mainContent = isGeneric
        ? `${sText}ANSWERED ${
            referenceUserId && targetUser?.id === referenceUserId ? 'YOUR' : `AN`
          } ASK`
        : `${sText}ANSWERED ${
            targetPrompt.emoji !== targetRec.emoji ? `${targetRec.emoji} ` : ' '
          }${targetPrompt.emoji} ${targetPrompt.title}`;
      mainLink = `/rec/${targetRec.id}`;
      canOpenLinkOnMobile = true;
      break;
    }

    case MergedFeedItemType.MERGED_NEW_USER_FOLLOWS: {
      if (originUser == null || !('feedItems' in feedItem)) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      const firstFollow = feedItem.feedItems[0];
      if (!firstFollow?.targetUser) return null;
      mainContent = `${sText}FOLLOWED ${
        referenceUserId && firstFollow?.targetUser?.id === referenceUserId
          ? 'YOU'
          : `@${_.truncate(firstFollow?.targetUser?.username, { length: 10 })}`
      } and ${feedItem.feedItems.length - 1} OTHER${feedItem.feedItems.length - 1 > 1 ? 'S' : ''}`;
      mainLink = `/u/${originUser.username}/following`;
      break;
    }

    case MergedFeedItemType.MERGED_NEW_REC_ENDORSE_BY_USER: {
      if (originUser == null || !('feedItems' in feedItem)) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      const firstEndorse = feedItem.feedItems.filter(
        (mFeed) => mFeed.originUser && mFeed.originUser.id !== referenceUserId,
      )[0];
      if (!firstEndorse?.targetUser || !firstEndorse.targetRec) return null;
      mainContent = `${sText}LIKED ${firstEndorse.targetRec.emoji} ${_.truncate(
        firstEndorse.targetRec.title,
        { length: 10 },
      )} and ${feedItem.feedItems.length - 1} OTHER${feedItem.feedItems.length - 1 > 1 ? 'S' : ''}`;
      mainLink = `/rec/${firstEndorse.targetRec.id}`;
      break;
    }

    case MergedFeedItemType.MERGED_NEW_REC_ENDORSE: {
      if (originUser == null || !('feedItems' in feedItem)) return null;
      imageSrc =
        originUser?.avatarPhotoSrc ||
        `https://files.pi.fyi/default-profile-photo-${logoColor}-small.png`;
      const firstEndorse = feedItem.feedItems[0];
      if (!firstEndorse?.targetUser || !firstEndorse?.targetRec || !firstEndorse?.originUser)
        return null;
      mainContent = `@${firstEndorse.originUser.username} and ${
        feedItem.feedItems.length - 1
      } OTHER${feedItem.feedItems.length - 1 > 1 ? 'S' : ''} LIKED ${
        isGeneric
          ? 'THIS'
          : `${firstEndorse.targetRec.emoji} ${_.truncate(firstEndorse.targetRec.title, {
              length: 10,
            })}`
      }`;
      mainLink = `/rec/${firstEndorse.targetRec.id}`;
      break;
    }
  }

  if (!imageLink) {
    imageLink = mainLink;
  }

  return {
    imageSrc,
    imageFile,
    mainLink,
    canOpenLinkOnMobile,
    mainContent: mainContent?.toUpperCase(),
    imageLink,
    imageTooltip: imageTooltip?.toUpperCase(),
    mainTooltip: mainTooltip?.toUpperCase(),
  };
}
