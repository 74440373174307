import React, { useState, useEffect } from 'react';
import { DragifyProvider, useDraggable, useDroppable } from 'react-beautiful-dragify';
import { Button } from '../../button';
import { Modal, ModalContent } from '../../ui/modal';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { RiDeleteBin2Fill, RiImage2Fill } from 'react-icons/ri';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { gql } from 'shared/__generated__/gql';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { isInWebView } from 'shared/webview/index';
import { PerfImageFromFileFragment } from 'shared/__generated__/graphql';

interface Props {
  imageAttachments: any[];
  moveImages?: (newOrder: { id: string; position: number }[]) => void;
  onRemoveAttachment?: (id: string) => void;
  onOpenFilePicker?: () => void;
  isReadOnly?: boolean;
}

interface DraggableItemProps {
  id: string;
  image: any;
  index: number;
  children: React.ReactNode;
  isDragDisabled?: boolean;
}

function DraggableItem({ id, image, index, children, isDragDisabled = false }: DraggableItemProps) {
  const { draggableProps, dragHandleProps } = useDraggable({
    id,
    type: 'item',
    index,
    isDragDisabled,
    data: { ...image },
  });

  return (
    <div
      {...(draggableProps as React.HTMLAttributes<HTMLDivElement>)}
      className="relative cursor-grab"
      data-draggable-item="true"
    >
      {!isDragDisabled && (
        <div
          {...(dragHandleProps as React.HTMLAttributes<HTMLDivElement>)}
          className="absolute top-[30px] left-0 w-full h-[calc(100%-30px)] flex items-center z-10 justify-center after:content-[''] after:absolute after:top-[-30px] after:left-0 after:w-[calc(100%-31px)] after:h-full"
          style={{
            cursor: 'grab',
          }}
        />
      )}
      {children}
    </div>
  );
}

interface DroppableListProps {
  id: string;
  children: React.ReactNode;
}

function DroppableList({ id, children }: DroppableListProps) {
  const { droppableProps } = useDroppable({
    id,
    type: 'item',
    direction: 'horizontal',
    isDropDisabled: false,
    isCombineEnabled: false,
    ignoreContainerClipping: false,
  });

  return (
    <div
      {...(droppableProps as React.HTMLAttributes<HTMLDivElement>)}
      className="flex flex-row gap-4 overflow-x-auto p-4"
    >
      {children}
    </div>
  );
}

export default function RecDraggableAttachments({
  imageAttachments,
  moveImages,
  onRemoveAttachment,
  onOpenFilePicker,
  isReadOnly = false,
}: Props) {
  const IS_IN_WEBVIEW = isInWebView();
  const [imageList, setImageList] = useState(imageAttachments);
  const [changeAttachmentOrder] = useMutation(CHANGE_ATTACHMENT_ORDER_MUTATION, {
    onCompleted: () => {
      console.log('Attachment order updated successfully');
    },
    onError: (err) => console.error('Error updating attachment order:', err),
  });

  const [expandedImage, setExpandedImage] = useState<PerfImageFromFileFragment | null>(null);
  const modalState = useDisclosure();

  const changeAttachmentOrderDebounced = React.useMemo(() => {
    return _.debounce((attachments: any[]) => {
      changeAttachmentOrder({
        variables: {
          attachments: attachments.map((a) => ({ attachmentId: a.id, order: a.position })),
        },
      });
    }, 1000);
  }, [changeAttachmentOrder]);

  useEffect(() => {
    const imgAttachments =
      imageAttachments
        ?.filter((a) => a.type === 'IMAGE')
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0)) || [];

    const finalUrls = imgAttachments.map((image) => {
      return { ...image };
    });

    setImageList(finalUrls);
  }, [imageAttachments]);

  const handleDragEnd = (dropResult: any) => {
    if (!dropResult.destination || dropResult.source.index === dropResult.destination.index) {
      return;
    }

    const sourceIndex = dropResult.source.index;
    const destinationIndex = dropResult.destination.index;

    const mutableImageList = [...imageList];

    const movedAttachment = mutableImageList.splice(sourceIndex, 1)[0];
    mutableImageList.splice(destinationIndex, 0, movedAttachment);

    const formattedAttachments = mutableImageList.map((attachment, index) => ({
      ...attachment,
      position: index,
    }));

    setImageList(formattedAttachments);

    changeAttachmentOrderDebounced(formattedAttachments);
  };

  const remove = (id: string) => {
    if (onRemoveAttachment) {
      onRemoveAttachment?.(id);
    }
  };

  return imageList.length > 0 ? (
    <>
      <Modal open={modalState.isOpen} onOpenChange={modalState.onToggle}>
        <ModalContent className="w-full h-full">
          {expandedImage && (
            <PerfImageFromFile
              file={expandedImage}
              alt={`Image ${expandedImage.name}`}
              className="w-full h-full object-cover"
            />
          )}
        </ModalContent>
      </Modal>
      <div className="w-full">
        {/* @ts-ignore - Ignoring type issues with DragifyProvider */}
        <DragifyProvider onDragEnd={handleDragEnd}>
          <DroppableList id="image-list">
            {imageList.map((image, index) => (
              <DraggableItem
                image={image}
                key={image.id}
                id={image.id}
                index={index}
                isDragDisabled={isReadOnly}
              >
                <div className="relative w-32 h-32 overflow-hidden border border-gray-200">
                  {image.file.isFailed ? (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      !
                    </div>
                  ) : !image.file.isUploaded ? (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      loading...
                    </div>
                  ) : null}
                  {image.file ? (
                    <>
                      <PerfImageFromFile
                        file={image.file}
                        alt={`Image ${index + 1}`}
                        className="w-full h-full object-cover"
                        onClick={() => {
                          console.log('image.file', image.file);
                          setExpandedImage(image.file);
                          modalState.onOpen();
                        }}
                      />
                      <div
                        className="absolute w-full h-full top-0 right-0 z-10"
                        onClick={() => {
                          console.log('image.file', image.file);
                          setExpandedImage(image.file);
                          modalState.onOpen();
                        }}
                      />
                    </>
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      {/* @ts-ignore */}
                      <RiImage2Fill className="text-gray-400 text-2xl" />
                    </div>
                  )}

                  {!isReadOnly && (
                    <button
                      type="button"
                      onClick={() => remove(image.id)}
                      className="z-11 absolute top-2 right-2 p-1 bg-red-500 text-white"
                      aria-label="Remove image"
                    >
                      {/* @ts-ignore */}
                      <RiDeleteBin2Fill size={16} />
                    </button>
                  )}
                </div>
              </DraggableItem>
            ))}

            {!isReadOnly && imageList.length < 5 && imageList.length > 0 && !IS_IN_WEBVIEW && (
              <div className="flex items-center justify-center">
                <Button
                  onClick={onOpenFilePicker}
                  variant="outline"
                  className="h-32 w-32 flex flex-col items-center justify-center"
                >
                  {/* @ts-ignore */}
                  <RiImage2Fill className="text-2xl mb-2" />
                  <span>Add More</span>
                </Button>
              </div>
            )}
          </DroppableList>
        </DragifyProvider>
      </div>
    </>
  ) : null;
}

const CHANGE_ATTACHMENT_ORDER_MUTATION = gql(/* GraphQL */ `
  mutation changeAttachmentOrder($attachments: [AttachmentOrderArg!]!) {
    changeAttachmentOrder(attachments: $attachments) {
      id
      position
    }
  }
`);
