import {
  Box,
  Button,
  Text,
  FocusLock,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import InlineSubscribeToSignUp from '../../../../../InlineSubscribeToSignUp';
import { LexicalGenericInnerComponentProps } from './LexicalGenericComponent';
import React, { useEffect } from 'react';
import normalizeUrl from 'normalize-url';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey } from 'lexical';
import _ from 'lodash';
import { $isGenericComponentNode } from '../../LexicalGenericComponentNode';
import { URL_REGEX } from '../../../../../../../validation/schemas';

export default function LexicalCustomButtonComponent({
  isEditable,
  data,
  setData,
  nodeKey,
}: LexicalGenericInnerComponentProps) {
  const [editor] = useLexicalComposerContext();

  const [text, setText] = React.useState(data?.text || '');
  const [url, setUrl] = React.useState(data?.url || '');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const popoverState = useDisclosure();

  let normalizedUrl = url;
  let invalidUrl = !URL_REGEX.test(normalizedUrl);

  try {
    normalizedUrl = normalizeUrl(url);
  } catch (e) {
    invalidUrl = true;
  }

  let buttonNode = (
    <Button width="fit-content" height={{ sm: '50px' }} as="a" href={normalizedUrl} ref={buttonRef}>
      <Text textStyle="brand.headingMd">{text || (isEditable ? 'Placeholder' : '')}</Text>
    </Button>
  );

  const updateDataDebounced = React.useMemo(
    () =>
      _.debounce((nextData) => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey);
          if ($isGenericComponentNode(node)) {
            node.setData(nextData);
          }
        });
      }, 500),
    [],
  );

  useEffect(() => {
    if (isEditable) {
      updateDataDebounced({ text, url });
    }
  }, [isEditable, text, url]);

  if (isEditable) {
    buttonNode = (
      <Popover {...popoverState}>
        <PopoverTrigger>{buttonNode}</PopoverTrigger>
        <PopoverContent>
          <FocusLock persistentFocus={false}>
            <PopoverCloseButton />
            <PopoverHeader>Change Button</PopoverHeader>
            <PopoverBody>
              <Stack>
                <Input
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Button text"
                />
                <Input
                  value={url}
                  type="url"
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Button URL"
                />
                {invalidUrl && url && (
                  <Text textStyle="brand.actionSm" color="red">
                    Invalid URL
                  </Text>
                )}
              </Stack>
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      onClick={(e) => {
        if ((isEditable && buttonRef.current?.contains(e.target as Node)) || popoverState.isOpen) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {buttonNode}
    </Box>
  );
}
