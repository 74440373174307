import { createFileRoute, redirect } from '@tanstack/react-router';
import { gql, useMutation, useQuery } from '@apollo/client';
import Spinner from 'web2/app/components/spinner';
import { useState } from 'react';
import { Button as TWButton } from '../../components/button';
import { getSessionUser } from '../../actions/getSessionUser';
import { Select } from '@chakra-ui/react';

export const Route = createFileRoute('/redeem/$bonusId')({
  component: RouteComponent,
  beforeLoad: async ({ params }) => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({
        to: `/sign-in?destination=${encodeURIComponent(`/redeem/${params.bonusId}`)}`,
      });
    }
  },
});

function RouteComponent() {
  const { bonusId } = Route.useParams();
  const { data, loading } = useQuery(
    gql`
      query getInviteBonus($id: String!) {
        getInviteBonus(id: $id) {
          id
          isRedeemed
          product
        }
      }
    `,
    {
      variables: {
        id: bonusId,
      },
      onCompleted: (data) => {
        setSelectedVariant(data.getInviteBonus?.product?.variants?.nodes[0].id);
      },
    },
  );

  const [selectedVariant, setSelectedVariant] = useState('');

  const [mutate, mutateInfo] = useMutation(
    gql`
      mutation getCheckoutLinkForBonus($id: String!, $productVariantId: String!) {
        getCheckoutLinkForInviteBonus(id: $id, productVariantId: $productVariantId)
      }
    `,
  );

  const variants = data?.getInviteBonus.product?.variants?.nodes;

  const previousSrc = data?.getInviteBonus?.product?.media?.nodes[0]?.preview?.image?.url;

  const hasNoOptions = !loading && variants.length && variants[0].title === 'Default Title';

  return (
    <div className="pb-[100px] overflow-y-scroll">
      <div className="space-y-[24px] flex flex-col items-center mx-auto pl-[25px] pr-[25px] pb-[25px] pt-[48px] max-w-[700px] min-w-full lg:min-w-[600px] mt-[24px]">
        <span className="typography-heading-lg text-center">Redeem your bonus</span>
        {data?.getInviteBonus.product ? (
          <span className="typography-heading-md text-center mb-[12px]">
            "{data?.getInviteBonus.product.title}"
          </span>
        ) : null}
        {!loading ? (
          <>
            {data?.getInviteBonus?.isRedeemed ? (
              <div className="spacing-y-[12px]">
                <span className="typography-heading-sm mb-[12px] text-center">
                  This offer has already been used.
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col justify-center w-full space-y-3">
                  <img src={previousSrc} />
                </div>
                <div className="space-y-3">
                  {!hasNoOptions ? (
                    <>
                      <span className="typography-heading-sm mb-[12px]">
                        Choose a variant below
                      </span>
                      <Select
                        value={selectedVariant}
                        onChange={(e) => setSelectedVariant(e.target.value)}
                      >
                        {variants?.map((variant) => (
                          <option key={variant.id} value={variant.id}>
                            {variant.title}
                          </option>
                        ))}
                      </Select>
                    </>
                  ) : null}
                  <div className="flex flex-col items-start">
                    <TWButton
                      isLoading={mutateInfo.loading}
                      isDisabled={
                        mutateInfo.loading ||
                        !selectedVariant ||
                        mutateInfo?.data?.getCheckoutLinkForInviteBonus
                      }
                      onClick={async () => {
                        await mutate({
                          variables: {
                            id: bonusId,
                            productVariantId: selectedVariant,
                          },
                          onCompleted: (data) => {
                            window.location.href = data.getCheckoutLinkForInviteBonus;
                          },
                        });
                      }}
                    >
                      <span className="typography-heading-sm">Redeem</span>
                    </TWButton>
                    <span className="typography-body mt-[12px] self-center max-w-[300px]">
                      You'll be redirected to a checkout window to enter your address.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Spinner placement="center" />
        )}
      </div>
    </div>
  );
}
