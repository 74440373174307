import { Stack, Button, Link, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef } from 'react';

export enum PaywallReason {
  ARCHIVE = 'ARCHIVE',
  PREMIUM = 'PREMIUM',
}

export default function BlurredPaywall({
  reason = PaywallReason.PREMIUM,
  message,
}: {
  reason?: PaywallReason;
  message?: string;
}) {
  const { existingPaywallRef } = useContext(PaywallContext);
  const [isReady, setIsReady] = React.useState(true);

  let text = message || 'This content is paywalled';

  if (reason === PaywallReason.ARCHIVE && !message) {
    text = 'This recommendation is from the Perfectly Imperfect archive';
  }

  const ref = useRef<HTMLDivElement>(null);

  if (!existingPaywallRef.current && ref.current) {
    existingPaywallRef.current = ref.current;
  }

  if (existingPaywallRef.current && existingPaywallRef.current !== ref.current) {
    return null;
  }

  return (
    <Stack
      className="paywall"
      spacing="24px"
      display="flex"
      backdropFilter="blur(5px)"
      zIndex="1"
      position="absolute"
      margin="-5px"
      flex={1}
      maxWidth={'100%'}
      width="calc(100% + 10px)"
      height="calc(100% + 10px)"
      justifyContent="flex-start"
      alignItems="center"
      ref={ref}
      // backgroundColor="red"
    >
      <Text marginTop="24px" textStyle="brand.headingLg">
        👀
      </Text>
      <Text textStyle="brand.actionSm" backgroundColor="brand.background" p="6px">
        {text}
      </Text>
      <Button variant="primary" as={Link} href="/settings/premium">
        Upgrade
      </Button>
    </Stack>
  );
}

type ContextProps = {
  existingPaywallRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const PaywallContext = React.createContext<ContextProps>({} as ContextProps);

export function PaywallProvider({ children }) {
  // const [existingPaywall, setExistingPaywall] = React.useState<HTMLDivElement | null>(null);

  const existingPaywallRef = useRef<HTMLDivElement | null>(null);

  return (
    <PaywallContext.Provider value={{ existingPaywallRef }}>{children}</PaywallContext.Provider>
  );
}
