import { createFileRoute } from '@tanstack/react-router';
import AdminStaff from './-admin-staff';

export const Route = createFileRoute('/admin/staff/')({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminStaff />;
}
