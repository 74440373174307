'use client';

import React from 'react';
import { Box, Flex, Text, Stack, Heading, Button, useToast, Progress } from '@chakra-ui/react';
import { useQuery, gql } from '@apollo/client';
import { copyToClipboard } from '../clipboard';
import { BASE_SITE_URL } from '../../siteconfig';
import { useAnalytics } from '../providers/AnalyticsContext';
import { StyledStar } from '../components/StarContainer';
import PerfImage from '../components/util/PerfImage';
import PerfImageFromFile from './util/PerfImageFromFile';

const ReferralQuery = gql(/* GraphQL */ `
  query getReferrals {
    me {
      ... on User {
        id
        username
        isPremium
        invitesRedeemedCount
      }
    }
  }
`);

export default function InlineReferrals({ isBorderless = false }: { isBorderless?: boolean }) {
  const { trackEvent } = useAnalytics();
  const toast = useToast();

  const { data, loading } = useQuery(ReferralQuery);
  const { me } = data ?? {};

  if (loading || !me) {
    return null;
  }

  const { invitesRedeemedCount: inviteCount, isPremium = false } = me ?? {};
  const signUpUrl = `${BASE_SITE_URL}/subscribe/referral/${me.username}`;

  return (
    <Stack
      alignItems="center"
      border={isBorderless ? 'none' : '1px dashed'}
      borderTop="1px dashed"
      borderColor="brand.main"
      justifyContent="center"
      p="24px"
    >
      <Text
        textStyle="brand.headingLg"
        color="brand.highlight"
        fontSize={{ base: '20px', md: '24px' }}
        alignSelf="center"
        textAlign={{ base: 'center' }}
        mb="24px"
      >
        Invite friends For Free Stuff!
      </Text>
      <Flex
        alignItems="stretch"
        width="100%"
        maxWidth="700px"
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        {!isPremium && (
          <Stack justifyContent="center" spacing="6px" mb="12px" width="100%" alignItems="center">
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Text color="brand.highlight">
                <StyledStar fill="currentColor" width={50} height={50} />
              </Text>
              <Heading
                variant="L"
                alignSelf="center"
                textAlign="center"
                fontWeight="normal"
                fontSize={{ base: '20px', md: '24px' }}
              >
                Premium Trial
              </Heading>
            </Box>
            <Stack>
              <Progress width="200px" value={((inviteCount > 1 ? 1 : inviteCount) / 1) * 100} />
              <Text textAlign="center" fontSize="18px">
                ({inviteCount > 1 ? '1' : inviteCount}/1)
              </Text>
            </Stack>
          </Stack>
        )}
        <Stack
          justifyContent="space-between"
          spacing="6px"
          mb="12px"
          width="100%"
          alignItems="center"
        >
          <PerfImageFromFile
            width={100}
            height={100}
            fileKey="pi-mug-free.png"
            alt="PI coffee mug"
          />
          <Stack>
            <Progress width="200px" value={((inviteCount > 5 ? 5 : inviteCount) / 5) * 100} />
            <Text textAlign="center" fontSize="18px">
              ({inviteCount > 5 ? '5' : inviteCount}/5)
            </Text>
          </Stack>
        </Stack>
        <Stack
          justifyContent="space-between"
          spacing="6px"
          mb="12px"
          width="100%"
          alignItems="center"
        >
          <PerfImageFromFile
            width={100}
            height={100}
            fileKey="pi-shirt-free.png"
            alt="PI t-shirt"
          />
          <Stack>
            <Progress width="200px" value={((inviteCount > 10 ? 10 : inviteCount) / 10) * 100} />
            <Text textAlign="center" fontSize="18px">
              ({inviteCount > 10 ? '10' : inviteCount}/10)
            </Text>
          </Stack>
        </Stack>
      </Flex>
      <Button
        my="12px"
        variant="primary"
        textStyle="brand.headingSm"
        onClick={() => {
          copyToClipboard(signUpUrl);
          toast({
            title: 'Copied invite link',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          trackEvent('Click', 'Invite_Link', {
            inviteCode: signUpUrl,
          });
        }}
      >
        COPY YOUR INVITE LINK
      </Button>
      <Text opacity={0.5} textAlign="center" fontSize="12px">
        {signUpUrl}
      </Text>
    </Stack>
  );
}
