import { useMutation } from '@apollo/client';
import { gql } from '../../__generated__/gql';
import { useAnalytics } from '../providers/AnalyticsContext';

export default function useFollowUser({ user }) {
  const { trackEvent } = useAnalytics();

  const [followUser, followUserInfo] = useMutation(FOLLOW_USER_MUTATION, {
    variables: {
      targetUserId: user?.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      followUser: {
        __typename: 'User',
        id: user?.id,
        amIFollowing: true,
      },
    },
    onCompleted: () => {
      trackEvent('Click', 'User_Followed', { userId: user?.id });
    },
  });

  const [unfollowUser, unfollowUserInfo] = useMutation(UNFOLLOW_USER_MUTATION, {
    variables: {
      targetUserId: user?.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      unfollowUser: {
        __typename: 'User',
        id: user?.id,
        amIFollowing: false,
      },
    },
    onCompleted: () => {
      trackEvent('Click', 'User_Unfollowed', { userId: user?.id });
    },
  });

  return {
    followUser,
    followUserInfo,
    unfollowUser,
    unfollowUserInfo,
    isSubmitting: followUserInfo.loading || unfollowUserInfo.loading,
  };
}
const FOLLOW_USER_MUTATION = gql(/* GraphQL */ `
  mutation followUser($targetUserId: String!) {
    followUser(targetUserId: $targetUserId) {
      id
      amIFollowing
    }
  }
`);

const UNFOLLOW_USER_MUTATION = gql(/* GraphQL */ `
  mutation unfollowUser($targetUserId: String!) {
    unfollowUser(targetUserId: $targetUserId) {
      id
      amIFollowing
    }
  }
`);

