// app/client.tsx
/// <reference types="vinxi/types/client" />
import { hydrateRoot } from 'react-dom/client';
import { StartClient } from '@tanstack/react-start';
import { createRouter } from './router';
import * as Sentry from '@sentry/react';

const router = createRouter();

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
  try {
    Sentry.init({
      dsn: 'https://274934685cc0710b00c9a44baaadaf67@o4506049543602176.ingest.sentry.io/4506049546420224',
      integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
      // Setting a sample rate is required for sending performance data.
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control.
      tracesSampleRate: 1.0,
    });
  } catch (e) {
    console.error(e);
  }
}

hydrateRoot(document, <StartClient router={router} />);
