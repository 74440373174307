import { createFileRoute, redirect } from '@tanstack/react-router';

import { Box, Stack, Text } from '@chakra-ui/react';
import ThemeAwareHeader from 'shared/misc/components/ThemeAwareHeader';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__';
import { useAuth } from 'shared/misc/hooks/useAuth';
import BlockItem from './-user-block-list-item';
import SkeletonBlockItem from 'shared/misc/components/SkeletonBlockItem';
import { isInWebView } from 'shared/webview';
import { getSessionUser } from '../../../../actions/getSessionUser';
export const Route = createFileRoute('/settings/manage/blocks/')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({ to: `/sign-in?destination=${encodeURIComponent('/settings/premium')}` });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Blocked Users',
      },
    ],
  }),
});

const PageQuery = gql(/* GraphQL */ `
  query getManageBlockPage($first: Int, $after: String) {
    blockedByConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...BlockItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 30;

function RouteComponent() {
  const auth = useAuth();

  const { data, loading, fetchMore } = useQuery(PageQuery, {
    variables: { first: PAGE_LENGTH },
    fetchPolicy: 'cache-and-network',
  });

  const commonProps = {
    strokeSize: 1,
    borderBottom: '1px dashed',
    borderBottomColor: 'brand.main',
    pb: '36px',
    mb: '12px',
  };

  return (
    <div className="flex justify-center p-[24px] mb-[100px]">
      <Stack
        spacing="24px"
        width="100%"
        justifyContent="center"
        className="max-w-100 md:max-w-[700px]"
      >
        {isInWebView() ? (
          <ThemeAwareHeader
            {...commonProps}
            fontSize={{ base: '40px', md: '48px' }}
            textAlign="center"
          >
            Blocked Users
          </ThemeAwareHeader>
        ) : (
          <ThemeAwareHeader {...commonProps} fontSize={{ base: '36px', md: '48px' }}>
            Blocked Users
          </ThemeAwareHeader>
        )}
        {loading && (
          <Stack spacing="12px" justifyContent="flex-start" width="100%">
            <SkeletonBlockItem />
          </Stack>
        )}
        {!loading && data?.blockedByConnection?.edges.length === 0 && (
          <Text textStyle="brand.headingSm" textAlign={isInWebView() ? 'center' : undefined}>
            You haven't blocked anyone.
          </Text>
        )}
        {!loading &&
          data?.blockedByConnection?.edges.map((block: any) => (
            <BlockItem user={block.node} key={block.node.id} />
          ))}
      </Stack>
    </div>
  );
}
