import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useRouter } from '@tanstack/react-router';
import _ from 'lodash';
import { gql } from 'shared/__generated__/gql';
import { UserTileFragment } from 'shared/__generated__/graphql';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import Heading from 'shared/misc/components/heading';
import { getEmojisAsArrayFromString } from 'shared/emoji';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useFollowUser from 'shared/misc/hooks/useFollowUser';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import { Button } from 'web2/app/components/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'web2/app/components/ui/tooltip';
import { TWLink } from '../../link';

gql(/* GraphQL */ `
  fragment UserTile on User {
    ...UserDirectoryItem
    ...UserCore
    amIFollowing
    profileTheme
    areTheyFollowing
    avatarPhotoFile {
      ...PerfImageFromFile
    }
    recs(first: 5, includePromptRecs: true) {
      id
      emoji
    }
  }
`);

type Props = {
  size?: 'lg' | 'sm';
  user: UserTileFragment;
  priority?: boolean;
  skipStars?: boolean;
};

function UserTile({ user, size = 'sm', priority = false, skipStars }: Props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '2000px 0px 200px',
  });

  const href = `/u/${user.username}`;

  const themeColors = useThemeColors();

  const auth = useAuth();
  const router = useRouter();

  const colors = useThemeColors(user.profileTheme);

  // const isToday =
  //   isAfter(sub(new Date(), { days: 1 }), new Date(feature.date)) &&
  //   isBefore(add(new Date(), { days: 1 }), new Date(feature.date));

  const dimension = 175;

  const { followUser, unfollowUser } = useFollowUser({ user });

  const isLight = useIsBackgroundLight(colors.background);

  return (
    <div
      role="group"
      ref={ref}
      className="flex flex-col gap-3 border border-dashed overflow-hidden justify-center h-full pb-4"
      style={{
        borderColor: colors.highlight,
        backgroundColor: colors.background,
      }}
    >
      <div className="cursor-pointer w-full sm:w-[175px] self-center">
        <TWLink
          to={'/u/$username'}
          params={{ username: user.username }}
          className="w-full flex flex-col justify-center items-center"
        >
          <div
            className="relative flex flex-col justify-center items-center px-2 pb-[12px] w-full self-center min-h-[50px]"
          >
            <Heading
              fitToContainer
              variant="XL"
              theme={user.profileTheme}
              maxSize={30}
              minSize={12}
              strokeWidth={1}
              color={colors.titleBackground}
              className="text-[30px] py-2 max-w-full text-center line-height-1 pt-4 line-clamp-1"
            >
              <span className="flex h-fit line-height-1 line-clamp-1 block w-full">
                @{_.truncate(user.username, { length: 12 })}
              </span>
            </Heading>
            <Tooltip>
              <TooltipContent className="text-brand-main typography-action-sm">
                Recent recs.
              </TooltipContent>
              <TooltipTrigger>
                <div
                  className="typography-action-sm text-center whitespace-nowrap text-base"
                  style={{
                    color: colors.main,
                    opacity: user?.recs?.length ? 1 : 0.4,
                  }}
                >
                  {user?.recs?.length
                    ? getEmojisAsArrayFromString(
                        user?.recs?.reduce((acc, r) => `${acc}${r.emoji?.trim()}`, ''),
                      )
                        .slice(0, 5)
                        .join(' ')
                        .trim()
                    : 'No recs yet..'}
                </div>
              </TooltipTrigger>
            </Tooltip>
          </div>
          <div className="flex w-[calc(100%-24px)] sm:w-full pb-1 max-h-fit">
            <PerfImageFromFile
              file={user.avatarPhotoFile}
              backgroundColor={colors.background}
              useFallbackAvatarSrc
              alt="profile photo"
              quality={80}
              priority={inView}
              width={dimension}
              height={dimension}
              style={{
                width: `${dimension}px`,
                height: `${dimension}px`,
                objectFit: 'cover',
                objectPosition: 'top',
              }}
            />
          </div>
        </TWLink>
      </div>

      <div className="flex flex-row w-[calc(100%-24px)] self-center">
        <Button
          variant="primary"
          className="w-[calc(60%-4px)] h-fit p-[3px] mr-[4px] disabled:opacity-50"
          isDisabled={auth.user?.id === user.id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (auth.user) {
              if (user.amIFollowing) {
                unfollowUser();
              } else {
                followUser();
              }
            } else {
              router.navigate({ to: '/sign-in' });
            }
          }}
          style={{
            color: colors.background,
            backgroundColor: colors.highlight,
            border: '1px solid transparent',
          }}
        >
          {auth.user && user.amIFollowing
            ? 'Following'
            : user.id === auth.user?.id
            ? `That's you!`
            : 'Follow'}
        </Button>
        <Button
          className="w-[calc(40%-4px)] h-fit p-[3px] ml-[4px] disabled:opacity-50"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            router.navigate({ to: href });
          }}
          style={{
            color: colors.highlight,
            backgroundColor: colors.background,
            borderWidth: '1px',
            borderColor: colors.highlight,
          }}
        >
          View
        </Button>
      </div>
    </div>
  );
}

const FallBackImage = ({ withSkeleton }: { withSkeleton?: boolean }) => {
  return (
    <div
      className="flex w-full object-cover aspect-square object-[0_0] justify-center items-center"
      style={
        !withSkeleton
          ? {
              backgroundColor: 'brand.highlight',
            }
          : {}
      }
    >
      {withSkeleton && (
        <div className="h-full w-full bg-gradient-to-r from-brand-highlight to-[#0096FF] animate-pulse" />
      )}
    </div>
  );
};

export default UserTile;
