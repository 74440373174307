'use client';

import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/next-js';
import { useProfileContext } from 'shared/misc/providers/ProfileContext';
import { gql } from 'shared/__generated__/gql';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { ModalNames, useModalState } from 'shared/misc/wrappers/ModalProvider';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import PromptItem from '../../../components/by-type/prompts/prompt-item';
import GenericRecInfiniteScrollView from '../../../components/by-type/recommendations/generic-rec-infinite-scroll';
import GenericRecInfiniteCarousel from '../../../components/by-type/recommendations/generic-rec-infinite-carousel';
import { OrderBy } from 'shared/__generated__/graphql';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import FeedItemCarousel from 'shared/misc/components/FeedItemCarousel';
import FeedItem from 'shared/misc/components/by-type/feed/FeedItem';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { TWLink } from 'web2/app/components/link';
import { Suspense } from 'react';

export default function ProfileMainLowerContent() {
  const { profile } = useProfileContext();

  const themeColors = useThemeColors();

  const { titleBackground, titleBorder } = themeColors;

  const selectedProps = {
    color: titleBackground,
    WebkitTextStroke: '1px',
    WebkitTextStrokeColor: titleBorder,
    fontSize: ['24px', '36px'],
  };

  return (
    <div className="flex flex-col gap-6 w-full overflow-x-hidden transition-all duration-300">
      <GenericRecInfiniteCarousel
        key="pinnedRecs"
        variables={{
          originUserId: profile.id,
          ...PinnedRecommendationsVariables,
        }}
        // Figure out how to fix these props.
        itemProps={{ withPinnedIcon: false }}
      />
      <Tabs
        isFitted
        variant="stylized"
        defaultIndex={0}
        isLazy
        width="100%"
        // ml={{ base: '1px', sm: '0' }}
      >
        <TabList paddingBottom="24px" width="100%" display="flex" justifyContent="space-around">
          <Tab textStyle="brand.headingXL" fontSize={['20px', '30px']} _selected={selectedProps}>
            RECS {profile.recCount ? `(${profile.recCount})` : ''}
          </Tab>
          <Tab textStyle="brand.headingXL" fontSize={['20px', '30px']} _selected={selectedProps}>
            ASKS {profile.promptCount ? `(${profile.promptCount})` : ''}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box key="recs" marginTop="36px">
              <ProfileLowerRecommendations />
              {/* <Suspense
              fallback={
                <div className="flex flex-col gap-6">
                  <SkeletonFeedItem />
                  <SkeletonFeedItem />
                  <SkeletonFeedItem />
                  <SkeletonFeedItem />
                  <SkeletonFeedItem />
                  <SkeletonFeedItem />
                </div>
              }
            >
              
            </Suspense> */}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box key="asks" marginTop="36px">
              <ProfileLowerAsks />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

gql(/* GraphQL */ `
  fragment ProfileLowerRecommendations on User {
    id
    recCount
  }
`);

export const ProfileLowerRecommendationsVariables = {
  // originUserId: profile.id,
  orderBy: OrderBy.NewestFirst,
  includePromptRecs: true,
  includeReRecsWithoutComment: true,
  first: 10,
  isEndorsedByViewer: false,
  onlyGuests: false,
};

export const PinnedRecommendationsVariables = {
  isPinned: true,
  orderBy: OrderBy.PinnedFirst,
  includePromptRecs: true,
  includeReRecsWithoutComment: true,
  first: 2,
  isEndorsedByViewer: false,
  onlyGuests: false,
};

function ProfileLowerRecommendations() {
  const addRecommendationModalState = useModalState(ModalNames.ADD_RECOMMENDATION);

  const { profile } = useProfileContext();

  // const { data } = useSuspenseQuery(ProfileLowerRecommendationsQuery, {
  //   variables: { username: profile.username, orderBy: 'pinnedFirst', first: 5 },
  //   fetchPolicy: 'cache-and-network',
  //   queryKey: `ProfileLowerRecommendations:${profile.username}`,
  // });

  const auth = useAuth();

  // const user = data?.user;

  // if (!user) {
  //   return null;
  // }

  // const recommendations = user.recConnection?.edges;

  return (
    <Stack order="6" spacing="0px">
      <GenericRecInfiniteScrollView
        key="allRecs"
        variables={{
          originUserId: profile.id,
          isPinned: false,
          ...ProfileLowerRecommendationsVariables,
        }}
      />
    </Stack>
  );
}

gql(/* GraphQL */ `
  fragment ProfileLowerAsks on User {
    id
    recCount
    promptCount
    promptConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PromptItem
        }
      }
    }
  }
`);

const ProfileProfileLowerAsksQuery = gql(/* GraphQL */ `
  query getProfileLowerAsks($username: String!, $first: Int, $after: String) {
    user(username: $username) {
      ...ProfileLowerAsks
    }
  }
`);

function ProfileLowerAsks() {
  const addAskModalState = useModalState(ModalNames.ADD_ASK);

  const { profile } = useProfileContext();

  const { data, loading, variables } = useQuery(ProfileProfileLowerAsksQuery, {
    variables: { username: profile.username, first: 5 },
    fetchPolicy: 'cache-and-network',
  });

  const auth = useAuth();

  const user = data?.user;

  const prompts = user?.promptConnection?.edges || [];

  return (
    <Stack order="6" spacing="24px" width="100%">
      {!data && loading && (
        <>
          <SkeletonFeedItem isCentered />
          <SkeletonFeedItem isCentered />
          <SkeletonFeedItem isCentered />
          <SkeletonFeedItem isCentered />
          <SkeletonFeedItem isCentered />
          <SkeletonFeedItem isCentered />
        </>
      )}
      {prompts?.map(({ node: prompt }) => (
        <PromptItem prompt={prompt} key={prompt.id} />
      ))}
      {prompts?.length === 0 && (
        <Box alignSelf="center" padding={{ base: '24px', md: '48px' }}>
          <Tooltip
            label={
              profile.id === auth?.user?.id
                ? 'Ask people for recommendations.'
                : 'This user has asked anything yet.'
            }
          >
            <Text textStyle="brand.headingSm" layerStyle="secondaryText" textAlign="center">
              Nothing yet...
              {profile.id === auth?.user?.id && (
                <Button
                  ml="6px"
                  variant="link"
                  color="brand.lightgrey;"
                  borderBottomColor="brand.lightgrey;"
                  onClick={addAskModalState.onOpen}
                  padding="0px"
                >
                  Ask something?
                </Button>
              )}
            </Text>
          </Tooltip>
        </Box>
      )}
      {(user?.promptCount || 0 > 5) && (
        <TWLink
          to={'/u/$username/asks'}
          params={{ username: profile.username }}
          className="self-center typography-body text-brand-menu-text hover:text-brand-menu-text-hover"
        >
          View all ({user?.promptCount || '?'})
        </TWLink>
      )}
    </Stack>
  );
}
