export enum USER_SIGN_UP_STEP {
  INITIAL = 'INITIAL',
  VERIFY = 'VERIFY',
  VERIFY_ANOTHER = 'VERIFY_ANOTHER',
  VERIFY_COMPLETE = 'VERIFY_COMPLETE',
  ADD_EMAIL = 'ADD_EMAIL',
  ADD_PASSWORD = 'ADD_PASSWORD',
  SUBSTACK = 'SUBSTACK',
  PREMIUM = 'PREMIUM',
  LOCATION = 'LOCATION',
  MORE = 'MORE',
  MORE_USERNAME = 'MORE_USERNAME',
  MORE_BIRTHDAY = 'MORE_BIRTHDAY',
  PHOTO = 'PHOTO',
  ADD_RECS = 'ADD_RECS',
  DONE = 'DONE',
  FOLLOW = 'FOLLOW',
  INVITE_FRIENDS = 'INVITE_FRIENDS',
  ANSWER_ASKS = 'ANSWER_ASKS',
  THEME = 'THEME',
  SHARE = 'SHARE',
  SURVEY = 'SURVEY',
  LIKE_RECS = 'LIKE_RECS',
  FEATURES = 'FEATURES',
  CATEGORIES = 'CATEGORIES',
  LOADING = 'LOADING',
  TRIAL = 'TRIAL',
}

export enum USER_COLOR {
  RED = 'RED',
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  PURPLE = 'PURPLE',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}
