import { createFileRoute } from '@tanstack/react-router';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import {
  GlobalWebViewObjects,
  getGlobalWebViewObject,
  safeSendWebViewMessage,
} from 'shared/webview/index';
import { ProfileThemeType, RecommendationItemFragment } from 'shared/__generated__/graphql';
import { UpdateRecommendationMessages } from 'shared/webview/messages';
import UpdateRecommendationModal from '../../../components/modals/update-recommendation-modal';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { gql } from 'shared/__generated__/gql';
import { useQuery } from '@apollo/client';

export const Route = createFileRoute('/mobile/update-recommendation/')({
  component: RouteComponent,
  ssr: false,
});

function RouteComponent() {
  const modalState = useDisclosure();

  const onClose = () => {
    safeSendWebViewMessage(UpdateRecommendationMessages.CLOSE);
  };

  const WEB_VIEW_PROPS = getGlobalWebViewObject(GlobalWebViewObjects.UpdateRecommendationProps);
  const rec = WEB_VIEW_PROPS?.rec as RecommendationItemFragment;

  if (!rec) {
    // TODO: loading state
    return null;
  }

  const { data, loading } = useQuery(UPDATE_REC_ITEM_QUERY, {
    variables: {
      id: rec.id,
    },
    skip: !rec,
    fetchPolicy: 'cache-and-network',
  });

  useAutoSetTheme(ProfileThemeType.Default);

  return (
    <UpdateRecommendationModal rec={loading || !data?.rec ? rec : data?.rec} modalState={{ ...modalState, isOpen: true, onClose }} />
  );
}


const UPDATE_REC_ITEM_QUERY = gql(/* GraphQL */ `
  query getMobileRec($id: String!) {
    rec(id: $id) {
      ...RecommendationItem
    }
  }
`);
