import { Tooltip, Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { useAuth } from '../../hooks/useAuth';

interface Props extends BoxProps {
  children: ReactNode;
}

function IsLoggedInTooltip({ children, ...props }: Props) {
  const { user } = useAuth();
  return user ? (
    <>{children}</>
  ) : (
    <Tooltip label="You must be signed in to do this.">{children}</Tooltip>
  );
}

export default IsLoggedInTooltip;
