import { chakra, Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import StarSVG from './star.svg?react';
import _ from 'lodash';
import { perfectlyImperfectColors } from '../theme/colors';

export const StyledStar = chakra(StarSVG);

interface Props extends BoxProps {
  children: ReactNode;
  skipStars?: boolean;
  size?: number;
  offsetEdgeAmt?: number;
}

const LARGE = 110;
const MEDIUM = 100;
const XL = 130;

export const STAR_SIZES = [MEDIUM, MEDIUM, MEDIUM, 125, 125, LARGE, LARGE];

const SIDES: string[] = [
  'top-left',
  'middle-left',
  'bottom-left',
  'top-right',
  'middle-right',
  'bottom-right',
  'bottom-middle',
  'top-middle',
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function StarContainer({
  children,
  size: propSize,
  offsetEdgeAmt: propOffsetEdgeAmt,
  skipStars = true,
  ...boxProps
}: Props) {
  const [twinkle, setTwinkle] = useState<'left' | 'right'>('left');
  const ref = useRef<HTMLDivElement | null>(null);

  const [isMainImageLoaded, setIsMainImageLoaded] = useState<boolean>(false);

  const containerNode = ref?.current || null;

  return <Box {...boxProps}>{children}</Box>;

  interface StarInfo {
    width: any;
    height: any;
    rotate: any;
    left?: any;
    right?: any;
    top?: any;
    bottom?: any;
  }

  function genStarInfo(rect: DOMRect, side: string): StarInfo {
    const offEdgeAmt = (size) => propOffsetEdgeAmt || size / 2.7;
    const getVariation = (n) => getRandomInt(0 - n, n);
    const isPortrait = rect.height > rect.width;

    switch (side) {
      case 'top-left': {
        const size = propSize || LARGE;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          left: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          top: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
      case 'middle-left': {
        const size = propSize || XL;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          left: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          bottom: `${Math.floor(rect.height / 2) - 75 + getVariation(-120)}px`,
        };
      }
      case 'bottom-left': {
        const size = propSize || LARGE;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          left: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          bottom: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
      case 'top-right': {
        const size = propSize || LARGE;
        return {
          width: [`${size / 2}px`, `${size}px`],
          height: [`${size / 2}px`, `${size}px`],
          rotate: Math.floor(Math.random() * 360),
          right: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          top: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
      case 'middle-right': {
        const size = propSize || XL;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          right: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          bottom: `${Math.floor(rect.height / 2) - 75 + getVariation(20)}px`,
        };
      }
      case 'bottom-right': {
        const size = propSize || LARGE;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          right: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
          bottom: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
      // Middles
      case 'bottom-middle': {
        const size = propSize || MEDIUM;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          left: `${Math.floor(rect.width / 2) - 50 + getVariation(isPortrait ? 20 : 40)}px`,
          bottom: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
      case 'top-middle': {
        const size = propSize || LARGE;
        return {
          width: { base: `${size / 2}px`, lg: `${size}px` },
          height: { base: `${size / 2}px`, lg: `${size}px` },
          rotate: Math.floor(Math.random() * 360),
          left: `${Math.floor(rect.width / 2) - 50 + getVariation(isPortrait ? 20 : 40)}px`,
          top: { base: `-${offEdgeAmt(size / 2)}px`, lg: `-${offEdgeAmt(size)}px` },
        };
      }
    }

    throw new Error('Side not configured');
  }

  const stars = useMemo(() => {
    return containerNode !== null && isMainImageLoaded
      ? _.times(SIDES.length, (n) => {
          const rect = containerNode.getBoundingClientRect();

          const size = propSize || (_.sample(STAR_SIZES) as number) + 50;
          const side = SIDES[n] as string;

          const starInfo = genStarInfo(rect, side);

          return {
            ...starInfo,
            size,
            side,
          };
        })
      : [];
  }, [containerNode, isMainImageLoaded, propSize]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTwinkle((v) => (v === 'left' ? 'right' : 'left'));
    }, 2000);
    return () => clearInterval(interval);
  }, [stars]);

  const twinkledStars = useMemo(
    () =>
      stars.map((starInfo) => ({
        ...starInfo,
        rotate: starInfo.rotate + (twinkle === 'left' ? -10 : 10),
      })),
    [stars, twinkle],
  );

  return skipStars ? (
    <Box {...boxProps}>{children}</Box>
  ) : (
    <Box
      {...boxProps}
      position="relative"
      width="fit-content"
      height="fit-content"
      ref={ref}
      onLoad={(e) => {
        const isMainImage =
          // @ts-ignore
          e.target?.parentNode?.dataset?.imageType === 'star' ? false : true;
        if (isMainImage) {
          setIsMainImageLoaded(true);
        }
      }}
    >
      {twinkledStars.map(({ rotate, side, width, height, ...starInfo }) => {
        return (
          <Box
            {...starInfo}
            width={width}
            height={height}
            key={side}
            overflow="visible"
            position="absolute"
            data-image-type="star"
            userSelect="none"
            pointerEvents="none"
            transform={`rotate(${rotate}deg)`}
            // color="brand.highlight"
            color={perfectlyImperfectColors.brand.ronald}
          >
            <StyledStar fill="currentColor" width={width} height={height} />
          </Box>
        );
      })}
      {children}
    </Box>
  );
}

export default StarContainer;
