import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
  $isNodeSelection,
  $getSelection,
  $getNodeByKey,
  KEY_BACKSPACE_COMMAND,
  COMMAND_PRIORITY_HIGH,
  KEY_DELETE_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useCallback, useEffect } from 'react';

export function useDelete(nodeKey: string) {
  const [editor] = useLexicalComposerContext();

  const isEditable = useLexicalEditable();

  const [isSelected] = useLexicalNodeSelection(nodeKey);

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        node?.remove();
      }
      return false;
    },
    [isSelected, nodeKey],
  );

  useEffect(() => {
    if (!editor || !isEditable) return;
    return mergeRegister(
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
    );
  }, [editor, isSelected, isEditable]);
}
