import { createFileRoute } from '@tanstack/react-router';
import AdminStatsView from './-AdminStats';

export const Route = createFileRoute('/admin/dashboard/stats/')({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminStatsView />;
}
