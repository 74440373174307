'use client';
import React from 'react';
import { Box, Heading, Stack, Text, useTheme } from '@chakra-ui/react';
import _ from 'lodash';
import GenericRecInfiniteScroll from 'shared/misc/components/by-type/recommendations/GenericRecInfiniteScroll';
import { FeedItemType, RecCategory } from 'shared/__generated__/graphql';
import { getRecQueryCategoryAtString } from 'shared/data/rec';
import GenericFeedInfiniteScrollView from 'shared/misc/components/by-type/feed/GenericFeedInfiniteScroll';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { redirect } from '@tanstack/react-router';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/endorsed/')({
  component: EndorsedView,
});

export default function EndorsedView() {
  const themeColors = useThemeColors();
  const strokeColor = themeColors.titleBorder;
  const auth = useAuth();

  if (!auth.user) {
    return redirect({
      to: '/not-found',
    });
  }

  return (
    <div className="flex justify-center p-[24px] mb-[100px]">
      <div className="space-y-[24px] w-full max-w-[800px] justify-center">
        <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
          LIKED BY YOU
        </h1>
        <div className="space-y-[12px] justify-center w-full">
          <GenericFeedInfiniteScrollView
            hideAllFeedContext
            originUserId={auth.user.id}
            onlyFollowing={false}
            types={[FeedItemType.NewRecEndorse, FeedItemType.NewPromptEndorse]}
          />
        </div>
      </div>
    </div>
  );
}
