import { Stack, HStack, Skeleton } from '@chakra-ui/react';
import React from 'react';

type Props = {
  size: 'small' | 'large';
};

function SkeletonSidebarFeedItem({ size }: Props) {
  const commonProps = {
    fadeDuration: 5,
    borderRadius: 0,
  };

  return size === 'small' ? (
    <div className="flex flex-row items-center w-full min-w-[100px] h-[32px] gap-[5px] animate-pulse">
      <div className="h-[24px] w-[24px] shrink-0 bg-gray-200 dark:bg-gray-700" />
      <div className="h-[14px] w-[75%] bg-gray-200 dark:bg-gray-700" />
    </div>
  ) : (
    <div className="flex flex-row items-center w-full min-w-[100px] h-[32px] gap-[5px] animate-pulse">
      <div className="h-[32px] w-[32px] shrink-0 bg-gray-200 dark:bg-gray-700" />
      <div className="flex flex-col flex-1 justify-center h-full gap-[4px]">
        <div className="h-[14px] w-[75%] bg-gray-200 dark:bg-gray-700" />
        <div className="h-[10px] w-[30%] bg-gray-200 dark:bg-gray-700" />
      </div>
    </div>
  );
}

export default SkeletonSidebarFeedItem;
