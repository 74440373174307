import { relayStylePagination } from '@apollo/client/utilities';

const getStrongKeyForArgs = (baseKey: string, variables: any, args: string[]) => {
  return args.reduce((p, arg) => {
    if (typeof variables[arg] != 'undefined') {
      const value = variables[arg];
      return p + `:(${arg}:${Array.isArray(value) ? value.join(',') : value})`;
    }
    return p;
  }, baseKey);
};

const getRecKeyArgs = (variables) => {
  return getStrongKeyForArgs('recConnection', variables, [
    'isEndorsedByViewer',
    'orderBy',
    'search',
    'includePromptRecs',
    'onlyGuests',
    'userId',
    'includeReRecsWithoutComments',
    'relatedToId',
    'excludeIds',
    'relatedToPromptId',
  ]);
};

const getFeedKeyArgs = (variables) => {
  return getStrongKeyForArgs('feedConnection', variables, [
    'showGlobalFeed',
    'originUserId',
    'category',
    'types',
    'search',
    'orderBy',
  ]);
};

const getConvoArgs = (variables) => {
  return getStrongKeyForArgs('conversationEventConnection', variables, [
    'id',
    'threadId',
    'first',
    'after',
    'sortBy',
  ]);
};

const getFeatureConnectionArgs = (variables) => {
  return getStrongKeyForArgs('featureConnection', variables, [
    'first',
    'after',
    'sortBy',
    'search',
    'relatedToId',
  ]);
};

const getCommentsKeyArgs = (variables) => {
  return getStrongKeyForArgs('commentsConnection', variables, ['targetRecId', 'enableReplies']);
};

export const typePolicies = {
  Query: {
    fields: {
      featureConnection: relayStylePagination(getFeatureConnectionArgs),
      userConnection: relayStylePagination(['search', ['query'], '@connection', ['key']]),
      notificationConnection: relayStylePagination(['@connection', ['key']]),
      fullFeedConnection: relayStylePagination(getFeedKeyArgs),
      viewMoreFeedConnection: relayStylePagination(getFeedKeyArgs),
      feedConnection: relayStylePagination(getFeedKeyArgs),
      everyoneFeedConnection: relayStylePagination(getFeedKeyArgs),
      everyoneSidebarFeedConnection: relayStylePagination(getFeedKeyArgs),
      profileFeedConnection: relayStylePagination(getFeedKeyArgs),
      recConnection: relayStylePagination(getRecKeyArgs),
      promptConnection: relayStylePagination(['@connection', ['key']]),
      listEntryConnection: relayStylePagination(['type', '@connection', ['key']]),
      conversationEventConnection: relayStylePagination(getConvoArgs),
      conversationConnection: relayStylePagination(['type', '@connection', ['key']]),
      articleConnection: relayStylePagination(['type', '@connection', ['key']]),
      commentsConnection: relayStylePagination(getCommentsKeyArgs),
      randomRecConnection: relayStylePagination(getFeedKeyArgs),
      homePage: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      me: {
        merge(existing, incoming) {
          return incoming;
        },
        read(existing) {
          return existing;
        },
      },
    },
  },
  User: {
    keyFields(object) {
      if (object.id) return ['id'];
      if (object.username) return ['username'];
      return null;
    },
    fields: {
      recConnection: relayStylePagination(['type', '@connection', ['key']]),
      followingConnection: relayStylePagination(['type', '@connection', ['key']]),
      followersConnection: relayStylePagination(['type', '@connection', ['key']]),
      links: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      preferences: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      values: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Rec: {
    fields: {
      commentsConnection: relayStylePagination(getCommentsKeyArgs),
    },
  },
};
