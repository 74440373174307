

import { Box, Button, Stack, Text, useToast } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { signUpAddPasswordSchema } from 'shared/validation/schemas';
import { gql } from 'shared/__generated__/gql';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { createFileRoute } from '@tanstack/react-router';

type Inputs = {
  password: string;
};

export const Route = createFileRoute('/sign-up/add-password/')({
  component: SignUpBasicInfoPage,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Sign Up',
      },
    ],
  }),
});

function SignUpBasicInfoPage() {
  const [setPassword, { loading }] = useMutation(SET_PASSWORD);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpAddPasswordSchema),
  });

  const { updateStep } = useUpdateSetupStep();

  const onSubmit: SubmitHandler<Inputs> = async (values, event) => {
    event?.preventDefault();

    await setPassword({
      variables: { password: values.password },
      onCompleted: async () => {
        await updateStep(USER_SIGN_UP_STEP.PREMIUM);
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  return (
    <CenterPage minH="100vh" axis="horizontal">
      <Box
        m="10px"
        marginTop={{ base: '50px', sm: '100px' }}
        border="1px dashed"
        borderColor="brand.main"
        p="48px"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} w="100%" maxW="400px">
            <PerfImage
              src="https://files.pi.fyi/blue-star.png"
              alt="star-blue"
              width="50"
              height="50"
            />
            <Text textStyle="brand.headingSm">ADD PASSWORD.</Text>
            <PerfInput
              placeholder="Password"
              type="password"
              {...register('password')}
              error={errors.password}
            />
            <Stack spacing={3}>
              <Button type="submit" isLoading={loading}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </CenterPage>
  );
}

const SET_PASSWORD = gql(/* GraphQL */ `
  mutation setPassword($password: String!) {
    setPassword(password: $password) {
      id
      signUpStep
    }
  }
`);

const UPDATE_PROFILE_MUTATION = gql(/* GraphQL */ `
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      ...SettingsView
    }
  }
`);
