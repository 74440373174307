import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { useQuery } from '@apollo/client';
import { ListContext } from 'shared/misc/providers/ListContext';
import ListColorBlock from 'web2/app/components/by-type/lists/list-color-block';
import { gql } from 'shared/__generated__/gql';
import { useForm } from 'react-hook-form';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { useBackButton } from 'shared/misc/hooks/useBackButton';
import AddRecommendationModal from 'web2/app/components/modals/add-recommendation-modal';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import BlurredPaywall from 'shared/misc/components/gates/BlurredPaywall';
import { isUserPremium, isContentArchived } from 'shared/gates';
import { ListEntries } from 'web2/app/components/by-type/lists/list-entries';
import InlineSubscribeToSignUp from 'shared/misc/components/InlineSubscribeToSignUp';
import NotFound from '../../components/not-found';

const LIST_VIEW_QUERY = gql(/* GraphQL */ `
  query getListView($id: String!) {
    list(id: $id) {
      id
      title
      intro
      introLexical
      color
      createdAt
      updatedAt
      isPremium
      ...ListColorBlock
    }
  }
`);

type Inputs = {
  orderBy: 'oldestFirst' | 'newestFirst';
};
export const Route = createFileRoute('/list/$listId')({
  component: RouteComponent,
});

function RouteComponent() {
  const { watch, control } = useForm<Inputs>({ defaultValues: { orderBy: 'newestFirst' } });
  const { listId: id } = Route.useParams();

  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery(LIST_VIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
    variables: {
      id,
    },
  });

  const { getCanGoBack, goBack } = useBackButton();

  const addRecModalState = useDisclosure();
  const auth = useAuth();
  const router = useRouter();

  useAutoSetTheme(data?.list?.user?.profileTheme || ProfileThemeType.Default);

  const list = useMemo(() => data?.list, [data?.list]);

  if (loading && !list) {
    return null;
  }

  if (!data || !data.list) {
    return <NotFound />;
  }

  const isArchivedFeature = list?.feature && isContentArchived(list.feature.date);

  const isPremium = list?.isPremium;

  const isUserSignedInAndPremium = !auth.user || !isUserPremium(auth.user);

  const showPaywall = (isArchivedFeature || isPremium) && isUserSignedInAndPremium;

  return (
    <ListContext.Provider value={list}>
      <div className="flex justify-center p-[32px] pb-[250px]">
        <div className="flex flex-col gap-[24px] w-[800px]">
          <div className="flex flex-col gap-[24px] justify-center items-center">
            <ListColorBlock isCentered list={list} showUpdatedAt isTitleLink />
            <div className="flex flex-col gap-[24px] relative w-full">
              {showPaywall && <BlurredPaywall />}
              {list.feature && <InlineSubscribeToSignUp />}
              {list.feature?.thumbnailFile?.file && (
                <PerfImageFromFile
                  priority
                  quality={50}
                  file={list.feature.thumbnailFile?.file}
                  alt="banner"
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              )}
              <ListEntries listId={id} />
            </div>
          </div>
        </div>
        {addRecModalState.isOpen && (
          <AddRecommendationModal modalState={addRecModalState} redirectOnCreate={false} />
        )}
      </div>
    </ListContext.Provider>
  );
}
