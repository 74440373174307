import { useCallback, useEffect, useRef } from 'react';
import { Button, Flex, Tooltip, Box, Text, useToast } from '@chakra-ui/react';

import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { Modal, ModalContent, ModalTitle } from '../ui/modal';

import { Button as TWButton } from '../button';
import usePrevious from 'use-previous';
import _ from 'lodash';
import { useRouter, useRouterState } from '@tanstack/react-router';
import { useDropzone } from 'react-dropzone-esm';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import useRecDraftAutoSave from '../by-type/recommendations/hooks/use-rec-draft-auto-save';
import useRecommendationForm, {
  RecFormArgs,
  RecFormHandle,
  RecFormInputs,
} from '../by-type/recommendations/use-rec-form';
import {
  ConversationCoreFragment,
  PromptItemFragment,
  RecDraftAutoSaveFragment,
  RecommendationItemFragment,
  UserCoreFragment,
} from 'shared/__generated__/graphql';
import { isInWebView, safeSendMessageToReactNativeFromWebView } from 'shared/webview/index';
import { AddRecommendationMessages } from 'shared/webview/AddRecommendationMessages';
import useRecPhoto from '../by-type/recommendations/hooks/use-rec-photo';
import { safeParse } from 'shared/utils';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';

interface BaseDraftRecFields {
  title: string;
  url?: string | null;
  content: string;
  emoji?: string | null;
}

type Props = {
  modalState?: ReturnType<typeof useDisclosure>;
  repostRec?: RecommendationItemFragment;
  draft?: RecDraftAutoSaveFragment;
  targetPrompt?: PromptItemFragment;
  targetUser?: UserCoreFragment;
  targetConversation?: ConversationCoreFragment;
  complexConvoRecipients?: {
    userIds: string[];
    conversationIds: string[];
  };
  redirectOnCreate?: boolean;
  defaultValues?: RecFormArgs['defaultValues'];
  hideCloseButton?: boolean;
};

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

export default function AddRecommendationModal({
  draft,
  modalState: modalStateOveride,
  repostRec,
  targetPrompt,
  targetUser,
  targetConversation,
  complexConvoRecipients,
  defaultValues,
  hideCloseButton,
  redirectOnCreate = true,
}: Props) {
  const { isOpen, onClose, onOpen } =
    modalStateOveride || useModalState(ModalNames.ADD_RECOMMENDATION);
  const { trackEvent } = useAnalytics();

  const pathname = usePathname();

  const IS_IN_WEB_VIEW = isInWebView();
  const RENDER_OUTSIDE_MODAL = IS_IN_WEB_VIEW && pathname === '/mobile/add-recommendation';

  const formRef = useRef<RecFormHandle>(null);

  const {
    draftRec,
    onCreateRecFromDraft,
    onSaveDraftDebounced,
    onDeleteRecDraft,
    onCreateRecDraft,
    isSubmittingCreateRec,
  } = useRecDraftAutoSave({
    formRef,
    warnOnUnload: !repostRec && !targetPrompt,
    draftRecId: draft?.id,
  });

  const initialIsOpenRef = useRef(undefined);
  const prevIsOpen = usePrevious(modalStateOveride?.isOpen ? false : isOpen);
  const router = useRouter();

  const createRecFromDraft = async (values: RecFormInputs) => {
    try {
      const res = await onCreateRecFromDraft(
        values,
        repostRec || targetPrompt || targetUser || complexConvoRecipients || targetConversation
          ? { repostRec, targetPrompt, targetUser, targetConversation, complexConvoRecipients }
          : undefined,
      );

      if (!res?.data) {
        throw new Error('Failed to create rec from draft');
      }

      if (redirectOnCreate) {
        if (isInWebView()) {
          if ('startConversationWithRec' in res.data) {
            safeSendMessageToReactNativeFromWebView(
              AddRecommendationMessages.SUCCESSFULLY_SHARED_REC_TO_CONVO,
              res?.data?.startConversationWithRec,
            );
          } else if ('createRecFromRecDraft' in res.data) {
            safeSendMessageToReactNativeFromWebView(
              AddRecommendationMessages.SUCCESSFULLY_CREATED_REC,
              res?.data?.createRecFromRecDraft?.id,
            );
          }
        } else if ('createRecFromRecDraft' in res.data) {
          router.navigate({
            to: `/rec/$recId`,
            params: { recId: res?.data?.createRecFromRecDraft.id },
          });
        }

        if (!isInWebView()) {
          toast({
            title: 'Recommendation shared',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      }
      onClose();
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.error(e);
      // Sentry.captureException(e, { extra: { values } });
    }
  };

  // TODO Check if there are unsaved changes before saving.
  useEffect(() => {
    if (
      ((!prevIsOpen && isOpen) || (modalStateOveride?.isOpen && IS_IN_WEB_VIEW)) &&
      !draftRec?.id
    ) {
      onCreateRecDraft().then((draft) => {
        const draftRecId = draft?.id;
        // Hydrate form if updating.
        if (prevIsOpen && !isOpen && draftRecId && isDraftEmpty(draftRec)) {
          onDeleteRecDraft();
        }
      });
    }
  }, [isOpen, prevIsOpen, draftRec]);

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      trackEvent('Post', 'Rec_Started');
    }
  }, [isOpen, prevIsOpen]);

  const hasTarget =
    !!targetPrompt ||
    !!repostRec ||
    !!targetUser ||
    !!targetConversation ||
    !!complexConvoRecipients;

  const deleteIfEmptyAndClose = (
    forceDelete: boolean = !!hasTarget,
    confirmToast: boolean = false,
  ) => {
    if ((draftRec && isDraftEmpty(draftRec)) || forceDelete) {
      onDeleteRecDraft(confirmToast);
    }

    onClose();
  };

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  const { onUpload, onRemoveAttachment, attachments: uploadedAttachments, reset } = useRecPhoto();

  const attachments = draftRec?.attachments?.length
    ? draftRec.attachments
    : uploadedAttachments?.length
    ? uploadedAttachments
    : [];

  const onDrop = useCallback(
    async (files) => {
      if (files?.length > 5 || (attachments.length + files.length) > 5) {
        toast({
          title: 'Only 5 attachments per rec',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      else if (files.length && draftRec) {
        for (const file of files) {
          await onUpload(file, { draftRecId: draftRec?.id });
        }
      }
    },
    [draftRec],
  );  

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 5,
    multiple: true,
    noClick: true,
    onError: () => {
      toast({
        title: 'An error occured...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const initialValues = draft || defaultValues;

  const { onSubmit, mainFormNode, editImageNode, isImageUploaded } = useRecommendationForm({
    draftRecId: draftRec?.id,
    editorHeight: '250px',
    maxBodyEditorHeight: hasTarget ? '300px' : '500px',
    onSubmit: createRecFromDraft,
    onChange: onSaveDraftDebounced,
    titlePlaceholder: 'What do you want to post?',
    ref: formRef,
    isSubmitting: isSubmittingCreateRec,
    defaultValues: {
      title: initialValues?.title || '',
      url: initialValues?.url || '',
      emoji: initialValues?.emoji || '😃',
      contentLexicalState: initialValues?.contentLexical
        ? safeParse(initialValues?.contentLexical)
        : '',
    },
    requireContent: !!repostRec,
    disableTitle: !!repostRec,
    hideEmoji: !!repostRec,
    hideLink: !!repostRec,
    attachments: attachments || [],
    onUpload,
    onOpenFilePicker: () => {
      if (draftRec) {
        if (IS_IN_WEB_VIEW) {
          safeSendMessageToReactNativeFromWebView(
            AddRecommendationMessages.OPEN_IMAGE_PICKER,
            draftRec?.id,
          );
        } else {
          open();
        }
      }
    },
    moveImages: () => {
      if (!draftRec) return;
      if (IS_IN_WEB_VIEW) {
        safeSendMessageToReactNativeFromWebView(
          AddRecommendationMessages.MOVE_IMAGES,
          draftRec?.id,
        );
      }
    },
    onRemoveAttachment: (attachmentId) => {
      reset();
      if (draftRec) {
        onRemoveAttachment({ attachmentId, draftRecId: draftRec?.id });
      }
    },
  });

  const toast = useToast();

  const getSubmitText = () => {
    if (repostRec) {
      return 'Share Re-Rec';
    } else if (targetPrompt) {
      return 'Share response';
    } else if (targetUser) {
      return `Share with @${targetUser.username}`;
    } else if (targetConversation) {
      return 'Share with friends';
    } else if (complexConvoRecipients) {
      return `Share with friend${
        (complexConvoRecipients.conversationIds.length ||
          0 + complexConvoRecipients.userIds.length ||
          0) > 1
          ? 's'
          : ''
      }`;
    }
    return 'Share';
  };

  const containerProps = {
    maxH: { base: '100dvh', md: '90%' },
    // minHeight={IS_IN_WEB_VIEW ? '100dvh' : undefined}
    height: { base: '100dvh', md: repostRec ? '600px' : '800px' },
    maxW: '100%',
    width: { base: '100vw', md: '60vw' },
    ml: modalMLR,
    mr: modalMLR,
    mb: modalMTB,
    mt: modalMTB,
    cursor: isDragActive ? 'pointer' : undefined,
    p: { base: '12px', md: '24px', lg: '48px' },
    paddingTop: IS_IN_WEB_VIEW ? '48px' : undefined,
    border: IS_IN_WEB_VIEW ? '0px' : undefined,
  };

  const innerContent = (
    <>
      <Box h="100%" p="6px" mb={{ base: '12px', md: '24px' }} fontSize="16px" overflowY="scroll">
        {repostRec && (
          <Text
            padding="0px"
            mb="24px"
            mt="12px"
            border="0px"
            fontSize="24px"
            opacity={0.2}
            mr={{ md: '24px' }}
            textAlign="center"
            textTransform="uppercase"
          >
            <em>
              Re-Rec {repostRec.emoji} {_.truncate(repostRec.title, { length: 150 })}
            </em>
          </Text>
        )}
        {targetPrompt && (
          <Text
            padding="0px"
            mb="24px"
            mt="12px"
            border="0px"
            fontSize="24px"
            opacity={0.2}
            mr={{ md: '24px' }}
            textAlign="center"
            textTransform="uppercase"
          >
            <em>
              Responding to {targetPrompt.emoji} {_.truncate(targetPrompt.title, { length: 150 })}
            </em>
          </Text>
        )}
        {mainFormNode}
        <input {...getInputProps()} />
      </Box>
      <Box
        {...(IS_IN_WEB_VIEW ? { top: 0 } : { bottom: 0 })}
        right="0px"
        width="100%"
        position="absolute"
        p={{ base: IS_IN_WEB_VIEW ? '8px 12px 12px' : '12px', md: '24px', lg: '48px' }}
      >
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <div className="flex px-6">
            {IS_IN_WEB_VIEW ? (
              <Button
                size="sm"
                onClick={async () => {
                  deleteIfEmptyAndClose(true);
                }}
                variant="textOnly"
                color="brand.lightgrey"
                _hover={{ color: 'brand.red' }}
                border="none"
                textStyle="brand.actionSm"
              >
                Cancel
              </Button>
            ) : (
              <Box />
            )}
            {/* {editImageNode} */}
          </div>

          <div className={`flex gap-2 items-center ${IS_IN_WEB_VIEW ? 'p-[12px]' : 'p-[32px]'}`}>
            {!isDraftEmpty(draftRec) && !hasTarget && !IS_IN_WEB_VIEW && (
              <Tooltip label="Deletes the draft...forever.">
                <TWButton
                  size="sm"
                  onClick={async () => {
                    deleteIfEmptyAndClose(true, true);
                  }}
                  variant="textOnly"
                  className="text-[13.5px] typography-action-sm text-brand-lightgrey hover:text-brand-red border-none"
                >
                  Close and delete
                </TWButton>
              </Tooltip>
            )}
            {!hasTarget && (
              <Tooltip label="You can view your drafts from the sidebar.">
                <TWButton
                  onClick={onClose}
                  variant="secondary"
                  className="text-[13.5px] typography-action-sm"
                >
                  Keep draft
                </TWButton>
              </Tooltip>
            )}
            <TWButton
              onClick={onSubmit}
              variant="primary"
              className={`text-[13.5px] typography-action-sm ${!isImageUploaded ? 'opacity-50' : ''}`}
              isDisabled={isSubmittingCreateRec || !isImageUploaded}
              isLoading={isSubmittingCreateRec}
            >
              {getSubmitText()}
            </TWButton>
          </div>
        </Flex>
      </Box>
    </>
  );

  return (
    <>
      <Modal
        open={isOpen}
        onOpenChange={(o) => {
          if (o) {
            onOpen();
          } else {
            deleteIfEmptyAndClose();
          }
        }}
      >
        <ModalTitle>Add Recommendation</ModalTitle>
        {!RENDER_OUTSIDE_MODAL && (
          <ModalContent
            suppressHydrationWarning
            className={`${
              IS_IN_WEB_VIEW ? 'border-0 pt-[48px] md:pt-[72px] lg:pt-[124px]' : 'sm:min-h-[500px] pb-[58px]'
            }`}
            {...getRootProps()}
            {...containerProps}
            dontRenderOverlay={IS_IN_WEB_VIEW}
          >
            {innerContent}
          </ModalContent>
        )}
      </Modal>
      {RENDER_OUTSIDE_MODAL && (
        <Box {...getRootProps()} {...containerProps} className="px-8">
          {innerContent}
        </Box>
      )}
    </>
  );
}

function isDraftEmpty(draft?: BaseDraftRecFields | null) {
  return !draft || ['content', 'url', 'title'].every((key) => !draft[key]);
}
