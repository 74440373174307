import { createFileRoute, useRouter } from '@tanstack/react-router';
import { z } from 'zod';
import { Box, Flex, Heading, Input, Stack, Text, Textarea } from '@chakra-ui/react';
import Logo from 'shared/misc/components/logo-horizontal.svg?react';
import { PI_CLASSIC_COLORS } from 'shared/misc/theme/custom/custom-theme-colors';
import emailPreferenceOptions from 'shared/misc/emailPreferenceOptions';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { EmailListType } from 'shared/__generated__/graphql';
import { gql } from 'shared/__generated__/gql';
import { useState } from 'react';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { Button as TWButton } from '../../../../components/button';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

export const Route = createFileRoute('/unsubscribe/email-List/survey/')({
  component: RouteComponent,
  validateSearch: z.object({
    key: z.string().optional(),
  }),
});

const ADD_EMAIL_TYPES = gql(/* GraphQL */ `
  mutation addEmailTypes1($emailTypes: [EmailListType!]!, $key: String) {
    addEmailTypes(emailTypes: $emailTypes, key: $key) {
      emailLists
      id
    }
  }
`);

enum SURVEY_RESPONSES {
  CONTENT_NOT_RELEVANT = 'Content Not Relevant',
  EMAILS_TOO_FREQUENT = 'Emails are too frequent',
  DONT_LIKE_GUESTS = "Doesn't Like the guests",
  BUGS_OR_UNRESPONSIVE = 'Buggy or unresponsive',
}

type SurveyResponseType = {
  [SURVEY_RESPONSES.CONTENT_NOT_RELEVANT]: boolean;
  [SURVEY_RESPONSES.EMAILS_TOO_FREQUENT]: boolean;
  [SURVEY_RESPONSES.DONT_LIKE_GUESTS]: boolean;
  [SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE]: boolean;
};

function RouteComponent() {
  const { emailList } = Route.useParams();
  const { key } = Route.useSearch();

  const emailTypes = emailList || EmailListType.WeeklyContentDigest;
  const isMd = useBreakpoint('md');
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const auth = useAuth();
  const [reasonsSelected, setSelectedReasons] = useState<SurveyResponseType>({
    [SURVEY_RESPONSES.CONTENT_NOT_RELEVANT]: false,
    [SURVEY_RESPONSES.EMAILS_TOO_FREQUENT]: false,
    [SURVEY_RESPONSES.DONT_LIKE_GUESTS]: false,
    [SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE]: false,
  });

  const [extraFeedback, setExtraFeedback] = useState<string | null>(null);
  const [addEmailTypes] = useMutationWithAutoToast(ADD_EMAIL_TYPES, {
    variables: { emailTypes, key },
    refetchQueries: ['getEmailPreferences'],
  });

  const handleSelected = (e) => {
    setSelectedReasons((prev) => ({
      ...prev,
      [e.target.id]: !prev[e.target.id],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    trackEvent('Click', 'Submit_mail_list_unsubscribe_Form', { ...reasonsSelected, extraFeedback });
    router.navigate({ to: '/' });
  };
  return (
    <Flex
      justify="center"
      width="100vw"
      height="100vh"
      padding="24px"
      backgroundImage="url('https://files.pi.fyi/star-background.png')"
      backgroundColor={PI_CLASSIC_COLORS.background}
    >
      <Stack
        spacing="12px"
        marginTop={{ base: '24px', sm: '58px' }}
        align="center"
        maxWidth="750px"
        w="100%"
      >
        <Flex justifyContent="center" alignItems="center" color="brand.white">
          <Logo height={isMd ? 60 : 45} width={isMd ? 500 : 400} fill="currentColor" />
        </Flex>
        <Flex
          backgroundColor={PI_CLASSIC_COLORS.highlight}
          padding="25px"
          flex-direction="column"
          w="100%"
        >
          <Stack align="center" m="auto">
            <Heading variant="L" color="black">
              You've Unsubscribed
            </Heading>
            <Text
              className="text-main"
              textAlign="center"
              color="black"
              paddingBottom="10px"
              fontSize="16px"
            >
              You won't recieve anymore emails from this mailing list.
            </Text>
            <TWButton variant="primary">Manage Email Preferences</TWButton>
            <Heading variant="L" paddingTop="20px" fontSize="16px" color="black">
              Unsubscribed by accident?
            </Heading>
            <TWButton
              variant="secondary"
              fontSize="18px"
              onClick={async () => {
                await addEmailTypes();
                trackEvent('Click', 'Resubscribe_TWButton_mail_list_unsubscribe_form');
                router.navigate({ to: '/' });
              }}
            >
              Subscribe Again
            </TWButton>
          </Stack>
        </Flex>
        <Stack backgroundColor="black" w="100%" padding="20px">
          <Text className="text-main" color="white">
            We'd love to know why you unsubscribed.
          </Text>
          <Stack>
            <Box
              w="75%"
              cursor="pointer"
              as="label"
              htmlFor={SURVEY_RESPONSES.CONTENT_NOT_RELEVANT}
              padding="5px"
              color={
                reasonsSelected[SURVEY_RESPONSES.CONTENT_NOT_RELEVANT]
                  ? PI_CLASSIC_COLORS.highlight
                  : 'gray'
              }
            >
              <Text className="text-main">It's not you it's me</Text>
              <Input
                name={SURVEY_RESPONSES.CONTENT_NOT_RELEVANT}
                id={SURVEY_RESPONSES.CONTENT_NOT_RELEVANT}
                hidden
                type="checkbox"
                checked={reasonsSelected[SURVEY_RESPONSES.CONTENT_NOT_RELEVANT]}
                onChange={handleSelected}
              />
            </Box>
            <Box
              w="75%"
              cursor="pointer"
              as="label"
              htmlFor={SURVEY_RESPONSES.EMAILS_TOO_FREQUENT}
              padding="5px"
              color={
                reasonsSelected[SURVEY_RESPONSES.EMAILS_TOO_FREQUENT]
                  ? PI_CLASSIC_COLORS.highlight
                  : 'gray'
              }
            >
              <Text className="text-main">You send too many emails</Text>
              <Input
                name={SURVEY_RESPONSES.EMAILS_TOO_FREQUENT}
                id={SURVEY_RESPONSES.EMAILS_TOO_FREQUENT}
                hidden
                type="checkbox"
                checked={reasonsSelected[SURVEY_RESPONSES.EMAILS_TOO_FREQUENT]}
                onChange={handleSelected}
              />
            </Box>

            <Box
              w="75%"
              cursor="pointer"
              as="label"
              htmlFor={SURVEY_RESPONSES.DONT_LIKE_GUESTS}
              padding="5px"
              color={
                reasonsSelected[SURVEY_RESPONSES.DONT_LIKE_GUESTS]
                  ? PI_CLASSIC_COLORS.highlight
                  : 'gray'
              }
            >
              <Text className="text-main">I don't like the guests</Text>
              <Input
                name={SURVEY_RESPONSES.DONT_LIKE_GUESTS}
                id={SURVEY_RESPONSES.DONT_LIKE_GUESTS}
                hidden
                type="checkbox"
                checked={reasonsSelected[SURVEY_RESPONSES.DONT_LIKE_GUESTS]}
                onChange={handleSelected}
              />
            </Box>

            <Box
              w="75%"
              as="label"
              cursor="pointer"
              htmlFor={SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE}
              padding="5px"
              color={
                reasonsSelected[SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE]
                  ? PI_CLASSIC_COLORS.highlight
                  : 'gray'
              }
            >
              <Text className="text-main">Bugginess or formatting</Text>
              <Input
                name={SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE}
                id={SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE}
                hidden
                type="checkbox"
                checked={reasonsSelected[SURVEY_RESPONSES.BUGS_OR_UNRESPONSIVE]}
                onChange={handleSelected}
              />
            </Box>
            <Box paddingY="10px" fontSize="14px">
              <Text className="text-main" color="white" paddingBottom="10px">
                Any other feedback?
              </Text>
              <Textarea
                name="Extra Feedback"
                onChange={(e) => setExtraFeedback(e.target.value)}
                fontSize="14px"
                textTransform="none"
                padding="10px"
              ></Textarea>
            </Box>
          </Stack>
          <Stack direction="row">
            <TWButton variant="primary" onClick={handleSubmit}>
              Submit
            </TWButton>
            <TWButton
              color="white"
              variant="textOnly"
              my="auto"
              onClick={async () => {
                await trackEvent('Click', 'Unsubscribe_Survey_Skip', {
                  user: auth.user?.email,
                });
                router.navigate({ to: '/' });
              }}
            >
              Skip
            </TWButton>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
}
