import { useQuery } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { useCallback } from 'react';
import { gql } from 'shared/__generated__';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { InView } from 'react-intersection-observer';
import Spinner from 'web2/app/components/spinner';

import { useConvoActions } from 'shared/misc/hooks/useConvoHooksWeb';

import { UserAvatar } from '../../components/by-type/user/user-avatar';
import { getRelativeDateString } from 'shared/dates';
import { useState } from 'react';
import UserAvatarStack from '../../components/by-type/user/user-avatar-stack';
import { TWLink } from '../../components/link';
import { motion } from 'framer-motion';
import { useConvoEventHelpers } from 'shared/misc/hooks/useConvoHooks';
import {
  ConversationEventItemFragment,
  ConversationEventType,
  ConvosPageItemFragment,
} from 'shared/__generated__/graphql';
import { getUserStringForMultipleUsers } from 'shared/multipleUsersUtils';
import { cn } from '../../utils/cn';

export const Route = createFileRoute('/convos/')({
  component: RouteComponent,
});

const PAGE_LENGTH = 20;

gql(/* GraphQL */ `
  fragment ConvosPageItem on Conversation {
    ...ConversationItem
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getWebConversations($first: Int, $after: String) {
    conversationConnection(first: $first, after: $after, includeEmpty: false) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationItem
        }
      }
    }
  }
`);

function RouteComponent() {
  const auth = useAuth<true>();

  const { data, error, loading, refetch, fetchMore, networkStatus } = useQuery(PageQuery, {
    variables: { first: PAGE_LENGTH },
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
    notifyOnNetworkStatusChange: true,
  });

  const getNextPage = useCallback(async () => {
    if (!data?.conversationConnection.pageInfo.hasNextPage || loading) return;
    return await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.conversationConnection.pageInfo.endCursor,
      },
    });
  }, [data, fetchMore]);

  return (
    <div className="flex justify-center p-[24px] mb-[100px]">
      <div className="flex flex-col gap-[48px] w-full max-w-[700px] justify-center">
        <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
          Convos
        </h1>
        <div className="flex flex-col gap-[48px]">
          {data?.conversationConnection.edges.map(({ node }) => (
            <ConvoPageItem key={node.id} convo={node} />
          ))}
          <InView
            as="div"
            rootMargin="500px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
          {loading ? <Spinner placement="center" /> : null}
        </div>
      </div>
    </div>
  );
}

export function ConvoPageItem({ convo }: { convo: ConvosPageItemFragment }) {
  const { menuNode } = useConvoActions({ convoId: convo.id });

  const [recentEvent1, recentEvent2] = convo.recentMessageEvents;

  const hasUnseenEvents = convo.unseenEventsCount > 0;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex justify-between">
        <div className="flex flex-row gap-[12px] items-center">
          <UserAvatarStack users={convo.users.map(({ user }) => user)} />
          <TWLink
            to={`/convo/${convo.id}`}
            variant="stylizedNoDefaultBorder"
            className="typography-action-sm sm:typography-heading-sm"
          >
            {/* {convo.users.map((u) => `@${u.user.username}`).join(' + ')} */}
            {convo.name || getUserStringForMultipleUsers(convo.users.map(({ user }) => user))}
          </TWLink>
        </div>
        <div className="flex flex-row gap-[12px] items-center">
          {hasUnseenEvents ? (
            <TWLink
              to={`/convo/${convo.id}`}
              variant="stylizedNoDefaultBorder"
              textStyle="brand.headingSm"
            >
              <div className="flex flex-row gap-[6px]">
                <p className="typography-action-sm sm:typography-heading-sm text-brand-highlight">
                  📧
                </p>
                <p className="typography-action-sm sm:typography-heading-sm text-brand-highlight">
                  {convo.unseenEventsCount} new
                </p>
              </div>
            </TWLink>
          ) : null}
          {menuNode}
        </div>
      </div>
      <motion.div
        style={{ position: 'relative' }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        {recentEvent1 ? (
          <motion.div
            initial={{ y: 0, x: 0 }}
            animate={
              recentEvent2
                ? { y: isHovered ? -3 : 0 }
                : { x: isHovered ? 6 : 0, y: isHovered ? -6 : 0 }
            }
            transition={{ duration: 0.15 }}
          >
            <TWLink to={`/convo/${convo.id}`} variant="stylizedNoDefaultBorder">
              <div
                className={cn(
                  'flex flex-col bg-brand-background gap-[12px] p-[24px] border-[1px] border-dashed w-full cursor-pointer',
                  recentEvent2 ? 'mt-[6px]' : undefined,
                  hasUnseenEvents ? 'border-brand-highlight' : 'border-brand-main',
                )}
              >
                <ConvoEventPreviewItem event={recentEvent1} />
              </div>
            </TWLink>
          </motion.div>
        ) : null}
        {recentEvent2 ? (
          <motion.div
            initial={{ y: -6, x: 6 }}
            animate={{ y: isHovered ? -12 : -6, x: isHovered ? 12 : 6 }}
            transition={{ duration: 0.15 }}
            style={{
              position: 'absolute',
              top: '4px',
              left: 0,
              width: '100%',
              zIndex: 5,
            }}
          >
            <TWLink to={`/convo/${convo.id}`} variant="stylizedNoDefaultBorder">
              <div
                className={cn(
                  'flex flex-col bg-brand-background gap-[12px] p-[24px] border-[1px] border-dashed cursor-pointer',
                  hasUnseenEvents ? 'border-brand-highlight' : 'border-brand-main',
                )}
              >
                <ConvoEventPreviewItem event={recentEvent2} />
              </div>
            </TWLink>
          </motion.div>
        ) : null}
      </motion.div>
    </div>
  );
}

function ConvoEventPreviewItem({ event }: { event: ConversationEventItemFragment }) {
  const auth = useAuth<true>();

  const helpers = useConvoEventHelpers();

  return (
    <div className="flex flex-row gap-[6px] items-center opacity-60">
      <UserAvatar user={event.originUser} avatarSize={25} />
      <p className="typography-action-sm whitespace-nowrap overflow-hidden text-ellipsis line-clamp-1">
        {getRelativeDateString(new Date(event.createdAt))} -{' '}
        {getConvoFeedItemMessage(event, auth.user.id)} "{helpers.getOneLine(event, false)}"
      </p>
    </div>
  );
}

export function getConvoFeedItemMessage(event: ConversationEventItemFragment, authUserId: string) {
  switch (event.type) {
    case ConversationEventType.NewMessageRecShare:
      return `${authUserId === event.originUser?.id ? 'You' : ``} shared`;

    case ConversationEventType.NewMessageReply:
      return `${authUserId === event.originUser?.id ? 'You' : ``} replied`;

    case ConversationEventType.NewMessage:
      return `${authUserId === event.originUser?.id ? 'You' : ``} sent`;

    default:
      return '';
  }
}
