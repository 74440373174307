import {
  Box,
  Flex,
  HStack,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { Button as TWButton } from '../../components/button';
import { useEffect, useMemo, useRef } from 'react';
import * as _ from 'lodash';
import { gql } from 'shared/__generated__/gql';
import { useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  GetSearchViewQueryVariables,
  OrderBy,
  ProfileThemeType,
  RecCategory,
} from 'shared/__generated__/graphql';
import { InView } from 'react-intersection-observer';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { useRouterState, createFileRoute, useRouter } from '@tanstack/react-router';
import { isInWebView } from 'shared/webview';
import {
  REC_CATEGORY,
  getRecQueryCategoryAsEmoji,
  getRecQueryCategoryAtString,
} from 'shared/data/rec';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import FeedItem from '../../components/by-type/feed/feed-item';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { isInScrollData } from '../../utils/scrolling';
import { useAuth } from 'shared/misc/hooks/useAuth';

export const Route = createFileRoute('/search/')({
  component: Search,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Search',
      },
    ],
  }),
});

const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};

const SearchViewQuery = gql(/* GraphQL */ `
  query getSearchView($first: Int, $after: String, $search: String, $category: RecCategory) {
    feedConnection(
      first: $first
      after: $after
      search: $search
      category: $category
      types: [ADD_RECOMMENDATION, NEW_PROMPT_REC_REPLY, NEW_RE_POST_WITH_CONTENT]
      showGlobalFeed: true
    ) @connection(key: "searchFeed") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FullFeedItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 20;
const FIRST_PAGE_LENGTH = 10;

type Inputs = {
  orderBy: OrderBy;
  query: string | null;
  category: RecCategory | null;
};

const IS_IN_WEB_VIEW = isInWebView();

function Search() {
  const searchParams = useSearchParams();
  const q_orderBy = searchParams.get('orderBy');
  const q_category = searchParams.get('category');
  const q_query = searchParams.get('query');

  const { watch, control, register } = useForm<Inputs>({
    defaultValues: {
      orderBy: (q_orderBy as OrderBy) || OrderBy.NewestFirst,
      query: q_query || null,
      category: (q_category as RecCategory) || null,
    },
  });

  const values = watch();
  const router = useRouter();
  const { orderBy, query, category } = values;

  const getVariablesFromValues = () => {
    const nextVariables: GetSearchViewQueryVariables = { orderBy, search: null };

    if (category || query) {
      nextVariables.search = q_query || query;
      nextVariables.category = q_category || category;
    }

    return nextVariables;
  };

  const initialVariables = useRef({
    orderBy,
    first: FIRST_PAGE_LENGTH,
    ...getVariablesFromValues(),
  });

  const auth = useAuth();

  useAutoSetTheme(auth.user?.profileTheme || ProfileThemeType.Default);

  const isInHistory = isInScrollData();

  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    refetch,
    loading,
  } = useQuery(SearchViewQuery, {
    fetchPolicy: isInScrollData() ? 'cache-first' : 'cache-and-network',
    variables: {
      ...initialVariables.current,
    },
    // notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useMemo(() => {
    return _.debounce((nextVariables: GetSearchViewQueryVariables) => {
      refetch(nextVariables);
      const searchParams: Record<string, string> = {};

      if (nextVariables?.category) searchParams.category = nextVariables.category;
      if (nextVariables?.search) searchParams.query = nextVariables.search;

      router.navigate({
        to: `/search`,
        search: searchParams,
        replace: true,
      });
    }, 1000);
  }, [refetch]);

  useEffect(() => {
    if (
      (variables?.orderBy || null) !== values.orderBy ||
      (variables?.search?.category || null) !== values.category ||
      (variables?.search?.query || null) !== values.query
    ) {
      debouncedRefetch({
        orderBy,
        first: FIRST_PAGE_LENGTH,
        after: null,
        ...getVariablesFromValues(),
      });
    }
  }, [values.category, values.query]);

  const getNextPage = async () => {
    if (loading || !data?.feedConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        ...getVariablesFromValues(),
        first: PAGE_LENGTH,
        after: data?.feedConnection.pageInfo.endCursor,
      },
    });
  };

  const menuItemActiveProps = {
    color: 'blue',
  };

  const breakpoint = useBreakpoint();
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const getMenuItemActivePropsForCategory = (c) => (c === category ? menuItemActiveProps : {});

  const getMenuItemActivePropsForOrderBy = (o) => (o === orderBy ? menuItemActiveProps : {});

  const getOrderByInText = (o) => {
    switch (o) {
      case OrderBy.Top: {
        return 'Top';
      }
      case OrderBy.NewestFirst: {
        return 'New';
      }
      case OrderBy.OldestFirst: {
        return 'Old';
      }
    }
  };

  const feedItemEdges = data?.feedConnection.edges;

  const addAskModalState = useModalState(ModalNames.ADD_ASK);

  const filtersNode = (
    <>
      <Controller
        control={control}
        name="category"
        render={({ field: { ref, onChange } }) => {
          return (
            <Flex
              width="100%"
              maxWidth="700px"
              alignSelf="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Box alignSelf="center" m="6px" mt="0px">
                <TWButton
                  onClick={() => onChange(null)}
                  variant="secondaryBlack"
                  border="1px dashed"
                  borderColor={!category ? 'brand.highlight' : 'brand.main'}
                  color={!category ? 'brand.highlight' : 'brand.main'}
                  textAlign="center"
                  alignSelf="center"
                  width="fit-content"
                  _hover={{
                    border: '1px dashed',
                    borderColor: 'brand.highlight',
                    color: 'brand.highlight',
                  }}
                >
                  <Text textStyle="brand.headingSm" textTransform="uppercase">
                    ⭐ All
                  </Text>
                </TWButton>
              </Box>
              {[
                REC_CATEGORY.MUSIC,
                REC_CATEGORY.MOVIES,
                REC_CATEGORY.BOOKS,
                REC_CATEGORY.RESTAURANTS,
                REC_CATEGORY.CLOTHING,
                REC_CATEGORY.FOOD_AND_DRINK,
                REC_CATEGORY.TV,
                REC_CATEGORY.VIDEOS,
                REC_CATEGORY.PLACES,
                REC_CATEGORY.MISC,
              ].map((c) => {
                const title = getRecQueryCategoryAtString(c.toUpperCase() as RecCategory);
                const e = getRecQueryCategoryAsEmoji(c);
                return (
                  <Box alignSelf="center" key={c} m="6px" mt="0px">
                    <TWButton
                      ref={ref}
                      onClick={() => onChange(c as RecCategory)}
                      variant="secondaryBlack"
                      border="1px dashed"
                      borderColor={category === c ? 'brand.highlight' : 'brand.main'}
                      color={category === c ? 'brand.highlight' : 'brand.main'}
                      textAlign="center"
                      alignSelf="center"
                      width="fit-content"
                      _hover={{
                        border: '1px dashed',
                        borderColor: 'brand.highlight',
                        color: 'brand.highlight',
                      }}
                    >
                      <Text textStyle="brand.headingSm" textTransform="uppercase">
                        {e} {title}
                      </Text>
                    </TWButton>
                  </Box>
                );
              })}
            </Flex>
          );
        }}
      />
    </>
  );

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <div className="flex flex-col gap-[12px] justify-center w-[900px] max-w-full">
        {!IS_IN_WEB_VIEW && (
          <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px] pb-[24px]">
            BROWSE {category ? getRecQueryCategoryAtString(category) : 'All'}
          </h1>
        )}
        <div className="flex flex-col gap-[12px]">
          <HStack spacing="24px" alignItems="center" justifyContent="center" width="100%">
            <Box width="100%" maxWidth="400px" className="pb-[12px]">
              <PerfInput
                placeholder={`Search (e.g. books, @username, new york, or emojis "🎵")`}
                {...register('query')}
                rightNode={loading ? <Spinner /> : null}
              />
            </Box>
          </HStack>
          {filtersNode}
          <TWButton
            onClick={addAskModalState.onOpen}
            variant="link"
            className="mt-[12px] mb-[12px] typography-body-sm self-center"
          >
            Can't find what you're looking for? Try making an ask
          </TWButton>
        </div>
        {feedItemEdges && feedItemEdges?.length === 0 && (
          <Text textStyle="brand.headingSm" textAlign="center">
            No recommendations found.
          </Text>
        )}

        <SimpleGrid className="flex flex-col gap-[12px]">
          <>
            {feedItemEdges &&
              feedItemEdges.length > 0 &&
              feedItemEdges?.map(({ node }) => (
                <FeedItem key={node.id} feedItem={node} hideAllFeedContext />
              ))}
            {!data && loading && (
              <>
                <SkeletonFeedItem />
                <SkeletonFeedItem />
                <SkeletonFeedItem />
              </>
            )}
          </>
          <InView
            as="div"
            rootMargin="300px 0px"
            onChange={(inView) => {
              if (inView && !loading) {
                getNextPage();
              }
            }}
          />
        </SimpleGrid>
      </div>
    </Box>
  );
}
