import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Link, createFileRoute } from '@tanstack/react-router';
import { createServerFn } from '@tanstack/react-start';
import prisma from 'database';
import Logo from 'shared/misc/components/logo-horizontal.svg?react';
import ArrowDown from 'shared/misc/components/sidebar/arrow-down.svg?react';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import InlineSubscribeToSignUp from 'shared/misc/components/InlineSubscribeToSignUp';
import StarGutter from 'shared/misc/components/StarGutter';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { PI_CLASSIC_COLORS } from 'shared/misc/theme/custom/custom-theme-colors';
import { genMeta } from '../../../utils/meta';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

export const generateMetadata = createServerFn().handler(async ({ data }) => {
  const invite = await prisma.userInvite.findFirst({
    where: {
      OR: [
        {
          code: data.referralId,
        },
        {
          user: {
            username: {
              equals: data.referralId,
            },
          },
        },
      ],
    },
    include: {
      user: true,
    },
  });
  return invite;
});

export const Route = createFileRoute('/subscribe/referral/$referralId')({
  component: RouteComponent,
  head: async ({ loaderData }) => {
    const invite = loaderData;

    if (!invite) {
      return genMeta({
        title: `SIGN UP FOR PI.FYI`,
        image: 'https://files.pi.fyi/cdn-cgi/image/quality=20,format=auto/pi-image-for-social.png',
      });
    }

    return genMeta({
      title: `YOU'RE INVITED!`,
      description: invite!.contactName
        ? `HI, ${invite!.contactName.toUpperCase()}! YOU'RE INVITED TO PI.FYI.`
        : 'SIGN UP FOR PI.FYI',
      image: 'https://files.pi.fyi/cdn-cgi/image/quality=20,format=auto/pi-image-for-social.png',
    });
  },
  loader: async ({ params }) => {
    const invite = await generateMetadata({ data: { referralId: params.referralId } });
    return { invite };
  },
});

function RouteComponent() {
  useAutoSetTheme(ProfileThemeType.PiClassic);
  const { invite } = Route.useLoaderData();

  const guests = `Olivia Rodrigo, Francis Ford Coppola, Charli XCX, Ayo Edebiri, Michael Imperioli,
                  Emma Chamberlain, Clairo, Molly Ringwald, Weyes Blood, Jeremy O. Harris, Conner
                  O'Malley, Rayne Fisher Quann, Julian Casablancas, Alexa Chung, Mac Demarco, Moses
                  Sumney, Omar Apollo, Suki Waterhouse, Narwuar, The Dare`
    .split(',')
    .map((s) => s.trim());

  // const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: true });

  const breakpoint = useBreakpoint();
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const logoHeight = isMobile ? 40 : 60;
  const logoWidth = isMobile ? 300 : 400;

  const arrowHeight = 75;
  const arrowWidth = 38;

  console.log({ breakpoint });

  return (
    <div
      // className="w-full h-screen"
      style={{ backgroundImage: "url('https://files.pi.fyi/star-background.png')" }}
    >
      <div className="flex justify-center p-[12px]">
        <div className="flex flex-col items-center gap-[12px] w-full max-w-[600px]">
          <div className="text-brand-main mb-6 pt-[48px]">
            <Logo width={logoWidth} height={logoHeight} fill="currentColor" />
          </div>
          {!(/* isMobile */ false) ? (
            <div className="typography-heading-sm mb-6 max-w-full sm:max-w-md text-center">
              Your friend{' '}
              <span className="text-brand-title-background">@{invite?.user.username}</span> must
              have good taste if they're inviting you to PI...
            </div>
          ) : null}
          <div className="text-brand-heading-lg text-4xl sm:text-6xl leading-none text-brand-highlight text-center">
            A TASTE OF SOMEONE’S TASTE
          </div>
          <div className="text-brand-main">
            <ArrowDown width={arrowWidth} height={arrowHeight} />
          </div>
          <div className="flex flex-col items-center gap-[12px] mt-6 w-full">
            {
              /* isMobile */ false ? (
                <div className="typography-heading-sm mb-6 max-w-full sm:max-w-md text-center">
                  Your friend{' '}
                  <span className="text-brand-title-background">@{invite?.user.username}</span> must
                  have good taste if they're inviting you to join...
                </div>
              ) : null
            }
            <div className="flex flex-col gap-[12px] mt-3 w-full sm:w-md border border-dashed border-brand-main p-4 md:p-6">
              <InlineSubscribeToSignUp
                onlyInput
                redirectTo="/sign-up/more"
                submitText="join"
                inviteCode={invite?.code}
                autoOpenModalVersion={false}
                withAutoFocus
              />
              <div className="typography-body-sm text-brand-lightgrey">
                By clicking Join, you agree to our{' '}
                <a href="/terms" className="border-b border-brand-lightgrey">
                  Terms
                </a>{' '}
                and{' '}
                <a href="/terms" className="border-b border-brand-lightgrey">
                  Privacy
                </a>{' '}
                conditions.
              </div>
            </div>

            <div className="flex flex-col gap-[24px] mt-12 pb-32">
              <div className="typography-heading-lg leading-6 mb-6 text-center text-brand-title-background">
                Wondering what this is?
              </div>
              <div className="typography-heading-sm leading-6 mb-6 text-center">
                PI.FYI features recommendations from cool people all over the world like{' '}
                <span className="text-brand-title-background">@{invite?.user.username}</span>
                {', '}
                and hundreds of <span className="text-brand-title-background">
                  special guests
                </span>{' '}
                like...
              </div>

              {guests.map((guest) => (
                <span
                  className="typography-heading-lg leading-6 text-center text-brand-title-background"
                  key={guest}
                >
                  {guest}
                </span>
              ))}
              <span className="typography-heading-lg leading-6 text-center text-brand-main">
                and many, many, more.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
