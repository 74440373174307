import { createFileRoute, redirect } from '@tanstack/react-router';
import { createServerFn } from '@tanstack/react-start';
import prisma from 'database';

const getTodaysArticle = createServerFn({ method: 'GET' }).handler(async () => {
  const article = await prisma.article.findFirstOrThrow({
    where: { publishedAt: { not: null }, urlSlug: { not: null } },
    orderBy: { publishedAt: 'desc' },
  });
  return article;
});

export const Route = createFileRoute('/today/')({
  component: RouteComponent,
  loader: async () => {
    const article = await getTodaysArticle();
    throw redirect({ to: `/p/${article.urlSlug}` });
  },
});

function RouteComponent() {
  return null;
}
