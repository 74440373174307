import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

type ToastOptions = {
  successMessage?: string;
  onCompleted?: (() => void) | (() => Promise<void>);
  errorMessage?: string;
  onError?: () => void;
  shouldToastOnError?: boolean;
};

type UseMutationWithAutoToast = typeof useMutation extends (a: infer AU, b: infer BU) => infer R
  ? (a: AU, b?: BU & ToastOptions) => R
  : never;

const useMutationWithAutoToast: UseMutationWithAutoToast = (
  mutation,
  {
    successMessage,
    errorMessage,
    shouldToastOnError = true,
    onCompleted,
    onError,
    ...options
  } = {},
) => {
  const toast = useToast();
  return useMutation(mutation, {
    ...options,
    onCompleted: async (...args) => {
      toast({
        title: successMessage || 'Success',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      if (onCompleted) onCompleted(...args);
    },
    onError: (...args) => {
      if (shouldToastOnError) {
        toast({
          title: errorMessage || 'Something went wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      if (onError) onError(...args);
    },
  });
};

export default useMutationWithAutoToast;
