import * as React from "react"
import * as HoverCardPrimitive from "@radix-ui/react-hover-card"
import { Root as HoverCardRoot, HoverCardProps } from "@radix-ui/react-hover-card"

import { cn } from '../../utils/cn';

const HoverCard: React.FC<HoverCardProps> = (props) => (
  <HoverCardRoot closeDelay={100} openDelay={150} {...props} />
);

const HoverCardTrigger = HoverCardPrimitive.Trigger

const HoverCardPortal = HoverCardPrimitive.Portal;

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <HoverCardPortal>
    <HoverCardPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-[999999999999] w-64 bg-brand-background border border-dashed border-brand-main outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className,
      )}
      {...props}
    />
  </HoverCardPortal>
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName

export { HoverCard, HoverCardTrigger, HoverCardContent }
