/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignUpImport } from './routes/sign-up'
import { Route as AdminImport } from './routes/admin'
import { Route as AboutImport } from './routes/about'
import { Route as IndexImport } from './routes/index'
import { Route as WhatIsThisIndexImport } from './routes/what-is-this/index'
import { Route as WelcomeIndexImport } from './routes/welcome/index'
import { Route as VerifyAnotherEmailIndexImport } from './routes/verify-another-email/index'
import { Route as UnlockedArchiveIndexImport } from './routes/unlocked-archive/index'
import { Route as TodayIndexImport } from './routes/today/index'
import { Route as TestIndexImport } from './routes/test/index'
import { Route as TermsIndexImport } from './routes/terms/index'
import { Route as Sxsw2025IndexImport } from './routes/sxsw-2025/index'
import { Route as SpotlightApplyIndexImport } from './routes/spotlight-apply/index'
import { Route as SignUpIndexImport } from './routes/sign-up/index'
import { Route as SignOutIndexImport } from './routes/sign-out/index'
import { Route as SignInIndexImport } from './routes/sign-in/index'
import { Route as SettingsIndexImport } from './routes/settings/index'
import { Route as SearchIndexImport } from './routes/search/index'
import { Route as RecsIndexImport } from './routes/recs/index'
import { Route as ReadIndexImport } from './routes/read/index'
import { Route as RandomIndexImport } from './routes/random/index'
import { Route as PrivacyIndexImport } from './routes/privacy/index'
import { Route as NotificationsIndexImport } from './routes/notifications/index'
import { Route as LinkInBioIndexImport } from './routes/link-in-bio/index'
import { Route as InvitesIndexImport } from './routes/invites/index'
import { Route as FeaturesIndexImport } from './routes/features/index'
import { Route as EndorsedIndexImport } from './routes/endorsed/index'
import { Route as DraftsIndexImport } from './routes/drafts/index'
import { Route as DownloadIndexImport } from './routes/download/index'
import { Route as DirectoryIndexImport } from './routes/directory/index'
import { Route as CookiesIndexImport } from './routes/cookies/index'
import { Route as ConvosIndexImport } from './routes/convos/index'
import { Route as CategoriesIndexImport } from './routes/categories/index'
import { Route as CareersIndexImport } from './routes/careers/index'
import { Route as BookmarksIndexImport } from './routes/bookmarks/index'
import { Route as AsksIndexImport } from './routes/asks/index'
import { Route as AppStoreIndexImport } from './routes/app-store/index'
import { Route as AdminIndexImport } from './routes/admin/index'
import { Route as TKeyImport } from './routes/t/$key'
import { Route as RedeemBonusIdImport } from './routes/redeem/$bonusId'
import { Route as RecRecIdImport } from './routes/rec/$recId'
import { Route as PArticleSlugOrIdImport } from './routes/p/$articleSlugOrId'
import { Route as ListListIdImport } from './routes/list/$listId'
import { Route as LUrlImport } from './routes/l/$url'
import { Route as ConvoConvoIdImport } from './routes/convo/$convoId'
import { Route as AskPromptIdImport } from './routes/ask/$promptId'
import { Route as ActivateActivationKeyImport } from './routes/activate/$activationKey'
import { Route as WelcomeCodeIndexImport } from './routes/welcome/$code/index'
import { Route as UUsernameIndexImport } from './routes/u/$username/index'
import { Route as StripeSuccessIndexImport } from './routes/stripe/success/index'
import { Route as StripeCancelIndexImport } from './routes/stripe/cancel/index'
import { Route as SignUpVerifyIndexImport } from './routes/sign-up/verify/index'
import { Route as SignUpVerifyCompleteIndexImport } from './routes/sign-up/verify-complete/index'
import { Route as SignUpTrialIndexImport } from './routes/sign-up/trial/index'
import { Route as SignUpThemeIndexImport } from './routes/sign-up/theme/index'
import { Route as SignUpSurveyIndexImport } from './routes/sign-up/survey/index'
import { Route as SignUpShareIndexImport } from './routes/sign-up/share/index'
import { Route as SignUpPremiumIndexImport } from './routes/sign-up/premium/index'
import { Route as SignUpPhotoIndexImport } from './routes/sign-up/photo/index'
import { Route as SignUpPersonalizingIndexImport } from './routes/sign-up/personalizing/index'
import { Route as SignUpMoreIndexImport } from './routes/sign-up/more/index'
import { Route as SignUpLocationIndexImport } from './routes/sign-up/location/index'
import { Route as SignUpLikeRecsIndexImport } from './routes/sign-up/like-recs/index'
import { Route as SignUpInviteCodeIndexImport } from './routes/sign-up/invite-code/index'
import { Route as SignUpFollowIndexImport } from './routes/sign-up/follow/index'
import { Route as SignUpFeaturesIndexImport } from './routes/sign-up/features/index'
import { Route as SignUpDoneIndexImport } from './routes/sign-up/done/index'
import { Route as SignUpCategoriesIndexImport } from './routes/sign-up/categories/index'
import { Route as SignUpAppIndexImport } from './routes/sign-up/app/index'
import { Route as SignUpAddRecsIndexImport } from './routes/sign-up/add-recs/index'
import { Route as SignUpAddPasswordIndexImport } from './routes/sign-up/add-password/index'
import { Route as SignUpAddEmailIndexImport } from './routes/sign-up/add-email/index'
import { Route as SettingsPremiumIndexImport } from './routes/settings/premium/index'
import { Route as MobileUpdateRecommendationIndexImport } from './routes/mobile/update-recommendation/index'
import { Route as MobileUpdatePromptIndexImport } from './routes/mobile/update-prompt/index'
import { Route as MobileAddRecommendationIndexImport } from './routes/mobile/add-recommendation/index'
import { Route as MobileAddPromptIndexImport } from './routes/mobile/add-prompt/index'
import { Route as MobileAddCommentIndexImport } from './routes/mobile/add-comment/index'
import { Route as HomeTabIndexImport } from './routes/home/$tab/index'
import { Route as FeaturesRecsIndexImport } from './routes/features/recs/index'
import { Route as CategoriesNameIndexImport } from './routes/categories/$name/index'
import { Route as AdminSubstackIndexImport } from './routes/admin/substack/index'
import { Route as AdminStaffIndexImport } from './routes/admin/staff/index'
import { Route as AdminDashboardIndexImport } from './routes/admin/dashboard/index'
import { Route as UnsubscribeEmailListEmailListImport } from './routes/unsubscribe/email-List/$emailList'
import { Route as SubscribeReferralReferralIdImport } from './routes/subscribe/referral/$referralId'
import { Route as SignUpInviteCodeImport } from './routes/sign-up/invite/$code'
import { Route as AuthEmailCallbackIdImport } from './routes/auth/email-callback/$id'
import { Route as UnsubscribeEmailListSurveyIndexImport } from './routes/unsubscribe/email-List/survey/index'
import { Route as UUsernameRecsIndexImport } from './routes/u/$username/recs/index'
import { Route as UUsernameListsIndexImport } from './routes/u/$username/lists/index'
import { Route as UUsernameLatestAskIndexImport } from './routes/u/$username/latest-ask/index'
import { Route as UUsernameFollowingIndexImport } from './routes/u/$username/following/index'
import { Route as UUsernameFollowersIndexImport } from './routes/u/$username/followers/index'
import { Route as UUsernameAsksIndexImport } from './routes/u/$username/asks/index'
import { Route as SettingsManageEmailIndexImport } from './routes/settings/manage/email/index'
import { Route as SettingsManageBlocksIndexImport } from './routes/settings/manage/blocks/index'
import { Route as AdminEmailSubscribersIndexImport } from './routes/admin/email/subscribers/index'
import { Route as AdminEmailBlastsIndexImport } from './routes/admin/email/blasts/index'
import { Route as AdminDocumentsDraftsIndexImport } from './routes/admin/documents/drafts/index'
import { Route as AdminDashboardUsersIndexImport } from './routes/admin/dashboard/users/index'
import { Route as AdminDashboardStatsIndexImport } from './routes/admin/dashboard/stats/index'
import { Route as AdminDashboardMonthlyArchivalPicksIndexImport } from './routes/admin/dashboard/monthly-archival-picks/index'
import { Route as AdminDocumentsComposeDocumentIdImport } from './routes/admin/documents/compose/$documentId'

// Create/Update Routes

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const AboutRoute = AboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WhatIsThisIndexRoute = WhatIsThisIndexImport.update({
  id: '/what-is-this/',
  path: '/what-is-this/',
  getParentRoute: () => rootRoute,
} as any)

const WelcomeIndexRoute = WelcomeIndexImport.update({
  id: '/welcome/',
  path: '/welcome/',
  getParentRoute: () => rootRoute,
} as any)

const VerifyAnotherEmailIndexRoute = VerifyAnotherEmailIndexImport.update({
  id: '/verify-another-email/',
  path: '/verify-another-email/',
  getParentRoute: () => rootRoute,
} as any)

const UnlockedArchiveIndexRoute = UnlockedArchiveIndexImport.update({
  id: '/unlocked-archive/',
  path: '/unlocked-archive/',
  getParentRoute: () => rootRoute,
} as any)

const TodayIndexRoute = TodayIndexImport.update({
  id: '/today/',
  path: '/today/',
  getParentRoute: () => rootRoute,
} as any)

const TestIndexRoute = TestIndexImport.update({
  id: '/test/',
  path: '/test/',
  getParentRoute: () => rootRoute,
} as any)

const TermsIndexRoute = TermsIndexImport.update({
  id: '/terms/',
  path: '/terms/',
  getParentRoute: () => rootRoute,
} as any)

const Sxsw2025IndexRoute = Sxsw2025IndexImport.update({
  id: '/sxsw-2025/',
  path: '/sxsw-2025/',
  getParentRoute: () => rootRoute,
} as any)

const SpotlightApplyIndexRoute = SpotlightApplyIndexImport.update({
  id: '/spotlight-apply/',
  path: '/spotlight-apply/',
  getParentRoute: () => rootRoute,
} as any)

const SignUpIndexRoute = SignUpIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignOutIndexRoute = SignOutIndexImport.update({
  id: '/sign-out/',
  path: '/sign-out/',
  getParentRoute: () => rootRoute,
} as any)

const SignInIndexRoute = SignInIndexImport.update({
  id: '/sign-in/',
  path: '/sign-in/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsIndexRoute = SettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => rootRoute,
} as any)

const SearchIndexRoute = SearchIndexImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => rootRoute,
} as any)

const RecsIndexRoute = RecsIndexImport.update({
  id: '/recs/',
  path: '/recs/',
  getParentRoute: () => rootRoute,
} as any)

const ReadIndexRoute = ReadIndexImport.update({
  id: '/read/',
  path: '/read/',
  getParentRoute: () => rootRoute,
} as any)

const RandomIndexRoute = RandomIndexImport.update({
  id: '/random/',
  path: '/random/',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyIndexRoute = PrivacyIndexImport.update({
  id: '/privacy/',
  path: '/privacy/',
  getParentRoute: () => rootRoute,
} as any)

const NotificationsIndexRoute = NotificationsIndexImport.update({
  id: '/notifications/',
  path: '/notifications/',
  getParentRoute: () => rootRoute,
} as any)

const LinkInBioIndexRoute = LinkInBioIndexImport.update({
  id: '/link-in-bio/',
  path: '/link-in-bio/',
  getParentRoute: () => rootRoute,
} as any)

const InvitesIndexRoute = InvitesIndexImport.update({
  id: '/invites/',
  path: '/invites/',
  getParentRoute: () => rootRoute,
} as any)

const FeaturesIndexRoute = FeaturesIndexImport.update({
  id: '/features/',
  path: '/features/',
  getParentRoute: () => rootRoute,
} as any)

const EndorsedIndexRoute = EndorsedIndexImport.update({
  id: '/endorsed/',
  path: '/endorsed/',
  getParentRoute: () => rootRoute,
} as any)

const DraftsIndexRoute = DraftsIndexImport.update({
  id: '/drafts/',
  path: '/drafts/',
  getParentRoute: () => rootRoute,
} as any)

const DownloadIndexRoute = DownloadIndexImport.update({
  id: '/download/',
  path: '/download/',
  getParentRoute: () => rootRoute,
} as any)

const DirectoryIndexRoute = DirectoryIndexImport.update({
  id: '/directory/',
  path: '/directory/',
  getParentRoute: () => rootRoute,
} as any)

const CookiesIndexRoute = CookiesIndexImport.update({
  id: '/cookies/',
  path: '/cookies/',
  getParentRoute: () => rootRoute,
} as any)

const ConvosIndexRoute = ConvosIndexImport.update({
  id: '/convos/',
  path: '/convos/',
  getParentRoute: () => rootRoute,
} as any)

const CategoriesIndexRoute = CategoriesIndexImport.update({
  id: '/categories/',
  path: '/categories/',
  getParentRoute: () => rootRoute,
} as any)

const CareersIndexRoute = CareersIndexImport.update({
  id: '/careers/',
  path: '/careers/',
  getParentRoute: () => rootRoute,
} as any)

const BookmarksIndexRoute = BookmarksIndexImport.update({
  id: '/bookmarks/',
  path: '/bookmarks/',
  getParentRoute: () => rootRoute,
} as any)

const AsksIndexRoute = AsksIndexImport.update({
  id: '/asks/',
  path: '/asks/',
  getParentRoute: () => rootRoute,
} as any)

const AppStoreIndexRoute = AppStoreIndexImport.update({
  id: '/app-store/',
  path: '/app-store/',
  getParentRoute: () => rootRoute,
} as any)

const AdminIndexRoute = AdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminRoute,
} as any)

const TKeyRoute = TKeyImport.update({
  id: '/t/$key',
  path: '/t/$key',
  getParentRoute: () => rootRoute,
} as any)

const RedeemBonusIdRoute = RedeemBonusIdImport.update({
  id: '/redeem/$bonusId',
  path: '/redeem/$bonusId',
  getParentRoute: () => rootRoute,
} as any)

const RecRecIdRoute = RecRecIdImport.update({
  id: '/rec/$recId',
  path: '/rec/$recId',
  getParentRoute: () => rootRoute,
} as any)

const PArticleSlugOrIdRoute = PArticleSlugOrIdImport.update({
  id: '/p/$articleSlugOrId',
  path: '/p/$articleSlugOrId',
  getParentRoute: () => rootRoute,
} as any)

const ListListIdRoute = ListListIdImport.update({
  id: '/list/$listId',
  path: '/list/$listId',
  getParentRoute: () => rootRoute,
} as any)

const LUrlRoute = LUrlImport.update({
  id: '/l/$url',
  path: '/l/$url',
  getParentRoute: () => rootRoute,
} as any)

const ConvoConvoIdRoute = ConvoConvoIdImport.update({
  id: '/convo/$convoId',
  path: '/convo/$convoId',
  getParentRoute: () => rootRoute,
} as any)

const AskPromptIdRoute = AskPromptIdImport.update({
  id: '/ask/$promptId',
  path: '/ask/$promptId',
  getParentRoute: () => rootRoute,
} as any)

const ActivateActivationKeyRoute = ActivateActivationKeyImport.update({
  id: '/activate/$activationKey',
  path: '/activate/$activationKey',
  getParentRoute: () => rootRoute,
} as any)

const WelcomeCodeIndexRoute = WelcomeCodeIndexImport.update({
  id: '/welcome/$code/',
  path: '/welcome/$code/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameIndexRoute = UUsernameIndexImport.update({
  id: '/u/$username/',
  path: '/u/$username/',
  getParentRoute: () => rootRoute,
} as any)

const StripeSuccessIndexRoute = StripeSuccessIndexImport.update({
  id: '/stripe/success/',
  path: '/stripe/success/',
  getParentRoute: () => rootRoute,
} as any)

const StripeCancelIndexRoute = StripeCancelIndexImport.update({
  id: '/stripe/cancel/',
  path: '/stripe/cancel/',
  getParentRoute: () => rootRoute,
} as any)

const SignUpVerifyIndexRoute = SignUpVerifyIndexImport.update({
  id: '/verify/',
  path: '/verify/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpVerifyCompleteIndexRoute = SignUpVerifyCompleteIndexImport.update({
  id: '/verify-complete/',
  path: '/verify-complete/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpTrialIndexRoute = SignUpTrialIndexImport.update({
  id: '/trial/',
  path: '/trial/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpThemeIndexRoute = SignUpThemeIndexImport.update({
  id: '/theme/',
  path: '/theme/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpSurveyIndexRoute = SignUpSurveyIndexImport.update({
  id: '/survey/',
  path: '/survey/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpShareIndexRoute = SignUpShareIndexImport.update({
  id: '/share/',
  path: '/share/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpPremiumIndexRoute = SignUpPremiumIndexImport.update({
  id: '/premium/',
  path: '/premium/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpPhotoIndexRoute = SignUpPhotoIndexImport.update({
  id: '/photo/',
  path: '/photo/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpPersonalizingIndexRoute = SignUpPersonalizingIndexImport.update({
  id: '/personalizing/',
  path: '/personalizing/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpMoreIndexRoute = SignUpMoreIndexImport.update({
  id: '/more/',
  path: '/more/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpLocationIndexRoute = SignUpLocationIndexImport.update({
  id: '/location/',
  path: '/location/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpLikeRecsIndexRoute = SignUpLikeRecsIndexImport.update({
  id: '/like-recs/',
  path: '/like-recs/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpInviteCodeIndexRoute = SignUpInviteCodeIndexImport.update({
  id: '/invite-code/',
  path: '/invite-code/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpFollowIndexRoute = SignUpFollowIndexImport.update({
  id: '/follow/',
  path: '/follow/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpFeaturesIndexRoute = SignUpFeaturesIndexImport.update({
  id: '/features/',
  path: '/features/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpDoneIndexRoute = SignUpDoneIndexImport.update({
  id: '/done/',
  path: '/done/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpCategoriesIndexRoute = SignUpCategoriesIndexImport.update({
  id: '/categories/',
  path: '/categories/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpAppIndexRoute = SignUpAppIndexImport.update({
  id: '/app/',
  path: '/app/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpAddRecsIndexRoute = SignUpAddRecsIndexImport.update({
  id: '/add-recs/',
  path: '/add-recs/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpAddPasswordIndexRoute = SignUpAddPasswordIndexImport.update({
  id: '/add-password/',
  path: '/add-password/',
  getParentRoute: () => SignUpRoute,
} as any)

const SignUpAddEmailIndexRoute = SignUpAddEmailIndexImport.update({
  id: '/add-email/',
  path: '/add-email/',
  getParentRoute: () => SignUpRoute,
} as any)

const SettingsPremiumIndexRoute = SettingsPremiumIndexImport.update({
  id: '/settings/premium/',
  path: '/settings/premium/',
  getParentRoute: () => rootRoute,
} as any)

const MobileUpdateRecommendationIndexRoute =
  MobileUpdateRecommendationIndexImport.update({
    id: '/mobile/update-recommendation/',
    path: '/mobile/update-recommendation/',
    getParentRoute: () => rootRoute,
  } as any)

const MobileUpdatePromptIndexRoute = MobileUpdatePromptIndexImport.update({
  id: '/mobile/update-prompt/',
  path: '/mobile/update-prompt/',
  getParentRoute: () => rootRoute,
} as any)

const MobileAddRecommendationIndexRoute =
  MobileAddRecommendationIndexImport.update({
    id: '/mobile/add-recommendation/',
    path: '/mobile/add-recommendation/',
    getParentRoute: () => rootRoute,
  } as any)

const MobileAddPromptIndexRoute = MobileAddPromptIndexImport.update({
  id: '/mobile/add-prompt/',
  path: '/mobile/add-prompt/',
  getParentRoute: () => rootRoute,
} as any)

const MobileAddCommentIndexRoute = MobileAddCommentIndexImport.update({
  id: '/mobile/add-comment/',
  path: '/mobile/add-comment/',
  getParentRoute: () => rootRoute,
} as any)

const HomeTabIndexRoute = HomeTabIndexImport.update({
  id: '/home/$tab/',
  path: '/home/$tab/',
  getParentRoute: () => rootRoute,
} as any)

const FeaturesRecsIndexRoute = FeaturesRecsIndexImport.update({
  id: '/features/recs/',
  path: '/features/recs/',
  getParentRoute: () => rootRoute,
} as any)

const CategoriesNameIndexRoute = CategoriesNameIndexImport.update({
  id: '/categories/$name/',
  path: '/categories/$name/',
  getParentRoute: () => rootRoute,
} as any)

const AdminSubstackIndexRoute = AdminSubstackIndexImport.update({
  id: '/substack/',
  path: '/substack/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminStaffIndexRoute = AdminStaffIndexImport.update({
  id: '/staff/',
  path: '/staff/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminDashboardIndexRoute = AdminDashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => AdminRoute,
} as any)

const UnsubscribeEmailListEmailListRoute =
  UnsubscribeEmailListEmailListImport.update({
    id: '/unsubscribe/email-List/$emailList',
    path: '/unsubscribe/email-List/$emailList',
    getParentRoute: () => rootRoute,
  } as any)

const SubscribeReferralReferralIdRoute =
  SubscribeReferralReferralIdImport.update({
    id: '/subscribe/referral/$referralId',
    path: '/subscribe/referral/$referralId',
    getParentRoute: () => rootRoute,
  } as any)

const SignUpInviteCodeRoute = SignUpInviteCodeImport.update({
  id: '/invite/$code',
  path: '/invite/$code',
  getParentRoute: () => SignUpRoute,
} as any)

const AuthEmailCallbackIdRoute = AuthEmailCallbackIdImport.update({
  id: '/auth/email-callback/$id',
  path: '/auth/email-callback/$id',
  getParentRoute: () => rootRoute,
} as any)

const UnsubscribeEmailListSurveyIndexRoute =
  UnsubscribeEmailListSurveyIndexImport.update({
    id: '/unsubscribe/email-List/survey/',
    path: '/unsubscribe/email-List/survey/',
    getParentRoute: () => rootRoute,
  } as any)

const UUsernameRecsIndexRoute = UUsernameRecsIndexImport.update({
  id: '/u/$username/recs/',
  path: '/u/$username/recs/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameListsIndexRoute = UUsernameListsIndexImport.update({
  id: '/u/$username/lists/',
  path: '/u/$username/lists/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameLatestAskIndexRoute = UUsernameLatestAskIndexImport.update({
  id: '/u/$username/latest-ask/',
  path: '/u/$username/latest-ask/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameFollowingIndexRoute = UUsernameFollowingIndexImport.update({
  id: '/u/$username/following/',
  path: '/u/$username/following/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameFollowersIndexRoute = UUsernameFollowersIndexImport.update({
  id: '/u/$username/followers/',
  path: '/u/$username/followers/',
  getParentRoute: () => rootRoute,
} as any)

const UUsernameAsksIndexRoute = UUsernameAsksIndexImport.update({
  id: '/u/$username/asks/',
  path: '/u/$username/asks/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsManageEmailIndexRoute = SettingsManageEmailIndexImport.update({
  id: '/settings/manage/email/',
  path: '/settings/manage/email/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsManageBlocksIndexRoute = SettingsManageBlocksIndexImport.update({
  id: '/settings/manage/blocks/',
  path: '/settings/manage/blocks/',
  getParentRoute: () => rootRoute,
} as any)

const AdminEmailSubscribersIndexRoute = AdminEmailSubscribersIndexImport.update(
  {
    id: '/email/subscribers/',
    path: '/email/subscribers/',
    getParentRoute: () => AdminRoute,
  } as any,
)

const AdminEmailBlastsIndexRoute = AdminEmailBlastsIndexImport.update({
  id: '/email/blasts/',
  path: '/email/blasts/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminDocumentsDraftsIndexRoute = AdminDocumentsDraftsIndexImport.update({
  id: '/documents/drafts/',
  path: '/documents/drafts/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminDashboardUsersIndexRoute = AdminDashboardUsersIndexImport.update({
  id: '/dashboard/users/',
  path: '/dashboard/users/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminDashboardStatsIndexRoute = AdminDashboardStatsIndexImport.update({
  id: '/dashboard/stats/',
  path: '/dashboard/stats/',
  getParentRoute: () => AdminRoute,
} as any)

const AdminDashboardMonthlyArchivalPicksIndexRoute =
  AdminDashboardMonthlyArchivalPicksIndexImport.update({
    id: '/dashboard/monthly-archival-picks/',
    path: '/dashboard/monthly-archival-picks/',
    getParentRoute: () => AdminRoute,
  } as any)

const AdminDocumentsComposeDocumentIdRoute =
  AdminDocumentsComposeDocumentIdImport.update({
    id: '/documents/compose/$documentId',
    path: '/documents/compose/$documentId',
    getParentRoute: () => AdminRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/activate/$activationKey': {
      id: '/activate/$activationKey'
      path: '/activate/$activationKey'
      fullPath: '/activate/$activationKey'
      preLoaderRoute: typeof ActivateActivationKeyImport
      parentRoute: typeof rootRoute
    }
    '/ask/$promptId': {
      id: '/ask/$promptId'
      path: '/ask/$promptId'
      fullPath: '/ask/$promptId'
      preLoaderRoute: typeof AskPromptIdImport
      parentRoute: typeof rootRoute
    }
    '/convo/$convoId': {
      id: '/convo/$convoId'
      path: '/convo/$convoId'
      fullPath: '/convo/$convoId'
      preLoaderRoute: typeof ConvoConvoIdImport
      parentRoute: typeof rootRoute
    }
    '/l/$url': {
      id: '/l/$url'
      path: '/l/$url'
      fullPath: '/l/$url'
      preLoaderRoute: typeof LUrlImport
      parentRoute: typeof rootRoute
    }
    '/list/$listId': {
      id: '/list/$listId'
      path: '/list/$listId'
      fullPath: '/list/$listId'
      preLoaderRoute: typeof ListListIdImport
      parentRoute: typeof rootRoute
    }
    '/p/$articleSlugOrId': {
      id: '/p/$articleSlugOrId'
      path: '/p/$articleSlugOrId'
      fullPath: '/p/$articleSlugOrId'
      preLoaderRoute: typeof PArticleSlugOrIdImport
      parentRoute: typeof rootRoute
    }
    '/rec/$recId': {
      id: '/rec/$recId'
      path: '/rec/$recId'
      fullPath: '/rec/$recId'
      preLoaderRoute: typeof RecRecIdImport
      parentRoute: typeof rootRoute
    }
    '/redeem/$bonusId': {
      id: '/redeem/$bonusId'
      path: '/redeem/$bonusId'
      fullPath: '/redeem/$bonusId'
      preLoaderRoute: typeof RedeemBonusIdImport
      parentRoute: typeof rootRoute
    }
    '/t/$key': {
      id: '/t/$key'
      path: '/t/$key'
      fullPath: '/t/$key'
      preLoaderRoute: typeof TKeyImport
      parentRoute: typeof rootRoute
    }
    '/admin/': {
      id: '/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AdminIndexImport
      parentRoute: typeof AdminImport
    }
    '/app-store/': {
      id: '/app-store/'
      path: '/app-store'
      fullPath: '/app-store'
      preLoaderRoute: typeof AppStoreIndexImport
      parentRoute: typeof rootRoute
    }
    '/asks/': {
      id: '/asks/'
      path: '/asks'
      fullPath: '/asks'
      preLoaderRoute: typeof AsksIndexImport
      parentRoute: typeof rootRoute
    }
    '/bookmarks/': {
      id: '/bookmarks/'
      path: '/bookmarks'
      fullPath: '/bookmarks'
      preLoaderRoute: typeof BookmarksIndexImport
      parentRoute: typeof rootRoute
    }
    '/careers/': {
      id: '/careers/'
      path: '/careers'
      fullPath: '/careers'
      preLoaderRoute: typeof CareersIndexImport
      parentRoute: typeof rootRoute
    }
    '/categories/': {
      id: '/categories/'
      path: '/categories'
      fullPath: '/categories'
      preLoaderRoute: typeof CategoriesIndexImport
      parentRoute: typeof rootRoute
    }
    '/convos/': {
      id: '/convos/'
      path: '/convos'
      fullPath: '/convos'
      preLoaderRoute: typeof ConvosIndexImport
      parentRoute: typeof rootRoute
    }
    '/cookies/': {
      id: '/cookies/'
      path: '/cookies'
      fullPath: '/cookies'
      preLoaderRoute: typeof CookiesIndexImport
      parentRoute: typeof rootRoute
    }
    '/directory/': {
      id: '/directory/'
      path: '/directory'
      fullPath: '/directory'
      preLoaderRoute: typeof DirectoryIndexImport
      parentRoute: typeof rootRoute
    }
    '/download/': {
      id: '/download/'
      path: '/download'
      fullPath: '/download'
      preLoaderRoute: typeof DownloadIndexImport
      parentRoute: typeof rootRoute
    }
    '/drafts/': {
      id: '/drafts/'
      path: '/drafts'
      fullPath: '/drafts'
      preLoaderRoute: typeof DraftsIndexImport
      parentRoute: typeof rootRoute
    }
    '/endorsed/': {
      id: '/endorsed/'
      path: '/endorsed'
      fullPath: '/endorsed'
      preLoaderRoute: typeof EndorsedIndexImport
      parentRoute: typeof rootRoute
    }
    '/features/': {
      id: '/features/'
      path: '/features'
      fullPath: '/features'
      preLoaderRoute: typeof FeaturesIndexImport
      parentRoute: typeof rootRoute
    }
    '/invites/': {
      id: '/invites/'
      path: '/invites'
      fullPath: '/invites'
      preLoaderRoute: typeof InvitesIndexImport
      parentRoute: typeof rootRoute
    }
    '/link-in-bio/': {
      id: '/link-in-bio/'
      path: '/link-in-bio'
      fullPath: '/link-in-bio'
      preLoaderRoute: typeof LinkInBioIndexImport
      parentRoute: typeof rootRoute
    }
    '/notifications/': {
      id: '/notifications/'
      path: '/notifications'
      fullPath: '/notifications'
      preLoaderRoute: typeof NotificationsIndexImport
      parentRoute: typeof rootRoute
    }
    '/privacy/': {
      id: '/privacy/'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyIndexImport
      parentRoute: typeof rootRoute
    }
    '/random/': {
      id: '/random/'
      path: '/random'
      fullPath: '/random'
      preLoaderRoute: typeof RandomIndexImport
      parentRoute: typeof rootRoute
    }
    '/read/': {
      id: '/read/'
      path: '/read'
      fullPath: '/read'
      preLoaderRoute: typeof ReadIndexImport
      parentRoute: typeof rootRoute
    }
    '/recs/': {
      id: '/recs/'
      path: '/recs'
      fullPath: '/recs'
      preLoaderRoute: typeof RecsIndexImport
      parentRoute: typeof rootRoute
    }
    '/search/': {
      id: '/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchIndexImport
      parentRoute: typeof rootRoute
    }
    '/settings/': {
      id: '/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsIndexImport
      parentRoute: typeof rootRoute
    }
    '/sign-in/': {
      id: '/sign-in/'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInIndexImport
      parentRoute: typeof rootRoute
    }
    '/sign-out/': {
      id: '/sign-out/'
      path: '/sign-out'
      fullPath: '/sign-out'
      preLoaderRoute: typeof SignOutIndexImport
      parentRoute: typeof rootRoute
    }
    '/sign-up/': {
      id: '/sign-up/'
      path: '/'
      fullPath: '/sign-up/'
      preLoaderRoute: typeof SignUpIndexImport
      parentRoute: typeof SignUpImport
    }
    '/spotlight-apply/': {
      id: '/spotlight-apply/'
      path: '/spotlight-apply'
      fullPath: '/spotlight-apply'
      preLoaderRoute: typeof SpotlightApplyIndexImport
      parentRoute: typeof rootRoute
    }
    '/sxsw-2025/': {
      id: '/sxsw-2025/'
      path: '/sxsw-2025'
      fullPath: '/sxsw-2025'
      preLoaderRoute: typeof Sxsw2025IndexImport
      parentRoute: typeof rootRoute
    }
    '/terms/': {
      id: '/terms/'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof TermsIndexImport
      parentRoute: typeof rootRoute
    }
    '/test/': {
      id: '/test/'
      path: '/test'
      fullPath: '/test'
      preLoaderRoute: typeof TestIndexImport
      parentRoute: typeof rootRoute
    }
    '/today/': {
      id: '/today/'
      path: '/today'
      fullPath: '/today'
      preLoaderRoute: typeof TodayIndexImport
      parentRoute: typeof rootRoute
    }
    '/unlocked-archive/': {
      id: '/unlocked-archive/'
      path: '/unlocked-archive'
      fullPath: '/unlocked-archive'
      preLoaderRoute: typeof UnlockedArchiveIndexImport
      parentRoute: typeof rootRoute
    }
    '/verify-another-email/': {
      id: '/verify-another-email/'
      path: '/verify-another-email'
      fullPath: '/verify-another-email'
      preLoaderRoute: typeof VerifyAnotherEmailIndexImport
      parentRoute: typeof rootRoute
    }
    '/welcome/': {
      id: '/welcome/'
      path: '/welcome'
      fullPath: '/welcome'
      preLoaderRoute: typeof WelcomeIndexImport
      parentRoute: typeof rootRoute
    }
    '/what-is-this/': {
      id: '/what-is-this/'
      path: '/what-is-this'
      fullPath: '/what-is-this'
      preLoaderRoute: typeof WhatIsThisIndexImport
      parentRoute: typeof rootRoute
    }
    '/auth/email-callback/$id': {
      id: '/auth/email-callback/$id'
      path: '/auth/email-callback/$id'
      fullPath: '/auth/email-callback/$id'
      preLoaderRoute: typeof AuthEmailCallbackIdImport
      parentRoute: typeof rootRoute
    }
    '/sign-up/invite/$code': {
      id: '/sign-up/invite/$code'
      path: '/invite/$code'
      fullPath: '/sign-up/invite/$code'
      preLoaderRoute: typeof SignUpInviteCodeImport
      parentRoute: typeof SignUpImport
    }
    '/subscribe/referral/$referralId': {
      id: '/subscribe/referral/$referralId'
      path: '/subscribe/referral/$referralId'
      fullPath: '/subscribe/referral/$referralId'
      preLoaderRoute: typeof SubscribeReferralReferralIdImport
      parentRoute: typeof rootRoute
    }
    '/unsubscribe/email-List/$emailList': {
      id: '/unsubscribe/email-List/$emailList'
      path: '/unsubscribe/email-List/$emailList'
      fullPath: '/unsubscribe/email-List/$emailList'
      preLoaderRoute: typeof UnsubscribeEmailListEmailListImport
      parentRoute: typeof rootRoute
    }
    '/admin/dashboard/': {
      id: '/admin/dashboard/'
      path: '/dashboard'
      fullPath: '/admin/dashboard'
      preLoaderRoute: typeof AdminDashboardIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/staff/': {
      id: '/admin/staff/'
      path: '/staff'
      fullPath: '/admin/staff'
      preLoaderRoute: typeof AdminStaffIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/substack/': {
      id: '/admin/substack/'
      path: '/substack'
      fullPath: '/admin/substack'
      preLoaderRoute: typeof AdminSubstackIndexImport
      parentRoute: typeof AdminImport
    }
    '/categories/$name/': {
      id: '/categories/$name/'
      path: '/categories/$name'
      fullPath: '/categories/$name'
      preLoaderRoute: typeof CategoriesNameIndexImport
      parentRoute: typeof rootRoute
    }
    '/features/recs/': {
      id: '/features/recs/'
      path: '/features/recs'
      fullPath: '/features/recs'
      preLoaderRoute: typeof FeaturesRecsIndexImport
      parentRoute: typeof rootRoute
    }
    '/home/$tab/': {
      id: '/home/$tab/'
      path: '/home/$tab'
      fullPath: '/home/$tab'
      preLoaderRoute: typeof HomeTabIndexImport
      parentRoute: typeof rootRoute
    }
    '/mobile/add-comment/': {
      id: '/mobile/add-comment/'
      path: '/mobile/add-comment'
      fullPath: '/mobile/add-comment'
      preLoaderRoute: typeof MobileAddCommentIndexImport
      parentRoute: typeof rootRoute
    }
    '/mobile/add-prompt/': {
      id: '/mobile/add-prompt/'
      path: '/mobile/add-prompt'
      fullPath: '/mobile/add-prompt'
      preLoaderRoute: typeof MobileAddPromptIndexImport
      parentRoute: typeof rootRoute
    }
    '/mobile/add-recommendation/': {
      id: '/mobile/add-recommendation/'
      path: '/mobile/add-recommendation'
      fullPath: '/mobile/add-recommendation'
      preLoaderRoute: typeof MobileAddRecommendationIndexImport
      parentRoute: typeof rootRoute
    }
    '/mobile/update-prompt/': {
      id: '/mobile/update-prompt/'
      path: '/mobile/update-prompt'
      fullPath: '/mobile/update-prompt'
      preLoaderRoute: typeof MobileUpdatePromptIndexImport
      parentRoute: typeof rootRoute
    }
    '/mobile/update-recommendation/': {
      id: '/mobile/update-recommendation/'
      path: '/mobile/update-recommendation'
      fullPath: '/mobile/update-recommendation'
      preLoaderRoute: typeof MobileUpdateRecommendationIndexImport
      parentRoute: typeof rootRoute
    }
    '/settings/premium/': {
      id: '/settings/premium/'
      path: '/settings/premium'
      fullPath: '/settings/premium'
      preLoaderRoute: typeof SettingsPremiumIndexImport
      parentRoute: typeof rootRoute
    }
    '/sign-up/add-email/': {
      id: '/sign-up/add-email/'
      path: '/add-email'
      fullPath: '/sign-up/add-email'
      preLoaderRoute: typeof SignUpAddEmailIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/add-password/': {
      id: '/sign-up/add-password/'
      path: '/add-password'
      fullPath: '/sign-up/add-password'
      preLoaderRoute: typeof SignUpAddPasswordIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/add-recs/': {
      id: '/sign-up/add-recs/'
      path: '/add-recs'
      fullPath: '/sign-up/add-recs'
      preLoaderRoute: typeof SignUpAddRecsIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/app/': {
      id: '/sign-up/app/'
      path: '/app'
      fullPath: '/sign-up/app'
      preLoaderRoute: typeof SignUpAppIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/categories/': {
      id: '/sign-up/categories/'
      path: '/categories'
      fullPath: '/sign-up/categories'
      preLoaderRoute: typeof SignUpCategoriesIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/done/': {
      id: '/sign-up/done/'
      path: '/done'
      fullPath: '/sign-up/done'
      preLoaderRoute: typeof SignUpDoneIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/features/': {
      id: '/sign-up/features/'
      path: '/features'
      fullPath: '/sign-up/features'
      preLoaderRoute: typeof SignUpFeaturesIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/follow/': {
      id: '/sign-up/follow/'
      path: '/follow'
      fullPath: '/sign-up/follow'
      preLoaderRoute: typeof SignUpFollowIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/invite-code/': {
      id: '/sign-up/invite-code/'
      path: '/invite-code'
      fullPath: '/sign-up/invite-code'
      preLoaderRoute: typeof SignUpInviteCodeIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/like-recs/': {
      id: '/sign-up/like-recs/'
      path: '/like-recs'
      fullPath: '/sign-up/like-recs'
      preLoaderRoute: typeof SignUpLikeRecsIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/location/': {
      id: '/sign-up/location/'
      path: '/location'
      fullPath: '/sign-up/location'
      preLoaderRoute: typeof SignUpLocationIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/more/': {
      id: '/sign-up/more/'
      path: '/more'
      fullPath: '/sign-up/more'
      preLoaderRoute: typeof SignUpMoreIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/personalizing/': {
      id: '/sign-up/personalizing/'
      path: '/personalizing'
      fullPath: '/sign-up/personalizing'
      preLoaderRoute: typeof SignUpPersonalizingIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/photo/': {
      id: '/sign-up/photo/'
      path: '/photo'
      fullPath: '/sign-up/photo'
      preLoaderRoute: typeof SignUpPhotoIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/premium/': {
      id: '/sign-up/premium/'
      path: '/premium'
      fullPath: '/sign-up/premium'
      preLoaderRoute: typeof SignUpPremiumIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/share/': {
      id: '/sign-up/share/'
      path: '/share'
      fullPath: '/sign-up/share'
      preLoaderRoute: typeof SignUpShareIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/survey/': {
      id: '/sign-up/survey/'
      path: '/survey'
      fullPath: '/sign-up/survey'
      preLoaderRoute: typeof SignUpSurveyIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/theme/': {
      id: '/sign-up/theme/'
      path: '/theme'
      fullPath: '/sign-up/theme'
      preLoaderRoute: typeof SignUpThemeIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/trial/': {
      id: '/sign-up/trial/'
      path: '/trial'
      fullPath: '/sign-up/trial'
      preLoaderRoute: typeof SignUpTrialIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/verify-complete/': {
      id: '/sign-up/verify-complete/'
      path: '/verify-complete'
      fullPath: '/sign-up/verify-complete'
      preLoaderRoute: typeof SignUpVerifyCompleteIndexImport
      parentRoute: typeof SignUpImport
    }
    '/sign-up/verify/': {
      id: '/sign-up/verify/'
      path: '/verify'
      fullPath: '/sign-up/verify'
      preLoaderRoute: typeof SignUpVerifyIndexImport
      parentRoute: typeof SignUpImport
    }
    '/stripe/cancel/': {
      id: '/stripe/cancel/'
      path: '/stripe/cancel'
      fullPath: '/stripe/cancel'
      preLoaderRoute: typeof StripeCancelIndexImport
      parentRoute: typeof rootRoute
    }
    '/stripe/success/': {
      id: '/stripe/success/'
      path: '/stripe/success'
      fullPath: '/stripe/success'
      preLoaderRoute: typeof StripeSuccessIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/': {
      id: '/u/$username/'
      path: '/u/$username'
      fullPath: '/u/$username'
      preLoaderRoute: typeof UUsernameIndexImport
      parentRoute: typeof rootRoute
    }
    '/welcome/$code/': {
      id: '/welcome/$code/'
      path: '/welcome/$code'
      fullPath: '/welcome/$code'
      preLoaderRoute: typeof WelcomeCodeIndexImport
      parentRoute: typeof rootRoute
    }
    '/admin/documents/compose/$documentId': {
      id: '/admin/documents/compose/$documentId'
      path: '/documents/compose/$documentId'
      fullPath: '/admin/documents/compose/$documentId'
      preLoaderRoute: typeof AdminDocumentsComposeDocumentIdImport
      parentRoute: typeof AdminImport
    }
    '/admin/dashboard/monthly-archival-picks/': {
      id: '/admin/dashboard/monthly-archival-picks/'
      path: '/dashboard/monthly-archival-picks'
      fullPath: '/admin/dashboard/monthly-archival-picks'
      preLoaderRoute: typeof AdminDashboardMonthlyArchivalPicksIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/dashboard/stats/': {
      id: '/admin/dashboard/stats/'
      path: '/dashboard/stats'
      fullPath: '/admin/dashboard/stats'
      preLoaderRoute: typeof AdminDashboardStatsIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/dashboard/users/': {
      id: '/admin/dashboard/users/'
      path: '/dashboard/users'
      fullPath: '/admin/dashboard/users'
      preLoaderRoute: typeof AdminDashboardUsersIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/documents/drafts/': {
      id: '/admin/documents/drafts/'
      path: '/documents/drafts'
      fullPath: '/admin/documents/drafts'
      preLoaderRoute: typeof AdminDocumentsDraftsIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/email/blasts/': {
      id: '/admin/email/blasts/'
      path: '/email/blasts'
      fullPath: '/admin/email/blasts'
      preLoaderRoute: typeof AdminEmailBlastsIndexImport
      parentRoute: typeof AdminImport
    }
    '/admin/email/subscribers/': {
      id: '/admin/email/subscribers/'
      path: '/email/subscribers'
      fullPath: '/admin/email/subscribers'
      preLoaderRoute: typeof AdminEmailSubscribersIndexImport
      parentRoute: typeof AdminImport
    }
    '/settings/manage/blocks/': {
      id: '/settings/manage/blocks/'
      path: '/settings/manage/blocks'
      fullPath: '/settings/manage/blocks'
      preLoaderRoute: typeof SettingsManageBlocksIndexImport
      parentRoute: typeof rootRoute
    }
    '/settings/manage/email/': {
      id: '/settings/manage/email/'
      path: '/settings/manage/email'
      fullPath: '/settings/manage/email'
      preLoaderRoute: typeof SettingsManageEmailIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/asks/': {
      id: '/u/$username/asks/'
      path: '/u/$username/asks'
      fullPath: '/u/$username/asks'
      preLoaderRoute: typeof UUsernameAsksIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/followers/': {
      id: '/u/$username/followers/'
      path: '/u/$username/followers'
      fullPath: '/u/$username/followers'
      preLoaderRoute: typeof UUsernameFollowersIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/following/': {
      id: '/u/$username/following/'
      path: '/u/$username/following'
      fullPath: '/u/$username/following'
      preLoaderRoute: typeof UUsernameFollowingIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/latest-ask/': {
      id: '/u/$username/latest-ask/'
      path: '/u/$username/latest-ask'
      fullPath: '/u/$username/latest-ask'
      preLoaderRoute: typeof UUsernameLatestAskIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/lists/': {
      id: '/u/$username/lists/'
      path: '/u/$username/lists'
      fullPath: '/u/$username/lists'
      preLoaderRoute: typeof UUsernameListsIndexImport
      parentRoute: typeof rootRoute
    }
    '/u/$username/recs/': {
      id: '/u/$username/recs/'
      path: '/u/$username/recs'
      fullPath: '/u/$username/recs'
      preLoaderRoute: typeof UUsernameRecsIndexImport
      parentRoute: typeof rootRoute
    }
    '/unsubscribe/email-List/survey/': {
      id: '/unsubscribe/email-List/survey/'
      path: '/unsubscribe/email-List/survey'
      fullPath: '/unsubscribe/email-List/survey'
      preLoaderRoute: typeof UnsubscribeEmailListSurveyIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface AdminRouteChildren {
  AdminIndexRoute: typeof AdminIndexRoute
  AdminDashboardIndexRoute: typeof AdminDashboardIndexRoute
  AdminStaffIndexRoute: typeof AdminStaffIndexRoute
  AdminSubstackIndexRoute: typeof AdminSubstackIndexRoute
  AdminDocumentsComposeDocumentIdRoute: typeof AdminDocumentsComposeDocumentIdRoute
  AdminDashboardMonthlyArchivalPicksIndexRoute: typeof AdminDashboardMonthlyArchivalPicksIndexRoute
  AdminDashboardStatsIndexRoute: typeof AdminDashboardStatsIndexRoute
  AdminDashboardUsersIndexRoute: typeof AdminDashboardUsersIndexRoute
  AdminDocumentsDraftsIndexRoute: typeof AdminDocumentsDraftsIndexRoute
  AdminEmailBlastsIndexRoute: typeof AdminEmailBlastsIndexRoute
  AdminEmailSubscribersIndexRoute: typeof AdminEmailSubscribersIndexRoute
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminIndexRoute: AdminIndexRoute,
  AdminDashboardIndexRoute: AdminDashboardIndexRoute,
  AdminStaffIndexRoute: AdminStaffIndexRoute,
  AdminSubstackIndexRoute: AdminSubstackIndexRoute,
  AdminDocumentsComposeDocumentIdRoute: AdminDocumentsComposeDocumentIdRoute,
  AdminDashboardMonthlyArchivalPicksIndexRoute:
    AdminDashboardMonthlyArchivalPicksIndexRoute,
  AdminDashboardStatsIndexRoute: AdminDashboardStatsIndexRoute,
  AdminDashboardUsersIndexRoute: AdminDashboardUsersIndexRoute,
  AdminDocumentsDraftsIndexRoute: AdminDocumentsDraftsIndexRoute,
  AdminEmailBlastsIndexRoute: AdminEmailBlastsIndexRoute,
  AdminEmailSubscribersIndexRoute: AdminEmailSubscribersIndexRoute,
}

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren)

interface SignUpRouteChildren {
  SignUpIndexRoute: typeof SignUpIndexRoute
  SignUpInviteCodeRoute: typeof SignUpInviteCodeRoute
  SignUpAddEmailIndexRoute: typeof SignUpAddEmailIndexRoute
  SignUpAddPasswordIndexRoute: typeof SignUpAddPasswordIndexRoute
  SignUpAddRecsIndexRoute: typeof SignUpAddRecsIndexRoute
  SignUpAppIndexRoute: typeof SignUpAppIndexRoute
  SignUpCategoriesIndexRoute: typeof SignUpCategoriesIndexRoute
  SignUpDoneIndexRoute: typeof SignUpDoneIndexRoute
  SignUpFeaturesIndexRoute: typeof SignUpFeaturesIndexRoute
  SignUpFollowIndexRoute: typeof SignUpFollowIndexRoute
  SignUpInviteCodeIndexRoute: typeof SignUpInviteCodeIndexRoute
  SignUpLikeRecsIndexRoute: typeof SignUpLikeRecsIndexRoute
  SignUpLocationIndexRoute: typeof SignUpLocationIndexRoute
  SignUpMoreIndexRoute: typeof SignUpMoreIndexRoute
  SignUpPersonalizingIndexRoute: typeof SignUpPersonalizingIndexRoute
  SignUpPhotoIndexRoute: typeof SignUpPhotoIndexRoute
  SignUpPremiumIndexRoute: typeof SignUpPremiumIndexRoute
  SignUpShareIndexRoute: typeof SignUpShareIndexRoute
  SignUpSurveyIndexRoute: typeof SignUpSurveyIndexRoute
  SignUpThemeIndexRoute: typeof SignUpThemeIndexRoute
  SignUpTrialIndexRoute: typeof SignUpTrialIndexRoute
  SignUpVerifyCompleteIndexRoute: typeof SignUpVerifyCompleteIndexRoute
  SignUpVerifyIndexRoute: typeof SignUpVerifyIndexRoute
}

const SignUpRouteChildren: SignUpRouteChildren = {
  SignUpIndexRoute: SignUpIndexRoute,
  SignUpInviteCodeRoute: SignUpInviteCodeRoute,
  SignUpAddEmailIndexRoute: SignUpAddEmailIndexRoute,
  SignUpAddPasswordIndexRoute: SignUpAddPasswordIndexRoute,
  SignUpAddRecsIndexRoute: SignUpAddRecsIndexRoute,
  SignUpAppIndexRoute: SignUpAppIndexRoute,
  SignUpCategoriesIndexRoute: SignUpCategoriesIndexRoute,
  SignUpDoneIndexRoute: SignUpDoneIndexRoute,
  SignUpFeaturesIndexRoute: SignUpFeaturesIndexRoute,
  SignUpFollowIndexRoute: SignUpFollowIndexRoute,
  SignUpInviteCodeIndexRoute: SignUpInviteCodeIndexRoute,
  SignUpLikeRecsIndexRoute: SignUpLikeRecsIndexRoute,
  SignUpLocationIndexRoute: SignUpLocationIndexRoute,
  SignUpMoreIndexRoute: SignUpMoreIndexRoute,
  SignUpPersonalizingIndexRoute: SignUpPersonalizingIndexRoute,
  SignUpPhotoIndexRoute: SignUpPhotoIndexRoute,
  SignUpPremiumIndexRoute: SignUpPremiumIndexRoute,
  SignUpShareIndexRoute: SignUpShareIndexRoute,
  SignUpSurveyIndexRoute: SignUpSurveyIndexRoute,
  SignUpThemeIndexRoute: SignUpThemeIndexRoute,
  SignUpTrialIndexRoute: SignUpTrialIndexRoute,
  SignUpVerifyCompleteIndexRoute: SignUpVerifyCompleteIndexRoute,
  SignUpVerifyIndexRoute: SignUpVerifyIndexRoute,
}

const SignUpRouteWithChildren =
  SignUpRoute._addFileChildren(SignUpRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/admin': typeof AdminRouteWithChildren
  '/sign-up': typeof SignUpRouteWithChildren
  '/activate/$activationKey': typeof ActivateActivationKeyRoute
  '/ask/$promptId': typeof AskPromptIdRoute
  '/convo/$convoId': typeof ConvoConvoIdRoute
  '/l/$url': typeof LUrlRoute
  '/list/$listId': typeof ListListIdRoute
  '/p/$articleSlugOrId': typeof PArticleSlugOrIdRoute
  '/rec/$recId': typeof RecRecIdRoute
  '/redeem/$bonusId': typeof RedeemBonusIdRoute
  '/t/$key': typeof TKeyRoute
  '/admin/': typeof AdminIndexRoute
  '/app-store': typeof AppStoreIndexRoute
  '/asks': typeof AsksIndexRoute
  '/bookmarks': typeof BookmarksIndexRoute
  '/careers': typeof CareersIndexRoute
  '/categories': typeof CategoriesIndexRoute
  '/convos': typeof ConvosIndexRoute
  '/cookies': typeof CookiesIndexRoute
  '/directory': typeof DirectoryIndexRoute
  '/download': typeof DownloadIndexRoute
  '/drafts': typeof DraftsIndexRoute
  '/endorsed': typeof EndorsedIndexRoute
  '/features': typeof FeaturesIndexRoute
  '/invites': typeof InvitesIndexRoute
  '/link-in-bio': typeof LinkInBioIndexRoute
  '/notifications': typeof NotificationsIndexRoute
  '/privacy': typeof PrivacyIndexRoute
  '/random': typeof RandomIndexRoute
  '/read': typeof ReadIndexRoute
  '/recs': typeof RecsIndexRoute
  '/search': typeof SearchIndexRoute
  '/settings': typeof SettingsIndexRoute
  '/sign-in': typeof SignInIndexRoute
  '/sign-out': typeof SignOutIndexRoute
  '/sign-up/': typeof SignUpIndexRoute
  '/spotlight-apply': typeof SpotlightApplyIndexRoute
  '/sxsw-2025': typeof Sxsw2025IndexRoute
  '/terms': typeof TermsIndexRoute
  '/test': typeof TestIndexRoute
  '/today': typeof TodayIndexRoute
  '/unlocked-archive': typeof UnlockedArchiveIndexRoute
  '/verify-another-email': typeof VerifyAnotherEmailIndexRoute
  '/welcome': typeof WelcomeIndexRoute
  '/what-is-this': typeof WhatIsThisIndexRoute
  '/auth/email-callback/$id': typeof AuthEmailCallbackIdRoute
  '/sign-up/invite/$code': typeof SignUpInviteCodeRoute
  '/subscribe/referral/$referralId': typeof SubscribeReferralReferralIdRoute
  '/unsubscribe/email-List/$emailList': typeof UnsubscribeEmailListEmailListRoute
  '/admin/dashboard': typeof AdminDashboardIndexRoute
  '/admin/staff': typeof AdminStaffIndexRoute
  '/admin/substack': typeof AdminSubstackIndexRoute
  '/categories/$name': typeof CategoriesNameIndexRoute
  '/features/recs': typeof FeaturesRecsIndexRoute
  '/home/$tab': typeof HomeTabIndexRoute
  '/mobile/add-comment': typeof MobileAddCommentIndexRoute
  '/mobile/add-prompt': typeof MobileAddPromptIndexRoute
  '/mobile/add-recommendation': typeof MobileAddRecommendationIndexRoute
  '/mobile/update-prompt': typeof MobileUpdatePromptIndexRoute
  '/mobile/update-recommendation': typeof MobileUpdateRecommendationIndexRoute
  '/settings/premium': typeof SettingsPremiumIndexRoute
  '/sign-up/add-email': typeof SignUpAddEmailIndexRoute
  '/sign-up/add-password': typeof SignUpAddPasswordIndexRoute
  '/sign-up/add-recs': typeof SignUpAddRecsIndexRoute
  '/sign-up/app': typeof SignUpAppIndexRoute
  '/sign-up/categories': typeof SignUpCategoriesIndexRoute
  '/sign-up/done': typeof SignUpDoneIndexRoute
  '/sign-up/features': typeof SignUpFeaturesIndexRoute
  '/sign-up/follow': typeof SignUpFollowIndexRoute
  '/sign-up/invite-code': typeof SignUpInviteCodeIndexRoute
  '/sign-up/like-recs': typeof SignUpLikeRecsIndexRoute
  '/sign-up/location': typeof SignUpLocationIndexRoute
  '/sign-up/more': typeof SignUpMoreIndexRoute
  '/sign-up/personalizing': typeof SignUpPersonalizingIndexRoute
  '/sign-up/photo': typeof SignUpPhotoIndexRoute
  '/sign-up/premium': typeof SignUpPremiumIndexRoute
  '/sign-up/share': typeof SignUpShareIndexRoute
  '/sign-up/survey': typeof SignUpSurveyIndexRoute
  '/sign-up/theme': typeof SignUpThemeIndexRoute
  '/sign-up/trial': typeof SignUpTrialIndexRoute
  '/sign-up/verify-complete': typeof SignUpVerifyCompleteIndexRoute
  '/sign-up/verify': typeof SignUpVerifyIndexRoute
  '/stripe/cancel': typeof StripeCancelIndexRoute
  '/stripe/success': typeof StripeSuccessIndexRoute
  '/u/$username': typeof UUsernameIndexRoute
  '/welcome/$code': typeof WelcomeCodeIndexRoute
  '/admin/documents/compose/$documentId': typeof AdminDocumentsComposeDocumentIdRoute
  '/admin/dashboard/monthly-archival-picks': typeof AdminDashboardMonthlyArchivalPicksIndexRoute
  '/admin/dashboard/stats': typeof AdminDashboardStatsIndexRoute
  '/admin/dashboard/users': typeof AdminDashboardUsersIndexRoute
  '/admin/documents/drafts': typeof AdminDocumentsDraftsIndexRoute
  '/admin/email/blasts': typeof AdminEmailBlastsIndexRoute
  '/admin/email/subscribers': typeof AdminEmailSubscribersIndexRoute
  '/settings/manage/blocks': typeof SettingsManageBlocksIndexRoute
  '/settings/manage/email': typeof SettingsManageEmailIndexRoute
  '/u/$username/asks': typeof UUsernameAsksIndexRoute
  '/u/$username/followers': typeof UUsernameFollowersIndexRoute
  '/u/$username/following': typeof UUsernameFollowingIndexRoute
  '/u/$username/latest-ask': typeof UUsernameLatestAskIndexRoute
  '/u/$username/lists': typeof UUsernameListsIndexRoute
  '/u/$username/recs': typeof UUsernameRecsIndexRoute
  '/unsubscribe/email-List/survey': typeof UnsubscribeEmailListSurveyIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/activate/$activationKey': typeof ActivateActivationKeyRoute
  '/ask/$promptId': typeof AskPromptIdRoute
  '/convo/$convoId': typeof ConvoConvoIdRoute
  '/l/$url': typeof LUrlRoute
  '/list/$listId': typeof ListListIdRoute
  '/p/$articleSlugOrId': typeof PArticleSlugOrIdRoute
  '/rec/$recId': typeof RecRecIdRoute
  '/redeem/$bonusId': typeof RedeemBonusIdRoute
  '/t/$key': typeof TKeyRoute
  '/admin': typeof AdminIndexRoute
  '/app-store': typeof AppStoreIndexRoute
  '/asks': typeof AsksIndexRoute
  '/bookmarks': typeof BookmarksIndexRoute
  '/careers': typeof CareersIndexRoute
  '/categories': typeof CategoriesIndexRoute
  '/convos': typeof ConvosIndexRoute
  '/cookies': typeof CookiesIndexRoute
  '/directory': typeof DirectoryIndexRoute
  '/download': typeof DownloadIndexRoute
  '/drafts': typeof DraftsIndexRoute
  '/endorsed': typeof EndorsedIndexRoute
  '/features': typeof FeaturesIndexRoute
  '/invites': typeof InvitesIndexRoute
  '/link-in-bio': typeof LinkInBioIndexRoute
  '/notifications': typeof NotificationsIndexRoute
  '/privacy': typeof PrivacyIndexRoute
  '/random': typeof RandomIndexRoute
  '/read': typeof ReadIndexRoute
  '/recs': typeof RecsIndexRoute
  '/search': typeof SearchIndexRoute
  '/settings': typeof SettingsIndexRoute
  '/sign-in': typeof SignInIndexRoute
  '/sign-out': typeof SignOutIndexRoute
  '/sign-up': typeof SignUpIndexRoute
  '/spotlight-apply': typeof SpotlightApplyIndexRoute
  '/sxsw-2025': typeof Sxsw2025IndexRoute
  '/terms': typeof TermsIndexRoute
  '/test': typeof TestIndexRoute
  '/today': typeof TodayIndexRoute
  '/unlocked-archive': typeof UnlockedArchiveIndexRoute
  '/verify-another-email': typeof VerifyAnotherEmailIndexRoute
  '/welcome': typeof WelcomeIndexRoute
  '/what-is-this': typeof WhatIsThisIndexRoute
  '/auth/email-callback/$id': typeof AuthEmailCallbackIdRoute
  '/sign-up/invite/$code': typeof SignUpInviteCodeRoute
  '/subscribe/referral/$referralId': typeof SubscribeReferralReferralIdRoute
  '/unsubscribe/email-List/$emailList': typeof UnsubscribeEmailListEmailListRoute
  '/admin/dashboard': typeof AdminDashboardIndexRoute
  '/admin/staff': typeof AdminStaffIndexRoute
  '/admin/substack': typeof AdminSubstackIndexRoute
  '/categories/$name': typeof CategoriesNameIndexRoute
  '/features/recs': typeof FeaturesRecsIndexRoute
  '/home/$tab': typeof HomeTabIndexRoute
  '/mobile/add-comment': typeof MobileAddCommentIndexRoute
  '/mobile/add-prompt': typeof MobileAddPromptIndexRoute
  '/mobile/add-recommendation': typeof MobileAddRecommendationIndexRoute
  '/mobile/update-prompt': typeof MobileUpdatePromptIndexRoute
  '/mobile/update-recommendation': typeof MobileUpdateRecommendationIndexRoute
  '/settings/premium': typeof SettingsPremiumIndexRoute
  '/sign-up/add-email': typeof SignUpAddEmailIndexRoute
  '/sign-up/add-password': typeof SignUpAddPasswordIndexRoute
  '/sign-up/add-recs': typeof SignUpAddRecsIndexRoute
  '/sign-up/app': typeof SignUpAppIndexRoute
  '/sign-up/categories': typeof SignUpCategoriesIndexRoute
  '/sign-up/done': typeof SignUpDoneIndexRoute
  '/sign-up/features': typeof SignUpFeaturesIndexRoute
  '/sign-up/follow': typeof SignUpFollowIndexRoute
  '/sign-up/invite-code': typeof SignUpInviteCodeIndexRoute
  '/sign-up/like-recs': typeof SignUpLikeRecsIndexRoute
  '/sign-up/location': typeof SignUpLocationIndexRoute
  '/sign-up/more': typeof SignUpMoreIndexRoute
  '/sign-up/personalizing': typeof SignUpPersonalizingIndexRoute
  '/sign-up/photo': typeof SignUpPhotoIndexRoute
  '/sign-up/premium': typeof SignUpPremiumIndexRoute
  '/sign-up/share': typeof SignUpShareIndexRoute
  '/sign-up/survey': typeof SignUpSurveyIndexRoute
  '/sign-up/theme': typeof SignUpThemeIndexRoute
  '/sign-up/trial': typeof SignUpTrialIndexRoute
  '/sign-up/verify-complete': typeof SignUpVerifyCompleteIndexRoute
  '/sign-up/verify': typeof SignUpVerifyIndexRoute
  '/stripe/cancel': typeof StripeCancelIndexRoute
  '/stripe/success': typeof StripeSuccessIndexRoute
  '/u/$username': typeof UUsernameIndexRoute
  '/welcome/$code': typeof WelcomeCodeIndexRoute
  '/admin/documents/compose/$documentId': typeof AdminDocumentsComposeDocumentIdRoute
  '/admin/dashboard/monthly-archival-picks': typeof AdminDashboardMonthlyArchivalPicksIndexRoute
  '/admin/dashboard/stats': typeof AdminDashboardStatsIndexRoute
  '/admin/dashboard/users': typeof AdminDashboardUsersIndexRoute
  '/admin/documents/drafts': typeof AdminDocumentsDraftsIndexRoute
  '/admin/email/blasts': typeof AdminEmailBlastsIndexRoute
  '/admin/email/subscribers': typeof AdminEmailSubscribersIndexRoute
  '/settings/manage/blocks': typeof SettingsManageBlocksIndexRoute
  '/settings/manage/email': typeof SettingsManageEmailIndexRoute
  '/u/$username/asks': typeof UUsernameAsksIndexRoute
  '/u/$username/followers': typeof UUsernameFollowersIndexRoute
  '/u/$username/following': typeof UUsernameFollowingIndexRoute
  '/u/$username/latest-ask': typeof UUsernameLatestAskIndexRoute
  '/u/$username/lists': typeof UUsernameListsIndexRoute
  '/u/$username/recs': typeof UUsernameRecsIndexRoute
  '/unsubscribe/email-List/survey': typeof UnsubscribeEmailListSurveyIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/admin': typeof AdminRouteWithChildren
  '/sign-up': typeof SignUpRouteWithChildren
  '/activate/$activationKey': typeof ActivateActivationKeyRoute
  '/ask/$promptId': typeof AskPromptIdRoute
  '/convo/$convoId': typeof ConvoConvoIdRoute
  '/l/$url': typeof LUrlRoute
  '/list/$listId': typeof ListListIdRoute
  '/p/$articleSlugOrId': typeof PArticleSlugOrIdRoute
  '/rec/$recId': typeof RecRecIdRoute
  '/redeem/$bonusId': typeof RedeemBonusIdRoute
  '/t/$key': typeof TKeyRoute
  '/admin/': typeof AdminIndexRoute
  '/app-store/': typeof AppStoreIndexRoute
  '/asks/': typeof AsksIndexRoute
  '/bookmarks/': typeof BookmarksIndexRoute
  '/careers/': typeof CareersIndexRoute
  '/categories/': typeof CategoriesIndexRoute
  '/convos/': typeof ConvosIndexRoute
  '/cookies/': typeof CookiesIndexRoute
  '/directory/': typeof DirectoryIndexRoute
  '/download/': typeof DownloadIndexRoute
  '/drafts/': typeof DraftsIndexRoute
  '/endorsed/': typeof EndorsedIndexRoute
  '/features/': typeof FeaturesIndexRoute
  '/invites/': typeof InvitesIndexRoute
  '/link-in-bio/': typeof LinkInBioIndexRoute
  '/notifications/': typeof NotificationsIndexRoute
  '/privacy/': typeof PrivacyIndexRoute
  '/random/': typeof RandomIndexRoute
  '/read/': typeof ReadIndexRoute
  '/recs/': typeof RecsIndexRoute
  '/search/': typeof SearchIndexRoute
  '/settings/': typeof SettingsIndexRoute
  '/sign-in/': typeof SignInIndexRoute
  '/sign-out/': typeof SignOutIndexRoute
  '/sign-up/': typeof SignUpIndexRoute
  '/spotlight-apply/': typeof SpotlightApplyIndexRoute
  '/sxsw-2025/': typeof Sxsw2025IndexRoute
  '/terms/': typeof TermsIndexRoute
  '/test/': typeof TestIndexRoute
  '/today/': typeof TodayIndexRoute
  '/unlocked-archive/': typeof UnlockedArchiveIndexRoute
  '/verify-another-email/': typeof VerifyAnotherEmailIndexRoute
  '/welcome/': typeof WelcomeIndexRoute
  '/what-is-this/': typeof WhatIsThisIndexRoute
  '/auth/email-callback/$id': typeof AuthEmailCallbackIdRoute
  '/sign-up/invite/$code': typeof SignUpInviteCodeRoute
  '/subscribe/referral/$referralId': typeof SubscribeReferralReferralIdRoute
  '/unsubscribe/email-List/$emailList': typeof UnsubscribeEmailListEmailListRoute
  '/admin/dashboard/': typeof AdminDashboardIndexRoute
  '/admin/staff/': typeof AdminStaffIndexRoute
  '/admin/substack/': typeof AdminSubstackIndexRoute
  '/categories/$name/': typeof CategoriesNameIndexRoute
  '/features/recs/': typeof FeaturesRecsIndexRoute
  '/home/$tab/': typeof HomeTabIndexRoute
  '/mobile/add-comment/': typeof MobileAddCommentIndexRoute
  '/mobile/add-prompt/': typeof MobileAddPromptIndexRoute
  '/mobile/add-recommendation/': typeof MobileAddRecommendationIndexRoute
  '/mobile/update-prompt/': typeof MobileUpdatePromptIndexRoute
  '/mobile/update-recommendation/': typeof MobileUpdateRecommendationIndexRoute
  '/settings/premium/': typeof SettingsPremiumIndexRoute
  '/sign-up/add-email/': typeof SignUpAddEmailIndexRoute
  '/sign-up/add-password/': typeof SignUpAddPasswordIndexRoute
  '/sign-up/add-recs/': typeof SignUpAddRecsIndexRoute
  '/sign-up/app/': typeof SignUpAppIndexRoute
  '/sign-up/categories/': typeof SignUpCategoriesIndexRoute
  '/sign-up/done/': typeof SignUpDoneIndexRoute
  '/sign-up/features/': typeof SignUpFeaturesIndexRoute
  '/sign-up/follow/': typeof SignUpFollowIndexRoute
  '/sign-up/invite-code/': typeof SignUpInviteCodeIndexRoute
  '/sign-up/like-recs/': typeof SignUpLikeRecsIndexRoute
  '/sign-up/location/': typeof SignUpLocationIndexRoute
  '/sign-up/more/': typeof SignUpMoreIndexRoute
  '/sign-up/personalizing/': typeof SignUpPersonalizingIndexRoute
  '/sign-up/photo/': typeof SignUpPhotoIndexRoute
  '/sign-up/premium/': typeof SignUpPremiumIndexRoute
  '/sign-up/share/': typeof SignUpShareIndexRoute
  '/sign-up/survey/': typeof SignUpSurveyIndexRoute
  '/sign-up/theme/': typeof SignUpThemeIndexRoute
  '/sign-up/trial/': typeof SignUpTrialIndexRoute
  '/sign-up/verify-complete/': typeof SignUpVerifyCompleteIndexRoute
  '/sign-up/verify/': typeof SignUpVerifyIndexRoute
  '/stripe/cancel/': typeof StripeCancelIndexRoute
  '/stripe/success/': typeof StripeSuccessIndexRoute
  '/u/$username/': typeof UUsernameIndexRoute
  '/welcome/$code/': typeof WelcomeCodeIndexRoute
  '/admin/documents/compose/$documentId': typeof AdminDocumentsComposeDocumentIdRoute
  '/admin/dashboard/monthly-archival-picks/': typeof AdminDashboardMonthlyArchivalPicksIndexRoute
  '/admin/dashboard/stats/': typeof AdminDashboardStatsIndexRoute
  '/admin/dashboard/users/': typeof AdminDashboardUsersIndexRoute
  '/admin/documents/drafts/': typeof AdminDocumentsDraftsIndexRoute
  '/admin/email/blasts/': typeof AdminEmailBlastsIndexRoute
  '/admin/email/subscribers/': typeof AdminEmailSubscribersIndexRoute
  '/settings/manage/blocks/': typeof SettingsManageBlocksIndexRoute
  '/settings/manage/email/': typeof SettingsManageEmailIndexRoute
  '/u/$username/asks/': typeof UUsernameAsksIndexRoute
  '/u/$username/followers/': typeof UUsernameFollowersIndexRoute
  '/u/$username/following/': typeof UUsernameFollowingIndexRoute
  '/u/$username/latest-ask/': typeof UUsernameLatestAskIndexRoute
  '/u/$username/lists/': typeof UUsernameListsIndexRoute
  '/u/$username/recs/': typeof UUsernameRecsIndexRoute
  '/unsubscribe/email-List/survey/': typeof UnsubscribeEmailListSurveyIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/about'
    | '/admin'
    | '/sign-up'
    | '/activate/$activationKey'
    | '/ask/$promptId'
    | '/convo/$convoId'
    | '/l/$url'
    | '/list/$listId'
    | '/p/$articleSlugOrId'
    | '/rec/$recId'
    | '/redeem/$bonusId'
    | '/t/$key'
    | '/admin/'
    | '/app-store'
    | '/asks'
    | '/bookmarks'
    | '/careers'
    | '/categories'
    | '/convos'
    | '/cookies'
    | '/directory'
    | '/download'
    | '/drafts'
    | '/endorsed'
    | '/features'
    | '/invites'
    | '/link-in-bio'
    | '/notifications'
    | '/privacy'
    | '/random'
    | '/read'
    | '/recs'
    | '/search'
    | '/settings'
    | '/sign-in'
    | '/sign-out'
    | '/sign-up/'
    | '/spotlight-apply'
    | '/sxsw-2025'
    | '/terms'
    | '/test'
    | '/today'
    | '/unlocked-archive'
    | '/verify-another-email'
    | '/welcome'
    | '/what-is-this'
    | '/auth/email-callback/$id'
    | '/sign-up/invite/$code'
    | '/subscribe/referral/$referralId'
    | '/unsubscribe/email-List/$emailList'
    | '/admin/dashboard'
    | '/admin/staff'
    | '/admin/substack'
    | '/categories/$name'
    | '/features/recs'
    | '/home/$tab'
    | '/mobile/add-comment'
    | '/mobile/add-prompt'
    | '/mobile/add-recommendation'
    | '/mobile/update-prompt'
    | '/mobile/update-recommendation'
    | '/settings/premium'
    | '/sign-up/add-email'
    | '/sign-up/add-password'
    | '/sign-up/add-recs'
    | '/sign-up/app'
    | '/sign-up/categories'
    | '/sign-up/done'
    | '/sign-up/features'
    | '/sign-up/follow'
    | '/sign-up/invite-code'
    | '/sign-up/like-recs'
    | '/sign-up/location'
    | '/sign-up/more'
    | '/sign-up/personalizing'
    | '/sign-up/photo'
    | '/sign-up/premium'
    | '/sign-up/share'
    | '/sign-up/survey'
    | '/sign-up/theme'
    | '/sign-up/trial'
    | '/sign-up/verify-complete'
    | '/sign-up/verify'
    | '/stripe/cancel'
    | '/stripe/success'
    | '/u/$username'
    | '/welcome/$code'
    | '/admin/documents/compose/$documentId'
    | '/admin/dashboard/monthly-archival-picks'
    | '/admin/dashboard/stats'
    | '/admin/dashboard/users'
    | '/admin/documents/drafts'
    | '/admin/email/blasts'
    | '/admin/email/subscribers'
    | '/settings/manage/blocks'
    | '/settings/manage/email'
    | '/u/$username/asks'
    | '/u/$username/followers'
    | '/u/$username/following'
    | '/u/$username/latest-ask'
    | '/u/$username/lists'
    | '/u/$username/recs'
    | '/unsubscribe/email-List/survey'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/about'
    | '/activate/$activationKey'
    | '/ask/$promptId'
    | '/convo/$convoId'
    | '/l/$url'
    | '/list/$listId'
    | '/p/$articleSlugOrId'
    | '/rec/$recId'
    | '/redeem/$bonusId'
    | '/t/$key'
    | '/admin'
    | '/app-store'
    | '/asks'
    | '/bookmarks'
    | '/careers'
    | '/categories'
    | '/convos'
    | '/cookies'
    | '/directory'
    | '/download'
    | '/drafts'
    | '/endorsed'
    | '/features'
    | '/invites'
    | '/link-in-bio'
    | '/notifications'
    | '/privacy'
    | '/random'
    | '/read'
    | '/recs'
    | '/search'
    | '/settings'
    | '/sign-in'
    | '/sign-out'
    | '/sign-up'
    | '/spotlight-apply'
    | '/sxsw-2025'
    | '/terms'
    | '/test'
    | '/today'
    | '/unlocked-archive'
    | '/verify-another-email'
    | '/welcome'
    | '/what-is-this'
    | '/auth/email-callback/$id'
    | '/sign-up/invite/$code'
    | '/subscribe/referral/$referralId'
    | '/unsubscribe/email-List/$emailList'
    | '/admin/dashboard'
    | '/admin/staff'
    | '/admin/substack'
    | '/categories/$name'
    | '/features/recs'
    | '/home/$tab'
    | '/mobile/add-comment'
    | '/mobile/add-prompt'
    | '/mobile/add-recommendation'
    | '/mobile/update-prompt'
    | '/mobile/update-recommendation'
    | '/settings/premium'
    | '/sign-up/add-email'
    | '/sign-up/add-password'
    | '/sign-up/add-recs'
    | '/sign-up/app'
    | '/sign-up/categories'
    | '/sign-up/done'
    | '/sign-up/features'
    | '/sign-up/follow'
    | '/sign-up/invite-code'
    | '/sign-up/like-recs'
    | '/sign-up/location'
    | '/sign-up/more'
    | '/sign-up/personalizing'
    | '/sign-up/photo'
    | '/sign-up/premium'
    | '/sign-up/share'
    | '/sign-up/survey'
    | '/sign-up/theme'
    | '/sign-up/trial'
    | '/sign-up/verify-complete'
    | '/sign-up/verify'
    | '/stripe/cancel'
    | '/stripe/success'
    | '/u/$username'
    | '/welcome/$code'
    | '/admin/documents/compose/$documentId'
    | '/admin/dashboard/monthly-archival-picks'
    | '/admin/dashboard/stats'
    | '/admin/dashboard/users'
    | '/admin/documents/drafts'
    | '/admin/email/blasts'
    | '/admin/email/subscribers'
    | '/settings/manage/blocks'
    | '/settings/manage/email'
    | '/u/$username/asks'
    | '/u/$username/followers'
    | '/u/$username/following'
    | '/u/$username/latest-ask'
    | '/u/$username/lists'
    | '/u/$username/recs'
    | '/unsubscribe/email-List/survey'
  id:
    | '__root__'
    | '/'
    | '/about'
    | '/admin'
    | '/sign-up'
    | '/activate/$activationKey'
    | '/ask/$promptId'
    | '/convo/$convoId'
    | '/l/$url'
    | '/list/$listId'
    | '/p/$articleSlugOrId'
    | '/rec/$recId'
    | '/redeem/$bonusId'
    | '/t/$key'
    | '/admin/'
    | '/app-store/'
    | '/asks/'
    | '/bookmarks/'
    | '/careers/'
    | '/categories/'
    | '/convos/'
    | '/cookies/'
    | '/directory/'
    | '/download/'
    | '/drafts/'
    | '/endorsed/'
    | '/features/'
    | '/invites/'
    | '/link-in-bio/'
    | '/notifications/'
    | '/privacy/'
    | '/random/'
    | '/read/'
    | '/recs/'
    | '/search/'
    | '/settings/'
    | '/sign-in/'
    | '/sign-out/'
    | '/sign-up/'
    | '/spotlight-apply/'
    | '/sxsw-2025/'
    | '/terms/'
    | '/test/'
    | '/today/'
    | '/unlocked-archive/'
    | '/verify-another-email/'
    | '/welcome/'
    | '/what-is-this/'
    | '/auth/email-callback/$id'
    | '/sign-up/invite/$code'
    | '/subscribe/referral/$referralId'
    | '/unsubscribe/email-List/$emailList'
    | '/admin/dashboard/'
    | '/admin/staff/'
    | '/admin/substack/'
    | '/categories/$name/'
    | '/features/recs/'
    | '/home/$tab/'
    | '/mobile/add-comment/'
    | '/mobile/add-prompt/'
    | '/mobile/add-recommendation/'
    | '/mobile/update-prompt/'
    | '/mobile/update-recommendation/'
    | '/settings/premium/'
    | '/sign-up/add-email/'
    | '/sign-up/add-password/'
    | '/sign-up/add-recs/'
    | '/sign-up/app/'
    | '/sign-up/categories/'
    | '/sign-up/done/'
    | '/sign-up/features/'
    | '/sign-up/follow/'
    | '/sign-up/invite-code/'
    | '/sign-up/like-recs/'
    | '/sign-up/location/'
    | '/sign-up/more/'
    | '/sign-up/personalizing/'
    | '/sign-up/photo/'
    | '/sign-up/premium/'
    | '/sign-up/share/'
    | '/sign-up/survey/'
    | '/sign-up/theme/'
    | '/sign-up/trial/'
    | '/sign-up/verify-complete/'
    | '/sign-up/verify/'
    | '/stripe/cancel/'
    | '/stripe/success/'
    | '/u/$username/'
    | '/welcome/$code/'
    | '/admin/documents/compose/$documentId'
    | '/admin/dashboard/monthly-archival-picks/'
    | '/admin/dashboard/stats/'
    | '/admin/dashboard/users/'
    | '/admin/documents/drafts/'
    | '/admin/email/blasts/'
    | '/admin/email/subscribers/'
    | '/settings/manage/blocks/'
    | '/settings/manage/email/'
    | '/u/$username/asks/'
    | '/u/$username/followers/'
    | '/u/$username/following/'
    | '/u/$username/latest-ask/'
    | '/u/$username/lists/'
    | '/u/$username/recs/'
    | '/unsubscribe/email-List/survey/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AboutRoute: typeof AboutRoute
  AdminRoute: typeof AdminRouteWithChildren
  SignUpRoute: typeof SignUpRouteWithChildren
  ActivateActivationKeyRoute: typeof ActivateActivationKeyRoute
  AskPromptIdRoute: typeof AskPromptIdRoute
  ConvoConvoIdRoute: typeof ConvoConvoIdRoute
  LUrlRoute: typeof LUrlRoute
  ListListIdRoute: typeof ListListIdRoute
  PArticleSlugOrIdRoute: typeof PArticleSlugOrIdRoute
  RecRecIdRoute: typeof RecRecIdRoute
  RedeemBonusIdRoute: typeof RedeemBonusIdRoute
  TKeyRoute: typeof TKeyRoute
  AppStoreIndexRoute: typeof AppStoreIndexRoute
  AsksIndexRoute: typeof AsksIndexRoute
  BookmarksIndexRoute: typeof BookmarksIndexRoute
  CareersIndexRoute: typeof CareersIndexRoute
  CategoriesIndexRoute: typeof CategoriesIndexRoute
  ConvosIndexRoute: typeof ConvosIndexRoute
  CookiesIndexRoute: typeof CookiesIndexRoute
  DirectoryIndexRoute: typeof DirectoryIndexRoute
  DownloadIndexRoute: typeof DownloadIndexRoute
  DraftsIndexRoute: typeof DraftsIndexRoute
  EndorsedIndexRoute: typeof EndorsedIndexRoute
  FeaturesIndexRoute: typeof FeaturesIndexRoute
  InvitesIndexRoute: typeof InvitesIndexRoute
  LinkInBioIndexRoute: typeof LinkInBioIndexRoute
  NotificationsIndexRoute: typeof NotificationsIndexRoute
  PrivacyIndexRoute: typeof PrivacyIndexRoute
  RandomIndexRoute: typeof RandomIndexRoute
  ReadIndexRoute: typeof ReadIndexRoute
  RecsIndexRoute: typeof RecsIndexRoute
  SearchIndexRoute: typeof SearchIndexRoute
  SettingsIndexRoute: typeof SettingsIndexRoute
  SignInIndexRoute: typeof SignInIndexRoute
  SignOutIndexRoute: typeof SignOutIndexRoute
  SpotlightApplyIndexRoute: typeof SpotlightApplyIndexRoute
  Sxsw2025IndexRoute: typeof Sxsw2025IndexRoute
  TermsIndexRoute: typeof TermsIndexRoute
  TestIndexRoute: typeof TestIndexRoute
  TodayIndexRoute: typeof TodayIndexRoute
  UnlockedArchiveIndexRoute: typeof UnlockedArchiveIndexRoute
  VerifyAnotherEmailIndexRoute: typeof VerifyAnotherEmailIndexRoute
  WelcomeIndexRoute: typeof WelcomeIndexRoute
  WhatIsThisIndexRoute: typeof WhatIsThisIndexRoute
  AuthEmailCallbackIdRoute: typeof AuthEmailCallbackIdRoute
  SubscribeReferralReferralIdRoute: typeof SubscribeReferralReferralIdRoute
  UnsubscribeEmailListEmailListRoute: typeof UnsubscribeEmailListEmailListRoute
  CategoriesNameIndexRoute: typeof CategoriesNameIndexRoute
  FeaturesRecsIndexRoute: typeof FeaturesRecsIndexRoute
  HomeTabIndexRoute: typeof HomeTabIndexRoute
  MobileAddCommentIndexRoute: typeof MobileAddCommentIndexRoute
  MobileAddPromptIndexRoute: typeof MobileAddPromptIndexRoute
  MobileAddRecommendationIndexRoute: typeof MobileAddRecommendationIndexRoute
  MobileUpdatePromptIndexRoute: typeof MobileUpdatePromptIndexRoute
  MobileUpdateRecommendationIndexRoute: typeof MobileUpdateRecommendationIndexRoute
  SettingsPremiumIndexRoute: typeof SettingsPremiumIndexRoute
  StripeCancelIndexRoute: typeof StripeCancelIndexRoute
  StripeSuccessIndexRoute: typeof StripeSuccessIndexRoute
  UUsernameIndexRoute: typeof UUsernameIndexRoute
  WelcomeCodeIndexRoute: typeof WelcomeCodeIndexRoute
  SettingsManageBlocksIndexRoute: typeof SettingsManageBlocksIndexRoute
  SettingsManageEmailIndexRoute: typeof SettingsManageEmailIndexRoute
  UUsernameAsksIndexRoute: typeof UUsernameAsksIndexRoute
  UUsernameFollowersIndexRoute: typeof UUsernameFollowersIndexRoute
  UUsernameFollowingIndexRoute: typeof UUsernameFollowingIndexRoute
  UUsernameLatestAskIndexRoute: typeof UUsernameLatestAskIndexRoute
  UUsernameListsIndexRoute: typeof UUsernameListsIndexRoute
  UUsernameRecsIndexRoute: typeof UUsernameRecsIndexRoute
  UnsubscribeEmailListSurveyIndexRoute: typeof UnsubscribeEmailListSurveyIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AboutRoute: AboutRoute,
  AdminRoute: AdminRouteWithChildren,
  SignUpRoute: SignUpRouteWithChildren,
  ActivateActivationKeyRoute: ActivateActivationKeyRoute,
  AskPromptIdRoute: AskPromptIdRoute,
  ConvoConvoIdRoute: ConvoConvoIdRoute,
  LUrlRoute: LUrlRoute,
  ListListIdRoute: ListListIdRoute,
  PArticleSlugOrIdRoute: PArticleSlugOrIdRoute,
  RecRecIdRoute: RecRecIdRoute,
  RedeemBonusIdRoute: RedeemBonusIdRoute,
  TKeyRoute: TKeyRoute,
  AppStoreIndexRoute: AppStoreIndexRoute,
  AsksIndexRoute: AsksIndexRoute,
  BookmarksIndexRoute: BookmarksIndexRoute,
  CareersIndexRoute: CareersIndexRoute,
  CategoriesIndexRoute: CategoriesIndexRoute,
  ConvosIndexRoute: ConvosIndexRoute,
  CookiesIndexRoute: CookiesIndexRoute,
  DirectoryIndexRoute: DirectoryIndexRoute,
  DownloadIndexRoute: DownloadIndexRoute,
  DraftsIndexRoute: DraftsIndexRoute,
  EndorsedIndexRoute: EndorsedIndexRoute,
  FeaturesIndexRoute: FeaturesIndexRoute,
  InvitesIndexRoute: InvitesIndexRoute,
  LinkInBioIndexRoute: LinkInBioIndexRoute,
  NotificationsIndexRoute: NotificationsIndexRoute,
  PrivacyIndexRoute: PrivacyIndexRoute,
  RandomIndexRoute: RandomIndexRoute,
  ReadIndexRoute: ReadIndexRoute,
  RecsIndexRoute: RecsIndexRoute,
  SearchIndexRoute: SearchIndexRoute,
  SettingsIndexRoute: SettingsIndexRoute,
  SignInIndexRoute: SignInIndexRoute,
  SignOutIndexRoute: SignOutIndexRoute,
  SpotlightApplyIndexRoute: SpotlightApplyIndexRoute,
  Sxsw2025IndexRoute: Sxsw2025IndexRoute,
  TermsIndexRoute: TermsIndexRoute,
  TestIndexRoute: TestIndexRoute,
  TodayIndexRoute: TodayIndexRoute,
  UnlockedArchiveIndexRoute: UnlockedArchiveIndexRoute,
  VerifyAnotherEmailIndexRoute: VerifyAnotherEmailIndexRoute,
  WelcomeIndexRoute: WelcomeIndexRoute,
  WhatIsThisIndexRoute: WhatIsThisIndexRoute,
  AuthEmailCallbackIdRoute: AuthEmailCallbackIdRoute,
  SubscribeReferralReferralIdRoute: SubscribeReferralReferralIdRoute,
  UnsubscribeEmailListEmailListRoute: UnsubscribeEmailListEmailListRoute,
  CategoriesNameIndexRoute: CategoriesNameIndexRoute,
  FeaturesRecsIndexRoute: FeaturesRecsIndexRoute,
  HomeTabIndexRoute: HomeTabIndexRoute,
  MobileAddCommentIndexRoute: MobileAddCommentIndexRoute,
  MobileAddPromptIndexRoute: MobileAddPromptIndexRoute,
  MobileAddRecommendationIndexRoute: MobileAddRecommendationIndexRoute,
  MobileUpdatePromptIndexRoute: MobileUpdatePromptIndexRoute,
  MobileUpdateRecommendationIndexRoute: MobileUpdateRecommendationIndexRoute,
  SettingsPremiumIndexRoute: SettingsPremiumIndexRoute,
  StripeCancelIndexRoute: StripeCancelIndexRoute,
  StripeSuccessIndexRoute: StripeSuccessIndexRoute,
  UUsernameIndexRoute: UUsernameIndexRoute,
  WelcomeCodeIndexRoute: WelcomeCodeIndexRoute,
  SettingsManageBlocksIndexRoute: SettingsManageBlocksIndexRoute,
  SettingsManageEmailIndexRoute: SettingsManageEmailIndexRoute,
  UUsernameAsksIndexRoute: UUsernameAsksIndexRoute,
  UUsernameFollowersIndexRoute: UUsernameFollowersIndexRoute,
  UUsernameFollowingIndexRoute: UUsernameFollowingIndexRoute,
  UUsernameLatestAskIndexRoute: UUsernameLatestAskIndexRoute,
  UUsernameListsIndexRoute: UUsernameListsIndexRoute,
  UUsernameRecsIndexRoute: UUsernameRecsIndexRoute,
  UnsubscribeEmailListSurveyIndexRoute: UnsubscribeEmailListSurveyIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/about",
        "/admin",
        "/sign-up",
        "/activate/$activationKey",
        "/ask/$promptId",
        "/convo/$convoId",
        "/l/$url",
        "/list/$listId",
        "/p/$articleSlugOrId",
        "/rec/$recId",
        "/redeem/$bonusId",
        "/t/$key",
        "/app-store/",
        "/asks/",
        "/bookmarks/",
        "/careers/",
        "/categories/",
        "/convos/",
        "/cookies/",
        "/directory/",
        "/download/",
        "/drafts/",
        "/endorsed/",
        "/features/",
        "/invites/",
        "/link-in-bio/",
        "/notifications/",
        "/privacy/",
        "/random/",
        "/read/",
        "/recs/",
        "/search/",
        "/settings/",
        "/sign-in/",
        "/sign-out/",
        "/spotlight-apply/",
        "/sxsw-2025/",
        "/terms/",
        "/test/",
        "/today/",
        "/unlocked-archive/",
        "/verify-another-email/",
        "/welcome/",
        "/what-is-this/",
        "/auth/email-callback/$id",
        "/subscribe/referral/$referralId",
        "/unsubscribe/email-List/$emailList",
        "/categories/$name/",
        "/features/recs/",
        "/home/$tab/",
        "/mobile/add-comment/",
        "/mobile/add-prompt/",
        "/mobile/add-recommendation/",
        "/mobile/update-prompt/",
        "/mobile/update-recommendation/",
        "/settings/premium/",
        "/stripe/cancel/",
        "/stripe/success/",
        "/u/$username/",
        "/welcome/$code/",
        "/settings/manage/blocks/",
        "/settings/manage/email/",
        "/u/$username/asks/",
        "/u/$username/followers/",
        "/u/$username/following/",
        "/u/$username/latest-ask/",
        "/u/$username/lists/",
        "/u/$username/recs/",
        "/unsubscribe/email-List/survey/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/about": {
      "filePath": "about.tsx"
    },
    "/admin": {
      "filePath": "admin.tsx",
      "children": [
        "/admin/",
        "/admin/dashboard/",
        "/admin/staff/",
        "/admin/substack/",
        "/admin/documents/compose/$documentId",
        "/admin/dashboard/monthly-archival-picks/",
        "/admin/dashboard/stats/",
        "/admin/dashboard/users/",
        "/admin/documents/drafts/",
        "/admin/email/blasts/",
        "/admin/email/subscribers/"
      ]
    },
    "/sign-up": {
      "filePath": "sign-up.tsx",
      "children": [
        "/sign-up/",
        "/sign-up/invite/$code",
        "/sign-up/add-email/",
        "/sign-up/add-password/",
        "/sign-up/add-recs/",
        "/sign-up/app/",
        "/sign-up/categories/",
        "/sign-up/done/",
        "/sign-up/features/",
        "/sign-up/follow/",
        "/sign-up/invite-code/",
        "/sign-up/like-recs/",
        "/sign-up/location/",
        "/sign-up/more/",
        "/sign-up/personalizing/",
        "/sign-up/photo/",
        "/sign-up/premium/",
        "/sign-up/share/",
        "/sign-up/survey/",
        "/sign-up/theme/",
        "/sign-up/trial/",
        "/sign-up/verify-complete/",
        "/sign-up/verify/"
      ]
    },
    "/activate/$activationKey": {
      "filePath": "activate/$activationKey.tsx"
    },
    "/ask/$promptId": {
      "filePath": "ask/$promptId.tsx"
    },
    "/convo/$convoId": {
      "filePath": "convo/$convoId.tsx"
    },
    "/l/$url": {
      "filePath": "l/$url.tsx"
    },
    "/list/$listId": {
      "filePath": "list/$listId.tsx"
    },
    "/p/$articleSlugOrId": {
      "filePath": "p/$articleSlugOrId.tsx"
    },
    "/rec/$recId": {
      "filePath": "rec/$recId.tsx"
    },
    "/redeem/$bonusId": {
      "filePath": "redeem/$bonusId.tsx"
    },
    "/t/$key": {
      "filePath": "t/$key.tsx"
    },
    "/admin/": {
      "filePath": "admin/index.tsx",
      "parent": "/admin"
    },
    "/app-store/": {
      "filePath": "app-store/index.ts"
    },
    "/asks/": {
      "filePath": "asks/index.tsx"
    },
    "/bookmarks/": {
      "filePath": "bookmarks/index.tsx"
    },
    "/careers/": {
      "filePath": "careers/index.tsx"
    },
    "/categories/": {
      "filePath": "categories/index.tsx"
    },
    "/convos/": {
      "filePath": "convos/index.tsx"
    },
    "/cookies/": {
      "filePath": "cookies/index.tsx"
    },
    "/directory/": {
      "filePath": "directory/index.tsx"
    },
    "/download/": {
      "filePath": "download/index.tsx"
    },
    "/drafts/": {
      "filePath": "drafts/index.tsx"
    },
    "/endorsed/": {
      "filePath": "endorsed/index.tsx"
    },
    "/features/": {
      "filePath": "features/index.tsx"
    },
    "/invites/": {
      "filePath": "invites/index.tsx"
    },
    "/link-in-bio/": {
      "filePath": "link-in-bio/index.tsx"
    },
    "/notifications/": {
      "filePath": "notifications/index.tsx"
    },
    "/privacy/": {
      "filePath": "privacy/index.tsx"
    },
    "/random/": {
      "filePath": "random/index.tsx"
    },
    "/read/": {
      "filePath": "read/index.tsx"
    },
    "/recs/": {
      "filePath": "recs/index.tsx"
    },
    "/search/": {
      "filePath": "search/index.tsx"
    },
    "/settings/": {
      "filePath": "settings/index.tsx"
    },
    "/sign-in/": {
      "filePath": "sign-in/index.tsx"
    },
    "/sign-out/": {
      "filePath": "sign-out/index.tsx"
    },
    "/sign-up/": {
      "filePath": "sign-up/index.tsx",
      "parent": "/sign-up"
    },
    "/spotlight-apply/": {
      "filePath": "spotlight-apply/index.tsx"
    },
    "/sxsw-2025/": {
      "filePath": "sxsw-2025/index.tsx"
    },
    "/terms/": {
      "filePath": "terms/index.tsx"
    },
    "/test/": {
      "filePath": "test/index.tsx"
    },
    "/today/": {
      "filePath": "today/index.tsx"
    },
    "/unlocked-archive/": {
      "filePath": "unlocked-archive/index.tsx"
    },
    "/verify-another-email/": {
      "filePath": "verify-another-email/index.tsx"
    },
    "/welcome/": {
      "filePath": "welcome/index.tsx"
    },
    "/what-is-this/": {
      "filePath": "what-is-this/index.tsx"
    },
    "/auth/email-callback/$id": {
      "filePath": "auth/email-callback/$id.tsx"
    },
    "/sign-up/invite/$code": {
      "filePath": "sign-up/invite/$code.tsx",
      "parent": "/sign-up"
    },
    "/subscribe/referral/$referralId": {
      "filePath": "subscribe/referral/$referralId.tsx"
    },
    "/unsubscribe/email-List/$emailList": {
      "filePath": "unsubscribe/email-List/$emailList.tsx"
    },
    "/admin/dashboard/": {
      "filePath": "admin/dashboard/index.tsx",
      "parent": "/admin"
    },
    "/admin/staff/": {
      "filePath": "admin/staff/index.tsx",
      "parent": "/admin"
    },
    "/admin/substack/": {
      "filePath": "admin/substack/index.tsx",
      "parent": "/admin"
    },
    "/categories/$name/": {
      "filePath": "categories/$name/index.tsx"
    },
    "/features/recs/": {
      "filePath": "features/recs/index.tsx"
    },
    "/home/$tab/": {
      "filePath": "home/$tab/index.tsx"
    },
    "/mobile/add-comment/": {
      "filePath": "mobile/add-comment/index.tsx"
    },
    "/mobile/add-prompt/": {
      "filePath": "mobile/add-prompt/index.tsx"
    },
    "/mobile/add-recommendation/": {
      "filePath": "mobile/add-recommendation/index.tsx"
    },
    "/mobile/update-prompt/": {
      "filePath": "mobile/update-prompt/index.tsx"
    },
    "/mobile/update-recommendation/": {
      "filePath": "mobile/update-recommendation/index.tsx"
    },
    "/settings/premium/": {
      "filePath": "settings/premium/index.tsx"
    },
    "/sign-up/add-email/": {
      "filePath": "sign-up/add-email/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/add-password/": {
      "filePath": "sign-up/add-password/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/add-recs/": {
      "filePath": "sign-up/add-recs/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/app/": {
      "filePath": "sign-up/app/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/categories/": {
      "filePath": "sign-up/categories/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/done/": {
      "filePath": "sign-up/done/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/features/": {
      "filePath": "sign-up/features/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/follow/": {
      "filePath": "sign-up/follow/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/invite-code/": {
      "filePath": "sign-up/invite-code/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/like-recs/": {
      "filePath": "sign-up/like-recs/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/location/": {
      "filePath": "sign-up/location/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/more/": {
      "filePath": "sign-up/more/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/personalizing/": {
      "filePath": "sign-up/personalizing/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/photo/": {
      "filePath": "sign-up/photo/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/premium/": {
      "filePath": "sign-up/premium/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/share/": {
      "filePath": "sign-up/share/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/survey/": {
      "filePath": "sign-up/survey/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/theme/": {
      "filePath": "sign-up/theme/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/trial/": {
      "filePath": "sign-up/trial/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/verify-complete/": {
      "filePath": "sign-up/verify-complete/index.tsx",
      "parent": "/sign-up"
    },
    "/sign-up/verify/": {
      "filePath": "sign-up/verify/index.tsx",
      "parent": "/sign-up"
    },
    "/stripe/cancel/": {
      "filePath": "stripe/cancel/index.tsx"
    },
    "/stripe/success/": {
      "filePath": "stripe/success/index.tsx"
    },
    "/u/$username/": {
      "filePath": "u/$username/index.tsx"
    },
    "/welcome/$code/": {
      "filePath": "welcome/$code/index.tsx"
    },
    "/admin/documents/compose/$documentId": {
      "filePath": "admin/documents/compose/$documentId.tsx",
      "parent": "/admin"
    },
    "/admin/dashboard/monthly-archival-picks/": {
      "filePath": "admin/dashboard/monthly-archival-picks/index.tsx",
      "parent": "/admin"
    },
    "/admin/dashboard/stats/": {
      "filePath": "admin/dashboard/stats/index.tsx",
      "parent": "/admin"
    },
    "/admin/dashboard/users/": {
      "filePath": "admin/dashboard/users/index.tsx",
      "parent": "/admin"
    },
    "/admin/documents/drafts/": {
      "filePath": "admin/documents/drafts/index.tsx",
      "parent": "/admin"
    },
    "/admin/email/blasts/": {
      "filePath": "admin/email/blasts/index.tsx",
      "parent": "/admin"
    },
    "/admin/email/subscribers/": {
      "filePath": "admin/email/subscribers/index.tsx",
      "parent": "/admin"
    },
    "/settings/manage/blocks/": {
      "filePath": "settings/manage/blocks/index.tsx"
    },
    "/settings/manage/email/": {
      "filePath": "settings/manage/email/index.tsx"
    },
    "/u/$username/asks/": {
      "filePath": "u/$username/asks/index.tsx"
    },
    "/u/$username/followers/": {
      "filePath": "u/$username/followers/index.tsx"
    },
    "/u/$username/following/": {
      "filePath": "u/$username/following/index.tsx"
    },
    "/u/$username/latest-ask/": {
      "filePath": "u/$username/latest-ask/index.tsx"
    },
    "/u/$username/lists/": {
      "filePath": "u/$username/lists/index.tsx"
    },
    "/u/$username/recs/": {
      "filePath": "u/$username/recs/index.tsx"
    },
    "/unsubscribe/email-List/survey/": {
      "filePath": "unsubscribe/email-List/survey/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
