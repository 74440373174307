import React, { useEffect, useState, useRef } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { gql } from 'shared/__generated__/gql';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { useQuery } from '@apollo/client';
import { useAuth } from 'shared/misc/hooks/useAuth';
import RecommendationItem from 'shared/misc/components/by-type/recommendations/RecommendationItem';
import { ResponsiveValue } from '@chakra-ui/react';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'web2/app/components/button';

const INTRO_TEXT =
  "Tap the green star for the posts you'd recommend, and the red thumbs down on the ones you wouldn't.";
const LIKE_IMAGE = 'star-like-icon.png';
const DISLIKE_IMAGE = 'thumb-dislike-icon.png';

const GetOnboardingRecs = gql(/* GraphQL */ `
  query getOnboardingRecs {
    onboardingRecs {
      ...RecommendationItem
    }
  }
`);

const fadeInStyle = {
  opacity: 1,
  transform: 'translateY(0)',
  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
};

export const Route = createFileRoute('/sign-up/like-recs/')({
  component: LikeRecs,
  staleTime: 60 * 1000,
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query({
      query: GetOnboardingRecs,
    });
    return { data };
  },
});

function LikeRecs() {
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.FEATURES] });
  const { setNavigation } = useSignUpNavigation();
  const { trackEvent } = useAnalytics();
  const [currentRec, setCurrentRec] = useState(0);
  const { user } = useAuth();
  const [animationStyle, setAnimationStyle] = useState({});
  const [displayText, skipToEndOfTypewriter, isTyping] = useTypewriter(INTRO_TEXT, 50);

  const { data } = Route.useLoaderData();
  const recs = data?.onboardingRecs || [];

  type UserAction = { id: string; action: 'like' | 'dislike' };
  const [userActions, setUserActions] = useState<UserAction[]>([]);
  const [parentHeight, setParentHeight] = useState(0);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNavigation({
      showLeftArrow: true,
      showRightArrow: false,
      leftArrowDisabled: false,
      rightArrowDisabled: false,
      yPosition: 165,
      onLeftPress: () => {
        if (currentRec > 0) {
          setCurrentRec((prev) => prev - 1);
        } else {
          updateStep(USER_SIGN_UP_STEP.SURVEY);
        }
      },
      onSkip: async () => {
        await trackEvent('Click', 'Step_Skipped', {
          userId: user?.id,
        });
        await updateStep(USER_SIGN_UP_STEP.FEATURES);
      },
      showSkip: true,
      signupStep: USER_SIGN_UP_STEP.LIKE_RECS,
    });

    return () => {
      setNavigation({
        showLeftArrow: false,
        showRightArrow: false,
        yPosition: 260,
        leftArrowDisabled: false,
        rightArrowDisabled: false,
      });
    };
  }, [setNavigation, currentRec, recs.length]);

  useEffect(() => {
    if (itemRef.current) {
      setParentHeight(itemRef.current.offsetHeight);
    }
    if (recs[currentRec]) {
      setAnimationStyle({ opacity: 0, transform: 'translateY(-50px)' });
      setTimeout(() => {
        setAnimationStyle({ ...fadeInStyle });
      }, 250);
    }
  }, [currentRec, recs]);

  const dislike = async () => {
    setAnimationStyle({
      transform: 'rotate(-10deg) translateX(-100%)',
      opacity: 0,
      transition:
        'transform 0.35s ease-in-out, opacity 0.35s ease-in-out, height 0.25s ease-in-out',
    });
    const currentRecId = recs[currentRec].id;
    setTimeout(async () => {
      setUserActions((prev) => [...prev, { id: currentRecId, action: 'dislike' }]);
      if (currentRec < recs.length - 1) {
        await trackEvent('Click', 'Rec_Disliked', {
          userId: user?.id,
          recId: currentRecId,
        });
        setCurrentRec((prev) => prev + 1);
        setAnimationStyle({ opacity: 0, transform: 'translateY(-50px)' });
      } else {
        await handleDone();
      }
    }, 350);
  };

  const like = async () => {
    setAnimationStyle({
      transform: 'rotate(10deg) translateX(100%)',
      opacity: 0,
      transition:
        'transform 0.35s ease-in-out, opacity 0.35s ease-in-out, height 0.25s ease-in-out',
    });
    const currentRecId = recs[currentRec].id;
    setTimeout(async () => {
      setUserActions((prev) => [...prev, { id: currentRecId, action: 'like' }]);
      if (currentRec < recs.length - 1) {
        await trackEvent('Click', 'Rec_Liked', {
          userId: user?.id,
          recId: currentRecId,
        });
        setCurrentRec((prev) => prev + 1);
        setAnimationStyle({ opacity: 0, transform: 'translateY(-50px)' });
      } else {
        await handleDone();
      }
    }, 350);
  };

  const handleDone = async () => {
    await trackEvent('Click', 'Recs_Reviewed', {
      userId: user?.id,
    });
    await updateStep(USER_SIGN_UP_STEP.FEATURES);
  };

  return (
    <div>
      <div className="min-h-[54px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
        {displayText}
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        {currentRec + 1}/{recs.length} posts
      </div>
      <div className="flex justify-center items-start md:items-center relative mt-[96px] md:mt-[36px] min-h-[475px] h-[${parentHeight}px] transition-height duration-250 ease-in-out">
        <div className="absolute right-0 top-[-88px] md:top-1/2 md:transform md:-translate-y-1/2 transition-transform duration-250 ease-in-out">
          <Button
            variant="icon"
            className="w-20 h-20 relative rounded-full m-0 p-0 transition-transform duration-300 ease-in-out hover:scale-95 hover:rotate-12 active:rotate-0 active:scale-95"
            onClick={like}
          >
            <PerfImage
              src={`https://files.pi.fyi/${LIKE_IMAGE}`}
              alt="like"
              width="52"
              height="52"
              imageProps={{ maxHeight: '80px' }}
              boxProps={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
              }}
            />
          </Button>
        </div>
        <div
          className="min-h-[200px] flex justify-center items-center transition-height duration-250 ease-in-out w-full max-w-[300px] md:max-w-[400px] pointer-events-none"
          style={animationStyle}
          ref={itemRef}
        >
          {recs[currentRec] && <RecommendationItem rec={recs[currentRec]} />}
        </div>
        <div className="absolute left-0 top-[-88px] md:top-1/2 md:transform md:-translate-y-1/2 transition-transform duration-250 ease-in-out">
          <Button
            variant="icon"
            className="w-20 h-20 relative rounded-full m-0 p-0 transition-transform duration-300 ease-in-out hover:scale-95 hover:rotate-[-12deg] active:rotate-0 active:scale-95"
            onClick={dislike}
          >
            <PerfImage
              src={`https://files.pi.fyi/${DISLIKE_IMAGE}`}
              alt="dislike"
              width="52"
              height="52"
              imageProps={{ maxHeight: '80px' }}
              boxProps={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
}
