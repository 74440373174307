import { useCallback } from 'react';
import { Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { InView } from 'react-intersection-observer';
import { useApolloClient, useMutation } from '@apollo/client';
import { Link } from '@tanstack/react-router';
import { FeedItemType, NotificationFeedItemFragment } from 'shared/__generated__/graphql';
import { getRelativeDateString } from 'shared/dates';
import { MARK_AS_SEEN_MUTATION } from 'shared/misc/graphql/FeedItemMutations';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useFeedItemData } from './utils/use-feed-item-data';
import { MergableFeedItemResult } from './utils/feed-utils';
import { UserAvatar } from '../user/user-avatar';
import _ from 'lodash';
import { Link as TWLink } from '@tanstack/react-router';

interface SidebarFeedItemProps {
  feedItem: MergableFeedItemResult | NotificationFeedItemFragment;
  markSeen?: boolean;
  showHasSeenDot?: boolean;
  hideTypes?: Set<string | FeedItemType>;
  size?: 'small' | 'large';
  truncateLength?: number;
  highlightText?: boolean;
  withVerticalSpacing?: boolean;
}

export default function SidebarFeedItem({
  feedItem,
  markSeen = false,
  hideTypes = new Set([]),
  showHasSeenDot,
  size = 'small',
  truncateLength = 25,
  highlightText,
  withVerticalSpacing = false,
}: SidebarFeedItemProps) {
  const auth = useAuth<true>();

  const displayData = useFeedItemData({
    feedItem,
    hideTypes,
    referenceUserId: auth?.user?.id,
  });

  const [markAsSeenFn] = useMutation(MARK_AS_SEEN_MUTATION, {
    refetchQueries: ['getNotificationsView'],
  });

  const client = useApolloClient();

  const markAsSeen = useCallback(() => {
    if ('isMerged' in feedItem) {
      feedItem.feedItems.forEach((f) =>
        markAsSeenFn({
          variables: {
            targetFeedItemId: f.id,
          },
          refetchQueries: ['getNotificationsView'],
          optimisticResponse: {
            markAsSeen: {
              __typename: 'FeedItem',
              id: f.id,
              seen: true,
            },
          },
        }),
      );
    } else {
      markAsSeenFn({
        variables: {
          targetFeedItemId: feedItem.id,
        },
        refetchQueries: ['getNotificationsView'],
        optimisticResponse: {
          markAsSeen: {
            __typename: 'FeedItem',
            id: feedItem.id,
            seen: true,
          },
        },
      });
    }
  }, [client, markAsSeenFn, feedItem]);

  if (!displayData) {
    return null;
  }

  const { imageSrc, imageFile, mainLink, mainContent, imageLink, imageTooltip, mainTooltip } =
    displayData;

  const imageDimension = size === 'small' ? 24 : 32;

  let imageNode = imageSrc && (
    <img
      src={imageSrc}
      width={imageDimension}
      height={imageDimension}
      alt={'image'}
      className={`w-[${imageDimension}px] max-w-[${imageDimension}px] h-[${imageDimension}px] max-h-[${imageDimension}px]`}
    />
  );

  if (imageFile && feedItem.originUser) {
    imageNode = (
      <UserAvatar
        withActiveIndicator={!showHasSeenDot}
        user={feedItem.originUser}
        avatarSize={imageDimension}
        wrapWithLink={false}
      />
    );
  }

  const finalImageNode =
    imageNode && (imageLink ? <TWLink to={imageLink}>{imageNode}</TWLink> : imageNode);

  const finalContentNode = mainLink && mainContent && (
    <TWLink to={mainLink} className={'typography-action-sm text-theme-main'}>
      <div
        className={`overflow-hidden whitespace-nowrap text-ellipsis typography-action-sm h-fit no-of-lines-1 mr-[32px] ${
          highlightText ? 'text-theme-menu-text hover:text-theme-menu-highlight' : ''
        }`}
      >
        {_.truncate(mainContent, { length: truncateLength })}
      </div>
    </TWLink>
  );

  if (!finalContentNode || !finalImageNode) {
    return null;
  }

  const finalFeedItemNode = (
    <div
      className={`flex items-center w-full relative overflow-x-visible ${
        withVerticalSpacing ? 'mb-[6px]' : ''
      }`}
      onClick={() => {
        if ('seen' in feedItem && !feedItem.seen) {
          markAsSeen();
        }
      }}
    >
      <div className="mr-[10px] relative">
        {finalImageNode}
        {showHasSeenDot && 'seen' in feedItem && !feedItem.seen && (
          <>
            <div className="absolute text-brand-highlight text-[50px] top-[-1px] right-[-1px] w-[5px] h-[5px] bg-brand-highlight rounded-[2.5px] dot" />
          </>
        )}
      </div>
      <Tooltip
        openDelay={1000}
        label={
          <div className="p-[6px]">
            <div>
              {mainTooltip ? `${mainTooltip} /` : ''}{' '}
              {feedItem.createdAt ? getRelativeDateString(new Date(feedItem.createdAt)) : ''}
            </div>
          </div>
        }
      >
        <div className="space-y-[2px]">
          {finalContentNode}
          {feedItem.createdAt && size === 'large' ? (
            <div className="typography-action-sm text-[11px] opacity-40">
              {getRelativeDateString(new Date(feedItem.createdAt))}
            </div>
          ) : (
            ''
          )}
        </div>
      </Tooltip>
    </div>
  );

  return markSeen ? (
    <InView
      threshold={1}
      onChange={(inView) => {
        if (inView) {
          if ('seen' in feedItem && !feedItem.seen) {
            markAsSeen();
          }
        }
      }}
    >
      {finalFeedItemNode}
    </InView>
  ) : (
    finalFeedItemNode
  );
}
