import { useAuth } from 'shared/misc/hooks/useAuth';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { UserCoreFragment } from 'shared/__generated__/graphql';

export default function UserAvatarStack({
  users,
  size,
}: {
  users: UserCoreFragment[];
  size?: number;
}) {
  const auth = useAuth();

  const [user1, user2] = users.sort((a, b) => (a.id !== auth.user?.id ? 0 : -1));

  const AVATAR_SIZE = size || 28;
  const MULTI_AVATAR_SIZE = AVATAR_SIZE ? AVATAR_SIZE - 4 : 24;

  const user1Node = (
    <PerfImageFromFile
      file={user1.avatarPhotoFile}
      useFallbackAvatarSrc
      alt="profile photo"
      quality={40}
      width={!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}
      height={!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}
      style={{
        width: `${!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}px`,
        height: `${!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}px`,
      }}
      className="object-cover"
    />
  );

  if (!user2) {
    return user1Node;
  }

  const user2Node = (
    <PerfImageFromFile
      file={user2.avatarPhotoFile}
      useFallbackAvatarSrc
      alt="profile photo"
      quality={40}
      width={MULTI_AVATAR_SIZE}
      height={MULTI_AVATAR_SIZE}
      style={{
        width: `${MULTI_AVATAR_SIZE}px`,
        height: `${MULTI_AVATAR_SIZE}px`,
      }}
      className="object-cover"
    />
  );

  return (
    <div className="relative w-[28px] h-[28px] my-[8px]">
      {user1Node}
      <div className="absolute w-[28px] h-[28px] top-[8px] left-[8px]">{user2Node}</div>
    </div>
  );
}
