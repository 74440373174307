import { NetworkStatus, useQuery } from '@apollo/client';
import { useRef } from 'react';
import { VList, VListHandle } from 'virtua';
import { FeedItemType, GetHomeSidebarFeedDocument } from 'shared/__generated__/graphql';
import SkeletonSidebarFeedItem from 'shared/misc/components/SkeletonSidebarFeedItem';
import SidebarFeedItem from '../../components/by-type/feed/sidebar-feed-item';
import { combineRelatedFeedItems } from 'shared/misc/components/by-type/feed/utils/feedUtils';

const PAGE_LENGTH = 20;

const TYPES = [
  FeedItemType.AddRecommendation,
  FeedItemType.NewComment,
  FeedItemType.NewRecEndorse,
  FeedItemType.NewUser,
  FeedItemType.NewUserFollow,
  FeedItemType.NewPrompt,
  FeedItemType.NewArticleEndorse,
  FeedItemType.NewRePost,
  FeedItemType.NewRePostWithContent,
  FeedItemType.NewPromptRecReply,
];

export default function ActivityFeedModule() {
  const listRef = useRef<VListHandle>(null);
  const fetchedCountRef = useRef(-1);

  const { data, fetchMore, loading, networkStatus } = useQuery(GetHomeSidebarFeedDocument, {
    variables: {
      first: PAGE_LENGTH,
      showGlobalFeed: true,
      types: TYPES,
    },
    fetchPolicy: 'cache-and-network',
    initialFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
  });

  const isRefetching =
    networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  const feedItems = combineRelatedFeedItems(
    (data?.feedConnection?.edges || []).map(({ node }) => node),
  );

  const fetchNextPage = async () => {
    if (!data?.feedConnection.pageInfo.hasNextPage || isRefetching) return;

    await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.feedConnection.pageInfo.endCursor,
        types: TYPES,
      },
    });
  };

  return (
    <div className="mb-[50px] md:mb-0 bg-brand-background p-3 border border-dashed border-brand-main flex flex-col overflow-x-hidden h-[450px]">
      <p className="text-brand-main typography-heading-md pb-4 mb-2">Live Site activity</p>
      <div className="flex-1 w-full overflow-hidden">
        {!data && loading && !isRefetching ? (
          <VList className="h-[300px]">
            {[...Array(40)].map((_, index) => (
              <SkeletonSidebarFeedItem key={index} size={'large'} />
            ))}
          </VList>
        ) : (
          <VList
            ref={listRef}
            className="h-[300px]"
            onScroll={async () => {
              if (!listRef.current) return;
              const currentCount = feedItems.length;

              if (
                fetchedCountRef.current < currentCount &&
                listRef.current.findEndIndex() + 5 > currentCount
              ) {
                fetchedCountRef.current = currentCount;
                await fetchNextPage();
              }
            }}
          >
            {feedItems?.map((node) => (
              <SidebarFeedItem
                withVerticalSpacing
                key={node.id}
                feedItem={node}
                truncateLength={70}
                hideTypes={new Set([FeedItemType.NewMention])}
                size="small"
              />
            ))}
            {isRefetching && (
              <div className="flex justify-center py-3">
                <div className="animate-spin h-5 w-5 border-2 border-brand-main border-t-transparent rounded-full" />
              </div>
            )}
          </VList>
        )}
      </div>
    </div>
  );
}
