import {
  Box,
  Button,
  FocusLock,
  FormControl,
  FormErrorMessage,
  Text,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure,
  Tooltip,
  HStack,
  PopoverFooter,
  IconButton,
  useTheme,
  Spinner,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import {
  $createParagraphNode,
  $isRootOrShadowRoot,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  EditorState,
  EditorThemeClasses,
  LexicalEditor,
  LineBreakNode,
} from 'lexical';
import EmojiPicker, { Categories, EmojiStyle } from 'emoji-picker-react';
import React, { ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { RiDeleteBin2Fill, RiImage2Fill, RiLinkM } from 'react-icons/ri';
import { useMutation } from '@apollo/client';
import { generateLexicalContentFromString } from '../../../../lexical';
import { updateRecSchema } from '../../../../validation/schemas';
import BasicEditor from '../../rich-text/basic/BasicEditor';
import PerfInput from '../../util/PerfInput';
import { recBodyTheme, recTitleTheme } from '../../../theme/selectors/lexicalStyles';
import { RecommendationAttachmentFragment } from '../../../../__generated__/graphql';
import { isInWebView } from '../../../../webview';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { gql } from '../../../../__generated__';
import { useThemeColors } from '../../../hooks/useThemeColors';
import {
  mergeRegister,
  mediaFileReader,
  isMimeType,
  $insertNodeToNearestRoot,
  $wrapNodeInElement,
} from '@lexical/utils';
import { $createImageNode } from '../../rich-text/basic/nodes/LexicalImageNode';
import { ACCEPTABLE_IMAGE_TYPES } from '../../rich-text/basic/plugins/PastePlugin';

export const FOOTER_HEIGHT = 60;

export type RecFormInputs = {
  emoji: string;
  url?: string | undefined;
  // As Json
  titleLexicalState: EditorState | undefined | null;
  title?: string | null | undefined;
  contentLexicalState: EditorState | undefined | null;
};

export type RecFormArgs = {
  recId?: string;
  draftRecId?: string;
  editorHeight?: string;
  minBodyEditorHeight?: string;
  maxBodyEditorHeight?: string;
  maxBodyEditorWithFileHeight?: string;
  defaultValues?: {
    title?: string | null | undefined;
    emoji?: string | null | undefined;
    url?: string | null | undefined;
    // As Json
    contentLexicalState?: EditorState | undefined | null;
  };
  onChange?: ((values: RecFormInputs, event?: React.SyntheticEvent) => void) | Function;
  onSubmit?: ((values: RecFormInputs, event?: React.SyntheticEvent) => void) | Function;
  isSubmitting?: boolean;
  submitText?: string;
  ref?: React.MutableRefObject<RecFormHandle | null>;
  defaultIsOpenEmojiToolTip?: boolean;
  hideEmoji?: boolean;
  hideLink?: boolean;
  withEmojiLinkSameLine?: boolean;
  isSlim?: boolean;
  isReadOnly?: boolean;
  titleTheme?: EditorThemeClasses;
  bodyTheme?: EditorThemeClasses;
  emojiPlaceholder?: string;
  titlePlaceholder?: string | ReactNode;
  bodyPlaceholder?: string | ReactNode;
  startCollapsed?: boolean;
  disableTitle?: boolean;
  requireContent?: boolean;
  belowContentNode?: ReactNode;
  attachments?: RecommendationAttachmentFragment[];
  onOpenFilePicker?: () => any;
  onUpload?: (file: File, args: { recId?: string; draftRecId?: string }) => any;
  onRemoveAttachment?: (attachmentId?: string) => any;
  onAddAttachment?: (attachment: RecommendationAttachmentFragment) => any;
};

type FormType = UseFormReturn<RecFormInputs, any, undefined>;

export interface RecFormHandle extends FormType {}

type ReturnValue = {
  isDirty: boolean;
  submitButtonNode: ReactNode;
  mainFormNode: ReactNode;
  editLinkNode: ReactNode;
  emojiNode: ReactNode;
  editImageNode: ReactNode;
  contentNode: ReactNode;
  titleNode: ReactNode;
  isOpen?: boolean;
  ref?: React.MutableRefObject<RecFormHandle | null>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

function useRecForm({
  onChange: _onChange,
  onSubmit,
  defaultValues,
  isReadOnly = false,
  hideEmoji = false,
  hideLink = false,
  isSubmitting = false,
  submitText = 'Submit',
  editorHeight = '250px',
  minBodyEditorHeight = editorHeight,
  maxBodyEditorWithFileHeight = minBodyEditorHeight,
  maxBodyEditorHeight = editorHeight,
  ref: formRef,
  defaultIsOpenEmojiToolTip = false,
  withEmojiLinkSameLine = false,
  isSlim = false,
  titleTheme = recTitleTheme,
  bodyTheme = recBodyTheme,
  emojiPlaceholder,
  titlePlaceholder = 'What do you want to post?',
  bodyPlaceholder = (
    <Box>
      Tell us why :)
      <br />
      <br />
      Tip: tap on the emoji above to change it.
    </Box>
  ),
  startCollapsed = false,
  disableTitle = false,
  belowContentNode = null,
  requireContent = false,
  attachments = [],
  onOpenFilePicker,
  onUpload,
  onRemoveAttachment,
  onAddAttachment,
  recId,
  draftRecId,
}: RecFormArgs): ReturnValue {
  const popoverState = useDisclosure();
  const editLinkPopoverState = useDisclosure();

  const [bodyEditor, setBodyEditor] = useState<LexicalEditor | null>();
  const [titleEditor, setTitleEditor] = useState<LexicalEditor | null>();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const isOpen = (startCollapsed && !isCollapsed) || !startCollapsed;

  const initialTitle = defaultValues?.title || '';

  const IS_IN_WEB_VIEW = isInWebView();

  const [analyzeLink, analyzeLinkState] = useMutation(ANALYZE_LINK);

  const form = useForm<RecFormInputs>({
    // @ts-ignore
    resolver: yupResolver(updateRecSchema(!disableTitle, requireContent)),
    defaultValues: {
      emoji: emojiPlaceholder || defaultValues?.emoji || '⭐',
      url: defaultValues?.url || '',
      // @ts-ignore
      titleLexicalState: initialTitle ? generateLexicalContentFromString(initialTitle) : null,
      contentLexicalState: defaultValues?.contentLexicalState,
    },
  });

  const { control, register, handleSubmit, setValue, watch, reset, formState } = form;
  const { errors } = formState;

  const isDirty = form.formState.isDirty;

  const allValues = watch();

  const onReset = () => {
    reset();
    bodyEditor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    titleEditor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  };

  useEffect(() => {
    if (!defaultValues?.emoji && allValues.emoji !== emojiPlaceholder) {
      setValue('emoji', emojiPlaceholder || defaultValues?.emoji || '⭐');
    }
  }, [emojiPlaceholder]);

  const onFormSubmit: SubmitHandler<RecFormInputs> = async (values, event) => {
    event?.preventDefault();
    onSubmit?.(values);
  };

  useEffect(() => {
    const subscription = watch((values: RecFormInputs) => {
      _onChange?.(values);
    });
    return () => subscription.unsubscribe();
  }, [watch, _onChange]);

  useImperativeHandle(formRef, () => ({ ...form, reset: onReset }), [onReset]);

  useEffect(() => {
    return titleEditor?.registerNodeTransform(LineBreakNode, (node) => {
      node.remove();
    });
  }, [titleEditor]);

  const themeColors = useThemeColors();

  const mainColor = themeColors.main;

  const prevEditLinkPopoverIsOpen = useRef<boolean>(editLinkPopoverState.isOpen);
  const prevUrl = useRef<string | undefined>(allValues.url);
  const attemptedUrls = useRef(new Set());

  useEffect(() => {
    if (
      prevEditLinkPopoverIsOpen.current &&
      !editLinkPopoverState.isOpen &&
      allValues.url &&
      !attemptedUrls.current.has(allValues.url)
    ) {
      analyzeLink({
        variables: { recId, draftRecId, url: allValues.url },
        onCompleted: (data) => {
          const attachment = data.analyzeLink?.attachment;
          if (attachment) onAddAttachment?.(attachment);

          const titleAsString = (data.analyzeLink?.draftRec || data.analyzeLink?.rec)?.title;
          const titleFromLink = data.analyzeLink?.otherFields?.ogTitle;

          if (!titleAsString && titleFromLink) {
            const newEditorState = titleEditor?.parseEditorState(
              JSON.stringify(generateLexicalContentFromString(titleFromLink)),
            );
            setValue('titleLexicalState', newEditorState);
            titleEditor?.setEditorState(newEditorState!);
          }
        },
      });
      attemptedUrls.current.add(allValues.url);
    }
    prevUrl.current = allValues.url;
    prevEditLinkPopoverIsOpen.current = editLinkPopoverState.isOpen;
  }, [editLinkPopoverState.isOpen, allValues.url, setValue]);

  useEffect(() => {
    if (bodyEditor) {
      return mergeRegister(
        bodyEditor?.registerCommand(
          DRAG_DROP_PASTE,
          (files) => {
            (async () => {
              if (!onUpload) return;
              const filesResult = await mediaFileReader(
                files,
                [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x),
              );
              for (const { file } of filesResult) {
                if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
                  onUpload(file, { recId, draftRecId });
                }
              }
            })();
            return true;
          },
          COMMAND_PRIORITY_HIGH,
        ),
      );
    }
  }, [bodyEditor, onUpload]);

  const attachment = attachments?.find((a) => a.type === 'IMAGE');
  const image = attachment?.file;
  const isImageUploaded = image && image?.isUploaded;

  const imagePreviewNode = isImageUploaded && (
    <HStack spacing="6px" alignItems="center" {...(isReadOnly ? { pointerEvents: 'none' } : {})}>
      <Tooltip label={!isReadOnly ? 'Change image' : ''}>
        <Box onClick={onOpenFilePicker} cursor="pointer" maxWidth="200px" objectFit="contain">
          <PerfImageFromFile file={image} alt="recommendation image" />
        </Box>
      </Tooltip>

      {!isReadOnly && (
        <Tooltip label={!isReadOnly ? 'Remove image' : ''}>
          <IconButton
            variant="icon"
            color="main"
            icon={<RiDeleteBin2Fill size="20px" />}
            onClick={() => {
              if (onRemoveAttachment) {
                onRemoveAttachment(attachment.id);
              }
            }}
            textStyle="brand.headingLg"
            aria-label="Add link"
          />
        </Tooltip>
      )}
    </HStack>
  );

  const editImageNode = (
    <HStack spacing="6px" alignItems="center">
      {/* {isImageUploaded && imagePreviewNode} */}
      <Tooltip label={allValues.url ? 'Add more images' : 'Add up to 5 images'}>
        <IconButton
          variant="icon"
          icon={<RiImage2Fill size={!isSlim ? '30px' : '28px'} />}
          onClick={onOpenFilePicker}
          textStyle="brand.headingLg"
          aria-label="Add link"
          opacity={image ? 1 : 0.3}
          color={image ? 'brand.highlight' : 'brand.main'}
        />
      </Tooltip>
      {!isImageUploaded && image && !analyzeLinkState.loading && <Spinner size="xs" />}
    </HStack>
  );

  const editLinkNode = (
    <HStack spacing="12px">
      <Popover {...editLinkPopoverState} placement="bottom-start">
        <PopoverTrigger>
          <Box>
            <Tooltip label={allValues.url ? 'Edit link' : 'Add link?'}>
              <IconButton
                variant="icon"
                icon={<RiLinkM size="26px" />}
                textStyle="brand.headingLg"
                aria-label="Add link"
                opacity={allValues.url ? 1 : 0.3}
                color={allValues.url ? 'brand.highlight' : 'brand.main'}
              />
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                e.stopPropagation();
              }
            }}
          >
            <FocusLock persistentFocus={false}>
              <PopoverCloseButton />
              <PopoverHeader>{allValues.url ? 'Modify link' : 'Add link'}</PopoverHeader>
              <PopoverBody p="0px" pt="5px">
                <Box mb="16px" fontSize="16px" textStyle="brand.headingLg">
                  <PerfInput
                    placeholder="URL (Optional)"
                    {...register('url')}
                    error={errors.url}
                    _placeholder={{
                      color: `${mainColor || 'black'} !important`,
                      opacity: '90%',
                    }}
                  />
                </Box>
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <HStack spacing="6px">
                  {allValues.url && (
                    <Button
                      size="sm"
                      variant="secondaryBlack"
                      onClick={() => setValue('url', undefined)}
                    >
                      Remove
                    </Button>
                  )}
                  <Button size="sm" variant="primaryBlack" onClick={editLinkPopoverState.onClose}>
                    Close
                  </Button>
                </HStack>
              </PopoverFooter>
            </FocusLock>
          </PopoverContent>
        </Portal>
      </Popover>
      {analyzeLinkState.loading && <Spinner size="xs" />}
      {!editLinkPopoverState.isOpen && errors.url && (
        <Text textStyle="brand.formError">({errors.url?.message})</Text>
      )}
    </HStack>
  );

  const emojiNode = (
    <Popover {...popoverState} placement="bottom-start">
      <PopoverTrigger>
        <Button
          padding="0px"
          variant="icon"
          {...(popoverState.isOpen
            ? {
                // border: '1px',
                // borderColor: 'brand.highlight',
                // m: '-1px',
                _hover: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }
            : {})}
          onClick={popoverState.onToggle}
        >
          <Tooltip
            label="Click here to select an emoji."
            defaultIsOpen={defaultIsOpenEmojiToolTip && allValues.emoji === '😃'}
          >
            <Text textStyle="brand.headingLg" fontSize={!isSlim ? '33px' : '26px'}>
              {allValues.emoji || defaultValues?.emoji || '😃'}
            </Text>
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.stopPropagation();
            }
          }}
        >
          <FocusLock persistentFocus={false}>
            <PopoverCloseButton />
            <PopoverHeader>SELECT AN EMOJI</PopoverHeader>
            <PopoverBody p="0px" pt="5px">
              <Controller
                control={control}
                name="emoji"
                render={({ field: { ref, onChange } }) => (
                  <Box ref={ref}>
                    <EmojiPicker
                      onEmojiClick={({ emoji }) => {
                        onChange(emoji);
                        popoverState.onClose();
                      }}
                      width="100%"
                      height="100%"
                      skinTonesDisabled={true}
                      lazyLoadEmojis
                      emojiStyle={EmojiStyle.NATIVE}
                      autoFocusSearch={false}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={EMOJI_CATEGORIES}
                    />
                  </Box>
                )}
              />
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  const titleNode = (
    <Box
      width="100%"
      fontWeight="normal"
      onClick={() => {
        if (startCollapsed && isCollapsed) {
          setIsCollapsed(false);
        }
      }}
      marginBottom={IS_IN_WEB_VIEW ? '16px' : undefined}
    >
      <FormControl isInvalid={!!errors.titleLexicalState}>
        <Controller
          control={control}
          name="titleLexicalState"
          render={({ field: { onChange, ref } }) => (
            <BasicEditor
              isReadOnly={isReadOnly}
              key={isReadOnly ? 'title-readOnly' : 'title-editable'}
              isPlainText
              onChange={(editorState, _editor) => {
                onChange(editorState);
                setTitleEditor(_editor);
              }}
              ref={ref}
              shouldAutoFocus={false}
              initialEditorState={allValues.titleLexicalState}
              borderRadius="none"
              placeholder={titlePlaceholder}
              textStyle="brand.headingSm"
              border="1px solid transparent"
              borderColor="transparent"
              minHeight={isSlim ? { base: '16px', md: '24px' } : { base: '20px', md: '36px' }}
              maxHeight="150px"
              padding="0px"
              paddingLeft="0px"
              fontSize={isSlim ? { base: '16px', md: '24px' } : { base: '20px', md: '36px' }}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'block !important',
                  width: '10px !important',
                },
                ...(isSlim
                  ? {
                      '.content-editable': {
                        paddingLeft: '0px !important',
                        caretColor: `${mainColor} !important`,
                      },
                    }
                  : {
                      '.content-editable': {
                        caretColor: `${mainColor} !important`,
                      },
                    }),
              }}
              contentEditableProps={{
                padding: '0px',
                paddingLeft: '0px',
              }}
              _focus={{
                paddingBottom: '-1px',
                boxShadow: 'none',
              }}
              editorTheme={titleTheme}
              readOnlyEditorTheme={titleTheme}
              placeholderProps={{
                color: `${mainColor || 'black'} !important`,
                opacity: '60% !important',
                cursor: 'text',
                // textStyle: 'brand.bodyLg',
                // fontSize: '20px',
              }}
            />
          )}
        />
        {errors.titleLexicalState && (
          <FormErrorMessage>{errors.titleLexicalState?.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );

  const showTop =
    (!hideEmoji && emojiNode) ||
    (!hideLink && editLinkNode) ||
    (withEmojiLinkSameLine && !disableTitle && titleNode);

  const contentNode = (
    <Box width="100%" fontWeight="normal" flexGrow={1} display={!isOpen ? 'none' : undefined}>
      <FormControl
        isInvalid={!!errors.contentLexicalState}
        h="100%"
        minHeight={minBodyEditorHeight}
        maxHeight={{
          base: IS_IN_WEB_VIEW
            ? undefined
            : isSlim
            ? attachments[0]?.id
              ? minBodyEditorHeight
              : maxBodyEditorHeight
            : '650px',
          md: attachments[0]?.id ? maxBodyEditorWithFileHeight : maxBodyEditorHeight,
        }}
        overflowY="scroll"
        m="0px"
      >
        <Controller
          control={control}
          name="contentLexicalState"
          render={({ field: { onChange, ref } }) => (
            <BasicEditor
              isReadOnly={isReadOnly}
              key={isReadOnly ? 'content-readOnly' : 'content-editable'}
              onChange={(editorState, _editor) => {
                onChange(editorState);
                setBodyEditor(_editor);
              }}
              ref={ref}
              includeMentions
              uploadImageOnFilePaste
              shouldAutoFocus={false}
              initialEditorState={allValues.contentLexicalState}
              borderRadius="none"
              placeholder={bodyPlaceholder}
              textStyle="brand.bodyLg"
              border="1px solid transparent"
              borderColor="transparent"
              fontSize="20px"
              minHeight={minBodyEditorHeight}
              maxHeight={{
                base: IS_IN_WEB_VIEW
                  ? undefined
                  : isSlim
                  ? attachments.length > 0
                    ? minBodyEditorHeight
                    : maxBodyEditorHeight
                  : '650px',
                md: attachments.length > 0 ? maxBodyEditorWithFileHeight : maxBodyEditorHeight,
              }}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'block !important',
                  width: '10px !important',
                },
                // ...(isSlim
                //   ? {
                //       '.content-editable': {
                //         paddingLeft: '0px !important',
                //         caretColor: `${mainColor} !important`,
                //       },
                //     }
                //   : {
                //       '.content-editable': {
                //         paddingLeft: '0px !important',
                //         caretColor: `${mainColor} !important`,
                //       },
                //     }),
              }}
              sx={{
                '.editor-placeholder': {
                  // p: '8px',
                  // pt: '10px',
                  // pl: '12px',
                },
              }}
              contentEditableProps={{
                p: '0px',
                pb: '10px',
                paddingLeft: '0px',
              }}
              _focus={{
                paddingBottom: '-1px',
                // borderColor: 'brand.highlight',
                // border: '1px solid transparent',
                // borderBottom: '1px solid',
                // borderBottomColor: 'brand.highlight',
                boxShadow: 'none',
              }}
              editorTheme={bodyTheme}
              readOnlyEditorTheme={recBodyTheme}
              placeholderProps={{
                // color: 'lightgrey !important',
                color: `${mainColor || 'black'} !important`,
                opacity: '60% !important',
                // paddingLeft: '0px',
                // top: '4px',
                // left: '0px',
                // textStyle: 'brand.bodyLg',
                // fontSize: '20px',
              }}
            />
          )}
        />
        {errors.contentLexicalState && (
          <FormErrorMessage position="absolute" bottom="0px">
            {errors.contentLexicalState?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );

  const mainFormNode = (
    <Box h="auto" as="form" onSubmit={handleSubmit(onFormSubmit)}>
      {showTop && (
        <Stack
          display="flex"
          spacing={!isSlim ? '24px' : '12px'}
          mt={!isSlim ? '12px' : '0px'}
          flexFlow="column"
          {...(isReadOnly ? { pointerEvents: 'none' } : {})}
        >
          <HStack spacing={!isSlim ? '16px' : '6px'} alignItems="center">
            {!hideEmoji && emojiNode}
            <HStack spacing={!isSlim ? '12px' : '4px'} alignItems="center">
              {editImageNode}
              {!hideLink && editLinkNode}
            </HStack>
            {withEmojiLinkSameLine && !disableTitle && titleNode}
          </HStack>
          <FormErrorMessage>{errors.emoji?.message}</FormErrorMessage>
        </Stack>
      )}
      <Stack
        display="flex"
        flexDirection="column"
        h="100%"
        spacing={!isSlim ? '20px' : '12px'}
        mt={showTop ? (!isSlim ? '24px' : '12px') : undefined}
        overflowY="auto"
      >
        {!withEmojiLinkSameLine && !disableTitle && titleNode}
        {contentNode}
        {imagePreviewNode}
      </Stack>
      {belowContentNode}
    </Box>
  );

  const submitButtonNode = (
    <Button
      onClick={handleSubmit(onFormSubmit)}
      variant="primary"
      textStyle="brand.actionSm"
      fontSize="13.5px"
      isLoading={isSubmitting}
    >
      {submitText}
    </Button>
  );

  return {
    isDirty,
    isOpen,
    editImageNode: !IS_IN_WEB_VIEW ? editImageNode : null,
    submitButtonNode,
    mainFormNode,
    emojiNode,
    editLinkNode,
    contentNode,
    titleNode,
    ref: formRef,
    onSubmit: handleSubmit(onFormSubmit),
  };
}

const ANALYZE_LINK = gql(/* GraphQL */ `
  mutation analyzeLink($recId: String, $draftRecId: String, $url: String!) {
    analyzeLink(url: $url, recId: $recId, draftRecId: $draftRecId) {
      otherFields
      attachment {
        id
        ...RecommendationAttachment
      }
      rec {
        id
        title
        attachments {
          ...RecommendationAttachment
        }
      }
      draftRec {
        id
        title
        attachments {
          ...RecommendationAttachment
        }
      }
    }
  }
`);

const EMOJI_CATEGORIES = [
  {
    category: Categories.SUGGESTED,
    name: 'Recently Used',
  },
  {
    category: Categories.OBJECTS,
    name: 'Objects...',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Activities...',
  },
  {
    category: Categories.SYMBOLS,
    name: 'Symbols...',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Food & Drink...',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Travel...',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Faces...',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Nature & Animals...',
  },
  {
    category: Categories.FLAGS,
    name: 'Flags...',
  },
];

export default useRecForm;
