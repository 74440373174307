import { RecommendationNode } from '../nodes/LexicalRecommendationNode';
import { MentionNode } from '../nodes/MentionNode';
import { PromptNode } from '../nodes/LexicalPromptNode';
import { ImageNode } from '../nodes/LexicalImageNode';
import { GenericComponentNode } from '../nodes/LexicalGenericComponentNode';
import { DecoratorNode, LexicalNode } from 'lexical';
import RecommendationComponent from '../nodes/components/LexicalRecommendationComponent';
import MentionComponent from '../nodes/components/MentionComponent';
import PromptComponent from '../nodes/components/LexicalPromptComponent';
import ImageComponent from '../nodes/components/LexicalImageComponent';
import GenericComponent from '../nodes/components/LexicalGenericComponent/LexicalGenericComponent';

export function initDecorators(nodes?: any[]) {
  if (!nodes || !nodes?.includes(RecommendationNode)) {
    RecommendationNode.prototype.decorate = function () {
      if (typeof window === 'undefined') {
        return null;
      }

      return (
        <RecommendationComponent
          id={this.__id}
          rec={this.__rec}
          nodeKey={this.__key}
          updateRec={(rec) => {
            this.setRec(rec);
          }}
        />
      );
    };
  }

  if (!nodes || !nodes?.includes(MentionNode)) {
    MentionNode.prototype.decorate = function () {
      if (typeof window === 'undefined') {
        return null;
      }

      return <MentionComponent id={this.__id} user={this.__user} />;
    };
  }

  if (!nodes || !nodes?.includes(PromptNode)) {
    PromptNode.prototype.decorate = function () {
      if (typeof window === 'undefined') {
        return null;
      }

      return (
        <PromptComponent
          id={this.__id}
          prompt={this.__prompt}
          nodeKey={this.__key}
          updatePrompt={(prompt) => {
            this.setRec(prompt);
          }}
        />
      );
    };
  }

  if (!nodes || !nodes?.includes(ImageNode)) {
    ImageNode.prototype.decorate = function () {
      if (typeof window === 'undefined') {
        return null;
      }

      return (
        <ImageComponent
          id={this.__id}
          image={this.__image}
          link={this.__link}
          nodeKey={this.__key}
          setImage={(image) => {
            this.setImage(image);
          }}
          setLink={(link: string) => {
            this.setLink(link);
          }}
        />
      );
    };
  }

  if (!nodes || !nodes?.includes(GenericComponentNode)) {
    GenericComponentNode.prototype.decorate = function () {
      if (typeof window === 'undefined') {
        return null;
      }

      return (
        <GenericComponent
          componentType={this.__componentType}
          nodeKey={this.__key}
          data={this.__data}
          setComponentType={(componentType) => {
            this.setComponentType(componentType);
          }}
          setData={(data) => {
            this.setData(data);
          }}
        />
      );
    };
  }
}
