import { gql } from "../../__generated__";

export const MARK_AS_SEEN_MUTATION = gql(/* GraphQL */ `
  mutation markAsSeen($targetFeedItemId: String!) {
    markAsSeen(targetFeedItemId: $targetFeedItemId) {
      id
      seen
    }
  }
`);
