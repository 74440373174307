import React, { useContext } from 'react';
import { ListViewFragment } from '../../__generated__/graphql';

type ContextProps = {
  list: ListViewFragment;
};

export const ListContext = React.createContext<ContextProps>({} as ContextProps);

export const useListContext = () => {
  return useContext(ListContext);
};
