import type { ImageProps } from 'next/image';

interface ImageWrapperProps extends ImageProps {
  // You can add any additional props or overrides here if needed
}

export default function ImageWrapper({
  src,
  alt,
  width,
  height,
  priority,
  ...rest
}: ImageWrapperProps) {
  return <img src={src} alt={alt} width={width} height={height} {...rest} />;
}
