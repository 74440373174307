import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../utils/cn';
import { ButtonHTMLAttributes, forwardRef } from 'react';

const buttonVariants = cva(
  // Base styles
  [
    'w-fit',
    'text-[13.5px]',
    'leading-none',
    'font-normal',
    'tracking-[0.01em]',
    'transition-colors',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'p-[8px]',
    'typography-action-sm',
  ],
  {
    variants: {
      variant: {
        // Primary variant
        primary: [
          'bg-brand-highlight',
          'text-brand-background',
          'border',
          'border-transparent',
          'hover:opacity-90',
        ],

        // Primary Black variant
        primaryBlack: [
          'bg-black',
          'text-brand-white',
          'border',
          'border-transparent',
          'hover:opacity-90',
        ],

        // Primary Black variant
        primaryBlackText: [
          'bg-brand-highlight',
          '!text-black',
          'border',
          'border-transparent',
          'hover:opacity-90',
        ],

        // Secondary variant
        secondary: [
          'bg-transparent',
          'text-brand-highlight',
          'border',
          'border-brand-highlight',
          'hover:opacity-90',
        ],

        // Secondary Black variant
        secondaryBlack: [
          'bg-brand-background',
          'text-brand-black',
          'border',
          'border-brand-black',
          'hover:opacity-90',
        ],

        // Secondary Danger variant
        secondaryDanger: [
          'bg-brand-secondary',
          'text-brand-red',
          'border',
          'border-brand-red',
          'hover:opacity-90',
        ],

        // Link variant
        link: [
          '!p-0',
          'text-brand-main',
          'border-b',
          'border-dashed',
          'border-brand-main',
          'hover:text-brand-highlight',
          'hover:border-brand-highlight',
        ],

        // Link No Default Border variant
        linkNoDefaultBorder: [
          '!p-0',
          'text-inherit',
          'border-b',
          'border-dashed',
          'border-transparent',
          'hover:text-brand-highlight',
          'hover:border-brand-highlight',
        ],

        // Link No Border variant
        linkNoBorder: [
          '!p-0',
          'text-inherit',
          'border-b-0',
          'hover:text-brand-highlight',
          'hover:border-b-0',
        ],

        // Secondary Link variant
        secondaryLink: [
          '!p-0',
          'text-brand-lightgrey',
          'border-b',
          'border-dashed',
          'border-transparent',
          'hover:text-brand-highlight',
          'hover:border-brand-highlight',
        ],

        // Link No Hover variant
        linkNoHover: [
          '!p-0',
          'text-inherit',
          'border-b',
          'border-dashed',
          'border-black',
          'disabled:opacity-100',
          'disabled:text-inherit',
          'disabled:border-black',
        ],

        // Text Only variant
        textOnly: ['!p-0', 'text-inherit', 'h-fit'],

        // Text Only Highlight variant
        textOnlyHighlight: [
          '!p-0',
          '!text-brand-highlight',
          'h-fit',
          'font-brand-times',
          'text-24px',
        ],

        // Icon variant
        icon: ['!p-0', '!m-0', 'text-[20.5px]', 'w-fit', 'min-w-fit', 'border-0'],

        // Outline variant
        outline: [
          'p-0',
          'text-brand-main',
          'bg-brand-background',
          'border',
          'border-dashed',
          'border-brand-highlight',
          'hover:text-brand-highlight',
          'hover:border-brand-highlight',
        ],
      },
      size: {
        sm: [],
        md: [],
        lg: ['p-16', 'w-full'],
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, children, isLoading, isDisabled, ...props }, ref) => {
    return (
      <button
        type={props.type || 'button'}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={isDisabled || props.disabled}
      >
        {children}
        {isLoading ? '...' : ''}
      </button>
    );
  },
);

Button.displayName = 'Button';

const TWButton = Button;

export { Button, TWButton, buttonVariants };
