import { createFileRoute } from '@tanstack/react-router';
import AdminSubstack from './-admin-substack';

export const Route = createFileRoute('/admin/substack/')({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminSubstack />;
}
