

import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up/verify-complete/')({
  component: VerifyCompletePage,
});

function VerifyCompletePage() {
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.PREMIUM] });

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '50px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p="48px"
        >
          <Stack spacing={6} w="100%" maxW="400px">
            <PerfImage
              src="https://files.pi.fyi/blue-star.png"
              alt="star-blue"
              width="50"
              height="50"
            />
            <Text textStyle="brand.headingSm">Email verified.</Text>
            <HStack spacing={3}>
              <Button
                variant="primary"
                onClick={async () => {
                  await updateStep(USER_SIGN_UP_STEP.PREMIUM);
                }}
              >
                Next
              </Button>
            </HStack>
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}
