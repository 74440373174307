import React from 'react';
import { TWLink } from '../../../../apps/web2/app/components/link';

type Props = {
  children: React.ReactNode | Array<React.ReactNode> | null;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, _errorInfo) {
    console.log({ _errorInfo });
    console.error(error);
    // Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center p-6">
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-y-6 justify-center">
              <h1 className="flex flex-col items-center text-center typography-heading-xl uppercase mt-12">
                <span>😢</span>
                <span>ERROR</span>
              </h1>
              <div>
                <p className="typography-heading-sm text-center">
                  It looks like something went (very) wrong.
                </p>
              </div>
              <div className="flex flex-row justify-center space-x-4">
                <TWLink
                  variant="fancy"
                  className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
                  to="/home"
                >
                  Go Home
                </TWLink>
                <TWLink
                  variant="fancy"
                  className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
                  to="/random-feature"
                >
                  Random feature
                </TWLink>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
