import { createFileRoute } from '@tanstack/react-router';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { safeSendWebViewMessage } from 'shared/webview/index';
import AddPromptModal from 'web2/app/components/modals/add-prompt-modal';
import { AddPromptMessages } from 'shared/webview/messages';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';

export const Route = createFileRoute('/mobile/add-prompt/')({
  component: RouteComponent,
  ssr: false,
});

function RouteComponent() {
  const modalState = useDisclosure();

  const onClose = () => {
    safeSendWebViewMessage(AddPromptMessages.CLOSE);
  };

  useAutoSetTheme(ProfileThemeType.Default);

  return <AddPromptModal modalState={{ ...modalState, isOpen: true, onClose }} />;
}
