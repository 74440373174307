import { gql } from '../../__generated__';

export const CURATE_REC_MUTATION = gql(/* GraphQL */ `
  mutation curateRec($recId: String!) {
    curateRec(targetRecId: $recId) {
      id
      isCurated
    }
  }
`);

export const UNDO_CURATE_REC_MUTATION = gql(/* GraphQL */ `
  mutation undoCurateRec($recId: String!) {
    undoCurateRec(targetRecId: $recId) {
      id
      isCurated
    }
  }
`);

export const SPOTLIGHT_USER_MUTATION = gql(/* GraphQL */ `
  mutation spotlightUser($userId: String!) {
    spotlightUser(userId: $userId)
  }
`);
