import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, type SystemStyleFunction } from '@chakra-ui/styled-system';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  text: {
    color: 'brand.red',
    textStyle: 'brand.actionSm',
  },
});

export const FormError = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {},
});
