import { gql } from '../../__generated__/gql';

export const SIGNED_IN_USER_FRAGMENT = gql(/* GraphQL */ `
  fragment SignedInUser on User {
    ...UserCore
    id
    username
    email
    firstName
    lastName
    role
    flag
    hasGeneratedUsername
    expoPushToken
    deniedNotifications
    isPremium
    signUpStep
    substackStatus
    location
    instagram
    twitter
    website
    bio
    bioLexical
    hasRedeemedFreeTrial
    preferences
    values
    profilePhotoSrc
    avatarPhotoSrc
    premiumTier
    followingCount
    storeReviewLastPromptedAt
    notificationsLastPromptedAt
    avatarPhotoFile {
      ...PerfImageFromFile
    }
    inviteCount
    # invitesRedeemedCount
    # pinnedRecCount
    profileTheme
  }
`);

export const AUTH_USER_FRAGMENT = gql(/* GraphQL */ `
  fragment AuthUser on Query {
    isInMaintenance
    me {
      ...SignedInUser
    }
  }
`);

export const GET_AUTH_USER = gql(/* GraphQL */ `
  query getAuthUser {
    ...AuthUser
  }
`);

export const DESTROY_SESSION_MUTATION = gql(/* GraphQL */ `
  mutation signOutUser {
    signOut
  }
`);
