import { useToast } from '@chakra-ui/react';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { signUpThemeSchema } from 'shared/validation/schemas';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { ProfileThemeType, UserFlagType } from 'shared/__generated__/graphql';
import { SIGN_UP_THEME } from 'shared/misc/graphql/SignUpFragments';
import { THEME_CONFIG } from 'shared/misc/theme/custom/custom-theme-colors';
import { isUserPremium } from 'shared/gates';
import { ThemeWheel } from 'shared/misc/components/ThemeSwitcher';
import UserTile from 'web2/app/components/by-type/user/user-tile';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import PremiumModal from '../../../components/modals/premium-modal';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';

type Inputs = {
  profileTheme: ProfileThemeType;
};

export const Route = createFileRoute('/sign-up/theme/')({
  component: SignUpThemePage,
});

function SignUpThemePage() {
  const [signUpMoreInfoMutation, { loading }] = useMutation(SIGN_UP_THEME);
  const { user } = useAuth<true>();
  const { trackEvent } = useAnalytics();
  const toast = useToast();
  const modalState = useDisclosure();

  const { updateStep } = useUpdateSetupStep();
  const { setNavigation } = useSignUpNavigation();

  const {
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(signUpThemeSchema),
    defaultValues: {
      profileTheme: user?.profileTheme || ProfileThemeType.Default,
    },
  });

  const values = watch();

  const onSubmit = async () => {
    const { profileTheme } = values;

    const isDisabled = THEME_CONFIG[profileTheme].isPremium && !isUserPremium(user);

    if (isDisabled) {
      toast({
        title: 'Premium theme selected.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    await trackEvent('Click', 'Theme_Selected',{
      userId: user?.id,
      theme: profileTheme,
    });

    if (profileTheme) {
      let nextSignUpStep: null | string = USER_SIGN_UP_STEP.SURVEY;
      const isGuest = user?.flag.includes(UserFlagType.Guest) || user?.email.includes('pi-temp');
      if (isGuest) {
        nextSignUpStep = null;
      }

      await signUpMoreInfoMutation({
        variables: { profileTheme },
        onCompleted: async () => {
          await updateStep(nextSignUpStep, nextSignUpStep !== null ? '/sign-up/survey' : '/sign-up/done');
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProfileTheme: {
            __typename: 'User',
            id: user.id,
            signUpStep: nextSignUpStep,
            profileTheme,
          },
        },
        onError: () => {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      });
    }
  };

  const setTheme = (theme: ProfileThemeType) => {
    setValue('profileTheme', theme);
  };

  const onOpenModal = () => {
    modalState.onOpen();
    trackEvent('Click', 'Pro_Theme_Selected',{
      userId: user?.id,
    });
  };

  useEffect(() => {
    setNavigation({
      rightArrowDisabled: false,
      leftArrowDisabled: false,
      showRightArrow: true,
      showLeftArrow: true,
      showSkip: false,
      onLeftPress: () => updateStep(USER_SIGN_UP_STEP.LOCATION),
      rightLabel: THEME_CONFIG[values.profileTheme].isPremium ? 'Go Pro' : undefined,
      leftLabel: undefined,
      onRightPress: () =>
        THEME_CONFIG[values.profileTheme].isPremium
          ? onOpenModal()
          : onSubmit(),
      popoverBody: (
        !isUserPremium(user) && THEME_CONFIG[values.profileTheme].isPremium ? (
          <div className="typography-body-sm text-brand-main mb-3 text-[12px]">
            Pro themes require a membership. Click to learn more.
          </div>
        ) : undefined
      ),
      rightArrowColor: !isUserPremium(user) && THEME_CONFIG[values.profileTheme].isPremium ? '#FFBB00' : undefined,
      yPosition: 265,
      signupStep: USER_SIGN_UP_STEP.THEME,
    });
  }, [setNavigation, values.profileTheme]);

  const [displayText] = useTypewriter('Choose your profile theme.', 50);

  return (
    <div className="flex flex-col min-h-full">
      <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
        {displayText}
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        Pro themes require a premium membership.
      </div>
      <div
        className="w-[200px] pointer-events-none self-center mt-[15px] sm:mt-[20px] md:mt-[20px] 2xl:mt-[50px]"
      >
        <UserTile
          skipStars
          user={{
            ...user,
            profileTheme: values.profileTheme,
          }}
        />
      </div>
      <div
        className="fixed flex items-center justify-center bottom-[25px] sm:bottom-[30px] md:bottom-[30px] left-1/2 transform -translate-x-1/2 typography-action-sm text-[18px] z-10"
      >
        {THEME_CONFIG[values.profileTheme].name}
        {THEME_CONFIG[values.profileTheme].isPremium ? (
          <div
            className="inline-flex justify-center items-center bg-brand-highlight text-brand-black p-[0_4px] ml-[8px]"
          >
            <div className="text-[14px]">Pro</div>
          </div>
        ) : null}
      </div>
      <div
        className="fixed bottom-[-320px] sm:bottom-[-300px] md:bottom-[-300px] left-1/2 transform -translate-x-1/2"
      >
        <div
          className="w-[515px] bg-[rgba(0,0,0,0.3)] h-[575px] absolute left-1/2 transform -translate-x-1/2 bottom-[-125px] m-auto rounded-full border border-black"
        />
        <ThemeWheel onChange={setTheme} value={values.profileTheme} />
      </div>
      {modalState.isOpen && <PremiumModal modalState={modalState} />}
    </div>
  );
}
