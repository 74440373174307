import { Tooltip, TooltipContent, TooltipTrigger } from 'web2/app/components/ui/tooltip';
import NextImage from 'shared/misc/components/image-wrapper';
import { GoBookmark, GoBookmarkFill, GoComment, GoCommentDiscussion } from 'react-icons/go';
import { useMutation } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { useRecommendationContext } from 'shared/misc/components/by-type/recommendations/providers/useRecommendationContext';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { useDocumentWrapperContext } from 'shared/misc/providers/DocumentWrapperContext';
import LikeStars from 'web2/app/components/by-type/likes/like-stars';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import {
  DocumentRelatedDataType,
  RecommendationItemFragment,
  RecommendationItemWithEndorsedByFragment,
  RecType,
  UserFlagType,
  UserState,
} from 'shared/__generated__/graphql';
import { useAuth } from 'shared/misc/hooks/useAuth';
import {
  BOOKMARK_REC_MUTATION,
  UNBOOKMARK_REC_MUTATION,
  ENDORSE_REC_MUTATION,
  UNENDORSE_REC_MUTATION,
} from 'shared/misc/graphql/RecommendationMutations';
import { getRelativeDateString } from 'shared/dates';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { useContext } from 'react';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { UserAvatar } from '../../user/user-avatar';
import { useProfileContext } from 'shared/misc/providers/ProfileContext';
import { DocumentRenderMode } from 'shared/types';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { Link as TWLink } from 'web2/app/components/link';
import { twMerge } from 'tailwind-merge';
interface Props {
  withResponses?: boolean;
  withBottomActions?: boolean;
  withBorder?: boolean;
  isRecView?: boolean;
  withNoPadding?: boolean;
  isInline?: boolean;
  parentRepostRec?: RecommendationItemFragment | RecommendationItemWithEndorsedByFragment | null;
}

export default function RecommendationItemBottom({
  withResponses,
  withBottomActions = true,
  parentRepostRec,
  withBorder,
  isRecView = false,
  withNoPadding = false,
  isInline = false,
}: Props) {
  const { rec } = useRecommendationContext();
  const auth = useAuth();
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const { hideRecommendationUserInfo, document, mode } = useDocumentWrapperContext();

  const relatedDataForDraftGuest = document?.relatedData?.find(
    (d) => d.targetUser?.state === UserState.DraftGuest,
  )?.targetUser;

  const relatedDataForRecItem = document?.relatedData?.find(
    (data) =>
      data.type === DocumentRelatedDataType.RecommendationItem && data.targetRec?.id === rec.id,
  );

  let user = rec?.user;

  if (document && mode === DocumentRenderMode.EDIT) {
    user = relatedDataForRecItem?.targetUser || relatedDataForDraftGuest || rec?.user;
  }

  const [endorseRec] = useMutationWithAutoToast(ENDORSE_REC_MUTATION, {
    successMessage: 'Liked.',
    variables: {
      targetRecId: rec.id,
      repostRecId: parentRepostRec?.id,
    },
    optimisticResponse: {
      endorseRec: {
        __typename: 'Rec',
        id: rec.id,
        isEndorsed: true,
        endorsementCount: rec.endorsementCount + 1,
      },
    },
  });

  const [unendorseRec] = useMutationWithAutoToast(UNENDORSE_REC_MUTATION, {
    successMessage: 'Unliked.',
    variables: {
      targetRecId: rec.id,
    },
    optimisticResponse: {
      unendorseRec: {
        __typename: 'Rec',
        id: rec.id,
        isEndorsed: false,
        endorsementCount: rec.endorsementCount - 1,
      },
    },
  });

  const themeColors = useThemeColors();

  const logoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

  const isBackgroundLight = useIsBackgroundLight();

  const { profile } = useProfileContext();

  const isMobile = true;

  const bookmarkRec = rec.conversation && rec.targetRec ? rec.targetRec : rec;

  const isStaffPick =
    !profile &&
    (rec.user?.flag.includes(UserFlagType.PerfectlyImperfect) ||
      rec.user?.flag.includes(UserFlagType.Staff));

  const [addRecToBookmarks] = useMutation(BOOKMARK_REC_MUTATION, {
    variables: { recId: bookmarkRec.id },
    onCompleted: () => {
      console.log('it worked');
    },
    onError: (e) => {
      console.log(e);
    },
    optimisticResponse: {
      __typename: 'Mutation',
      addRecToBookmarks: {
        __typename: 'Rec',
        id: bookmarkRec.id,
        isBookmarked: true,
      },
    },
    refetchQueries: ['getBookmarksView'],
  });

  const [removeRecFromBookmarks] = useMutation(UNBOOKMARK_REC_MUTATION, {
    variables: { recId: bookmarkRec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      removeRecFromBookmarks: {
        __typename: 'Rec',
        id: bookmarkRec.id,
        isBookmarked: false,
      },
    },
    refetchQueries: ['getBookmarksView'],
  });

  const handleBookmark = async () => {
    if (auth.user) {
      if (bookmarkRec.isBookmarked) {
        await removeRecFromBookmarks();
        trackEvent('Click', 'Remove_Bookmark_Rec', {
          recId: bookmarkRec.id,
          endorsementCount: rec.endorsementCount,
          isEndorsed: rec.isEndorsed,
          isReRec: isRepost,
          commentsCount: rec.commentsCount,
        });
      } else {
        await addRecToBookmarks();
        trackEvent('Click', 'Bookmark_Rec', {
          recId: bookmarkRec.id,
          endorsementCount: rec.endorsementCount,
          isEndorsed: rec.isEndorsed,
          isReRec: isRepost,
          commentsCount: rec.commentsCount,
        });
      }
    } else {
      trackEvent('Click', 'Redirect_To_Sign_In', {
        clickTarget: 'Bookmark',
        targetId: rec.id,
        targetType: 'Rec',
      });
      router.navigate({ to: '/sign-in' });
    }
  };

  const BookmarkIcon = bookmarkRec.isBookmarked ? GoBookmarkFill : GoBookmark;

  const bookmarkNode = (
    <Tooltip>
      <TooltipContent withPortal className="z-[500] typography-action-sm">
        {bookmarkRec.isBookmarked ? 'Forget this rec...' : 'Remember this rec for later...'}
      </TooltipContent>
      <TooltipTrigger>
        <div
          // ml={isMobile ? '-2px' : undefined}
          // mr={!isMobile ? '-2px' : undefined}
          onClick={handleBookmark}
          className={twMerge(
            'cursor-pointer flex flex-row items-center gap-[6px]',
            isMobile && 'ml-[-2px]',
            !isMobile && 'mr-[-2px]',
          )}
        >
          <span className="text-brand-main hover:text-brand-highlight">
            <BookmarkIcon size="24px" />
          </span>
          {isRecView && (
            <span className="typography-action-sm text-brand-main hover:text-brand-highlight cursor-pointer">
              {rec.isBookmarked ? 'FORGET' : 'REMEMBER'}
            </span>
          )}
        </div>
      </TooltipTrigger>
    </Tooltip>
  );

  const CommentsIcon = rec.commentsCount > 0 ? GoCommentDiscussion : GoComment;

  const disableButtons = rec.type !== RecType.UnpublishedPostRec;

  const actionsNode = withBottomActions && (
    <div
      // spacing={isInline ? ['4px', '8px'] : '12px'}
      // alignSelf="flex-start"
      // alignItems="center"
      // left={
      //   isInline
      //     ? {
      //         base: '12px',
      //         sm: '16px',
      //       }
      //     : undefined
      // }
      // position={isInline ? 'absolute' : undefined}
      className={twMerge(
        'flex flex-row items-center self-start',
        isInline ? 'gap-[4px] sm:gap-[8px]' : 'gap-[12px]',
        isInline && 'left-[16px] sm:left-[16px] bottom-[12px]',
        isInline && 'absolute',
        // isInline && 'pb-[6px]',
      )}
    >
      {withResponses && (
        <div
          // textStyle="brand.actionSm"
          // fontWeight="normal"
          // textTransform="uppercase"
          // fontSize={{ base: '10px', sm: '11.5px' }}
          // ml={isMobile ? '-4px' : undefined}
          // mr={!isMobile ? '-4px' : undefined}
          className={twMerge(
            'flex align-center content-center text-brand-action-sm font-normal text-transform-uppercase font-size-[10px] sm:font-size-[11.5px]',
            isMobile ? 'ml-[-4px]' : undefined,
            !isMobile ? 'mr-[-4px]' : undefined,
          )}
        >
          <Tooltip>
            <TooltipContent withPortal className="z-[500] typography-action-sm">
              {`${rec.commentsCount} Repl${rec.commentsCount !== 1 ? 'ies' : 'y'}`}
            </TooltipContent>
            <TooltipTrigger>
              <TWLink
                to={`/rec/$recId`}
                params={{ recId: rec.id }}
                // display="flex"
                // whiteSpace="nowrap"
                // color="brand.main"
                // alignItems="center"
                className="text-brand-main hover:text-brand-highlight cursor-pointer flex items-center whitespace-nowrap"
                onClick={() => {
                  trackEvent('Click', 'View_Replies_Rec', {
                    recId: rec.id,
                    endorsementCount: rec.endorsementCount,
                    isEndorsed: rec.isEndorsed,
                    isReRec: isRepost,
                    commentsCount: rec.commentsCount,
                  });
                }}
              >
                <div className="flex flex-row items-center gap-[6px]">
                  {rec.commentsCount !== 0 && (
                    <span
                      // textStyle={isInline ? 'brand.actionSm' : 'brand.headingSm'}
                      // color="brand.main"
                      // display={isInline ? ['none', 'none', 'block'] : 'block'}
                      // cursor="pointer"
                      className={twMerge(
                        'text-brand-main hover:text-brand-highlight cursor-pointer',
                        isInline ? 'text-brand-action-sm' : 'text-brand-heading-sm',
                        isInline ? 'hidden md:block' : 'block',
                      )}
                    >
                      {rec.commentsCount}
                    </span>
                  )}
                  <CommentsIcon
                    size="24px"
                    className={`responsive-icon ${isInline ? 'responsive-icon-inline' : ''}`}
                  />
                </div>
              </TWLink>
            </TooltipTrigger>
          </Tooltip>
        </div>
      )}
      <LikeStars
        // withCount={!!document}
        isEndorsed={rec.isEndorsed}
        endorsementCount={rec.endorsementCount}
        unendorse={unendorseRec}
        endorse={endorseRec}
        targetRecId={rec.id}
        ownerId={user?.id as string}
        withText={isRecView}
        classes={isInline ? 'responsive-icon-lg' : ''}
      />
    </div>
  );

  const isRepost =
    !!parentRepostRec &&
    (parentRepostRec.type === RecType.RePost || parentRepostRec.type === RecType.RePostWithContent);

  const dateNode = (
    <span
      // paddingLeft={isInline ? '8px' : undefined}
      // opacity={isBackgroundLight ? 0.2 : 0.7}
      // textTransform="none"
      // whiteSpace="nowrap"
      className={twMerge(
        'typography-action-sm text-theme-menu-text',
        isInline ? 'pl-[8px]' : undefined,
        isBackgroundLight ? 'opacity-20' : 'opacity-70',
        'text-transform-none whitespace-nowrap',
      )}
    >
      {/* {format(new Date(rec.date), 'MMM dd, yyyy')} */}
      {getRelativeDateString(new Date(rec.date))}
      {/* <Text as="span"> at {format(new Date(rec.date), 'h:mm a')}</Text> */}
    </span>
  );

  const featureNumber = rec.feature
    ? new RegExp(/([0-9]{2,3})/gi).exec(rec.feature.title)?.[0]
    : null;

  const usernameTextNode = (
    <span className="max-w-[100%] line-clamp-1 text-brand-main hover:text-brand-highlight typography-action-sm">
      @{user?.username || rec.guest?.name || 'NO_USER_YET'}
    </span>
  );

  const usernameNode = (
    <div className="flex flex-row gap-[3px] items-center">
      {rec.type !== RecType.UnpublishedPostRec || rec.user ? (
        <TWLink to={`/u/$username`} params={{ username: user?.username }}>
          {usernameTextNode}
        </TWLink>
      ) : (
        usernameTextNode
      )}
      {isStaffPick ? (
        <div className="typography-action-sm font-bold text-brand-background bg-brand-highlight py-[3px] px-[6px] ml-[6px]">
          STAFF
        </div>
      ) : null}
      {/* {rec.conversation && (
          <Text maxWidth={{ sm: '100%' }} noOfLines={1}>
            STARTED A CONVO
          </Text>
        )} */}
      {rec.feature?.list && featureNumber && (
        <TWLink to={`/list/$listId`} params={{ listId: rec.feature.list.id }}>
          <span className="sm:max-w-[100%] line-clamp-1">(#{featureNumber})</span>
        </TWLink>
      )}
    </div>
  );

  const infoNodes = (
    <>
      {usernameNode}
      {dateNode}
    </>
  );

  const avatarSize = isMobile ? 25 : 35;

  const src = user?.avatarPhotoSrc || `/default-profile-photo-${logoColor}-small.png`;

  const imageNode = (
    <UserAvatar
      withActiveIndicator
      user={user}
      avatarSize={avatarSize}
      wrapWithLink={rec.type !== RecType.UnpublishedPostRec || !!user}
    />
  );

  return (
    <div
      // spacing="14px"
      // paddingTop={{ base: '12px', sm: '0px' }}
      // sx={isInline ? { flexDirection: 'row', justifyContent: 'space-between' } : {}}
      className={twMerge(
        'flex flex-col gap-[14px] pt-[12px] sm:pt-0',
        isInline && 'flex-row justify-between',
        isInline && 'pb-[10px]',
        isInline && 'mt-[12px]',
      )}
    >
      {isMobile && (!hideRecommendationUserInfo || !!user) ? (
        <div
          // justifyContent="space-between"
          // alignItems="center"
          // textStyle="brand.actionSm"
          // pl={withNoPadding ? '0px' : '16px'}
          // pr={withNoPadding ? '0px' : '16px'}
          className={twMerge(
            'flex flex-row items-center justify-between text-brand-action-sm',
            withNoPadding ? 'pl-0 pr-0' : 'pl-[16px] pr-[16px]',
          )}
        >
          <div
            // spacing="8px"
            // alignItems="center"
            // maxWidth={isMobile ? undefined : '300px'}
            className={twMerge(
              'flex flex-row items-center gap-[8px] target-empty-children-and-hide',
              !isMobile ? 'max-w-[300px]' : undefined,
            )}
            // height="35px"
          >
            {imageNode}
            {!isMobile ? (
              <div
                // spacing={isMobile ? '12px' : '3px'}
                // direction={isMobile ? 'row' : undefined}
                // textStyle="brand.actionSm"
                className={twMerge(
                  'flex flex-row items-center',
                  isMobile ? 'gap-[12px]' : 'gap-[3px]',
                  isMobile ? 'flex-row' : 'flex-col',
                  'text-brand-action-sm',
                )}
              >
                {infoNodes}
              </div>
            ) : (
              usernameNode
            )}
          </div>
          {dateNode}
        </div>
      ) : null}
      {withBottomActions ? (
        <div
          // textStyle="brand.actionLg"
          // justifyContent="space-between"
          // alignItems="center"
          // {...(isMobile
          //   ? {
          //       ...(withBorder ? { borderTop: '1px dashed', borderTopColor: 'brand.main' } : {}),
          //       p: '14px',
          //       pt: '7px',
          //       pb: '7px',
          //     }
          //   : {})}
          className={twMerge(
            'flex items-center justify-between text-brand-action-lg',
            isMobile ? 'p-[14px] pt-[7px] pb-[7px]' : undefined,
            withBorder && isMobile ? 'border-t border-dashed border-brand-main' : undefined,
          )}
        >
          {/* {isMobile && actionsNode} */}
          <div
            // spacing="8px"
            // alignItems="center"
            // maxWidth={isMobile ? undefined : '300px'}
            // height="35px"
            className={twMerge(
              'flex flex-row items-center gap-[8px]',
              !isMobile ? 'max-w-[300px]' : undefined,
            )}
          >
            {!isMobile && !hideRecommendationUserInfo ? (
              <>
                {imageNode}
                <div
                  // spacing={isMobile ? '12px' : '3px'}
                  // direction={isMobile ? 'row' : undefined}
                  // textStyle="brand.actionSm"
                  className={twMerge(
                    'flex flex-row items-center',
                    isMobile ? 'gap-[12px]' : 'gap-[3px]',
                    isMobile ? 'flex-row' : 'flex-col',
                    'text-brand-action-sm',
                  )}
                >
                  {infoNodes}
                </div>
              </>
            ) : (
              <>{!!actionsNode && bookmarkNode}</>
            )}
          </div>
          {actionsNode}
        </div>
      ) : (
        <div className="pt-[6px]" />
      )}
    </div>
  );
}
