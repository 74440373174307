'use client';

import React, { useEffect, useRef } from 'react';
import fitty from 'fitty';
import { useThemeColors } from '../hooks/useThemeColors';
import { twMerge } from 'tailwind-merge';
import { ProfileThemeType } from '../../__generated__/graphql';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  minSize?: number;
  maxSize?: number;
  strokeWidth?: number;
  fitToContainer?: boolean;
  variant?: string;
  theme?: ProfileThemeType | null;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: string;
  className?: string;
}

export default function Heading({
  minSize = 40,
  maxSize = typeof window === 'undefined' ? 120 : 140,
  strokeWidth = 2,
  variant,
  fitToContainer,
  as: Component = 'h2',
  className,
  theme,
  ...props
}: Props) {
  const ref = useRef<HTMLHeadingElement>(null);
  const themeColors = useThemeColors(theme);

  const titleBorder = variant === 'XL' ? themeColors.titleBorder : undefined;
  const mainColor = variant === 'XL' ? themeColors.titleBackground : props.color;

  useEffect(() => {
    const headingRef = ref.current;
    if (headingRef && fitToContainer) {
      requestAnimationFrame(() => {
        const spans = Array.from(headingRef.querySelectorAll('span'));
        const instances = spans.map((n) =>
          fitty(n, {
            minSize,
            maxSize,
          }),
        );
        return () => instances.forEach((i) => i.unsubscribe());
      });
    }
  }, [minSize, maxSize, fitToContainer]);

  return (
    <Component
      ref={ref}
      style={{
        ...(titleBorder && {
          WebkitTextStroke: `${strokeWidth}px ${titleBorder}`,
        }),
        color: mainColor,
      }}
      className={twMerge(
        'font-abc-favorite',
        'typography-heading-xl',
        variant === 'XL' && 'text-title-background',
        className,
      )}
      {...props}
    />
  );
}

// ('use client');

// export default function Heading(props) {
//   const ref = useRef<HTMLHeadingElement>();
//   const theme = useTheme();

//   const strokeColor = props?.variant === 'XL' ? theme.colors.brand.titleBorder : null;

//   useEffect(() => {
//     const headingRef = ref.current;
//     if (headingRef && props.fitToContainer) {
//       const spans = Array.from(headingRef.querySelectorAll('span'));

//       const instances = spans.map((n) =>
//         fitty(n, {
//           minSize: 40,
//           maxSize: 140,
//         }),
//       );

//       return () => instances.forEach((i) => i.unsubscribe());
//     }
//   }, []);

//   return (
//     <Head
//       ref={ref}
//       {...props}
//       css={{
//         WebkitTextStrokeColor: strokeColor,
//       }}
//     />
//   );
// }
