'use client';

import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LexicalSelectionHandler from '../LexicalSelectionHandler';
import _ from 'lodash';
import { useDelete } from '../hooks/useHelperHooks';
import { GenericComponentType } from '../../LexicalGenericComponentNode';
import InlineSubscribeToSignUp from '../../../../../InlineSubscribeToSignUp';
import { useAuth } from '../../../../../../hooks/useAuth';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import LexicalInlineSubscribeComponent from './LexicalInlineSubscribeComponent';
import LexicalCustomButtonComponent from './LexicalCustomButtonComponent';
import LexicalDividerComponent from './LexicalDividerComponent';

export interface LexicalGenericComponentProps {
  id: string;
  nodeKey: string;
  componentType: GenericComponentType;
  data?: any;
  setComponentType: (componentType: GenericComponentType) => void;
  setData: (data: any) => void;
}

export interface LexicalGenericInnerComponentProps extends LexicalGenericComponentProps {
  isEditable: boolean;
}

function LexicalGenericComponent(props: LexicalGenericComponentProps) {
  const { nodeKey, componentType, data, setComponentType, setData } = props;

  const auth = useAuth();

  useDelete(nodeKey);

  const isEditable = useLexicalEditable();

  let node: React.ReactNode | null = null;

  const innerComponentProps = { isEditable, ...props };

  if (componentType === 'inline-subscribe' && (!auth.user || isEditable)) {
    node = <LexicalInlineSubscribeComponent {...innerComponentProps} />;
  }

  if (componentType === 'custom-button') {
    node = <LexicalCustomButtonComponent {...innerComponentProps} />;
  }

  if (componentType === 'divider') {
    node = <LexicalDividerComponent {...innerComponentProps} />;
  }

  return (
    <div className="w-full max-w-full justify-center">
      {isEditable ? (
        <LexicalSelectionHandler nodeKey={nodeKey}>{node}</LexicalSelectionHandler>
      ) : (
        node
      )}
    </div>
  );
}

export default LexicalGenericComponent;
