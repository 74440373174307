import { useEffect } from 'react';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { Box, Stack, Text, Tooltip } from '@chakra-ui/react';
import Heading from 'shared/misc/components/heading';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { gql } from 'shared/__generated__';
import { useMutation, useQuery } from '@apollo/client';
import { InView } from 'react-intersection-observer';
import FeedItem from '../../components/by-type/feed/feed-item';
import SkeletonGenericSmallFeedItem from 'shared/misc/components/SkeletonGenericSmallFeedItem';

const PAGE_LENGTH = 30;

const PageQuery = gql(/* GraphQL */ `
  query getNotificationsPageView($first: Int, $after: String) {
    notificationConnection(first: $first, after: $after) @connection(key: "notificationsPage") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          seen
          ...FullFeedItem
        }
      }
    }
  }
`);

const MARK_ALL_AS_SEEN_MUTATION = gql(/* GraphQL */ `
  mutation markAllAsSeenMainPage {
    markAllAsSeen {
      id
      unseenNotificationCount
    }
  }
`);

export const Route = createFileRoute('/notifications/')({
  component: RouteComponent,
});

function RouteComponent() {
  const auth = useAuth();

  const router = useRouter();

  useEffect(() => {
    if (!auth.user && auth.isFullyLoaded) {
      router.navigate({ to: '/home' });
    }
  }, []);

  const { data, loading, fetchMore } = useQuery(PageQuery, {
    variables: { first: PAGE_LENGTH },
    initialFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-and-network',
  });

  const getNextPage = async () => {
    if (!data?.notificationConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.notificationConnection.pageInfo.endCursor,
      },
    });
  };

  const [markAllAsSeen, { data: mutationData }] = useMutation(MARK_ALL_AS_SEEN_MUTATION, {
    optimisticResponse: {
      markAllAsSeen: {
        __typename: 'User',
        id: auth?.user.id,
        unseenNotificationCount: 0,
      },
    },
  });

  useEffect(() => {
    if (!loading && !mutationData) {
      markAllAsSeen();
    }
  }, [loading]);

  const feedItems = data?.notificationConnection.edges;

  return (
    <Box
      display="flex"
      justifyContent="center"
      p="24px"
      mb="100px"
      className="flex flex-col items-center p-[24px] mb-[100px]"
    >
      <Stack
        spacing="24px"
        width="100%"
        maxWidth={{ base: '100%', md: '700px' }}
        justifyContent="center"
        className="flex flex-col space-y-[24px] w-full max-w-[700px] items-center"
      >
        <Heading variant="XL" strokeWidth={0} className="text-[24px]  md:text-[48px] text-center">
          Notifications
        </Heading>
        {loading && (
          <Stack spacing="12px" justifyContent="flex-start" width="100%">
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
            <SkeletonGenericSmallFeedItem />
          </Stack>
        )}
        <Stack spacing="12px" justifyContent="flex-start" width="100%">
          {!loading &&
            feedItems?.map(({ node }, idx, arr) => {
              const nextNode = arr[idx + 1]?.node;
              const nextNodeSeen = nextNode && 'seen' in nextNode && nextNode.seen;
              const currNodeSeen = 'seen' in node && node.seen;
              const shouldDrawLine = nextNodeSeen && !currNodeSeen;

              return (
                <Box key={node.id} width="100%" alignSelf="flex-start">
                  <Box width="100%" alignSelf="flex-start" position="relative">
                    {'seen' in node && !node.seen && false && (
                      <Box
                        position="absolute"
                        color="brand.highlight"
                        fontSize="50px"
                        mt="17px"
                        marginLeft="-17px"
                        width="10px"
                        height="10px"
                        backgroundColor="brand.highlight"
                        borderRadius="15px"
                        className="dot"
                      />
                    )}
                    <FeedItem
                      feedItem={node}
                      relateRecToUser
                      forceGeneric
                      hideAllFeedContext
                      fullWidth
                    />
                  </Box>
                  {shouldDrawLine && (
                    <Box position="relative" width="100%">
                      <Box position="absolute" top={{ base: '-16px' }} right={0}>
                        <Text textStyle="brand.actionSm" color="brand.highlight">
                          NEW
                        </Text>
                      </Box>
                      <Box
                        width="100%"
                        borderBottom="1px solid"
                        borderBottomColor="brand.highlight"
                        mt="12px"
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          {data && feedItems?.length === 0 && (
            <Tooltip label="Follow some people to get updates here.">
              <Text textStyle="brand.bodySm" layerStyle="secondaryText" paddingTop="12px">
                No activity yet...
              </Text>
            </Tooltip>
          )}
          <InView
            as="div"
            rootMargin="1000px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
