import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { isInWebView } from '../../../webview';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    // bg: 'rgba(0, 0, 0, 0.30)', //change the background
    // backdropFilter: 'blur(2px) hue-rotate(180deg)',
  },
  dialog: {
    ...(!isInWebView()
      ? { borderRadius: '0px', border: '1px dashed', borderColor: 'brand.main' }
      : {}),
    bg: `brand.background`,
  },
  // dialogContainer: {
  //   bg: `brand.background`,
  // },
  header: {
    color: 'brand.main',
    textStyle: 'brand.headingXL',
    fontSize: '36px',
    borderBottom: '1px dashed',
    borderBottomColor: 'brand.main',
    mb: '10px',
  },
  closeButton: {
    color: 'brand.main',
  },
  footer: {
    backgroundColor: 'brand.background',
  },
});

export const Drawer = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    motionPreset: 'none',
  },
});
