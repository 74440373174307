import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';

import { gql } from 'shared/__generated__';
import {
  CreateRecForLexicalMutation,
  DocumentRelatedDataStatus,
  DocumentRelatedDataType,
  PromptType,
  RecDraftAutoSaveFragment,
  RecInput,
  RecommendationItemFragment,
} from '../../../../../../__generated__/graphql';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodeToNearestRoot, $wrapNodeInElement } from '@lexical/utils';
import { $insertNodes, $isRootOrShadowRoot, $createParagraphNode, LexicalEditor } from 'lexical';
import {
  $createRecommendationNode,
  RecommendationNode,
} from '../../nodes/LexicalRecommendationNode';
import useFileUpload from '../../../../../hooks/useFileUpload';
import { $createImageNode } from '../../nodes/LexicalImageNode';
import useDocumentHooks from '../../../../../hooks/useDocumentHooks';
import { useDocumentWrapperContext } from '../../../../../providers/DocumentWrapperContext';
import { RecFormInputs } from '../../../../by-type/recommendations/useRecForm';
import {
  $createGenericComponentNode,
  GenericComponentType,
} from '../../nodes/LexicalGenericComponentNode';
import React from 'react';
import { $createPromptNode } from '../../nodes/LexicalPromptNode';

export interface UseComponentPickerHelpers {
  createRecDraftNode: (input?: RecInput) => Promise<RecommendationNode | null>;
  onInsertRecommendation: () => Promise<void> | any;
  onInsertPrompt: () => Promise<void> | any;
  onInsertImageFromPicker: () => Promise<void> | any;
  onInsertGenericComponent: (componentType: GenericComponentType) => void;
  inputNodes: React.ReactNode;
}

export default function useComponentPickerHelpers(): UseComponentPickerHelpers {
  const editor = useLexicalComposerContext()?.[0];

  const { document } = useDocumentWrapperContext();

  const { createDocumentRelatedData } = useDocumentHooks();

  const { createRecDraft } = useRecDraftMutations();

  const onInsertRecommendation = async () => {
    const rec = (await createRecDraft({ variables: { input: {} } }))?.data?.createRec || null;
    if (rec) {
      editor.update(() => {
        const recNode = $createRecommendationNode(rec.id, rec);
        $insertNodeToNearestRoot(recNode);
        if ($isRootOrShadowRoot(recNode.getParentOrThrow())) {
          $wrapNodeInElement(recNode, $createParagraphNode).selectEnd();
        }
      });

      if (document) {
        createDocumentRelatedData({
          variables: {
            documentId: document.id,
            input: {
              targetRecId: rec.id,
              type: DocumentRelatedDataType.RecommendationItem,
              status: document
                ? DocumentRelatedDataStatus.DocumentContent
                : DocumentRelatedDataStatus.DraftOnly,
            },
          },
        });
      }
    }
  };

  const [createPrompt, createPromptState] = useMutation(CREATE_PROMPT_DRAFT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getProfileView', 'getHomeview'],
  });

  const onInsertPrompt = async () => {
    const prompt =
      (
        await createPrompt({
          variables: { input: { title: '', type: PromptType.UnpublishedPostPrompt } },
        })
      )?.data?.createPrompt || null;

    if (prompt) {
      editor.update(() => {
        const promptNode = $createPromptNode(prompt.id, prompt);
        $insertNodeToNearestRoot(promptNode);
        if ($isRootOrShadowRoot(promptNode.getParentOrThrow())) {
          $wrapNodeInElement(promptNode, $createParagraphNode).selectEnd();
        }
      });

      if (document) {
        createDocumentRelatedData({
          variables: {
            documentId: document.id,
            input: {
              targetPromptId: prompt.id,
              type: DocumentRelatedDataType.PromptItem,
              status: document
                ? DocumentRelatedDataStatus.DocumentContent
                : DocumentRelatedDataStatus.DraftOnly,
            },
          },
        });
      }
    }
  };

  const { openPicker, nodes: inputNodes } = useFileUpload({
    onFileSign: async (file) => {
      editor.update(() => {
        const imageNode = $createImageNode(file.id, file);
        $insertNodeToNearestRoot(imageNode);
        if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
          $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
        }
      });
      if (document) {
        createDocumentRelatedData({
          variables: {
            documentId: document.id,
            input: {
              targetFileId: file.id,
              type: DocumentRelatedDataType.Image,
              status: DocumentRelatedDataStatus.DocumentContent,
            },
          },
        });
      }
    },
    // onUpload: async (file) => {
    //   console.log('file uploaded', file);
    // },
  });

  const onInsertImageFromPicker = () => {
    openPicker();
    console.log(openPicker.toString());
  };

  const onInsertGenericComponent = async (componentType: GenericComponentType) => {
    editor.update(() => {
      const componentNode = $createGenericComponentNode(componentType);
      $insertNodeToNearestRoot(componentNode);
      if ($isRootOrShadowRoot(componentNode.getParentOrThrow())) {
        $wrapNodeInElement(componentNode, $createParagraphNode).selectEnd();
      }
    });
  };

  return {
    onInsertGenericComponent,
    onInsertRecommendation,
    onInsertPrompt,
    onInsertImageFromPicker,
    inputNodes,
  };
}

export function useRecDraftMutations() {
  const [createRecDraft] = useMutation(CREATE_UNLISTED_REC_DRAFT, {
    fetchPolicy: 'network-only',
  });
  const { document } = useDocumentWrapperContext();

  const { createDocumentRelatedData } = useDocumentHooks();

  const [getRec] = useLazyQuery(GET_REC_FROM_ID);

  const createRecRelatedDataFromRecId = async (recId: string) => {
    const res = await getRec({ variables: { id: recId } });

    createDocumentRelatedData({
      variables: {
        documentId: document!.id,
        input: {
          targetRecId: res.data?.rec!.id,
          type: DocumentRelatedDataType.RecommendationItem,
          status: document?.publishedAt
            ? DocumentRelatedDataStatus.DocumentContent
            : DocumentRelatedDataStatus.DraftOnly,
        },
      },
    }).then();

    return res.data?.rec;
  };

  const createRecDraftNode = async (editor: LexicalEditor, input?: RecInput) => {
    const rec =
      (await createRecDraft({ variables: { input: input || {} } }))?.data?.createRec || null;

    createDocumentRelatedData({
      variables: {
        documentId: document!.id,
        input: {
          targetRecId: rec!.id,
          type: DocumentRelatedDataType.RecommendationItem,
          status: document?.publishedAt
            ? DocumentRelatedDataStatus.DocumentContent
            : DocumentRelatedDataStatus.DraftOnly,
        },
      },
    }).then();

    return rec;
  };

  const createRecNode = async (editor: LexicalEditor, input?: RecInput) => {
    const rec =
      (await createRecDraft({ variables: { input: input || {} } }))?.data?.createRec || null;

    createDocumentRelatedData({
      variables: {
        documentId: document!.id,
        input: {
          targetRecId: rec!.id,
          type: DocumentRelatedDataType.RecommendationItem,
          status: document?.publishedAt
            ? DocumentRelatedDataStatus.DocumentContent
            : DocumentRelatedDataStatus.DraftOnly,
        },
      },
    }).then();

    return rec;
  };

  return {
    createRecDraft,
    createRecDraftNode,
    createRecRelatedDataFromRecId,
  };
}

const GET_REC_FROM_ID = gql(/* GraphQL */ `
  query getRecFromId($id: String!) {
    rec(id: $id) {
      ...RecommendationItem
    }
  }
`);

const CREATE_UNLISTED_REC_DRAFT = gql(/* GraphQL */ `
  mutation createRecForLexical($input: RecInput!) {
    createRec(input: $input, skipUser: true, skipFeed: true, type: UNPUBLISHED_POST_REC) {
      ...RecommendationItem
    }
  }
`);

const CREATE_PROMPT_DRAFT = gql(/* GraphQL */ `
  mutation createPromptForComponentPicker($input: CreatePromptInput!) {
    createPrompt(input: $input) {
      ...PromptItem
    }
  }
`);
