import { createFileRoute } from '@tanstack/react-router';
import { GetRecViewDocument, GetRecViewQuery } from 'shared/__generated__/graphql';
import RecViewPageContainer from './-rec-view-page-container';
import { CommentsQuery } from 'shared/misc/components/by-type/comments/CommentsContainer';
import { RelatedRecsQueryDocument } from 'shared/misc/components/by-type/recommendations/RecViewRelatedRecs';
import { preloadImageByKey } from '../../../lib/utils';
import { genCDN } from 'shared/cdn';
import _ from 'lodash';
import { genMeta } from '../../utils/meta';

export const Route = createFileRoute('/rec/$recId')({
  component: RouteComponent,
  staleTime: 60 * 5,
  head(ctx) {
    const data = ctx.loaderData as Awaited<ReturnType<typeof Route.loader>>;

    const rec = data?.initialRecData as GetRecViewQuery['rec'];

    const fileAttachment = rec?.attachments?.find((attachment) => attachment?.file?.key);

    const image = genCDN(fileAttachment?.file?.key ?? '/pi-image-for-social.png', 70, 'webp');

    return genMeta({
      title: `Rec | ${_.truncate(rec?.title, { length: 60 }).toUpperCase()}`,
      description: _.truncate(rec?.content || 'LEARN MORE', { length: 155 }).toUpperCase(),
      image,
    });
  },
  loader: async ({ params, context }) => {
    // const commentsQueryRef = context.apolloClient.query({
    //   query: CommentsQuery,
    //   variables: { first: 5, targetRecId: params.recId },
    // });

    const recQuery = await context.apolloClient.query({
      query: GetRecViewDocument,
      variables: { id: params.recId },
    });

    if (recQuery.data?.rec) {
      if (recQuery.data?.rec?.user?.profilePhotoFile?.key) {
        preloadImageByKey(recQuery.data?.rec?.user?.profilePhotoFile?.key);
      }
      if (recQuery.data?.rec?.attachments) {
        recQuery.data?.rec?.attachments.forEach((attachment) => {
          if (attachment?.file?.key) {
            preloadImageByKey(attachment.file.key);
          }
        });
      }
    }

    // const relatedRecsQueryRef = await context.apolloClient.query({
    //   query: RelatedRecsQueryDocument,
    //   variables: { recId: params.recId, userId: recQuery.data?.rec?.user?.id },
    // });
    const relatedRecsQueryRef = context.preloadQuery(RelatedRecsQueryDocument, {
      variables: { recId: params.recId, userId: recQuery.data?.rec?.user?.id },
    });
    // REMOVED BECAUSE WE NEED THE APOLLO CACHE TO WORK
    // const similarRecsQueryRef = relatedRecsQueryRef.then(({ data }) => {
    //   const similarRecs = data?.similarRecs.edges.map((edge) => edge.node) ?? [];
    //   return similarRecs;
    // });
    // const topRecsFromUserQueryRef = relatedRecsQueryRef.then(({ data }) => {
    //   const topRecsFromUser = data?.topRecsFromUser.edges.map((edge) => edge.node) ?? [];
    //   return topRecsFromUser;
    // });
    return {
      recId: params.recId,
      username: recQuery.data?.rec?.user?.username,
      initialRecData: recQuery.data?.rec,
      relatedRecsQueryRef,
      // commentsQueryRef,
      // similarRecsQueryRef,
      // topRecsFromUserQueryRef,
    };
  },
});

function RouteComponent() {
  const { initialRecData } = Route.useLoaderData();
  const { recId } = Route.useParams();

  return <RecViewPageContainer id={recId} initialRecData={initialRecData} />;
}
