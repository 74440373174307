import { createFileRoute, redirect } from '@tanstack/react-router';
import AdminDocumentsDrafts from './-AdminDocumentsDrafts';
import { getSessionUser } from '../../../../actions/getSessionUser';

export const Route = createFileRoute('/admin/documents/drafts/')({
  component: RouteComponent,
  ssr: false,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if ((!user || user.role !== 'ADMIN') && process.env['NODE_ENV'] === 'production') {
      throw redirect({ to: '/' });
    }
  },
});

function RouteComponent() {
  return <AdminDocumentsDrafts />;
}
