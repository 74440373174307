

import { Box, Button, Flex, HStack, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import useRecForm, {
  RecFormHandle,
  RecFormInputs,
} from 'shared/misc/components/by-type/recommendations/useRecForm';
import useRecDraftAutoSave, {
  isDraftEmpty,
} from 'shared/misc/components/by-type/recommendations/useRecDraftAutoSave';
import { gql } from 'shared/__generated__/gql';
import { useQuery } from '@apollo/client';
import PerfSuspense from 'shared/misc/components/util/PerfSuspense';
import { redirect } from '@tanstack/react-router';
import { GetAddRecsPageQuery } from 'shared/__generated__/graphql';
import { safeParse } from 'web2/app/components/by-type/rich-text/basic/basic-editor';
import { createFileRoute } from '@tanstack/react-router';

gql(/* GraphQL */ `
  fragment UserDraftRecsSignUp on User {
    id
    recCount
    draftRecs {
      ...RecDraftAutoSave
    }
  }
`);

const Query = gql(/* GraphQL */ `
  query getAddRecsPage {
    me {
      ...UserDraftRecsSignUp
    }
  }
`);

function AddRecsPage() {
  const { data, loading } = useQuery(Query, {
    fetchPolicy: 'network-only',
  });

  if (loading && !data) {
    return <PerfSuspense />;
  }

  if (!data || !data.me) {
    return redirect({to: '/not-found'});
  }

  return <Content me={data.me} />;
}

function Content({ me }: { me: NonNullable<GetAddRecsPageQuery['me']> }) {
  const formRef = useRef<RecFormHandle>(null);

  const recentDraft = me?.draftRecs[0];

  const {
    draftRec,
    onCreateRecFromDraft,
    onSaveDraftDebounced,
    onDeleteRecDraft,
    onCreateRecDraft,
    isSubmittingCreateRec,
  } = useRecDraftAutoSave({ formRef, draftRecId: recentDraft?.id || null, warnOnUnload: false });

  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.FOLLOW],
  });

  const onDone = async (values: RecFormInputs) => {
    try {
      await onCreateRecFromDraft(values);
      await updateStep(USER_SIGN_UP_STEP.FOLLOW);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const { mainFormNode, onSubmit } = useRecForm({
    defaultIsOpenEmojiToolTip: true,
    onSubmit: onDone,
    editorHeight: '300px',
    ref: formRef,
    onChange: onSaveDraftDebounced,
    defaultValues: {
      title: recentDraft?.title || '',
      url: recentDraft?.url || '',
      emoji: recentDraft?.emoji || '😃',
      contentLexicalState: recentDraft?.contentLexical
        ? safeParse(recentDraft?.contentLexical)
        : '',
    },
  });

  useEffect(() => {
    if (!recentDraft) {
      onCreateRecDraft().then(() => {
        formRef.current?.setFocus('title');
      });
    }
  }, []);

  const [isLargerThan400] = useMediaQuery('(min-width: 400px)', { ssr: true });

  return (
    <>
      <CenterPage
        minH="100vh"
        axis="horizontal"
        paddingBottom={{ base: '0px', sm: '100px' }}
        p={{ base: '12px', md: '48px' }}
      >
        <Box
          m="10px"
          marginTop={{ base: '12px', sm: '24px' }}
          border="1px dashed"
          borderColor="brand.main"
          p={{ base: '24px', md: '48px' }}
          justifyContent="center"
          width="100%"
          maxW={{ base: '100%', lg: '800px' }}
        >
          <Stack spacing="12px" justifyContent="center" width="100%" maxWidth="100%">
            <Stack
              maxWidth="100%"
              width={{ base: '100%', md: '500px' }}
              spacing={6}
              justifyContent="center"
              alignSelf="center"
            >
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">SHARE A TASTE OF YOUR TASTE</Text>
              <Text textStyle="brand.body" fontWeight="normal">
                It's time to write {me?.recCount || 0 > 0 ? 'another' : 'your first'}{' '}
                recommendation!
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                Try thinking of a song you've had on repeat, some life advice, what you're snacking
                on as you read this, your favorite movie, or any kind of recent obsession. You get
                the idea...
              </Text>
              <Text fontWeight="normal">
                If nothing is coming to mind, that's okay. You can hit{' '}
                <Button
                  size="md"
                  padding="0px"
                  variant="link"
                  width="fit-content"
                  onClick={async () => {
                    await updateStep(USER_SIGN_UP_STEP.FOLLOW);
                  }}
                >
                  skip
                </Button>{' '}
                and just write one later.
              </Text>
            </Stack>
            <Box
              width="100%"
              alignSelf="center"
              padding="12px"
              borderBottom="1px dashed"
              borderBottomColor="brand.main"
            />
            <Box
              maxWidth="100%"
              width={{ base: '100%', md: '600px' }}
              height={{ base: '300px', md: '400px' }}
              pointerEvents={!draftRec ? 'none' : 'all'}
            >
              {mainFormNode}
              {/* {editLinkNode} */}
            </Box>
            <Flex marginTop="48px" justifyContent="flex-end" alignItems="center">
              <HStack spacing="12px">
                {isLargerThan400 && (
                  <Box color="brand.lightgrey">
                    <Button
                      variant="link"
                      borderBottomColor="brand.lightgrey"
                      onClick={async () => {
                        onDeleteRecDraft().then();
                        await updateStep(USER_SIGN_UP_STEP.FOLLOW);
                      }}
                    >
                      {!isDraftEmpty(draftRec) ? 'Delete draft and skip' : 'Skip for now'}
                    </Button>
                  </Box>
                )}
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await updateStep(USER_SIGN_UP_STEP.FOLLOW);
                  }}
                >
                  {!isDraftEmpty(draftRec) ? 'Finish Draft later' : 'Skip for now'}
                </Button>
                <Button
                  variant="primary"
                  isLoading={isSubmittingCreateRec}
                  isDisabled={isDraftEmpty(draftRec)}
                  onClick={async () => {
                    await onSubmit();
                  }}
                >
                  Save & Next
                </Button>
              </HStack>
            </Flex>
            {!isLargerThan400 && !isDraftEmpty(draftRec) && (
              <Box color="brand.lightgrey" alignSelf="flex-end">
                <Button
                  variant="link"
                  borderBottomColor="brand.lightgrey"
                  onClick={async () => {
                    onDeleteRecDraft().then();
                    await updateStep(USER_SIGN_UP_STEP.FOLLOW);
                  }}
                >
                  Delete draft and skip
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}

export const Route = createFileRoute('/sign-up/add-recs/')({
  component: AddRecsComponent,
});
