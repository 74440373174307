import React from 'react';
import { useRouter } from '@tanstack/react-router';
import { Box, SimpleGrid, Heading, useTheme } from '@chakra-ui/react';
import { useThemeColors } from '../hooks/useThemeColors';

const FeedItemCollection = ({ children, title, url, accent = 'brand.background' }) => {
  const themeColors = useThemeColors();

  const router = useRouter();

  return (
    <Box
      backgroundColor={accent}
      w="100%"
      p={{ base: '24px', sm: '48px' }}
      border="1px solid"
      borderStyle="dashed"
      borderColor="brand.main"
    >
      <Heading
        onClick={() => router.navigate({ to: url })}
        fontSize={{ base: '48px', sm: '64px' }}
        mb={{ base: '48px', sm: '24px' }}
        mt={{ base: '24px', sm: '0' }}
        variant="XL"
        textAlign="center"
        transform="scale(1, 1.25)"
        className="mb-4"
        _hover={{
          cursor: 'pointer',
        }}
        color="brand.titleBackground"
        sx={{
          WebkitTextStroke: '2px',
          WebkitTextStrokeColor: themeColors.titleBorder,
        }}
      >
        {title}
      </Heading>
      <SimpleGrid columns={{ sm: 2, lg: 4 }} gap={{ base: '0', sm: '12px' }}>
        {children}
      </SimpleGrid>
    </Box>
  );
};

export default FeedItemCollection;
