import { isBefore, subDays } from 'date-fns';
import { RecType, UserCoreFragment } from './__generated__/graphql';

enum UserRoleType {
  DEFAULT = 'DEFAULT',
  MODERATOR = 'MODERATOR',
  ADMIN = 'ADMIN',
}

enum UserFlagType {
  DEFAULT = 'DEFAULT',
  GUEST = 'GUEST',
  STAFF = 'STAFF',
  PERFECTLY_IMPERFECT = 'PERFECTLY_IMPERFECT',
  CURATOR = 'CURATOR',
}

export function isUserAdmin(user: { role: UserRoleType | string }) {
  if (!user) return false;
  return user.role === UserRoleType.ADMIN;
}

export function isUserStaff(user: { flag: (UserFlagType | string)[] }) {
  if (!user) return false;
  return user.flag.includes(UserFlagType.STAFF);
}

export function isUserCurator(user: {
  role: UserRoleType | string;
  flag: (UserFlagType | string)[];
}) {
  if (!user) return false;
  return (
    isUserAdmin(user) ||
    user.flag.includes(UserFlagType.STAFF) ||
    user.flag.includes(UserFlagType.CURATOR) ||
    user.flag.includes(UserFlagType.PERFECTLY_IMPERFECT)
  );
}

export function isUserGuest(user: { flag: (UserFlagType | string)[] }) {
  return user?.flag?.includes(UserFlagType.GUEST);
}

// Is the content older 7 days.
export function isContentArchived(date: string) {
  return isBefore(new Date(date), subDays(new Date(), 2));
}

export function isRecPrivateShare(rec: { type: RecType | string }) {
  if (!rec) return false;
  return (
    rec.type === RecType.Private ||
    rec.type === RecType.PrivateShareExisting ||
    rec.type === RecType.PrivateShareNew
  );
}

export function isUserPremium(user: UserCoreFragment): boolean {
  if (!user) return false;
  if (user?.role === 'ADMIN') {
    return true;
  }
  return user?.isPremium || user?.premiumTier !== null;
}

export function newConversationsEnabled(user: UserCoreFragment): boolean {
  return true;
  if (user?.role === 'ADMIN') {
    return true;
  }
  return false;
}
