interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

export interface ParsedRec {
  title: string;
  content: string;
  contentHTML?: string;
  contentLexical?: string;
  emoji: string;
  url: string;
  date?: string | null;
  imageSrc?: string | null;
}

export interface ParsedGuest {
  name: string;
  recs: ParsedRec[];
  instagram?: string;
  website?: string;
  tiktok?: string;
  twitter?: string;
  pifyiUsername?: string;
}

export interface ParsedFeature {
  title: string;
  subtitle: string;
  url: string;
  urlSlug: string;
  draftUrl: string;
  intro: string;
  introHTML?: string;
  thumbnailSrc?: string | null;
  bannerSrc?: string | null;
  guests: ParsedGuest[];
  date?: string | null;
  cachedSubstackContent?: string | null;
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export type PrismaArgType<
  TPrismaFunction,
  TPrismaKey extends keyof ArgumentTypes<TPrismaFunction>[0],
> = NonNullable<PropType<NonNullable<ArgumentTypes<TPrismaFunction>[0]>, TPrismaKey>>;

declare global {
  interface Window {
    IS_REACT_NATIVE_WEB_VIEW?: boolean;
    IS_IOS?: boolean;
    ReactNativeWebView?: {
      postMessage(msg: string): void;
    };
  }
}

export interface ShadowBanContext {}

export enum DocumentRenderEnvironment {
  WEB = 'WEB',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
}

export enum DocumentRenderMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum DocumentRelatedDataContext {
  ContributorIntro = 'ContributorIntro',
  ContributorGraphic = 'ContributorGraphic',
  FileThumbnail = 'FileThumbnail',
  ContributorGuest = 'ContributorGuest',
}

export enum UnsubscribeEmailType {
  WEEKLY_DIGEST = 'WeeklyDigest',
  NEW_FOLLOWER_EMAIL = 'NewFollowerEmail',
  DEVELOPER_UPDATES = 'DeveloperUpdates',
  ALL_EMAILS = 'ALL_EMAILS',
}

export {};
