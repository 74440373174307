import type { File } from 'database';
import { PerfImageFromFileFragment } from './__generated__/graphql';

// export const PUBLIC_BUCKET_URL = `https://pub-8236cd699ef5408ab775bf0b0fa5446b.r2.dev`;
export const PUBLIC_BUCKET_URL = `https://files.pi.fyi`;

export enum KEY_TYPES {
  compressed = 'compressed',
  square = 'square',
  original = 'original',
}

export function getUserFileKey(userId: string, fileId: string, extension: string, root = 'users') {
  return `${root}/${userId}/images/${fileId}/original.${extension}`;
}

export function getUserFileRoot(userId: string, fileId: string, fileName: string) {
  return `users/${userId}/images/${fileId}/${fileName}`;
}

export function getFileKey(fileId: string, extension: string, root = 'etc', subroot = 'images') {
  return `${root}/${subroot}/${fileId}/original.${extension}`;
}

export function getCompressedFileKey(
  fileId: string,
  extension: string,
  quality: number,
  root = 'etc',
  subroot = 'images',
) {
  return `${root}/${subroot}/${fileId}/compressed${
    quality ? `-quality-${quality}` : ''
  }.${extension}`;
}

export function getPublicUrl(file: File, key: string = file.key) {
  return `${PUBLIC_BUCKET_URL}/${key}`;
}

export function getPublicUrlForGenericFile(fileId: string, extension: string) {
  return `${PUBLIC_BUCKET_URL}/${fileId}.${extension}`;
}

export function getPublicUrlFromKey(key: string) {
  return `${PUBLIC_BUCKET_URL}/${key}`;
}
