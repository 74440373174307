import { createFileRoute, redirect } from '@tanstack/react-router';
import { createServerFn } from '@tanstack/react-start';
import prisma from 'database';
import { getPublicUrl } from 'shared/s3';

const getTinyByKey = createServerFn({ method: 'GET' }).handler(async ({ data }) => {
  const tiny = await prisma.tinyUrl.findFirstOrThrow({ where: { key: data.key } });

  const user = await prisma.user.findFirst({
    where: { username: tiny.data as string },
    include: {
      guest: {
        include: {
          features: {
            include: {
              thumbnailFile: { include: { file: true } },
              bannerFile: { include: { file: true } },
            },
          },
        },
      },
    },
  });

  return { tiny, user };
});

export const Route = createFileRoute('/t/$key')({
  component: RouteComponent,
  head: ({ loaderData }) => {
    const { tiny, user } = loaderData;
    const usernameUpper = tiny?.data?.toUpperCase();
    const graphic = user?.guest?.features?.[0]?.thumbnailFile?.file;
    const graphicSrc = graphic ? getPublicUrl(graphic) : null;
    return {
      meta: [
        {
          title: `ACTIVATE @${usernameUpper}`,
        },
        {
          description: `A taste of ${user.firstName || user.username}'s taste.`,
        },
        {
          openGraph: {
            images: [
              graphicSrc ||
                'https://files.pi.fyi/cdn-cgi/image/quality=30,format=auto/default-profile-photo-blue-small.png',
            ],
          },
        },
      ],
    };
  },
  loader: async ({ params }) => {
    const { tiny, user } = await getTinyByKey({ data: { key: params.key } });
    throw redirect({ href: tiny.url });
  },
});

function RouteComponent() {
  return null;
}
