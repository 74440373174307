import { useToast, UseDisclosureProps, UseDisclosureReturn } from '@chakra-ui/react';
import { Modal, ModalContent } from '../ui/modal';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { subscribeSchema } from 'shared/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { gql } from 'shared/__generated__';
import { TWLink } from 'web2/app/components/link';
import { Button } from 'web2/app/components/button';
import { useRouter } from '@tanstack/react-router';
import PerfInput from '../ui/perf-input';

type Inputs = {
  email: string;
};

export default function SimpleSubscribeModal({
  redirectTo,
  modalState,
}: {
  redirectTo?: string | null;
  modalState?: UseDisclosureReturn;
}) {
  const { isOpen, onOpen, onClose } = modalState || useModalState(ModalNames.SIMPLE_SUBSCRIBE);

  const { signIn, ...auth } = useAuth();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(subscribeSchema),
  });

  const [mutation, mutationInfo] = useMutation(SIGN_UP_EMAIL_ONLY);

  const finishSignUpModalState = useModalState(ModalNames.FINISH_SIGN_UP);

  const router = useRouter();

  console.log({ redirectTo });

  const onSubmit: SubmitHandler<Inputs> = async ({ email }: Inputs, event) => {
    event?.preventDefault();
    await mutation({
      variables: {
        input: { email },
      },
      onCompleted: async (data) => {
        const token = data?.signUpEmailOnly;
        if (token) await signIn(token);
        toast({
          title: 'Subscribed',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        onClose();
        console.log({ redirectTo });

        if (redirectTo) {
          router.navigate({ to: redirectTo });
        } else {
          finishSignUpModalState.onOpen();
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onClose())}>
      <ModalContent className="md:max-w-[700px]">
        <div className="overflow-y-scroll pb-6 pt-6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col base:p-1.5 base:gap-4.5 md:gap-6"
          >
            <div className="typography-heading-lg text-brand-title-background base:text-[36px] md:text-[36px] pb-[12px]">
              a taste of someone's taste, right in your inbox.
            </div>
            <div className="self-center w-full">
              <div className="flex items-center w-full">
                <PerfInput
                  autofocus
                  placeholder="ENTER YOUR EMAIL"
                  className="flex-1"
                  {...register('email')}
                />
                <div className="bg-transparent border-l-0 rounded-none border-brand-main p-1">
                  <Button
                    type="submit"
                    className="mb-1.5 text-[15px]"
                    variant="primary"
                    disabled={mutationInfo.loading}
                  >
                    {mutationInfo.loading ? 'Loading...' : 'Sign Up'}
                  </Button>
                </div>
              </div>
              {errors.email && (
                <div className="text-red-500 font-normal">{errors.email?.message}</div>
              )}
              <button
                className="typography-action-sm opacity-40 pb-[16px] md:pb-0 hover:opacity-100 cursor-pointer leading-[150%]"
                onClick={onClose}
              >
                Skip for now
              </button>
            </div>
            <div className="flex flex-col gap-1.5">
              <div className="typography-heading-sm italic leading-[150%]">
                Recommendations from...
              </div>
              <div className="typography-action-sm leading-[150%] text-brand-title-background">
                Olivia Rodrigo, Francis Ford Coppola, Charli XCX, Ayo Edebiri, Michael Imperioli,
                Emma Chamberlain, Clairo, Molly Ringwald, Weyes Blood, Jeremy O. Harris, Conner
                O'Malley, Rayne Fisher Quann, Julian Casablancas, Alexa Chung, Mac Demarco, Moses
                Sumney, Omar Apollo, Suki Waterhouse, Narwuar, The Dare, ...
              </div>
              <div className="typography-action-sm italic leading-[150%]">
                and many more in{' '}
                <TWLink
                  to="/read"
                  className="typography-action-sm italic leading-[150%]"
                  variant="stylized"
                >
                  the Perfectly Imperfect Archive.
                </TWLink>
              </div>
            </div>
          </form>
          <div className="flex justify-between items-center w-full mt-3 base:px-1.5">
            <div className="typography-body-sm text-brand-lightgrey w-fit">
              <TWLink to="/sign-in" prefetch variant="stylized">
                Sign in
              </TWLink>
            </div>
            <div className="typography-body-sm text-brand-lightgrey w-fit">
              <TWLink to="/what-is-this" prefetch variant="stylized">
                Learn more
              </TWLink>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}

const SIGN_UP_EMAIL_ONLY = gql(/* GraphQL */ `
  mutation signUpEmailOnlyModal($input: SignUpEmailOnlyInput!) {
    signUpEmailOnly(input: $input)
  }
`);
