import React, { ReactNode, useEffect, useState } from 'react';
import Script from 'next/script';
import { useAuth } from '../hooks/useAuth';

type HeapAnalyticsProps = {
  children: ReactNode;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  interface Window {
    heap: {
      track: (event: string, properties?: Object) => void;
      identify: (identity: string) => void;
      resetIdentity: () => void;
      addUserProperties: (properties: Object) => void;
      addEventProperties: (properties: Object) => void;
      removeEventProperty: (property: string) => void;
      clearEventProperties: () => void;
      appid: string;
      userId: string;
      identity: string | null;
      config: any;
    };
  }
}

const isProd = process.env.NODE_ENV === 'production';

export default function HeapAnalytics({ children }: HeapAnalyticsProps) {
  const { user } = useAuth();
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (isProd && window.heap && user) {
      window.heap.identify(user.email);
      window.heap.addUserProperties({
        username: user.username,
        userId: user.id,
      });
    }
  }, [scriptLoaded, user]);

  const scriptReady = () => {
    if (window.heap && isProd) {
      setScriptLoaded(true);
    }
  };

  return (
    <>
      {isProd && (
        <Script
          id="heap-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
              heap.load("228692434");
            `,
          }}
          onReady={scriptReady}
        />
      )}
      {children}
    </>
  );
}
