import { useMemo } from 'react';
import hexToRgb from 'hex-to-rgb';
import { ProfileThemeType } from '../../__generated__/graphql';
import { perfectlyImperfectColors } from '../theme/colors';
import { ThemeColors, ThemeColorMap } from '../theme/custom/custom-theme-colors';
import { useThemeContext } from '../providers/ThemeContext';

export const useThemeColors = (
  theme?: ProfileThemeType | null,
  includeDefaults = true,
): ThemeColors => {
  const { activeTheme } = useThemeContext();
  const t = theme || activeTheme;
  return useMemo(() => {
    if (t) {
      const colors = ThemeColorMap[t];
      return { ...(includeDefaults ? perfectlyImperfectColors.brand : {}), ...colors };
    }
    return perfectlyImperfectColors.brand;
  }, [t]);
};

export const useDarkestColors = (theme?: ProfileThemeType | null): string[] => {
  const colors = useThemeColors(theme, false);
  return Object.values(colors).filter((color) => contrast(color, 250) === 'dark');
};

function contrast(hex, threshold = 180) {
  const rgb = hexToRgb(hex);
  const o = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000,
  );
  return o <= threshold ? 'dark' : 'light';
}
