import { genCDN } from 'shared/cdn';

export { cn } from '../app/utils/cn';

export function preloadImageByKey(key: string, quality: number = 70) {
  if (typeof window !== 'undefined') {
    const cdnImage = genCDN(key, quality, 'webp');
    if (cdnImage) {
      // Prefetch the image using the fetch API
      const prefetchImage = new Image();
      prefetchImage.src = cdnImage;
      // Optional: You can also use the modern loading hint
      prefetchImage.loading = 'eager';
    }
  }
}
