'use client';

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Tabs,
  TabList,
  Tab,
  Text,
  Link,
  Flex,
  TabPanels,
  TabPanel,
  HStack,
} from '@chakra-ui/react';
import { useRouter, useRouterState } from '@tanstack/react-router';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import {
  GetHomeMainContentTopQuery,
  GetHomePageQuery,
  ProfileThemeType,
} from 'shared/__generated__/graphql';
import HomeMainContentNew, { ArticleImageOnly } from './-home-main-content-new';
import RecItemSlimInlineComposer from 'web2/app/components/by-type/recommendations/rec-slim-inline-composer';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__';
import { FeedInfiniteScroll } from './-home-tabbed-container';
import InlineSubscribeToSignUp from 'shared/misc/components/InlineSubscribeToSignUp';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { cn } from '../../utils/cn';
import { Link as TWLink } from 'web2/app/components/link';

const AnnouncementsQuery = gql(/* GraphQL */ `
  query getAnnouncements {
    announcements {
      key
      title
      message
      important
    }
  }
`);

const RecCountQuery = gql(/* GraphQL */ `
  query extraInfo {
    me {
      id
      recCount
      invites {
        id
        code
        currentSignUpCount
      }
    }
  }
`);

const HomePageQuery = gql(/* GraphQL */ `
  query getHomePage {
    homePage {
      articleOne {
        ...ArticleTile
      }
      articleTwo {
        ...ArticleTile
      }
      articleThree {
        ...ArticleTile
      }
      dailyAsk {
        ...PromptItem
      }
      dailyAskTwo {
        ...PromptItem
      }
      hot {
        ...FullFeedItem
      }
      rising {
        ...FullFeedItem
      }
      hotAsks {
        ...FullFeedItem
      }
      piArticles {
        ...ArticleTile
      }
      staffPickOne {
        ...RecommendationItem
      }
      staffPickTwo {
        ...RecommendationItem
      }
      staffPicks {
        ...RecommendationItem
      }
      hotUsers {
        ...UserTile
      }
    }
  }
`);

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};

export default function HomeContainerNew({
  tab,
  prefetchedHomePageTopQueryData,
}: {
  prefetchedHomePageTopQueryData?: GetHomeMainContentTopQuery | null;
  tab: 'top' | 'everyone' | 'following';
}) {
  const auth = useAuth();

  const [isInView, setIsInView] = useState(true);

  // dark mode
  useAutoSetTheme(auth.user?.profileTheme || ProfileThemeType.Default);

  const themeColors = useThemeColors();

  const { background } = themeColors;

  const selectedClass = 'text-2xl md:text-3xl text-brand-highlight';

  const { data } = useQuery(RecCountQuery);

  const dataToRender = prefetchedHomePageTopQueryData?.homePageTop;

  const articles = dataToRender?.piArticles ?? [];

  const searchParams = useSearchParams();
  const hideTabs = true || searchParams.get('hideTabs') === 'true';
  const hideArticles = true || searchParams.get('hideArticles') === 'true';
  const showTabs = tab !== 'top';

  const indexMap = {
    top: 0,
    following: auth.user && auth.user?.followingCount ? 1 : 1000,
    everyone: auth.user && auth.user?.followingCount ? 2 : 1,
  };

  let defaultIndex = 1;

  if (tab) {
    defaultIndex = indexMap[tab];

    if (typeof defaultIndex == 'undefined') defaultIndex = 1;
  }

  if ((!auth.user && auth.isFullyLoaded) || !auth.user?.followingCount) {
    defaultIndex = 0;
  }

  const onlyShowEveryone = !auth.user;

  const router = useRouter();

  useEffect(() => {
    if (tab === 'following' && !(auth.user && auth.user?.followingCount)) {
      router.navigate({ to: '/' });
    }
  }, [auth.user]);

  const firstInvite = data?.me?.invites[0];

  const contentWidthClass = 'w-full md:w-[800px]';

  const feedComposer = auth.user ? (
    <div className="flex flex-col gap-4 mb-[24px]">
      <div className="w-full flex justify-center">
        <div className="min-w-full">
          <RecItemSlimInlineComposer
            withInvertedEmojiLink
            startCollapsed
            redirectToRec
            oneLineViewMode={tab === 'top'}
            titlePlaceholder={
              data?.me?.recCount === 0 ? 'Share your first recommendation!' : undefined
            }
            bodyPlaceholder={data?.me?.recCount === 0 ? 'If you like it, share it.' : undefined}
            withRotatingOptions={(data?.me?.recCount || 0) > 0}
          />
        </div>
      </div>
      {firstInvite && !auth.user.premiumTier ? (
        <div className="self-start">
          <HStack as={Link} href="/invites" spacing="6px">
            <Text textStyle="brand.actionSm" opacity={0.7}>
              Invite {5 - firstInvite?.currentSignUpCount || 0}
              {firstInvite.currentSignUpCount > 0 ? ' more' : ''} people to earn a free month of
              PI.FYI pro{' '}
            </Text>
            <Text textStyle="brand.actionSm" color="brand.highlightBorder" opacity={1} as="span">
              LEARN MORE.
            </Text>
          </HStack>
        </div>
      ) : null}
    </div>
  ) : null;
  return (
    <>
      <div className="overflow-visible relative mx-[8px] md:mx-[32px]">
        <div className="w-full overflow-x-hidden overflow-y-scroll pt-[18px] px-0 sm:px-[16px] lg:px-[64px] flex flex-col">
          <div className="relative w-full min-h-full">
            <div className="flex justify-center">
              <div
                className={cn(
                  'flex flex-col gap-[24px] w-full justify-center',
                  tab !== 'top' ? 'max-w-[900px]' : '',
                )}
              >
                <Tabs
                  isFitted
                  variant="stylized"
                  isLazy
                  lazyBehavior="unmount"
                  w="100%"
                  index={indexMap[tab]}
                  position="initial"
                  border="none"
                >
                  {showTabs ? (
                    <div className="flex flex-col gap-[6px] w-full justify-center z-[100] mb-[24px] self-center">
                      <div className="flex border-0 mt-[18px] mb-0 self-center max-w-full w-full h-[50px] md:h-[75px]">
                        <TWLink
                          to={'/'}
                          className={cn(
                            'flex-1 text-center text-brand-main scale-y-125',
                            'text-xl md:text-2xl',

                            'tracking-widest',
                            'typography-heading-md sm:[-webkit-text-stroke:1px] sm:[-webkit-text-stroke-color:(var(--theme-background))]',
                            {
                              [selectedClass]: tab === 'top',
                            },
                          )}
                        >
                          HOME
                        </TWLink>

                        {(auth.user?.followingCount || 0) > 0 && (
                          <TWLink
                            to={'/home/$tab'}
                            params={{ tab: 'following' }}
                            className={cn(
                              'flex-1 text-center text-brand-main scale-y-125',

                              'text-xl md:text-2xl',

                              'tracking-widest',
                              'typography-heading-md sm:[-webkit-text-stroke:1px] sm:[-webkit-text-stroke-color:(var(--theme-background))]',
                              {
                                [selectedClass]: tab === 'following',
                              },
                            )}
                            onClick={(e) => {
                              if (!auth.user) {
                                e.preventDefault();
                                router.navigate({ to: '/sign-in' });
                              }
                            }}
                          >
                            FRIENDS
                          </TWLink>
                        )}

                        <TWLink
                          to={'/home/$tab'}
                          params={{ tab: 'everyone' }}
                          className={cn(
                            'flex-1 text-center text-brand-main scale-y-125',
                            'text-xl md:text-2xl',

                            'tracking-widest',
                            'typography-heading-md sm:[-webkit-text-stroke:1px] sm:[-webkit-text-stroke-color:(var(--theme-background))]',
                            {
                              [selectedClass]: tab === 'everyone',
                            },
                          )}
                        >
                          EVERYONE
                        </TWLink>
                      </div>
                    </div>
                  ) : null}

                  <TabPanels>
                    <TabPanel>
                      <HomeMainContentNew
                        newLinks={hideTabs}
                        prefetchedHomePageTopQueryData={prefetchedHomePageTopQueryData}
                      />
                    </TabPanel>
                    {(auth.user?.followingCount || 0) > 0 && (
                      <TabPanel className="w-full flex flex-col items-center self-center overflow-y-scroll">
                        {!onlyShowEveryone ? (
                          <div
                            className={cn(
                              contentWidthClass,
                              'mt-[12px] sm:mt-[24px] px-[12px] sm:px-[0px]',
                            )}
                          >
                            {/* {recEditor} */}
                            <div
                              className={cn(
                                !auth.user ? 'flex flex-col justify-center' : '',
                                'w-full',
                              )}
                            >
                              {feedComposer}
                            </div>
                            <FeedInfiniteScroll
                              key="following"
                              onlyFollowing
                              isInView={isInView}
                              // prefetchedHomePageQueryData={prefetchedHomePageQueryData}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </TabPanel>
                    )}
                    <TabPanel className="w-full flex flex-col items-center self-center overflow-y-scroll">
                      <div
                        className={cn(
                          contentWidthClass,
                          'mt-[12px] sm:mt-[24px] px-[12px] sm:px-[0px]',
                        )}
                      >
                        <div
                          className={cn(!auth.user ? 'flex flex-col justify-center' : '', 'w-full')}
                        >
                          {feedComposer}
                        </div>

                        <FeedInfiniteScroll key="all" onlyFollowing={false} isInView={isInView} />
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!hideArticles && articles.length && (
        <Box
          as="aside"
          flexDirection="column"
          ml="0 !important"
          minWidth="200px"
          width="200px !important"
          position="sticky"
          top="0"
          right="0"
          left="calc(100% - 200px)"
          overflowY="scroll"
          maxHeight="calc(100vh - 64px)"
          className="hidden xl:flex"
        >
          {articles.map((article) => (
            <ArticleImageOnly spacing="0" article={article} key={article.id} />
          ))}
          <Button
            variant="primary"
            width="200px"
            height="64px"
            minHeight="64px"
            onClick={() => router.navigate({ to: '/read' })}
          >
            See More
          </Button>
        </Box>
      )}
    </>
  );
}
