import React, { Node, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import {
  isInWebView,
  safeSendMessageToReactNativeFromWebView,
  shouldAddSafePaddingTop,
} from '../../webview';
import { CacheSyncMessages, GlobalWebViewMessages } from '../../webview/messages';
import { sneakySneaky } from '../apollo/base';

type Props = { children: ReactNode };

export default function HandleWebView({ children }: Props) {
  const client = useApolloClient();
  const router = useRouter();
  const [shouldWrapWithBox, setShouldWrapWithBox] = useState(false);
  const [paddingTop, setPaddingTop] = useState('24px');

  useEffect(() => {
    if (isInWebView()) {
      sneakySneaky(client.cache, client);

      const cb = (event) => {
        if (!event?.data) return;

        const { msg, value } = safeParse(event?.data) || { msg: event.data, value: null };

        try {
          switch (msg) {
            case CacheSyncMessages.WRITE: {
              if (value.origin !== 'WEB') {
                try {
                  // @ts-ignore
                  client.cache._write(...value.args);
                } catch (e) {
                  console.log('Something went wrong applying cache update.');
                }
              }
              break;
            }
            case CacheSyncMessages.EVICT: {
              if (value.origin !== 'WEB') {
                try {
                  // @ts-ignore
                  client.cache._evict(...value.args);
                } catch (e) {
                  console.log('Something went wrong applying cache update.');
                }
              }
              break;
            }
            case CacheSyncMessages.MODIFY: {
              if (value.origin !== 'WEB') {
                try {
                  // @ts-ignore
                  client.cache._modify(...value.args);
                } catch (e) {
                  console.log('Something went wrong applying cache update.');
                }
              }
              break;
            }
            case CacheSyncMessages.REFETCH_QUERIES: {
              // alert(`${JSON.stringify(value?.refetchQueries)}`);
              // alert(`Received ${value.origin} ${JSON.stringify(value?.refetchQueries)}`);

              if (value.origin !== 'WEB') {
                client
                  .refetchQueries({
                    include: value?.refetchQueries || [],
                  })
                  .then((res) => {});
              }
              break;
            }
          }
        } catch (e) {
          console.log('Failed to sync cache.');
          console.log(e);
        }
      };

      router.replace = (pathname) => {
        safeSendMessageToReactNativeFromWebView(GlobalWebViewMessages.NEXT_NAVIGATION, {
          pathname,
        });
      };

      router.push = (pathname) => {
        safeSendMessageToReactNativeFromWebView(GlobalWebViewMessages.NEXT_NAVIGATION, {
          pathname,
        });
      };

      window.addEventListener('message', cb);
      return () => window.removeEventListener('message', cb);
    }
  }, [client, router]);

  useEffect(() => {
    if (isInWebView() && shouldAddSafePaddingTop()) {
      setShouldWrapWithBox(true);
      if ('ADD_SAFE_PADDING_TOP' in window && window?.ADD_SAFE_PADDING_TOP) {
        setPaddingTop(window.ADD_SAFE_PADDING_TOP);
      }
    }
  }, []);

  return shouldWrapWithBox ? (
    <Box width="100%" height="100vh" paddingTop={paddingTop}>
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
}

export function safeParse(maybeJson?: string | null) {
  if (typeof maybeJson === 'object') {
    return maybeJson;
  }

  try {
    // @ts-ignore
    const parsed = JSON.parse(maybeJson);
    return typeof parsed === 'object' ? parsed : null;
  } catch (e) {
    return null;
  }
}
