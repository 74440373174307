import { createFileRoute, redirect, Outlet } from '@tanstack/react-router';
import { getSessionUser } from '../actions/getSessionUser';

export const Route = createFileRoute('/admin')({
  beforeLoad: async () => {
    const user = await getSessionUser();
    if ((!user || user.role !== 'ADMIN') && process.env['NODE_ENV'] === 'production') {
      throw redirect({ to: '/' });
    }
  },
});
