import { gql } from '../../__generated__';

export const DELETE_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation deletePrompt($promptId: String!) {
    deletePrompt(promptId: $promptId)
  }
`);

export const SUBSCRIBE_TO_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation subscribeToPrompt($targetPromptId: String!) {
    subscribeToPrompt(targetPromptId: $targetPromptId) {
      id
      isViewerSubscribed
    }
  }
`);

export const UNSUBSCRIBE_FROM_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation unsubscribeFromPrompt($targetPromptId: String!) {
    unsubscribeFromPrompt(targetPromptId: $targetPromptId) {
      id
      isViewerSubscribed
    }
  }
`);

export const ENDORSE_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation endorsePrompt($targetPromptId: String!) {
    endorsePrompt(targetPromptId: $targetPromptId) {
      id
      isEndorsed
      endorsementCount
    }
  }
`);

export const UNENDORSE_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation unendorsePrompt($targetPromptId: String!) {
    unendorsePrompt(targetPromptId: $targetPromptId) {
      id
      isEndorsed
      endorsementCount
    }
  }
`);

export const MUTE_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation mutePrompt($promptId: String!) {
    mutePrompt(promptId: $promptId) {
      id
      muted
    }
  }
`);

export const UNMUTE_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation unmutePrompt($promptId: String!) {
    unmutePrompt(promptId: $promptId) {
      id
      muted
    }
  }
`);

