import { useCallback, useContext, useEffect, useMemo } from 'react';
import { gql } from '../../__generated__/gql';
import { NetworkStatus, useFragment, useMutation, useQuery } from '@apollo/client';
import {
  ConversationCoreFragment,
  ConversationEventCoreFragment,
  ConversationEventItemFragment,
  ConversationItemFragmentDoc,
  UpdateConversationMutationVariables,
  UserCoreFragment,
} from '../../__generated__/graphql';
import { processLexicalState } from '../../lexical';
import React from 'react';
import _ from 'lodash';

gql(/* GraphQL */ `
  fragment ConversationCore on Conversation {
    id
    name
    theme
    users {
      user {
        ...UserCore
        id
        username
        firstName
        lastName
        createdAt
        updatedAt
        avatarPhotoSrc
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment ConversationItem on Conversation {
    ...ConversationCore
    unseenEventsCount
    eventsCount
    recentMessageEvents {
      ...ConversationEventCore
    }
  }
`);

gql(/* GraphQL */ `
  fragment ConversationEventCore on ConversationEvent {
    id
    type
    createdAt
    targetConversationId
    threadId
    originUser {
      ...UserCore
    }
    targetMessage {
      id
      content
      contentLexical
    }
    targetRec {
      ...RecommendationItem
    }
  }
`);

gql(/* GraphQL */ `
  fragment ConversationEventItem on ConversationEvent {
    ...ConversationEventCore
    replyTo {
      ...ConversationEventCore
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getConvoWeb($id: String!, $threadId: String, $first: Int, $after: String) {
    conversationEventConnection(convoId: $id, threadId: $threadId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationEventItem
        }
      }
    }
  }
`);

const SubscriptionQuery = gql(/* GraphQL */ `
  subscription OnNewConversationEvent($id: String!) {
    onConversationEvent(convoId: $id) {
      cursor
      node {
        id
        ...ConversationEventItem
        targetRec {
          ...RecommendationItem
          targetRec {
            ...RecommendationItem
          }
        }
      }
    }
  }
`);

interface ConvoHookArgs {
  convoId: string;
  threadId?: string | null;
  reverse?: boolean;
}

const PAGE_LENGTH = 40;

type MergedEvent = {
  originUser: UserCoreFragment;
  data: ConversationEventCoreFragment['createdAt'];
  events: ConversationEventCoreFragment[];
};

export const useConvoEventListHooks = ({ convoId, threadId, reverse = true }: ConvoHookArgs) => {
  const { data, loading, variables, refetch, fetchMore, subscribeToMore, networkStatus } = useQuery(
    PageQuery,
    {
      variables: { id: convoId, threadId, first: PAGE_LENGTH },
      initialFetchPolicy: 'network-only',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { conversation } = useConversationItemQueryHooks({ convoId });

  const isInitialLoad = networkStatus === NetworkStatus.loading && !data;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const { markAllEventsAsSeen } = useConvoActions({ convoId });

  useEffect(() => {
    if (conversation) {
      markAllEventsAsSeen();
    }
  }, [conversation?.id]);

  useEffect(() => {
    return subscribeToMore({
      document: SubscriptionQuery,
      variables: { id: convoId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) return prev;
        const event = subscriptionData.data.onConversationEvent;

        if (!event?.node || (threadId && event?.node?.threadId !== threadId)) return prev;

        let edges = [
          { __typename: 'QueryConversationEventConnectionEdge', ...event },
          ...(prev.conversationEventConnection?.edges || []),
        ];

        // console.log('event', event);
        return Object.assign({}, prev, {
          conversationEventConnection: {
            ...prev.conversationEventConnection,
            edges,
          },
        });
      },
      onError: (err) => {
        console.log(JSON.stringify(err, null, 2));
      },
    });
  }, [subscribeToMore, convoId, threadId, reverse]);

  const getNextPage = useCallback(async () => {
    if (!data?.conversationEventConnection.pageInfo.hasNextPage || loading) return;
    return await fetchMore({
      variables: {
        ...variables,
        first: PAGE_LENGTH,
        after: data?.conversationEventConnection.pageInfo.endCursor,
      },
    });
  }, [data, fetchMore]);

  const eventEdges = useMemo(() => {
    const res = [...(data?.conversationEventConnection?.edges || [])];
    return reverse ? res.reverse() : res;
  }, [data, reverse]);

  const mergedEventEdges = useMemo(
    () => mergeEvents(eventEdges.map((edge) => edge.node)),
    [eventEdges],
  );

  return {
    eventEdges,
    conversation,
    mergedEventEdges,
    getNextPage,
    isInitialLoad,
    isFetchingMore,
    refetch,
  };
};

const ConversationItemQuery = gql(/* GraphQL */ `
  query getConversationItemQuery($id: String!) {
    conversation(id: $id) {
      ...ConversationItem
    }
  }
`);

export const useConversationItemQueryHooks = ({ convoId }: ConvoHookArgs) => {
  const { complete, data: convoCoreFragment } = useFragment({
    fragment: ConversationItemFragmentDoc,
    fragmentName: 'ConversationItem',
    from: {
      __typename: 'Conversation',
      id: convoId,
    },
  });

  const { data, loading } = useQuery(ConversationItemQuery, {
    variables: { id: convoId },
    fetchPolicy: 'network-only',
    context: {
      fetchOptions: {
        next: { revalidate: 0 },
      },
    },
  });

  const conversation = data?.conversation || (complete ? convoCoreFragment : data?.conversation);

  return { conversation, loading };
};

export const LEAVE_CONVERSAION = gql(/* GraphQL */ `
  mutation leaveConversationWeb($conversationId: String!) {
    leaveConversation(conversationId: $conversationId)
  }
`);

export const MARK_ALL_EVENTS_AS_SEEN = gql(/* GraphQL */ `
  mutation markAllConversationEventsAsSeen($convoId: String!) {
    markAllEventsAsSeen(convoId: $convoId) {
      id
      name
      theme
      unseenEventsCount
      eventsCount
    }
  }
`);

export const UPDATE_CONVERSATION = gql(/* GraphQL */ `
  mutation updateConversation($convoId: String!, $name: String, $theme: ProfileThemeType) {
    updateConversation(convoId: $convoId, name: $name, theme: $theme) {
      id
      name
      theme
    }
  }
`);

export function useConvoActions({ convoId }: { convoId: string }) {
  const [leaveConversationMutation] = useMutation(LEAVE_CONVERSAION, {
    refetchQueries: ['getMobileConversations', 'getWebConversations'],
  });

  const [markAllEventsAsSeenMutation] = useMutation(MARK_ALL_EVENTS_AS_SEEN, {
    refetchQueries: ['getMobileConversations', 'getWebConversations', 'getNotificationCount'],
  });

  const [updateConversationMutation] = useMutation(UPDATE_CONVERSATION);

  const leaveConvo = useCallback(async (args?: any) => {
    await leaveConversationMutation({
      variables: {
        conversationId: convoId,
      },
      ...args,
    });
  }, []);

  const markAllEventsAsSeen = useCallback(async (args?: any) => {
    await markAllEventsAsSeenMutation({
      variables: {
        convoId,
      },
      ...args,
    });
  }, []);

  const updateConversation = useCallback(
    async (variables: Omit<UpdateConversationMutationVariables, 'convoId'>, args?: any) => {
      await updateConversationMutation({
        variables: {
          ...variables,
          convoId,
        },
        ...args,
      });
    },
    [],
  );

  return { leaveConvo, markAllEventsAsSeen, updateConversation };
}

function mergeEvents(events: ConversationEventCoreFragment[]): MergedEvent[] {
  const merged: MergedEvent[] = [];
  let currentEvent: MergedEvent | null = null;

  events.forEach((event) => {
    const eventTime = new Date(event.createdAt).getTime();
    const originUserId = event.originUser.id;

    if (currentEvent && currentEvent.originUser.id === originUserId) {
      const lastEventTime = new Date(currentEvent.data).getTime();
      // 3 minutes
      if (eventTime - lastEventTime <= 1 * 60 * 1000 && currentEvent.events.length < 5) {
        currentEvent.events.push(event);
      } else {
        merged.push(currentEvent);
        currentEvent = { originUser: event.originUser, data: event.createdAt, events: [event] };
      }
    } else {
      if (currentEvent) merged.push(currentEvent);
      currentEvent = { originUser: event.originUser, data: event.createdAt, events: [event] };
    }
  });

  if (currentEvent) merged.push(currentEvent); // Push the last event

  return merged;
}

const SendMessageMutation = gql(/* GraphQL */ `
  mutation sendConversationMessage(
    $content: String
    $convoId: String!
    $contentLexical: String
    $targetRecId: String
    $replyToConversationEventId: String
    $threadId: String
  ) {
    sendConversationMessage(
      content: $content
      convoId: $convoId
      contentLexical: $contentLexical
      targetRecId: $targetRecId
      replyToConversationEventId: $replyToConversationEventId
      threadId: $threadId
    ) {
      edge {
        cursor
        node {
          ...ConversationEventItem
        }
      }
    }
  }
`);

export function useConvoComposerHooks({ convoId }: ConvoHookArgs) {
  const ctx = useConvoContext();

  const [sendMessage, sendMessageInfo] = useMutation(SendMessageMutation);

  const sendTextMessage = useCallback(
    async (values: { content: any }, { threadId, isLexical = true, onCompleted }) => {
      let { text: content, json: contentLexical } = isLexical
        ? processLexicalState(values.content)
        : { text: values.content, json: null };

      if (!content?.trim().length) return;

      const variables = {
        convoId,
        content,
        contentLexical,
        replyToConversationEventId: ctx.replyingTo?.id,
        threadId: threadId || ctx.replyingTo?.threadId || ctx.replyingTo?.id,
      };

      await sendMessage({
        variables,
        onCompleted,
        onError: (error) => {
          console.log('error sending message');
        },
        // update: (cache, { data }) => {
        //   const newEvent = data?.sendConversationMessage?.edge;

        //   const existingData = cache.readQuery({
        //     query: PageQuery,
        //     variables: { id: convoId },
        //   });

        //   console.log({
        //     newEvent,
        //     existingData,
        //   });

        //   if (!existingData || !newEvent) return existingData;

        //   cache.writeQuery({
        //     query: PageQuery,
        //     variables: { id: convoId },
        //     data: {
        //       ...existingData,
        //       conversationEventConnection: {
        //         ...(existingData?.conversationEventConnection || {}),
        //         edges: [newEvent, ...(existingData?.conversationEventConnection.edges || [])],
        //       },
        //     },
        //   });
        // },
      });
    },
    [sendMessage, ctx.replyingTo, convoId],
  );

  return { sendTextMessage, isSending: sendMessageInfo.loading };
}

export const CreateConversationMutation = gql(/* GraphQL */ `
  mutation createConversation($userIds: [String!]!) {
    createConversation(userIds: $userIds) {
      # Single Conversation
      ...ConversationCore
    }
  }
`);

export function useConvoCreationHooks() {
  const [createConversation, createConversationInfo] = useMutation(CreateConversationMutation);

  const startConvoWithUsers = useCallback(
    async ({ userIds }: { userIds: string[] }) => {
      const res = await createConversation({
        variables: {
          userIds,
        },
      });

      return res.data?.createConversation.id;
    },
    [createConversation],
  );

  return { startConvoWithUsers, isSubmitting: createConversationInfo.loading };
}

export function useConvoEventHelpers() {
  const getOneLine = useCallback(
    (event: ConversationEventCoreFragment | null, shouldTruncate = true) => {
      if (!event) return '';

      if (event.targetRec) {
        return `${event.targetRec.emoji} ${
          shouldTruncate ? _.truncate(event.targetRec.title, { length: 50 }) : event.targetRec.title
        }`;
      }
      return shouldTruncate
        ? _.truncate(event.targetMessage?.content || '', { length: 50 })
        : event.targetMessage?.content || '';
    },
    [],
  );

  return { getOneLine };
}

type ContextProps = {
  conversation?: ConversationCoreFragment | null;
  replyingTo: ConversationEventItemFragment | null;
  setReplyingTo: React.Dispatch<React.SetStateAction<ConversationEventItemFragment | null>>;
};

export const ConvoContext = React.createContext<ContextProps>({} as ContextProps);

export const useConvoContext = () => {
  return useContext(ConvoContext);
};

export function ConvoProvider({
  children,
  conversation,
}: {
  children: React.ReactNode;
  conversation: ConversationCoreFragment;
}) {
  const [replyingTo, setReplyingTo] = React.useState<ConversationEventItemFragment | null>(null);
  return (
    <ConvoContext.Provider value={{ replyingTo, setReplyingTo, conversation }}>
      {children}
    </ConvoContext.Provider>
  );
}
