import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

// define the base component styles
const stylized = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: 'semibold', // change the font weight
    color: 'brand.main',
    // bottomBorder: '1px dashed',
    // borderBottomColor: 'brand.main',
    textStyle: 'brand.actionSm',
    borderRadius: '0px !important',
    fontSize: '16px',
    marginBottom: '0px',
    _selected: {
      // bottomBorder: '1px dashed',
      // borderBottomColor: 'brand.main',
      // borderBottom: '1px dashed',
      color: 'brand.highlight',
    },
  },
  tablist: {
    color: 'brand.main',
    paddingBottom: '0px',
    bottomBorder: '1px dashed',
    borderColor: 'brand.main',
    borderBottom: '1px dashed',
  },
  root: {
    borderTopRadius: '0px',
  },
  tabpanel: {
    padding: '0px',
  },
});

const button = definePartsStyle({
  tab: {
    fontWeight: 'semibold',
    color: 'brand.main',
    border: '1px dashed',
    borderColor: 'brand.main',
    padding: '0px',
    textStyle: 'brand.actionSm',
    borderRadius: '0px !important',
    fontSize: '16px',
    _selected: {
      color: 'brand.highlight',
    },
  },
  tablist: {
    color: 'brand.main',
    bottomBorder: '1px dashed',
    borderColor: 'brand.main',
    borderBottom: '1px dashed',
  },
  root: {
    borderTopRadius: '0px',
  },
  tabpanel: {
    padding: '0px',
  },
});

// export the component theme
export const Tabs = defineMultiStyleConfig({
  variants: { stylized, button },
  defaultProps: { variant: 'stylized' },
});
