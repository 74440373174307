'use client';

import React from 'react';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { MentionUserFragment } from '../../../../../../__generated__/graphql';
import { TWLink } from '../../../../../../../../apps/web2/app/components/link';

type Props = { id: string; user: MentionUserFragment };

function MentionComponent({ user }: Props) {
  const isEditable = useLexicalEditable();
  const [editor] = useLexicalComposerContext();

  return (
    <a
      href={isEditable ? '#' : `/u/${user.username}`}
      className={`${editor._config.theme.link} ${editor._config.theme.paragraph} !border-b-[0px] typography-action-sm`}
      // textDecoration="none !important"
      // borderBottom="1px transparent !important"
      variant="stylizedNoDefaultBorder"
    >
      <span className="font-brand-arial-narrow uppercase text-brand-highlight">
        @{user.username}
      </span>
    </a>
  );
}

export default MentionComponent;
