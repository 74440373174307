import React, { useEffect } from 'react';
import {
  Button,
  Stack,
  Text,
  ButtonGroup,
  useDisclosure,
  UseDisclosureReturn,
  Icon,
  HStack,
  Box,
  Spinner,
  Link,
  useToast,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '../ui/modal';
import { RiCheckFill } from 'react-icons/ri';
import useSelectUsersForConversation from 'shared/misc/hooks/useSelectUsersForConversation';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { getUserStringForMultipleUsers } from 'shared/multipleUsersUtils';
import UserAvatarStack from '../by-type/user/user-avatar-stack';
import { UserCoreFragment } from 'shared/__generated__/graphql';

type Props = { modalState?: UseDisclosureReturn; recId: string };

export default function SendToConvoUsersModal({ modalState: _modalState, recId }: Props) {
  const modalState = _modalState || useDisclosure();
  const auth = useAuth<true>();

  const toast = useToast();

  const {
    isSearching,
    resultsToDisplay,
    onToggle,
    selectedCount,
    isSelected,
    allSelectedUserIds,
    selectedGroupConvos,
    sendExistingRecState,
    createConversationState,
    onCreateGroup,
    onConfirm,
    form,
  } = useSelectUsersForConversation({
    recId,
    onConfirm: (ids) => {
      modalState.onClose();

      toast({
        title: 'Rec sent!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: () => (
          <Box
            border="2px solid"
            borderRadius="0px !important"
            textStyle="brand.body !important"
            fontSize="13.5px !important"
            textTransform="uppercase"
            backgroundColor="white !important"
            bgColor="white !important"
            padding="12px"
            color="black !important"
          >
            <Stack spacing="6px">
              <Text textStyle="brand.headingSm">Rec sent</Text>
              <Box>
                <Link
                  href="/convos"
                  variant="stylizedBasic"
                  color="blue"
                  textStyle="brand.actionSm"
                >
                  Go to convos
                </Link>
              </Box>
            </Stack>
          </Box>
        ),
      });
    },
  });

  useEffect(() => {
    form.setFocus('query');
  }, []);

  return (
    <>
      <Modal open={modalState.isOpen} onOpenChange={modalState.onToggle}>
        <ModalOverlay />
        <ModalContent className="flex flex-col justify-start align-stretch w-[100dvw] md:w-[600px] h-[100dvh] md:h-[600px] max-h-[600px] md:max-h-[90%] m-0 lg:ml-16 lg:mr-16 lg:mb-16 lg:mt-16 p-[12px] overflow-hidden">
          <ModalHeader className="text-[22px] lg:text-[36px] border-none h-[50px] opacity-100 box-border">
            Send to...
          </ModalHeader>
          <div className="flex-1 overflow-y-auto h-full md:max-h-[450px] w-full align-start self-start">
            <InputGroup>
              <Input {...form.register('query')} maxW="574px" pos="fixed" backgroundColor="brand.background" mb="12px" placeholder="Search people" />
              {isSearching && (
                <InputRightElement bg="none" p="4px">
                  <Spinner size="sm" />
                </InputRightElement>
              )}
            </InputGroup>
            <div className="overflow-y-auto h-auto pt-[40px] flex flex-col gap-2">
              {resultsToDisplay.map((result) => {
                const isConversation = result.__typename === 'Conversation';
                const users = (
                  isConversation ? result.users.map(({ user }) => user) : [result]
                ).filter((u) => u.id !== auth.user?.id) as UserCoreFragment[];

                const selected = isSelected(result);

                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    backgroundColor={selected ? 'brand.highlight' : 'brand.background'}
                  >
                    <HStack onClick={() => onToggle(result)}>
                      <UserAvatarStack users={users} />
                      <Text
                        textStyle="brand.actionSm"
                        color={selected ? 'brand.background' : 'brand.main'}
                      >
                        {getUserStringForMultipleUsers(users)}
                      </Text>
                    </HStack>
                    {selected && (
                      <Icon
                        mx="6px"
                        color={selected ? 'brand.background' : 'brand.highlight'}
                        as={RiCheckFill}
                        size={20}
                      />
                    )}
                  </Box>
                );
              })}
            </div>
          </div>
          <ModalFooter className="flex justify-end h-[75px] absolute bottom-0 w-[calc(100%-24px)]">
            <ButtonGroup>
              {allSelectedUserIds.length > 1 &&
                selectedGroupConvos.length === 0 &&
                !sendExistingRecState.loading && (
                  <Button
                    alignSelf="center"
                    // opacity={0.7}
                    variant="secondary"
                    isDisabled={createConversationState.loading}
                    isLoading={createConversationState.loading}
                    onClick={() => {
                      if (createConversationState.loading || sendExistingRecState.loading) return;
                      onCreateGroup();
                    }}
                  >
                    Create group?
                  </Button>
                )}
              <Button
                variant="primary"
                onClick={() => {
                  if (createConversationState.loading || sendExistingRecState.loading) return;
                  onConfirm();
                }}
                isDisabled={selectedCount === 0 || sendExistingRecState.loading}
                isLoading={sendExistingRecState.loading}
              >
                Send
              </Button>
              {/* <Button variant="primaryBlack" onClick={createModalState.onOpen}>
                Create a new list
              </Button> */}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
