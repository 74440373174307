import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { gql } from '../../__generated__/gql';
import { RecommendationItemFragment } from '../../__generated__/graphql';

export default function useMarkAllRelatedFeedItemsAsSeen({
  rec,
}: {
  rec: RecommendationItemFragment;
}) {
  const [markAllRelatedToConversationRecAsSeen, _markAllRelatedToConversationRecAsSeenState] =
    useMutation(MARK_ALL_RELATED_AS_SEEN, {
      // onCompleted: (data) => console.log(data),
      // onError: (data) => console.log(data),
      refetchQueries: ['getAllNotificationsCount', 'getNotificationsCount'],
    });

  useEffect(() => {
    // console.log('useMarkAllRelatedFeedItemsAsSeen', rec.conversation?.id);
    if (!rec || !rec.conversation?.id) return;
    markAllRelatedToConversationRecAsSeen({
      variables: {
        targetRecId: rec.id,
        targetConversationId: rec.conversation.id,
      },
      refetchQueries: ['getAllNotificationsCount', 'getNotificationsCount'],
    }).then();
  }, [rec?.conversation?.id]);
}

const MARK_ALL_RELATED_AS_SEEN = gql(/* GraphQL */ `
  mutation markAllRelatedToConversationRecAsSeen(
    $targetRecId: String!
    $targetConversationId: String!
  ) {
    markAllRelatedToConversationRecAsSeen(
      targetRecId: $targetRecId
      targetConversationId: $targetConversationId
    ) {
      ...ConversationFeedItem
      unseenTargetRecFeedItemsCount
      # Make to update unseenFeedItemsCount on parent conversation
      targetConversation {
        id
        unseenFeedItemsCount
        unseenSignificantFeedItemsCount
      }
    }
  }
`);
