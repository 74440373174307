import { Stack, HStack, Text } from '@chakra-ui/react';
import { RecommendationItemFragment } from '../../../../__generated__/graphql';
import { getUserStringForMultipleUsers } from '../../../../multipleUsersUtils';
import { useAuth } from '../../../hooks/useAuth';
import useMarkAllRelatedFeedItemsAsSeen from '../../../hooks/useMarkAllRelatedFeedItemsAsSeen';
import UserAvatarStack from '../user/UserAvatarStack';

interface RecommendationPrivateContextProps {
  rec: RecommendationItemFragment;
}

export default function RecommendationPrivateContext({ rec }: RecommendationPrivateContextProps) {
  const auth = useAuth();

  useMarkAllRelatedFeedItemsAsSeen({ rec });

  if (!rec.conversation) return null;

  const users = rec.conversation.users.map(({ user }) => user);

  return (
    <Stack
      spacing="6px"
      alignItems="center"
      alignSelf="center"
      width="fit-content"
      _hover={{ opacity: 1 }}
      maxWidth="calc(100% - 96px)"
    >
      {/* <Text textStyle="brand.headingLg" textAlign="center" fontSize={['22px', '30px']}>
                    SHARED BY @{rec.user.username}
                  </Text> */}
      <HStack spacing="6px">
        <UserAvatarStack users={users} size={15} />
        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontSize="11px"
          paddingRight="0.3em"
          textStyle="brand.actionSm"
          opacity={0.6}
        >
          ONLY {getUserStringForMultipleUsers(users, auth.user?.id)} CAN SEE THIS.
        </Text>
      </HStack>
    </Stack>
  );
}
