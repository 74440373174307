import _ from 'lodash';
import { UserCoreFragment } from './__generated__/graphql';

export function getUserStringForMultipleUsers(
  users: UserCoreFragment[],
  authUserId?: string,
): string {
  const sortedUsers = users.sort((a, b) => (a.id !== authUserId ? 0 : -1));
  const getUsername = (user) =>
    `${authUserId === user.id ? 'YOU' : `@${_.truncate(user.username, { length: 20 })}`}`;
  if (sortedUsers.length === 0) return '';
  if (sortedUsers.length === 1) return getUsername(sortedUsers[0]);
  if (sortedUsers.length === 2) return sortedUsers.map((user) => getUsername(user)).join(' + ');
  const firstTwoUsers = sortedUsers
    .slice(0, 2)
    .map((user) => getUsername(user))
    .join(' + ');
  const othersCount = sortedUsers.length - 2;
  return `${firstTwoUsers} + ${othersCount} other${othersCount > 1 ? 's' : ''}`;
}
