import { createFileRoute } from '@tanstack/react-router';
import { genCDN } from 'shared/cdn';
import { preloadImageByKey } from '../../../lib/utils';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';
import InlineSubscribeToSignUp from 'shared/misc/components/InlineSubscribeToSignUp';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { GetRecViewQuery } from 'shared/__generated__/graphql';
import { genMeta } from '../../utils/meta';

const FILE_URL = genCDN('sxsw-2025-final.png', 80, 'webp');

export const Route = createFileRoute('/sxsw-2025/')({
  component: RouteComponent,
  staleTime: 0,
  head(ctx) {
    const image = genCDN('sxsw-2025-final.png', 80, 'webp');

    return genMeta({
      title: 'PI x YOU MISSED IT - SXSW 2025',
      description: 'WILL YOU BE THERE? OR WILL YOU MISS IT?',
      image,
    });
  },
  loader: async ({ context }) => {
    preloadImageByKey('sxsw-2025-final.png', 80);

    const { data } = await context.apolloClient.query({
      query: gql/* GraphQL */ `
        query getEvent($eventTitle: String!) {
          event(eventTitle: $eventTitle) {
            id
            title
            amIAttending
          }
        }
      `,
      variables: { eventTitle: 'SXSW 2025' },
    });

    return {
      event: data.event,
    };
  },
});

function RouteComponent() {
  const { event } = Route.useLoaderData();

  const breakpoint = useBreakpoint();
  const isMobile = ['base', 'sm'].includes(breakpoint);

  return (
    <div
      className="h-full"
      style={{
        backgroundImage: `url(${FILE_URL})`,
        backgroundSize: 'inherit',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
      }}
    >
      <div className="flex items-center justify-center p-[24px] py-[36px] pb-[128px] h-full">
        <div className="flex flex-col gap-[24px] justify-center max-w-[700px]">
          <PerfImageFromFile
            fileKey="sxsw-2025-final.png"
            alt="SXSW 2025"
            quality={80}
            width={3027}
            height={3868}
            className="self-center border-[1px] border-dashed border-main"
            style={{
              width: isMobile ? '100%' : '700px',
              height: 'auto',
            }}
          />
          {/* <div className="flex items-center justify-center" style={{ height: '150px' }}>
          <p
            className="typography-heading-md text-center"
            style={{ transform: 'scale(1,3)', lineHeight: '100%' }}
          >
            OUR LINE-UP WAS TOO LIT SO SXSW MADE US TAKE THE FLYER OFF OUR IG PAGE.
          </p>
        </div> */}
          {!event.amIAttending ? (
            <InlineSubscribeToSignUp
              ctaText="Are you coming?"
              confirmationText="You're going."
              submitText="Yes"
              eventTitle="SXSW 2025"
              redirectTo={null}
              enableRedirectToEmailSignIn={true}
              updateSignUpStep={false}
            />
          ) : (
            <div className="flex items-center justify-center p-[24px] border-[1px] border-dashed border-main bg-brand-background">
              <p className="typography-heading-md text-center">You're going.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
