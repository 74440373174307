import { Button, Text, Stack, HStack, Select, useToast } from '@chakra-ui/react';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton } from '../ui/modal';
import React from 'react';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signInViaEmailCodeSchema, signUpEmailOnlyStep2Schema } from 'shared/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { Link } from '@chakra-ui/next-js';
import discoveryList from 'shared/misc/discovery';
import { useMutation } from '@apollo/client';
import { SIGN_UP_EMAIL_ONLY_STEP_2 } from 'shared/misc/graphql/SignUpFragments';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { SEND_SIGN_IN_EMAIL, SIGN_IN_VIA_CODE } from 'shared/misc/graphql/SignInViewFragments';
import { usePathname, useSearchParams } from 'shared/misc/hooks/routerHooks';
import { useRouter } from '@tanstack/react-router';
import { UserState } from 'shared/__generated__/graphql';

type Inputs = {
  username: string;
  password: string;
  confirmPassword: string;
  discoverySource: string;
};

export default function EmailSignInModal() {
  const { isOpen, onOpen, onClose } = useModalState(ModalNames.AUTO_SUGGEST_EMAIL_SIGN_IN);
  const finishSignUpModalState = useModalState(ModalNames.FINISH_SIGN_UP);

  const auth = useAuth();

  const toast = useToast();

  const searchParams = useSearchParams();
  const email = searchParams.get('to');

  const pathname = usePathname();

  const [sendSignInEmail, { data: result, loading: sendSignInEmailLoading }] =
    useMutationWithAutoToast(SEND_SIGN_IN_EMAIL, {
      successMessage: 'Email sent.',
    });

  const [verifyEmailAndSignInViaCode, verifyEmailAndSignInViaCodeLoading] =
    useMutation(SIGN_IN_VIA_CODE);

  const signInViaEmailCodeForm = useForm<{ code: string }>({
    resolver: yupResolver(signInViaEmailCodeSchema),
  });

  const code = signInViaEmailCodeForm.watch('code');

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  const router = useRouter();

  const onCloseAndFixUrl = () => {
    router.navigate({
      to: pathname,
    });
    onClose();
  };

  if (!email || auth.user) {
    return null;
  }

  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onCloseAndFixUrl())}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Stack overflowY="scroll" pb="24px" pt="24px">
          <Stack spacing="18px">
            {!result ? (
              <>
                <Text textStyle="brand.headingLg" color="brand.highlight">
                  Want to bookmark this?
                </Text>
                <Stack spacing="8px">
                  <Text textStyle="brand.actionSm" lineHeight="140%" fontSize={18}>
                    you can easily sign-in by sending a "magic" link to{' '}
                    <Text
                      textStyle="brand.actionSm"
                      lineHeight="140%"
                      color="brand.highlight"
                      as="span"
                      fontSize={18}
                    >
                      {email}
                    </Text>
                  </Text>
                </Stack>
              </>
            ) : (
              <>
                <Text textStyle="brand.headingLg" color="brand.highlight">
                  Done!
                </Text>
                <Stack spacing="12px">
                  <Text textStyle="brand.actionSm" lineHeight="140%" fontSize={18}>
                    Check your inbox for a magic link and/or a code you can enter here.
                  </Text>
                  <PerfInput
                    key="code"
                    placeholder="Code"
                    {...signInViaEmailCodeForm.register('code')}
                    error={signInViaEmailCodeForm.formState.errors.code}
                  />
                </Stack>
              </>
            )}
            <HStack spacing="3px" alignSelf="flex-end">
              <Button variant="linkBasic" onClick={onCloseAndFixUrl} opacity={0.3}>
                Skip
              </Button>
              {!result ? (
                <Button
                  isDisabled={sendSignInEmailLoading}
                  isLoading={sendSignInEmailLoading}
                  variant="primary"
                  onClick={() =>
                    sendSignInEmail({
                      variables: { email, destination: pathname },
                      fetchPolicy: 'network-only',
                      optimisticResponse: {
                        sendSignInLinkViaEmail: true,
                      },
                    })
                  }
                >
                  Send magic link
                </Button>
              ) : (
                <Button
                  isDisabled={verifyEmailAndSignInViaCodeLoading.loading}
                  isLoading={verifyEmailAndSignInViaCodeLoading.loading}
                  variant="primary"
                  onClick={async () => {
                    await verifyEmailAndSignInViaCode({
                      variables: { code },
                      onCompleted: async (data) => {
                        const me = await auth.signIn(data.verifyEmailAndSignInViaCode);
                        toast({
                          title: 'Signed in!',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                          position: 'top',
                        });
                        onCloseAndFixUrl();
                        if (me.state !== UserState.Complete) {
                          finishSignUpModalState.onOpen();
                        }
                      },
                      onError: (e) => {
                        toast({
                          title: 'An error occured...',
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                          position: 'top',
                        });
                      },
                    });
                  }}
                >
                  Sign in
                </Button>
              )}
            </HStack>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
