import {
  Box,
  Button,
  FocusLock,
  FormControl,
  FormErrorMessage,
  Text,
  Stack,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../ui/tooltip';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { Controller, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState, LexicalEditor, LineBreakNode } from 'lexical';
import EmojiPicker, { Categories, EmojiStyle } from 'emoji-picker-react';
import React, { ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { promptSchema } from 'shared/validation/schemas';
import BasicEditor from 'web2/app/components/by-type/rich-text/basic/basic-editor';
import { recBodyTheme, recTitleTheme } from 'shared/misc/theme/selectors/lexicalStyles';
import { generateLexicalContentFromString } from 'shared/lexical';
import { isInWebView } from 'shared/webview';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';

export type PromptFormInputs = {
  emoji: string;
  // As Json
  titleLexicalState: EditorState | undefined | null;
  contentLexicalState: EditorState | undefined | null;
};

export type PromptFormArgs = {
  editorHeight?: string;
  defaultValues?: {
    title: string;
    emoji: string;
    // As Json
    contentLexicalState: EditorState | undefined | null;
  };
  onChange?: ((values: PromptFormInputs, event?: React.SyntheticEvent) => void) | Function;
  onSubmit?: ((values: PromptFormInputs, event?: React.SyntheticEvent) => void) | Function;
  isSubmitting?: boolean;
  submitText?: string;
  ref?: React.MutableRefObject<PromptFormHandle | null>;
  defaultIsOpenEmojiToolTip?: boolean;
};

type FormType = UseFormReturn<PromptFormInputs, any, undefined>;

export interface PromptFormHandle extends FormType {}

type ReturnValue = {
  submitButtonNode: ReactNode;
  mainFormNode: ReactNode;
  ref?: React.MutableRefObject<PromptFormHandle | null>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

function usePromptForm({
  onChange: _onChange,
  onSubmit,
  defaultValues,
  isSubmitting = false,
  submitText = 'Submit',
  editorHeight = '200px',
  ref: formRef,
  defaultIsOpenEmojiToolTip = false,
}: PromptFormArgs): ReturnValue {
  const popoverState = useDisclosure();

  const initialTitle = defaultValues?.title || '';

  const IS_IN_WEB_VIEW = isInWebView();

  const form = useForm<PromptFormInputs>({
    // @ts-ignore
    resolver: yupResolver(promptSchema),
    defaultValues: {
      emoji: defaultValues?.emoji || '😃',
      // @ts-ignore
      titleLexicalState: initialTitle ? generateLexicalContentFromString(initialTitle) : null,
      contentLexicalState: defaultValues?.contentLexicalState || null,
    },
  });

  const { control, handleSubmit, watch, reset, formState } = form;
  const { errors } = formState;
  const allValues = watch();

  const onFormSubmit: SubmitHandler<PromptFormInputs> = async (values, event) => {
    event?.preventDefault();
    onSubmit?.(values);
  };

  useEffect(() => {
    const subscription = watch((values: PromptFormInputs) => {
      _onChange?.(values);
    });
    return () => subscription.unsubscribe();
  }, [watch, _onChange]);

  useImperativeHandle(formRef, () => ({ ...form }), [reset]);

  const [titleEditor, setTitleEditor] = useState<LexicalEditor | null>();

  useEffect(() => {
    return titleEditor?.registerNodeTransform(LineBreakNode, (node) => {
      node.remove();
    });
  }, [titleEditor]);

  const themeColors = useThemeColors();

  const mainColor = themeColors.main;

  const emojiNode = (
    <Popover open={popoverState.isOpen} onOpenChange={popoverState.onToggle}>
      <PopoverTrigger>
        <Button
          padding="0px"
          variant="icon"
          {...(popoverState.isOpen
            ? {
                // border: '1px',
                // borderColor: 'brand.highlight',
                // m: '-1px',
                _hover: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }
            : {})}
          onClick={(e) => {
            e.preventDefault();
            popoverState.onToggle();
          }}
        >
          <Tooltip>
            <TooltipContent className="typography-action-sm">
              Click here to select an emoji.
            </TooltipContent>
            <TooltipTrigger>
              <Text textStyle="brand.headingLg" fontSize="36px">
                {allValues.emoji}
              </Text>
            </TooltipTrigger>
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.stopPropagation();
          }
        }}
      >
        <FocusLock persistentFocus={false}>
          <div className="bg-brand-background p-[18px] border-brand-main border-[1px] border-dashed w-[270px]">
            <div className="typography-heading-sm text-brand-main font-bold text-[12px] mb-[5px]">
              SELECT AN EMOJI
            </div>
            <div className="p-0 m-0 border-dashed border-brand-background border-[1px]">
              <Controller
                control={control}
                name="emoji"
                render={({ field: { ref, onChange } }) => (
                  <Box ref={ref}>
                    <EmojiPicker
                      onEmojiClick={({ emoji }) => {
                        onChange(emoji);
                        popoverState.onClose();
                      }}
                      width="100%"
                      height="100%"
                      skinTonesDisabled={true}
                      autoFocusSearch={false}
                      lazyLoadEmojis
                      emojiStyle={EmojiStyle.NATIVE}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={EMOJI_CATEGORIES}
                    />
                  </Box>
                )}
              />
            </div>
          </div>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );

  const mainFormNode = (
    <Box as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Stack display="flex" spacing="24px" m="12px 0 24px" flexFlow="column">
        <HStack spacing="12px">{emojiNode}</HStack>
        <FormErrorMessage>{errors.emoji?.message}</FormErrorMessage>
        <Box width="100%" fontWeight="normal" marginBottom={IS_IN_WEB_VIEW ? '12px' : undefined}>
          <FormControl isInvalid={!!errors.titleLexicalState}>
            <Controller
              control={control}
              name="titleLexicalState"
              render={({ field: { onChange, ref } }) => (
                <BasicEditor
                  isPlainText
                  onChange={(editorState, editor) => {
                    onChange(editorState);
                    setTitleEditor(editor);
                  }}
                  ref={ref}
                  shouldAutoFocus={false}
                  initialEditorState={allValues.titleLexicalState}
                  borderRadius="none"
                  placeholder="What are you looking for?"
                  textStyle="brand.headingSm"
                  border="1px solid transparent"
                  borderColor="transparent"
                  minHeight={{ base: '20px', md: '36px' }}
                  maxHeight="150px"
                  padding="0px"
                  paddingLeft="0px"
                  fontSize={{ base: '20px', md: '36px' }}
                  textTransform="uppercase"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'block !important',
                      width: '10px !important',
                    },
                  }}
                  sx={{
                    '.content-editable': {
                      caretColor: `${mainColor} !important`,
                    },
                  }}
                  contentEditableProps={{
                    padding: '0px',
                    paddingLeft: '0px',
                    fontSize: '36px',
                  }}
                  _focus={{
                    paddingBottom: '-1px',
                    boxShadow: 'none',
                  }}
                  editorTheme={recTitleTheme}
                  placeholderProps={{
                    // color: 'lightgrey !important',
                    opacity: '50%',
                    textTransform: 'uppercase',
                    // textStyle: 'brand.bodyLg',
                    // fontSize: '20px',
                  }}
                />
              )}
            />
            {errors.titleLexicalState && (
              <FormErrorMessage>{errors.titleLexicalState?.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
      </Stack>
      <Stack display="flex" spacing="24px" maxHeight="80%" overflowY="scroll">
        <Box width="100%" fontWeight="normal" flexGrow={1}>
          <FormControl
            isInvalid={!!errors.contentLexicalState}
            // h="100%"
            // minHeight={editorHeight}
            height={{ base: '650px', md: editorHeight }}
            m="0px"
          >
            <Controller
              control={control}
              name="contentLexicalState"
              render={({ field: { onChange, ref } }) => (
                <BasicEditor
                  placeholder={
                    <Box>
                      Consider adding helpful context.
                      <br />
                      <br />
                      For example, if you're looking for a restaurant, consider mentioning a few
                      others you like.
                      <br />
                      <br />
                      Tip: tap on the emoji above to change it.
                    </Box>
                  }
                  onChange={onChange}
                  ref={ref}
                  includeMentions
                  shouldAutoFocus={false}
                  initialEditorState={allValues.contentLexicalState}
                  borderRadius="none"
                  textStyle="brand.bodyLg"
                  border="1px solid transparent"
                  borderColor="transparent"
                  fontSize="20px"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'block !important',
                      width: '10px !important',
                    },
                  }}
                  sx={{
                    '.editor-placeholder': {
                      // p: '8px',
                      // pt: '10px',
                      // pl: '12px',
                    },
                    '.content-editable': {
                      minHeight: '100px',
                      caretColor: `${mainColor} !important`,
                    },
                  }}
                  contentEditableProps={{
                    p: '0px',
                    pb: '10px',
                    paddingLeft: '0px',
                    ...(!IS_IN_WEB_VIEW ? { maxHeight: '248px' } : {}),
                  }}
                  _focus={{
                    paddingBottom: '-1px',
                    // borderColor: 'brand.highlight',
                    // border: '1px solid transparent',
                    // borderBottom: '1px solid',
                    // borderBottomColor: 'brand.highlight',
                    boxShadow: 'none',
                  }}
                  editorTheme={recBodyTheme}
                  placeholderProps={{
                    // color: 'lightgrey !important',
                    opacity: '50%',
                    // paddingLeft: '0px',
                    // top: '4px',
                    // left: '0px',
                    // textStyle: 'brand.bodyLg',
                    // fontSize: '20px',
                  }}
                />
              )}
            />
            {errors.contentLexicalState && (
              <FormErrorMessage position="absolute" bottom="0px">
                {errors.contentLexicalState?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );

  const submitButtonNode = (
    <Button
      onClick={handleSubmit(onFormSubmit)}
      variant="primary"
      textStyle="brand.actionSm"
      fontSize="13.5px"
      isLoading={isSubmitting}
    >
      {submitText}
    </Button>
  );

  return {
    submitButtonNode,
    mainFormNode,
    ref: formRef,
    onSubmit: handleSubmit(onFormSubmit),
  };
}

const EMOJI_CATEGORIES = [
  {
    category: Categories.SUGGESTED,
    name: 'Promptently Used',
  },
  {
    category: Categories.OBJECTS,
    name: 'Objects...',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Activities...',
  },
  {
    category: Categories.SYMBOLS,
    name: 'Symbols...',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Food & Drink...',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Travel...',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Faces...',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Nature & Animals...',
  },
  {
    category: Categories.FLAGS,
    name: 'Flags...',
  },
];

export default usePromptForm;
