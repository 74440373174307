import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { isInWebView } from '../../../webview';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    // bg: 'rgba(0, 0, 0, 0.30)', //change the background
    // backdropFilter: 'blur(2px) hue-rotate(180deg)',
    zIndex: 10000000,
  },
  dialog: {
    ...(!isInWebView()
      ? { borderRadius: '0px', border: '1px dashed', borderColor: 'brand.main' }
      : {}),
    bg: `brand.background`,
    boxShadow: 'none',
    zIndex: 10000001,
  },
  header: {
    color: 'brand.main',
    textStyle: 'brand.headingXL',
    fontSize: '36px',
    borderBottom: '1px dashed',
    borderBottomColor: 'brand.main',
    mb: '10px',
  },
  closeButton: {
    color: 'brand.main',
    _focus: {
      boxShadow: 'none',
    },
  },
  footer: {
    backgroundColor: 'brand.background',
  },
});

const fullWidthImageStyle = definePartsStyle({
  dialog: {
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  closeButton: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '0px',
  },
});

const genericFlex = definePartsStyle({
  dialog: {
    bg: 'white',
    boxShadow: 'none',
    border: 'none',
    minWidth: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const variants = {
  base: baseStyle,
  fullWidthImage: fullWidthImageStyle,
  genericFlex: genericFlex,
};

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    closeOnEsc: false,
    closeOnOverlayClick: false,
    isLazy: true,
    lazyBehavior: 'unmount',
    unmountOnExit: true,
    motionPreset: 'none',
    variant: 'base',
  },
});
