'use client';

import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import * as _ from 'lodash';
import { gql } from 'shared/__generated__/gql';
import { useQuery } from '@apollo/client';
import { OrderBy } from 'shared/__generated__/graphql';
import PerfSuspense from 'shared/misc/components/util/PerfSuspense';
import RecommendationItem from '../../components/by-type/recommendations/recommendation-item';
import { InView } from 'react-intersection-observer';
import Heading from 'shared/misc/components/heading';
import { createFileRoute } from '@tanstack/react-router';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

export const Route = createFileRoute('/recs/')({
  component: RecsFromFollowing,
});

const RecsFromFollowingQuery = gql(/* GraphQL */ `
  query getRecsFromFollowingView(
    $first: Int
    $after: String
    $orderBy: OrderBy
    $search: RecSearchInput
  ) {
    recConnection(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      fromFollowing: true
    ) @connection(key: "followingRecsView") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 30;

function RecsFromFollowing() {
  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    loading,
  } = useQuery(RecsFromFollowingQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: OrderBy.NewestFirst,
      first: PAGE_LENGTH,
    },
  });

  const getNextPage = async () => {
    if (loading || !data?.recConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        first: PAGE_LENGTH,
        after: data?.recConnection.pageInfo.endCursor,
      },
    });
  };

  const recommendations = data?.recConnection.edges;

  const breakpoint = useBreakpoint();

  if (loading && !data) {
    return <PerfSuspense />;
  }

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack spacing="24px" width="100%" maxWidth="1200px">
        <Stack spacing="24px" justifyContent="center">
          <Heading
            as="h1"
            textAlign="center"
            variant="XL"
            fontSize={{ base: '45px', md: '104px' }}
            textTransform="uppercase"
            mb="24px"
            mt="48px"
          >
            ALL FOLLOWING
          </Heading>
          {data && recommendations?.length === 0 && (
            <Text textStyle="brand.headingSm" textAlign="center">
              No recommendations found.
            </Text>
          )}
          <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} width="100%" gap="24px">
            {recommendations?.map(({ node }) => (
              <RecommendationItem
                key={node.id}
                rec={node}
                noOfLines={10}
                withLessPadding={!['sm', 'base'].includes(breakpoint)}
              />
            ))}
          </SimpleGrid>

          <InView
            as="div"
            rootMargin="4000px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
          {/* <Box width="100%" justifyContent="center">
            {loading && <Spinner />}
          </Box> */}
        </Stack>
      </Stack>
    </Box>
  );
}
