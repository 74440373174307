import { $getRoot, EditorState } from 'lexical';
import * as yup from 'yup';
import { ProfileThemeType } from '../__generated__/graphql';

export const URL_REGEX =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const EMAIL_REGEX =
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/;

const validateLexicalState =
  (isRequired: boolean = true, maxLength: number = 20000) =>
  (value: EditorState, ctx) => {
    console.log({ value });

    if (!value && isRequired) {
      return ctx.createError({ message: 'Required.' });
    }

    // if (!value?._nodeMap) {
    //   return ctx.createError({ message: 'Something went very wrong.' });
    // }

    const length = value?.read?.(() => {
      return $getRoot().getTextContent().trim().length;
    });

    if (isRequired && !length) {
      return ctx.createError({ message: 'Required.' });
    }

    if (length > maxLength) {
      return ctx.createError({ message: `Max ${maxLength} characters.` });
    }

    if (!isRequired) {
      return true;
    }

    const isEmpty = value?.read?.(() => {
      const root = $getRoot();
      return !root || (root?.getFirstChild()?.isEmpty() && root.getChildrenSize() === 1);
    });

    if (isEmpty) {
      return ctx.createError({ message: 'Required.' });
    }

    return true;
  };

export const signUpBasicInfoSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
    username: yup
      .string()
      .required('Required.')
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/u, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    // password: yup.string().required('Required.').min(8, 'Minimum 8 characters.').trim(),
    // confirmPassword: yup
    //   .string()
    //   .required('Required.')
    //   .min(8, 'Minimum 8 characters.')
    //   .trim()
    //   .test('passwords-match', 'Passwords must match', function (value) {
    //     return this.parent.password === value;
    //   }),
    discoverySource: yup.string(),
  })
  .required();

  export const signUpEmailOnlySchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim()
  })
  .required();

export const editUserSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
    username: yup
      .string()
      .required('Required.')
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/u, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    firstName: yup.string().trim().max(20, 'Max 20 characters'),
    lastName: yup.string().trim().max(20, 'Max 20 characters'),
  })
  .required();

export const signUpEmailOnlyStep2Schema = yup
  .object({
    username: yup
      .string()
      .required('Required.')
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/u, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    password: yup.string().required('Required.').min(8, 'Minimum 8 characters.').trim(),
    confirmPassword: yup
      .string()
      .required('Required.')
      .min(8, 'Minimum 8 characters.')
      .trim()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
    discoverySource: yup.string(),
  })
  .required();

export const activateAccountSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
    password: yup.string().required('Required.').min(5, 'Minimum 5 characters.').trim(),
    confirmPassword: yup
      .string()
      .required('Required.')
      .min(5, 'Minimum 5 characters.')
      .trim()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
  })
  .required();

export const signUpAddPasswordSchema = yup
  .object({
    password: yup.string().required('Required.').min(8, 'Minimum 8 characters.').trim(),
  })
  .required();

export const signInSchema = yup
  .object({
    username: yup.string().required('Required.').trim(),
    password: yup.string().required('Required.').trim(),
  })
  .required();

export const signInViaEmailCodeSchema = yup
  .object({
    code: yup
      .string()
      .min(6, 'Must be 6 characters.')
      .max(6, 'Must be 6 characters.')
      .required('Required.')
      .trim(),
  })
  .required();

export const sendEmailCodeSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
  })
  .required();

export const verifyEmailViaCodeSchema = yup
  .object({
    code: yup
      .string()
      .required('Required.')
      .trim()
      .test('code_length', 'Code must 6 digits.', (value) => value.length === 6),
  })
  .required();

export const waitlistSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
  })
  .required();

export const inviteCodeSchema = yup
  .object({
    code: yup.string().required('Enter a code.').trim(),
  })
  .required();

export const subscribeSchema = yup
  .object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, { message: 'Invalid email.' })
      .required('Required.')
      .trim(),
  })
  .required();

export const signUpMoreInfoSchema = yup
  .object({
    firstName: yup.string().trim().max(20, 'Max 20 characters'),
    lastName: yup.string().trim().max(20, 'Max 20 characters'),
    username: yup
      .string()
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^[a-zA-Z0-9_-]+$/, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    location: yup.string().max(50, 'Max 50 characters.').trim().nullable(),
    instagram: yup.string().max(300, 'Max 30 characters.').trim().nullable(),
    twitter: yup.string().max(300, 'Max 30 characters.').trim().nullable(),
    website: yup
      .string()
      .matches(URL_REGEX, { message: 'Enter a valid url or remove.', excludeEmptyString: true })
      .max(300, 'Max 30 characters.')
      .trim()
      .nullable(),
    // bio: yup.string().max(1500, 'Max 1500 characters.').trim().nullable(),
  })
  .required();

export const signUpNameUsernameBirthdaySchema = yup
  .object({
    name: yup.string().trim().test('name-parts-length', 'Must be under 40 characters', (value) => {
      if (!value) return true;
      const parts = value.split(' ');
      return parts[0].length <= 20 && (parts.slice(1).join(' ').length <= 20 || parts.length === 1);
    }),
    username: yup
      .string()
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^[a-zA-Z0-9_-]+$/, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    birthday: yup.string().trim(),
  })
  .required();

export const signUpLocationSchema = yup
  .object({
    location: yup.string().trim()
      .matches(/^[a-zA-Z0-9_\-\s,\.]+$/, {
        message: 'No special characters except spaces, underscores, hyphens, commas, and periods.',
        excludeEmptyString: true,
      })
      .trim(),
  })
  .required();

export const signUpThemeSchema = yup
  .object({
    profileTheme: yup.string().oneOf(Object.values(ProfileThemeType)).required(),
  })
  .required();

export const settingsProfileInfoSchema = yup
  .object({
    username: yup
      .string()
      .required('Required.')
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/u, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    location: yup.string().max(50, 'Max 50 characters.').trim(),
    instagram: yup.string().max(100, 'Max 100 characters.').trim(),
    twitter: yup.string().max(100, 'Max 100 characters.').trim(),
    website: yup.string().max(100, 'Max 100 characters.').trim(),
    profileTheme: yup.string().required('Required.').trim(),
    bioLexicalState: yup
      .mixed()
      .nullable()
      .required('Required.')
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(false),
      }),
  })
  .required();

export const updateRecDraftSchema = yup
  .object({
    title: yup.string().required('Title required.').max(200, 'Max 200 characters.').trim(),
    url: yup.string().matches(URL_REGEX, 'Enter a valid url.').trim().nullable(),
    contentLexicalState: yup
      .mixed()
      .nullable()
      // .required('Required.')
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(false),
      }),
    emoji: yup.string().required('Emoji required.').trim(),
  })
  .required();

export const createCommentSchema = yup
  .object({
    contentLexicalState: yup
      .mixed()
      .nullable()
      // .required('Required.')
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(false),
      }),
  })
  .required();

export const updateRecSchema = (requireTitle = true, requireContent = false) =>
  yup
    .object({
      url: yup
        .string()
        .nullable()
        .matches(URL_REGEX, { excludeEmptyString: true, message: 'Enter a valid url.' })
        .trim()
        .nullable(),
      titleLexicalState: yup
        .mixed()
        .nullable()
        .test({
          name: 'is_editor_state',
          test: validateLexicalState(requireTitle, 150),
        }),
      contentLexicalState: yup
        .mixed()
        .nullable()
        // .required('Required.')
        .test({
          name: 'is_editor_state',
          test: validateLexicalState(requireContent),
        }),
      emoji: yup.string().required('Required.').trim(),
    })
    .required();

export const promptSchema = yup
  .object({
    titleLexicalState: yup
      .mixed()
      .nullable()
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(true, 150),
      }),
    contentLexicalState: yup
      .mixed()
      .nullable()
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(false),
      }),
    emoji: yup.string().required('Required.').trim(),
  })
  .required();

export const createListSchema = yup
  .object({
    title: yup.string().required('Title required.').max(200, 'Max 200 characters.').trim(),
    color: yup.string().trim().nullable(),
    introLexicalState: yup
      .mixed()
      .nullable()
      .test({
        name: 'is_editor_state',
        test: validateLexicalState(false),
      }),
  })
  .required();

export const spotlightApplicationSchema = yup
  .object({
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    username: yup
      .string()
      .required('Required.')
      .min(2, 'Minimum 2 characters.')
      .max(25, 'Max 25 characters')
      .matches(/^(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/u, {
        message: 'No special characters or spaces.',
        excludeEmptyString: true,
      })
      .trim(),
    about: yup.string().max(1000, 'Max 1000 characters.').trim(),
  })
  .required();

export const verifyEmailSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  code: yup.string().required('Verification code is required').length(6, 'Code must be 6 digits'),
});

export const linkInputSchema = yup
  .object({
    url: yup
      .string()
      .required('Required.')
      .matches(URL_REGEX, {
        message: 'Not a valid url.',
        excludeEmptyString: true,
      })
      .trim(),
    title: yup.string().required('Required.').max(20, 'Max 20 characters').trim(),
  })
  .required();
