import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../utils/cn';
import { createLink, LinkComponent } from '@tanstack/react-router';
import { forwardRef } from 'react';

const linkVariants = cva(
  [
    // Base styles
    // 'typography-action-sm',
    'text-brand-main',
    'text-uppercase',
    'hover:text-brand-highlight',
  ],
  {
    variants: {
      variant: {
        default: [
          'border-0 border-solid border-transparent',
          'hover:text-brand-highlight hover:border-transparent',
        ],
        stylized: [
          'text-brand-main',
          'border-b border-dashed border-brand-main',
          'hover:text-brand-highlight hover:border-brand-highlight hover:opacity-100',
          'typography-action-sm',
        ],
        stylizedBasic: [
          'text-brand-main',
          'border-b border-dashed border-brand-main',
          'hover:text-brand-highlight hover:border-brand-highlight',
        ],
        fancy: [
          'text-brand-yellow',
          'bg-brand-blue',
          'px-0.5',
          'text-brand-actionSm',
          'hover:text-brand-blue hover:bg-brand-yellow',
        ],
        stylizedNoDefaultBorder: [
          'text-brand-main',
          // 'text-brand-actionSm',
          'hover:text-brand-highlight hover:border-brand-highlight',
        ],
        basic: [
          'text-brand-main',
          'border border-solid border-transparent',
          'hover:border-b hover:border-dashed hover:border-b-brand-main',
        ],
        basicWithBlue: [
          'border border-solid border-transparent',
          'hover:border-b hover:border-dashed hover:border-b-brand-main',
        ],
        basicLight: [
          'text-brand-secondary',
          'border border-solid border-transparent',
          'hover:border-b hover:border-dashed hover:border-b-brand-secondary',
        ],
        btn: ['no-underline'],
        list: ['hover:border-b-0'],
      },
      size: {
        sm: [],
        lg: [],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type LinkVariants = VariantProps<typeof linkVariants>;

interface LinkProps
  extends VariantProps<typeof linkVariants>,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children?: React.ReactNode;
  onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const BaseTWLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant, size, children, ...props }, ref) => {
    return (
      <a
        className={cn(linkVariants({ variant, size, className }))}
        ref={ref}
        onMouseDown={(e) => {
          if (props.onMouseDown) {
            return props.onMouseDown(e);
          }
          // Skip for right clicks (button 2) or if modifier keys are pressed or on touch devices
          if (e?.button === 2 || e.metaKey || e.ctrlKey || 'ontouchstart' in window) {
            return;
          }
          e.preventDefault();
          e.currentTarget.click();
        }}
        {...props}
      >
        {children}
      </a>
    );
  },
);

const CreatedLinkComponent = createLink(BaseTWLink);

export const Link: LinkComponent<typeof BaseTWLink> = (props) => {
  return <CreatedLinkComponent preload={'intent'} {...props} children={props.children} />;
};

export const TWLink = Link;
export { linkVariants };
