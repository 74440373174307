import {
  sub,
  differenceInMinutes,
  differenceInHours,
  isBefore,
  differenceInDays,
  format,
} from 'date-fns';

const JUST_NOW_THRESHOLD = 10;

export function getRelativeDateString(date: Date, justNowThresholdMinutes = JUST_NOW_THRESHOLD) {
  if (!date) {
    return '';
  }

  // const formatRelative(new Date(rec.date), new Date())

  const oneHourAgo = sub(new Date(), {
    hours: 1,
  });

  const oneDayAgo = sub(new Date(), {
    hours: 24,
  });

  const oneWeekAgo = sub(new Date(), {
    weeks: 1,
  });

  // Within last hour.
  if (isBefore(oneHourAgo, date)) {
    const minutes = differenceInMinutes(new Date(), date);

    if (minutes < justNowThresholdMinutes) {
      return 'Just now';
    }

    return `${minutes}m ago`;
  }

  // Within last day.
  if (isBefore(oneDayAgo, date)) {
    const hours = differenceInHours(new Date(), date);
    return `${hours}h ago`;
  }

  // Within one week.
  if (isBefore(oneWeekAgo, date)) {
    const days = differenceInDays(new Date(), date);
    return `${days}d ago`;
  }

  return format(date, 'MMM d, yyyy');
}
