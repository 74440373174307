import { useToast } from '@chakra-ui/react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import React, { useState, useEffect, useRef } from 'react';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { signUpNameUsernameBirthdaySchema } from 'shared/validation/schemas';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { SIGN_UP_USER_META } from 'shared/misc/graphql/SignUpFragments';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType, UserState } from 'shared/__generated__/graphql';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import DatePicker from 'react-datepicker';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import datepickerCss from 'react-datepicker/dist/react-datepicker.css?inline';
import localCss from './style.css?inline';
import { createFileRoute } from '@tanstack/react-router';

function CssInjector() {
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  if (!isMounted) {
    return null;
  }
  
  return (
    <style
      key="datepicker-styles"
      dangerouslySetInnerHTML={{
        __html: `${datepickerCss}${localCss}`,
      }}
    />
  );
}

type Inputs = {
  name?: string;
  username?: string;
  birthday?: string;
};

export const Route = createFileRoute('/sign-up/more/')({
  component: SignUpMoreInfoPage,
});

function SignUpMoreInfoPage() {
  const [currentStep, setCurrentStep] = useState<'name' | 'username' | 'birthday'>('name');
  const { user } = useAuth<true>();
  const [signUpUserMetaMutation] = useMutation(SIGN_UP_USER_META);
  const toast = useToast();
  const { setNavigation } = useSignUpNavigation();
  const { trackEvent } = useAnalytics();
  const [displayText] = useTypewriter(
    currentStep === 'name'
      ? "What's your name?"
      : currentStep === 'username'
      ? 'Choose your username.'
      : 'When is your birthday?',
    50,
  );

  const usernameInputRef = useRef<HTMLInputElement | null>(null);

  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.PHOTO] });

  useEffect(() => {
    const signupStep = [USER_SIGN_UP_STEP.MORE_USERNAME, USER_SIGN_UP_STEP.MORE_BIRTHDAY].includes(
      user?.signUpStep as USER_SIGN_UP_STEP,
    )
      ? user?.signUpStep
      : USER_SIGN_UP_STEP.MORE;
    updateStep(signupStep);
  }, []);

  useEffect(() => {
    if (currentStep === 'username' && usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, [currentStep]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpNameUsernameBirthdaySchema),
    defaultValues: {
      name: user?.firstName || '',
      username: user?.username || '',
      birthday: '',
    },
  });

  const values = watch();

  const handleBack = async () => {
    if (currentStep === 'username') {
      await updateStep(USER_SIGN_UP_STEP.MORE);
      setCurrentStep('name');
    } else if (currentStep === 'birthday') {
      await updateStep(USER_SIGN_UP_STEP.MORE_USERNAME);
      setCurrentStep('username');
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (values, event) => {
    event?.preventDefault();
    if (currentStep === 'name') {
      const { data } = await signUpUserMetaMutation({
        variables: {
          input: {
            name: values.name,
          },
        },
      });
      if (data?.signUpUserMeta?.suggestedUsername) {
        setValue('username', data.signUpUserMeta.suggestedUsername);
      }
      await trackEvent('Click', 'Name_Entered', {
        platform: 'web',
        userId: user?.id,
      });
      await updateStep(USER_SIGN_UP_STEP.MORE_USERNAME);
      setCurrentStep('username');
      return;
    }

    if (currentStep === 'username') {
      await signUpUserMetaMutation({
        variables: {
          input: {
            username: values.username,
          },
        },
        onError: async (e) => {
          const message = e.graphQLErrors?.[0]?.message.toLowerCase().includes('unique')
            ? 'Username already taken.'
            : e.graphQLErrors?.[0]?.message;
          toast({
            title: 'Error',
            description: message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setError('username', {
            message,
          });
        },
        onCompleted: async () => {
          await updateStep(USER_SIGN_UP_STEP.MORE_BIRTHDAY);
          setCurrentStep('birthday');
        },
      });
      await trackEvent('Click', 'Username_Entered', {
        platform: 'web',
        userId: user?.id,
      });
      return;
    }

    if (currentStep === 'birthday') {
      if (!values.birthday) return;
      const nextSignUpStep: null | string = USER_SIGN_UP_STEP.PHOTO;
      await signUpUserMetaMutation({
        variables: {
          input: {
            birthday: values.birthday,
            state: UserState.Complete,
          },
        },
        onCompleted: async (res) => {
          await trackEvent('Click', 'Birthday_Entered', {
            platform: 'web',
            userId: user?.id,
          });
          await updateStep(nextSignUpStep);
        },
        onError: async (e) => {
          toast({
            title: 'Error',
            description: e.graphQLErrors?.[0]?.message || 'You must be 18 or older to continue.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setError('birthday', {
            message: e.graphQLErrors?.[0]?.message || 'You must be 18 or older to continue.',
          });
        },
      });
    }
  };

  useAutoSetTheme(ProfileThemeType.PiClassic);

  useEffect(() => {
    const submitHandler = () => {
      const currentValues = watch();
      if (currentStep === 'name' && (!currentValues.name || currentValues.name.length < 1)) return;
      if (
        currentStep === 'username' &&
        (!currentValues.username || currentValues.username.length < 1)
      )
        return;
      if (currentStep === 'birthday' && !currentValues.birthday) return;
      handleSubmit(onSubmit)();
    };

    setNavigation({
      showLeftArrow: currentStep !== 'name',
      showRightArrow: true,
      yPosition: 225,
      showSkip: false,
      onLeftPress: handleBack,
      onRightPress: submitHandler,
      leftArrowDisabled: false,
      rightArrowDisabled:
        (currentStep === 'name' && (!values.name || values.name.length < 1)) ||
        (currentStep === 'username' && (!values.username || values.username.length < 1)) ||
        (currentStep === 'birthday' && !values.birthday),
      signupStep: USER_SIGN_UP_STEP.MORE,
    });
  }, [currentStep, values.name, values.username, values.birthday, handleSubmit]);

  const subtitleText =
    currentStep === 'name'
      ? 'This helps people you know recognize you.'
      : currentStep === 'username'
      ? "We've prefilled one for you below, but you can change it to anything you'd like."
      : 'You must be 18 or older to continue.';

  return (
    <>
      <CssInjector />
      <div className="min-h-full flex flex-col">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
          <button className="hidden" type="submit">
            submit
          </button>
          <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
            {displayText}
          </div>
          <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
            {subtitleText}
          </div>
          <div className="flex flex-col gap-4 pt-[28px]">
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PerfInput
                  autoFocus={currentStep === 'name'}
                  variant="underline"
                  placeholder="Full name"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  isInvalid={!!errors.name}
                  errorBorderColor="red.300"
                  formStyle={{
                    display: currentStep === 'name' ? 'block' : 'none',
                  }}
                />
              )}
            />
            <Controller
              name="username"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PerfInput
                  ref={usernameInputRef}
                  variant="underline"
                  placeholder="Username"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  isInvalid={!!errors.username}
                  errorBorderColor="red.300"
                  formStyle={{
                    display: currentStep === 'username' ? 'block' : 'none',
                  }}
                />
              )}
            />
            <Controller
              name="birthday"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  selected={value ? new Date(value) : null}
                  onChange={(date) => onChange(date ? date.toISOString() : '')}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select your birthday"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className={currentStep === 'birthday' ? 'datepicker' : 'datepicker-hidden'}
                />
              )}
            />
          </div>
        </form>
      </div>
    </>
  );
}
