import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__/gql';
import { InView } from 'react-intersection-observer';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import UserTile from 'web2/app/components/by-type/user/user-tile';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';

export const Route = createFileRoute('/directory/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | DIRECTORY',
        description: 'Our lovely users.',
      },
    ],
  }),
});

gql(/* GraphQL */ `
  fragment DirectoryView on Query {
    directoryViewConnection: userConnection(
      first: $first
      after: $after
      search: $search
      includeGuests: true
      includeUnfinishedSignUp: false
      includePerfectlyImperfect: true
    ) @connection(key: "directoryViewSearch") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UserTile
        }
      }
    }
  }
`);

const DirectoryViewQuery = gql(/* GraphQL */ `
  query getDirectoryView($first: Int, $after: String, $search: String) {
    ...DirectoryView
  }
`);

const PAGE_LENGTH = 20;

type Inputs = {
  search: string | null;
};

function RouteComponent() {
  const { watch, register } = useForm<Inputs>({
    defaultValues: { search: null },
  });

  const values = watch();
  const { search } = values;

  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    refetch,
    loading,
  } = useQuery(DirectoryViewQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: PAGE_LENGTH,
    },
  });

  const debouncedRefetch = useMemo(() => {
    return _.debounce((nextVariables) => {
      refetch(nextVariables);
    }, 1000);
  }, [refetch]);

  useEffect(() => {
    if (variables && variables?.search !== search) {
      debouncedRefetch({
        search,
        first: PAGE_LENGTH,
        after: null,
      });
    }
  }, [variables, values]);

  const getNextPage = async () => {
    if (loading || !data?.directoryViewConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        search,
        first: PAGE_LENGTH,
        after: data?.directoryViewConnection.pageInfo.endCursor,
      },
    });
  };

  const auth = useAuth();

  useAutoSetTheme(auth.user?.profileTheme);

  const userEdges = data?.directoryViewConnection?.edges || [];

  return (
    <div className="pb-[100px]">
      <div className="flex flex-col m-auto gap-[24px] px-[25px] lg:w-[900px] mt-[24px] justify-center">
        <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
          DIRECTORY
        </h1>
        <div className="w-full max-w-[400px] self-center my-[24px]">
          <PerfInput
            placeholder={`Search (first name, username, location, etc)`}
            {...register('search')}
          />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-[12px] text-brand-body overflow-x-scroll">
          {userEdges.map(({ node: user }) => (
            <div key={user.id} className="row-span-1 col-span-1">
              <UserTile user={user} key={user.id} skipStars />
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center">
          <InView
            as="div"
            rootMargin="500px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  );
}
