import _ from 'lodash';
import { useTheme } from '@chakra-ui/react';
import { useAuth } from '../../../../hooks/useAuth';
import { GetFeedItemData, FeedItemDisplayData, getFeedItemData } from './getFeedItemData';
import { useThemeColors } from '../../../../hooks/useThemeColors';

export function useFeedItemData(args: GetFeedItemData): FeedItemDisplayData | null {
  const auth = useAuth<true>();

  const themeColors = useThemeColors();

  const originUserFallbackLogoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

  return getFeedItemData({
    ...args,
    originUserFallbackLogoColor,
    referenceUserId: auth.user?.id,
  });
}
