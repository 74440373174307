import { createFileRoute } from '@tanstack/react-router';
import { gql } from 'shared/__generated__/gql';
import useRandomRecInfiniteScroll from 'shared/misc/components/by-type/recommendations/useRandomRecInfiniteScroll';
import { InView } from 'react-intersection-observer';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { isUserPremium } from 'shared/gates';
import InlinePayWall from 'shared/misc/components/InlinePayWall';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import RecommendationItem from 'web2/app/components/by-type/recommendations/recommendation-item';

const GET_RANDOM_RECS = gql(/* GraphQL */ `
  query RandomRecs {
    randomRecs {
      ...RecommendationItem
    }
  }
`);

export const Route = createFileRoute('/random/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { items, getNextPage, loading, error } = useRandomRecInfiniteScroll();

  const themeColors = useThemeColors();
  const strokeColor = themeColors.titleBorder;

  const auth = useAuth();

  const isUserSignedInAndPremium = auth.user && isUserPremium(auth.user);

  const showPaywall = !auth.user || !isUserSignedInAndPremium;

  return (
    <div className="flex justify-center p-[12px] mb-[500px]">
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-col gap-[12px] md:gap-[24px] justify-center w-full md:w-[800px] max-w-full">
          <h1 className="uppercase typography-heading-xl text-brand-highlight text-center my-[24px]">
            Random
          </h1>
          <div className="flex flex-col gap-[12px] md:gap-[24px] mb-[24px]">
            <div className="flex flex-col gap-[24px] position-relative">
              {showPaywall ? (
                <div className="w-full">
                  <InlinePayWall />
                </div>
              ) : null}

              {items.length > 0 && !showPaywall ? (
                <>
                  <div className="flex flex-col gap-[24px]">
                    {(showPaywall ? items.slice(0, 10) : items).map(({ node }) => {
                      return <RecommendationItem key={node.id} rec={node} showPrompt />;
                    })}
                  </div>
                  <InView
                    as="div"
                    rootMargin="500px 0px"
                    onChange={(inView) => {
                      console.log('inView', inView, showPaywall);

                      if (inView && !showPaywall) {
                        getNextPage();
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  {loading && (
                    <div className="flex flex-col gap-[24px]">
                      {Array.from({ length: 10 }).map((_, index) => (
                        <SkeletonFeedItem key={index} />
                      ))}
                    </div>
                  )}
                  {!loading && false && (
                    <p className="typography-heading-sm text-center">No recommendations found.</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
