import { useToast } from '@chakra-ui/react';
import { Button } from 'web2/app/components/button';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useAuth } from 'shared/misc/hooks/useAuth';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { POPULAR_LOCATIONS, LocationSuggestion } from 'shared/data/signup';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUpLocationSchema } from 'shared/validation/schemas';
import { GrLocation, GrFormClose } from 'react-icons/gr';
import { MdMyLocation } from 'react-icons/md';
import { useMutation } from '@apollo/client';
import { SIGN_UP_USER_META } from 'shared/misc/graphql/SignUpFragments';
import { debounce } from 'lodash';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';

type Inputs = {
  location?: string;
};

const SITE_URL = BASE_SITE_URL || 'https://pi.fyi';

export const Route = createFileRoute('/sign-up/location/')({
  component: SignUpLocationPage,
});

function SignUpLocationPage() {
  const toast = useToast();
  const { trackEvent } = useAnalytics();
  const { setNavigation } = useSignUpNavigation();
  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.THEME, USER_SIGN_UP_STEP.PHOTO],
  });

  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);

  const [signUpUserMetaMutation] = useMutation(SIGN_UP_USER_META);
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpLocationSchema),
    defaultValues: {
      location: user?.location || '',
    },
  });

  const location = watch('location');

  useEffect(() => {
    setNavigation({
      rightArrowDisabled: false,
      leftArrowDisabled: false,
      showRightArrow: true,
      showLeftArrow: true,
      onLeftPress: () => updateStep(USER_SIGN_UP_STEP.PHOTO),
      rightLabel: undefined,
      leftLabel: undefined,
      onRightPress: onSubmit,
      rightArrowColor: location && location.length > 0 ? '#FFBB00' : '#FFFFFF',
      yPosition: 265,
      onSkip: onSubmit,
      showSkip: true,
      signupStep: USER_SIGN_UP_STEP.LOCATION,
    });
  }, [setNavigation, location]);

  const [displayText] = useTypewriter('Where are you located?', 50);

  const onSubmit = async () => {
    await trackEvent('Click', location && location.length > 0 ? 'Location_Added' : 'Step_Skipped', {
      userId: user?.id,
      location: location,
    });
    await updateStep(USER_SIGN_UP_STEP.THEME);
  };

  const handleLocationSelect = useCallback(
    async (location: string | undefined, isDetected: boolean = false) => {
      setValue('location', location);
      setSuggestions([]);
      await signUpUserMetaMutation({
        variables: {
          input: {
            location,
          },
        },
        onCompleted: async () => {
          if (!isDetected) {
            await onSubmit();
          }
        },
      });
    },
    [setValue, signUpUserMetaMutation, updateStep],
  );

  const handleSuggestionPress = useCallback(
    async (suggestion: LocationSuggestion) => {
      await handleLocationSelect(suggestion.description);
    },
    [handleLocationSelect],
  );

  const getCurrentLocation = useCallback(async () => {
    if (!navigator.geolocation) {
      toast({
        title: 'Geolocation not supported',
        description: 'Your browser does not support geolocation.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoadingLocation(true);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        // completelt free, incredibly api.bigdatacloud.net
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`,
        );
        const place = await response.json();

        if (place.city) {
          await handleLocationSelect(place.city, true);
          // await track('Click', {
          //   eventValue: `Location_Added`,
          //   contextProperties: {
          //     platform: 'web',
          //   },
          //   userId: user?.id,
          //   userIdContext: user?.id,
          //   withLocationPermission: true,
          // });
          await onSubmit();
        }
      },
      (error) => {
        toast({
          title: 'Error',
          description: 'Could not get your location. Please enter it manually.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 },
    );

    setIsLoadingLocation(false);
  }, [handleLocationSelect]);

  const handlePopularLocationPress = useCallback(
    async (location: (typeof POPULAR_LOCATIONS)[number]) => {
      if (location.label === 'Current Location') {
        await getCurrentLocation();
      } else if (location.description) {
        // await track('Click', {
        //   eventValue: `Location_Added`,
        //   contextProperties: {
        //     platform: 'app',
        //   },
        //   userId: user?.id,
        //   userIdContext: user?.id,
        //   withLocationPermission: false,
        // });
        await signUpUserMetaMutation({
          variables: {
            input: {
              location: location.description,
            },
          },
          onCompleted: async () => {
            await updateStep(USER_SIGN_UP_STEP.THEME);
          },
        });
      }
    },
    [getCurrentLocation, signUpUserMetaMutation, updateStep],
  );

  const fetchSuggestions = useCallback(async (text: string) => {
    if (text.length > 1) {
      setIsLoadingSuggestions(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${SITE_URL}/api/location?query=${encodeURIComponent(text)}`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch suggestions');
        const data = await response.json();
        setSuggestions([
          {
            description: text,
            placeId: 'input',
            structured_formatting: {
              main_text: text,
              main_text_matched_substrings: [{ length: text.length, offset: 0 }],
              secondary_text: '',
            },
          },
          ...data.suggestions,
        ]);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch location suggestions',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingSuggestions(false);
      }
    } else {
      setSuggestions([]);
    }
  }, []);

  const debouncedFetchSuggestions = useCallback(
    debounce((text: string) => {
      fetchSuggestions(text);
    }, 300),
    [fetchSuggestions],
  );

  const handleLocationChange = useCallback(
    (text: string) => {
      setValue('location', text);
      if (text === '') {
        setSuggestions([]);
      } else {
        setSuggestions([
          {
            description: text,
            placeId: 'input',
            structured_formatting: {
              main_text: text,
              main_text_matched_substrings: [{ length: text.length, offset: 0 }],
              secondary_text: '',
            },
          },
          ...suggestions,
        ]);
        debouncedFetchSuggestions(text);
      }
    },
    [setValue, debouncedFetchSuggestions, suggestions],
  );

  return (
    <div className="min-h-full flex flex-col">
      <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
        {displayText}
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        This will be visible on your profile.
      </div>
      <div className="mt-[68px] relative">
        <Controller
          name="location"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PerfInput
              className="m-[8px]"
              variant="underline"
              placeholder="Location"
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e);
                handleLocationChange(e.target.value);
              }}
              value={value}
              isInvalid={!!errors.location}
              errorBorderColor="red-300"
            />
          )}
        />
        {location && location.length > 0 && (
          <Button
            variant="textOnly"
            className="absolute variant-text top-[-4px] right-0 size-[64px]"
            onClick={() => {
              setSuggestions([]);
              setValue('location', '');
            }}
          >
            <GrFormClose className="text-[18px]" />
          </Button>
        )}
        {suggestions.length === 0 ? (
          <div className="mt-[96px]">
            <div className="typography-action-sm font-normal text-uppercase text-brand-main">
              Popular
            </div>
            <div className="flex flex-wrap mt-[12px]">
              {POPULAR_LOCATIONS.map((location) => {
                const isCurrentLocation = location.label.toLowerCase() === 'current location';
                return (
                  <Button
                    key={location.label}
                    variant="outline"
                    className={`px-[6px] py-[4px] mr-[8px] mb-[8px] text-${
                      isCurrentLocation ? 'brand-highlight' : 'brand-main'
                    } border-${isCurrentLocation ? 'brand-highlight' : 'brand-main'} flex normal-case`}
                    onClick={() => handlePopularLocationPress(location)}
                  >
                    {isCurrentLocation ? (
                      <MdMyLocation className="mr-[4px] text-[18px]" />
                    ) : (
                      <GrLocation className="mr-[4px] text-[18px]" />
                    )}
                    <div className="typography-heading-sm text-none normal-case text-[18px]">{location.label}</div>
                  </Button>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="mt-[16px]">
            {suggestions.map((suggestion) => {
              const matches = suggestion.structured_formatting.main_text_matched_substrings[0];
              const mainText = suggestion.structured_formatting.main_text;
              const beforeMatch = mainText.slice(0, matches.offset);
              const match = mainText.slice(matches.offset, matches.offset + matches.length);
              const afterMatch = mainText.slice(matches.offset + matches.length);
              return (
                <Button
                  variant="textOnly"
                  key={suggestion.description}
                  className="border-b border-transparent variant-text flex items-center justify-start w-fit mb-2 hover:border-brand-main hover:border-b hover:border-dashed"
                  onClick={() => handleSuggestionPress(suggestion)}
                >
                  <GrLocation className="ml-[-30px] text-[24px]" />
                  <div className="typography-action-sm text-none font-thin text-brand-main opacity-80 ml-2">
                    {beforeMatch}
                  </div>
                  <div className="typography-action-sm text-none font-thin text-brand-main">
                    {match}
                  </div>
                  <div className="typography-action-sm text-none font-thin text-brand-main opacity-80">
                    {afterMatch}
                  </div>
                  <div className="typography-action-sm text-none font-thin text-brand-main opacity-50 ml-2">
                    {' ' + suggestion.structured_formatting.secondary_text}
                  </div>
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
