import { Box, BoxProps, Link, Tooltip } from '@chakra-ui/react';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface Props extends BoxProps {}

function ExpandableTextContainer({ children, noOfLines, ...props }: Props) {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLDivElement | null | undefined>(undefined);

  useLayoutEffect(() => {
    const element = textRef.current;

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
        : false,
    );
  }, []);

  if (!noOfLines) {
    return <>{children}</>;
  }

  const interactableProps = {
    cursor: 'pointer',
    onClick: (e) => {
      if (!isExpanded) {
        e.preventDefault();
        e.stopPropagation();
        // e.stopImmediatePropagation();
        setIsExpanded(true);
      }
    },
  };

  const contentNode = (
    <Box
      noOfLines={!isExpanded ? noOfLines : undefined}
      ref={textRef}
      flexGrow={1}
      {...props}
      {...(isEllipsisActive && !isExpanded ? interactableProps : {})}
    >
      {children}
    </Box>
  );

  return isEllipsisActive && !isExpanded ? (
    <Tooltip label="Click to expand">{contentNode}</Tooltip>
  ) : (
    contentNode
  );
}

export default ExpandableTextContainer;
