import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { HoverCard, HoverCardContent, HoverCardTrigger } from 'web2/app/components/ui/hover-card';
import React, { useMemo } from 'react';
import { TWLink } from '../../../components/link';
import contrast from 'contrast';
import NextImage from 'shared/misc/components/image-wrapper';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { gql } from 'shared/__generated__/gql';
import { safeSendWebViewMessage } from 'shared/webview';
import { GlobalWebViewMessages } from 'shared/webview/messages';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

type Props = {
  isEndorsed: boolean;
  size?: number;
  endorsementCount: number;
  unendorse: () => any;
  endorse: () => any;
  targetRecId?: string;
  targetCommentId?: string;
  targetPromptId?: string;
  targetArticleId?: string;
  ownerId: string;
  withText: boolean;
  withCount?: boolean;
  classes?: string;
};

const GET_INTERACTIONS = gql(/* GraphQL */ `
  query getInteractions(
    $targetRecId: String
    $targetCommentId: String
    $targetPromptId: String
    $targetArticleId: String
  ) {
    interactionConnection(
      targetRecId: $targetRecId
      targetCommentId: $targetCommentId
      targetPromptId: $targetPromptId
      targetArticleId: $targetArticleId
      type: "ENDORSE"
    ) {
      edges {
        node {
          id
          type
          originUser {
            id
            username
            firstName
            lastName
            avatarPhotoSrc
          }
        }
      }
    }
  }
`);

export default function LikeStars({
  isEndorsed,
  endorsementCount,
  unendorse,
  endorse,
  size = 30,
  targetRecId,
  targetCommentId,
  targetPromptId,
  targetArticleId,
  ownerId,
  withText,
  withCount,
  classes = '',
}: Props) {
  const auth = useAuth();
  const popoverState = useDisclosure();

  const { trackEvent } = useAnalytics();
  const themeColors = useThemeColors();
  const backgroundColor = themeColors.background;

  const isDark = contrast(backgroundColor) === 'dark';

  const [loadInteractions, { data }] = useLazyQuery(GET_INTERACTIONS, {
    variables: { targetRecId, targetCommentId, targetPromptId, targetArticleId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
  });

  const breakpoint = useBreakpoint();
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const imageProps = useMemo(() => {
    let res = 'one-endorsed';
    const width = size;
    const height = size;
    switch (endorsementCount) {
      case 0:
        res = isEndorsed ? 'one-endorsed' : 'no-endorsements';
        break;
      case 1:
        res = `one-endorsed`;
        break;
      case 2:
        res = `two-endorsed`;
        break;
      case 3:
      default:
        res = `three-endorsed`;
        break;
    }
    return {
      className: classes,
      width,
      height,
      src: `https://files.pi.fyi/${res}${isEndorsed ? '-user-endorsed' : ''}.png`,
    };
  }, [size, isEndorsed, endorsementCount]);

  const tooltip = useMemo(() => {
    const count = isEndorsed ? endorsementCount - 1 : endorsementCount;

    if (!auth.user) {
      return 'Log in to like this.';
    }

    if (isEndorsed && count > 0) {
      return `Liked by you & ${count} other user${count > 1 ? 's' : ''}.`;
    } else if (isEndorsed && count === 0) {
      return 'Liked by you.';
    } else if (count > 0) {
      return `Liked by ${count} user${count > 1 ? 's' : ''}.`;
    } else {
      return 'Be the first to like this.';
    }
  }, [auth.user, isEndorsed, endorsementCount]);

  const router = useRouter();

  const togglePopover = () => {
    loadInteractions();
    popoverState.onToggle();
  };

  const onClick = async () => {
    if (
      (isMobile &&
        (popoverState.isOpen || (!isEndorsed && auth.user && auth.user.id !== ownerId))) ||
      !isMobile
    ) {
      if (auth.user) {
        try {
          safeSendWebViewMessage(GlobalWebViewMessages.HAPTIC_LIGHT);
        } catch (e) {}

        if (isEndorsed) {
          await unendorse();
          loadInteractions();
          trackEvent('Click', 'Unendorse', {
            targetRecId,
            targetCommentId,
            targetPromptId,
            targetArticleId,
          });
        } else {
          await endorse();
          loadInteractions();
          trackEvent('Click', 'Endorse', {
            targetRecId,
            targetCommentId,
            targetPromptId,
            targetArticleId,
          });
        }
      } else {
        const type = targetRecId
          ? 'Rec'
          : targetCommentId
          ? 'Comment'
          : targetPromptId
          ? 'Ask'
          : 'Unknown';
        trackEvent('Click', 'Redirect_To_Sign_In', {
          targetType: type,
          targetId: targetRecId || targetCommentId || targetPromptId || targetArticleId,
          clickTarget: 'Endorse',
        });
        router.navigate({
          to: '/sign-in',
        });
      }
    }
  };

  return (
    <HoverCard open={popoverState.isOpen} onOpenChange={togglePopover}>
      <HoverCardTrigger>
        <div
          className={`flex items-center ${isMobile ? 'flex-row-reverse' : 'flex-row'} ${
            popoverState.isOpen ? 'open' : ''
          }`}
        >
          {withCount && endorsementCount > 2 && (
            <div className="typography-heading-sm text-brand-main cursor-pointer">
              {endorsementCount}
            </div>
          )}
          <button aria-label="Endorse" className="icon" onClick={onClick}>
            <img
              alt="endorsements"
              {...imageProps}
              style={
                isDark && !isEndorsed && endorsementCount > 0
                  ? {
                      WebkitFilter: `
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                        `,
                      filter: `
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                          drop-shadow(0 0 1px #fff)
                        `,
                    }
                  : {}
              }
            />
          </button>
          {withText && (
            <div className="typography-action-sm text-brand-main cursor-pointer pl-1">see likes</div>
          )}
        </div>
      </HoverCardTrigger>
      {popoverState.isOpen && (
        <HoverCardContent className="p-0 max-h-[175px] overflow-y-scroll relative">
          <p className="pt-4 px-4 pb-0 sticky top-0 bg-brand-background">LIKES ({endorsementCount})</p>
          <div className="p-4">
            <div className="max-h-200px overflow-y-scroll">
              {data?.interactionConnection?.edges.length === 0 && (
                <div className="font-normal">Be the first to like this.</div>
              )}
              {data?.interactionConnection?.edges?.map(({ node }) => (
                <TWLink
                  key={node.id}
                  to={`/u/${node.originUser?.username}`}
                  className="flex gap-2 items-center mb-1"
                >
                  <NextImage
                    src={
                      node.originUser?.avatarPhotoSrc ||
                      `/default-profile-photo-${
                        themeColors.main === '#ffffff' || themeColors.main === 'white'
                          ? 'white'
                          : 'blue'
                      }-small.png`
                    }
                    width={20}
                    height={20}
                    alt="image"
                    quality={20}
                    style={{
                      minWidth: '20px',
                      minHeight: '20px',
                      maxWidth: '20px',
                      maxHeight: '20px',
                    }}
                  />
                  <div className="typography-action-sm">{node.originUser.username}</div>
                </TWLink>
              ))}
            </div>
          </div>
        </HoverCardContent>
      )}
    </HoverCard>
  );
}
