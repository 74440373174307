import { Box } from '@chakra-ui/react';
import { useAuth } from '../../../hooks/useAuth';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { UserCoreFragment } from '../../../../__generated__/graphql';

export default function UserAvatarStack({
  users,
  size,
}: {
  users: UserCoreFragment[];
  size?: number;
}) {
  const auth = useAuth();

  const [user1, user2] = users.sort((a, b) => (a.id !== auth.user?.id ? 0 : -1));

  const AVATAR_SIZE = size || 28;
  const MULTI_AVATAR_SIZE = size ? size - 4 : 24;

  const user1Node = (
    <PerfImageFromFile
      file={user1.avatarPhotoFile}
      useFallbackAvatarSrc
      alt="profile photo"
      quality={40}
      width={!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}
      height={!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}
      style={{
        width: `${!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}px`,
        height: `${!user2 ? AVATAR_SIZE : MULTI_AVATAR_SIZE}px`,
      }}
    />
  );

  if (!user2) {
    return user1Node;
  }

  const user2Node = (
    <PerfImageFromFile
      file={user2.avatarPhotoFile}
      useFallbackAvatarSrc
      alt="profile photo"
      quality={40}
      width={MULTI_AVATAR_SIZE}
      height={MULTI_AVATAR_SIZE}
      style={{
        width: `${MULTI_AVATAR_SIZE}px`,
        height: `${MULTI_AVATAR_SIZE}px`,
      }}
    />
  );

  return (
    <Box position="relative" width={`${AVATAR_SIZE}px`} height={`${AVATAR_SIZE}px`}>
      {user1Node}
      <Box position="absolute" top={1} left={1}>
        {user2Node}
      </Box>
    </Box>
  );
}
