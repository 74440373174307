import {
  Box,
  Button,
  FocusLock,
  FormControl,
  FormErrorMessage,
  Text,
  Stack,
  useDisclosure,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../ui/tooltip';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { Controller, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import {
  $createParagraphNode,
  $isRootOrShadowRoot,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  EditorState,
  EditorThemeClasses,
  LexicalEditor,
  LineBreakNode,
} from 'lexical';
import EmojiPicker, { Categories, EmojiStyle } from 'emoji-picker-react';
import React, { ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { RiDeleteBin2Fill, RiImage2Fill, RiLinkM, RiEdit2Fill } from 'react-icons/ri';
import { IoIosMove } from "react-icons/io";
import { useMutation } from '@apollo/client';
import { generateLexicalContentFromString } from 'shared/lexical';
import { updateRecSchema } from 'shared/validation/schemas';
import BasicEditor from 'web2/app/components/by-type/rich-text/basic/basic-editor';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { recBodyTheme, recTitleTheme } from 'shared/misc/theme/selectors/lexicalStyles';
import { RecommendationAttachmentFragment } from 'shared/__generated__/graphql';
import { isInWebView } from 'shared/webview';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { gql } from 'shared/__generated__/gql';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { mergeRegister, mediaFileReader, isMimeType } from '@lexical/utils';
import { ACCEPTABLE_IMAGE_TYPES } from 'shared/misc/components/rich-text/basic/plugins/PastePlugin';
import { TWButton } from '../../button';
import { cn } from '../../../utils/cn';
import RecDraggableAttachments from './rec-draggable-attachments';

export const FOOTER_HEIGHT = 60;

export type RecFormInputs = {
  emoji: string;
  url?: string | undefined;
  // as JSON
  titleLexicalState: EditorState | undefined | null;
  contentLexicalState: EditorState | undefined | null;
};

export type RecFormArgs = {
  recId?: string;
  draftRecId?: string;
  editorHeight?: string;
  minBodyEditorHeight?: string;
  maxBodyEditorHeight?: string;
  maxBodyEditorWithFileHeight?: string;
  defaultValues?: {
    title?: string | null | undefined;
    emoji?: string | null | undefined;
    url?: string | null | undefined;
    // As Json
    contentLexicalState?: EditorState | undefined | null;
  };
  onChange?: ((values: RecFormInputs, event?: React.SyntheticEvent) => void) | Function;
  onSubmit?: ((values: RecFormInputs, event?: React.SyntheticEvent) => void) | Function;
  isSubmitting?: boolean;
  submitText?: string;
  ref?: React.MutableRefObject<RecFormHandle | null>;
  defaultIsOpenEmojiToolTip?: boolean;
  hideEmoji?: boolean;
  hideLink?: boolean;
  withEmojiLinkSameLine?: boolean;
  isSlim?: boolean;
  isReadOnly?: boolean;
  titleTheme?: EditorThemeClasses;
  bodyTheme?: EditorThemeClasses;
  emojiPlaceholder?: string;
  titlePlaceholder?: string | ReactNode;
  bodyPlaceholder?: string | ReactNode;
  startCollapsed?: boolean;
  disableTitle?: boolean;
  requireContent?: boolean;
  belowContentNode?: ReactNode;
  attachments?: RecommendationAttachmentFragment[];
  onOpenFilePicker?: () => any;
  moveImages?: () => any;
  onUpload?: (file: File, args: { recId?: string; draftRecId?: string }) => any;
  onRemoveAttachment?: (attachmentId?: string) => any;
  onAddAttachment?: (attachment: RecommendationAttachmentFragment) => any;
};

type FormType = UseFormReturn<RecFormInputs, any, undefined>;

export interface RecFormHandle extends FormType {}

type ReturnValue = {
  isDirty: boolean;
  submitButtonNode: ReactNode;
  mainFormNode: ReactNode;
  editLinkNode: ReactNode;
  emojiNode: ReactNode;
  editImageNode: ReactNode;
  contentNode: ReactNode;
  titleNode: ReactNode;
  isOpen?: boolean;
  ref?: React.MutableRefObject<RecFormHandle | null>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  isImageUploaded: boolean;
  onReset: () => void;
};

function useRecForm({
  onChange: _onChange,
  onSubmit,
  defaultValues,
  isReadOnly = false,
  hideEmoji = false,
  hideLink = false,
  isSubmitting = false,
  submitText = 'Submit',
  editorHeight = '250px',
  minBodyEditorHeight = editorHeight,
  maxBodyEditorWithFileHeight = minBodyEditorHeight,
  maxBodyEditorHeight = editorHeight,
  ref: formRef,
  defaultIsOpenEmojiToolTip = false,
  withEmojiLinkSameLine = false,
  isSlim = false,
  titleTheme = recTitleTheme,
  bodyTheme = recBodyTheme,
  emojiPlaceholder,
  titlePlaceholder = 'What do you want to post?',
  bodyPlaceholder = (
    <Box>
      Tell us why :)
      <br />
      <br />
      Tip: tap on the emoji above to change it.
    </Box>
  ),
  startCollapsed = false,
  disableTitle = false,
  belowContentNode = null,
  requireContent = false,
  attachments = [],
  onOpenFilePicker,
  moveImages,
  onUpload,
  onRemoveAttachment,
  onAddAttachment,
  recId,
  draftRecId,
}: RecFormArgs): ReturnValue {
  const popoverState = useDisclosure();
  const editLinkPopoverState = useDisclosure();

  const [bodyEditor, setBodyEditor] = useState<LexicalEditor | null>();
  const [titleEditor, setTitleEditor] = useState<LexicalEditor | null>();
  const [linkStateIsOpen, setLinkStateIsOpen] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const isOpen = (startCollapsed && !isCollapsed) || !startCollapsed;

  const initialTitle = defaultValues?.title || '';

  const IS_IN_WEB_VIEW = isInWebView();

  const [analyzeLink, analyzeLinkState] = useMutation(ANALYZE_LINK);

  const form = useForm<RecFormInputs>({
    // @ts-ignore
    resolver: yupResolver(updateRecSchema(!disableTitle, requireContent)),
    defaultValues: {
      emoji: emojiPlaceholder || defaultValues?.emoji || '⭐',
      url: defaultValues?.url || '',
      // @ts-ignore
      titleLexicalState: initialTitle ? generateLexicalContentFromString(initialTitle) : null,
      contentLexicalState: defaultValues?.contentLexicalState,
    },
  });

  const { control, register, handleSubmit, setValue, watch, reset, formState } = form;
  const { errors } = formState;

  const isDirty = form.formState.isDirty;

  const allValues = watch();

  const onReset = () => {
    reset();
    bodyEditor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    titleEditor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  };

  useEffect(() => {
    if (!defaultValues?.emoji && allValues.emoji !== emojiPlaceholder) {
      setValue('emoji', emojiPlaceholder || defaultValues?.emoji || '⭐');
    }
  }, [emojiPlaceholder]);

  const onFormSubmit: SubmitHandler<RecFormInputs> = async (values, event) => {
    event?.preventDefault();
    onSubmit?.(values);
  };

  useEffect(() => {
    const subscription = watch((values: RecFormInputs) => {
      _onChange?.(values);
    });
    return () => subscription.unsubscribe();
  }, [watch, _onChange]);

  useImperativeHandle(formRef, () => ({ ...form, reset: onReset }), [onReset]);

  useEffect(() => {
    return titleEditor?.registerNodeTransform(LineBreakNode, (node) => {
      node.remove();
    });
  }, [titleEditor]);

  const themeColors = useThemeColors();

  const mainColor = themeColors.main;

  const prevEditLinkPopoverIsOpen = useRef<boolean>(editLinkPopoverState.isOpen);
  const prevUrl = useRef<string | undefined>(allValues.url);
  const attemptedUrls = useRef(new Set());

  useEffect(() => {
    if (
      prevEditLinkPopoverIsOpen.current &&
      !editLinkPopoverState.isOpen &&
      allValues.url &&
      !attemptedUrls.current.has(allValues.url)
    ) {
      analyzeLink({
        variables: { recId, draftRecId, url: allValues.url },
        onCompleted: (data) => {
          const attachment = data.analyzeLink?.attachment;
          if (attachment) onAddAttachment?.(attachment);

          const titleAsString = (data.analyzeLink?.draftRec || data.analyzeLink?.rec)?.title;
          const titleFromLink = data.analyzeLink?.otherFields?.ogTitle;

          if (!titleAsString && titleFromLink) {
            const newEditorState = titleEditor?.parseEditorState(
              JSON.stringify(generateLexicalContentFromString(titleFromLink)),
            );
            setValue('titleLexicalState', newEditorState);
            titleEditor?.setEditorState(newEditorState!);
          }
        },
      });
      attemptedUrls.current.add(allValues.url);
    }
    prevUrl.current = allValues.url;
    prevEditLinkPopoverIsOpen.current = editLinkPopoverState.isOpen;
  }, [editLinkPopoverState.isOpen, allValues.url, setValue]);

  useEffect(() => {
    if (bodyEditor) {
      return mergeRegister(
        bodyEditor?.registerCommand(
          DRAG_DROP_PASTE,
          (files) => {
            (async () => {
              if (!onUpload) return;
              const filesResult = await mediaFileReader(
                files,
                [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x),
              );
              for (const { file } of filesResult) {
                if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
                  onUpload(file, { recId, draftRecId });
                }
              }
            })();
            return true;
          },
          COMMAND_PRIORITY_HIGH,
        ),
      );
    }
  }, [bodyEditor, onUpload]);

  const imageAttachments = React.useMemo(() => {
    return attachments?.filter((a) => a.type === 'IMAGE').sort((a, b) => a.position - b.position);
  }, [attachments]);
  const image = imageAttachments?.[0]?.file;
  const isImageUploaded = imageAttachments.every((a) => a && (a?.file?.isUploaded));
  const imagePreviewNode = !IS_IN_WEB_VIEW ? (
    <RecDraggableAttachments
      imageAttachments={imageAttachments}
      moveImages={moveImages}
      onRemoveAttachment={onRemoveAttachment}
      onOpenFilePicker={onOpenFilePicker}
      isReadOnly={isReadOnly}
    />
  ) : (
    <div className="flex flex-col gap-2">
      <div className="flex space-x-2">
        {imageAttachments?.slice(0, 2).map((image, index) => {
          const maxDimension = index === 0 ? 220 : 120;
          const isLandscape = image.file.width > image.file.height;
          const aspectRatio = image.file.width / image.file.height;

          const width = isLandscape ? maxDimension : maxDimension * aspectRatio;
          const height = isLandscape ? maxDimension / aspectRatio : maxDimension;

          return (
            <div
              key={image.id}
              className="relative overflow-hidden cursor-pointer flex items-center justify-center"
              style={{ width: `${width}px`, height: `${height}px` }}
              onClick={() => {
                if (moveImages && imageAttachments.length >= 1 && imageAttachments.length < 5 && !isReadOnly && isImageUploaded) {
                  moveImages([{ id: imageAttachments.id, position: index }]);
                }
              }}
            >
              <PerfImageFromFile file={image.file} alt="image preview" className="object-cover" />
              {<div className="absolute top-[1px] right-[1px] p-[2px] bg-white">
                {!(index === 1 && attachments.length > 2) && (
                  <RiDeleteBin2Fill
                    size="20px"
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      if (onRemoveAttachment) {
                        onRemoveAttachment(image.id);
                      }
                    }}
                  />
                )}
              </div>}
              {index === 1 && attachments.length > 2 && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold">
                  +{attachments.length - 1}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {imageAttachments.length >= 1 && !isReadOnly && !IS_IN_WEB_VIEW && (
        <div>
          <Button
            variant="secondaryBlack"
            className="!p-0 !m-0 !pr-2 !justify-between text-[18px] leading-[18px]"
            onClick={(e) => {
              e.preventDefault();
              onOpenFilePicker?.();
            }}
            disabled={imageAttachments.length >= 5}
          >
            <RiImage2Fill className="ml-2 mr-1" size="18px" />
            Add Another
          </Button>
        </div>
      )}
    </div>
  );
  


  // const imagePreviewNode = isImageUploaded && (
  //   <HStack spacing="6px" alignItems="center" {...(isReadOnly ? { pointerEvents: 'none' } : {})}>
  //     <Tooltip>
  //       <TooltipContent className="typography-action-sm">Change image</TooltipContent>
  //       <TooltipTrigger>
  //         <Box onClick={onOpenFilePicker} cursor="pointer" maxWidth="200px" objectFit="contain">
  //           <PerfImageFromFile file={image} alt="recommendation image" />
  //         </Box>
  //       </TooltipTrigger>
  //     </Tooltip>

  //     {!isReadOnly && (
  //       <Tooltip>
  //         <TooltipContent className="typography-action-sm">Remove image</TooltipContent>
  //         <TooltipTrigger>
  //           <IconButton
  //             variant="icon"
  //             color="main"
  //             icon={<RiDeleteBin2Fill size="20px" />}
  //             onClick={() => {
  //               if (onRemoveAttachment) {
  //                 onRemoveAttachment(attachment.id);
  //               }
  //             }}
  //             textStyle="brand.headingLg"
  //             aria-label="Add link"
  //           />
  //         </TooltipTrigger>
  //       </Tooltip>
  //     )}
  //   </HStack>
  // );

  const editImageNode = (
    <div className="relative">
      {/* {isImageUploaded && imagePreviewNode} */}
      <Tooltip>
        <TooltipContent className="typography-action-sm">
          {IS_IN_WEB_VIEW ? 'Add an image' : allValues.url ? 'Add more images' : 'Add up to 5 images'}
        </TooltipContent>
        <TooltipTrigger>
          <IconButton
            variant="icon"
            icon={<RiImage2Fill size={!isSlim ? '30px' : '28px'} />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onOpenFilePicker?.();
            }}
            textStyle="brand.headingLg"
            aria-label="Add link"
            opacity={isImageUploaded && image ? 1 : 0.3}
            color={image ? 'brand.highlight' : 'brand.main'}
            disabled={imageAttachments.length >= 5}
          />
        </TooltipTrigger>
      </Tooltip>
      {!isImageUploaded && image && !analyzeLinkState.loading && (
        <div className="absolute z-10 top-3 left-[8px]"><Spinner size="sm" /></div>
      )}
    </div>
  );

  const editLinkNode = typeof window !== 'undefined' && (
    <Popover open={editLinkPopoverState.isOpen} onOpenChange={editLinkPopoverState.onToggle}>
      <PopoverTrigger>
        <div
          role="button"
          className="cursor-pointer p-0 vertical-center w-[30px] h-[30px]"
          onClick={(e) => {
            e.preventDefault();
            editLinkPopoverState.onToggle();
          }}
          aria-label={allValues.url ? 'Edit link' : 'Add link?'}
        >
          <Tooltip>
            <TooltipContent data-hidden-without-js="true" className="typography-action-sm">
              {allValues.url ? 'Edit link' : 'Add link?'}
            </TooltipContent>
            <TooltipTrigger>
              <RiLinkM
                size="28px"
                className={cn(
                  allValues.url ? 'text-brand-highlight' : 'text-brand-main',
                  !allValues.url && 'opacity-30',
                )}
              />
            </TooltipTrigger>
          </Tooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent
        data-hidden-without-js="true"
        className="bg-brand-background p-[18px] border-brand-main border-[1px] border-dashed"
        onCloseAutoFocus={(event) => {
          event.preventDefault();
        }}
        onOpenAutoFocus={(event) => {
          event.preventDefault();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.stopPropagation();
          }
        }}
      >
        <FocusLock persistentFocus={false}>
          <div className="text-brand-main text-[12px] font-bold">
            {allValues.url ? 'Modify link' : 'Add link'}
          </div>
          <div className="p-0 pt-5">
            <Box mb="16px" fontSize="16px" textStyle="brand.headingLg">
              <PerfInput placeholder="URL (Optional)" {...register('url')} error={errors.url} />
            </Box>
          </div>
          <footer className="flex justify-end">
            <HStack spacing="6px">
              {allValues.url && (
                <Button
                  size="sm"
                  variant="secondaryBlack"
                  onClick={() => setValue('url', undefined)}
                >
                  Remove
                </Button>
              )}
              <Button size="sm" variant="primaryBlack" onClick={editLinkPopoverState.onClose}>
                Close
              </Button>
            </HStack>
          </footer>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );

  const emojiNode = (
    <Popover open={popoverState.isOpen} onOpenChange={popoverState.onToggle}>
      <PopoverTrigger>
        <TWButton
          variant="icon"
          className={cn('p-0', popoverState.isOpen ? 'hover:bg-transparent hover:shadow-none' : '')}
          onClick={(e) => {
            e.preventDefault();
            popoverState.onToggle();
          }}
        >
          <Tooltip
          // label="Click here to select an emoji."
          // defaultIsOpen={defaultIsOpenEmojiToolTip && allValues.emoji === '😃'}
          >
            <TooltipContent className="typography-action-sm">
              Click here to select an emoji.
            </TooltipContent>
            <TooltipTrigger>
              <Text textStyle="brand.headingLg" fontSize={!isSlim ? '33px' : '24px'}>
                {allValues.emoji || defaultValues?.emoji || '😃'}
              </Text>
            </TooltipTrigger>
          </Tooltip>
        </TWButton>
      </PopoverTrigger>
      <PopoverContent
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.stopPropagation();
          }
        }}
      >
        <FocusLock persistentFocus={false}>
          {/* <PopoverCloseButton /> */}
          <div className="bg-brand-background p-[18px] border-brand-main border-[1px] border-dashed w-[270px]">
            <div className="typography-heading-sm text-brand-main font-bold text-[12px] mb-[5px]">
              SELECT AN EMOJI
            </div>
            <div className="p-0 m-0 border-dashed border-brand-background border-[1px]">
              <Controller
                control={control}
                name="emoji"
                render={({ field: { ref, onChange } }) => (
                  <Box ref={ref}>
                    <EmojiPicker
                      onEmojiClick={({ emoji }) => {
                        onChange(emoji);
                        popoverState.onClose();
                      }}
                      width="100%"
                      height="312px"
                      skinTonesDisabled={true}
                      lazyLoadEmojis
                      emojiStyle={EmojiStyle.NATIVE}
                      autoFocusSearch={false}
                      previewConfig={{
                        showPreview: false,
                      }}
                      style={{
                        width: '100%',
                        height: '312px',
                      }}
                      categories={EMOJI_CATEGORIES}
                    />
                  </Box>
                )}
              />
            </div>
          </div>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );

  const titleNode = (
    <Box
      width="100%"
      fontWeight="normal"
      onClick={() => {
        if (startCollapsed && isCollapsed) {
          setIsCollapsed(false);
        }
      }}
      marginBottom={IS_IN_WEB_VIEW ? '16px' : undefined}
    >
      <FormControl isInvalid={!!errors.titleLexicalState}>
        <Controller
          control={control}
          name="titleLexicalState"
          render={({ field: { onChange, ref } }) => (
            <BasicEditor
              isReadOnly={isReadOnly}
              key={isReadOnly ? 'title-readOnly' : 'title-editable'}
              isPlainText
              onChange={(editorState, _editor) => {
                onChange(editorState);
                setTitleEditor(_editor);
              }}
              ref={ref}
              shouldAutoFocus={false}
              initialEditorState={allValues.titleLexicalState}
              borderRadius="none"
              placeholder={titlePlaceholder}
              textStyle="brand.headingSm"
              border="1px solid transparent"
              borderColor="transparent"
              minHeight={isSlim ? { base: '16px', md: '24px' } : { base: '20px', md: '36px' }}
              maxHeight="150px"
              padding="0px"
              paddingLeft="0px"
              fontSize={isSlim ? { base: '16px', md: '24px' } : { base: '20px', md: '36px' }}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'block !important',
                  width: '10px !important',
                },
                ...(isSlim
                  ? {
                      '.content-editable': {
                        paddingLeft: '0px !important',
                        // caretColor: `${mainColor} !important`,
                      },
                    }
                  : {
                      '.content-editable': {
                        // caretColor: `${mainColor} !important`,
                      },
                    }),
              }}
              contentEditableProps={{
                padding: '0px',
                paddingLeft: '0px',
                fontSize: isSlim ? '24px' : '36px',
                ...(isSlim ? { height: '36px' } : {}),
                className: `content-editable ${
                  isSlim ? 'text-[16px] sm:text-[24px]' : 'text-[20px] sm:text-[36px]'
                }`,
              }}
              _focus={{
                paddingBottom: '-1px',
                boxShadow: 'none',
              }}
              editorTheme={titleTheme}
              readOnlyEditorTheme={titleTheme}
              placeholderProps={{
                opacity: '50% !important',
                cursor: 'text',
              }}
            />
          )}
        />
        {errors.titleLexicalState && (
          <FormErrorMessage>{errors.titleLexicalState?.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );

  const showTop =
    (!hideEmoji && emojiNode) ||
    (!hideLink && editLinkNode) ||
    (withEmojiLinkSameLine && !disableTitle && titleNode);

  const contentNode = (
    <Box width="100%" fontWeight="normal" flexGrow={1} display={!isOpen ? 'none' : undefined}>
      <FormControl
        isInvalid={!!errors.contentLexicalState}
        h="100%"
        minHeight={minBodyEditorHeight}
        maxHeight={{
          base: IS_IN_WEB_VIEW
            ? undefined
            : isSlim
            ? attachments[0]?.id
              ? minBodyEditorHeight
              : maxBodyEditorHeight
            : '650px',
          md: attachments[0]?.id ? maxBodyEditorWithFileHeight : maxBodyEditorHeight,
        }}
        overflowY="scroll"
        m="0px"
      >
        <Controller
          control={control}
          name="contentLexicalState"
          render={({ field: { onChange, ref } }) => (
            <BasicEditor
              isReadOnly={isReadOnly}
              key={isReadOnly ? 'content-readOnly' : 'content-editable'}
              onChange={(editorState, _editor) => {
                onChange(editorState);
                setBodyEditor(_editor);
              }}
              ref={ref}
              includeMentions
              uploadImageOnFilePaste
              shouldAutoFocus={false}
              initialEditorState={allValues.contentLexicalState}
              borderRadius="none"
              placeholder={bodyPlaceholder}
              textStyle="brand.bodyLg"
              border="1px solid transparent"
              borderColor="transparent"
              fontSize="20px"
              minHeight={minBodyEditorHeight}
              maxHeight={{
                base: IS_IN_WEB_VIEW
                  ? undefined
                  : isSlim
                  ? attachments.length > 0
                    ? minBodyEditorHeight
                    : maxBodyEditorHeight
                  : '650px',
                md: attachments.length > 0 ? maxBodyEditorWithFileHeight : maxBodyEditorHeight,
              }}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'block !important',
                  width: '10px !important',
                },
              }}
              contentEditableProps={{
                p: '0px',
                pb: '10px',
                paddingLeft: '0px',
                ...(!IS_IN_WEB_VIEW ? { maxHeight: '248px' } : {}),
              }}
              _focus={{
                paddingBottom: '-1px',
                boxShadow: 'none',
              }}
              editorTheme={bodyTheme}
              readOnlyEditorTheme={recBodyTheme}
              placeholderProps={{
                opacity: '50% !important',
              }}
            />
          )}
        />
        {errors.contentLexicalState && (
          <FormErrorMessage position="absolute" bottom="0px">
            {errors.contentLexicalState?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );

  const mainFormNode = (
    <Box h="auto" as="form" onSubmit={handleSubmit(onFormSubmit)}>
      {showTop && (
        <Stack
          display="flex"
          spacing={!isSlim ? '24px' : '12px'}
          mt={!isSlim ? '12px' : '0px'}
          flexFlow="column"
          {...(isReadOnly ? { pointerEvents: 'none' } : {})}
        >
          <HStack spacing={!isSlim ? '16px' : '6px'} alignItems="center">
            {!hideEmoji && emojiNode}
            <HStack spacing={!isSlim ? '12px' : '4px'} alignItems="center">
              {editImageNode}
              {!hideLink && editLinkNode}
            </HStack>
            {withEmojiLinkSameLine && !disableTitle && titleNode}
          </HStack>
          <FormErrorMessage>{errors.emoji?.message}</FormErrorMessage>
        </Stack>
      )}
      <Stack
        display="flex"
        flexDirection="column"
        h="100%"
        spacing={!isSlim ? '20px' : '12px'}
        mt={showTop ? (!isSlim ? '24px' : '12px') : undefined}
        overflowY="auto"
      >
        {!withEmojiLinkSameLine && !disableTitle && titleNode}
        {contentNode}
        {imagePreviewNode}
      </Stack>
      {belowContentNode}
    </Box>
  );

  const submitButtonNode = (
    <TWButton
      onClick={handleSubmit(onFormSubmit)}
      variant="primary"
      isLoading={isSubmitting}
      className="typography-action-sm text-[13.5px]"
      disabled={isSubmitting || !isImageUploaded}
    >
      {submitText}
    </TWButton>
  );

  return {
    isDirty,
    isOpen,
    editImageNode: !IS_IN_WEB_VIEW ? editImageNode : null,
    submitButtonNode,
    mainFormNode,
    emojiNode,
    editLinkNode,
    contentNode,
    titleNode,
    ref: formRef,
    onSubmit: handleSubmit(onFormSubmit),
    isImageUploaded,
    onReset
  };
}

const ANALYZE_LINK = gql(/* GraphQL */ `
  mutation analyzeLink($recId: String, $draftRecId: String, $url: String!) {
    analyzeLink(url: $url, recId: $recId, draftRecId: $draftRecId) {
      otherFields
      attachment {
        id
        ...RecommendationAttachment
      }
      rec {
        id
        title
        attachments {
          ...RecommendationAttachment
        }
      }
      draftRec {
        id
        title
        attachments {
          ...RecommendationAttachment
        }
      }
    }
  }
`);

const EMOJI_CATEGORIES = [
  {
    category: Categories.SUGGESTED,
    name: 'Recently Used',
  },
  {
    category: Categories.OBJECTS,
    name: 'Objects...',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Activities...',
  },
  {
    category: Categories.SYMBOLS,
    name: 'Symbols...',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Food & Drink...',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Travel...',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Faces...',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Nature & Animals...',
  },
  {
    category: Categories.FLAGS,
    name: 'Flags...',
  },
];

export default useRecForm;
