import { createFileRoute } from '@tanstack/react-router';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__/gql';
import DraftRecommendation from 'web2/app/components/by-type/recommendations/draft-recommendation';

export const Route = createFileRoute('/drafts/')({
  component: RouteComponent,
});

gql(/* GraphQL */ `
  fragment UserDrafts on User {
    id
    recCount
    draftRecs(first: 30) {
      ...RecDraftAutoSave
    }
  }
`);

const Query = gql(/* GraphQL */ `
  query getDraftsView {
    me {
      ...UserDraftRecsSignUp
    }
  }
`);

function RouteComponent() {
  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery(Query, {
    fetchPolicy: 'cache-and-network',
    // pollInterval: 60000,
  });

  const draftsList = data?.me?.draftRecs;

  if (!draftsList) {
    return null;
  }

  return (
    <div className="flex justify-center p-6 pb-[100px]">
      <div className="space-y-6 w-full md:max-w-[800px]">
        <div className="space-y-12 flex flex-col align-center">
          <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
            Drafts
          </h1>
          {!draftsList.length ? (
            <span className="typography-action-xl uppercase text-center">
              If you keep an unfinished recommendation it will show up here for you to come back to
              whenever you'd like.
            </span>
          ) : (
            draftsList.map((draft) => (
              <div key={draft.id} className="w-[700px] max-w-full self-center">
                <DraftRecommendation draft={draft} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
