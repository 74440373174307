import { ComponentStyleConfig, defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const Tooltip: ComponentStyleConfig = defineStyleConfig({
  // style object for base or default style
  baseStyle: {},
  variants: {
    default: defineStyle({
      // cursor: 'pointer',
      backgroundColor: 'brand.background',
      color: 'brand.main',
      borderColor: 'brand.main',
      borderWidth: '1px',
      textStyle: 'brand.headingSm',
      fontWeight: 'bold',
      borderRadius: 0,
      padding: '4px',
    }),
  },
  defaultProps: {
    variant: 'default',
  },
});
