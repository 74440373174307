import React from 'react';
import AutoApplyInvite from './-auto-apply-invite';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up/invite/$code')({
  component: InvitePage,
  head: () => ({
    meta: [
      {
        title: `INVITATION FOR PI.FYI`,
        description: 'Sign up and get started.',
      },
    ],
  }),
});

export const revalidate = 5000;

export default async function InvitePage() {
  const { code } = Route.useParams();

  return <AutoApplyInvite code={code} />;
}
