import { useToast } from '@chakra-ui/react';
import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/stripe/success/')({
  component: RouteComponent,
});

function RouteComponent() {
  const router = useRouter();
  const toast = useToast();

  useEffect(() => {
    toast({
      title: 'Subscription updated.',
      status: 'success',
      duration: 3000,
      isClosable: false,
      position: 'top',
      onCloseComplete: () => {
        router.navigate({ to: '/settings/premium' });
      },
    });
  }, []);
  return <></>;
}
