

import { Box, Button, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { gql } from 'shared/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import UserDirectoryItem from 'shared/misc/components/by-type/user/UserDirectoryItem';
import { Link } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

gql(/* GraphQL */ `
  fragment FollowView on Query {
    topUsersOfWeekConnection: topUserConnection(first: 15)
      @connection(key: "topUsersOfWeekConnection") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UserDirectoryItem
        }
      }
    }
  }
`);

export const FollowViewQuery = gql(/* GraphQL */ `
  query getFollowViewQuery {
    ...FollowView
  }
`);

export const Route = createFileRoute('/sign-up/follow/')({
  component: FollowPage,
});

function FollowPage() {
  const { data } = useSuspenseQuery(FollowViewQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.PREMIUM],
  });

  // const auth = useAuth<true>();

  const users = data.topUsersOfWeekConnection.edges;

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal" overflowY="scroll">
        <Box
          marginTop={{ base: '24px', md: '100px' }}
          marginBottom={{ base: '24px', md: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p={{ base: '24px', md: '48px' }}
        >
          <Stack spacing={6} w="100%" maxW={{ base: '400px', lg: '800px' }}>
            <Stack spacing={6} w="100%" maxW={{ base: '500px' }} alignSelf="center">
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">Your account is ready!</Text>
              <Text textStyle="brand.body" fontWeight="normal" fontStyle="italic">
                Just a few things before you dive in
              </Text>
              <Text textStyle="brand.body" fontWeight="normal" key="there's an app now">
                <strong>There's an app now...</strong> and it's better than the website in almost
                every way. If that sounds cool,{' '}
                <Link
                  variant="stylizedBasic"
                  className="text-brand-main underline"
                  to="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926"
                >
                  click here to download.
                </Link>
              </Text>
              {/* <Text textStyle="brand.body" fontWeight="normal">
                Don't hesitate to{' '}
                <Link href="mailto:tyler@perfectlyimperfect.fyi?subject=Perfectly%20Imperfect">
                  email
                </Link>{' '}
                us with any feedback or if you encounter a bug. Thanks for being a part of this
                project!
              </Text> */}
              <Text textStyle="brand.body" fontWeight="normal" key="overthink">
                <strong>Don't overthink it...</strong> recommendations don’t have to be precious or
                "cool" or interesting or unique or anything special really. It's just a tiny piece
                of yourself that other people may also resonate with. Plain and simple.
              </Text>
              <Text textStyle="brand.body" fontWeight="normal" key="fun with friends">
                <strong>PI.FYI is fun with friends...</strong> and cool strangers, so we{' '}
                <Link variant="stylizedBasic" className="text-brand-main underline" to="/invites">
                  pre-loaded 10 invite codes
                </Link>{' '}
                to your account to bring some friends along or you can follow some top users below.
              </Text>

              <HStack spacing={3} alignSelf="center" mt="12px">
                <Button
                  onClick={async () => {
                    await updateStep(null);
                  }}
                >
                  LET ME IN!
                </Button>
              </HStack>
            </Stack>
            <Stack spacing="24px" marginTop={{ base: '16px', md: '24px' }} overflowY="scroll">
              <Text textStyle="brand.headingSm" fontSize="16px" alignSelf="center">
                Top users last week
              </Text>
              <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 2 }} width="100%" gap="12px">
                {users.map(({ node: user }) => {
                  return (
                    <UserDirectoryItem
                      recsWhereBadgeIs
                      user={user}
                      key={user.id}
                      showMutuals={false}
                    />
                  );
                })}
              </SimpleGrid>
            </Stack>
            <HStack spacing={3} alignSelf="center" mt="12px">
              <Button
                onClick={async () => {
                  await updateStep(null);
                }}
              >
                LET ME IN!
              </Button>
            </HStack>
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}
