import { createFileRoute, redirect } from '@tanstack/react-router';
import { Box, Stack } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { gql } from 'shared/__generated__/gql';
import Heading from 'shared/misc/components/heading';
import SettingsProfileInfo from './-settings-profile-info';
import { getSessionUser } from '../../actions/getSessionUser';

export const Route = createFileRoute('/settings/')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({ to: `/sign-in?destination=${encodeURIComponent('/settings/premium')}` });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Settings',
      },
    ],
  }),
});

gql(/* GraphQL */ `
  fragment SettingsView on User {
    id
    email
    firstName
    lastName
    username
    isPremium
    location
    instagram
    twitter
    website
    bio
    bioLexical
    profilePhotoSrc
    avatarPhotoSrc
    profileTheme
  }
`);

const SETTINGS_VIEW_QUERY = gql(/* GraphQL */ `
  query getSettingsView {
    me {
      ...SettingsView
    }
  }
`);

function RouteComponent() {
  const { user } = useAuth();

  const { data } = useQuery(SETTINGS_VIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const me = data?.me || user;

  return (
    <Box className="flex justify-center mb-[75px] p-[24px] pb-[128px]">
      <Stack spacing="24px" maxWidth="800px" width="100%">
        <Stack spacing="24px" justifyContent="center" width="100%">
          <Box alignSelf="center">
            <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
              Settings
            </h1>
          </Box>
          <SettingsProfileInfo user={me} />
        </Stack>
      </Stack>
    </Box>
  );
}
