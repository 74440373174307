'use client';
import { Stack, SimpleGrid, Text } from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { redirect } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { InView } from 'react-intersection-observer';
import { useState } from 'react';
import { gql } from 'shared/__generated__';
import { GetGenericRecScrollViewQueryVariables } from 'shared/__generated__/graphql';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import RecommendationItem, { RecommendationItemProps } from './recommendation-item';

const GenericRecScrollViewQuery = gql(/* GraphQL */ `
  query getGenericRecScrollView(
    $first: Int
    $after: String
    $search: RecSearchInput
    $category: RecCategory
    $orderBy: OrderBy
    $originUserId: String
    $showGlobalFeed: Boolean
    $isPinned: Boolean
    $isEndorsedByViewer: Boolean
    $includePromptRecs: Boolean
    $includeReRecsWithoutComment: Boolean
    $onlyGuests: Boolean
  ) {
    genericRecConnection: recConnection(
      first: $first
      after: $after
      isEndorsedByViewer: $isEndorsedByViewer
      orderBy: $orderBy
      search: $search
      includePromptRecs: $includePromptRecs
      onlyGuests: $onlyGuests
      userId: $originUserId
      includeReRecsWithoutComment: $includeReRecsWithoutComment
      isPinned: $isPinned
    ) @connection(key: "genericRecConnection") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 10;

export enum FeedUserFilter {
  FOLLOWING = 'FOLLOWING',
  EVERYONE = 'EVERYONE',
}

export enum FeedContentFilter {
  ALL = 'ALL',
  ONLY_RECS = 'ONLY_RECS',
}

interface ItemProps extends RecommendationItemProps {
  rec?: null;
}

interface GenericRecInfiniteScrollViewProps {
  shouldFetchMore?: boolean;
  showSpinner?: boolean;
  pageLength?: number;
  variables?: GetGenericRecScrollViewQueryVariables;
  itemProps?: ItemProps;
  includePromptRecs?: boolean;
  includeReRecsWithoutComment?: boolean;
  // onlyFollowing?: boolean;
}

export default function GenericRecInfiniteScrollView({
  pageLength = PAGE_LENGTH,
  shouldFetchMore = true,
  showSpinner = true,
  variables: {
    isEndorsedByViewer = false,
    search = undefined,
    includePromptRecs = true,
    onlyGuests = false,
    includeReRecsWithoutComment = false,
    ...propVariables
  } = {},
  itemProps = {},
}: GenericRecInfiniteScrollViewProps) {
  const [isInView, setIsInView] = useState(true);

  const { data, loading, error, fetchMore, variables } = useQuery(GenericRecScrollViewQuery, {
    fetchPolicy: 'cache-first',
    // pollInterval: isInView ? 30000 : undefined,
    variables: {
      isEndorsedByViewer,
      search,
      includePromptRecs,
      includeReRecsWithoutComment,
      onlyGuests,
      ...propVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const getNextPage = async () => {
    if (!data?.genericRecConnection.pageInfo.hasNextPage || (!loading && !shouldFetchMore)) return;
    await fetchMore({
      variables: {
        ...variables,
        first: pageLength,
        after: data?.genericRecConnection.pageInfo.endCursor,
      },
    });
  };

  if (!data && !loading) {
    return redirect({ to: '/not-found' });
  } else if (error) {
    return redirect({ to: '/error' });
  }

  const recItems = data?.genericRecConnection?.edges || [];

  return (
    <>
      <InView
        as="div"
        width="100%"
        rootMargin="200px 0px"
        onChange={(inView) => {
          setIsInView(inView);
        }}
      />
      <Stack spacing="24px">
        {!data && loading && (
          <>
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
          </>
        )}
        {recItems.length > 0 ? (
          <>
            <SimpleGrid columns={1} gap="24px">
              {recItems.map(({ node }) => {
                return <RecommendationItem {...itemProps} key={node.id} rec={node} showPrompt />;
              })}
            </SimpleGrid>
            <InView
              as="div"
              rootMargin="2000px 0px"
              onChange={(inView) => {
                if (inView) {
                  getNextPage();
                }
              }}
            />
            {loading && <Spinner size="lg" placement="center" />}
          </>
        ) : (
          <Text textStyle="brand.headingSm" alignSelf="center">
            No recommendations found.
          </Text>
        )}
      </Stack>
    </>
  );
}
