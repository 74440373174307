import React, { useCallback, useEffect, useMemo } from 'react';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../ui/modal';
import { Button } from '../button';
import { useMutation, useQuery } from '@apollo/client';
import usePrevious from 'use-previous';
import { RiCheckFill } from 'react-icons/ri';
import { gql } from 'shared/__generated__/gql';
import { useRecommendationContext } from 'shared/misc/components/by-type/recommendations/providers/useRecommendationContext';
import CreateOrUpdateListModal from 'web2/app/components/modals/create-or-update-list-modal';

type Props = { modalState?: ReturnType<typeof useDisclosure> };

interface List {
  __typename: "List";
  id: string;
  title: string;
  entryCount: number;
}

export default function AddToListModal({ modalState: _modalState }: Props) {
  const { rec } = useRecommendationContext();
  const modalState = _modalState || useDisclosure();
  const createModalState = useDisclosure();

  const { data } = useQuery(GET_VIEWER_LISTS, {
    variables: { recId: rec.id },
    fetchPolicy: 'cache-and-network',
  });

  const [addRecToList] = useMutation(ADD_TO_LIST_MUTATION);
  const [removeRecFromList] = useMutation(REMOVE_REC_LIST_MUTATION);

  const recLists = data?.rec?.lists || [];

  console.log({ recLists });

  useEffect(() => {
    console.log('modalState', modalState.isOpen);
  }, [modalState.isOpen]);

  const prevIsOption = usePrevious(modalState.isOpen);
  const listIds = useMemo(() => new Set(recLists.map(({ id }) => id)), [recLists]);

  const onAddToList = useCallback(
    async (list: List) => {
      await addRecToList({
        variables: { recId: rec.id, listId: list.id },
        optimisticResponse: {
          __typename: 'Mutation',
          addRecToList: {
            __typename: 'Rec',
            id: rec.id,
            lists: [
              list,
              ...recLists
            ],
          },
        },
      });
    },
    [addRecToList, recLists, rec.id],
  );

  const onRemoveFromList = useCallback(
    async (list: List) => {
      await removeRecFromList({
        variables: { recId: rec.id, listId: list.id },
        optimisticResponse: {
          __typename: 'Mutation',
          removeRecFromList: {
            __typename: 'Rec',
            id: rec.id,
            lists: recLists.filter((l) => l.id !== list.id),
          },
        },
        // update(cache) {
        //   cache.modify({
        //     id: cache.identify(rec),
        //     fields: {
        //       lists(existingListRefs = [], { readField }) {
        //         return existingListRefs.filter((l) => readField('id', l) !== list.id);
        //       },
        //     },
        //   });
        // },
      });
    },
    [removeRecFromList, recLists, rec.id],
  );

  const onToggle = useCallback(
    async (list: List) => {
      if (!listIds.has(list.id)) {
        onAddToList(list);
      } else {
        onRemoveFromList(list);
      }
    },
    [onAddToList, onRemoveFromList, listIds],
  );

  useEffect(() => {
    if (!prevIsOption && modalState.isOpen) {
      // runQuery();
    }
  }, [modalState.isOpen]);

  return (
    <>
      {createModalState.isOpen && (
        <CreateOrUpdateListModal modalState={createModalState} recId={rec.id} />
      )}
      <Modal open={modalState.isOpen} onOpenChange={modalState.onToggle}>
        <ModalContent className="max-h-[100%] max-w-[100%] w-[700px] m-0 lg:ml-[16px] lg:mr-[16px] lg:mb-[16px] lg:mt-[16px] p-[24px] lg:p-[48px] lg:pb-[96px] lg:mt-[16px]">
          <ModalHeader className="text-[22px] lg:text-[36px] border-none h-[50px] opacity-100">Add to List</ModalHeader>
          <div>
            <div className="w-full overflow-y-auto max-h-[215px] flex flex-col gap-2">
              <Button
                key="create-new-list"
                className="p-[6px] w-full text-left flex justify-between items-center text-[16px] lg:text-[24px]"
                variant="secondary"
                onClick={createModalState.onOpen}
              >
                <span>Create a new list</span>
              </Button>
              {data?.me?.lists.map((list) => (
                <Button
                  variant={listIds.has(list.id) ? 'primary' : 'secondary'}
                  key={list.id}
                  className="p-[6px] w-full text-left flex justify-between items-center text-[16px] lg:text-[24px]"
                  onClick={() => onToggle(list)}
                >
                  <span>{list.title || 'Untitled'}</span>
                  {listIds.has(list.id) && (
                    <span className="ml-1">
                      <RiCheckFill aria-hidden="true" />
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </div>
          <ModalFooter className="flex justify-end items-center h-[68px] absolute bottom-0" style={{ width: "calc(100% - 24px)"}}>
            <div className="flex gap-2">
              <Button className="w-[80px] h-[40px] m-0" variant="primary" onClick={modalState.onClose}>
                Done
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const GET_VIEWER_LISTS = gql(/* GraphQL */ `
  query getViewerLists($recId: String!) {
    rec(id: $recId) {
      id
      lists {
        id
        title
      }
    }
    me {
      id
      bookmarksCount
      lists(orderBy: "recentlyUpdated") {
        id
        title
        entryCount
      }
    }
  }
`);

const ADD_TO_LIST_MUTATION = gql(/* GraphQL */ `
  mutation addRecToList($recId: String!, $listId: String!) {
    addRecToList(recId: $recId, listId: $listId) {
      id
      lists {
        ...RecommendationItemList
      }
    }
  }
`);

const REMOVE_REC_LIST_MUTATION = gql(/* GraphQL */ `
  mutation removeRecFromListPopover($recId: String!, $listId: String!) {
    removeRecFromList(recId: $recId, listId: $listId) {
      id
      lists {
        ...RecommendationItemList
      }
    }
  }
`);
