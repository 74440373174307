import {
  CreateDocumentInput,
  CreateDocumentRelatedDataMutationVariables,
  DocumentBlockCoreFragment,
  DocumentBlockInput,
  DocumentCoreFragment,
  DocumentFullFragmentDoc,
  DocumentRelatedDataCoreFragment,
  DocumentRelatedDataStatus,
  DocumentRelatedDataType,
  UpdateDocumentRelatedDataInput,
} from './../../__generated__/graphql';
import { useFragment, useMutation } from '@apollo/client';
import { gql } from '../../__generated__/gql';
import _ from 'lodash';
import { useCallback } from 'react';
import { EditorState } from 'lexical';
import { processLexicalState } from '../../lexical';
import useFileUpload from './useFileUpload';
import { useToast } from '@chakra-ui/react';
import { safeParse } from '../../utils';

interface UseDocumentArgs {
  document?: DocumentCoreFragment;
}

export default function useDocumentHooks({ document }: UseDocumentArgs = {}) {
  const toast = useToast();

  const [createDocument, createDocumentInfo] = useMutation(CREATE_DOCUMENT_MUTATION);

  const [createDocumentBlock, createDocumentBlockInfo] = useMutation(
    CREATE_DOCUMENT_BLOCK_MUTATION,
  );

  const [updateDocumentBlock, updateDocumentBlockInfo] = useMutation(
    UPDATE_DOCUMENT_BLOCK_MUTATION,
  );

  const [deleteDocumentBlock, deleteDocumentBlockInfo] = useMutation(
    DELETE_DOCUMENT_BLOCK_MUTATION,
  );

  const [updateDocumentLastViewedAtMutation, updateDocumentLastViewedAtInfo] = useMutation(
    UPDATE_DOCUMENT_LAST_VIEWED_AT_MUTATION,
  );

  const [createDocumentRelatedData, createDocumentRelatedDataInfo] = useMutation(
    CREATE_DOCUMENT_RELATED_DATA_MUTATION,
  );

  const [deleteDocumentRelatedData, deleteDocumentRelatedDataInfo] = useMutation(
    DELETE_DOCUMENT_RELATED_DATA_MUTATION,
  );

  const [updateDocumentRelatedData, updateDocumentRelatedDataInfo] = useMutation(
    UPDATE_DOCUMENT_RELATED_DATA_MUTATION,
  );

  const [updateDocument, updateDocumentInfo] = useMutation(UPDATE_DOCUMENT_MUTATION);

  const [updateDocumentStatus, updateDocumentStatusInfo] = useMutation(UPDATE_DOCUMENT_STATUS, {
    onError: (e) => {
      if (e.graphQLErrors[0]?.extensions?.surfaceToUser) {
        toast({
          title: e.graphQLErrors[0].message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  const updateDocumentLastViewedAt = (documentId: string) => {
    updateDocumentLastViewedAtMutation({
      variables: { documentId },
    });
  };

  const [deleteDocument, deleteDocumentInfo] = useMutation(DELETE_DOCUMENT);

  const updateDocumentDebounced = useCallback(
    _.debounce(
      (
        document: DocumentCoreFragment,
        input: Omit<CreateDocumentInput, 'title'> & {
          titleLexicalState?: EditorState | null;
          subtitleLexicalState?: EditorState | null;
          contentLexicalState?: EditorState | null;
        },
      ) => {
        const {
          contentLexicalState = null,
          titleLexicalState,
          subtitleLexicalState,
          ...rest
        } = input;

        const { text: title } = titleLexicalState
          ? processLexicalState(titleLexicalState)
          : { text: document.title };

        const { text: subtitle } = subtitleLexicalState
          ? processLexicalState(subtitleLexicalState)
          : { text: document.title };

        const { text: content, json: contentLexical } = contentLexicalState
          ? processLexicalState(contentLexicalState)
          : { text: document.content, json: document.contentLexical };

        const updatedDocument = {
          ...rest,
          ...document,
          __typename: 'Document',
          id: document.id,
          type: rest.type || document.type,
          section: document.section,
          title,
          subtitle,
          content,
          contentLexical,
        };

        if (!_.isEqual(updatedDocument, document)) {
          if (updatedDocument.content === '' && (document?.content?.length || 0) > 100) {
            const confirmed = confirm(
              `Did you mean to delete everything? Or was this an accident.`,
            );
            if (!confirmed) return;
          }

          try {
            const drafts = safeParse(
              localStorage.getItem(`drafts:${document.id}`),
            ) as DocumentCoreFragment[];
            if (drafts) {
              const updatedDrafts = addToArrayWithMaxLength(drafts, document, 4);
              localStorage.setItem(`drafts:${document.id}`, JSON.stringify(updatedDrafts));
            } else {
              localStorage.setItem(`drafts:${document.id}`, JSON.stringify([document]));
            }
          } catch (e) {
            // localStorage.setItem(`drafts:${document.id}`, JSON.stringify([document]));
          }

          updateDocument({
            variables: {
              documentId: document.id,
              input: {
                ...rest,
                title,
                subtitle,
                content,
                contentLexical,
                section: document.section,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateDocument: {
                ...document,
                ...updateDocument,
                id: document.id,
                __typename: 'Document',
              },
            },
          });
        }
      },
      1000,
    ),
    [updateDocumentBlock],
  );

  const updateDocumentSafe = (
    document: DocumentCoreFragment,
    input: Omit<CreateDocumentInput, 'title' | 'section'>,
    successMessage?: string,
  ) => {
    const { content, contentHTML, contentLexical, ...rest } = input;

    const updatedDocument = {
      ...document,
      ...rest,
      __typename: 'Document',
      id: document.id,
    };

    if (!_.isEqual(updatedDocument, document)) {
      updateDocument({
        variables: {
          documentId: document.id,
          input: {
            title: document.title,
            section: document.section,
            ...rest,
          },
        },
        onCompleted: () => {
          if (successMessage) {
            toast({
              title: successMessage,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDocument: {
            ...document,
            ...updateDocument,
            id: document.id,
            __typename: 'Document',
          },
        },
      });
    }
  };

  const updateDocumentSafeDebounced = useCallback(_.debounce(updateDocumentSafe, 1000), [
    updateDocumentSafe,
  ]);

  const updateDocumentBlockDebounced = useCallback(
    _.debounce(
      (
        documentBlock: DocumentBlockCoreFragment,
        input: DocumentBlockInput & {
          contentLexicalState?: EditorState | null;
        },
      ) => {
        const { contentLexicalState = null, ...rest } = input;

        const { text: content, json: contentLexical } = contentLexicalState
          ? processLexicalState(contentLexicalState)
          : { text: documentBlock.content, json: documentBlock.contentLexical };

        const updatedDocumentBlock = {
          ...documentBlock,
          ...rest,
          __typename: 'DocumentBlock',
          id: documentBlock.id,
          position: rest.position || documentBlock.position,
          type: rest.type || documentBlock.type,
          updatedAt: documentBlock.updatedAt,
          createdAt: documentBlock.createdAt,
          content,
          contentLexical,
        };

        if (!_.isEqual(updatedDocumentBlock, documentBlock)) {
          updateDocumentBlock({
            variables: {
              documentBlockId: documentBlock.id,
              input: {
                ...rest,
                content,
                contentLexical,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateDocumentBlock: {
                ...updatedDocumentBlock,
                id: documentBlock.id,
                __typename: 'DocumentBlock',
              },
            },
          });
        }
      },
      1000,
    ),
    [updateDocumentBlock],
  );

  const [moveDocumentBlockPosition, moveDocumentBlockPositionInfo] = useMutation(
    MOVE_DOCUMENT_BLOCK_POSITION_MUTATION,
  );

  const [sendTestEmail, sendTestEmailInfo] = useMutation(SEND_TEST_EMAIL);

  const { openPickerPromise, nodes: inputNodes } = useFileUpload();

  const createDocumentRelatedDataFromFile = async ({
    replaceIfExists,
    context,
    status,
  }: {
    replaceIfExists?: boolean;
    context?: string[];
    status?: DocumentRelatedDataStatus;
  } = {}) => {
    const file = await openPickerPromise();
    if (file) {
      createDocumentRelatedData({
        variables: {
          documentId: document!.id,
          replaceIfExists,
          input: { targetFileId: file.id, type: DocumentRelatedDataType.Image, status, context },
        },
      });
    }
  };

  const updateDocumentRelatedDataOptimistic = (
    documentRelatedData: DocumentRelatedDataCoreFragment,
    input: UpdateDocumentRelatedDataInput,
  ) => {
    return updateDocumentRelatedData({
      variables: { documentRelatedDataId: documentRelatedData.id, input },
      optimisticResponse: {
        __typename: 'Mutation',
        updateDocumentRelatedData: {
          __typename: 'DocumentRelatedData',
          ...documentRelatedData,
          ...input,
          misc: input.misc || documentRelatedData.misc || {},
          context: input.context || documentRelatedData.context,
          status: input.status || documentRelatedData.status,
        },
      },
    });
  };

  return {
    createDocument,
    createDocumentInfo,
    updateDocument,
    updateDocumentInfo,
    updateDocumentDebounced,
    updateDocumentSafe,
    updateDocumentSafeDebounced,
    createDocumentBlock,
    createDocumentBlockInfo,
    updateDocumentBlock,
    updateDocumentBlockInfo,
    updateDocumentBlockDebounced,
    deleteDocumentBlock,
    deleteDocumentBlockInfo,
    moveDocumentBlockPosition,
    moveDocumentBlockPositionInfo,
    createDocumentRelatedData,
    createDocumentRelatedDataInfo,
    createDocumentRelatedDataFromFile,
    deleteDocumentRelatedData,
    deleteDocumentRelatedDataInfo,
    updateDocumentRelatedData,
    updateDocumentRelatedDataOptimistic,
    updateDocumentRelatedDataInfo,
    updateDocumentStatus,
    updateDocumentStatusInfo,
    updateDocumentLastViewedAt,
    updateDocumentLastViewedAtInfo,
    deleteDocument,
    deleteDocumentInfo,
    sendTestEmail,
    sendTestEmailInfo,
    inputNodes,
  };
}

export function useDocumentFragment(documentId: string) {
  const { data } = useFragment({
    fragment: DocumentFullFragmentDoc,
    fragmentName: 'DocumentFull',
    from: {
      __typename: 'Document',
      id: documentId,
    },
  });

  return { document: data };
}

gql(/* GraphQL */ `
  fragment DocumentBlockCore on DocumentBlock {
    id
    documentId
    createdAt
    updatedAt
    position
    type
    content
    contentHTML
    contentLexical
    targetRec {
      ...RecommendationItem
    }
    targetFile {
      id
      ...PerfImageFromFile
    }
  }
`);

gql(/* GraphQL */ `
  fragment DocumentRelatedDataCore on DocumentRelatedData {
    id
    documentId
    createdAt
    updatedAt
    type
    status
    context
    misc
    targetUser {
      ...UserCore
    }
    targetRec {
      ...RecommendationItem
    }
    targetFile {
      id
      ...PerfImageFromFile
    }
    targetPrompt {
      ...PromptItem
    }
  }
`);

gql(/* GraphQL */ `
  fragment DocumentCore on Document {
    id
    draftId
    createdAt
    updatedAt
    publishedAt
    scheduledAt
    lastViewedAt
    title
    subtitle
    content
    contentLexical
    status
    type
    section
    variables
    urlSlug
    subject
    skipEmail
    article {
      id
      urlSlug
      publishedAt
    }
    thumbnailFile {
      url
      ...PerfImageFromFile
    }
    user {
      ...UserCore
    }
    lastUpdatedByUser {
      ...UserCore
    }
    lastViewedAtUser {
      ...UserCore
    }
    relatedData {
      ...DocumentRelatedDataCore
    }
  }
`);

gql(/* GraphQL */ `
  fragment DocumentTile on Document {
    id
    draftId
    createdAt
    updatedAt
    publishedAt
    scheduledAt
    lastViewedAt
    title
    subtitle
    content
    contentLexical
    status
    type
    section
    variables
    urlSlug
    subject
    skipEmail
    thumbnailFile {
      url
      ...PerfImageFromFile
    }
    # user {
    #   ...UserCore
    # }
    # lastUpdatedByUser {
    #   ...UserCore
    # }
    # lastViewedAtUser {
    #   ...UserCore
    # }
    # relatedData {
    #   ...DocumentRelatedDataCore
    # }
  }
`);

gql(/* GraphQL */ `
  fragment DocumentFull on Document {
    ...DocumentCore
  }
`);

const CREATE_DOCUMENT_MUTATION = gql(/* GraphQL */ `
  mutation createDocument($input: CreateDocumentInput!, $autoInsertRichTextBlock: Boolean = true) {
    createDocument(input: $input, autoInsertRichTextBlock: $autoInsertRichTextBlock) {
      ...DocumentFull
    }
  }
`);

const UPDATE_DOCUMENT_MUTATION = gql(/* GraphQL */ `
  mutation updateDocument($input: CreateDocumentInput!, $documentId: String!) {
    updateDocument(input: $input, documentId: $documentId) {
      ...DocumentFull
    }
  }
`);

const CREATE_DOCUMENT_BLOCK_MUTATION = gql(/* GraphQL */ `
  mutation createDocumentBlock($input: DocumentBlockInput!, $documentId: String!) {
    createDocumentBlock(input: $input, documentId: $documentId) {
      ...DocumentBlockCore
    }
  }
`);

const CREATE_DOCUMENT_RELATED_DATA_MUTATION = gql(/* GraphQL */ `
  mutation createDocumentRelatedData(
    $input: DocumentRelatedDataInput!
    $replaceIfExists: Boolean
    $documentId: String!
  ) {
    createDocumentRelatedData(
      input: $input
      documentId: $documentId
      replaceIfExists: $replaceIfExists
    ) {
      ...DocumentRelatedDataCore
      document {
        id
        relatedData {
          ...DocumentRelatedDataCore
        }
      }
    }
  }
`);

const UPDATE_DOCUMENT_RELATED_DATA_MUTATION = gql(/* GraphQL */ `
  mutation updateDocumentRelatedData(
    $input: UpdateDocumentRelatedDataInput!
    $documentRelatedDataId: String!
  ) {
    updateDocumentRelatedData(input: $input, documentRelatedDataId: $documentRelatedDataId) {
      ...DocumentRelatedDataCore
    }
  }
`);

const UPDATE_DOCUMENT_BLOCK_MUTATION = gql(/* GraphQL */ `
  mutation updateDocumentBlock($input: UpdateDocumentBlockInputType!, $documentBlockId: String!) {
    updateDocumentBlock(input: $input, documentBlockId: $documentBlockId) {
      ...DocumentBlockCore
    }
  }
`);

const DELETE_DOCUMENT_BLOCK_MUTATION = gql(/* GraphQL */ `
  mutation deleteDocumentBlock($documentBlockId: String!) {
    deleteDocumentBlock(documentBlockId: $documentBlockId)
  }
`);

const UPDATE_DOCUMENT_LAST_VIEWED_AT_MUTATION = gql(/* GraphQL */ `
  mutation updateDocumentLastViewedAt($documentId: String!) {
    updateDocumentLastViewedAt(documentId: $documentId) {
      ...DocumentFull
    }
  }
`);

const DELETE_DOCUMENT_RELATED_DATA_MUTATION = gql(/* GraphQL */ `
  mutation deleteDocumentRelatedData($documentRelatedDataId: String!) {
    deleteDocumentRelatedData(documentRelatedDataId: $documentRelatedDataId) {
      id
      relatedData {
        ...DocumentRelatedDataCore
      }
    }
  }
`);

const MOVE_DOCUMENT_BLOCK_POSITION_MUTATION = gql(/* GraphQL */ `
  mutation moveDocumentBlockPosition($blockId: String!, $newPosition: Int!) {
    moveDocumentBlockPosition(blockId: $blockId, newPosition: $newPosition)
  }
`);

const SEND_TEST_EMAIL = gql(/* GraphQL */ `
  mutation sendTestEmailForDocument($email: String!, $documentId: String!) {
    sendTestEmailForDocument(email: $email, documentId: $documentId)
  }
`);

const UPDATE_DOCUMENT_STATUS = gql(/* GraphQL */ `
  mutation updateDocumentStatus($documentId: String!, $status: String!) {
    updateDocumentStatus(documentId: $documentId, status: $status) {
      ...DocumentFull
    }
  }
`);

const DELETE_DOCUMENT = gql(/* GraphQL */ `
  mutation deleteDocument($documentId: String!) {
    deleteDocument(documentId: $documentId)
  }
`);

function addToArrayWithMaxLength(arr, item, maxLength) {
  if (arr.length >= maxLength) {
    arr.shift(); // Remove the oldest item (first element)
  }
  arr.push(item); // Add the new item to the end
  return arr;
}
