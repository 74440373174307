import { createFileRoute } from '@tanstack/react-router';
import { GetAskViewDocument } from 'shared/__generated__/graphql';
import AskViewPageContainer from './-ask-view-page-container';
import _ from 'lodash';
import { genMeta } from '../../utils/meta';

export const Route = createFileRoute('/ask/$promptId')({
  component: RouteComponent,
  staleTime: 60 * 1000,
  head: ({ loaderData }) => {
    const prompt = loaderData?.prompt;
    if (!prompt) {
      return genMeta({
        title: 'NOT FOUND',
      });
    }
    return genMeta({
      title: `ASK | ${prompt.title.toUpperCase()}`,
      description: _.truncate(prompt.content, { length: 155 }),
    });
  },
  loader: async ({ params, context }) => {
    const { data } = await context.apolloClient.query({
      query: GetAskViewDocument,
      variables: { id: params.promptId },
    });
    return {
      promptId: params.promptId,
      prompt: data.prompt,
    };
  },
});

function RouteComponent() {
  const { promptId } = Route.useParams();
  const { prompt } = Route.useLoaderData();
  return <AskViewPageContainer id={promptId} initialAskData={prompt} />;
}
