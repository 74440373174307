'use client';

import React, { useEffect } from 'react';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { Box } from '@chakra-ui/react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import {
  PromptItemFragment,
  PromptItemFragmentDoc,
  PromptType,
} from '../../../../../../__generated__/graphql';
import { useFragment } from '@apollo/client';
import LexicalSelectionHandler from './LexicalSelectionHandler';
import { useDelete } from './hooks/useHelperHooks';
import { usePathname } from 'shared/misc/hooks/routerHooks';
import PromptItem from '../../../../by-type/prompts/PromptItem';

type Props = {
  id: string;
  prompt: PromptItemFragment;
  nodeKey: string;
  updatePrompt: (draft: PromptItemFragment) => void;
};

function LexicalPromptComponent({ prompt, updatePrompt, nodeKey }: Props) {
  const isEditable = useLexicalEditable();
  const [editor] = useLexicalComposerContext();

  const { data: promptFragment, complete } = useFragment({
    fragment: PromptItemFragmentDoc,
    fragmentName: 'PromptItem',
    from: {
      __typename: 'Prompt',
      id: prompt.id,
    },
  });

  // console.log({ promptFragment, prompt });

  useEffect(() => {
    if (promptFragment?.id && complete) {
      editor.update(() => {
        updatePrompt(promptFragment);
      });
    }
  }, [promptFragment, complete]);

  const finalPrompt = promptFragment?.id && complete ? promptFragment : prompt;

  const primaryNode = <PromptItem prompt={finalPrompt} noOfLines={100} />;

  useDelete(nodeKey);

  const pathname = usePathname();

  const content = isEditable ? (
    <LexicalSelectionHandler nodeKey={nodeKey}>{primaryNode}</LexicalSelectionHandler>
  ) : (
    primaryNode
  );

  if (
    finalPrompt.type === PromptType.UnpublishedPostPrompt &&
    !isEditable &&
    !pathname.includes('draft')
  ) {
    return null;
  }

  return (
    <Box width="100%" maxWidth="100%" alignSelf="center">
      {content}
    </Box>
  );
}

export default LexicalPromptComponent;
