import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { useMutation } from '@apollo/client';
import { Box, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendEmailCodeSchema, signInViaEmailCodeSchema } from 'shared/validation/schemas';
import PerfImage from 'shared/misc/components/util/PerfImage';
import PerfInput from 'shared/misc/components/util/PerfInput';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { gql } from 'shared/__generated__/gql';
import { userExistsWithEmail } from '../../actions/userExistsWithEmail';
import { Button as TWButton } from '../../components/button';
import { z } from 'zod';
import { getSessionUser } from '../../actions/getSessionUser';

type InputsSendEmail = {
  email: string;
};

type InputsSignInWithCode = {
  code: string;
};

const SEND_VERIFY_EMAIL = gql(/* GraphQL */ `
  mutation sendVerifyEmail($email: String!, $destination: String) {
    sendVerifyEmail(email: $email, destination: $destination)
  }
`);

const SIGN_IN_VIA_CODE = gql(/* GraphQL */ `
  mutation verifyEmailViaCode($code: String!) {
    verifyEmailViaCode(code: $code) {
      token
      destination
    }
  }
`);
export const Route = createFileRoute('/verify-another-email/')({
  component: RouteComponent,
  validateSearch: z.object({
    destination: z.string().optional(),
  }),
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      return redirect({ to: '/sign-in' });
    }
  },
});

function RouteComponent() {
  const router = useRouter();
  const [isCodeForm, setIsCodeForm] = useState(false);
  const toast = useToast();
  const [sendSignInEmail, { loading: sendSignInEmailLoading }] = useMutationWithAutoToast(
    SEND_VERIFY_EMAIL,
    {
      successMessage: 'Email sent.',
    },
  );

  const [verifyEmailViaCode, { loading: verifyEmailViaCodeLoading }] =
    useMutation(SIGN_IN_VIA_CODE);

  const { updateStep } = useUpdateSetupStep();

  const { destination } = Route.useSearch();

  const loading = sendSignInEmailLoading || verifyEmailViaCodeLoading;

  const signInViaEmailCodeForm = useForm<InputsSignInWithCode>({
    resolver: yupResolver(signInViaEmailCodeSchema),
  });

  const sendSignInEmailCodeForm = useForm<InputsSendEmail>({
    resolver: yupResolver(sendEmailCodeSchema),
  });

  const email = sendSignInEmailCodeForm.watch().email;

  const sendEmail = async () => {
    await sendSignInEmail({
      variables: { email, destination },
      optimisticResponse: {
        sendVerifyEmail: true,
      },
    });

    setIsCodeForm(true);
  };

  const onEmailCodeSubmit: SubmitHandler<InputsSignInWithCode> = async (values, event) => {
    event?.preventDefault();

    await verifyEmailViaCode({
      variables: { code: values.code },
      onCompleted: async (data) => {
        toast({
          title: 'Email verified',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        const dest = decodeURIComponent(destination || data.verifyEmailViaCode.destination || '');
        if (dest) {
          if (dest === '/sign-up/premium') {
            await updateStep(USER_SIGN_UP_STEP.PREMIUM);
          } else if (dest) {
            router.navigate({ to: dest });
          }
        }
        router.navigate({ to: '/' });
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const onSendEmailSubmit: SubmitHandler<InputsSendEmail> = async (values, event) => {
    event?.preventDefault();
    const emailExists = await userExistsWithEmail({ data: values });

    if (emailExists) {
      sendSignInEmailCodeForm.setError('email', {
        message: 'Existing account with this email.',
      });
      return;
    }

    await sendEmail();
  };

  const onSubmit = (e, ...args) => {
    e.preventDefault();
    if (isCodeForm) {
      return signInViaEmailCodeForm.handleSubmit(onEmailCodeSubmit)(e, ...args);
    } else {
      return sendSignInEmailCodeForm.handleSubmit(onSendEmailSubmit)(e, ...args);
    }
  };

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '50px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p="48px"
        >
          <form onSubmit={onSubmit}>
            <Stack spacing="24px" w="100%" maxW="300px">
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">Verify another email</Text>
              <Stack spacing="12px">
                {!isCodeForm ? (
                  <PerfInput
                    key="email"
                    placeholder="Email"
                    {...sendSignInEmailCodeForm.register('email')}
                    error={sendSignInEmailCodeForm.formState.errors.email}
                  />
                ) : (
                  <PerfInput
                    key="code"
                    placeholder="Code"
                    {...signInViaEmailCodeForm.register('code')}
                    error={signInViaEmailCodeForm.formState.errors.code}
                  />
                )}
              </Stack>
              <Stack spacing="24px">
                <HStack spacing="6px">
                  <TWButton type="submit" variant="primary" isLoading={loading}>
                    Submit
                  </TWButton>
                  {!isCodeForm && (
                    <TWButton variant="secondary" onClick={() => setIsCodeForm(true)}>
                      Already Have a code?
                    </TWButton>
                  )}
                  {isCodeForm && (
                    <TWButton variant="secondary" onClick={() => setIsCodeForm(false)}>
                      Back to email?
                    </TWButton>
                  )}
                  {isCodeForm && (
                    <TWButton variant="secondary" onClick={() => sendEmail()}>
                      Resend
                    </TWButton>
                  )}
                </HStack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </CenterPage>
    </>
  );
}
