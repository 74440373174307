'use client';

import { Box, Icon, Text } from '@chakra-ui/react';
import { UserCoreFragment } from '../../../../__generated__/graphql';
import React from 'react';
import { HiOutlineStatusOnline, HiOutlineStatusOffline } from 'react-icons/hi';

export const UserOnlineIndicator = ({
  user,
  showOffline = true,
}: {
  user: Pick<UserCoreFragment, 'isOnline'>;
  showOffline?: boolean;
}) =>
  user.isOnline || showOffline ? (
    <span
      // display="flex"
      // backgroundColor="brand.background"
      // border="1px dashed"
      // borderColor="brand.highlight"
      // color="brand.highlight"
      // padding="2px 6px"
      // textStyle="brand.actionSm"
      // textTransform="uppercase"
      // alignSelf="flex-start"
      // textAlign="right"
      // fontSize="14px"
      className="flex items-center typography-action-sm text-brand-highlight bg-brand-background border border-dashed border-brand-highlight p-[2px] px-[6px]"
    >
      <Icon
        mr="2px"
        as={user.isOnline ? HiOutlineStatusOnline : HiOutlineStatusOffline}
        size={20}
      />
      {user.isOnline ? 'ONLINE NOW' : 'OFFLINE'}
    </span>
  ) : (
    <div className="h-[25px]" />
  );
