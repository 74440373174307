import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import ThemeAwareStar from 'shared/misc/components/ThemeAwareStar';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  SEND_SIGN_IN_EMAIL,
  SIGN_IN_VIA_CODE,
  SIGN_IN_VIA_PASSWORD,
} from 'shared/misc/graphql/SignInViewFragments';
import { useSearchParams } from 'shared/misc/hooks/routerHooks';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import {
  signInSchema,
  signInViaEmailCodeSchema,
  sendEmailCodeSchema,
} from 'shared/validation/schemas';
import { TWLink } from '../../components/link';
import { Button as TWButton } from '../../components/button';
import { getSessionUser } from '../../actions/getSessionUser';
import { useToast } from '@chakra-ui/react';
import { userExistsWithUsername } from '../../actions/isUniqueUsername';
import { userExistsWithEmail } from '../../actions/userExistsWithEmail';

type Inputs = {
  username: string;
  password: string;
};

type InputsSendEmail = {
  email: string;
};

type InputsSignInWithCode = {
  code: string;
};

export const Route = createFileRoute('/sign-in/')({
  component: RouteComponent,
  beforeLoad: async () => {
    if (await getSessionUser()) {
      throw redirect({ to: '/' });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Sign In',
      },
    ],
  }),
});

function RouteComponent() {
  const searchParams = useSearchParams();
  const emailFromParams = searchParams.get('email');
  const destination = searchParams.get('destination');
  const context = searchParams.get('context');

  const isEmailSignInInitial = context === 'email-activate' || context === 'email-sign-in' || true;

  const [isEmailSignIn, setIsEmailSignIn] = useState<boolean>(
    !!emailFromParams || isEmailSignInInitial,
  );
  const [isCodeForm, setIsCodeForm] = useState(false);

  const { signIn } = useAuth();

  const toast = useToast();

  const [sendSignInEmail, { loading: sendSignInEmailLoading }] = useMutationWithAutoToast(
    SEND_SIGN_IN_EMAIL,
    {
      successMessage: 'Email sent.',
    },
  );

  const [verifyEmailAndSignInViaCode] = useMutation(SIGN_IN_VIA_CODE);

  const [signInViaPassword, { loading: signInViaPasswordLoading }] =
    useMutation(SIGN_IN_VIA_PASSWORD);

  const router = useRouter();
  const loading = signInViaPasswordLoading || sendSignInEmailLoading;

  const signInViaPasswordForm = useForm<Inputs>({
    resolver: yupResolver(signInSchema),
  });

  const signInViaEmailCodeForm = useForm<InputsSignInWithCode>({
    resolver: yupResolver(signInViaEmailCodeSchema),
  });

  const sendSignInEmailCodeForm = useForm<InputsSendEmail>({
    resolver: yupResolver(sendEmailCodeSchema),
    defaultValues: { email: emailFromParams || '' },
  });

  const email = sendSignInEmailCodeForm.watch().email;

  const onPasswordSubmit: SubmitHandler<Inputs> = async (values, event) => {
    event?.preventDefault();

    const usernameExists = await userExistsWithUsername(values);

    if (!usernameExists) {
      signInViaPasswordForm.setError('username', { message: `Username doesn't exist.` });
      return;
    }

    signInViaPassword({
      variables: { input: values },
      onCompleted: async (data) => {
        await signIn(data.signInViaPassword);

        if (destination) {
          window.location.href = decodeURIComponent(destination);
        } else {
          router.navigate({ to: '/' });
        }
      },
      onError: (e) => {
        if (e.graphQLErrors[0]?.extensions?.surfaceToUser) {
          toast({
            title: e.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        } else {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      },
    });
  };

  const sendEmail = async () => {
    await sendSignInEmail({
      variables: { email },
      optimisticResponse: {
        sendSignInLinkViaEmail: true,
      },
    });

    setIsCodeForm(true);
  };

  const onEmailCodeSubmit: SubmitHandler<InputsSignInWithCode> = async (values, event) => {
    event?.preventDefault();

    await verifyEmailAndSignInViaCode({
      variables: { code: values.code },
      onCompleted: async (data) => {
        setIsEmailSignIn(true);
        const me = await signIn(data.verifyEmailAndSignInViaCode);
        if (me.signUpStep === USER_SIGN_UP_STEP.MORE) {
          // !TODO: this is going to continue to error until the sign up page is migrated
          router.navigate({ to: '/sign-up/more' });
        } else {
          if (destination) {
            window.location.href = decodeURIComponent(destination);
          } else {
            router.navigate({ to: '/' });
          }
        }
      },
      onError: (e) => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const onSendEmailSubmit: SubmitHandler<InputsSendEmail> = async (values, event) => {
    event?.preventDefault();

    const emailExists = await userExistsWithEmail({ data: { email: values.email } });

    if (!emailExists) {
      sendSignInEmailCodeForm.setError('email', { message: 'No account with this email.' });
      return;
    }

    await sendEmail();
  };

  const onSubmit = (...args) => {
    if (isCodeForm) {
      return signInViaEmailCodeForm.handleSubmit(onEmailCodeSubmit)(...args);
    } else if (isEmailSignIn) {
      return sendSignInEmailCodeForm.handleSubmit(onSendEmailSubmit)(...args);
    } else {
      return signInViaPasswordForm.handleSubmit(onPasswordSubmit)(...args);
    }
  };

  useAutoSetTheme(ProfileThemeType.PiClassic);

  return (
    <>
      <div
        className="flex justify-center items-start min-h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('https://files.pi.fyi/star-background.png')" }}
      >
        <div className="border border-dashed border-brand-main m-2 mt-32 lg:p-12 w-full max-w-[500px] ">
          <form onSubmit={onSubmit}>
            <div className="space-y-8 w-full">
              <ThemeAwareStar />
              <p className="typography-heading-md sm:typography-heading-md">
                Sign in via {isEmailSignIn ? 'email' : 'password'}.
              </p>
              <div className="space-y-3">
                {!isEmailSignIn ? (
                  <>
                    <PerfInput
                      key="username"
                      placeholder="Username"
                      {...signInViaPasswordForm.register('username')}
                      error={signInViaPasswordForm.formState.errors.username}
                      className="typography-action-sm md:typography-heading-sm"
                    />
                    <PerfInput
                      placeholder="Password"
                      type="password"
                      {...signInViaPasswordForm.register('password')}
                      error={signInViaPasswordForm.formState.errors.password}
                      className="typography-action-sm md:typography-heading-sm"
                    />
                  </>
                ) : (
                  <>
                    {!isCodeForm ? (
                      <>
                        <PerfInput
                          key="email"
                          placeholder="Email"
                          {...sendSignInEmailCodeForm.register('email')}
                          error={sendSignInEmailCodeForm.formState.errors.email}
                          className="typography-action-sm md:typography-heading-sm"
                        />
                        {context === 'existing-account' && (
                          <p className="text-body font-normal">
                            There's an existing account with the email you just tried to sign up
                            with. Click submit below to be sent a link to log in.
                          </p>
                        )}
                      </>
                    ) : (
                      <PerfInput
                        key="code"
                        placeholder="Code"
                        className="typography-action-sm  md:typography-heading-sm"
                        {...signInViaEmailCodeForm.register('code')}
                        error={signInViaEmailCodeForm.formState.errors.code}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="space-y-6">
                <div className="flex space-x-1">
                  <TWButton
                    type="submit"
                    variant="primary"
                    className="typography-action-sm md:typography-heading-sm"
                    disabled={loading}
                  >
                    {isEmailSignIn && !isCodeForm ? 'Send Magic Link' : 'Submit'}
                  </TWButton>
                  {isEmailSignIn && !isCodeForm && (
                    <TWButton
                      className="typography-action-sm md:typography-heading-sm"
                      variant="secondary"
                      onClick={() => setIsCodeForm(true)}
                    >
                      enter code
                    </TWButton>
                  )}
                  {isEmailSignIn && isCodeForm && (
                    <TWButton variant="textOnly" onClick={() => setIsCodeForm(false)}>
                      Back to email?
                    </TWButton>
                  )}
                  {isEmailSignIn && isCodeForm && (
                    <TWButton variant="textOnly" onClick={() => sendEmail()}>
                      Resend
                    </TWButton>
                  )}
                </div>
                {!isEmailSignInInitial || true ? (
                  <div className="space-y-1">
                    <p className="typography-body-sm text-secondaryText text-brand-lightgrey">
                      Prefer{' '}
                      <TWButton
                        variant="textOnly"
                        className="typography-lightgrey border-b"
                        onClick={() => setIsEmailSignIn((prev) => !prev)}
                      >
                        {isEmailSignIn ? 'password' : 'email'}
                      </TWButton>
                      ?
                    </p>
                    <p className="typography-body-sm text-secondaryText text-brand-lightgrey">
                      New to PI.FYI?{' '}
                      <TWLink to="/sign-up" className="typography-highlight">
                        Sign up
                      </TWLink>
                      .
                    </p>
                  </div>
                ) : (
                  <p className="typography-body-sm text-secondaryText text-brand-lightgrey">
                    Confused?{' '}
                    <TWLink to="/what-is-this" className="typography-highlight">
                      Learn more
                    </TWLink>
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
