import { createFileRoute } from '@tanstack/react-router';
import { createServerFn } from '@tanstack/react-start';
import ActivateAccount from './-activate-account';
import { decrypt } from 'shared/crypto';
import prisma from 'database';

const getUserFromKey = createServerFn({ method: 'GET' })
  .validator((data: string) => decrypt(decodeURIComponent(data)))
  .handler(async ({ data }) => {
    try {
      const user = await prisma.user.findUniqueOrThrow({
        where: { username: data },
        include: {
          followers: true,
          guest: {
            include: {
              features: {
                include: {
                  thumbnailFile: { include: { file: true } },
                  bannerFile: { include: { file: true } },
                },
              },
            },
          },
        },
      });
      return user;
    } catch (e) {
      console.log(e);
    }
  });

export const Route = createFileRoute('/activate/$activationKey')({
  component: RouteComponent,
  loader: ({ params: { activationKey } }) => getUserFromKey({ data: activationKey }),
});

function RouteComponent() {
  const user = Route.useLoaderData();
  const key = Route.useParams().activationKey;
  return <ActivateAccount user={user} secretKey={key} />;
}
