import { createFileRoute, redirect } from '@tanstack/react-router';
import MonthlyArchivePicksView from './-MonthlyArchivePicksView';
import { getSessionUser } from '../../../../actions/getSessionUser';

export const Route = createFileRoute('/admin/dashboard/monthly-archival-picks/')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if ((!user || user.role !== 'ADMIN') && process.env['NODE_ENV'] === 'production') {
      throw redirect({ to: '/' });
    }
  },
});

function RouteComponent() {
  return <MonthlyArchivePicksView />;
}
