export enum GlobalWebViewObjects {
  AddRecommendationProps = 'AddRecommendationProps',
  AddCommentProps = 'AddCommentProps',

  UpdateRecommendationProps = 'UpdateRecommendationProps',
  UpdatePromptProps = 'UpdatePromptProps',
}

const WINDOW_EXISTS = typeof window !== 'undefined';

export function safeSendWebViewMessage(msg: string) {
  if (WINDOW_EXISTS && 'ReactNativeWebView' in window && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(msg);
  }
}

export function safeSendMessageToReactNativeFromWebView(msg: string, value: any) {
  if (WINDOW_EXISTS && 'ReactNativeWebView' in window && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        msg,
        value,
      }),
    );
  }
}

export function safeSendMessageToWebViewFromReactNative(webViewRef: any, msg: string, value: any) {
  webViewRef?.getRef()?.postMessage(
    JSON.stringify({
      msg,
      value,
    }),
  );
}

export function isInWebView() {
  return WINDOW_EXISTS && 'IS_REACT_NATIVE_WEB_VIEW' in window && window.IS_REACT_NATIVE_WEB_VIEW;
}

export function shouldAddSafePaddingTop() {
  return WINDOW_EXISTS && 'ADD_SAFE_PADDING_TOP' in window && window.ADD_SAFE_PADDING_TOP;
}

export function getGlobalWebViewObject(key: GlobalWebViewObjects) {
  try {
    return WINDOW_EXISTS && key in window && window[key];
    //  JSON.parse(window[key as string]);
  } catch (e) {
    console.log(e);
    return {};
  }
}
