import { add, sub } from 'date-fns';
import {
  FullFeedItemFragment,
  SidebarFeedItemFragment,
  FeedItemType,
} from 'shared/__generated__/graphql';

export type ValidFeedItem = SidebarFeedItemFragment | FullFeedItemFragment;
export type MergableFeedItemResult = ValidFeedItem | MergedFeedItem;

export interface MergedFeedItem<TFeedItemType extends ValidFeedItem = ValidFeedItem> {
  isMerged: true;
  index: number;
  type: MergedFeedItemType;
  feedItems: TFeedItemType[];
  id: TFeedItemType['id'];
  createdAt: TFeedItemType['createdAt'];
  originUser?: TFeedItemType['originUser'];
  targetUser?: TFeedItemType['targetUser'];
  targetRec?: TFeedItemType['targetRec'];
  originGuest?: TFeedItemType['originGuest'];
  targetFeature?: TFeedItemType['targetFeature'];
  targetList?: TFeedItemType['targetList'];
  targetComment?: TFeedItemType['targetComment'];
  targetPrompt?: TFeedItemType['targetPrompt'];
}

export enum MergedFeedItemType {
  MERGED_NEW_USER_FOLLOWS = 'MERGED_NEW_USER_FOLLOWS',
  MERGED_NEW_REC_ENDORSE = 'MERGED_NEW_REC_ENDORSE',
  MERGED_NEW_REC_ENDORSE_BY_USER = 'MERGED_NEW_REC_ENDORSE_BY_USER',
  MERGED_RE_REC = 'MERGED_RE_REC',
  MERGED_COMMENT = 'MERGED_COMMENT',
  MERGED_COMMENT_FROM_SAME_USER = 'MERGED_COMMENT_FROM_SAME_USER',
  MERGED_PROMPT_REPLY = 'MERGED_PROMPT_REPLY',
}

export const DefaultMergableTypeMap = {
  [FeedItemType.NewUserFollow]: MergedFeedItemType.MERGED_NEW_USER_FOLLOWS,
  [FeedItemType.NewRecEndorse]: MergedFeedItemType.MERGED_NEW_REC_ENDORSE_BY_USER,
  [FeedItemType.NewRePost]: MergedFeedItemType.MERGED_RE_REC,
  [FeedItemType.NewComment]: MergedFeedItemType.MERGED_COMMENT_FROM_SAME_USER,
  [FeedItemType.NewPromptRecReply]: MergedFeedItemType.MERGED_PROMPT_REPLY,
  [FeedItemType.AddRecommendation]: undefined,
};

interface DefaultMergableTypeMapInterface {
  [k: FeedItemType]: MergedFeedItemType;
}

export function combineRelatedFeedItems(
  feedItems: ValidFeedItem[],
  enabledMerges: Set<MergedFeedItemType> = new Set([
    MergedFeedItemType.MERGED_NEW_USER_FOLLOWS,
    // MergedFeedItemType.MERGED_NEW_REC_ENDORSE_BY_USER,
    MergedFeedItemType.MERGED_COMMENT,
    MergedFeedItemType.MERGED_PROMPT_REPLY,
    // MergedFeedItemType.MERGED_RE_REC,
  ]),
  MergableTypeMap: DefaultMergableTypeMapInterface = DefaultMergableTypeMap,
  lastPage = new Date(),
): MergableFeedItemResult[] {
  const final = [] as MergableFeedItemResult[];

  const skips = new Set<string>([]);

  for (let i = 0; i < feedItems.length; i++) {
    const feedItem = feedItems[i];

    if (skips.has(feedItem.id) || new Date(feedItem.createdAt) > lastPage) {
      continue;
    }

    const mergeableType = MergableTypeMap[feedItem.type];

    if (mergeableType && enabledMerges.has(mergeableType)) {
      const timeWindowHigh = add(new Date(feedItem.createdAt), { minutes: 240 });
      const timeWindowLow = sub(new Date(feedItem.createdAt), { minutes: 240 });

      const mergeItem: MergedFeedItem = {
        ...feedItem,
        index: i,
        isMerged: true,
        type: mergeableType,
        id: feedItem.id,
        originUser: feedItem.originUser,
        createdAt: feedItem.createdAt,
        feedItems: [feedItem],
      };

      for (let j = i + 1; j < feedItems.length; j++) {
        const maybeMergeFeedItem = feedItems[j];
        const maybeMergeableType = MergableTypeMap[maybeMergeFeedItem.type];
        const maybeMergeDate = new Date(maybeMergeFeedItem.createdAt);

        if (
          maybeMergeDate > timeWindowHigh ||
          maybeMergeDate < timeWindowLow ||
          !feedItem.originUser ||
          !maybeMergeFeedItem.originUser ||
          !mergeItem.originUser
        ) {
          break;
        }

        if (
          maybeMergeableType === mergeableType &&
          maybeMergeDate > timeWindowLow &&
          maybeMergeDate < timeWindowHigh
        ) {
          const addToMerged = () => {
            skips.add(maybeMergeFeedItem.id);
            // mergeItem.createdAt = maybeMergeFeedItem.createdAt;
            mergeItem.feedItems.push(maybeMergeFeedItem);
          };
          if (mergeableType === MergedFeedItemType.MERGED_NEW_USER_FOLLOWS) {
            if (feedItem.originUser.id === maybeMergeFeedItem.originUser.id) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.originUser = feedItem.originUser;
              // addToMerged();
            }
          } else if (mergeableType === MergedFeedItemType.MERGED_NEW_REC_ENDORSE) {
            if (
              mergeItem.targetRec &&
              maybeMergeFeedItem.targetRec?.id === mergeItem.targetRec.id
            ) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.targetRec = feedItem.targetRec;
              // addToMerged();
            }
          } else if (mergeableType === MergedFeedItemType.MERGED_RE_REC) {
            if (
              mergeItem.targetRec &&
              (maybeMergeFeedItem.targetRec?.id === mergeItem.targetRec.id ||
                maybeMergeFeedItem.targetRec?.id === mergeItem.targetRec?.repostTargetRec?.id ||
                maybeMergeFeedItem.targetRec?.repostTargetRec?.id === mergeItem.targetRec?.id ||
                maybeMergeFeedItem.targetRec?.repostTargetRec?.id ===
                  mergeItem.targetRec?.repostTargetRec?.id)
            ) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.targetRec = maybeMergeFeedItem.targetRec;
              // addToMerged();
            }
          } else if (mergeableType === MergedFeedItemType.MERGED_NEW_REC_ENDORSE_BY_USER) {
            if (feedItem.originUser.id === maybeMergeFeedItem.originUser.id) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.originUser = feedItem.originUser;
              // addToMerged();
            }
          } else if (mergeableType === MergedFeedItemType.MERGED_COMMENT_FROM_SAME_USER) {
            if (
              mergeItem.targetRec &&
              maybeMergeFeedItem.targetRec?.id === mergeItem.targetRec.id &&
              maybeMergeFeedItem.originUser.id === mergeItem.originUser.id
            ) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.targetRec = feedItem.targetRec;
              maybeMergeFeedItem.originUser = mergeItem.originUser;
              // addToMerged();
            }
          } else if (
            mergeableType === MergedFeedItemType.MERGED_COMMENT ||
            mergeableType === MergedFeedItemType.MERGED_PROMPT_REPLY
          ) {
            if (
              mergeItem.targetRec &&
              maybeMergeFeedItem.targetRec?.id === mergeItem.targetRec.id
            ) {
              addToMerged();
            } else if (mergeItem.feedItems.length === 0) {
              mergeItem.targetRec = feedItem.targetRec;
              // addToMerged();
            }
          } else if (
            mergeItem.targetPrompt &&
            maybeMergeFeedItem.targetPrompt?.id === mergeItem.targetPrompt.id
          ) {
            addToMerged();
          } else if (mergeItem.feedItems.length === 0) {
            mergeItem.targetPrompt = feedItem.targetPrompt;
            // addToMerged();
          }
        }
      }

      if (mergeItem.feedItems.length > 1) {
        final.push(mergeItem);
      } else {
        final.push(feedItem);
      }
    } else {
      final.push(feedItem);
    }
  }

  return final;
}
