import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// default base style from the Input theme
const baseStyle = definePartsStyle({
  field: {
    fontWeight: 'normal',
    fontSize: '16px',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    border: '1px solid',
    borderRadius: 'none',
    borderColor: 'brand.main',
    textStyle: 'brand.actionLg',
    _placeholder: {
      color: 'brand.lightgrey',
      opacity: '60%',
    },
  },
});

const variantOutline = definePartsStyle(() => {
  return {
    field: {
      color: 'brand.main',
      width: '100%',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      border: '1px solid',
      borderColor: 'brand.main',
      borderRadius: 'none',
      textStyle: 'brand.actionLg',
      _hover: {
        borderColor: 'brand.highlight',
      },
      _focus: {
        borderColor: 'brand.highlight',
        outlineOffset: '-1px',
        outline: '1px solid',
        outlineColor: 'brand.highlight',
        boxShadow: 'none',
      },
      focusBorderColor: 'brand.highlight',
    },
  };
});

const title = definePartsStyle(() => {
  return {
    field: {
      color: 'brand.black',
      width: '100%',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      border: '1px solid transparent',
      borderColor: 'transparent',
      textStyle: 'brand.actionLg',
      paddingLeft: '0px',
      _focus: {
        paddingBottom: '-1px',
        // borderColor: 'brand.highlight',
        // border: '1px solid transparent',
        // borderBottom: '1px solid',
        // borderBottomColor: 'brand.highlight',
        boxShadow: 'none',
      },

      fontFamily: 'brand.arialNarrowBold',
      fontSize: ['22px', '36px'],
      fontWeight: 'bold',
      lineHeight: ['115%', '100%'],
      letterSpacing: '1%',
      textTransform: 'uppercase',
    },
  };
});

const underline = definePartsStyle(() => {
  return {
    field: {
      background: 'transparent !important',
      color: 'brand.main',
      width: '100%',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      borderTop: 'none',
      borderBottom: '1px solid rgba(255,255,255,0.4)',
      borderLeft: 'none',
      borderRight: 'none',
      borderColor: 'rgba(255, 255, 255, 0.4)',
      textStyle: 'brand.bodySm',
      paddingLeft: '0px',
      fontFamily: 'brand.abcFavorite',
      textTransform: 'none',
      fontSize: '24px',
      lineHeight: '100%',
      letterSpacing: '1%',
      _focus: {
        borderBottom: '1px solid rgba(255,255,255,0.6)',
        borderBottomColor: 'rgba(255,255,255,0.6)',
      },
      _autofill: {
        color: 'brand.main',
        backgroundColor: 'transparent !important',
      },
    },
  };
});

const admin = definePartsStyle(() => {
  return {
    field: {
      color: 'brand.black',
      width: '100%',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      border: '1px solid',
      borderColor: 'brand.black',
      borderRadius: 'none',
      textStyle: 'brand.actionLg',
      _focus: {
        borderColor: 'brand.black',
        outlineOffset: '-1px',
        outline: '1px solid',
        outlineColor: 'brand.black',
        boxShadow: 'none',
      },
      focusBorderColor: 'brand.black',
    },
  };
});

const variants = {
  outline: variantOutline,
  title,
  underline,
  admin,
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline',
    // focusBorderColor: 'brand.highlight',
  },
});
