import { extendTheme } from '@chakra-ui/react';
import { PerfectlyImperfectTheme } from '../theme';
import { ProfileThemeType } from '../../../__generated__/graphql';
import {
  GREEN_RED_COLORS,
  WHITE_PINK_BLUE_COLORS,
  UPS_COLORS,
  PI_CLASSIC_COLORS,
  JOHANNA_COLORS,
  RED_PINK_COLORS,
  REGGAE_COLORS,
  IRIS_COLORS,
  BEN_COLORS,
  FIONA_COLORS,
  MICHAEL_COLORS,
  WILL_COLORS,
  JENNIE_COLORS,
  ThemeColorMap,
} from './custom-theme-colors';

export function createThemeFromColors(colors) {
  return extendTheme(
    {
      colors: {
        brand: {
          ...colors,
        },
      },
      styles: {
        global: () => ({
          'html, body': {
            fontFamily: 'brand.arial',
            color: 'brand.main',
            bg: 'brand.background',
          },
        }),
      },
      layerStyles: {
        secondaryText: {
          color: 'lightgrey',
        },
      },
    },
    PerfectlyImperfectTheme,
  );
}

export function getThemeFromThemeName(themeName: ProfileThemeType) {
  const colors = ThemeColorMap[themeName];
  if (colors) return createThemeFromColors(colors);
  return PerfectlyImperfectTheme;
}
