import { create } from 'zustand';
import { useCallback, useMemo } from 'react';

export enum ModalNames {
  ADD_RECOMMENDATION = 'ADD_RECOMMENDATION',
  ADD_ASK = 'ADD_ASK',
  UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO',
  FINISH_SIGN_UP = 'FINISH_SIGN_UP',
  SIMPLE_SUBSCRIBE = 'SIMPLE_SUBSCRIBE',
  ADMIN_SELECT_OR_CREATE_USER = 'ADMIN_SELECT_OR_CREATE_USER',
  ADMIN_EDIT_USER = 'ADMIN_EDIT_USER',
  AUTO_SUGGEST_EMAIL_SIGN_IN = 'AUTO_SUGGEST_EMAIL_SIGN_IN',
  ADMIN_SEND_MERCH = 'ADMIN_SEND_MERCH',
  MANAGE_LINKS = 'MANAGE_LINKS',
}

interface ModalStore {
  modals: Record<ModalNames, boolean>;
  openModal: (modal: ModalNames) => void;
  closeModal: (modal: ModalNames) => void;
  toggleModal: (modal: ModalNames) => void;
}

const initialModalsState: Record<ModalNames, boolean> = {
  [ModalNames.ADD_RECOMMENDATION]: false,
  [ModalNames.ADD_ASK]: false,
  [ModalNames.UPDATE_PROFILE_PHOTO]: false,
  [ModalNames.FINISH_SIGN_UP]: false,
  [ModalNames.SIMPLE_SUBSCRIBE]: false,
  [ModalNames.ADMIN_SELECT_OR_CREATE_USER]: false,
  [ModalNames.ADMIN_EDIT_USER]: false,
  [ModalNames.AUTO_SUGGEST_EMAIL_SIGN_IN]: false,
  [ModalNames.ADMIN_SEND_MERCH]: false,
  [ModalNames.MANAGE_LINKS]: false,
};

export const useModalStore = create<ModalStore>((set) => ({
  modals: initialModalsState,
  openModal: (modal: ModalNames) =>
    set((state) => ({
      modals: { ...state.modals, [modal]: true },
    })),
  closeModal: (modal: ModalNames) =>
    set((state) => ({
      modals: { ...state.modals, [modal]: false },
    })),
  toggleModal: (modal: ModalNames) =>
    set((state) => ({
      modals: { ...state.modals, [modal]: !state.modals[modal] },
    })),
}));

export function useModalState(modalName: ModalNames) {
  const isOpen = useModalStore((state) => state.modals[modalName]);
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const toggleModal = useModalStore((state) => state.toggleModal);

  const onOpen = useCallback(() => openModal(modalName), [openModal, modalName]);
  const onClose = useCallback(() => closeModal(modalName), [closeModal, modalName]);
  const onToggle = useCallback(() => toggleModal(modalName), [toggleModal, modalName]);

  const memoized = useMemo(
    () => ({ isOpen, onOpen, onClose, onToggle }),
    [isOpen, onOpen, onClose, onToggle],
  );

  return memoized;
}
