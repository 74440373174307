import React from 'react';
import { gql } from 'shared/__generated__';
import BasicEditor from '../rich-text/basic/basic-editor';
import ExpandableTextContainer from '../text/expandable-text-container';
import { recBodyTheme } from 'shared/misc/theme/selectors/lexicalStyles';
import { PromptItemFragment, PromptItemFragmentDoc } from 'shared/__generated__/graphql';
import PromptActions from './parts/prompt-actions';
import PromptItemBottom from './parts/prompt-item-bottom';
import OverlapTitle from 'shared/misc/components/OverlapTitle';
import { cn } from '../../../utils/cn';
import { TWLink } from '../../../components/link';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { useFragment } from '@apollo/client';

gql(/* GraphQL */ `
  fragment PromptItem on Prompt {
    id
    title
    date
    type
    content
    contentLexical
    emoji
    createdAt
    updatedAt
    isHidden
    muted
    recsCount
    isViewerSubscribed
    isEndorsed
    endorsementCount
    user {
      ...UserCore
    }
  }
`);

export type PromptItemProps = {
  prompt: PromptItemFragment;
  withBorder?: boolean;
  noOfLines?: number;
  withResponses?: boolean;
  withLessPadding?: boolean;
  mode?: PromptItemMode;
  overlapTitle?: string;
  verticallyCentered?: boolean;
  withSpecialBorder?: boolean;
  isInline?: boolean;
  titleFontSize?: string;
};

export enum PromptItemMode {
  DEFAULT,
  FULL_VIEW,
}

export default function PromptItem(props: PromptItemProps) {
  const actionsMenuState = useDisclosure();

  const {
    prompt,
    withBorder = true,
    withResponses = true,
    noOfLines = props.isInline ? 1 : 7,
    withLessPadding = false,
    mode = PromptItemMode.DEFAULT,
    overlapTitle,
    verticallyCentered = false,
    withSpecialBorder = false,
    isInline = false,
    titleFontSize,
  } = props;

  const textAlign = isInline ? 'left' : 'center';

  let paddingClass = 'p-[12px]';

  if (isInline) {
    paddingClass = 'p-[12px] pl-[64px] sm:pl-[82px] md:pl-[102px]';
  }

  const promptEmojiNode = isInline ? (
    <div className="typography-body-xl relative">
      {prompt?.emoji}
      <div className="absolute right-0 top-[24px] sm:top-[32px] typography-heading-lg">?</div>
    </div>
  ) : (
    <>? {prompt?.emoji} ?</>
  );

  return (
    <div
      className={cn(
        'bg-theme-background w-full relative flex flex-col justify-between h-full',
        !isInline ? 'min-h-[200px]' : 'min-h-[124px]',
        withBorder ? 'border-[1px] border-dashed border-brand-main' : '',
        withSpecialBorder ? 'border-brand-ronald' : '',
      )}
      onMouseEnter={actionsMenuState.onOpen}
      onMouseLeave={actionsMenuState.onClose}
    >
      <div
        className={cn(
          'flex w-full h-full flex-1',
          verticallyCentered ? 'items-center' : '',
          isInline ? 'relative mt-0 justify-start' : 'justify-center mt-[12px]',
          paddingClass,
        )}
      >
        {overlapTitle && <OverlapTitle>{overlapTitle}</OverlapTitle>}
        <div
          className={cn(
            'flex flex-col',
            isInline ? 'w-[calc(100% - 46px)] gap-[12px]' : 'w-full mt-[12px] p-[12px] gap-[24px]',
          )}
        >
          {(mode === PromptItemMode.DEFAULT || mode === PromptItemMode.FULL_VIEW) && (
            <div
              className={cn(
                'typography-heading-lg',
                !isInline ? 'text-[22px] sm:text-[36px]' : 'text-[18px] sm:text-[24px]',
                withSpecialBorder ? 'text-brand-ronald' : 'text-brand-main',
                isInline ? 'left-[12px] sm:left-[14px] md:left-[18px]' : '',
                isInline ? 'top-[50%]' : '',
                isInline ? 'translate-y-[-50%]' : '',
                isInline ? 'absolute' : '',
                'flex justify-center items-center',
              )}
            >
              {promptEmojiNode}
            </div>
          )}
          <div
            className={cn(
              'flex flex-row gap-[12px] w-fit items-center',
              isInline ? 'text-left' : 'text-center self-center',
            )}
          >
            <TWLink
              to={`/ask/${prompt.id}`}
              className={cn(
                'typography-heading-lg text-brand-main break-words w-fit max-w-full',
                isInline ? 'text-left' : 'text-center self-center',
                isInline
                  ? 'text-[16px] sm:text-[24px] line-clamp-1'
                  : 'text-[22px] sm:text-[36px] line-clamp-3',
              )}
            >
              {prompt.title}
            </TWLink>
          </div>
          {typeof window === 'undefined' && (
            <div className="w-full flex justify-start align-middle">
              <p
                className={cn(
                  'typography-body-lg whitespace-pre-line',
                  isInline ? 'text-left' : 'text-center',
                  isInline ? 'line-clamp-1' : 'line-clamp-7',
                )}
              >
                {prompt.content?.replace(/\n{3,}/g, '\n')}
              </p>
            </div>
          )}
          {typeof window !== 'undefined' && prompt.content && (
            <div
              className={`flex items-center h-fit w-full ${
                isInline ? 'justify-start' : 'justify-center'
              }`}
            >
              {prompt.contentLexical ? (
                <div className="typography-body-lg font-normal text-[24px]">
                  <BasicEditor
                    key={prompt.updatedAt}
                    isReadOnly={true}
                    initialEditorState={prompt.contentLexical}
                    maxLength={5}
                    textAlign={textAlign}
                    noOfLines={noOfLines}
                    textStyle="brand.bodyLg"
                    editorTheme={recBodyTheme}
                    readOnlyEditorTheme={recBodyTheme}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-[10px]">
                  <ExpandableTextContainer noOfLines={noOfLines} as="div">
                    <p className="typography-body-lg font-normal text-[20px] text-red-500">
                      {/* {prompt.content} */}
                    </p>
                  </ExpandableTextContainer>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={cn(
          'w-full self-end',
          isInline ? 'mt-0 pl-[64px] sm:pl-[82px] md:pl-[102px]' : 'mt-[24px]',
        )}
      >
        <PromptItemBottom
          prompt={prompt}
          withBorder={mode !== PromptItemMode.FULL_VIEW}
          withResponses={mode !== PromptItemMode.FULL_VIEW}
          isPromptView={mode === PromptItemMode.FULL_VIEW}
          withSpecialBorder={withSpecialBorder}
          isInline={isInline}
        />
      </div>

      <PromptActions
        prompt={prompt}
        withLessPadding={withLessPadding}
        isInline={isInline}
        withBorder={withBorder}
      />
    </div>
  );
}

export function PromptItemWithFragment(props: PromptItemProps) {
  const { complete, data } = useFragment({
    fragment: PromptItemFragmentDoc,
    fragmentName: 'PromptItem',
    from: {
      __typename: 'Prompt',
      id: props.prompt.id,
    },
  });

  return <PromptItem {...props} prompt={complete ? data : props.prompt} />;
}
