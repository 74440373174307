import { GET_AUTH_USER } from 'shared/misc/graphql/AuthUserFragments';

import { SignedInUserFragment } from 'shared/__generated__/graphql';

import { getAuthCookie } from '../utils/cookies';
import { createServerFn } from '@tanstack/react-start';
import { getClient } from '../utils/apolloClient';

export const getCookiesMap = (cookiesString: string) => {
  const cookies = cookiesString?.split(';') || [];
  const cookieMap: Record<string, string> = {};
  cookies?.forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookieMap[key.trim()] = value;
  });
  return cookieMap;
};

export const getSessionUserServer = createServerFn({ method: 'GET' }).handler(async () => {
  const client = getClient();
  const authCookie = await getAuthCookie();

  if (!authCookie) return null;

  try {
    // const { userId } = safeParse(session) || {};

    // if (!userId) {
    //   return null;
    // }

    // let user = await getAuthUserViaIdFromCache(userId);
    // if (!user) {
    //   return null;
    // }

    try {
      const queryResult = await client.query({
        query: GET_AUTH_USER,
      });

      const user = queryResult?.data?.me;
      // Convert JSON fields to serializable format
      return {
        ...user,
        links: user?.links ? JSON.stringify(user.links) : null,
      } as any as SignedInUserFragment;
    } catch (e) {
      console.log('error occured');
      console.log(e);
    }
  } catch (e) {
    console.log('Something went wrong getting the user from cookie.');
    console.log(e);
    return null;
  }
  return null;
});

export function getSessionUser() {
  if (typeof window === 'undefined') {
    return getSessionUserServer();
  }

  return getSessionUserClient();
}

async function getSessionUserClient() {
  const client = getClient();

  // With proper type policies, we can just use client.query with cache-first
  try {
    const queryResult = await client.query({
      query: GET_AUTH_USER,
      fetchPolicy: 'cache-first', // This is the default, so it's optional
    });
    if (queryResult?.data?.me) {
      return queryResult.data.me;
    }

    return null;
  } catch (e) {
    console.error('Error fetching user data:', e);
    return null;
  }
}
