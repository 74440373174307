import { createFileRoute, redirect } from '@tanstack/react-router';
import { getSessionUser } from '../../../../actions/getSessionUser';
import AdminDocumentCompose from './-parts/AdminDocumentCompose';

export const Route = createFileRoute('/admin/documents/compose/$documentId')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if ((!user || user.role !== 'ADMIN') && process.env['NODE_ENV'] === 'production') {
      throw redirect({ to: '/' });
    }
  },
});

function RouteComponent() {
  const { documentId } = Route.useParams();
  return <AdminDocumentCompose documentId={documentId} />;
}
