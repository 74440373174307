import React, { useRef } from 'react';
import { Button, Flex, HStack, useToast } from '@chakra-ui/react';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { Modal, ModalContent, ModalFooter } from '../ui/modal';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import usePromptForm, {
  PromptFormHandle,
  PromptFormInputs,
} from 'shared/misc/components/by-type/prompts/usePromptForm';
import { gql } from 'shared/__generated__/gql';
import { processLexicalState } from 'shared/lexical';
import { PromptItemFragment } from 'shared/__generated__/graphql';
import { safeParse } from 'web2/app/components/by-type/rich-text/basic/basic-editor';
import { isInWebView } from 'shared/webview';

type UseDisclosureReturn = ReturnType<typeof useDisclosure>;

type Props = {
  prompt: PromptItemFragment;
  modalState?: UseDisclosureReturn;
};

export default function UpdatePromptModal({ prompt, modalState: modalStateOveride }: Props) {
  const { isOpen, onClose } = modalStateOveride || useDisclosure();

  const formRef = useRef<PromptFormHandle>(null);

  const toast = useToast();

  const [updatePrompt, updatePromptState] = useMutation(UPDATE_PROMPT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getProfileView', 'getHomeview'],
  });

  const IS_IN_WEB_VIEW = isInWebView();

  const onUpdatePrompt = async (values: PromptFormInputs) => {
    const { titleLexicalState, contentLexicalState = null, emoji = '' } = values;

    const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
    const { text: title } = processLexicalState(titleLexicalState);

    updatePrompt({
      variables: {
        promptId: prompt.id,
        input: {
          title,
          emoji,
          content,
          contentLexical,
        },
      },
      onCompleted: async () => {
        toast({
          title: 'Ask updated.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
        onClose();
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const { onSubmit, mainFormNode } = usePromptForm({
    onSubmit: onUpdatePrompt,
    ref: formRef,
    isSubmitting: updatePromptState.loading,
    editorHeight: '320px',
    defaultValues: {
      title: prompt?.title || '',
      emoji: prompt?.emoji || '😃',
      contentLexicalState: prompt.content ? safeParse(prompt?.contentLexical) : '' || '',
    },
  });

  return (
    <Modal open={isOpen} onOpenChange={onClose}>
      <ModalContent
        dontRenderOverlay={IS_IN_WEB_VIEW}
        hideCloseButton={IS_IN_WEB_VIEW}
        className={`max-h-[90%] h-[100dvh] max-w-[100%] w-full sm:w-[60vw] m-0 lg:ml-[16px] lg:mr-[16px] lg:mb-[16px] lg:mt-[16px] p-[12px] md:p-[24px] lg:p-[48px] ${
          IS_IN_WEB_VIEW ? 'border-0 pt-[48px]' : ''
        }`}
      >
        <div className="mb-24 text-[16px] overflow-y-scroll">{mainFormNode}</div>
        <ModalFooter
          className={`absolute ${
            IS_IN_WEB_VIEW ? 'top-0' : 'bottom-0'
          } left-0 w-full bg-brand-background p-[12px] md:p-[24px] lg:p-[48px]`}
        >
          <Flex justifyContent="flex-end" width="100%" alignItems="center">
            <HStack spacing="12px">
              <Button
                size="sm"
                onClick={onClose}
                variant="textOnly"
                color="brand.lightgrey"
                _hover={{ color: 'brand.red' }}
                border="none"
                textStyle="brand.actionSm"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                variant="primary"
                textStyle="brand.actionSm"
                fontSize="13.5px"
                isLoading={updatePromptState.loading}
                isDisabled={updatePromptState.loading}
              >
                Save
              </Button>
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const UPDATE_PROMPT = gql(/* GraphQL */ `
  mutation updatePrompt($input: CreatePromptInput!, $promptId: String!) {
    updatePrompt(input: $input, promptId: $promptId) {
      id
      ...PromptItem
    }
  }
`);
