import { useRouterState } from '@tanstack/react-router';

export const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

export const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};
