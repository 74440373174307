import React from 'react';
import Heading from './heading';
import { cn } from '../../../../apps/web2/app/utils/cn';

export default function OverlapTitle({ children }: { children: React.ReactNode }) {
  return (
    <Heading
      as="h1"
      fontSize="40px"
      fontWeight="700"
      textStyle="brand.headingLg"
      textTransform="uppercase"
      variant="XL"
      className={cn(
        'text-[40px] absolute typography-heading-lg uppercase translate-x-[-50%] whitespace-nowrap left-1/2 top-[-20px]',
        'after:content-[""] after:absolute after:bg-brand-background after:h-[2px] after:w-full after:left-[-3px] after:top-[19px] after:z-[-1]',
      )}
    >
      {children}
    </Heading>
  );
}
