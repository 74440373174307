import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useQuery, gql } from '@apollo/client';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { copyToClipboard } from 'shared/misc/clipboard';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { TWLink } from '../../components/link';
import { Progress } from '../../components/ui/progress';
import { TWButton } from '../../components/button';

const ReferralQuery = gql(/* GraphQL */ `
  query getReferrals {
    me {
      ... on User {
        id
        username
        isPremium
        invitesRedeemedCount
      }
    }
  }
`);
export const Route = createFileRoute('/invites/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | INVITES',
        description: 'Invite your friends to PI.FYI',
      },
    ],
  }),
});

function RouteComponent() {
  const { data, loading } = useQuery(ReferralQuery);
  const { me } = data ?? {};
  const { invitesRedeemedCount: inviteCount, isPremium = false } = me ?? {};

  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const router = useRouter();

  useEffect(() => {
    if (!me?.id && !loading) {
      router.navigate({ to: `/sign-in?destination=${encodeURIComponent('/invites')}` });
    }
  }, [me, loading]);

  if (!me?.id) {
    return null;
  }

  const signUpUrl = `${BASE_SITE_URL}/subscribe/referral/${me.username}`;

  return (
    <div className="flex justify-center mb-100 p-[12px] pt-[48px]">
      <div className="flex flex-col gap-[12px] justify-center w-full items-center">
        <h1 className="uppercase typography-heading-xl text-brand-highlight text-center">
          Invites
        </h1>
        <TWButton
          variant="primary"
          className="typography-heading-sm my-[24px]"
          onClick={() => {
            copyToClipboard(signUpUrl);
            toast({
              title: 'Copied invite link',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            trackEvent('Click', 'Invite_Link', {
              inviteCode: signUpUrl,
            });
          }}
        >
          COPY YOUR INVITE LINK
        </TWButton>
        <p className="opacity-50 text-center typography-body-sm text-[24px] mb-[12px]">
          {signUpUrl}
        </p>
        <div className="flex flex-col gap-[6px] max-w-[500px] mb-[12px] w-full">
          <p className="text-center typography-heading-sm font-normal my-[12px]">
            <span className="text-brand-highlight">{Math.max(1 - inviteCount, 0)} invites</span>{' '}
            away from a{' '}
            <TWLink to="/settings/premium" variant="stylizedBasic" textStyle="brand.headingSm">
              PI.FYI premium
            </TWLink>{' '}
            trial.
          </p>
          <Progress value={(inviteCount / 1) * 100} />
        </div>
        <div className="flex flex-col gap-[6px] max-w-[500px] mb-[12px] w-full">
          <p className="text-center typography-heading-sm font-normal my-[12px]">
            <span className="text-brand-highlight">{Math.max(5 - inviteCount, 0)} invites</span>{' '}
            away from a{' '}
            <TWLink
              href="https://cdn.shopify.com/s/files/1/0552/9429/6121/files/white-glossy-mug-white-11-oz-handle-on-right-6750f3a779b99.jpg?v=1733358514"
              variant="stylizedBasic"
              textStyle="brand.headingSm"
            >
              free PI coffee mug.
            </TWLink>
          </p>
          <Progress value={(inviteCount / 5) * 100} />
        </div>
        <div className="flex flex-col gap-[6px] max-w-[500px] mb-[12px] w-full">
          <p className="text-center typography-heading-sm font-normal my-[12px]">
            <span className="text-brand-highlight">{Math.max(10 - inviteCount, 0)} invites</span>{' '}
            away from a{' '}
            <TWLink
              href="https://cdn.shopify.com/s/files/1/0552/9429/6121/files/unisex-staple-t-shirt-black-front-67508c239b25a.jpg?v=1733332013"
              variant="stylizedBasic"
              textStyle="brand.headingSm"
            >
              free PI t-shirt.
            </TWLink>
          </p>
          <Progress value={(inviteCount / 10) * 100} />
        </div>
      </div>
    </div>
  );
}
