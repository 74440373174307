

import { Box, Button, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import { useQuery } from '@apollo/client';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { Link } from '@tanstack/react-router';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { inviteCodeSchema } from 'shared/validation/schemas';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { UserFlagType } from 'shared/__generated__/graphql';
import { SYNC_SUBSTACK_STATUS, USE_INVITE_CODE } from 'shared/misc/graphql/SignUpFragments';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import ThemeAwareHeader from 'shared/misc/components/ThemeAwareHeader';
import ThemeAwareStar from '../--theme-aware-star';
import { createFileRoute } from '@tanstack/react-router';

type Inputs = {
  code: string;
};

export const Route = createFileRoute('/sign-up/premium/')({
  component: SignUpSubstackPage,
});

function SignUpSubstackPage() {
  const toast = useToast();
  const { user, isFullyLoaded } = useAuth();

  const [useInviteCode, { loading: useInviteCodeSubmitting }] = useMutationWithAutoToast(
    USE_INVITE_CODE,
    {
      successMessage: "Valid invite code. You're in!",
      errorMessage: 'Invalid code.',
    },
  );

  const { register, handleSubmit, watch } = useForm<Inputs>({
    resolver: yupResolver(inviteCodeSchema),
  });

  const values = watch();

  useAutoSetTheme(user?.profileTheme || 'DEFAULT');

  const { refetch, loading: syncSubstackStatusLoading } = useQuery(SYNC_SUBSTACK_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: () => {
      toast({
        title: 'An error occured...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.MORE] });
  const isPremium = (user && user?.isPremium) || user?.substackStatus === 'paid';
  const isGuest = user?.flag.includes(UserFlagType.Guest) || user?.email.includes('pi-temp');

  const onInviteCodeAttemptSubmit: SubmitHandler<Inputs> = (variables) => {
    useInviteCode({
      variables,
      onCompleted: async () => {
        // await updateStep(null, isGuest ? null : '/sign-up/done');
        // await updateStep(isGuest ? null : USER_SIGN_UP_STEP.DONE);
        // refetchUser();
      },
    });
  };

  const onResync = () => refetch();

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal" padding="12px">
        <Box
          m="10px"
          marginTop={{ base: '40px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p={{ base: '24px', md: '48px' }}
          w="100%"
          maxW="500px"
        >
          <Stack spacing={6} justifyContent="center">
            <ThemeAwareStar />
            <ThemeAwareHeader>
              {isGuest ? (
                <>WELCOME</>
              ) : user?.isPremium ? (
                <>Your subscription is active!</>
              ) : (
                <>Want more features and content?</>
              )}
            </ThemeAwareHeader>
            {user && (
              <Stack spacing="24px">
                {isGuest ? (
                  <Stack spacing="6px">
                    <Text textStyle="brand.bodySm" fontWeight="normal">
                      We're activating your official Perfectly Imperfect guest account which will
                      have a special badge + your pre-loaded recommendations.
                    </Text>
                    <Text textStyle="brand.bodySm" fontWeight="normal">
                      As a thanks for your contributions you'll also be grandfathered into a premium
                      account and have access to special features before anyone else.
                    </Text>
                  </Stack>
                ) : (
                  <>
                    {!isPremium ? (
                      <Stack spacing="6px">
                        <Text textStyle="brand.bodySm" fontWeight="bold">
                          Consider{' '}
                          <Link className="text-brand-main underline" to="/settings/premium" variant="stylized">
                            upgrading to premium
                          </Link>{' '}
                          for more newsletter content, exclusive themes, special app features,
                          access to the full archive, and more.
                        </Text>
                        <Text textStyle="brand.bodySm" fontWeight="normal">
                          You'll also be helping us not go broke paying for all these servers.
                        </Text>
                      </Stack>
                    ) : (
                      <Text textStyle="brand.bodySm" fontWeight="normal">
                        As a premium user you'll have access to{' '}
                        <Link className="text-brand-main underline" to="/settings/premium" variant="stylized">
                          special features and themes
                        </Link>{' '}
                        here on PI.FYI! We <em>greatly</em> appreciate your support.
                      </Text>
                    )}
                  </>
                )}
                <HStack spacing="6px" alignSelf="flex-start">
                  <Button
                    variant="primary"
                    onClick={async () => {
                      await updateStep(null, isGuest ? null : '/sign-up/done');
                      // await updateStep(isGuest ? null : USER_SIGN_UP_STEP.FOLLOW);
                    }}
                  >
                    {isGuest ? 'Finish sign-up' : 'Next'}
                  </Button>
                  {!isPremium && !isGuest && (
                    <Button
                      as={Link}
                      variant="secondary"
                      to="/settings/premium"
                      target="_blank"
                      isLoading={syncSubstackStatusLoading}
                    >
                      UPGRADE TO PREMIUM
                    </Button>
                  )}
                </HStack>
                {!user.isPremium && !isGuest && (
                  <>
                    <Stack spacing="6px">
                      <Text textStyle="brand.bodySm" fontWeight="normal" fontSize="11px">
                        We couldn't find an existing subscription related to {user.email}.
                      </Text>
                      <Text textStyle="brand.bodySm" fontWeight="normal" fontSize="11px">
                        If you're a paying subscriber coming from Substack, double check that your
                        Substack email is the same as the email you signed up with here.
                      </Text>
                      <Text textStyle="brand.bodySm" fontWeight="normal" fontSize="11px">
                        <Link
                          className="text-brand-main underline"
                          to={`/verify-another-email?destination=${encodeURIComponent(
                            '/sign-up/premium',
                          )}`}
                          variant="stylizedBasic"
                        >
                          Click here
                        </Link>{' '}
                        to sync another email address or send us an{' '}
                        <Link
                          className="text-brand-main underline"
                          to="mailto:tyler@perfectlyimperfect.fyi?subject=Perfectly%20Imperfect%20Paid%20Issue"
                          variant="stylizedBasic"
                        >
                          message
                        </Link>{' '}
                        if you're still having issues
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}
