import { createFileRoute } from '@tanstack/react-router';
import { Box, useDisclosure } from '@chakra-ui/react';
import {
  GlobalWebViewObjects,
  getGlobalWebViewObject,
  safeSendWebViewMessage,
} from 'shared/webview/index';
import CommentComposer from 'shared/misc/components/by-type/comments/CommentComposer';
import { CommentItemCoreFragment, ProfileThemeType } from 'shared/__generated__/graphql';
import { AddRecommendationMessages } from '../../../../../../packages/shared/webview/AddRecommendationMessages';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';

export const Route = createFileRoute('/mobile/add-comment/')({
  component: RouteComponent,
  ssr: false,
});

function RouteComponent() {
  const modalState = useDisclosure();

  const onClose = () => {
    safeSendWebViewMessage(AddRecommendationMessages.CLOSE);
  };

  const WEB_VIEW_PROPS = getGlobalWebViewObject(GlobalWebViewObjects.AddCommentProps);

  const targetRecId = WEB_VIEW_PROPS?.targetRecId as string;

  const replyToComment = WEB_VIEW_PROPS?.replyToComment as CommentItemCoreFragment | undefined;
  const parentComment = WEB_VIEW_PROPS?.parentComment as CommentItemCoreFragment | undefined;

  useAutoSetTheme(ProfileThemeType.Default);

  return (
    <Box height="200px">
      <CommentComposer
        targetRecId={targetRecId}
        withBorder={false}
        replyToComment={replyToComment}
        parentCommentId={parentComment?.id}
        withCancel
      />
    </Box>
  );
}
