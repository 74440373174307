import { createFileRoute } from '@tanstack/react-router';
import { TWLink } from '../../components/link';

export const Route = createFileRoute('/admin/')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col gap-[24px] max-w-full w-[800px] mt-20 p-4">
        <h1 className="typography-heading-xl">ADMIN</h1>
        <div className="flex flex-col gap-[24px]">
          <TWLink
            to="/admin/documents/drafts"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Articles
          </TWLink>
          <TWLink
            to="/admin/staff"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Staff
          </TWLink>
          <hr />
          <TWLink
            to="/admin/dashboard/stats"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Analytics
          </TWLink>
          <TWLink
            to="/admin/dashboard/users"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Users
          </TWLink>
          <TWLink
            to="/admin/email/blasts"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Email Blasts
          </TWLink>
          <TWLink
            to="/admin/email/subscribers"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Email Subscribers
          </TWLink>
          <hr />
          <TWLink
            to="/admin/substack"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            Substack
          </TWLink>
          <TWLink
            to="/api/graphql"
            variant="stylizedNoDefaultBorder"
            className="typography-heading-lg"
          >
            GraphQL
          </TWLink>
        </div>
      </div>
    </div>
  );
}
