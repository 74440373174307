import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
} from '../../components/ui/dropdown-menu';
import _, { add } from 'lodash';
import {
  ArticleTileFragment,
  GetHomeMainContentBottomDocument,
  GetHomeMainContentQuery,
  GetHomeMainContentTopDocument,
  GetHomeMainContentTopQuery,
  GetHomePageDocument,
  GetHomePageHotByCategoryDocument,
  GetHomePageQuery,
  ProfileThemeType,
  RecCategory,
} from 'shared/__generated__/graphql';
import ArticleTile from 'shared/misc/components/by-type/articles/ArticleTile';
import PromptItem from '../../components/by-type/prompts/prompt-item';
import RecommendationItem from '../../components/by-type/recommendations/recommendation-item';
import FeedItemAccordion from 'shared/misc/components/FeedItemAccordion';
import FeedItemGallery from '../../components/by-type/feed/feed-item-gallery';
import { useRouter, useRouterState } from '@tanstack/react-router';
import FeedItemCollection from 'shared/misc/components/FeedItemCollection';
import FeedItemCarousel from 'shared/misc/components/FeedItemCarousel';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { isAfter, sub, isBefore, format } from 'date-fns';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { usePrefetch } from 'shared/misc/hooks/usePrefetch';
import FeedItem from '../../components/by-type/feed/feed-item';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import { useEffect, useMemo, useState } from 'react';
import InlineSubscribeToSignUp from 'shared/misc/components/InlineSubscribeToSignUp';
import InlineReferrals from 'shared/misc/components/InlineReferrals';
import {
  DEFAULT_COLORS,
  DONUT_COLORS,
  GREEN_RED_COLORS,
  UPS_COLORS,
  WHITE_PINK_BLUE_COLORS,
} from 'shared/misc/theme/custom/custom-theme-colors';
import { useRef } from 'react';
import UserTile from 'web2/app/components/by-type/user/user-tile';
import ActivityFeedModule from './-activity-feed-module';
import React from 'react';
import RecItemSlimInlineComposer from 'web2/app/components/by-type/recommendations/rec-slim-inline-composer';
import Heading from 'shared/misc/components/heading';
import { InView } from 'react-intersection-observer';
import { FeedInfiniteScroll } from './-home-tabbed-container';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';

import { Button as TWButton } from '../../components/button';
import { TWLink } from 'web2/app/components/link';

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};

export default function HomeMainContentNew({
  prefetchedHomePageTopQueryData,
  newLinks,
}: {
  prefetchedHomePageTopQueryData?: GetHomeMainContentTopQuery | null;
  newLinks?: boolean;
}) {
  const [activeCategoryIdx, setActiveCategoryIdx] = useState<number | null>(0);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(false);

  const [isNearEnd, setIsNearEnd] = useState<boolean>(false);

  const router = useRouter();
  const auth = useAuth();

  useAutoSetTheme(auth.user?.profileTheme || ProfileThemeType.Default);

  const { data: freshTopData } = useQuery(GetHomeMainContentTopDocument, {
    fetchPolicy: 'cache-and-network',
    // skip: !auth.user,
    // pollInterval: 60000 * 10,
  });

  const { data: freshBottomData } = useQuery(GetHomeMainContentBottomDocument, {
    fetchPolicy: 'cache-and-network',
    // skip: !auth.user,
    // pollInterval: 60000 * 10,
  });

  type HomePageType = GetHomePageQuery['homePage'];

  const data = {
    __typename: 'Query',
    homePage: {
      ...(prefetchedHomePageTopQueryData?.homePageTop || {}),
      ...(freshTopData?.homePageTop || {}),
      ...(freshBottomData?.homePageBottom || {}),
    } as HomePageType,
  };

  // console.log({ freshTopData, freshBottomData });

  const [loadCategory, loadCategoryData] = useLazyQuery(GetHomePageHotByCategoryDocument, {
    fetchPolicy: 'network-only',
  });

  const categoryConfig = [
    {
      category: null,
    },
    {
      category: RecCategory.Music,
      title: 'Music',
      accent: DONUT_COLORS.highlight,
      emoji: '🎵',
    },
    {
      category: RecCategory.Movies,
      title: 'Movies',
      // accent: 'brand.blue',
      emoji: '🎥',
    },
    {
      category: RecCategory.Books,
      title: 'Books',
      accent: UPS_COLORS.background,
      emoji: '📚',
    },
    {
      onlyWithLink: true,
      title: 'Links',
      accent: GREEN_RED_COLORS.background,
      emoji: '🔗',
    },
    {
      category: RecCategory.Videos,
      title: 'Videos',
      accent: DEFAULT_COLORS.titleBackground,
      emoji: '📹',
    },
    {
      category: RecCategory.Clothing,
      title: 'Clothing',
      accent: WHITE_PINK_BLUE_COLORS.main,
      emoji: '👕',
    },
  ];

  useEffect(() => {
    // if (isAutoScrollEnabled) {
    //   const intervalRef = setTimeout(async () => {
    //     let next = (activeCategoryIdx || 0) + 1;
    //     if (next === categoryConfig.length - 1) {
    //       next = 0;
    //     }
    //     const hotConfig = categoryConfig[next];
    //     if (next !== 0 && hotConfig) {
    //       await loadCategory({
    //         variables: { category: hotConfig.category, onlyWithLink: hotConfig.onlyWithLink },
    //         fetchPolicy: 'cache-first',
    //       });
    //     }
    //     setActiveCategoryIdx(next);
    //   }, 10000);
    //   return () => clearInterval(intervalRef);
    // }
  }, [activeCategoryIdx, isAutoScrollEnabled, loadCategoryData.loading]);

  const defaultConfig = categoryConfig[0];

  let hotConfig = categoryConfig[activeCategoryIdx || 0];

  let hotData =
    loadCategoryData.variables?.category === hotConfig?.category
      ? loadCategoryData?.data?.homePageHotByCategory
      : data?.homePage;

  if (
    hotConfig !== defaultConfig &&
    loadCategoryData.variables?.category === hotConfig?.category &&
    !loadCategoryData?.data?.homePageHotByCategory?.hot?.length === 0 &&
    !loadCategoryData.loading
  ) {
    hotConfig = defaultConfig;
    hotData = data?.homePage;
  }

  const mouseRef = useRef<any>(null);

  const title = useMemo(() => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger
          className="cursor-pointer p-2 typography-heading-xl text-[36px] md:text-[48px] text-justify"
          style={{ transform: 'scale(1, 1.25)' }}
        >
          {hotConfig.title ? `${hotConfig.title} ${hotConfig.emoji}` : "What's hot"}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {categoryConfig.map((config, idx) => (
            <DropdownMenuItem
              key={idx}
              onSelect={async () => {
                setIsAutoScrollEnabled(false);
                await loadCategory({
                  variables: {
                    category: config.category,
                    onlyWithLink: config.onlyWithLink,
                  },
                  fetchPolicy: 'cache-first',
                });
                setActiveCategoryIdx(idx);
              }}
            >
              {config.title || 'Everything'} {config.emoji}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }, [hotConfig?.title]);

  const buttons = (
    <div className="flex flex-row gap-0 w-full">
      {auth.user && (
        <TWButton
          variant="outline"
          className="p-0 px-0 py-0 w-full h-9 text-center border border-brand-main typography-heading-sm hover:border-theme-main hover:text-theme-main text-[16px] lg:text-[16px] xl:text-[21px]"
        >
          <TWLink className="w-full flex place-content-center" to="/home/following">
            Friends
          </TWLink>
        </TWButton>
      )}
      <TWButton
        variant="outline"
        className="p-0 px-0 py-0 w-full h-9 text-center border border-theme-main typography-heading-sm hover:border-theme-main hover:text-theme-highlight text-[16px] lg:text-[16px] xl:text-[21px]"
      >
        <TWLink className="w-full flex place-content-center" to="/home/everyone">
          Everyone
        </TWLink>
      </TWButton>
      <TWButton
        variant="outline"
        className="p-0 px-0 py-0 w-full h-9 text-center border border-theme-main typography-heading-sm hover:border-theme-main hover:text-theme-highlight text-[16px] lg:text-[16px] xl:text-[21px] hidden md:block"
      >
        <TWLink className="w-full flex place-content-center" to="/asks">
          ASKS
        </TWLink>
      </TWButton>
      <TWButton
        variant="outline"
        className="p-0 px-0 py-0 w-full h-9 text-center border border-theme-main typography-heading-sm hover:border-theme-main hover:text-theme-highlight text-[16px] lg:text-[16px] xl:text-[21px] hidden md:block"
      >
        <TWLink className="w-full flex place-content-center" to="/read">
          INTERVIEWS
        </TWLink>
      </TWButton>
    </div>
  );

  const breakpoint = useBreakpoint();
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const themeColors = useThemeColors();

  const mobileView = (
    <div className="flex flex-col gap-[12px]">
      {auth.user ? (
        <div className="w-full flex justify-center">
          <div className="min-w-full">
            <RecItemSlimInlineComposer
              withInvertedEmojiLink
              // startCollapsed
              redirectToRec
              // oneLineViewMode
              // titlePlaceholder={'What have you been into?'}
              // bodyPlaceholder={'Share what you love.'}
              withRotatingOptions
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <InlineSubscribeToSignUp
            ctaText="Join the tens of thousands of people sharing their taste on PI.FYI"
            submitText="Sign up"
            confirmationText="Welcome!"
            redirectTo="/sign-up/more"
            autoOpenModalVersion={isMobile}
            containerProps={{
              width: '100%',
            }}
          />
        </div>
      )}
      {newLinks && buttons}
      <FeedItemCarousel
        title={title}
        // accent={hotConfig.accent || 'brand.red'}
        height={{ base: undefined, sm: '800px' }}
        containerProps={{
          onMouseEnter: () => {
            mouseRef.current = setTimeout(() => {
              setIsAutoScrollEnabled(false);
            }, 1000);
          },
          onMouseLeave: () => {
            if (mouseRef.current) {
              clearTimeout(mouseRef.current);
            }
            setIsAutoScrollEnabled(true);
          },
        }}
      >
        {hotData?.hot?.map((item) => {
          return (
            <FeedItem
              feedItem={item}
              recProps={{
                noOfLines: item.targetRec?.attachments?.length ? 1 : 8,
                titleNoOfLines: 1,
                disableExpandImage: true,
              }}
              key={item.id}
            />
          );
        })}
      </FeedItemCarousel>
      <TwoColumnLayout>
        {data?.homePage?.articleOne! && <ArticleImageOnly article={data?.homePage?.articleOne!} />}
        {data?.homePage?.dailyAsk && (
          <PromptItem
            prompt={data?.homePage?.dailyAsk!}
            overlapTitle="Today's Ask"
            verticallyCentered
          />
        )}
      </TwoColumnLayout>
      <TwoColumnLayout>
        <ActivityFeedModule />
        {data?.homePage?.articleTwo! && <ArticleImageOnly article={data?.homePage?.articleTwo!} />}
      </TwoColumnLayout>
      <FeedItemGallery title="Rising">
        {data?.homePage?.rising?.map((item) => (
          <FeedItem
            feedItem={item}
            recProps={{ noOfLines: 1, expandImageOnMount: true }}
            key={item.id}
          />
        ))}
      </FeedItemGallery>
      <TwoColumnLayout>
        {data?.homePage?.staffPickOne! && (
          <RecommendationItem
            rec={data?.homePage?.staffPickOne!}
            noOfLines={2}
            overlapTitle="Staff Rec"
          />
        )}
        {data?.homePage?.piArticles?.[1]! && (
          <ArticleImageOnly article={data?.homePage?.piArticles?.[1]!} />
        )}
      </TwoColumnLayout>
      <FeedItemGallery
        centerItems
        title="Rising Users"
        columnCount={'grid-cols-2 md:grid-cols-3'}
        headerStyles="text-[52px] md:text-[72px]"
      >
        {data?.homePage?.hotUsers?.slice(0, 8).map((user) => (
          <div key={user.id} className="flex justify-center">
            <div className="max-w-[200px] w-[calc(50vw-24px)] md:w-[200px]">
              <UserTile user={user} />
            </div>
          </div>
        ))}
      </FeedItemGallery>
      <TwoColumnLayout>
        {/* <ArticleImageOnly article={data?.homePage?.articleThree!} /> */}
        {data?.homePage?.piArticles?.[2]! && (
          <ArticleImageOnly article={data?.homePage?.piArticles?.[2]!} />
        )}
        {data?.homePage?.staffPickTwo! && (
          <RecommendationItem
            rec={data?.homePage?.staffPickTwo!}
            noOfLines={2}
            titleNoOfLines={1}
            overlapTitle="Staff Rec"
          />
        )}
      </TwoColumnLayout>
      <InlineReferrals />
    </div>
  );

  const desktopView = (
    <div className="flex flex-col gap-[12px]">
      <div className="grid grid-cols-5 gap-[12px]">
        <div className="col-span-3">
          <div className="flex flex-col gap-[12px]">
            <div className="w-full">{newLinks && buttons}</div>
            {auth.user ? (
              <div className="w-full flex justify-center">
                <div className="min-w-full">
                  <RecItemSlimInlineComposer
                    withInvertedEmojiLink
                    // startCollapsed
                    redirectToRec
                    // oneLineViewMode
                    // titlePlaceholder={'What have you been into?'}
                    // bodyPlaceholder={'Share what you love.'}
                    withRotatingOptions
                  />
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <InlineSubscribeToSignUp
                  ctaText="Join the tens of thousands of people sharing their taste on PI.FYI"
                  submitText="Sign up"
                  confirmationText="Welcome!"
                  redirectTo="/sign-up/more"
                  autoOpenModalVersion={!isMobile}
                  containerProps={{
                    width: '100%',
                  }}
                />
              </div>
            )}
            <FeedItemCarousel
              title={title}
              // accent={hotConfig.accent || 'brand.red'}
              height={{ base: undefined, sm: '850px' }}
              withStars={false}
              containerProps={{
                onMouseEnter: () => {
                  mouseRef.current = setTimeout(() => {
                    setIsAutoScrollEnabled(false);
                  }, 1000);
                },
                onMouseLeave: () => {
                  if (mouseRef.current) {
                    clearTimeout(mouseRef.current);
                  }
                  setIsAutoScrollEnabled(true);
                },
                p: { base: '12px', sm: '36px' },
              }}
              itemWidth={600}
            >
              {_.chunk(hotData?.hot || [], 4).map((items, idx) => (
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full" key={idx}>
                  {items.map((item) => (
                    <div className="w-full h-full" key={item.id}>
                      <FeedItem
                        feedItem={item}
                        recProps={{
                          // noOfLines: item.targetRec?.attachments?.length ? 1 : 2,
                          titleNoOfLines: 1,
                          disableExpandImage: true,
                          imageMaxHeight: '200px',
                          isInline: true,
                        }}
                        promptProps={{
                          isInline: true,
                        }}
                        key={item.id}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </FeedItemCarousel>
            <div className="w-full relative bg-brand-background p-4 pt-8 pb-0 border border-dashed border-brand-main flex-1">
              <FeedItemGallery
                title="Rising Users"
                columnCount={'grid-cols-2 lg:grid-cols-3'}
                headerStyles="text-[52px] lg:text-[72px]"
                withStars={false}
                bodyStyles="max-h-[640px] pt-[32px] pb-[75px] overflow-y-auto"
              >
                {data?.homePage?.hotUsers?.slice(0, 12).map((user) => (
                  <div className="flex justify-center" key={user.id}>
                    <div className="w-[200px]">
                      <UserTile user={user} />
                    </div>
                  </div>
                ))}
              </FeedItemGallery>
              <div className="absolute bottom-0 left-0 w-full h-[100px] bg-gradient-to-t from-brand-background to-transparent pointer-events-none"></div>
            </div>
            <InlineReferrals />
            <InlineSubscribeToSignUp
              ctaText="Check out our newsletter."
              submitText="Subscribe"
              confirmationText="Subscribed!"
              // redirectTo="/sign-up/more"
              autoOpenModalVersion={false}
            />
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-col gap-4">
            <div className="w-full">
              {data?.homePage?.articleOne! && (
                <ArticleImageOnly isBorderless article={data?.homePage?.articleOne!} />
              )}
            </div>
            <div className="w-full mt-4">
              {data?.homePage?.dailyAsk! && (
                <PromptItem
                  prompt={data?.homePage?.dailyAsk!}
                  overlapTitle="Today's Ask"
                  verticallyCentered
                  titleFontSize={['36px', '48px']}
                />
              )}
            </div>

            <div className="w-full">
              <ActivityFeedModule />
            </div>
            <div className="w-full mt-4 flex-1">
              {data?.homePage?.staffPickOne! && (
                <RecommendationItem
                  rec={data?.homePage?.staffPickOne!}
                  noOfLines={2}
                  overlapTitle="Staff Rec"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-rows-[auto] grid-cols-5 gap-4">
        <div className="row-span-5 col-span-2">
          <div className="flex flex-col gap-4 h-full">
            <div className="w-full">
              {data?.homePage?.articleTwo! && (
                <ArticleImageOnly isBorderless article={data?.homePage?.articleTwo!} />
              )}
            </div>
            <div className="w-full">
              {data?.homePage?.piArticles?.[1]! && (
                <ArticleImageOnly isBorderless article={data?.homePage?.piArticles?.[1]!} />
              )}
            </div>
            <div className="w-full mt-6 flex-1">
              <div className="flex flex-col gap-4">
                {data?.homePage?.dailyAskTwo! && (
                  <PromptItem
                    prompt={data?.homePage?.dailyAskTwo!}
                    verticallyCentered
                    overlapTitle="Hot asks"
                  />
                )}
                {data?.homePage?.hotAsks?.slice(0, 4).map((item) => (
                  <FeedItem
                    feedItem={item}
                    promptProps={{ isInline: true, titleFontSize: ['16px', '22px'] }}
                    key={item.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row-span-5 col-span-3">
          <div className="flex flex-col gap-4 h-full">
            <FeedItemCarousel
              title="Staff Recs"
              // accent={hotConfig.accent || 'brand.red'}
              height={{ base: undefined, sm: '850px' }}
              withStars={false}
              itemWidth={600}
            >
              {_.chunk(data?.homePage?.staffPicks || [], 4).map((items, idx) => (
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full" key={idx}>
                  {items.map((item) => (
                    <div className="w-full h-full" key={item.id}>
                      <RecommendationItem
                        rec={item}
                        titleNoOfLines={1}
                        disableExpandImage={true}
                        imageMaxHeight="200px"
                        isInline={true}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </FeedItemCarousel>
            <div className="w-full bg-brand-background p-4 pt-8 border border-dashed border-brand-main flex-1">
              <Heading
                variant="XL"
                className="mb-4 [transform:scale(1,1.25)] text-[48px] md:text-[64px] text-center pb-6 md:pb-1"
                color="brand.titleBackground"
              >
                RISING
              </Heading>
              <div className="flex flex-col gap-4">
                {data?.homePage?.rising?.map((item) => (
                  <FeedItem
                    feedItem={item}
                    recProps={{
                      isInline: true,
                    }}
                    promptProps={{
                      isInline: true,
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="w-full mt-4 flex-1">
              {data?.homePage?.staffPickTwo! && (
                <RecommendationItem
                  rec={data?.homePage?.staffPickTwo!}
                  noOfLines={2}
                  overlapTitle="Staff Rec"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FeedItemCollection accent="brand.blue" url="/read" title="Latest Interviews">
        {data?.homePage?.piArticles?.slice(0, 8).map((article) => (
          <ArticleImageOnly spacing="8px" article={article} key={article.id} />
        ))}
      </FeedItemCollection>
    </div>
  );

  return (
    <div className="w-full max-w-full flex flex-col gap-4 md:gap-4 px-3 sm:px-0 m-0">
      {Object.keys(data.homePage || {}).length === 0 && !prefetchedHomePageTopQueryData ? (
        <div className="flex flex-col gap-12">
          <TwoColumnLayout>
            <SkeletonFeedItem
              skeletons={15}
              height={{ base: '400px', xl: '443px', '2xl': '592px' }}
            />
            <SkeletonFeedItem
              skeletons={15}
              height={{ base: '400px', xl: '443px', '2xl': '592px' }}
            />
          </TwoColumnLayout>
          <SkeletonFeedItem />
          <SkeletonFeedItem />
          <SkeletonFeedItem />
          <SkeletonFeedItem />
        </div>
      ) : (
        <>
          <div className="block md:hidden">{mobileView}</div>
          <div className="hidden md:block">{desktopView}</div>

          <div className="flex flex-col items-center gap-4 self-center w-full max-w-[1280px] ">
            <InView
              as="div"
              rootMargin="1000px 0px"
              onChange={(inView) => {
                if (inView) {
                  setIsNearEnd(true);
                }
              }}
            />
            {isNearEnd ? <FeedInfiniteScroll key="all" onlyFollowing={false} isInView /> : null}
          </div>
        </>
      )}
    </div>
  );
}

export function ArticleImageOnly({
  article,
  spacing = '50px',
  isBorderless = false,
}: {
  article: ArticleTileFragment;
  spacing?: any;
  isBorderless?: boolean;
}) {
  const { document, feature } = article;

  const thumbnailFile = document?.thumbnailFile || feature?.thumbnailFile?.file;

  const href = `/p/${article?.urlSlug || document?.urlSlug || feature?.urlSlug || feature?.id}`;

  const isToday =
    isAfter(new Date(article.publishedAt), sub(new Date(), { days: 1 })) &&
    isBefore(new Date(article.publishedAt), add(new Date(), { days: 1 }));

  let title = article.title || document?.title || feature?.title || '';
  const subtitle = article.subtitle || document?.subtitle || '';

  try {
    title = title.replace(/#([0-9]{2,3}):/gi, '').trim();
  } catch (e) {
    console.log(e);
  }

  return (
    <TWLink to={href}>
      <div
        className="[--spacing-value:var(--spacing,50px)] mb-[var(--spacing-value)] md:mb-0"
        style={{ '--spacing': spacing } as any}
      >
        <div className="relative h-full border border-dashed border-brand-main">
          <PerfImageFromFile
            quality={30}
            file={thumbnailFile}
            style={{
              aspectRatio: 1 / 1,
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'top',
            }}
            alt="thumbnail"
          />
          <div className="flex flex-col items-center justify-center w-full gap-3 py-2.5 md:py-4 border-t border-dashed border-brand-main border-b-0 bg-brand-background target-empty-children-and-hide">
            <div className="text-center truncate w-full">
              <TWLink
                to={href}
                className="inline-block w-full text-[24px] md:text-[36px] text-brand-main typography-brand-heading-lg hover:text-brand-highlight truncate px-[8px]"
              >
                {title}
              </TWLink>
            </div>
            <div className="text-center truncate w-full">
              <TWLink
                to={href}
                className="inline-block w-full text-[14px] text-brand-main typography-brand-action-sm hover:text-brand-highlight truncate px-[8px]"
              >
                {subtitle}
              </TWLink>
            </div>
            <div className="text-center text-[12px] text-brand-main typography-brand-action-sm">
              {isToday
                ? 'TODAY'
                : article.publishedAt
                ? format(new Date(article.publishedAt), 'MMMM d, yyyy')
                : ''}
            </div>
          </div>
          {!isBorderless && <div className="w-full border-b border-dashed border-brand-main"></div>}
        </div>
      </div>
    </TWLink>
  );
}

export function TwoColumnLayout({ children }: { children: React.ReactNode }) {
  return <div className="grid grid-cols-1 md:grid-cols-2 gap-4">{children}</div>;
}

export function OuterTwoColumnLayout({ children }: { children: React.ReactNode }) {
  return <div className="grid grid-rows-2 grid-cols-5 gap-4">{children}</div>;
}
