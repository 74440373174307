import { $dfs } from '@lexical/utils';
import { HeadingNode } from '@lexical/rich-text';
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $isElementNode,
  $isLineBreakNode,
  $isParagraphNode,
  $isRootNode,
  $isTextNode,
  $nodesOfType,
  EditorState,
  Klass,
  LexicalEditor,
  LexicalNode,
  LineBreakNode,
  ParagraphNode,
  SerializedElementNode,
  SerializedLexicalNode,
} from 'lexical';

import { createHeadlessEditor } from '@lexical/headless';

import { HashtagNode } from '@lexical/hashtag';

import {
  $convertToMarkdownString,
  ElementTransformer,
  TRANSFORMERS,
  TextMatchTransformer,
} from '@lexical/markdown';
import { $generateHtmlFromNodes } from '@lexical/html';
import {
  $isMentionNode,
  $createMentionNode,
  MentionNode,
} from './misc/components/rich-text/basic/nodes/MentionNode';
import DefaultNodes from './misc/components/rich-text/basic/nodes/defaultNodes';
import { BASE_SITE_URL } from './siteconfig';
import {
  DocumentFullFragment,
  DocumentRelatedDataCoreFragment,
  DocumentSection,
  PerfImageFromFileFragment,
  PromptItemFragment,
  RecommendationItemFragment,
  UserCoreFragment,
} from './__generated__/graphql';
import {
  $isRecommendationNode,
  RecommendationNode,
} from './misc/components/rich-text/basic/nodes/LexicalRecommendationNode';
import { $isImageNode, ImageNode } from './misc/components/rich-text/basic/nodes/LexicalImageNode';
import { DocumentRelatedDataContext } from './types';
import truncateMarkdown from 'markdown-truncate';
import {
  $isGenericComponentNode,
  GenericComponentNode,
} from './misc/components/rich-text/basic/nodes/LexicalGenericComponentNode';
import {
  $isPromptNode,
  PromptNode,
} from './misc/components/rich-text/basic/nodes/LexicalPromptNode';

const nodes: Array<Klass<LexicalNode>> = [
  ...DefaultNodes,
  HashtagNode,
  MentionNode,
  RecommendationNode,
  PromptNode,
  ImageNode,
  HeadingNode,
  GenericComponentNode,
];

export const MENTION_TRANSFORM: TextMatchTransformer = {
  dependencies: [MentionNode],
  export: (node, exportChildren, exportFormat) => {
    if (!$isMentionNode(node)) {
      return null;
    }

    return `[@${node.__user.username.toUpperCase()}](${BASE_SITE_URL}/u/${node.__user.username})`;
  },
  importRegExp: /#(?:\[([^[]*)\])(?:\(([^(]+)\))/,
  regExp: /#(?:\[([^[]*)\])(?:\(([^(]+)\))$/,
  replace: (textNode, match) => {
    const [, type, name] = match;
    const mentionNode = $createMentionNode(name, {});
    textNode.replace(mentionNode);
  },
  trigger: ')',
  type: 'text-match',
};

export function processLexicalState(state?: EditorState | undefined | null) {
  const isEmpty = state?.read?.(() => {
    const root = $getRoot();
    return !root || (root?.getFirstChild()?.isEmpty?.() && root.getChildrenSize() === 1);
  });

  const text = state
    ? state?.read?.(() => {
        return $getRoot().getTextContent();
      }) || ''
    : '';

  return {
    text,
    json: state && !isEmpty && text ? safeStringify(state) : null,
  };
}

export function safeStringify(maybeObject?: Object | null) {
  if (typeof maybeObject === 'string') {
    return maybeObject;
  }
  try {
    // @ts-ignore
    return JSON.stringify(maybeObject);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function generateLexicalContentFromString(str: string): EditorState {
  return {
    root: {
      children: str
        .split(/\r?\n|\r|\n/g)
        .filter(Boolean)
        .map((text) => ({
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text,
              type: 'text',
              version: 1,
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        })),
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  };
}

export async function mapFromEditorState(
  editorState: string,
  cb: (LexicalEditor) => any,
  update: boolean = false,
) {
  const editor = createHeadlessEditor({
    nodes,
    onError: (e) => console.log(e),
  });

  const initialEditorState = editor.parseEditorState(editorState);

  if (initialEditorState.isEmpty()) return null;

  editor.setEditorState(initialEditorState);

  if (update) {
    return new Promise((resolve, reject) => {
      try {
        editor.update(async () => {
          const returnValue = await cb(editor);
          resolve(returnValue);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  return await editor.getEditorState().read(() => cb(editor));
}

export async function getEditorFromState(editorState: string): Promise<LexicalEditor> {
  const editor = createHeadlessEditor({
    nodes,
    onError: (e) => {
      console.log(e);
    },
  });

  const initialEditorState = editor.parseEditorState(editorState);

  editor.setEditorState(initialEditorState);

  return editor;
}

export async function convertEditorStateToPlainText(editorState: string): Promise<string> {
  return mapFromEditorState(editorState, () => {
    const root = $getRoot();
    return root.getTextContent();
  });
}

export const LINE_BREAK_FIX: ElementTransformer = {
  dependencies: [ParagraphNode, LineBreakNode],
  export: (node) => {
    if ($isParagraphNode(node) && node.getTextContentSize() === 0) {
      return '<br />';
    }
    return null;
  },
  regExp: /\\$/,
  replace: (textNode) => {
    if (!textNode?.getParent()) return;
    textNode.replace($createTextNode());
  },
  type: 'element',
};

export async function convertEditorStateToMarkdown(
  editorStateAsString: string,
  charCount?: number,
): Promise<string> {
  try {
    return mapFromEditorState(editorStateAsString, () => {
      const markdownString = $convertToMarkdownString([
        MENTION_TRANSFORM,
        // LINE_BREAK_FIX,
        ...TRANSFORMERS,
      ]).trim();

      if (charCount) {
        return truncateMarkdown(markdownString, {
          limit: charCount,
          ellipsis: true,
        });
      }

      return markdownString;
    });
  } catch (e) {
    console.log(e);
    return '';
  }
}

export async function convertEditorStateToHTML(editorState: string): Promise<string> {
  return mapFromEditorState(editorState, (e) => $generateHtmlFromNodes(e));
}

export async function generateInitialLexicalWithMention(
  userToMention: UserCoreFragment,
  initialEditor?: LexicalEditor | null,
): Promise<EditorState | null> {
  try {
    const editor =
      initialEditor ||
      createHeadlessEditor({
        nodes,
        onError: (e) => console.log(e),
      });

    await editor.update(() => {
      const node = $createMentionNode(userToMention.id, userToMention);

      const [existingMention] = $nodesOfType(MentionNode);

      if (existingMention) {
        existingMention.replace(node);
      } else {
        const paragraphNode = $createParagraphNode();
        paragraphNode.append(node, $createTextNode(' '));
        $getRoot().append(paragraphNode);
      }
    });

    return editor.getEditorState();
  } catch (e) {
    console.log('Something went wrong generating lexical state.');
    return null;
  }
}

export async function getEditorFromObjectState(editorState: EditorState): Promise<LexicalEditor> {
  const editor = createHeadlessEditor({
    nodes,
    onError: (e) => console.log(e),
  });

  editor.setEditorState(editorState);

  return editor;
}

export function exportNodeToJSON<SerializedNode extends SerializedLexicalNode>(
  node: LexicalNode,
): SerializedNode {
  const serializedNode = node.exportJSON();
  const nodeClass = node.constructor;

  if (serializedNode.type !== nodeClass.getType()) {
    throw new Error(
      'LexicalNode: Node %s does not match the serialized type. Check if .exportJSON() is implemented and it is returning the correct type.',
    );
  }

  if ($isElementNode(node)) {
    const serializedChildren = (serializedNode as SerializedElementNode).children;
    if (!Array.isArray(serializedChildren)) {
      throw new Error(
        'LexicalNode: Node %s is an element but .exportJSON() does not have a children array.',
      );
    }

    const children = node.getChildren();

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const serializedChildNode = exportNodeToJSON(child);
      serializedChildren.push(serializedChildNode);
    }
  }

  // @ts-expect-error
  return serializedNode;
}

export enum EditorBlockType {
  RICH_TEXT = 'RICH_TEXT',
  IMAGE = 'IMAGE',
  BUTTON = 'BUTTON',
  DIVIDER = 'DIVIDER',
  RECOMMENDATION_ITEM = 'RECOMMENDATION_ITEM',
  PROMPT_ITEM = 'PROMPT_ITEM',
  CONTRIBUTOR_FOOTER = 'CONTRIBUTOR_FOOTER',
  DEFAULT_FOOTER = 'DEFAULT_FOOTER',
  INLINE_SUBSCRIBE = 'INLINE_SUBSCRIBE',
}

export type BlockDocumentRelatedData = DocumentRelatedDataCoreFragment;

// Generic info for rendering emails.
export type EditorBlock =
  | {
      type: EditorBlockType.RICH_TEXT;
      content: string;
      contentMarkdown: string;
      contentLexical: string;
    }
  | {
      type: EditorBlockType.RECOMMENDATION_ITEM;
      rec: RecommendationItemFragment;
      contentMarkdown: string;
      relatedData?: BlockDocumentRelatedData;
    }
  | {
      type: EditorBlockType.PROMPT_ITEM;
      prompt: PromptItemFragment;
      contentMarkdown: string;
      relatedData?: BlockDocumentRelatedData;
    }
  | {
      type: EditorBlockType.IMAGE;
      image: PerfImageFromFileFragment;
      link?: string;
      relatedData?: BlockDocumentRelatedData;
    }
  | {
      type: EditorBlockType.CONTRIBUTOR_FOOTER;
      data: DocumentRelatedDataCoreFragment;
    }
  | {
      type: EditorBlockType.BUTTON;
      text: string;
      url: string;
    }
  | {
      type: EditorBlockType.DIVIDER;
    }
  | {
      type: EditorBlockType.DEFAULT_FOOTER;
    }
  | {
      type: EditorBlockType.INLINE_SUBSCRIBE;
    };

export async function lexicalToBlocksFromDocument(
  document: DocumentFullFragment,
): Promise<EditorBlock[]> {
  const lexicalState = document!.contentLexical!;

  const editor = await getEditorFromState(lexicalState);

  const nodeSet = new Set();

  let finalBlocks = editor.getEditorState().read(() => {
    const root = $getRoot();
    // console.log(root.getTextContent());

    const topLevelChildren = root.getChildren();

    const blocks: EditorBlock[] = [];

    let currentRichText: LexicalNode[] = [];

    function appendRichTextBlock() {
      if (currentRichText.length === 1 && currentRichText[0]?.getTextContentSize() === 0) {
        return;
      }

      blocks.push({
        type: EditorBlockType.RICH_TEXT,
        content: '',
        contentMarkdown: '',
        contentLexical: JSON.stringify({
          root: {
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'root',
            version: 1,
            children: currentRichText.map(exportNodeToJSON),
          },
        }),
      });
      currentRichText = [];
    }

    for (const topLevelChild of topLevelChildren) {
      let node = topLevelChild;

      if ($isElementNode(node)) {
        const descendent = node.getLastDescendant();

        const imageNode = $isImageNode(descendent) ? descendent : null;
        if (imageNode) {
          node = imageNode;
        } else {
          const recNode = $isRecommendationNode(descendent) ? descendent : null;
          const promptNode = $isPromptNode(descendent) ? descendent : null;
          const componentNode = $isGenericComponentNode(descendent) ? descendent : null;
          if (recNode) {
            node = recNode;
          } else if (promptNode) {
            node = promptNode;
          } else if (componentNode) {
            node = componentNode;
          }
        }
      }

      let type = node.getType();

      if ([ParagraphNode.getType(), HeadingNode.getType()].includes(type)) {
        currentRichText.push(node);
      } else {
        if (currentRichText.length) {
          appendRichTextBlock();
        }
        switch (type) {
          case RecommendationNode.getType(): {
            const rec = (node as RecommendationNode).__rec;
            const relatedData = document.relatedData!.find((d) => d.targetRec?.id === rec.id);
            const freshRec = relatedData?.targetRec;
            blocks.push({
              type: EditorBlockType.RECOMMENDATION_ITEM,
              rec: freshRec || rec,
              contentMarkdown: '',
              relatedData,
            });
            break;
          }
          case PromptNode.getType(): {
            const prompt = (node as PromptNode).__prompt;
            const relatedData = document.relatedData!.find((d) => d.targetPrompt?.id === prompt.id);
            const freshPrompt = relatedData?.targetPrompt;
            blocks.push({
              type: EditorBlockType.PROMPT_ITEM,
              prompt: freshPrompt || prompt,
              contentMarkdown: '',
              relatedData,
            });
            break;
          }
          case ImageNode.getType(): {
            const imageNode = node as ImageNode;
            const image = imageNode.__image;
            const relatedData = document.relatedData!.find((d) => d.targetFile?.id === image?.id);
            const freshImage = relatedData?.targetFile;
            blocks.push({
              type: EditorBlockType.IMAGE,
              image: freshImage || image,
              link: imageNode.__link,
              relatedData,
            });
            break;
          }
          case GenericComponentNode.getType(): {
            const componentType = (node as GenericComponentNode).__componentType;
            const data = (node as GenericComponentNode).__data;

            if (componentType === 'custom-button') {
              blocks.push({
                type: EditorBlockType.BUTTON,
                text: data.text as string,
                url: data.url as string,
              });
            } else if (componentType === 'divider') {
              blocks.push({
                type: EditorBlockType.DIVIDER,
              });
            } else if (componentType === 'inline-subscribe') {
              blocks.push({
                type: EditorBlockType.INLINE_SUBSCRIBE,
              });
            }

            break;
          }
        }
      }
    }

    if (currentRichText.length) {
      appendRichTextBlock();
    }

    return blocks;
  });

  for (const block of finalBlocks) {
    if (block.type === EditorBlockType.RICH_TEXT) {
      block.content = await convertEditorStateToPlainText(block.contentLexical);
      block.contentMarkdown = await convertEditorStateToMarkdown(block.contentLexical);
    } else if (block.type === EditorBlockType.RECOMMENDATION_ITEM && block.rec.contentLexical) {
      block.contentMarkdown = await convertEditorStateToMarkdown(block.rec.contentLexical);
    } else if (block.type === EditorBlockType.PROMPT_ITEM && block.prompt.contentLexical) {
      block.contentMarkdown = await convertEditorStateToMarkdown(block.prompt.contentLexical);
    }
  }

  if (document.section === DocumentSection.PiClassic) {
    const introData = document.relatedData.find(
      (d) =>
        d.context.includes(DocumentRelatedDataContext.ContributorIntro) ||
        d.context.includes(DocumentRelatedDataContext.ContributorGraphic),
    );
    if (introData) {
      finalBlocks.push({
        type: EditorBlockType.CONTRIBUTOR_FOOTER,
        data: introData,
      });
    }
  }

  // console.log(finalBlocks.map((b) => b.type));

  // Filter out accidental new line blocks between two non rich text blocks (i.e. recommendations)
  // We'll let the renderer handle spacing.
  finalBlocks = finalBlocks.filter((block, index, arr) => {
    const next = arr[index + 1];
    const prev = arr[index - 1];

    if (
      next &&
      prev &&
      block.type === EditorBlockType.RICH_TEXT &&
      next.type !== EditorBlockType.RICH_TEXT &&
      prev.type !== EditorBlockType.RICH_TEXT
    ) {
      if (!block.content.trim().length) {
        return false;
      }
    }

    return true;
  });

  finalBlocks.push({
    type: EditorBlockType.DEFAULT_FOOTER,
  });

  // console.log(finalBlocks.map((b) => b.type));

  return finalBlocks;
}
