import React, { useCallback } from 'react';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { RiMoreFill } from 'react-icons/ri';
import { useMutation } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { copyToClipboard } from '../../../../clipboard';
import { BASE_SITE_URL } from '../../../../../siteconfig';
import { useRecommendationContext } from '../providers/useRecommendationContext';
import AddToListModal from '../../../../../../../apps/web2/app/components/modals/add-to-list-modal';
import { useListContext } from '../../../../providers/ListContext';
import useMutationWithAutoToast from '../../../../hooks/useMutationWithAutoToast';
import UpdateRecommendationModal from '../../../../../../../apps/web2/app/components/modals/update-recommendation-modal';
import { useAuth } from '../../../../hooks/useAuth';
import AddRecommendationModal from '../../../../../../../apps/web2/app/components/modals/add-recommendation-modal';
import {
  BOOKMARK_REC_MUTATION,
  DELETE_REC_MUTATION,
  REMOVE_RE_POST_REC_MUTATION,
  RE_POST_REC_MUTATION,
  UNBOOKMARK_REC_MUTATION,
  PIN_REC_MUTATION,
  UNPIN_REC_MUTATION,
  MUTE_REC_MUTATION,
  UNMUTE_REC_MUTATION,
  SHADOW_BAN_REC,
  UNDO_SHADOW_BAN_REC,
} from '../../../../graphql/RecommendationMutations';
import { isInWebView, safeSendMessageToReactNativeFromWebView } from '../../../../../webview';
import { GlobalWebViewMessages } from '../../../../../webview/messages';
import { CREATE_REPORT_MUTATION } from '../../../../graphql/UserReportMutations';
import { CURATE_REC_MUTATION, UNDO_CURATE_REC_MUTATION } from '../../../../graphql/CurateMutations';
import { isUserCurator, newConversationsEnabled } from '../../../../../gates';
import {
  DocumentRelatedDataType,
  RecType,
  UserFlagType,
} from '../../../../../__generated__/graphql';
import { useAnalytics } from '../../../../providers/AnalyticsContext';
import SendToConvoUsersModal from '../../../../../../../apps/web2/app/components/modals/send-to-convo-users-modal';
import { gql } from 'shared/__generated__';
import AdminSelectOrCreateUserModal, {
  ModalPurpose,
} from '../../../../../../../apps/web2/app/components/modals/admin-select-or-create-user-modal';
import { useDocumentWrapperContext } from '../../../../providers/DocumentWrapperContext';
import useDocumentHooks from '../../../../hooks/useDocumentHooks';
import { usePathname } from '../../../../hooks/routerHooks';

enum RECOMMENDATION_ACTIONS {
  COPY_LINK = 'COPY_LINK',
  ADD_TO_LIST = 'ADD_TO_LIST',
  BOOKMARK = 'BOOKMARK',
}

type Props = {
  _enabled?: Array<RECOMMENDATION_ACTIONS>;
  withLessPadding?: boolean;
};

const REC_REFETCH_QUERIES = [
  // 'genericRecConnection',
  'getHomeViewContainer',
  'getHomeView',
  'getListView',
  'getProfileView',
  'getProfileLowerRecommendations',
  // 'getFeedView',
  'getSearchView',
  'getAskViewRecsQuery',
  'getAskView',
];

function RecommendationActions({
  withLessPadding,
  _enabled = [
    RECOMMENDATION_ACTIONS.COPY_LINK,
    RECOMMENDATION_ACTIONS.ADD_TO_LIST,
    RECOMMENDATION_ACTIONS.BOOKMARK,
  ],
}: Props) {
  const { user } = useAuth();
  const { rec: parentRec } = useRecommendationContext();
  const { list } = useListContext();
  const { trackEvent } = useAnalytics();

  const rec = parentRec.conversation && parentRec.targetRec ? parentRec.targetRec : parentRec;

  const isUnpublishedRec = rec.type === RecType.UnpublishedPostRec;

  const toast = useToast();

  const onClickCopyLink = useCallback(() => {
    copyToClipboard(`${BASE_SITE_URL}/rec/${rec.id}`);
    trackEvent('Click', 'Copy_Rec_Link', {
      recId: rec.id,
      endorsementCount: rec.endorsementCount,
      isEndorsed: rec.isEndorsed,
      isRepost: rec.isReposted,
      commentsCount: rec.commentsCount,
      link: `${BASE_SITE_URL}/rec/${rec.id}`,
    });
    toast({
      title: 'Copied link to recommendtion',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, [rec.id, rec.endorsementCount, rec.isEndorsed, rec.isReposted, rec.commentsCount]);

  const [onMute] = useMutation(MUTE_REC_MUTATION, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      muteRec: {
        __typename: 'Rec',
        id: rec.id,
        muted: true,
      },
    },
  });

  const [onUnmute] = useMutation(UNMUTE_REC_MUTATION, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      unmuteRec: {
        __typename: 'Rec',
        id: rec.id,
        muted: false,
      },
    },
  });

  const onClickCopyLinkToTitleOnSubstack = useCallback(() => {
    copyToClipboard(`${rec?.feature?.url}#:~:text=${encodeURI(rec.title)}`);
    toast({
      title: 'Copied link to recommendtion',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, []);

  const onClickCopyLinkToRec = useCallback(() => {
    copyToClipboard(`${rec.url}#:~:text=${encodeURI(rec.title)}`);
    toast({
      title: 'Copied link to recommendtion',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, []);

  const [onShadowBanRec] = useMutation(SHADOW_BAN_REC, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      shadowBanRec: {
        __typename: 'Rec',
        id: rec.id,
        isShadowBanned: true,
      },
    },
    onError: (e) => {
      toast({
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        description: e.message,
      });
    },
  });

  const [onUndoShadowBanRec] = useMutation(UNDO_SHADOW_BAN_REC, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      undoShadowBanRec: {
        __typename: 'Rec',
        id: rec.id,
        isShadowBanned: false,
      },
    },
    onError: (e) => {
      toast({
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        description: e.message,
      });
    },
  });

  // const [removeRecFromList] = useMutationWithAutoToast(REMOVE_REC_LIST_MUTATION);
  const [deleteRec] = useMutationWithAutoToast(DELETE_REC_MUTATION);

  const [addRecToBookmarks] = useMutation(BOOKMARK_REC_MUTATION, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      addRecToBookmarks: {
        __typename: 'Rec',
        id: rec.id,
        isBookmarked: true,
      },
    },
    refetchQueries: ['getBookmarksView'],
  });

  const [removeRecFromBookmarks] = useMutation(UNBOOKMARK_REC_MUTATION, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      removeRecFromBookmarks: {
        __typename: 'Rec',
        id: rec.id,
        isBookmarked: false,
      },
    },
    refetchQueries: ['getBookmarksView'],
  });

  const [pinRec] = useMutation(PIN_REC_MUTATION, {
    variables: { recId: rec.id },
    onError: (e) => {
      if (e.graphQLErrors[0]?.extensions?.surfaceToUser) {
        toast({
          title: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    },
    optimisticResponse: {
      __typename: 'Mutation',
      pinRec: {
        __typename: 'Rec',
        id: rec.id,
        isPinned: true,
      },
    },
    refetchQueries: [
      'genericRecConnection',
      'getProfileLowerRecommendations',
      'getProfileRecommendationsView',
      'getProfileView',
    ],
  });

  const [unpinRec] = useMutation(UNPIN_REC_MUTATION, {
    variables: { recId: rec.id },
    optimisticResponse: {
      __typename: 'Mutation',
      unpinRec: {
        __typename: 'Rec',
        id: rec.id,
        isPinned: false,
      },
    },
    refetchQueries: [
      'genericRecConnection',
      'getProfileLowerRecommendations',
      'getProfileRecommendationsView',
      'getProfileView',
    ],
  });

  // const onRemoveFromList = useCallback(async () => {
  //   await removeRecFromList({
  //     variables: { recId: rec.id, listId: list.id },
  //     refetchQueries: ['getListView', 'getProfileView'],
  //     optimisticResponse: {
  //       removeRecFromList: {
  //         list: {
  //           __typename: 'List',
  //           id: list.id,
  //           listEntries: list.listEntries.filter((e) => e.rec.id !== rec.id),
  //         },
  //       },
  //     },
  //   });
  // }, [removeRecFromList, list, rec]);

  const pathname = usePathname();

  const onDeleteRec = useCallback(async () => {
    await deleteRec({
      variables: { recId: rec.id },
      refetchQueries: REC_REFETCH_QUERIES,
      optimisticResponse: {
        deleteRec: true,
      },
      update(cache) {
        const normalizedId = cache.identify({ id: rec.id, __typename: 'Rec' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
    if (pathname.includes('/rec/')) {
      router.navigate({ to: '/', replace: true });
    }
  }, [deleteRec, list, rec]);

  const [createReport] = useMutation(CREATE_REPORT_MUTATION, {
    variables: { targetId: rec?.id, targetType: 'rec', context: 'REPORT' },
  });

  const [onCurateRec] = useMutation(CURATE_REC_MUTATION, {
    variables: { recId: rec?.id as string },
    optimisticResponse: {
      __typename: 'Mutation',
      curateRec: {
        __typename: 'Rec',
        id: rec.id,
        isCurated: true,
      },
    },
  });

  const [onUndoCurateRec] = useMutation(UNDO_CURATE_REC_MUTATION, {
    variables: { recId: rec?.id as string },
    optimisticResponse: {
      __typename: 'Mutation',
      undoCurateRec: {
        __typename: 'Rec',
        id: rec.id,
        isCurated: false,
      },
    },
  });

  const onCreateReport = useCallback(() => {
    createReport();
    trackEvent('Click', 'Report_Rec', {
      recId: rec.id,
      endorsementCount: rec.endorsementCount,
      isEndorsed: rec.isEndorsed,
      isRepost: rec.isReposted,
      commentsCount: rec.commentsCount,
    });
  }, [
    createReport,
    rec.id,
    rec.endorsementCount,
    rec.isEndorsed,
    rec.isReposted,
    rec.commentsCount,
  ]);

  const handleMute = useCallback(() => {
    if (rec.muted) {
      onUnmute();
      trackEvent('Click', 'Unmute_Rec', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    } else {
      onMute();
      trackEvent('Click', 'Mute_Rec', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    }
  }, [rec.id, rec.endorsementCount, rec.isEndorsed, rec.isReposted, rec.commentsCount, rec.muted]);

  const router = useRouter();
  const menuState = useDisclosure();

  const canEdit =
    user?.id === rec.user?.id ||
    (user?.role === 'ADMIN' && rec.type === RecType.UnpublishedPostRec) ||
    (user?.role === 'ADMIN' && rec.user && rec.user.flag.includes(UserFlagType.Guest));

  const handlePin = useCallback(() => {
    if (!user) {
      router.navigate({ to: '/sign-in' });
      trackEvent('Click', 'Redirect_To_Sign_In', {
        targetType: 'Rec',
        targetId: rec.id,
        clickTarget: 'Pin',
      });
      return;
    }

    if (rec.isPinned) {
      unpinRec();
      trackEvent('Click', 'Unpin', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    } else if (user.pinnedRecCount >= 5) {
      alert('Max (5) pinned recs. Unpin one and try again.');
    } else {
      pinRec();
      trackEvent('Click', 'Pin', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    }
  }, [user, router, rec.isPinned, rec.id, router, unpinRec, pinRec, trackEvent]);

  const handleBookmark = useCallback(() => {
    if (!user) {
      router.navigate({ to: '/sign-in' });
      trackEvent('Click', 'Redirect_To_Sign_In', {
        targetType: 'Rec',
        targetId: rec.id,
        clickTarget: 'Bookmark',
      });
      return;
    }

    if (rec.isBookmarked) {
      removeRecFromBookmarks();
      trackEvent('Click', 'Remove_Bookmark_Rec', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    } else {
      addRecToBookmarks();
      trackEvent('Click', 'Bookmark_Rec', {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    }
  }, [
    rec.id,
    rec.endorsementCount,
    rec.isEndorsed,
    rec.isReposted,
    rec.commentsCount,
    rec.isBookmarked,
    user,
    router,
  ]);

  const handleDeleteRec = useCallback(async () => {
    const { id: recId, endorsementCount, isEndorsed, isReposted: isRepost, commentsCount } = rec;
    trackEvent('Click', 'Delete_Rec_Dialog', {
      recId,
      endorsementCount,
      isEndorsed,
      isRepost,
      commentsCount,
    });
    const confirmed = confirm('Delete rec?');
    if (confirmed) {
      await onDeleteRec();
      trackEvent('Click', 'Delete_Rec', {
        recId,
        endorsementCount,
        isEndorsed,
        isRepost,
        commentsCount,
      });
    }
  }, [rec.id, rec.endorsementCount, rec.isEndorsed, rec.isReposted, rec.commentsCount]);

  return (
    <Flex position="absolute" top={0} right={0} p={{ base: '12px', md: '16px' }}>
      <Menu
        // isLazy
        {...menuState}
        onOpen={() => {
          if (isInWebView()) {
            safeSendMessageToReactNativeFromWebView(
              GlobalWebViewMessages.OPEN_REC_ACTION_MENU,
              rec,
            );
          } else {
            menuState.onOpen();
          }
        }}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<RiMoreFill />}
          // mt="-8px"
          variant="icon"
          height="fill-content"
        />

        <MenuList zIndex={1401}>
          {canEdit && <RecommendationEdit isAdmin={user?.role === 'ADMIN'} />}
          {user && newConversationsEnabled(user) && <RecommendationSendTo />}
          {!isUnpublishedRec ? (
            <>
              {user?.id === rec.user?.id && (
                <MenuItem closeOnSelect={false} onClick={handlePin}>
                  {!rec.isPinned ? 'Pin this' : 'Unpin this'}
                </MenuItem>
              )}
              {!!user && rec.type !== RecType.RePost && (
                <RecommendationRepost menuState={menuState} />
              )}
              {user && isUserCurator(user) && (
                <MenuItem
                  closeOnSelect={false}
                  onClick={!rec.isCurated ? () => onCurateRec() : () => onUndoCurateRec()}
                >
                  {!rec.isCurated ? 'Curate for Homepage' : 'Undo Curate'}
                </MenuItem>
              )}
              <MenuItem closeOnSelect={false} onClick={handleBookmark}>
                {rec.isBookmarked ? 'Forget this' : 'Remember this'}
              </MenuItem>
              {/* <MenuItem as={Link} href={`/rec/${rec.id}`} closeOnSelect={false}>
            View Rec
          </MenuItem> */}
              {!!user && <RecommendationAddToList />}
              {/* {user && list && list.user.id === user.id && (
            <MenuItem closeOnSelect onClick={onRemoveFromList}>
              Remove from List
            </MenuItem>
          )} */}
              <MenuItem closeOnSelect={false} onClick={onClickCopyLink}>
                Copy Link
              </MenuItem>
              {user && (
                <MenuItem closeOnSelect={false} onClick={handleMute}>
                  {rec.muted ? 'Unmute Notifications' : 'Mute Notifications'}
                </MenuItem>
              )}
              {user && user.role === 'ADMIN' && rec?.feature?.url && (
                <MenuItem closeOnSelect={false} onClick={onClickCopyLinkToTitleOnSubstack}>
                  Copy Post Link (Admin)
                </MenuItem>
              )}
              {user && user.role === 'ADMIN' && (
                <MenuItem closeOnSelect={false} onClick={onClickCopyLinkToRec}>
                  Copy Rec URL (Admin)
                </MenuItem>
              )}
              {user && user.role === 'ADMIN' && (
                <MenuItem
                  closeOnSelect={false}
                  onClick={() => (!rec.isShadowBanned ? onShadowBanRec() : onUndoShadowBanRec())}
                >
                  {!rec.isShadowBanned ? 'Shadow Ban Rec (Admin)' : 'Undo Shadow Ban (Admin)'}
                </MenuItem>
              )}
              {user && <MenuItem onClick={onCreateReport}>Report</MenuItem>}
              <DocumentAddUserToRec />
              <MenuDivider />
              {user?.id === rec.user?.id && (
                <MenuItem closeOnSelect onClick={handleDeleteRec}>
                  Delete
                </MenuItem>
              )}
            </>
          ) : (
            <>
              <MenuItem closeOnSelect={false} onClick={onClickCopyLink}>
                Copy Link
              </MenuItem>
              <DocumentAddUserToRec />
            </>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}

function RecommendationAddToList() {
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();
  const { rec } = useRecommendationContext();

  const toggleModal = useCallback(() => {
    const beforeModalState = modalState.isOpen;
    modalState.onToggle();
    trackEvent('Click', `${!beforeModalState ? 'Open' : 'Close'}_Add_To_List_Modal`, {
      recId: rec.id,
      endorsementCount: rec.endorsementCount,
      isEndorsed: rec.isEndorsed,
      isRepost: rec.isReposted,
      commentsCount: rec.commentsCount,
    });
  }, [modalState]);

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={toggleModal}>
        Add To List
      </MenuItem>
      {modalState.isOpen && (
        <AddToListModal modalState={modalState} />
      )}
    </>
  );
}

function DocumentAddUserToRec() {
  const auth = useAuth();

  const modalState = useDisclosure();

  const { updateDocumentRelatedDataOptimistic } = useDocumentHooks();

  const { rec } = useRecommendationContext();

  const { document } = useDocumentWrapperContext();

  const relatedDataForRec = document?.relatedData?.find(
    (data) =>
      data.type === DocumentRelatedDataType.RecommendationItem && data.targetRec?.id === rec.id,
  );

  if (!relatedDataForRec || auth.user?.role !== 'ADMIN') {
    return null;
  }

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={modalState.onToggle}>
        {relatedDataForRec.targetUser ? 'Update' : 'Add'} Rec User
      </MenuItem>
      {relatedDataForRec.targetUser && false && (
        <MenuItem
          closeOnSelect={false}
          onClick={() =>
            updateDocumentRelatedDataOptimistic(relatedDataForRec, {
              targetUserId: null,
              misc: {
                ...(relatedDataForRec.misc || {}),
                userId: null,
              } as any,
            })
          }
        >
          Remove Rec User
        </MenuItem>
      )}
      {modalState.isOpen && (
        <AdminSelectOrCreateUserModal
          modalState={modalState}
          purpose={ModalPurpose.DocumentDraft}
          onSelect={(users) => {
            updateDocumentRelatedDataOptimistic(relatedDataForRec, {
              targetUserId: users[0].id,
              misc: {
                ...(relatedDataForRec.misc || {}),
                userId: users[0].id,
              } as any,
            });
          }}
        />
      )}
    </>
  );
}

function RecommendationRepost({ menuState }) {
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();

  const { rec } = useRecommendationContext();

  const toast = useToast();

  const [repostRec] = useMutationWithAutoToast(RE_POST_REC_MUTATION, {
    variables: { targetRecId: rec.id },
    successMessage: "Re-Rec'd",
    shouldToastOnError: false,
    optimisticResponse: {
      repostRec: {
        __typename: 'Rec',
        id: '',
        isReposted: true,
        repostTargetRec: {
          id: rec.id,
          isReposted: true,
        },
      },
    },
    onCompleted: () => {
      menuState.onClose();
    },
    refetchQueries: REC_REFETCH_QUERIES,
  });

  const [removeRepost] = useMutationWithAutoToast(REMOVE_RE_POST_REC_MUTATION, {
    variables: { targetRecId: rec.id },
    successMessage: 'Removed re-rec',
    shouldToastOnError: false,
    optimisticResponse: {
      removeRepost: {
        __typename: 'Rec',
        id: '',
        isReposted: false,
      },
    },
    onCompleted: () => {
      menuState.onClose();
    },
    refetchQueries: REC_REFETCH_QUERIES,
  });

  const auth = useAuth();
  const router = useRouter();

  const Rerec = useCallback(async () => {
    menuState.onClose();

    if (auth.user) {
      if (rec.isReposted) {
        await removeRepost({
          variables: { targetRecId: rec.id },
          optimisticResponse: {
            removeRepost: {
              __typename: 'Rec',
              id: '',
              isReposted: false,
            },
          },
          onCompleted: () => {
            menuState.onClose();
          },
          refetchQueries: REC_REFETCH_QUERIES,
        });
        trackEvent('Click', 'Remove_ReRec', {
          recId: rec.id,
          endorsementCount: rec.endorsementCount,
          isEndorsed: rec.isEndorsed,
          isRepost: rec.isReposted,
          commentsCount: rec.commentsCount,
        });
        toast({
          title: 'Removed re-rec',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await repostRec({
          variables: { targetRecId: rec.id },
          optimisticResponse: {
            repostRec: {
              __typename: 'Rec',
              id: '',
              isReposted: true,
              repostTargetRec: {
                id: rec.id,
                isReposted: true,
              },
            },
          },
          onCompleted: () => {
            menuState.onClose();
          },
          refetchQueries: REC_REFETCH_QUERIES,
        });
        trackEvent('Click', 'ReRec', {
          recId: rec.id,
          endorsementCount: rec.endorsementCount,
          isEndorsed: rec.isEndorsed,
          isRepost: rec.isReposted,
          commentsCount: rec.commentsCount,
        });
        toast({
          title: 'Re-Rec’d',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      trackEvent('Click', 'Redirect_To_Sign_In', {
        targetType: 'Rec',
        targetId: rec.id,
        clickTarget: 'ReRec',
      });
      router.navigate({ to: '/sign-in' });
    }
  }, [
    rec.id,
    rec.endorsementCount,
    rec.isEndorsed,
    rec.isReposted,
    rec.commentsCount,
    auth,
    router,
  ]);

  const toggleModal = useCallback(() => {
    if (auth.user) {
      const beforeModalState = modalState.isOpen;
      modalState.onToggle();
      trackEvent('Click', `${!beforeModalState ? 'Open' : 'Close'}_ReRec_With_Comment_Modal`, {
        recId: rec.id,
        endorsementCount: rec.endorsementCount,
        isEndorsed: rec.isEndorsed,
        isRepost: rec.isReposted,
        commentsCount: rec.commentsCount,
      });
    } else {
      trackEvent('Click', 'Redirect_To_Sign_In', {
        targetType: 'Rec',
        targetId: rec.id,
        clickTarget: 'ReRec With Comment',
      });
      router.navigate({ to: '/sign-in' });
    }
  }, [
    modalState,
    auth,
    router,
    rec.id,
    rec.endorsementCount,
    rec.isEndorsed,
    rec.isReposted,
    rec.commentsCount,
  ]);

  return (
    <>
      {((auth.user && auth.user?.id !== rec.user?.id) || !auth.user) && (
        <MenuItem closeOnSelect={false} onClick={Rerec}>
          {rec.isReposted ? 'Undo Re-Rec' : 'Re-Rec'}
        </MenuItem>
      )}
      {auth.user && (
        <MenuItem closeOnSelect={false} onClick={toggleModal}>
          Re-Rec With Comment
        </MenuItem>
      )}
      {modalState.isOpen && (
        <AddRecommendationModal modalState={modalState} repostRec={rec} />
      )}
    </>
  );
}

function RecommendationEdit({ isAdmin }: { isAdmin: boolean }) {
  const { rec } = useRecommendationContext();
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();

  const toggleModal = useCallback(() => {
    const beforeModalState = modalState.isOpen;
    modalState.onToggle();
    trackEvent('Click', `${!beforeModalState ? 'Open' : 'Close'}_Edit_Rec_Modal`, {
      recId: rec.id,
      endorsementCount: rec.endorsementCount,
      isEndorsed: rec.isEndorsed,
      isRepost: rec.isReposted,
      commentsCount: rec.commentsCount,
    });
  }, [modalState, rec.id, rec.endorsementCount, rec.isEndorsed, rec.isReposted, rec.commentsCount]);

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={toggleModal}>
        Edit{isAdmin ? ' (Admin)' : ''}
      </MenuItem>
      {modalState.isOpen && (
        <UpdateRecommendationModal modalState={modalState} rec={rec} />
      )}
    </>
  );
}

function RecommendationSendTo() {
  const { rec } = useRecommendationContext();
  const modalState = useDisclosure();

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={modalState.onToggle}>
        Send
      </MenuItem>
      {modalState.isOpen && (
        <SendToConvoUsersModal modalState={modalState} recId={rec.id} />
      )}
    </>
  );
}

export const IsCuratedQuery = gql(/* GraphQL */ `
  query isCurated($recId: String!) {
    rec(id: $recId) {
      isCurated
    }
  }
`);

// const REMOVE_REC_LIST_MUTATION = gql(/* GraphQL */ `
//   mutation removeRecFromListButton($recId: String!, $listId: String!) {
//     removeRecFromList(recId: $recId, listId: $listId) {
//       list {
//         id
//         listEntries {
//           id
//           position
//           rec {
//             ...RecommendationItem
//           }
//         }
//       }
//     }
//   }
// `);

export default RecommendationActions;
