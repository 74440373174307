import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import {
  Link as BasicLink,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import RecommendationItem from 'web2/app/components/by-type/recommendations/recommendation-item';
import { gql } from 'shared/__generated__/gql';
import { InView } from 'react-intersection-observer';
import { Controller, useForm } from 'react-hook-form';
import { useBackButton } from 'shared/misc/hooks/useBackButton';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { ModalNames, useModalState } from 'shared/misc/wrappers/ModalProvider';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { TWLink } from '../../../../components/link';

type RecommendationViewProps = {
  username: string;
};

gql(/* GraphQL */ `
  fragment ProfileRecommendationsView on User {
    id
    email
    firstName
    lastName
    username
    listCount
    profileTheme
    recConnection(first: $first, after: $after, orderBy: $orderBy, includePromptRecs: true) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getProfileRecommendationsView(
    $username: String!
    $first: Int
    $after: String
    $orderBy: String
  ) {
    user(username: $username) {
      ...ProfileRecommendationsView
    }
  }
`);

type Inputs = {
  orderBy: 'oldestFirst' | 'newestFirst';
};

const PAGE_LENGTH = 20;
export const Route = createFileRoute('/u/$username/recs/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { username } = Route.useParams();
  const router = useRouter();
  const themeColors = useThemeColors();
  const strokeColor = themeColors.titleBorder;

  const { watch, control } = useForm<Inputs>({ defaultValues: { orderBy: 'pinnedFirst' } });
  const { orderBy } = watch();

  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    refetch,
    loading,
  } = useQuery(PageQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      username,
      orderBy: 'pinnedFirst',
      first: PAGE_LENGTH,
    },
  });

  useEffect(() => {
    if (variables && variables.orderBy !== orderBy) {
      refetch({
        username,
        orderBy,
        first: PAGE_LENGTH,
        after: null,
      });
    }
  }, [orderBy, variables]);

  const getNextPage = async () => {
    if (loading || !data?.user?.recConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        first: PAGE_LENGTH,
        after: data?.user?.recConnection.pageInfo.endCursor,
      },
    });
  };

  const auth = useAuth();
  const addRecommendationModalState = useModalState(ModalNames.ADD_RECOMMENDATION);

  const profile = data?.user;

  const { getCanGoBack, goBack } = useBackButton();

  useAutoSetTheme(profile?.profileTheme || null);

  if (loading && !profile) {
    return null;
  }

  if (!profile) {
    router.navigate({ to: '/not-found' });
  }

  const recommendations = profile.recConnection.edges;

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack
        spacing="24px"
        width={{ base: '100%', md: '900px' }}
        // width="100%"
      >
        {getCanGoBack() && (
          <BasicLink variant="stylized" onClick={goBack} width="fit-content">
            Back
          </BasicLink>
        )}
        <Stack spacing="24px" justifyContent="center">
          <h1 className="typography-heading-lg text-brand-highlight mb-[24px] mt-[48px] uppercase text-center">
            <TWLink
              to={`/u/${profile.username}`}
              css={{
                WebkitTextStroke: '2px',
                WebkitTextStrokeColor: strokeColor,
              }}
            >
              @{profile.username}
            </TWLink>
            'S RECS
          </h1>
          {recommendations.length !== 0 && (
            <Flex alignItems="center" mx="8" mt="20px" mb="24px" justifyContent="space-between">
              <Text textStyle="brand.body">{/* Recommendations */}</Text>
              <Controller
                control={control}
                name="orderBy"
                render={({ field: { ref, onChange } }) => (
                  <Menu>
                    <MenuButton as={Button} variant="link" textStyle="brand.actionSm" ref={ref}>
                      Order by ({orderBy === 'newestFirst' ? 'Recent' : 'Ancient'})
                    </MenuButton>
                    <MenuList zIndex="100" width="fit-content">
                      <MenuItem onClick={() => onChange('pinnedFirst')}>Pinned first</MenuItem>
                      <MenuItem onClick={() => onChange('newestFirst')}>Recent</MenuItem>
                      <MenuItem onClick={() => onChange('oldestFirst')}>Ancient</MenuItem>
                    </MenuList>
                  </Menu>
                )}
              />
            </Flex>
          )}
          {recommendations.length === 0 && (
            <Stack
              spacing="24px"
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              {profile?.id === auth.user?.id ? (
                <>
                  <Box>
                    <Text textStyle="brand.headingSm" textAlign="center">
                      You don't have any recs yet.
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      ml="6px"
                      variant="link"
                      onClick={addRecommendationModalState.onOpen}
                      padding="0px"
                    >
                      Write your first?
                    </Button>
                  </Box>
                </>
              ) : (
                <Box>
                  <Text textStyle="brand.headingSm" textAlign="center">
                    <TWLink to={`/u/${profile.username}`}>@{profile.username}</TWLink> hasn't
                    written any recomendations yet.
                  </Text>
                </Box>
              )}
            </Stack>
          )}
          <Stack spacing="48px" justifyContent="center">
            {recommendations?.map(({ node }) => (
              <RecommendationItem key={node.id} rec={node} showPrompt withPinnedIcon />
            ))}
          </Stack>
          <InView
            as="div"
            rootMargin="4000px 0px"
            onChange={(inView) => {
              if (inView) {
                getNextPage();
              }
            }}
          />
          {loading && <Spinner size="lg" placement="center" />}
        </Stack>
      </Stack>
    </Box>
  );
}
