import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  UseDisclosureReturn,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Modal, ModalContent, ModalFooter } from '../ui/modal';
import { useMutation, useQuery } from '@apollo/client';
import { SubmitHandler } from 'react-hook-form';
import _ from 'lodash';
import { EditorState } from 'lexical';
import { useDropzone } from 'react-dropzone-esm';
import { safeParse } from 'web2/app/components/by-type/rich-text/basic/basic-editor';
import { gql } from 'shared/__generated__';
import {
  RecType,
  RecommendationAttachmentFragment,
  RecommendationItemFragment,
} from 'shared/__generated__/graphql';
import { processLexicalState } from 'shared/lexical';
import useRecommendationForm, {
  RecFormHandle,
  RecFormInputs,
} from '../by-type/recommendations/use-rec-form';
import { isInWebView, safeSendMessageToReactNativeFromWebView } from 'shared/webview';
import { UpdateRecommendationMessages } from 'shared/webview/messages';
import useRecPhoto from '../by-type/recommendations/hooks/use-rec-photo';
import { useDocumentWrapperContext } from 'shared/misc/providers/DocumentWrapperContext';
import { DocumentRenderMode } from 'shared/types';

type Inputs = {
  url?: string | undefined;
  // As Json
  contentLexicalState: EditorState | undefined | null;
  titleLexicalState: EditorState | undefined | null;
  emoji: string;
};

type UpdateRecommendationModalProps = {
  rec: RecommendationItemFragment;
  modalState?: UseDisclosureReturn;
};

export default function UpdateRecommendationModal({
  modalState: initialModalState,
  rec,
}: UpdateRecommendationModalProps) {
  const { isOpen, onClose, onToggle } = initialModalState || useDisclosure();
  const toast = useToast();

  const IS_IN_WEB_VIEW = isInWebView();

  const [updateRec, updateRecState] = useMutation(UPDATE_REC, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getListView', 'getProfileView'],
  });

  const formRef = useRef<RecFormHandle>(null);

  const isUnPublished = rec.type === RecType.UnpublishedPostRec;

  const {
    onUpload,
    onRemoveAttachment: onRemoveAttachmentRecPhoto,
    attachments: uploadedAttachments,
    reset,
    setAttachments,
  } = useRecPhoto();

  const [otherAttachments, setOtherAttachments] = useState<RecommendationAttachmentFragment[]>([]);

  const [removeAttachmentMap, setRemoveAttachment] = useState({});

  const allAttachments = [
    ...(uploadedAttachments || []),
    ...(rec.attachments || []),
    ...otherAttachments,
  ]
    .filter((attachment) => attachment && !removeAttachmentMap[attachment.id!])
    .sort((a, b) => a.position - b.position);

  const clearAttachments = () => {
    setAttachments([]);
    setOtherAttachments([]);
    setRemoveAttachment({});
  };

  const { mode } = useDocumentWrapperContext();

  const shouldAutoSave = mode === DocumentRenderMode.EDIT && isUnPublished;

  const attachmentIds = allAttachments.map((attachment) => attachment?.id);

  useEffect(() => {
    if (attachmentIds && shouldAutoSave) {
      onSaveDebounced(formRef.current!.getValues());
    }
  }, [attachmentIds]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (values, event) => {
      const { titleLexicalState, url = '', contentLexicalState = null, emoji = '' } = values;
      event?.preventDefault();

      if (!rec) return;

      const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
      const { text: title } = processLexicalState(titleLexicalState);

      updateRec({
        variables: {
          recId: rec.id,
          attachmentIds: attachmentIds.filter((id) => !removeAttachmentMap[id]),
          removeAttachmentIds: shouldAutoSave ? [] : Object.keys(removeAttachmentMap),
          input: {
            title,
            url,
            emoji,
            content,
            contentLexical,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateRec: {
            __typename: 'Rec',
            id: rec.id,
            title,
            url,
            content,
            contentLexical,
            emoji,
            contentHTML: rec.contentHTML,
            updatedAt: rec.updatedAt,
            createdAt: rec.createdAt,
            attachments: rec.attachments,
          },
        },
        onCompleted: async () => {
          if (!shouldAutoSave) {
            toast({
              title: 'Recommendation saved.',
              status: 'success',
              duration: 2000,
              isClosable: true,
              position: 'top',
            });
            onClose();
          }
        },
        onError: () => {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      });
    },
    [rec, shouldAutoSave, attachmentIds],
  );

  const onSaveDebounced = useMemo(
    () =>
      _.debounce((values: RecFormInputs) => {
        if (formRef.current && shouldAutoSave) {
          onSubmit(values);
        }
      }, 1000),
    [onSubmit, shouldAutoSave],
  );

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  useEffect(() => {
    const values = formRef.current?.getValues();
    if (shouldAutoSave && values) {
      onSaveDebounced(values);
    }
  }, [attachmentIds]);

  const isRepost = [RecType.RePost, RecType.RePostWithContent].includes(rec.type);

  const onDrop = useCallback(
    async (files) => {
      if (files?.length > 5 || allAttachments?.length + files?.length > 5) {
        toast({
          title: 'You can only have 5 images per post.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      } else if (files?.length && rec?.id) {
        let position = allAttachments.length;
        for (const file of files) {
          await onUpload(file, undefined, position);
          position++;
        }
      }
    },
    [rec],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 5,
    multiple: true,
    noClick: true,
    onError: () => {
      toast({
        title: 'An error occured...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const hasTarget = !!rec.targetPrompt || !!rec.repostRec;

  const { submitButtonNode, mainFormNode, editImageNode, isDirty } = useRecommendationForm({
    defaultValues: {
      title: rec.title || '',
      url: rec.url || '',
      emoji: rec.emoji || '😃',
      contentLexicalState: safeParse(rec?.contentLexical) || '',
    },
    disableTitle: isRepost,
    editorHeight: '250px',
    maxBodyEditorHeight: hasTarget ? '250px' : '500px',
    ref: formRef,
    onSubmit,
    submitText: 'Save',
    isSubmitting: updateRecState.loading,
    attachments: allAttachments,
    onChange: shouldAutoSave ? (values) => onSaveDebounced(values) : undefined,
    onUpload,
    onOpenFilePicker: () => {
      if (rec) {
        if (IS_IN_WEB_VIEW) {
          safeSendMessageToReactNativeFromWebView(
            UpdateRecommendationMessages.OPEN_IMAGE_PICKER,
            rec?.id,
          );
        } else {
          open();
        }
      }
    },
    moveImages: (newOrder: { id: string; position: number }[]) => {
      if (IS_IN_WEB_VIEW) {
        safeSendMessageToReactNativeFromWebView(UpdateRecommendationMessages.MOVE_IMAGES, rec?.id);
      } else {
        const newAttachments = uploadedAttachments.map((attachment, index) => {
          return {
            ...attachment,
            position: newOrder.find((o) => o.id === attachment.id)?.position,
          };
        });
        setAttachments(() => newAttachments);
      }
    },
    onAddAttachment: (attachment) => {
      setOtherAttachments((existing) => [...existing, attachment]);
    },
    onRemoveAttachment: (id) => {
      // onRemoveAttachmentRecPhoto({ attachmentId: id!, recId: rec.id });
      setRemoveAttachment({ ...removeAttachmentMap, [id!]: true });
    },
  });

  const onCloseCheck = () => {
    // if (isDirty) {
    //   const confirm = window.confirm('You have unsaved changes. Are you sure you want to close?');
    //   if (confirm) {
    //     onClose();
    //   }
    // } else {
    //   onClose();
    // }
  };

  const containerProps = {
    maxH: { base: '100dvh', md: '90%' },
    // minHeight={IS_IN_WEB_VIEW ? '100dvh' : undefined}
    height: { base: '100dvh', md: '800px' },
    maxW: '100%',
    width: { base: '100vw', md: '60vw' },
    ml: modalMLR,
    mr: modalMLR,
    mb: modalMTB,
    mt: modalMTB,
    cursor: isDragActive ? 'pointer' : undefined,
    p: { base: '12px', md: '24px', lg: '48px' },
    paddingTop: IS_IN_WEB_VIEW ? '48px' : undefined,
    border: IS_IN_WEB_VIEW ? '0px' : undefined,
  };

  return (
    <Modal open={isOpen} onOpenChange={onToggle}>
      <ModalContent
        hideCloseButton={IS_IN_WEB_VIEW}
        {...getRootProps()}
        {...containerProps}
        className={`${IS_IN_WEB_VIEW ? 'border-0 pt-[48px] md:pt-[72px] lg:pt-[124px]' : ''}`}
      >
        <Box h="100%" p="6px" mb={{ base: '12px', md: '24px' }} fontSize="16px" overflowY="scroll">
          {mainFormNode}
          <input {...getInputProps()} />
        </Box>
        <ModalFooter
          className={`absolute left-0 w-full bg-brand-background p-[12px] ${
            IS_IN_WEB_VIEW ? 'top-0' : 'bottom-0'
          }`}
        >
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Box>
              {IS_IN_WEB_VIEW ? (
                <Button
                  size="sm"
                  onClick={() => {
                    onClose();
                  }}
                  variant="textOnly"
                  color="brand.lightgrey"
                  _hover={{ color: 'brand.red' }}
                  border="none"
                  textStyle="brand.actionSm"
                >
                  Cancel
                </Button>
              ) : (
                <Box />
              )}
              {/* {editImageNode} */}
            </Box>
            {!shouldAutoSave ? (
              submitButtonNode
            ) : (
              <Button
                onClick={() => {
                  onClose();
                }}
                variant="primary"
                textStyle="brand.actionSm"
              >
                Done
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

gql(/* GraphQL */ `
  fragment RecUpdateRecommendationModal on Rec {
    id
    title
    url
    content
    contentHTML
    contentLexical
    emoji
    updatedAt
    createdAt
    attachments {
      ...RecommendationAttachment
    }
  }
`);

const UPDATE_REC = gql(/* GraphQL */ `
  mutation updateRec(
    $recId: String!
    $attachmentIds: [String!]
    $attachmentId: String
    $removeAttachmentIds: [String!]
    $input: RecInput!
  ) {
    updateRec(
      recId: $recId
      input: $input
      attachmentIds: $attachmentIds
      attachmentId: $attachmentId
      removeAttachmentIds: $removeAttachmentIds
    ) {
      ...RecUpdateRecommendationModal
    }
  }
`);
