import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { ActivateWeeklyContentDigestDocument, EmailListType } from 'shared/__generated__/graphql';
import emailPreferenceOptions from 'shared/misc/emailPreferenceOptions';
import { useMutation } from '@apollo/client';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { PI_CLASSIC_COLORS } from 'shared/misc/theme/custom/custom-theme-colors';
import { useAuth } from 'shared/misc/hooks/useAuth';
import Logo from 'shared/misc/components/logo-horizontal.svg?react';
import { z } from 'zod';
import { gql } from 'shared/__generated__';
import { TWLink } from '../../../components/link';
import { Button as TWButton } from '../../../components/button';
import { useBreakpoint } from 'shared/misc/providers/breakpoint-provider';
import { useToast } from '@chakra-ui/react';

const REMOVE_EMAIL_TYPES = gql(/* GraphQL */ `
  mutation removeEmailTypes1($emailTypes: [EmailListType!]!, $key: String) {
    removeEmailTypes(emailTypes: $emailTypes, key: $key) {
      emailLists
      id
      email
    }
  }
`);

export const Route = createFileRoute('/unsubscribe/email-List/$emailList')({
  component: RouteComponent,
  validateSearch: z.object({
    key: z.string().optional(),
  }),
});

function RouteComponent() {
  const params = Route.useParams();
  const { key } = Route.useSearch();
  const isMd = useBreakpoint('md');
  const getDataFromParams = (params: { emailList: string }) => {
    return emailPreferenceOptions.find((option) => option.emailTypes.includes(params.emailList));
  };
  const {
    title,
    subtitle,
    emailTypes = [params.emailList || EmailListType.WeeklyContentDigest],
  } = getDataFromParams(params) || {};
  const router = useRouter();
  const toast = useToast();
  const auth = useAuth();

  const [removeEmailTypes, removeEmailTypesInfo] = useMutation(REMOVE_EMAIL_TYPES, {
    variables: { emailTypes, key },
    onError: (e) => {
      console.log(e);
      toast({
        title: 'Something went wrong. Redirecting you to Email Preferences.',
        status: 'error',
        duration: 3000,
        isClosable: false,
        position: 'top',
      });
    },
  });

  const [activateWeeklyContentDigest, activateWeeklyContentDigestInfo] = useMutation(
    ActivateWeeklyContentDigestDocument,
    {
      variables: { key },
      onError: (e) => {
        console.log(e);
        toast({
          title: 'Something went wrong. Redirecting you to Email Preferences.',
          status: 'error',
          duration: 3000,
          isClosable: false,
          position: 'top',
        });
      },
    },
  );

  const { loading, error } = removeEmailTypesInfo;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        router.navigate({
          to: `${BASE_SITE_URL}/sign-in?destination=${encodeURIComponent(
            '/settings/manage/email',
          )}`,
        });
      }, 1500);
    }
  }, [error]);

  return (
    <div
      className="flex justify-center w-screen h-screen p-6"
      style={{
        backgroundColor: PI_CLASSIC_COLORS.background,
        backgroundImage: "url('https://files.pi.fyi/star-background.png')",
      }}
    >
      <div className="space-y-3 mt-6 sm:mt-32 md:w-[764px] w-[364px]">
        <div className="flex justify-center items-center text-white">
          <Logo height={isMd ? 60 : 45} width={isMd ? 500 : 400} fill="currentColor" />
        </div>
        <div
          className="bg-highlight px-6 sm:px-8 py-6 sm:py-12 flex flex-col items-center"
          style={{ backgroundColor: PI_CLASSIC_COLORS.highlight }}
        >
          <div className="flex flex-col items-center">
            <p className="text-center text-3xl md:text-[64px] font-bold">UNSUBSCRIBE?</p>
            <div className="space-y-3 mt-3 md:mt-[36px] mb-3 md:mb-[54px]">
              <p className="text-center text-black text-[24px]  uppercase">
                Select from the options below to finish.
              </p>
            </div>
            <div className="space-y-3 flex flex-col items-center">
              <TWButton
                className="text-white bg-black border-none text-base md:text-2xl hover:no-underline"
                variant="primary"
                onClick={() => {
                  removeEmailTypes({
                    variables: { emailTypes, key },
                  });

                  toast({
                    title: 'unsubscribed.',
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                  });
                  router.navigate({
                    to: `/unsubscribe/email-list/survey${key && `?key=${encodeURIComponent(key)}`}`,
                  });
                }}
              >
                CONFIRM UNSUBSCRIBE :(
              </TWButton>

              {params.emailList !== EmailListType.WeeklyContentDigest ? (
                <TWButton
                  className="text-black bg-white border-none text-base md:text-2xl hover:no-underline"
                  onClick={() => {
                    activateWeeklyContentDigest({
                      variables: { key },
                    });
                    toast({
                      title: 'Switched to digest.',
                      status: 'success',
                      duration: 3000,
                      isClosable: false,
                      position: 'top',
                    });
                    router.navigate({ to: '/' });
                  }}
                >
                  SWITCH TO ONCE A WEEK DIGEST :)
                </TWButton>
              ) : null}
              <TWLink
                className="border-none pt-[12px] text-lg font-semibold hover:no-underline uppercase"
                variant="link"
                to={
                  auth.user
                    ? '/settings/manage/email'
                    : key
                    ? `/settings/manage/email?key=${encodeURIComponent(key)}`
                    : `/sign-in?destination=${encodeURIComponent('/settings/manage/email')}`
                }
              >
                manage email preferences
              </TWLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
