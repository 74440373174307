import { createFileRoute, useRouter } from '@tanstack/react-router';
import { gql } from 'shared/__generated__/gql';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import ListColorBlock from 'web2/app/components/by-type/lists/list-color-block';
import { ListEntries } from 'web2/app/components/by-type/lists/list-entries';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import NotFound from '../../components/not-found';

gql(/* GraphQL */ `
  fragment BookmarksView on List {
    id
    title
    intro
    color
    createdAt
    updatedAt
    user {
      id
      username
      firstName
      lastName
    }
    ...ListColorBlock
  }
`);

const LIST_VIEW_QUERY = gql(/* GraphQL */ `
  query getBookmarksView {
    me {
      id
      username
      profileTheme
      bookmarksList {
        ...BookmarksView
      }
    }
  }
`);

type Inputs = {
  orderBy: 'oldestFirst' | 'newestFirst';
};
export const Route = createFileRoute('/bookmarks/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Bookmarks',
      },
    ],
  }),
});

function RouteComponent() {
  const { watch } = useForm<Inputs>({
    defaultValues: { orderBy: 'newestFirst' },
  });
  const { orderBy } = watch();

  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery(LIST_VIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy,
    },
  });

  const router = useRouter();

  const bookmarksList = data?.me?.bookmarksList;

  useAutoSetTheme(data?.me?.profileTheme || ProfileThemeType.Default);

  if (!bookmarksList && !loading) {
    return <NotFound />;
  }

  return (
    <div className="flex justify-center mb-[75px]">
      <div className="space-y-[24px] md:w-[800px] w-full">
        <div className="space-y-[24px] pt-[24px] justify-center">
          {!loading && bookmarksList && (
            <ListColorBlock isCentered list={bookmarksList} showUpdatedAt isTitleLink />
          )}
          {bookmarksList?.id && <ListEntries listId={bookmarksList.id} />}
        </div>
      </div>
    </div>
  );
}
