import React from 'react';
import { Box, Text, Link, Stack, ToastProviderProps, UseToastOptions } from '@chakra-ui/react';

export interface CustomToastProps extends UseToastOptions {
  link?: {
    href: string;
    text: string;
  };
}

const toastOptions: ToastProviderProps = {
  defaultOptions: { position: 'top' },
  // component: ((props: CustomToastProps) => {
  //   console.log(props);

  //   return (
  //     <Box
  //       border="2px solid"
  //       borderRadius="0px !important"
  //       borderColor={props.status === 'success' ? 'brand.green' : 'brand.red'}
  //       textStyle="brand.body !important"
  //       fontSize="13.5px !important"
  //       textTransform="uppercase"
  //       backgroundColor="white !important"
  //       bgColor="white !important"
  //       padding="12px"
  //       color="black !important"
  //       // position="absolute"
  //       width="300px"
  //       height="50px"
  //     >
  //       <Stack spacing="6px">
  //         <Text textStyle="brand.headingSm">{props.title}</Text>
  //         {props.link?.href && (
  //           <Box>
  //             <Link
  //               href={props.link?.href}
  //               variant="stylizedBasic"
  //               color="blue"
  //               textStyle="brand.actionSm"
  //             >
  //               {props.link?.text}
  //             </Link>
  //           </Box>
  //         )}
  //       </Stack>
  //     </Box>
  //   );
  // }) as ToastProviderProps['component'],
};

export default toastOptions;
