import React, { useEffect } from 'react';
import {
  Button,
  Stack,
  Text,
  ButtonGroup,
  useDisclosure,
  UseDisclosureReturn,
  Icon,
  HStack,
  Box,
  Link,
  useToast,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '../ui/modal';
import { RiCheckFill } from 'react-icons/ri';
import useSelectUsersForConversation from 'shared/misc/hooks/useSelectUsersForConversation';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { UserCoreFragment } from 'shared/__generated__/graphql';
import { UserAvatar } from '../by-type/user/user-avatar';
import { SubmitHandler, useForm } from 'react-hook-form';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { gql } from 'shared/__generated__';
import { useMutation } from '@apollo/client';

export enum ModalPurpose {
  DocumentDraft = 'DocumentDraft',
}

type Props = {
  modalState?: UseDisclosureReturn;
  onSelect?: (users: UserCoreFragment[]) => void;
  purpose: string;
};

type Inputs = {
  firstName: string;
  lastName: string;
  username: string;
  location?: string;
  instagram?: string;
  twitter?: string;
  website?: string;
};

export default function AdminSelectOrCreateUserModal({
  modalState: _modalState,
  onSelect,
  purpose,
}: Props) {
  const { isOpen, onOpen, onClose } = _modalState || useDisclosure();
  const auth = useAuth<true>();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const [mode, setMode] = React.useState<'select' | 'create'>('select');

  const toast = useToast();

  const {
    isSearching,
    resultsToDisplay,
    onToggle,
    selectedCount,
    isSelected,
    sendExistingRecState,
    createConversationState,
    allSelectedUsers,
    form,
  } = useSelectUsersForConversation({
    skipConvos: true,
    includeDraftUsers: true,
    onlyFollowing: false,
    onlyAllowOne: true,
    includeSelf: true,
    onConfirm: (ids) => {
      onClose();
      toast({
        title: 'Selected user',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    },
  });

  const modalMLR = { base: 4, lg: 16 };
  const modalMTB = { base: '16px', lg: 16 };

  useEffect(() => {
    form.setFocus('query');
  }, []);

  const [createDraftGuestMutation, createDraftGuestMutationInfo] = useMutation(
    CreateDraftGuestMutationDoc,
  );

  const onSubmit: SubmitHandler<Inputs> = async (values, event) => {
    event?.preventDefault();

    const { ...input } = values;

    await createDraftGuestMutation({
      variables: { input: { ...input } },
      onCompleted: async (data) => {
        onToggle(data.createDraftGuest);
        setMode('select');
        toast({
          title: 'User created...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
      onError: (e) => {
        if (e.graphQLErrors[0]?.extensions?.surfaceToUser) {
          const { field } = e.graphQLErrors[0].extensions;
          if (field) {
            setError(field as keyof Inputs, { message: e.message });
            return;
          }
        }
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };
  if (!isOpen) return null;
  return (
    <>
      <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onClose())}>
        <ModalOverlay />
        <ModalContent className="overflow-y-auto max-h-[600px]">
          <ModalHeader className="typography-heading-md">
            {mode === 'create' ? 'Create' : 'Create or select'}
          </ModalHeader>
          <ModalCloseButton />
          {mode === 'select' ? (
            <>
              <Input {...form.register('query')} mb="12px" placeholder="Search people" />
              <div className="flex flex-col gap-[6px] w-full overflow-y-auto h-[300px]">
                {(resultsToDisplay as UserCoreFragment[]).map((result) => {
                  const selected = isSelected(result);

                  return (
                    <Box
                      key={result.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      cursor="pointer"
                      backgroundColor={selected ? 'brand.highlight' : 'brand.background'}
                    >
                      <HStack onClick={() => onToggle(result)}>
                        <UserAvatar user={result} />
                        <Text
                          textStyle="brand.actionSm"
                          color={selected ? 'brand.background' : 'brand.main'}
                        >
                          @{result.username}
                        </Text>
                      </HStack>
                      {selected && (
                        <Icon
                          mx="6px"
                          color={selected ? 'brand.background' : 'brand.highlight'}
                          as={RiCheckFill}
                          size={20}
                        />
                      )}
                    </Box>
                  );
                })}
              </div>
            </>
          ) : null}
          {mode === 'create' ? (
            <Stack as="form" spacing="12px" onSubmit={handleSubmit(onSubmit)}>
              {purpose === ModalPurpose.DocumentDraft ? (
                <Text textStyle="brand.body" fontWeight="normal" opacity={0.4}>
                  The thumbnail photo will be added as the profile picture for this user.
                </Text>
              ) : null}
              <PerfInput
                placeholder="Username"
                {...register('username')}
                error={errors.firstName}
              />
              <PerfInput
                placeholder="First name (optional)"
                {...register('firstName')}
                error={errors.firstName}
              />
              <PerfInput
                placeholder="Last name (optional)"
                {...register('lastName')}
                error={errors.lastName}
              />
              <PerfInput
                placeholder="Location (optional)"
                {...register('location')}
                error={errors.location}
              />
              <PerfInput
                placeholder="Instagram @ (optional)"
                {...register('instagram')}
                error={errors.instagram}
              />
              <PerfInput
                placeholder="Twitter @ (optional)"
                {...register('twitter')}
                error={errors.twitter}
              />
            </Stack>
          ) : null}
          <ModalFooter className="bg-brand-background">
            <ButtonGroup>
              {mode === 'select' && (
                <>
                  <Button
                    alignSelf="center"
                    // opacity={0.7}
                    variant="secondary"
                    isDisabled={createConversationState.loading}
                    isLoading={createConversationState.loading}
                    onClick={() => {
                      setMode('create');
                    }}
                  >
                    Create new user?
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      onSelect?.(allSelectedUsers);
                      onClose();
                    }}
                    isDisabled={selectedCount === 0 || sendExistingRecState.loading}
                    isLoading={sendExistingRecState.loading}
                  >
                    Done
                  </Button>
                </>
              )}
              {mode === 'create' && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setMode('select');
                    }}
                    isDisabled={sendExistingRecState.loading}
                    isLoading={sendExistingRecState.loading}
                  >
                    Back to select
                  </Button>
                  <Button
                    alignSelf="center"
                    // opacity={0.7}
                    variant="secondary"
                    isDisabled={createDraftGuestMutationInfo.loading}
                    isLoading={createDraftGuestMutationInfo.loading}
                    onClick={(e) => {
                      handleSubmit(onSubmit)(e);
                    }}
                  >
                    Create and select
                  </Button>
                </>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const CreateDraftGuestMutationDoc = gql(/* GraphQL */ `
  mutation createDraftGuest($input: CreateDraftGuestInput!) {
    createDraftGuest(input: $input) {
      ...UserCore
    }
  }
`);
