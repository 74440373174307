import _ from 'lodash';
import { RecCategory } from '../__generated__/graphql';

export enum REC_CATEGORY {
  MUSIC = 'MUSIC',
  MOVIES = 'MOVIES',
  CLOTHING = 'CLOTHING',
  PLACES = 'PLACES',
  FOOD_AND_DRINK = 'FOOD_AND_DRINK',
  TV = 'TV',
  VIDEOS = 'VIDEOS',
  BOOKS = 'BOOKS',
  RESTAURANTS = 'RESTAURANTS',
  READING = 'READING',
  TECHNOLOGY = 'TECHNOLOGY',
  // INTERNET = 'INTERNET',
  PRODUCTS = 'PRODUCTS',
  HOME = 'HOME',
  MISC = 'MISC',
}

export const REC_CATEGORY_LIST = [
  REC_CATEGORY.MUSIC,
  REC_CATEGORY.MOVIES,
  REC_CATEGORY.BOOKS,
  REC_CATEGORY.RESTAURANTS,
  REC_CATEGORY.TV,
  REC_CATEGORY.CLOTHING,
  REC_CATEGORY.FOOD_AND_DRINK,
  REC_CATEGORY.VIDEOS,
  REC_CATEGORY.MISC,
  REC_CATEGORY.PLACES,
];

export function getRecQueryCategoryAtString(category: REC_CATEGORY | RecCategory) {
  switch (category) {
    case REC_CATEGORY.MUSIC:
    case REC_CATEGORY.MOVIES:
    case REC_CATEGORY.PLACES:
    case REC_CATEGORY.TV:
    case REC_CATEGORY.VIDEOS:
    case REC_CATEGORY.BOOKS:
      return _.capitalize(category);
    case REC_CATEGORY.CLOTHING:
      return 'Clothes';
    case REC_CATEGORY.RESTAURANTS:
      return 'Restaurants & Bars';
    case REC_CATEGORY.FOOD_AND_DRINK:
      return 'Food & Drink';
    case REC_CATEGORY.MISC:
      return 'Random';
    default: {
      return null;
    }
  }
}

export function isValidCategory(category: REC_CATEGORY | RecCategory) {
  return REC_CATEGORY_LIST.find((c) => category.toUpperCase() === c);
}

export function getRecQueryCategoryAsEmoji(category: REC_CATEGORY | RecCategory) {
  switch (category) {
    case REC_CATEGORY.MUSIC:
      return '🎵';
    case REC_CATEGORY.MOVIES:
      return '📽️';
    case REC_CATEGORY.CLOTHING:
      return '👕';
    case REC_CATEGORY.TV:
      return '📺';
    case REC_CATEGORY.RESTAURANTS:
      return '🍴';
    case REC_CATEGORY.VIDEOS:
      return '📹';
    case REC_CATEGORY.BOOKS:
      return '📚';
    case REC_CATEGORY.FOOD_AND_DRINK:
      return '🍔';
    case REC_CATEGORY.PLACES:
      return '🏛️';
    case REC_CATEGORY.MISC:
      return '🧠';
    default: {
      return '⭐';
    }
  }
}

type SEARCH_TAGS = {
  extraEmojis: string[];
  keywords: string[];
  categories?: string[];
  domains: string[];
};

export function getTagsFromRecCategory(recCategory: REC_CATEGORY): SEARCH_TAGS {
  switch (recCategory) {
    case REC_CATEGORY.MUSIC:
      return {
        extraEmojis: ['💿', '📀', '🎶', '🎼'],
        keywords: ['music'],
        domains: ['spotify.com', 'music.apple.com', 'bandcamp.com', 'soundcloud.com'],
      };

    case REC_CATEGORY.BOOKS:
      return {
        extraEmojis: ['📚', '📖', '📕'],
        keywords: ['book'],
        domains: ['goodreads.com'],
      };

    case REC_CATEGORY.MOVIES:
      return {
        extraEmojis: ['📽️', '🎥', '🎞️', '🎬', '📹'],
        keywords: ['film', 'film_projector', 'video', 'movie'],
        domains: [
          'criterion.com',
          'tubitv.com',
          'letterboxd.com',
          'justwatch.com',
          // 'netflix.com',
          'hbo.com',
        ],
      };

    case REC_CATEGORY.TV:
      return {
        extraEmojis: ['📺', '📹'],
        keywords: ['tv'],
        domains: ['peacocktv.com', 'netflix.com', 'hbomax.com', 'twitch.tv', 'hulu.com'],
      };

    case REC_CATEGORY.RESTAURANTS:
      return {
        extraEmojis: ['🍴'],
        keywords: [
          'restaurant',
          'bar',
          'pub',
          'cafe',
          'frenchette',
          'tribeca',
          'bistro',
          'nyc',
          'holidays',
          'dining',
          'restaurant',
          'best',
          'cuisine',
          'menu',
          'food',
          'dinner',
          'lunch',
          'brunch',
          'overheard',
          'neighbors',
          'tables',
          'seating',
        ],
        domains: ['resy.com', 'infatuation.com'],
      };

    case REC_CATEGORY.CLOTHING:
      return {
        extraEmojis: ['👖', '👕', '🧥', '👞', '👔', '👟', '👗'],
        keywords: ['clothing', 't-shirt', 'jeans', 'dress'],
        domains: [],
      };

    case REC_CATEGORY.FOOD:
    case REC_CATEGORY.FOOD_AND_DRINK:
      return {
        extraEmojis: [],
        categories: ['Food & Drink'],
        keywords: ['food'],
        domains: [],
      };

    case REC_CATEGORY.PLACES:
      return {
        extraEmojis: [],
        keywords: ['travel', 'place', 'places', 'hole-in-the-wall', 'hole in the wall'],
        domains: ['yelp.com', 'resy.com'],
      };

    case REC_CATEGORY.VIDEOS:
      return {
        extraEmojis: ['📺', '📹'],
        keywords: ['tv'],
        domains: ['peacocktv.com', 'netflix.com', 'hbomax.com', 'twitch.tv', 'hulu.com'],
      };
  }

  console.log(`Category query not set up for "${recCategory}"`);
  return {
    extraEmojis: [],
    categories: [],
    keywords: [],
    domains: [],
  };
}

export function getSubTagsFromRecCategory(recCategory: REC_CATEGORY): SEARCH_TAGS {
  switch (recCategory) {
    case REC_CATEGORY.MUSIC:
      return {
        emojis: [
          '💿',
          '📀',
          '🎶',
          '🎼',
          '🎵',
          '🎶',
          '🎤',
          '🎸',
          '🎹',
          '🎧',
          '🎷',
          '🥁',
          '🎺',
          '🎻',
          '🎼',
          '📻',
          '🎛️',
          '🔊',
          '🎙️',
        ],
        keywords: [
          'music',
          'song',
          'artist',
          'album',
          'band',
          'tune',
          'melody',
          'beat',
          'track',
          'audio',
          'playlist',
          'lyrics',
          'concert',
          'live',
          'jam',
          'studio',
          'sound',
          'record',
          'vinyl',
          'radio',
          'tunes',
          'mp3',
          'musician',
          'rock',
          'pop',
          'hiphop',
          'jazz',
          'classical',
          'rap',
          'country',
          'genre',
          'musicology',
        ],
        domains: ['spotify.com', 'music.apple.com', 'bandcamp.com', 'soundcloud.com'],
      };

    case REC_CATEGORY.BOOKS:
      return {
        extraEmojis: ['📚', '📖', '📕'],
        keywords: ['book'],
        domains: ['goodreads.com'],
      };

    case REC_CATEGORY.MOVIES:
      return {
        extraEmojis: ['📽️', '🎥', '🎞️', '🎬', '📹'],
        keywords: [
          'film',
          'video',
          'movie',
          'cinema',
          'popcorn',
          'director',
          'actor',
          'screening',
          'premiere',
          'plot',
          'cinematograph',
          'star',
        ],
        domains: [
          'criterion.com',
          'tubitv.com',
          'letterboxd.com',
          'justwatch.com',
          // 'netflix.com',
          'hbo.com',
        ],
      };

    case REC_CATEGORY.TV:
      return {
        extraEmojis: ['📺', '📹'],
        keywords: [
          'television',
          'TV',
          'show',
          'series',
          'episode',
          'season',
          'streaming',
          'binge-watch',
          'sitcom',
          'finale',
          'cinematic',
          'syndication',
          'televised',
          'celebrity',
          'award-winning',
          'plot twist',
          'on-air',
        ],
        domains: ['peacocktv.com', 'netflix.com', 'hbomax.com', 'twitch.tv', 'hulu.com'],
      };

    case REC_CATEGORY.RESTAURANTS:
      return {
        extraEmojis: ['🍴'],
        keywords: [
          'restaurant',
          'bistro',
          'eatery',
          'dining',
          'cafe',
          'grill',
          'pub',
          'food',
          'cuisine',
          'kitchen',
          'diner',
          'tavern',
          'bar',
          'steakhouse',
          'pizzeria',
          'brasserie',
          'ristorante',
          'sushi',
          'brewery',
          'eat',
          'drink',
          'plate',
          'dish',
          'menu',
          'chef',
          'table',
          'culinary',
          'gourmet',
          'catering',
          'culinaire',
          'eats',
          'bites',
          'noms',
        ],
        domains: ['resy.com', 'infatuation.com'],
      };

    case REC_CATEGORY.CLOTHING:
      return {
        extraEmojis: ['👖', '👕', '🧥', '👞', '👔', '👟', '👗'],
        keywords: ['clothing', 't-shirt', 'jeans', 'dress'],
        domains: [],
      };

    case REC_CATEGORY.FOOD:
    case REC_CATEGORY.FOOD_AND_DRINK:
      return {
        emojis: [
          '🍔',
          '🍕',
          '🌮',
          '🍣',
          '🥗',
          '🍜',
          '🍝',
          '🍦',
          '🍩',
          '🍫',
          '🍰',
          '🍪',
          '🍎',
          '🍇',
          '🍉',
          '🍌',
          '🍓',
          '🍍',
          '🍈',
          '🍊',
          '🍋',
          '🍒',
          '🥑',
          '🥕',
          '🌽',
          '🍅',
          '🥔',
          '🥒',
          '🍆',
          '🥦',
          '🥖',
          '🥨',
          '🧀',
          '🥚',
          '🍳',
          '🥓',
          '🥩',
          '🍗',
          '🍖',
          '🍤',
          '🍟',
          '🍕',
          '🥪',
          '🍱',
          '🍲',
          '🍛',
          '🍚',
          '🍜',
          '🍝',
          '🍠',
        ],
        categories: ['Food & Drink'],
        keywords: [
          'food',
          'cuisine',
          'dish',
          'meal',
          'recipe',
          'cooking',
          'flavor',
          'taste',
          'ingredient',
          'spice',
          'culinary',
          'gourmet',
          'delicious',
          'yummy',
          'savory',
          'sweet',
          'appetizer',
          'main course',
          'dessert',
          'snack',
          'buffet',
          'menu',
          'chef',
          'kitchen',
          'restaurant',
          'eatery',
          'bistro',
          'dining',
          'culinary art',
          'gastronomy',
          'nutrition',
          'healthy',
          'organic',
          'local',
          'farm-to-table',
          'fresh',
          'homemade',
          'sustainable',
          'vegan',
          'vegetarian',
          'gluten-free',
          'tasting',
          'foodie',
          'comfort food',
          'spicy',
          'exotic',
          'international',
          'fusion',
          'food truck',
        ],
        domains: [],
      };

    case REC_CATEGORY.PLACES:
      return {
        extraEmojis: [],
        keywords: ['travel', 'place', 'places', 'hole-in-the-wall', 'hole in the wall'],
        domains: ['yelp.com', 'resy.com'],
      };

    case REC_CATEGORY.VIDEOS:
      return {
        extraEmojis: ['📺', '📹'],
        keywords: ['tv'],
        domains: ['peacocktv.com', 'netflix.com', 'hbomax.com', 'twitch.tv', 'hulu.com'],
      };
  }

  console.log(`Category query not set up for "${recCategory}"`);
  return {
    extraEmojis: [],
    categories: [],
    keywords: [],
    domains: [],
  };
}

export function guessLocationInformation(rec) {
  const NYC = [
    'New York City',
    'NYC',
    'Manhattan',
    'Brooklyn',
    'Queens',
    'Bronx',
    'Staten Island',
    'Big Apple',
    'Empire State',
    'Times Square',
    'Central Park',
    'Brooklyn Bridge',
    'Statue of Liberty',
    'Broadway',
    'Metropolitan Museum',
    'NY',
  ];

  const commonCities = [
    'New York City',
    'Paris',
    'Tokyo',
    'London',
    'Rome',
    'Barcelona',
    'San Francisco',
    'Los Angeles',
    'Sydney',
    'Dubai',
    'Hong Kong',
    'Miami',
    'Mumbai',
    'Bangkok',
    'Berlin',
    'Toronto',
    'Amsterdam',
    'Seoul',
    'Singapore',
    'Istanbul',
  ];
}
