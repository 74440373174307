import { SystemStyleObject } from '@chakra-ui/react';
import { EditorThemeClasses } from 'lexical';

export const editorTheme: EditorThemeClasses = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  hashtag: 'editor-hashtag',
  text: {
    bold: 'editor-bold',
    italic: 'editor-italic',
    underline: 'editor-underline',
  },
  heading: {
    h1: 'editor-h1',
    h2: 'editor-h2',
    h3: 'editor-h3',
    h4: 'editor-h4',
    // h5: 'editor-h5',
    // h6: 'editor-h6',
  },
  link: 'editor-link',
  strikethrough: 'editor-strikethrough',
};

export const readOnlyTheme: EditorThemeClasses = {
  ...editorTheme,
};

export const recBodyTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-recommendation-placeholder',
  paragraph: 'editor-recommendation-paragraph',
};

export const recSlimInlineBodyTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-rec-slim-inline-body-placeholder',
  paragraph: 'editor-rec-slim-inline-body-paragraph',
};

export const recTitleTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-rec-title-placeholder',
  paragraph: 'editor-rec-title-paragraph',
};

export const documentTitleTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-document-title-placeholder',
  paragraph: 'editor-document-title-paragraph',
};

export const documentSubtitleTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-document-subtitle-placeholder',
  paragraph: 'editor-document-subtitle-paragraph',
};

export const documentEditorTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-document-placeholder',
  paragraph: 'editor-document-paragraph',
};

export const recSlimInlineTitleTheme: EditorThemeClasses = {
  ...editorTheme,
  placeholder: 'editor-rec-slim-inline-title-placeholder',
  paragraph: 'editor-rec-slim-inline-title-paragraph',
};

const textStyles: Record<string, SystemStyleObject> = {
  '.editor-bold': {
    fontWeight: '700',
  },
  '.editor-italic': {
    fontStyle: 'italic',
  },
  '.editor-underline': {
    textDecoration: 'underline',
  },
  '.editor-strikethrough': {
    textDecoration: 'line-through',
  },
  '.editor-h1': {
    textStyle: 'brand.headingXL',
    // fontSize: '16px',
  },
  '.editor-h2': {
    textStyle: 'brand.headingLg',
  },
  '.editor-h3': {
    textStyle: 'brand.headingMd',
  },
  '.editor-h4': {
    textStyle: 'brand.headingSm',
  },

  '.editor-placeholder': {
    textStyle: 'brand.editor',
    fontSize: '16px',
    // p: '8px',
    // pt: '10px',
    // pl: '12px',
    top: '30px',
    left: '13px',
  },
  '.editor-document-placeholder': {
    textStyle: 'brand.editor',
    fontSize: '20px',
    lineHeight: '26px',
    // p: '8px',
    // pt: '10px',
    // pl: '12px',
    top: '30px',
    left: '13px',
  },
  '.editor-document-title-placeholder': {
    textStyle: 'brand.headingLg',
    color: 'brand.main',
    fontSize: '36px',
    lineHeight: '36px',
    textTransform: 'none',
  },
  '.editor-document-subtitle-placeholder': {
    textStyle: 'brand.headingLg',
    color: 'brand.main',
    fontSize: '24px',
    lineHeight: '24px',
    textTransform: 'none',
  },
  '.editor-recommendation-placeholder': {
    textStyle: 'brand.bodyLg',
    fontSize: '20px',
    // p: '8px',
    // pt: '10px',
    // pl: '12px',
    top: '0px',
    left: '0px',
    paddingTop: '2px',
  },
  '.editor-rec-title-placeholder': {
    textStyle: 'brand.headingSm',
    fontSize: { base: '20px', md: '36px' },
    top: { base: '2px', md: '4px' },
    left: { base: '2px', md: '2px' },
    textTransform: 'uppercase',
  },
  '.editor-rec-title-paragraph': {
    textStyle: 'brand.headingSm',
    color: 'brand.main',
    fontSize: { base: '20px', md: '36px' },
    textTransform: 'uppercase',
  },
  '.editor-paragraph': {
    textStyle: 'brand.editor',
    color: 'brand.main',
    fontSize: '16px',
  },
  '.editor-document-paragraph': {
    textStyle: 'brand.editor',
    color: 'brand.main',
    fontSize: '20px',
    lineHeight: '26px',
  },
  '.editor-document-title-paragraph': {
    textStyle: 'brand.headingLg',
    color: 'brand.main',
    fontSize: '36px',
    lineHeight: '36px',
    textTransform: 'none',
  },
  '.editor-document-subtitle-paragraph': {
    textStyle: 'brand.headingLg',
    color: 'brand.main',
    fontSize: '24px',
    lineHeight: '24px',
    textTransform: 'none',
  },
  '.editor-recommendation-paragraph': {
    textStyle: 'brand.bodyLg',
    color: 'brand.main',
    fontSize: '20px',
  },

  '.editor-rec-slim-inline-title-placeholder': {
    textStyle: 'brand.headingSm',
    fontSize: { base: '20px', md: '24px' },
    top: { base: '-1px', md: '-1px' },
    left: { base: '2px', md: '3px' },
  },
  '.editor-rec-slim-inline-title-paragraph': {
    color: 'brand.main',
    textStyle: 'brand.headingSm',
    fontSize: { base: '20px', md: '24px' },
  },
  '.editor-rec-slim-inline-body-placeholder': {
    textStyle: 'brand.bodyLg',
    fontSize: { base: '16px', md: '20px' },
    // p: '8px',
    // pt: '10px',
    // pl: '12px',
    top: '0px',
    left: '2px',
    paddingTop: '2px',
  },
  '.editor-rec-slim-inline-body-paragraph': {
    color: 'brand.main',
    textStyle: 'brand.bodyLg',
    fontSize: { base: '16px', md: '20px' },
  },

  '.editor-link': {
    color: 'brand.main !important',
    borderBottom: '1px dashed',
    borderColor: 'brand.main',
    textDecoration: 'none !important',
    _hover: {
      cursor: 'pointer',
      color: 'brand.highlight !important',
      textDecoration: 'none',
      borderBottom: '1px dashed',
      borderColor: 'brand.highlight',
    },
  },
  '.content-editable': {
    caretColor: 'brand.main',
  },

  '.editor-hashtag': {
    color: 'brand.highlight !important',
  },
};

export default {
  ...textStyles,
};
