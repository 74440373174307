'use client';
import { useState, useRef, useEffect } from 'react';
import { IconButton } from '@chakra-ui/react';
import { redirect } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { useDrag } from '@use-gesture/react';
import { gql } from 'shared/__generated__';
import { GetGenericRecScrollViewQueryVariables } from 'shared/__generated__/graphql';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import RecommendationItem, { RecommendationItemProps } from './recommendation-item';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import ArrowIcon from 'shared/misc/components/arrow.svg?react';
import DashArrowIcon from 'shared/misc/components/dash-arrow.svg?react';
import { isEmbeddable } from 'shared/misc/components/by-type/recommendations/RecommendationEmbedableContent';

const GenericRecScrollViewQuery = gql(/* GraphQL */ `
  query getGenericRecScrollView(
    $first: Int
    $after: String
    $search: RecSearchInput
    $category: RecCategory
    $orderBy: OrderBy
    $originUserId: String
    $showGlobalFeed: Boolean
    $isPinned: Boolean
    $isEndorsedByViewer: Boolean
    $includePromptRecs: Boolean
    $includeReRecsWithoutComment: Boolean
    $onlyGuests: Boolean
  ) {
    genericRecConnection: recConnection(
      first: $first
      after: $after
      isEndorsedByViewer: $isEndorsedByViewer
      orderBy: $orderBy
      search: $search
      includePromptRecs: $includePromptRecs
      onlyGuests: $onlyGuests
      userId: $originUserId
      includeReRecsWithoutComment: $includeReRecsWithoutComment
      isPinned: $isPinned
    ) @connection(key: "genericRecConnection") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 2;

export enum FeedUserFilter {
  FOLLOWING = 'FOLLOWING',
  EVERYONE = 'EVERYONE',
}

export enum FeedContentFilter {
  ALL = 'ALL',
  ONLY_RECS = 'ONLY_RECS',
}

interface GenericRecInfiniteCarouselProps {
  shouldFetchMore?: boolean;
  showSpinner?: boolean;
  pageLength?: number;
  variables?: GetGenericRecScrollViewQueryVariables;
  itemProps?: Partial<RecommendationItemProps>;
  includePromptRecs?: boolean;
  includeReRecsWithoutComment?: boolean;
  title?: string;
  itemWidth?: number;
  accent?: string;
  hideOffScreen?: boolean;
}

export default function GenericRecInfiniteCarousel({
  pageLength = PAGE_LENGTH,
  shouldFetchMore = true,
  showSpinner = true,
  variables: {
    isEndorsedByViewer = false,
    search = undefined,
    includePromptRecs = true,
    onlyGuests = false,
    includeReRecsWithoutComment = false,
    ...propVariables
  } = {},
  itemProps = {},
  title = 'Recommendations',
  itemWidth: itemWidthFromProps = 368,
  accent = 'brand.background',
  hideOffScreen: shouldHideOffScreen = true,
}: GenericRecInfiniteCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [delayedIndex, setDelayedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const gap = 24;
  const mobilePadding = 27;
  const TRANSITION_DURATION = 300;

  useEffect(() => {
    // Store the previous index before updating delayed index
    setPreviousIndex(currentIndex);
    const timer = setTimeout(() => {
      setDelayedIndex(currentIndex);
    }, TRANSITION_DURATION);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  const getInitialWidth = () => {
    if (typeof window === 'undefined') return itemWidthFromProps;
    if (containerRef.current) {
      const newWidth = Math.min(containerRef.current.offsetWidth - gap * 2, 759.6);
      return newWidth;
    } else {
      const availableWidth = window.innerWidth - mobilePadding;
      return Math.min(availableWidth, 759.6);
    }
  };

  const [containerWidth, setContainerWidth] = useState(getInitialWidth());

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const newWidth = Math.min(containerRef.current.offsetWidth - gap * 2, 759.6);
        setContainerWidth(newWidth);
      }
    };

    if (typeof window !== 'undefined') {
      updateWidth();
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }
  }, [gap]);

  const { data, loading, error, fetchMore, variables } = useQuery(GenericRecScrollViewQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      isEndorsedByViewer,
      search,
      includePromptRecs,
      includeReRecsWithoutComment,
      onlyGuests,
      first: pageLength,
      ...propVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const recItems = data?.genericRecConnection?.edges || [];
  const itemCount = recItems.length;
  const isSingleItem = itemCount === 1;
  const hasLoadedAllItems = !data?.genericRecConnection.pageInfo.hasNextPage;
  const itemWidth = Math.min(isSingleItem ? 759.6 : itemWidthFromProps, containerWidth);

  const bind = useDrag(({ down, direction: [xDir], distance: [dx], cancel, touches }) => {
    if (touches > 1) return;
    if (down && dx > itemWidth / 4) {
      if (xDir < 0 && currentIndex < itemCount - 1) {
        setCurrentIndex(currentIndex + 1);

        // Load more items when we're near the end
        if (currentIndex >= itemCount - 3 && !loading && shouldFetchMore && !hasLoadedAllItems) {
          getNextPage();
        }
      } else if (xDir > 0 && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
      cancel();
    }
  });

  const getNextPage = async () => {
    if (!data?.genericRecConnection.pageInfo.hasNextPage || (!loading && !shouldFetchMore)) return;
    await fetchMore({
      variables: {
        ...variables,
        first: pageLength,
        after: data?.genericRecConnection.pageInfo.endCursor,
      },
    });
  };

  const handleNext = () => {
    if (currentIndex < itemCount - 1) {
      setCurrentIndex(currentIndex + 1);

      // Load more items when we're near the end
      if (currentIndex >= itemCount - 3 && !loading && shouldFetchMore && !hasLoadedAllItems) {
        getNextPage();
      }
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (!data && !loading) {
    return redirect({ to: '/' });
  } else if (error) {
    return redirect({ to: '/' });
  }

  if (recItems.length === 0) {
    return null;
  }

  return (
    <div
      className="relative overflow-hidden bg-brand-background w-full min-w-full max-w-full py-[12px] pb-[24px] transition-all duration-300"
      ref={containerRef}
    >
      <div className="flex justify-between items-center mb-[8px]">
        <h1 className="text-[48px]">📌</h1>
        {!isSingleItem && (
          <div className="flex gap-[8px] items-center">
            <IconButton
              background="transparent"
              color="brand.main"
              _hover={currentIndex > 0 ? { color: 'brand.highlight' } : { color: 'brand.main' }}
              onClick={handlePrev}
              aria-label="Previous"
              disabled={currentIndex === 0}
              icon={
                currentIndex > 0 ? (
                  <ArrowIcon fill="currentColor" style={{ transform: 'rotate(180deg)' }} />
                ) : (
                  <DashArrowIcon stroke="currentColor" />
                )
              }
              _disabled={{
                cursor: 'not-allowed',
                pointerEvents: 'none',
                backgroundColor: 'transparent',
              }}
            >
              {currentIndex > 0 ? '←' : '-'}
            </IconButton>
            <IconButton
              background="transparent"
              color="brand.main"
              _hover={{ color: 'brand.highlight' }}
              icon={
                currentIndex < itemCount - 1 ? (
                  <ArrowIcon fill="currentColor" />
                ) : (
                  <DashArrowIcon style={{ transform: 'rotate(180deg)' }} stroke="currentColor" />
                )
              }
              onClick={handleNext}
              aria-label="Next"
              disabled={currentIndex === itemCount - 1 && hasLoadedAllItems}
              _disabled={{
                cursor: 'not-allowed',
                pointerEvents: 'none',
                backgroundColor: 'transparent',
              }}
            >
              {currentIndex < itemCount - 1 || !hasLoadedAllItems ? '→' : '-'}
            </IconButton>
          </div>
        )}
      </div>

      {!data && loading ? (
        <SkeletonFeedItem />
      ) : (
        recItems.length > 0 && (
          <div
            className="flex max-w-full min-h-[300px] items-stretch sm:items-start overflow-visible transition-all duration-300"
            style={{
              gap: `${gap}px`,
              width: `${isSingleItem ? '100%' : itemCount * (itemWidth + gap)}px`,
              transform: `translateX(-${currentIndex * (itemWidth + gap)}px)`,
            }}
            {...bind()}
          >
            {recItems.map(({ node }, index) => {
              const hideOffScreen =
                shouldHideOffScreen &&
                !!(
                  node.title.length >= 70 ||
                  node.attachments?.length ||
                  node?.repostTargetRec?.attachments?.length ||
                  isEmbeddable(node)
                );

              const isBecomingVisible =
                (index === currentIndex || index === currentIndex + 1) &&
                index !== previousIndex &&
                index !== previousIndex + 1;

              const useDelayedTransition = !isBecomingVisible;

              const isVisible = useDelayedTransition
                ? index === delayedIndex || index === delayedIndex + 1
                : index === currentIndex || index === currentIndex + 1;

              return (
                <div
                  className={`max-w-full border-brand-main border-dashed transition-all duration-300 flex flex-col max-w-full flex-none w-[${itemWidth}px]`}
                  style={{
                    flex: `0 0 ${itemWidth}px`,
                    height: hideOffScreen && !isVisible ? '300px' : undefined,
                    overflow: hideOffScreen ? 'hidden' : undefined,
                    borderWidth: hideOffScreen && !isVisible ? '1px' : '0px',
                    transition: 'all 0.3s ease-in-out',
                  }}
                  key={node.id}
                >
                  <RecommendationItem
                    {...itemProps}
                    imageMaxHeight={itemCount === 1 ? '600px' : '200px'}
                    rec={node}
                    showPrompt
                  />
                </div>
              );
            })}
            {loading && (
              <div
                className={`transition-all border border-dashed border-brand-main duration-300 flex flex-col flex-none h-[300px] w-[${itemWidth}px]`}
              />
            )}
          </div>
        )
      )}
    </div>
  );
} 