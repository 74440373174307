import { Button, Text, Stack, HStack, Select, useToast } from '@chakra-ui/react';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton } from '../ui/modal';
import React from 'react';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signUpEmailOnlyStep2Schema } from 'shared/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { Link } from '@chakra-ui/next-js';
import discoveryList from 'shared/misc/discovery';
import { isUniqueUsername } from '../../actions/isUniqueUsername';
import { useMutation } from '@apollo/client';
import { SIGN_UP_EMAIL_ONLY_STEP_2 } from 'shared/misc/graphql/SignUpFragments';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { usePathname, useSearchParams } from 'shared/misc/hooks/routerHooks';

import { useRouter } from '@tanstack/react-router';

type Inputs = {
  username: string;
  password: string;
  confirmPassword: string;
  discoverySource: string;
};

export default function FinishSignUpModal() {
  const { isOpen, onOpen, onClose } = useModalState(ModalNames.FINISH_SIGN_UP);

  const [signUpBasicInfoMutation, { loading, data }] = useMutation(SIGN_UP_EMAIL_ONLY_STEP_2);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpEmailOnlyStep2Schema),
  });

  const auth = useAuth();

  const toast = useToast();

  const values = watch();

  const router = useRouter();

  const pathname = usePathname();

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  const onSubmit: SubmitHandler<Inputs> = async (
    { confirmPassword: _confirmPassword, ...values },
    event,
  ) => {
    event?.preventDefault();

    const isValidUsername = await isUniqueUsername(values);

    if (!isValidUsername) {
      setError('username', { message: 'Username taken.' });
      return;
    }

    await signUpBasicInfoMutation({
      variables: { input: { ...values } },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const handleClose = () => {
    onClose();
    if (pathname.includes('/welcome')) {
      router.navigate({ to: '/home/top' });
    }
  };

  const IS_DONE = !!data?.signUpEmailOnlyStep2?.id;
  if (!isOpen) return null;
  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onClose())}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Stack overflowY="scroll" pb="24px" pt="24px">
          {IS_DONE ? (
            <Stack spacing={6}>
              <Text textStyle="brand.headingMd" color="brand.highlight">
                YOU'RE ALL SET!
              </Text>
              <Text textStyle="brand.actionSm" lineHeight="140%">
                You can add a profile photo and more on the{' '}
                <Link href="/settings" variant="stylized">
                  Settings
                </Link>{' '}
                page.
              </Text>
              <HStack>
                <Button
                  variant="primary"
                  onClick={() =>
                    window
                      ?.open(
                        'https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926',
                        '_blank',
                      )
                      .focus()
                  }
                >
                  Download the app
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Skip
                </Button>
              </HStack>
            </Stack>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={6} w="100%" maxW="400px">
                <Stack spacing={2} my="6px">
                  <Text textStyle="brand.headingSm" color="brand.highlight">
                    YOU'RE SIGNED UP!
                  </Text>
                  <Text textStyle="brand.headingMd">CLAIM A PI.FYI USERNAME.</Text>
                  <Text textStyle="brand.body" opacity={0.4}>
                    Personalize your page or keep it anonymous with @{auth.user?.username} (auto
                    generated)
                  </Text>
                </Stack>
                <PerfInput
                  placeholder="Username"
                  {...register('username')}
                  error={errors.username}
                />
                <PerfInput
                  placeholder="Password"
                  type="password"
                  {...register('password')}
                  error={errors.password}
                />
                <PerfInput
                  placeholder="Confirm password"
                  type="password"
                  {...register('confirmPassword')}
                  error={errors.confirmPassword}
                />
                <Select
                  placeholder="Where did you hear about us?"
                  sx={!values.discoverySource ? { color: '#e9e9e9' } : {}}
                  {...register('discoverySource')}
                >
                  {discoveryList.map((listItem) => (
                    <option value={listItem}>{listItem}</option>
                  ))}
                </Select>
                <Text textStyle="brand.bodySm" color="brand.lightgrey">
                  By clicking Finish, you agree to our{' '}
                  <Link href="/terms" variant="stylizedBasic" borderColor="brand.lightgrey">
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link href="/terms" variant="stylizedBasic" borderColor="brand.lightgrey">
                    Privacy
                  </Link>{' '}
                  conditions.
                </Text>
                <Stack spacing={3}>
                  <HStack>
                    <Button variant="secondary" isDisabled={loading} onClick={handleClose}>
                      Skip
                    </Button>
                    <Button type="submit" isLoading={loading} isDisabled={loading}>
                      Finish
                    </Button>
                  </HStack>
                  <Text
                    textStyle="brand.bodySm"
                    layerStyle="secondaryText"
                    color="brand.lightgrey"
                    mt="12px"
                  >
                    Already signed up?{' '}
                    <Link href="/sign-in" prefetch variant="stylized">
                      Sign in
                    </Link>
                  </Text>
                  <Text textStyle="brand.bodySm" layerStyle="secondaryText" color="brand.lightgrey">
                    Confused?{' '}
                    <Link href="/what-is-this" prefetch variant="stylized">
                      Learn more
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </form>
          )}
        </Stack>
      </ModalContent>
    </Modal>
  );
}
