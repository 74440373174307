import { AddRecommendationMessages } from './AddRecommendationMessages';

export enum GlobalWebViewMessages {
  OPEN_REC_ACTION_MENU = 'OPEN_REC_ACTION_MENU',
  UPDATE_PHOTO = 'UPDATE_PHOTO',
  NEXT_NAVIGATION = 'NEXT_NAVIGATION',
  HAPTIC_LIGHT = 'HAPTIC_LIGHT',
  HAPTIC_SUCCESS = 'HAPTIC_SUCCESS',
}

export enum CacheSyncMessages {
  WRITE = 'WRITE',
  EVICT = 'EVICT',
  MODIFY = 'MODIFY',
  REFETCH_QUERIES = 'REFETCH_QUERIES',
}

export type WebViewMessage =
  | {
      msg: GlobalWebViewMessages.OPEN_REC_ACTION_MENU;
      value: string;
    }
  | {
      msg: AddRecommendationMessages.SUCCESSFULLY_CREATED_REC;
      // Rec id
      value: string;
    }
  | {
      msg: AddRecommendationMessages.CLOSE;
      value: null;
    }
  | {
      msg: CacheSyncMessages;
      value: {
        args: any[];
        origin: 'WEB' | 'MOBILE';
      };
    }
  | {
      msg: CacheSyncMessages.REFETCH_QUERIES;
      value: {
        refetchQueries: any[];
      };
    }
  | {
      msg: GlobalWebViewMessages.NEXT_NAVIGATION;
      value: {
        pathname: string;
      };
    }
  | {
      msg: AddPromptMessages.SUCCESSFULLY_CREATED_ASK;
      // Ask Id
      value: string;
    }
  | {
      msg: AddPromptMessages.CLOSE;
      value: null;
    };

export enum UpdateRecommendationMessages {
  CLOSE = 'CLOSE',
  SUCCESSFULLY_UPDATED_REC = 'SUCCESSFULLY_UPDATED_REC',
  OPEN_IMAGE_PICKER = 'OPEN_IMAGE_PICKER',
  ERROR = 'ERROR',
  MOVE_IMAGES = 'MOVE_IMAGES',
}

export enum UpdatePromptMessages {
  CLOSE = 'CLOSE',
  SUCCESSFULLY_UPDATED_PROMPT = 'SUCCESSFULLY_UPDATED_PROMPT',
  OPEN_IMAGE_PICKER = 'OPEN_IMAGE_PICKER',
  ERROR = 'ERROR',
}

export enum AddOrUpdateRecommendationMessages {
  SYNC_ATTACHMENT = 'SYNC_ATTACHMENT',
}

export enum AddPromptMessages {
  CLOSE = 'CLOSE',
  SUCCESSFULLY_CREATED_ASK = 'SUCCESSFULLY_CREATED_ASK',
  ERROR = 'ERROR',
}

export enum AddCommentMessages {
  CLOSE = 'CLOSE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
