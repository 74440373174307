export const genMeta = ({
  title,
  description,
  keywords,
  image,
  other,
  isRoot = false,
}: {
  title: string;
  description?: string;
  image?: string;
  keywords?: string;
  other?: Record<string, string>[];
  isRoot?: boolean;
}) => ({
  meta: [
    { title },
    { name: 'description', content: description },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'og:type', content: 'website' },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    ...(keywords ? [{ name: 'keywords', content: keywords }] : []),
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover',
    },
    ...(image ? [{ name: 'og:image', content: image }] : []),
    ...(other ? other : []),
  ],
  ...(image
    ? [
        {
          links: [
            {
              rel: 'image_src',
              href: image,
            },
          ],
        },
      ]
    : []),
  ...(isRoot
    ? {
        script: [
          {
            async: true,
            src: 'https://www.googletagmanager.com/gtag/js?id=G-2GJCS2F15K',
          },
          {
            children: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-2GJCS2F15K');
              `,
          },
        ],
      }
    : {}),
});