import { useMemo, useState, useEffect } from 'react';
import { ConversationCoreFragment, UserCoreFragment } from 'shared/__generated__/graphql';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { gql } from 'shared/__generated__/gql';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  START_CONVERSATION_WITH_REC_MUTATION,
  CREATE_CONVERSATION_MUTATION,
} from 'shared/misc/graphql/RecommendationMutations';

const PAGE_LENGTH = 20;

interface SelectArgs {
  recId?: string;
  onConfirm?: (conversationIds: string[]) => void;
  onlyAllowOne?: boolean;
  skipConvos?: boolean;
  includeDraftUsers?: boolean;
  onlyFollowing?: boolean;
  includeSelf?: boolean;
}

export default function useSelectUsersForConversation({
  onConfirm: onConfirmFromProps,
  recId,
  skipConvos = false,
  includeDraftUsers = false,
  onlyFollowing = false,
  onlyAllowOne = false,
  includeSelf = false,
}: SelectArgs = {}) {
  const auth = useAuth<true>();

  const [selectedUsers, setSelelectedUsers] = useState<Record<string, UserCoreFragment>>({});

  const [selectedConversations, setSelelectedConversations] = useState<
    Record<string, ConversationCoreFragment>
  >({});

  const {
    variables,
    previousData,
    data = previousData,
    loading,
    refetch,
  } = useQuery(StartConversationSearchQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: PAGE_LENGTH,
      search: '',
      excludeUserIds: Object.keys(selectedUsers),
      skipConvos,
      includeDraftUsers,
      onlyFollowing,
      includeSelf,
    },
  });

  const form = useForm<{ query: string }>({
    defaultValues: {
      query: '',
    },
  });

  const { watch, register, setValue, control } = form;

  const values = watch();
  const { query } = values;

  const debouncedRefetch = useMemo(() => {
    return _.debounce((nextVariables) => {
      refetch(nextVariables);
    }, 500);
  }, [refetch]);

  useEffect(() => {
    if (variables && variables?.search !== query) {
      debouncedRefetch({
        ...variables,
        search: query,
        first: PAGE_LENGTH,
        after: null,
        // excludeUserIds: Object.keys(selectedUsers),
      });
    }
  }, [query]);

  const allResults = [
    ...(skipConvos
      ? []
      : (data?.selectConversationsConnection?.edges || []).map((edge) => edge.node)),
    ...(data?.selectUserConnection?.edges || []).map((edge) => edge.node),
  ];

  const results = allResults.filter((edge) => {
    return !selectedUsers[edge.id] && !selectedConversations[edge.id];
  });

  const onToggle = (node: UserCoreFragment | ConversationCoreFragment) => {
    const isConversation = node.__typename === 'Conversation';

    setValue('query', '');

    if (isConversation) {
      const conversation = node as ConversationCoreFragment;
      setSelelectedConversations((s) => {
        if (s[conversation.id]) {
          const { [conversation.id]: _, ...rest } = s;
          return rest;
        }
        return {
          ...s,
          [conversation.id]: conversation,
        };
      });
      return;
    }

    setSelelectedUsers((s) => {
      if (s[node.id]) {
        const { [node.id]: _, ...rest } = s;
        return rest;
      }
      if (onlyAllowOne) {
        return {
          [node.id]: node,
        };
      }
      return {
        ...s,
        [node.id]: node,
      };
    });

    if (
      Object.keys(selectedUsers).length > 0 &&
      (data?.selectUserConnection?.edges?.length || 0) < 7
    ) {
      refetch({
        ...variables,
        search: query,
        excludeUserIds: Object.keys(selectedUsers),
      });
    }
  };

  const selectedGroupConvos = Object.values(selectedConversations).filter(
    // 2 or more users (auth user counts)
    (c) => c.users.length > 2,
  );

  const allSelectedUserIds = [
    ...Object.keys(selectedUsers),
    ...Object.values(selectedConversations).flatMap((c) =>
      c.users.reduce((p, { user }) => {
        if (user.id !== auth.user?.id) {
          p.push(user.id);
        }
        return p;
      }, [] as string[]),
    ),
  ];

  const [sendExistingRec, sendExistingRecState] = useMutation(START_CONVERSATION_WITH_REC_MUTATION);

  const [createConversation, createConversationState] = useMutation(CREATE_CONVERSATION_MUTATION);

  const onCreateGroup = async () => {
    if (allSelectedUserIds.length > 10) {
      alert('Too many users (max 10)');
      return;
    }

    if (allSelectedUserIds.length) {
      await createConversation({
        variables: {
          userIds: allSelectedUserIds,
        },
        onCompleted: ({ createConversation: conversation }) => {
          setSelelectedUsers({});
          setSelelectedConversations((s) => ({
            [conversation.id]: conversation,
          }));
        },
      });
    }
  };

  const onConfirm = async () => {
    const users = Object.values(selectedUsers);
    const conversations = Object.values(selectedConversations);

    if (users.length > 0 || conversations.length > 0) {
      if (recId) {
        await sendExistingRec({
          variables: {
            targetRecId: recId,
            userIds: users.map((u) => u.id),
            conversationIds: conversations.map((c) => c.id),
          },
          onCompleted: ({ startConversationWithRec }) => {
            onConfirmFromProps?.(startConversationWithRec.map(({ id }) => id));
          },
        });
      } else {
        // TODO: Implement create rec for new convo
      }
    }
  };

  const isSelected = (node) => {
    return selectedUsers[node.id] || selectedConversations[node.id];
  };

  const resultsToDisplay = [
    ...Object.values(selectedUsers),
    ...(skipConvos ? [] : Object.values(selectedConversations)),
    ...results,
  ];

  return {
    form,
    isSearching: loading && form.formState.isDirty,
    onToggle,
    onCreateGroup,
    onConfirm,
    resultsToDisplay,
    isSelected,
    selectedCount: allSelectedUserIds.length,
    allSelectedUsers: Object.values(selectedUsers),
    allSelectedUserIds,
    selectedGroupConvos,
    sendExistingRecState,
    createConversationState,
  };
}

const StartConversationSearchQuery = gql(/* GraphQL */ `
  query StartConversationSearchShared(
    $first: Int
    $after: String
    $search: String
    $excludeUserIds: [String!]
    $skipConvos: Boolean!
    $includeDraftUsers: Boolean
    $onlyFollowing: Boolean
    $includeSelf: Boolean
  ) {
    selectConversationsConnection: conversationConnection(
      first: $first
      after: $after
      search: $search
      includeEmpty: true
    ) @connection(key: "selectConversationsConnection") @skip(if: $skipConvos) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationCore
        }
      }
    }

    selectUserConnection: userConnection(
      first: 20
      after: $after
      search: $search
      excludeUserIds: $excludeUserIds
      onlyFollowing: $onlyFollowing
      includeDraftUsers: $includeDraftUsers
      includeSelf: $includeSelf
      excludeUsersWithConversations: true
      includeGuests: true
      includeUnfinishedSignUp: false
      includePerfectlyImperfect: true
    ) @connection(key: "selectUserConnection") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UserCore
          id
          username
          firstName
          lastName
          createdAt
          updatedAt
          avatarPhotoSrc
        }
      }
    }
  }
`);
