import { Box } from '@chakra-ui/react';
import InlineSubscribeToSignUp from '../../../../../InlineSubscribeToSignUp';
import { LexicalGenericInnerComponentProps } from './LexicalGenericComponent';

export default function LexicalInlineSubscribeComponent({
  isEditable,
}: LexicalGenericInnerComponentProps) {
  return (
    <Box width="100%" id="yooo">
      <InlineSubscribeToSignUp showForAuthenticatedUsers={isEditable} redirectTo="/sign-up/more" />
    </Box>
  );
}
