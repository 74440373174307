import React from 'react';

export default function Spinner({
    size = 'lg',
    placement = 'center',
    className = '',
}: {
    size?: 'lg' | 'sm';
    placement?: 'center' | 'left' | 'right';
    className?: string;
}) {
    const sizeMap = {
        lg: 'h-6 w-6',
        sm: 'h-4 w-4',
    }
    
  return (
    <div className={`flex justify-${placement} ${className}`}>
        <div className={`animate-spin ${sizeMap[size]} border-4 border-brand-highlight rounded-full border-t-transparent`}></div>
    </div>
  );
}