import { createFileRoute, redirect } from '@tanstack/react-router';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__';
import EmailSettingsItem from './-email-settings-item';
import emailPreferenceOptions from 'shared/misc/emailPreferenceOptions';
import { EmailListType, UserPreference } from 'shared/__generated__/graphql';
import { getSessionUser } from '../../../../actions/getSessionUser';

const InitialQuery = gql(/* GraphQL */ `
  query getEmailPreferences {
    me {
      emailLists
      preferences
      id
    }
  }
`);

export const Route = createFileRoute('/settings/manage/email/')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({ to: `/sign-in?destination=${encodeURIComponent('/settings/premium')}` });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Email Preferences',
      },
    ],
  }),
});

function RouteComponent() {
  const { data, loading } = useQuery(InitialQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const allEmailsDisabled = data?.me?.preferences.includes(UserPreference.AllEmailsDisabled);
  const weeklyDigestEnabled = data?.me?.emailLists.includes(EmailListType.WeeklyContentDigest);

  return (
    <div className="flex justify-center p-[12px] mb-[500px]">
      <div className="flex flex-col gap-[12px] md:gap-[24px] justify-center w-full md:w-[800px] max-w-full">
        <h1 className="uppercase typography-heading-lg text-[36px] md:typography-heading-xl text-brand-highlight text-center my-[24px]">
          Email Preferences
        </h1>
        <div className="flex flex-col w-full md:pt-[36px] pb-[36px] items-center justify-center mb-[128px] gap-y-4">
          {loading && !data?.me && <Spinner size="lg" className="mt-[48px]" />}
          {(!loading || data?.me) &&
            emailPreferenceOptions.map((node) => (
              <div className="w-full max-w-[780px] min-w-[300px]" key={node.title}>
                <EmailSettingsItem
                  title={node.title}
                  subtitle={node.subtitle}
                  frequency={node.frequency}
                  emailTypes={node.emailTypes}
                  user={data?.me}
                  // isChecked={allEmailsDisabled ? false : undefined}
                  allEmailsDisabled={allEmailsDisabled || weeklyDigestEnabled}
                />

                <div className="border-b-[1px] w-full mt-[24px] mb-24[px] border-dashed border-brand-main" />
              </div>
            ))}
          {data?.me && (
            <>
              <EmailSettingsItem
                title="Once a week digest"
                subtitle="You'll only hear from us 1x a week"
                // frequency={node.frequency}
                activateWeeklyContentDigest
                isChecked={weeklyDigestEnabled}
                emailTypes={[]}
                user={data?.me}
              />
              <div className="border-b-[1px] w-full mt-[24px] mb-24[px] border-dashed border-brand-main" />
              <EmailSettingsItem
                title="DISABLE ALL EMAILS"
                subtitle="You'll never get an email from us again."
                // frequency={node.frequency}
                allEmails
                isChecked={allEmailsDisabled}
                emailTypes={[]}
                user={data?.me}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
