import { SystemStyleObject } from '@chakra-ui/react';

const emojiPickerStyles: Record<string, SystemStyleObject> = {
  '.EmojiPickerReact': {
    fontWeight: '700',
    maxHeight: '300px',
    padding: '0px',
    margin: '0px',
    textStyle: 'brand.body !important',
  },
  '.EmojiPickerReact *': {
    textStyle: 'brand.body !important',
    fontWeight: 'bold !important',
    color: 'red',
  },
  '.EmojiPickerReact.epr-main': {
    border: 'none !important',
  },
  '.epr-emoji-category-label': {
    textStyle: 'brand.body !important',
    fontWeight: 'bold',
    fontFamily: 'brand.arialNarrowBold !important',
    textTransform: 'uppercase !important',
    bgColor: 'white !important',
    fontSize: '12px !important',
  },
  '.epr-category-nav': {
    display: 'none !important',
  },
  '.epr-skin-tones': {
    display: 'none !important',
  },
  '.epr-body::-webkit-scrollbar': {
    display: 'none',
    width: '0 !important',
  },
  '.epr-search': {
    borderRadius: '0px !important',
    fontFamily: 'brand.arial !important',
    fontSize: '16px !important',
    border: '1px solid !important',
    borderColor: 'brand.main !important',
    backgroundColor: 'white !important',
    color: 'brand.black !important',
    _focus: {
      borderColor: 'brand.highlight !important',
      outlineOffset: '-1px !important',
      outline: '1px solid !important',
      outlineColor: 'brand.highlight !important',
      boxShadow: 'none !important',
    },
    focusBorderColor: 'brand.highlight !important',
    fontWeight: 'normal',
  },
};

export default {
  ...emojiPickerStyles,
};
