import { createFileRoute, redirect } from '@tanstack/react-router';
import { Box, Flex, Stack, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { PremiumTier } from 'shared/__generated__/graphql';
import { gql } from 'shared/__generated__';
import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import { getPathForStep } from 'shared/misc/hooks/useUpdateSetupStep';
import { SYNC_SUBSTACK_STATUS } from 'shared/misc/graphql/SignUpFragments';
import { StyledStar } from 'shared/misc/components/StarContainer';
import { getSessionUser } from '../../../actions/getSessionUser';
import { TWLink } from '../../../components/link';
import { Button as TWButton } from '../../../components/button';
import Heading from 'shared/misc/components/heading';

export const Route = createFileRoute('/settings/premium/')({
  component: RouteComponent,
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({ to: `/sign-in?destination=${encodeURIComponent('/settings/premium')}` });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Premium',
      },
    ],
  }),
});

function RouteComponent({ asModal = false }) {
  const auth = useAuth();
  const isElligibleForFreeTrial = !auth.user?.hasRedeemedFreeTrial;

  const { data } = useSuspenseQuery(GET_STRIPE_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  useQuery(SYNC_SUBSTACK_STATUS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const [getLink, { loading: checkoutLoading }] = useMutation(GET_CHECKOUT_LINK);

  const [getPortalLink, { loading: portalLoading }] = useMutation(GET_PORTAL_LINK);

  const [updateStripeSubscription, { loading: updateLoading }] = useMutation(UPDATE_SUBSCRIPTION);

  const [selectedTier, setSelectedTier] = useState<PremiumTier>(
    auth.user?.premiumTier || PremiumTier.MonthlyPremium,
  );

  const toast = useToast();

  const resetSelected = () => setSelectedTier(auth.user?.premiumTier || PremiumTier.MonthlyPremium);

  const isTierActiveOnUser = () => {
    if (
      selectedTier === PremiumTier.MonthlyPremium &&
      (auth.user?.premiumTier === PremiumTier.MonthlyPremium ||
        auth.user?.premiumTier === PremiumTier.MonthlySubstackPremium)
    ) {
      return true;
    }
    return selectedTier === auth.user?.premiumTier;
  };

  const tierMap = {
    [PremiumTier.MonthlyPremium]: 'Monthly Premium',
    [PremiumTier.MonthlySubstackPremium]: 'Monthly Premium (via Substack)',
    [PremiumTier.YearlyPremium]: 'Yearly Premium',
    [PremiumTier.YearlyLegendFr]: 'Yearly Legend Fr',
  };

  const path = auth.user?.signUpStep ? getPathForStep(auth.user?.signUpStep) : null;

  useEffect(() => {
    if (auth.user?.premiumTier !== selectedTier) {
      setSelectedTier(auth.user?.premiumTier || PremiumTier.MonthlyPremium);
    }
  }, [auth.user?.premiumTier]);

  if (!auth.user) {
    return null;
  }

  return (
    <div className="flex flex-col m-auto max-w-[600px]">
      <Box
        border={!asModal ? '1px dashed' : 'none'}
        borderColor="brand.main"
        className="p-[24px] md:p-[48px] my-[24px] md:my-[48px] border-[1px] border-dashed border-brand-main"
        position="relative"
      >
        <Stack spacing={6} w="100%" maxW={{ base: '400px', lg: '800px' }}>
          <Stack spacing="24px" w="100%" maxW={{ base: '500px' }} alignSelf="center">
            <Box alignSelf="center">
              <span className="text-brand-highlight">
                <StyledStar fill="currentColor" width={50} height={50} />
              </span>
            </Box>
            <Heading
              className="text-[60px] md:text-[72px] text-center "
              variant="XL"
              strokeWidth={0}
            >
              PREMIUM
            </Heading>
            <Stack spacing="12px" className="mt-0 md:mt-[12px]">
              <Text className="typography-heading-sm" fontWeight="normal" textAlign="center">
                Click to explore each tier
              </Text>
              {auth.user?.premiumTier && (
                <Text textStyle="brand.body" fontWeight="normal" fontSize="16px" textAlign="center">
                  Your current tier is <em>{tierMap[auth.user?.premiumTier]}</em>
                </Text>
              )}
              <Stack spacing="12px" mt="12px" mb={{ base: '12px', md: '24px' }}>
                <TWButton
                  variant={
                    selectedTier === PremiumTier.MonthlyPremium ||
                    selectedTier === PremiumTier.MonthlySubstackPremium
                      ? 'primary'
                      : 'secondary'
                  }
                  onClick={() => setSelectedTier(PremiumTier.MonthlyPremium)}
                  size="md"
                  className="p-2 text-lg md:text-xl w-100"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <span className="text-lg md:text-[24px]">MONTHLY PREMIUM</span>
                    <span className="text-lg md:text-[24px]">$6 / month</span>
                  </Flex>
                </TWButton>
                <TWButton
                  variant={selectedTier === PremiumTier.YearlyPremium ? 'primary' : 'secondary'}
                  onClick={() => setSelectedTier(PremiumTier.YearlyPremium)}
                  size="md"
                  // height="fit-content"
                  width="100%"
                  className="p-2 text-lg md:text-xl w-100"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <span className="text-lg md:text-[24px]">YEARLY PREMIUM</span>
                    <span className="text-lg md:text-[24px]">$64 / year</span>
                  </Flex>
                </TWButton>
                <TWButton
                  variant={selectedTier === PremiumTier.YearlyLegendFr ? 'primary' : 'secondary'}
                  onClick={() => setSelectedTier(PremiumTier.YearlyLegendFr)}
                  size="md"
                  className="p-2 text-lg md:text-xl w-100"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <span className="text-lg md:text-[24px]">YEARLY LEGEND FR</span>
                    <span className="text-lg md:text-[24px]">$120 / year</span>
                  </Flex>
                </TWButton>
              </Stack>
              {/* <Text textStyle="brand.headingSm" as="span" textAlign="center">
                  Perks
                </Text> */}
              {selectedTier === PremiumTier.YearlyPremium && (
                <>
                  <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                    <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                      ⭐ You save money...
                    </Text>{' '}
                    an annual membership is <em>11.11% cheaper</em> than going monthly.
                  </Text>
                </>
              )}
              {selectedTier === PremiumTier.YearlyLegendFr && (
                <>
                  <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                    <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                      ⭐ FULLY CUSTOM THEME...
                    </Text>{' '}
                    a color scheme for your profile that's completely unique to you. Pretty epic.
                  </Text>
                  <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                    <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                      ⭐ EARLY ACCESS...
                    </Text>{' '}
                    get new features on PI.FYI and exclusive content before anyone else in the whole
                    world, besides us.
                  </Text>
                  <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                    <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                      ⭐ An even cooler badge...
                    </Text>{' '}
                    that way everyone knows that you're a "legend" who supports independent
                    businesses & dope newsletters.
                  </Text>
                </>
              )}
              <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                  ⭐ Premium newsletter experience...
                </Text>{' '}
                you'll get extra content on our newsletter, access to the full archive, and more.
              </Text>
              <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                  ⭐ Exclusive Profile themes...
                </Text>{' '}
                you can make your account look cool as hell with premium themes like Soft Pink,
                Donnie Darko, Johanna, and more.
              </Text>{' '}
              <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                  ⭐ ENHANCED APP FEATURES...
                </Text>{' '}
                change your username whenever you want, view more than 20 of your bookmarks and
                likes, see other people's likes, and more.
              </Text>
              <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                  ⭐ ACCESS THE ARCHIVE...
                </Text>{' '}
                search and read hundreds of archival Perfectly Imperfect features with guests like
                Charli XCX, John Cale, and Michael Imperioli.
              </Text>
              {selectedTier !== PremiumTier.YearlyLegendFr && (
                <Text textStyle="brand.body" fontWeight="normal" fontSize="16px">
                  <Text textStyle="brand.actionSm" as="span" fontSize="16px">
                    ⭐ A sick badge...
                  </Text>{' '}
                  not much more to say about it. You get a sick badge on your profile that says
                  "pro" so people will know you're a real one.
                </Text>
              )}
              {/* {selectedTier === PremiumTier.MonthlyPremium && (
                  <>
                    <Text textStyle="brand.body" fontWeight="normal">
                      <Text textStyle="brand.actionSm" as="span">
                        PERFECTLY IMPERFECT PRO...
                      </Text>{' '}
                      All of the above + exclusive newsletter content on Substack like editor recs,
                      and extra recommendations on guest features, and archival interviews.
                    </Text>
                  </>
                )} */}
            </Stack>
            <Stack spacing="16px" mt="12px">
              {!isTierActiveOnUser() && (
                <>
                  <TWButton
                    variant="primaryBlack"
                    size="md"
                    className="p-2 text-lg md:text-xl w-100"
                    isDisabled={isTierActiveOnUser() || checkoutLoading || updateLoading}
                    isLoading={checkoutLoading || updateLoading}
                    onClick={() => {
                      if (data?.me?.stripe?.isActive) {
                        updateStripeSubscription({
                          variables: {
                            premiumTier: selectedTier,
                          },
                          onCompleted: () => {
                            resetSelected();
                            toast({
                              title: 'Subscription updated.',
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                              position: 'top',
                            });
                          },
                        });
                      } else {
                        getLink({
                          variables: {
                            premiumTier: selectedTier,
                          },
                          onCompleted: ({ createStripeCheckoutSession }) => {
                            window.location.href = createStripeCheckoutSession;
                          },
                        });
                      }
                    }}
                    // _disabled={{ opacity: 0.2 }}
                  >
                    <span className="text-lg md:text-[24px]">
                      {!auth.user?.premiumTier && selectedTier
                        ? isElligibleForFreeTrial
                          ? 'UPGRADE WITH 7 DAY FREE TRIAL'
                          : 'UPGRADE ACCOUNT'
                        : 'CHANGE TIER'}
                    </span>
                  </TWButton>
                </>
              )}
              {data?.me?.stripe?.isActive &&
                data?.me?.stripe?.premiumTier &&
                isTierActiveOnUser() && (
                  <>
                    <TWButton
                      variant="secondary"
                      size="md"
                      isDisabled={portalLoading}
                      isLoading={portalLoading}
                      className="self-center p-[12px] text-lg md:text-xl w-100"
                      onClick={() => {
                        getPortalLink({
                          onCompleted: ({ createStripePortalSession }) => {
                            window.location.href = createStripePortalSession;
                          },
                        });
                      }}
                      // _disabled={{ opacity: 0.2 }}
                    >
                      Manage payment details
                    </TWButton>
                    <TWButton
                      variant="link"
                      isDisabled={updateLoading}
                      isLoading={updateLoading}
                      className="self-center p-[12px] text-lg md:text-xl w-100 "
                      onClick={() => {
                        updateStripeSubscription({
                          variables: {
                            premiumTier: null,
                          },
                          onCompleted: () => {
                            resetSelected();
                            toast({
                              title: 'Subscription cancelled.',
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                              position: 'top',
                            });
                          },
                        });
                      }}
                    >
                      Cancel subscription
                    </TWButton>
                  </>
                )}
              {path && !asModal ? (
                <TWLink
                  to={path}
                  variant="stylized"
                  className="w-auto self-center text-gray-200 uppercase"
                >
                  Back to sign up
                </TWLink>
              ) : !asModal ? (
                <TWLink
                  to="/settings"
                  variant="stylized"
                  className="w-auto self-center text-gray-200 uppercase"
                >
                  Back to settings
                </TWLink>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}

const GET_STRIPE_DETAILS = gql(/* GraphQL */ `
  query getStripeDetails {
    me {
      id
      isPremium
      premiumTier
      stripe {
        id
        isActive
        premiumTier
      }
    }
  }
`);

const GET_CHECKOUT_LINK = gql(/* GraphQL */ `
  mutation createStripeCheckoutSession3($premiumTier: PremiumTier!) {
    createStripeCheckoutSession(premiumTier: $premiumTier)
  }
`);

const UPDATE_SUBSCRIPTION = gql(/* GraphQL */ `
  mutation updateStripeSubscription($premiumTier: PremiumTier) {
    updateStripeSubscription(premiumTier: $premiumTier) {
      id
      isPremium
      premiumTier
      stripe {
        id
        isActive
        premiumTier
      }
    }
  }
`);

const GET_PORTAL_LINK = gql(/* GraphQL */ `
  mutation createStripePortalSession {
    createStripePortalSession
  }
`);
