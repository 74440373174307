import { ComponentStyleConfig, defineStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = defineStyleConfig({
  // style object for base or default style
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  sizes: {
    sm: {},
    lg: {},
  },
  variants: {
    default: {
      border: '0px solid transparent',
      _hover: {
        textDecoration: 'none',
        border: '0px solid transparent',
        color: 'brand.highlight',
      },
    },
    stylized: {
      fontColor: 'brand.main',
      borderBottom: '1px dashed',
      borderColor: 'brand.main',
      _hover: {
        color: 'brand.highlight',
        textDecoration: 'none',
        borderColor: 'brand.highlight',
        opacity: 1,
      },
      textStyle: 'brand.actionSm',
    },
    stylizedBasic: {
      fontColor: 'brand.main',
      borderBottom: '1px dashed #000',
      borderColor: 'brand.main',
      _hover: {
        color: 'brand.highlight',
        textDecoration: 'none',
        borderColor: 'brand.highlight',
      },
    },
    fancy: {
      textStyle: 'brand.actionSm',
      color: 'brand.yellow',
      backgroundColor: 'brand.blue',
      pl: '2px',
      pr: '2px',
      _hover: {
        color: 'brand.blue',
        backgroundColor: 'brand.yellow',
      },
    },
    stylizedNoDefaultBorder: {
      fontColor: 'brand.main',
      _hover: {
        color: 'brand.highlight',
        textDecoration: 'none',
        borderColor: 'brand.highlight',
      },
      textStyle: 'brand.actionSm',
    },
    basic: {
      color: 'brand.main',
      border: '1px solid transparent',
      _hover: {
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.main',
      },
    },
    basicWithBlue: {
      border: '1px solid transparent',
      _hover: {
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.main',
      },
    },
    basicLight: {
      color: 'brand.secondary',
      border: '1px solid transparent',
      _hover: {
        textDecoration: 'none',
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.secondary',
      },
    },
    btn: {
      _hover: {
        textDecoration: 'none',
      },
    },
    list: {
      _hover: {
        textDecoration: 'none',
        borderBottom: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
});
