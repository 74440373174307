import React from 'react';
import _ from 'lodash';
import useStars from 'shared/misc/hooks/useStars';
import { cn } from '../../../utils/cn';

interface MasonryGridWithStarsProps {
  title: string;
  children: React.ReactNode;
  columnCount?: any;
  columnGap?: string;
  centerItems?: boolean;
  withStars?: boolean;
  // TAILWIND STYLES
  headerStyles?: string;
  bodyStyles?: string;
}

export default function MasonryGridWithStars({
  title,
  children,
  columnCount,
  columnGap = '8px',
  centerItems = false,
  withStars = true,
  headerStyles,
  bodyStyles,
}: MasonryGridWithStarsProps) {
  const widescreenStars = useStars(true, false, 8, true);
  const mobileStars = useStars(false, false, 8, true);

  return (
    <div className="relative overflow-visible p-0">
      {withStars && (
        <div className="absolute inset-0 pointer-events-none z-[1000]">
          <div className="hidden md:block">{widescreenStars}</div>
          <div className="block md:hidden">{mobileStars}</div>
        </div>
      )}
      <div className="w-full">
        <h2
          className={cn(
            'text-center text-brand-title-background mb-0 md:mb-4 typography-heading-xl font-brand-arial-narrow!',
            '[transform:scale(1,1.25)]',
            'whitespace-normal',
            'pb-6 md:pb-0',
            '[-webkit-text-stroke:2px]',
            'max-w-full font-weight-semibold',
            '[letter-spacing:1.04px] [line-height:90%]',
            headerStyles,
          )}
          style={{
            WebkitTextStrokeColor: 'var(--theme-title-border)',
          }}
        >
          {title}
        </h2>

        <div
          className={cn(
            'mx-auto md:w-[1000px] w-xs max-w-full md:px-4',
            'grid gap-[8px] md:gap-2',
            centerItems && 'place-items-center',
            columnCount,
            bodyStyles,
          )}
        >
          {React.Children.map(children, (child, index) => (
            <div key={index} className={cn('w-full', centerItems && 'flex justify-center')}>
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { MasonryGridWithStars };
