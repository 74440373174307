import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { TWLink } from '../../components/link';
import prisma from 'database';
import { Article, Feature } from 'database';
import _ from 'lodash';
import { useState } from 'react';
import Heading from 'shared/misc/components/heading';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { createServerFn } from '@tanstack/react-start';

export type ArticleWithExtraData = Article & { document: Document | null; feature: Feature | null };

const getUnlockedArticles = createServerFn({ method: 'GET' }).handler(async () => {
  const articles = await prisma.article.findMany({
    orderBy: [
      {
        score: {
          sort: 'desc',
          nulls: 'last',
        },
      },
      {
        publishedAt: {
          sort: 'desc',
          nulls: 'last',
        },
      },
    ],
    include: {
      feature: {
        select: {
          id: true,
          title: true,
          subtitle: true,
          score: true,
          guests: true,
        },
      },
      document: {
        select: {
          id: true,
          title: true,
          subtitle: true,
        },
      },
    },
  });

  return articles;
});

export const Route = createFileRoute('/unlocked-archive/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'Secret URLs',
      },
    ],
  }),
  loader: async () => {
    const articles = await getUnlockedArticles();
    return articles;
  },
});

function RouteComponent() {
  const articles = Route.useLoaderData();
  const [searchQuery, setSearchQuery] = useState('');

  const queryRegex = RegExp(searchQuery, 'ig');

  const sortedArticles = _.sortBy(
    articles,
    (article) => article.feature?.score || article.score || 0,
  ).reverse();

  const isMatch = (article: ArticleWithExtraData) => {
    return searchQuery
      ? article.feature
        ? (article.feature?.guests || []).some((guest) => guest.name.match(queryRegex))
        : (article.title || '').match(queryRegex)
      : true;
  };

  const filteredArticles =
    //@ts-ignore
    sortedArticles.filter((article) => isMatch(article));

  return (
    <Flex
      marginTop={{ md: '48px', base: '24px' }}
      padding={{ md: '0px', base: '20px' }}
      width={{ md: '800px', lg: '1200px', base: '100%' }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxWidth="80%"
      className="mt-[24px] md:mt-[48px] p-[20px] md:p-0 w-100 lg:w-[1200px] md:w-[800px] m-auto"
    >
      <Heading
        as="h1"
        variant="XL"
        className="sm:text-[50px] lg:text-[104px] text-center space-y-3"
      >
        🔓 Archive 🔓
      </Heading>
      <Stack
        spacing="24px"
        maxWidth={{ md: '600px', base: '100%' }}
        justifyContent="center"
        marginTop="24px"
      >
        <InputGroup borderRight="0" width="400px" alignSelf="center">
          <PerfInput
            leftNode={
              <InputLeftElement pointerEvents="none" className="p-2 text-gray-300 text-xl">
                <SearchIcon />
              </InputLeftElement>
            }
            placeholder="Start typing to search..."
            borderColor="brand.highlight"
            borderWidth="2px"
            focusBorderColor="brand.highlight"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            borderRadius="0"
            color="brand.highlight"
            fontWeight="bold"
          />
        </InputGroup>
        <Stack
          spacing="12px"
          justifyContent="center"
          minWidth={{ base: '100%', lg: '700px' }}
          className="max-w-100 lg:max-w-[700px] flex flex-col items-start space-y-3"
        >
          {filteredArticles &&
            filteredArticles.map((article: ArticleWithExtraData) => {
              let title = article.title || article.document?.title || article.feature?.title || '';
              const subtitle =
                article.subtitle || article.document?.subtitle || article.feature?.subtitle || '';

              try {
                title = title.replace(/#([0-9]{2,3}):/gi, '').trim();
              } catch (e) {
                console.log(e);
              }

              return (
                <Box
                  key={article.id}
                  trigger="hover"
                  placement="right-start"
                  id="feature-info"
                  isLazy
                >
                  <TWLink
                    style={{ textDecoration: 'none' }}
                    variant="ghost"
                    textAlign="left"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    target="_blank"
                    to={`/p/${article.id}`}
                    width="fit-content"
                    alignSelf="center"
                  >
                    <Text
                      as="b"
                      className="chakra-text sm:text-[16px] lg:text-[40px] hover:typography-brand-highlight typography-heading-sm text-background css-13m7za4 bg-brand-highlight text-brand-background hover:text-brand-highlight hover:bg-brand-background"
                    >
                      {title}
                    </Text>
                  </TWLink>
                </Box>
              );
            })}
        </Stack>
        <Button
          position="fixed"
          bottom="0"
          right="0"
          margin="25px"
          bgColor="brand.highlight"
          color="brand.secondary"
          _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
          borderRadius="0px"
          onClick={() => {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }}
        >
          Back to top
        </Button>
      </Stack>
    </Flex>
  );
}
