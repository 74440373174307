import React, { useRef } from 'react';
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  UseDisclosureReturn,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import usePromptForm, {
  PromptFormHandle,
  PromptFormInputs,
} from '../by-type/prompts/usePromptForm';
import { gql } from '../../../__generated__/gql';
import { processLexicalState } from '../../../lexical';
import { PromptItemFragment } from '../../../__generated__/graphql';
import { safeParse } from '../rich-text/basic/BasicEditor';
import { isInWebView } from '../../../webview';

type Props = {
  prompt: PromptItemFragment;
  modalState?: UseDisclosureReturn;
};

export default function UpdatePromptModal({ prompt, modalState: modalStateOveride }: Props) {
  const { isOpen, onClose } = modalStateOveride || useDisclosure();

  const formRef = useRef<PromptFormHandle>(null);

  const toast = useToast();

  const [updatePrompt, updatePromptState] = useMutation(UPDATE_PROMPT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getProfileView', 'getHomeview'],
  });

  const IS_IN_WEB_VIEW = isInWebView();

  const onUpdatePrompt = async (values: PromptFormInputs) => {
    const { titleLexicalState, contentLexicalState = null, emoji = '' } = values;

    const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
    const { text: title } = processLexicalState(titleLexicalState);

    updatePrompt({
      variables: {
        promptId: prompt.id,
        input: {
          title,
          emoji,
          content,
          contentLexical,
        },
      },
      onCompleted: async () => {
        toast({
          title: 'Ask updated.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
        onClose();
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const { onSubmit, mainFormNode } = usePromptForm({
    onSubmit: onUpdatePrompt,
    ref: formRef,
    isSubmitting: updatePromptState.loading,
    editorHeight: '320px',
    defaultValues: {
      title: prompt?.title || '',
      emoji: prompt?.emoji || '😃',
      contentLexicalState: prompt.content ? safeParse(prompt?.contentLexical) : '' || '',
    },
  });

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  return (
    <Modal
      isOpen={isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      motionPreset="none"
      onClose={onClose}
    >
      {!IS_IN_WEB_VIEW && <ModalOverlay />}
      <ModalContent
        maxH={{ lg: '90%' }}
        height={{ base: '100dvh', lg: '700px' }}
        maxW="100%"
        width={{ base: '100vw', lg: '60vw' }}
        ml={modalMLR}
        mr={modalMLR}
        mb={modalMTB}
        mt={modalMTB}
        p={{ base: '12px', md: '24px', lg: '48px' }}
        paddingTop={IS_IN_WEB_VIEW ? '48px' : undefined}
        border={IS_IN_WEB_VIEW ? '0px' : undefined}
      >
        {/* <ModalHeader fontSize={{ base: '22px', lg: '36px' }}>Ask for Recommendations</ModalHeader> */}
        {!IS_IN_WEB_VIEW && (
          <ModalCloseButton
            size="lg"
            right={{ base: IS_IN_WEB_VIEW ? '60px' : '24px', lg: '61px' }}
            top={{ base: IS_IN_WEB_VIEW ? '60px' : '24px', lg: '61px' }}
            zIndex="200000"
          />
        )}
        <ModalBody h="100%" mb="24px" fontSize="16px" overflowY="scroll">
          {mainFormNode}
        </ModalBody>
        <ModalFooter
          {...(IS_IN_WEB_VIEW ? { top: 0 } : { bottom: 0 })}
          position="absolute"
          left="0px"
          width="100%"
          backgroundColor="brand.background"
          p={{ base: '12px', md: '12px 24px 24px', lg: '12px 48px 48px' }}
        >
          <Flex justifyContent="flex-end" width="100%" alignItems="center">
            <HStack spacing="12px">
              <Button
                size="sm"
                onClick={onClose}
                variant="textOnly"
                color="brand.lightgrey"
                _hover={{ color: 'brand.red' }}
                border="none"
                textStyle="brand.actionSm"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                variant="primary"
                textStyle="brand.actionSm"
                fontSize="13.5px"
                isLoading={updatePromptState.loading}
                isDisabled={updatePromptState.loading}
              >
                Save
              </Button>
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const UPDATE_PROMPT = gql(/* GraphQL */ `
  mutation updatePrompt($input: CreatePromptInput!, $promptId: String!) {
    updatePrompt(input: $input, promptId: $promptId) {
      id
      ...PromptItem
    }
  }
`);
