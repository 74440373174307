import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Button } from 'web2/app/components/button';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useUploadProfilePhotos, CssInjector } from 'shared/misc/hooks/useUploadProfilePhoto';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up/photo/')({
  component: SignUpPhotoPage,
});

function SignUpPhotoPage() {
  const toast = useToast();
  const { setNavigation } = useSignUpNavigation();
  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.MORE_BIRTHDAY, USER_SIGN_UP_STEP.PHOTO],
  });

  const { user } = useAuth();
  const { trackEvent } = useAnalytics();

  const {
    state: { isCropping, isError, isUploading, isDone },
    reset,
    uploadPhotos,
    cropComponent,
    dropzoneProps,
  } = useUploadProfilePhotos();

  const onSubmit = async () => {
    await uploadPhotos();
    await trackEvent('Click', isDone ? 'Photo_Added' : 'Step_Skipped', {
      userId: user?.id,
    });
    await updateStep(USER_SIGN_UP_STEP.LOCATION);
  };

  useEffect(() => {
    setNavigation({
      rightArrowDisabled: false,
      leftArrowDisabled: false,
      showRightArrow: true,
      showLeftArrow: isCropping,
      onLeftPress: () => (isCropping ? reset() : updateStep(USER_SIGN_UP_STEP.MORE_BIRTHDAY)),
      rightLabel: isCropping ? (isUploading ? 'UPLOADING...' : 'DONE') : undefined,
      leftLabel: isCropping ? 'BACK' : undefined,
      onRightPress: onSubmit,
      rightArrowColor: isCropping ? '#FFBB00' : '#FFFFFF',
      yPosition: 265,
      onSkip: onSubmit,
      showSkip: true,
      signupStep: USER_SIGN_UP_STEP.PHOTO,
    });
  }, [setNavigation, isCropping, reset, isUploading]);

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error',
        description: 'There was an error uploading your photo.',
        status: 'error',
      });
    }
  }, [isError]);

  const [displayText] = useTypewriter('Add a profile photo.', 50);

  return (
    <>
      <CssInjector />
      <div className="min-h-full flex flex-col relative">
        <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
          {displayText}
        </div>
        <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
          It doesn't even have to be a photo of you. Just something that feels like you.
        </div>
        <div className="mt-[68px]">
          {isCropping ? (
            <div className="w-[calc(100%-150px)] sm:w-[calc(100%-200px)] flex justify-center items-center self-center relative max-w-361 mx-auto sm:mt-32 md:mt-0">
              {cropComponent}
            </div>
          ) : !isDone ? (
            <div {...dropzoneProps.getRootProps()}>
              <input {...dropzoneProps.getInputProps()} />
              <Button variant="primaryBlackText" className="w-full max-w-[321px] h-[41px]">
                CHOOSE A PHOTO
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
