import { createFileRoute, useRouter } from '@tanstack/react-router';
import { Link as BasicLink, Box, Button, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__/gql';
import { useBackButton } from 'shared/misc/hooks/useBackButton';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { InView } from 'react-intersection-observer';
import PromptItem from 'shared/misc/components/by-type/prompts/PromptItem';
import { ModalNames, useModalState } from 'shared/misc/wrappers/ModalProvider';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { TWLink } from '../../../../components/link';

export const Route = createFileRoute('/u/$username/asks/')({
  component: RouteComponent,
});

type ListViewProps = {
  username: string;
};

gql(/* GraphQL */ `
  fragment ProfilePromptsView on User {
    id
    email
    firstName
    lastName
    username
    profileTheme
    promptCount
    promptConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PromptItem
        }
      }
    }
  }
`);

const ProfilePromptsViewQuery = gql(/* GraphQL */ `
  query getProfilePromptsView($username: String!, $first: Int, $after: String) {
    user(username: $username) {
      ...ProfilePromptsView
    }
  }
`);

const PAGE_LENGTH = 25;

function RouteComponent() {
  const { username } = Route.useParams();
  const router = useRouter();
  const auth = useAuth();
  const themeColors = useThemeColors();
  const strokeColor = themeColors.titleBorder;

  const addAskModalState = useModalState(ModalNames.ADD_ASK);

  const { loading, data, fetchMore } = useQuery(ProfilePromptsViewQuery, {
    variables: { username },
    fetchPolicy: 'cache-and-network',
  });

  const { getCanGoBack, goBack } = useBackButton();

  const profile = data?.user;

  useAutoSetTheme(profile?.profileTheme || null);

  const getNextPage = async () => {
    if (loading || !data?.user?.promptConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.user?.promptConnection.pageInfo.endCursor,
      },
    });
  };

  if (loading && !profile) {
    return null;
  }

  if (!profile) {
    router.navigate({ to: '/not-found' });
  }

  const prompts = profile.promptConnection.edges;

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack
        spacing="24px"
        width={{ base: '100%', md: '1000px' }}
        // width="100%"
      >
        {getCanGoBack() && (
          <BasicLink variant="stylized" onClick={goBack} width="fit-content">
            Back
          </BasicLink>
        )}
        <Stack spacing="24px" justifyContent="center">
          <h1 className="typography-heading-lg text-brand-highlight mb-[24px] mt-[48px] uppercase text-center">
            <TWLink
              to={`/u/${profile.username}`}
              css={{
                WebkitTextStroke: '2px',
                WebkitTextStrokeColor: strokeColor,
              }}
            >
              @{profile.username}
            </TWLink>{' '}
            IS ASKING...
          </h1>
          <SimpleGrid
            columns={{ base: 1, md: prompts.length > 1 ? 2 : 1 }}
            gap="24px"
            justifyContent="center"
          >
            {prompts?.map(({ node }) => (
              <PromptItem key={node.id} prompt={node} />
            ))}
          </SimpleGrid>

          {prompts.length === 0 && (
            <Stack
              spacing="12px"
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              {profile.id === auth.user?.id ? (
                <>
                  <Box>
                    <Text textStyle="brand.headingSm" textAlign="center">
                      You don't have any prompts yet.
                    </Text>
                  </Box>
                  <Box>
                    <Button ml="6px" variant="link" onClick={addAskModalState.onOpen} padding="0px">
                      Create your first?
                    </Button>
                  </Box>
                </>
              ) : (
                <Box>
                  <Text textStyle="brand.headingSm" textAlign="center">
                    <TWLink to="/u/$username" params={{ username: profile.username }}>
                      @{profile.username}
                    </TWLink>{' '}
                    hasn't created any asks yet.
                  </Text>
                </Box>
              )}
            </Stack>
          )}
          {prompts.length > 0 && (
            <>
              <InView
                as="div"
                rootMargin="4000px 0px"
                onChange={(inView) => {
                  if (inView) {
                    getNextPage();
                  }
                }}
              />
              {loading && <Spinner size="lg" placement="center" />}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
