'use client';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'web2/app/components/button';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from 'web2/app/components/ui/dropdown-menu';
import { gql } from 'shared/__generated__/gql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth } from 'shared/misc/hooks/useAuth';
import _ from 'lodash';
import { InView } from 'react-intersection-observer';
import FeedItem from '../../components/by-type/feed/feed-item';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import { FeedItemType, ProfileThemeType, RecCategory } from 'shared/__generated__/graphql';

import {
  REC_CATEGORY,
  getRecQueryCategoryAsEmoji,
  getRecQueryCategoryAtString,
} from 'shared/data/rec';
import { Controller, useForm } from 'react-hook-form';
import { GetFeedViewQuery } from 'shared/__generated__/graphql';
import { VList, WindowVirtualizer, type WindowVirtualizerHandle } from 'virtua';
import { isInScrollData } from '../../utils/scrolling';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { cn } from 'web2/app/utils/cn';

const FeedViewQuery = gql(/* GraphQL */ `
  query getFeedView(
    $first: Int
    $after: String
    $showGlobalFeed: Boolean
    $types: [FeedItemType!]
    $category: RecCategory
  ) {
    fullFeedConnection: feedConnection(
      first: $first
      after: $after
      showGlobalFeed: $showGlobalFeed
      types: $types
      excludeSessionUser: true
      category: $category
    ) @connection(key: "fullFeedConnectionHomePage") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...FullFeedItem
        }
      }
    }
  }
`);

const ViewMoreFeedViewQuery = gql(/* GraphQL */ `
  query getViewMoreFeedView(
    $before: String
    $showGlobalFeed: Boolean
    $types: [FeedItemType!]
    $category: RecCategory
  ) {
    newFeedItemCount(
      before: $before
      showGlobalFeed: $showGlobalFeed
      types: $types
      excludeSessionUser: true
      category: $category
    )
  }
`);

const AnnouncementsQuery = gql(/* GraphQL */ `
  query getAnnouncements {
    announcements {
      key
      title
      message
      important
    }
  }
`);

const RecCountQuery = gql(/* GraphQL */ `
  query getRecCount {
    me {
      id
      recCount
    }
  }
`);

enum FeedContentFilter {
  ALL = 'ALL',
  ONLY_RECS = 'ONLY_RECS',
}

const PAGE_LENGTH = 30;

export function FeedInfiniteScroll({
  onlyFollowing = true,
  isInView: isTopInView = true,
  filter = FeedContentFilter.ALL,
  mainFeedData: initialFeedData,
}: {
  onlyFollowing?: boolean;
  isInView?: boolean;
  filter?: FeedContentFilter;
  mainFeedData?: GetFeedViewQuery;
}) {
  const [isInline, setIsInline] = useState<boolean>(() => {
    const savedState = typeof window !== 'undefined' ? localStorage?.getItem('isInline') : null;
    return savedState ? JSON.parse(savedState) : false;
  });
  const [togglePosition, setTogglePosition] = useState(() => {
    const savedState = typeof window !== 'undefined' ? localStorage?.getItem('isInline') : null;
    return savedState && JSON.parse(savedState) ? 'right' : 'left';
  });

  const { watch, control } = useForm<{ category: REC_CATEGORY | null }>({
    defaultValues: {
      category: null,
    },
  });

  const values = watch();
  const { category } = values;

  const auth = useAuth();

  useAutoSetTheme(auth.user?.profileTheme || ProfileThemeType.Default);

  const variables = useMemo(() => {
    return {
      showGlobalFeed: !onlyFollowing,
      types: [
        FeedItemType.AddRecommendation,
        FeedItemType.NewRePost,
        FeedItemType.NewRePostWithContent,
        FeedItemType.NewPromptRecReply,
        FeedItemType.NewPrompt,
      ],
      category,
    };
  }, [onlyFollowing, category]);

  const {
    data: upToDateData,
    loading,
    networkStatus,
    error,
    refetch,
    fetchMore,
    variables: currentVariables,
  } = useQuery(FeedViewQuery, {
    fetchPolicy: isInScrollData() ? 'cache-first' : 'cache-and-network',
    variables: {
      ...variables,
      after: null,
      first: 30,
    },
    notifyOnNetworkStatusChange: true,
  });

  const data = upToDateData ? upToDateData : initialFeedData;

  const connection = data?.fullFeedConnection;
  const upToDateStartCursor = upToDateData?.fullFeedConnection?.pageInfo?.startCursor;

  const [loadCount, viewMoreFeedViewQuery] = useLazyQuery(ViewMoreFeedViewQuery, {
    fetchPolicy: 'network-only',
  });

  // useEffect(() => {
  //   if (upToDateStartCursor) {
  //     const r = async () => {
  //       // const res = await loadCount({
  //       //   variables: {
  //       //     ...variables,
  //       //     before: upToDateStartCursor || null,
  //       //   },
  //       // });
  //     };

  //     if (viewMoreFeedViewQuery.called) {
  //       r();
  //     }

  //     const intervalRef = setInterval(() => {
  //       r();
  //     }, 10000);
  //     return () => clearInterval(intervalRef);
  //   }
  // }, [upToDateStartCursor, variables]);

  const getNextPage = async () => {
    if (!connection?.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...currentVariables,
        first: PAGE_LENGTH,
        after: connection?.pageInfo.endCursor,
      },
    }).then((d) => console.log({ d }));
  };

  const feedItems = (connection?.edges || []).map(({ node }) => node);

  const newCount = viewMoreFeedViewQuery.data?.newFeedItemCount || 0;

  const handleToggle = () => {
    setIsInline((prev) => {
      const newState = !prev;
      localStorage?.setItem('isInline', JSON.stringify(newState));
      setTogglePosition(newState ? 'right' : 'left');

      requestAnimationFrame(() => {
        document.body.offsetHeight;
      });

      return newState;
    });
  };

  const virtuaRef = useRef<WindowVirtualizerHandle>(null);

  // Add this ref to track fetched items
  const fetchedCountRef = useRef(0);
  const uniqueFeedItems = useMemo(() => {
    const ids = new Set();
    return feedItems.filter((item, index, self) => {
      if (ids.has(item.id)) return false;
      ids.add(item.id);
      return true;
    });
  }, [feedItems]);

  return (
    <div className="flex flex-col items-center justify-center gap-6 w-[800px] max-w-full">
      {viewMoreFeedViewQuery.data && newCount > 0 && (
        <Button
          className="w-full self-center h-full m-0 text-brand-main border-brand-main border-1 border-dashed"
          variant="secondaryBlack"
          // variant="link"
          // color="brand.highlight"
          onClick={async () => {
            if (!viewMoreFeedViewQuery.loading) {
              await refetch();
            }
          }}
        >
          Show ({newCount}
          {newCount === 50 ? '+' : ''}) new post{newCount == 1 ? '' : 's'}
        </Button>
      )}

      <div className="flex flex-col gap-6 flex-1 w-full">
        <div className="w-full flex justify-between">
          <div className="before:absolute before:inset-0 before:bg-[var(--brand-main)] before:opacity-20 before:-z-10 border border-dashed border-brand-main self-center h-[38px] m-0 w-[129px] relative hover:border-brand-highlight hover:text-brand-highlight">
            <div
              className={`absolute transition-all duration-300 ease-in-out w-[129px] flex justify-between ${
                togglePosition === 'left' ? 'left-[3px]' : 'left-[29px]'
              }`}
            >
              <Button
                className={cn(
                  'w-[95px] mt-[3px] h-[30px] border-brand-main border-1 border-dashed border-[1px] whitespace-nowrap',
                  'bg-brand-background focus:outline-none focus:box-shadow-none self-center',
                  'hover:border-brand-highlight hover:text-brand-highlight flex items-center justify-center self-center',
                )}
                onClick={handleToggle}
                variant="secondaryBlack"
              >
                <div className="text-brand-main bg-brand-background text-brand-headingSm text-brand-uppercase">
                  {isInline ? '🤏 Compact' : '👀 Gallery'}
                </div>
              </Button>
            </div>
          </div>
          <Controller
            control={control}
            name="category"
            render={({ field: { ref: _ref, onChange } }) => (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="text-brand-main bg-brand-background typography-heading-sm border border-dashed border-brand-main hover:border-brand-highlight focus:border-brand-highlight focus:outline-none hover:border-dashed focus:box-shadow-none min-w-[129px]">
                    {category
                      ? `${getRecQueryCategoryAsEmoji(category)} ${getRecQueryCategoryAtString(
                          category.toUpperCase() as RecCategory,
                        )}`
                      : '⭐ Everything'}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-brand-background text-brand-main">
                  <DropdownMenuItem onSelect={() => onChange(null)}>⭐ Everything</DropdownMenuItem>
                  {[
                    REC_CATEGORY.MUSIC,
                    REC_CATEGORY.MOVIES,
                    REC_CATEGORY.BOOKS,
                    // REC_CATEGORY.RESTAURANTS,
                    REC_CATEGORY.CLOTHING,
                    REC_CATEGORY.FOOD_AND_DRINK,
                    REC_CATEGORY.TV,
                    REC_CATEGORY.VIDEOS,
                    REC_CATEGORY.PLACES,
                    REC_CATEGORY.MISC,
                  ]
                    .slice(0, 6)
                    .map((c) => {
                      const title = getRecQueryCategoryAtString(c.toUpperCase() as RecCategory);
                      const e = getRecQueryCategoryAsEmoji(c);
                      return (
                        <DropdownMenuItem
                          className="w-[200px] text-brand-main"
                          key={c}
                          onSelect={() => onChange(c)}
                        >
                          {e} {title}
                        </DropdownMenuItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          />
        </div>
        {!upToDateData && loading && (
          <>
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
          </>
        )}

        <div className="">
          {uniqueFeedItems.map((feedItem) => (
            <div className="w-full flex justify-center py-4" key={feedItem.id}>
              <FeedItem
                feedItem={feedItem}
                hideAllFeedContext={true}
                hideContextForTypes={
                  filter === FeedContentFilter.ONLY_RECS
                    ? new Set([FeedItemType.AddRecommendation, FeedItemType.NewPromptRecReply])
                    : undefined
                }
                promptProps={{ isInline }}
                recProps={{ isInline }}
              />
            </div>
          ))}
          {!!uniqueFeedItems.length && (
            <InView
              rootMargin="750px 0px"
              onChange={(inView) => {
                if (connection?.pageInfo.hasNextPage && inView) {
                  fetchedCountRef.current = feedItems.length;
                  getNextPage();
                }
              }}
            />
          )}

          {/* <WindowVirtualizer
            ref={virtuaRef}
            className="h-full w-full"
            itemSize={200}
            overscan={5}
            onScroll={async (e) => {
              if (!virtuaRef.current) return;

              if (
                connection?.pageInfo.hasNextPage &&
                virtuaRef.current.findEndIndex() + 5 > feedItems.length
              ) {
                fetchedCountRef.current = feedItems.length;
                await getNextPage();
              }
            }}
          >
            {networkStatus === NetworkStatus.fetchMore && (
              <div className="flex justify-center py-4">
                <Spinner size="lg" />
              </div>
            )}

            {uniqueFeedItems.length === 0 && !loading && (
              <div className="flex justify-center py-4">
                <Text textStyle="brand.headingSm">Nothing to see here.</Text>
              </div>
            )}
          </WindowVirtualizer> */}
        </div>
      </div>
    </div>
  );
}
