import { createFileRoute } from '@tanstack/react-router';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import {
  GlobalWebViewObjects,
  getGlobalWebViewObject,
  safeSendWebViewMessage,
} from 'shared/webview/index';
import { PromptItemFragment } from 'shared/__generated__/graphql';
import UpdatePromptModal from 'web2/app/components/modals/update-prompt-modal';
import { UpdatePromptMessages } from 'shared/webview/messages';

export const Route = createFileRoute('/mobile/update-prompt/')({
  component: RouteComponent,
  ssr: false,
});

function RouteComponent() {
  const modalState = useDisclosure();

  const onClose = () => {
    safeSendWebViewMessage(UpdatePromptMessages.CLOSE);
  };

  const WEB_VIEW_PROPS = getGlobalWebViewObject(GlobalWebViewObjects.UpdatePromptProps);
  const prompt = WEB_VIEW_PROPS?.prompt as PromptItemFragment;

  return (
    <UpdatePromptModal prompt={prompt} modalState={{ ...modalState, isOpen: true, onClose }} />
  );
}
