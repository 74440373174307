import { Link } from '@chakra-ui/next-js';
import { Box, Flex, LinkBox, Tooltip, Text, useTheme, Button, Stack } from '@chakra-ui/react';
import NextImage from 'next/image';
import _ from 'lodash';
import { useRouter } from '@tanstack/react-router';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { gql } from '../../../../__generated__/gql';
import useFollowUser from '../../../hooks/useFollowUser';
import IsLoggedInTooltip from '../../gates/IsLoggedInTooltip';
import { useAuth } from '../../../hooks/useAuth';
import { UserOnlineIndicator } from './UserOnlineIndicator';
import { getEmojisAsArrayFromString } from '../../../../emoji';
import { UserCoreFragment, UserDirectoryItemFragment } from '../../../../__generated__/graphql';
import { UserAvatar } from './UserAvatar';

gql(/* GraphQL */ `
  fragment UserDirectoryItem on User {
    ...UserCore
    amIFollowing
    areTheyFollowing
    avatarPhotoFile {
      ...PerfImageFromFile
    }
    recs(first: 5, includePromptRecs: true) {
      id
      emoji
    }
    # relatedFollowersConnection(first: 100) {
    #   edges {
    #     node {
    #       ...ProfileMutual
    #     }
    #   }
    # }
  }
`);

type Props = {
  user: UserDirectoryItemFragment | UserCoreFragment;
  recsWhereBadgeIs?: boolean;
  noLink?: boolean;
  isSmall?: boolean;
  showMutuals?: boolean;
  withBorder?: boolean;
  withActiveIndicator?: boolean;
};

export default function UserDirectoryItem({
  user,
  recsWhereBadgeIs = true,
  noLink = false,
  isSmall = false,
  showMutuals = true,
  withBorder = true,
  withActiveIndicator = false,
}: Props) {
  const auth = useAuth();

  const { followUser, unfollowUser } = useFollowUser({ user });

  const recSummaryNode = user.recs ? (
    <Tooltip label="Summary of recent recs.">
      <Text
        textStyle="brand.body"
        textAlign="right"
        width="fit-content"
        whiteSpace="nowrap"
        fontSize="16px"
      >
        {getEmojisAsArrayFromString(user.recs.reduce((acc, r) => `${acc}${r.emoji?.trim()}`, ''))
          .slice(0, 5)
          .join(' ')
          .trim()}
      </Text>
    </Tooltip>
  ) : null;

  const showUsernameOnTopLine = isSmall || (!user.firstName && !user.lastName) || true;

  const router = useRouter();

  // console.log('user', auth.user);

  const userItemNode = (
    <Flex
      alignItems="center"
      padding="8px"
      {...(withBorder
        ? {
            border: '1px dashed',
            borderColor: 'brand.main',
          }
        : {})}
      maxWidth="100%"
      // minWidth="350px"
    >
      <Flex height="75px" marginRight="8px">
        <UserAvatar
          // withActiveIndicator={withActiveIndicator}
          user={user}
          avatarSize={isSmall ? 40 : 75}
          withPopover={false}
        />
      </Flex>
      <Flex
        height="75px"
        maxW="calc(100% - 83px)"
        flex={1}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack
          alignSelf="center"
          maxWidth="calc(100% - 90px)"
          spacing="12px"
          justifyContent="flex-start"
          marginRight="8px"
        >
          <Text
            textStyle="brand.headingSm"
            fontSize={isSmall ? '16px' : undefined}
            noOfLines={1}
            maxWidth="100%"
          >
            {_.truncate(
              showUsernameOnTopLine ? user.username : [user.firstName, user.lastName].join(' '),
              { length: 15 },
            )}
          </Text>
          {'recs' in user && (
            <Text
              textStyle="brand.body"
              textTransform="uppercase"
              whiteSpace={{ base: 'normal' }}
              noOfLines={1}
              opacity={user.recs?.length ? 1 : 0.2}
            >
              {user.recs?.length ? recSummaryNode : 'NO RECS YET'}
            </Text>
          )}
        </Stack>
        <Stack alignSelf="center" spacing="12px" alignItems="flex-end" justifyContent="flex-end">
          {withActiveIndicator && <UserOnlineIndicator showOffline={false} user={user} />}
          {/* {showMutuals && auth.user ? (
            <Text textStyle="brand.actionSm" textTransform="uppercase" alignSelf="flex-end">
              {user.relatedFollowersConnection?.edges.length === 0
                ? 'NO'
                : user.relatedFollowersConnection?.edges.length}{' '}
              MUTUAL
              {user.relatedFollowersConnection?.edges.length !== 1 ? 'S' : ''}
            </Text>
          ) : (
            <Box height="15px" alignSelf="flex-end" />
          )} */}
          <IsLoggedInTooltip>
            <Box>
              {' '}
              {auth.user &&
              auth.user?.id !== user.id &&
              'amIFollowing' in user &&
              'areTheyFollowing' in user ? (
                <>
                  {user.amIFollowing ? (
                    <Button
                      size="xs"
                      width="fit-content"
                      variant="secondaryBlack"
                      alignSelf="flex-end"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (auth.user) {
                          unfollowUser();
                        } else {
                          router.navigate({
                            to: '/sign-in',
                          });
                        }
                      }}
                    >
                      Following
                    </Button>
                  ) : (
                    <Button
                      size="xs"
                      width="fit-content"
                      variant="primaryBlack"
                      isDisabled={!auth.user}
                      alignSelf="flex-end"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (auth.user) {
                          followUser();
                        } else {
                          router.navigate({
                            to: '/sign-in',
                          });
                        }
                      }}
                    >
                      {user.areTheyFollowing ? 'Follow back' : 'Follow'}
                    </Button>
                  )}
                </>
              ) : null}
              {!auth.user || auth.user?.id === user.id ? <Box /> : null}
            </Box>
          </IsLoggedInTooltip>
        </Stack>
      </Flex>
    </Flex>
  );

  return !noLink ? (
    <LinkBox as={Link} href={`/u/${user.username}`} key={user.id}>
      {userItemNode}
    </LinkBox>
  ) : (
    userItemNode
  );
}
