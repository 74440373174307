import { gql } from '../../__generated__/gql';

export const SEND_SIGN_IN_EMAIL = gql(/* GraphQL */ `
  mutation sendSignInLinkViaEmail($email: String!, $isMobile: Boolean, $destination: String) {
    sendSignInLinkViaEmail(email: $email, isMobile: $isMobile, destination: $destination)
  }
`);

export const SIGN_IN_VIA_CODE = gql(/* GraphQL */ `
  mutation verifyEmailAndSignInViaCode($code: String!) {
    verifyEmailAndSignInViaCode(code: $code)
  }
`);

export const SIGN_IN_VIA_PASSWORD = gql(/* GraphQL */ `
  mutation signInViaPassword($input: SignInViaPasswordInput!) {
    signInViaPassword(input: $input)
  }
`);
