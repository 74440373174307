import {
  Box,
  BoxProps,
  HStack,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo } from 'react';
import { RiLinkM } from 'react-icons/ri';
import { useRouter, useRouterState } from '@tanstack/react-router';
import normalizeUrl from 'normalize-url';
import NextImage from 'shared/misc/components/image-wrapper';
import _ from 'lodash';
import { PiPushPinFill } from 'react-icons/pi';
import { gql } from '../../../../__generated__';
import {
  RecType,
  RecommendationItemFragment,
  RecommendationItemWithEndorsedByFragment,
  UserFlagType,
} from '../../../../__generated__/graphql';
import BasicEditor from '../../rich-text/basic/BasicEditor';
import ExpandableTextContainer from '../text/ExpandableTextContainer';
import { recBodyTheme } from '../../../theme/selectors/lexicalStyles';
import { useIsBackgroundLight } from '../../../hooks/useContrastHighlight';
import { useProfileContext } from '../../../providers/ProfileContext';
import { isContentArchived, isUserGuest } from '../../../../gates';
import BlurredPaywall, { PaywallReason } from '../../gates/BlurredPaywall';
import { useAuth } from '../../../hooks/useAuth';
import RecommendationActions from './parts/RecommendationActions';
import { RecommendationContext } from './providers/useRecommendationContext';
import RecommendationItemBottom from './parts/RecommendationItemBottom';
import RecommendationExpandableImage from './RecommendationImageGroup';
import RecommendationPrivateContext from './RecommendationPrivateContext';
import OverlapTitle from 'shared/misc/components/OverlapTitle';
import { useThemeColors } from '../../../hooks/useThemeColors';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { twMerge } from 'tailwind-merge';
import { TWLink } from '../../../../../../apps/web2/app/components/link';

gql(/* GraphQL */ `
  fragment RecommendationItemCore on Rec {
    id
    title
    url
    date
    content
    contentHTML
    contentLexical
    emoji
    createdAt
    isHidden
    muted
    updatedAt
    isBookmarked
    isEndorsed
    isReposted
    isPinned
    endorsementCount
    commentsCount
    type
    user {
      ...UserCore
    }
    # guest {
    #   id
    #   name
    # }
    # feature {
    #   id
    #   title
    #   url
    #   list {
    #     id
    #   }
    # }
    prompt {
      ...PromptItem
    }
    attachments {
      ...RecommendationAttachment
    }
    # permissions {
    #   id
    #   role
    #   user {
    #     ...UserCore
    #   }
    # }
    conversation {
      ...ConversationCore
    }
    isShadowBanned
    isCurated
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationAttachment on Attachment {
    id
    type
    position
    file {
      ...PerfImageFromFile
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItem on Rec {
    ...RecommendationItemCore
    repostTargetRec {
      ...RecommendationItemCore
      repostTargetRec {
        ...RecommendationItemCore
      }
    }
    targetRec {
      ...RecommendationItemCore
      targetRec {
        ...RecommendationItemCore
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItemWithEndorsedBy on Rec {
    ...RecommendationItem
    endorsedByUser(userId: $recEndorsedByUserId) {
      id
      username
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItemList on List {
    id
    title
    entryCount
  }
`);

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};

const orZero = (n: number) => (n > 0 ? n : 0);

const DeletedRec = () => (
  <Box textStyle="brand.actionSm" padding="12px" border="1px dashed" borderColor="brand.main">
    Original Recommendation deleted...
  </Box>
);

export type RecommendationItemProps = {
  rec: RecommendationItemFragment | RecommendationItemWithEndorsedByFragment;
  parentRepostRec?: RecommendationItemFragment | RecommendationItemWithEndorsedByFragment;
  contextNode?: ReactNode | null;
  withBorder?: boolean;
  noOfLines?: number;
  withLessPadding?: boolean;
  hideEmoji?: boolean;
  showPrompt?: boolean;
  withEmojiSameLine?: boolean;
  withResponses?: boolean;
  withMinHeight?: boolean;
  withActionMenu?: boolean;
  disableExpandImage?: boolean;
  withUrlSameLine?: boolean;
  withOnlyBottomBorder?: boolean;
  withNoPadding?: boolean;
  smallTitle?: boolean;
  isNestedRepostRec?: boolean;
  withBottomActions?: boolean;
  titleNoOfLines?: number;
  withBottom?: boolean;
  hideTitle?: boolean;
  isRecView?: boolean;
  expandImageOnMount?: boolean;
  withPinnedIcon?: boolean;
  overlapTitle?: string;
  isInline?: boolean;
  enableEmbed?: boolean;
  imageMaxHeight?: BoxProps['maxHeight'];
};

function urlHost(urlString: string): string | null {
  try {
    return `(${new URL(urlString).host})`;
  } catch (error) {
    return null;
  }
}

export default function RecommendationItem(props: RecommendationItemProps) {
  const {
    rec,
    contextNode = null,
    withBorder = true,
    withLessPadding = true,
    withNoPadding = false,
    hideEmoji = false,
    hideTitle = false,
    isRecView = false,
    disableExpandImage = false,
    withUrlSameLine = false,
    withResponses = true,
    withActionMenu = true,
    withBottomActions = true,
    withBottom = true,
    showPrompt = false,
    withMinHeight = false,
    withOnlyBottomBorder = false,
    smallTitle = false,
    isNestedRepostRec = false,
    parentRepostRec = null,
    withPinnedIcon = false,
    expandImageOnMount = false,
    imageMaxHeight,
    withEmojiSameLine = (withPinnedIcon && rec.isPinned) || false,
    noOfLines = props.isInline ? 1 : withPinnedIcon && rec.isPinned ? 2 : 7,
    titleNoOfLines = props.isInline ? 1 : props.titleNoOfLines,
    overlapTitle,
    isInline = false,
    enableEmbed = true,
  } = props;

  const actionsMenuState = useDisclosure();
  const imageModalState = useDisclosure();

  const { profile } = useProfileContext();

  const auth = useAuth();

  const ctx = useMemo(() => ({ rec }), [rec]);

  if (!rec) return <DeletedRec />;

  let { title, emoji, url, content, contentLexical } = rec || {};

  const isPrivateShare =
    rec.type === RecType.Private ||
    rec.type === RecType.PrivateShareExisting ||
    rec.type === RecType.PrivateShareNew;

  const isPrivateShareRepost = isPrivateShare && rec.type !== RecType.PrivateShareNew;

  const isRepost =
    rec.type === RecType.RePost || rec.type === RecType.RePostWithContent || isPrivateShareRepost;

  const targetRec = rec?.repostTargetRec || rec?.targetRec;

  if ((isRepost || isPrivateShareRepost) && targetRec) {
    emoji = isPrivateShareRepost ? '' : targetRec.emoji;
    title = targetRec.title;
    url = targetRec.url;
  }

  title = title?.trim();

  if (title?.startsWith(':')) {
    title = title.replace(':', '').trim();
  }

  if (!title && rec.type === RecType.UnpublishedPostRec) {
    title = 'PLACEHOLDER TITLE';
  }

  const pathname = usePathname();
  const router = useRouter();

  const image = rec.attachments?.find((a) => a.type === 'IMAGE')?.file;
  const hasImage = !!image?.isUploaded;
  const aspectRatio = image?.width && image?.height ? image.width / image.height : 1;
  const isLandscape = image?.width && image?.height ? image.width > image.height : null;
  const modalImageScale = 85;

  const minHeight = isInline && hasImage ? ['0px', '80px', '100px'] : undefined;

  const emojiNode = (
    <Text textStyle="brand.headingLg">{isInline ? emoji?.slice(0, 2) : emoji}</Text>
  );

  const isBackgroundLight = useIsBackgroundLight();

  let normalizedUrl: string | null = null;

  try {
    normalizedUrl = url ? normalizeUrl(url) : null;
  } catch (e) {}

  const linkNode = url ? (
    <Tooltip label={`This rec has a link${normalizedUrl ? ` ${urlHost(normalizedUrl)}` : ''}`}>
      {/* <IconButton
        as={Link}
        to={url}
        target="_blank"
        color="brand.highlight"
        variant="icon"
        icon={}
        height="20px"
        textStyle="brand.headingLg"
        aria-label="rec link"
      /> */}

      <TWLink to={url} target="_blank" className="text-brand-highlight">
        <RiLinkM size="24px" color="currentColor" />
      </TWLink>
    </Tooltip>
  ) : null;

  let extraProps = {};

  let extraClassnames = [];

  let padding: BoxProps['padding'] = { base: '16px', md: '16px' };
  let paddingClassnames = ['p-[16px]', 'md:p-[16px]'];

  if (isInline && !isNestedRepostRec) {
    extraProps = {
      paddingLeft: { base: '64px', sm: '82px', md: '102px' },
    };
    paddingClassnames.push('pl-[64px]', 'sm:pl-[82px]', 'md:pl-[102px]');
  }
  if (withBorder) {
    extraProps = { ...extraProps, border: '1px dashed', borderColor: 'brand.main' };
    if (withOnlyBottomBorder) {
      extraProps = {
        p: '0px',
        paddingBottom: padding,
        borderBottom: '1px dashed',
        borderBottomColor: 'brand.highlightBorder',
        ...(isInline &&
          !isNestedRepostRec && { paddingLeft: { base: '64px', sm: '82px', md: '102px' } }),
      };
      extraClassnames.push(
        'border-bottom-[1px]',
        'border-dashed',
        'border-brand-main',
        'p-[0px]',
        'pb-[16px]',
        'md:pb-[16px]',
      );
      if (isInline && !isNestedRepostRec) {
        extraClassnames.push('pl-[64px]', 'sm:pl-[82px]', 'md:pl-[102px]');
      }
    } else {
      extraClassnames.push(
        'border-t-[1px]',
        'border-l-[1px]',
        'border-r-[1px]',
        'border-dashed',
        'border-brand-main',
      );
    }
  } else if (withNoPadding) {
    padding = '0px';
    paddingClassnames = ['p-[0px]'];
  }

  const oveflowLimit = 60 + (url && rec.type !== RecType.RePostWithContent ? 36 : 0);

  let finalContextNode = contextNode;
  let repostContextNode: ReactNode | null = null;

  const themeColors = useThemeColors();

  if ((isRepost || isPrivateShare) && rec.user) {
    const logoColor =
      themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

    const imageSrc = rec.user?.avatarPhotoSrc || `/default-profile-photo-${logoColor}-small.png`;

    let contextLinkNode: Element | null = null;

    if (isPrivateShare && rec.conversation) {
      contextLinkNode = <RecommendationPrivateContext rec={rec} />;
    } else {
      contextLinkNode = !content ? (
        <TWLink to={`/u/$username`} params={{ username: rec.user.username }}>
          <em>RE-REC'D BY @{rec.user.username}</em>
        </TWLink>
      ) : (
        <TWLink to={`/u/$username`} params={{ username: rec.user.username }}>
          <em>RE-REC'D BY @{rec.user.username}</em>
        </TWLink>
      );
    }

    repostContextNode = contextLinkNode && (
      <HStack
        spacing="6px"
        alignItems="center"
        alignSelf="center"
        width="fit-content"
        opacity={isBackgroundLight ? 0.3 : 0.8}
        _hover={{ opacity: 1 }}
        maxWidth={`calc(100% - ${oveflowLimit}px)`}
        {...(isInline && !isNestedRepostRec && { pb: '8px' })}
      >
        {/* <Tooltip label={`@${rec.user?.username} Reposted this.`}>
          <IconButton
            variant="icon"
            icon={<RiRecycleFill size="20px" />}
            aria-label="repost context"
          />
        </Tooltip> */}
        {!isPrivateShare && (
          <img
            src={imageSrc}
            width={15}
            height={15}
            alt="image"
            style={{
              minWidth: '15px',
              minHeight: '15px',
            }}
          />
        )}
        <span
          // whiteSpace="nowrap"
          // overflow="hidden"
          // textOverflow="ellipsis"
          // paddingRight="0.3em"
          // fontSize="11px"
          // textStyle="brand.actionSm"
          className="whitespace-nowrap overflow-hidden text-ellipsis pr-[0.3em] font-size-[11px] typography-action-sm"
        >
          {contextLinkNode}
        </span>
      </HStack>
    );

    if (targetRec && !content && !parentRepostRec && !isPrivateShareRepost) {
      return (
        <RecommendationItem
          {...props}
          contextNode={repostContextNode}
          rec={targetRec}
          parentRepostRec={rec}
          showPrompt={!isPrivateShare}
        />
      );
    }

    if (!finalContextNode) {
      finalContextNode = repostContextNode;
    }
  }

  const isStaffPick =
    !profile &&
    (rec.user?.flag.includes(UserFlagType.PerfectlyImperfect) ||
      rec.user?.flag.includes(UserFlagType.Staff));

  const showTop =
    finalContextNode ||
    (((hideEmoji && rec.url) || !hideEmoji) &&
      !(withUrlSameLine && withEmojiSameLine) &&
      (emoji || !!rec.url));

  const isEndorsedRec = !isRepost && rec?.endorsedByUser;
  const showRecPrompt = !isRepost && rec?.prompt && showPrompt;
  const hasAddedContext = finalContextNode || isEndorsedRec || showRecPrompt;
  const extraHeight = isInline && hasAddedContext ? 10 : 0;

  const emojiNodeWithMaybeImage = !hideEmoji && !withEmojiSameLine && emoji && (
    <>
      {isInline && hasImage && (
        <TWLink
          to={'/rec/$recId'}
          params={{ recId: rec.id }}
          className={twMerge(
            'absolute',
            'left-[10px]',
            'sm:left-[12px]',
            'top-[50%]',
            'sm:top-[6px]',
            'cursor-pointer',
            'transform-translate-y-1/2',
            'sm:transform-translate-y-0',
          )}
        >
          <RecommendationExpandableImage rec={rec} disableExpandImage isThumbnail />
        </TWLink>
      )}
      <span
        // textStyle={
        //   isInline ? (hasImage ? 'brand.aside' : 'brand.bodyXl') : 'brand.headingLg'
        // }
        // left={
        //   isInline
        //     ? {
        //         base: hasImage ? '30px' : '9px',
        //         sm: hasImage ? '48px' : '11px',
        //         md: hasImage ? '68px' : '16px',
        //       }
        //     : undefined
        // }
        // top={
        //   isInline
        //     ? hasImage
        //       ? {
        //           base: `${
        //             content
        //               ? `calc(52px + ${extraHeight}px)`
        //               : `calc(38px + ${extraHeight}px)`
        //           }`,
        //           sm: `calc(50px + ${orZero(extraHeight - 4)}px)`,
        //           md: `calc(68px + ${orZero(extraHeight - 4)}px)`,
        //         }
        //       : content
        //       ? '16px'
        //       : { base: '4px', md: '2px' }
        //     : undefined
        // }
        // position={isInline ? 'absolute' : undefined}
        className={twMerge(
          isInline ? 'absolute' : '',
          isInline
            ? hasImage
              ? 'typography-aside'
              : 'typography-body-xl'
            : 'typography-heading-lg',
          ...(isInline
            ? hasImage
              ? ['left-[30px]', 'sm:left-[48px]', 'md:left-[68px]']
              : ['left-[9px]', 'sm:left-[11px]', 'md:left-[16px]']
            : []),
          isInline && hasImage
            ? content
              ? [
                  `top-[calc(52px+${extraHeight}px)]`,
                  `sm:top-[calc(50px+${orZero(extraHeight - 4)}px)]`,
                  `md:top-[calc(68px+${orZero(extraHeight - 4)}px)]`,
                ]
              : [
                  `top-[calc(38px+${extraHeight}px)]`,
                  `sm:top-[calc(50px+${orZero(extraHeight - 4)}px)]`,
                  `md:top-[calc(68px+${orZero(extraHeight - 4)}px)]`,
                ]
            : content
            ? ['top-[16px]']
            : ['top-[4px]', 'md:top-[2px]'],
        )}
      >
        {isInline ? emoji?.slice(0, 2) : emoji}
      </span>
    </>
  );

  return (
    <RecommendationContext.Provider value={ctx} key={rec.id}>
      <div
        className={twMerge(
          'bg-brand-background relative flex flex-col gap-[16px] w-[100%]',
          withMinHeight ? 'min-h-[200px]' : isInline ? 'min-h-[115px]' : undefined,
        )}
      >
        {overlapTitle && <OverlapTitle>{overlapTitle}</OverlapTitle>}
        {rec.user &&
          rec.user.id !== auth.user?.id &&
          isUserGuest(rec.user) &&
          isContentArchived(rec.date) &&
          !auth?.user?.isPremium &&
          false && <BlurredPaywall reason={PaywallReason.ARCHIVE} />}
        <div
          // display="flex"
          // width="100%"
          // flexGrow={1}
          // flexShrink={0}
          // justifyContent="space-between"
          // flexDirection="column"
          onMouseEnter={actionsMenuState.onOpen}
          onMouseLeave={actionsMenuState.onClose}
          className="flex w-[100%] grow-1 shrink-0 justify-between flex-col"
        >
          <div
            // p={padding}
            // {...extraProps}
            // paddingBottom={{ base: isInline ? '12px' : '36px', md: isInline ? '12px' : '36px' }}
            // borderBottomWidth="0px"
            // flexGrow={1}
            // position={isInline ? 'relative' : undefined}
            className={twMerge(
              'grow-1 border-b-0',
              ...extraClassnames,
              ...paddingClassnames,
              // isInline ? 'pb-[12px]' : 'pb-[36px]',
              isInline && hasImage ? ['min-h-[0px]', 'sm:min-h-[80px]', 'md:min-h-[100px]'] : '',
              isInline ? 'relative' : '',
              isInline && hasImage ? 'min-h-[0px] sm:min-h-[80px] md:min-h-[100px]' : undefined,
              isInline ? 'pb-[12px]' : 'pb-[36px]',
            )}
          >
            {isInline && emojiNodeWithMaybeImage}
            <div className={twMerge('flex flex-col gap-[12px]', withMinHeight ? 'gap-[16px]' : '')}>
              {showTop &&
              !isPrivateShareRepost &&
              !withEmojiSameLine &&
              !withUrlSameLine &&
              (!isInline ||
                finalContextNode ||
                (url &&
                  rec.type !== RecType.RePostWithContent &&
                  !withUrlSameLine &&
                  !isInline)) ? (
                <div className="flex flex-row gap-[12px] items-center">
                  {!isInline && emojiNodeWithMaybeImage}
                  {url &&
                  rec.type !== RecType.RePostWithContent &&
                  !withUrlSameLine &&
                  !isInline ? (
                    <span
                      // display="flex"
                      // as="span"
                      // textStyle="brand.headingLg"
                      // width="fit-content"
                      // height="fit-content"
                      // alignItems="center"
                      className="flex typography-heading-lg w-fit h-fit items-center"
                    >
                      {linkNode}
                    </span>
                  ) : null}
                  {finalContextNode}
                  {!finalContextNode &&
                  ((rec?.prompt && showPrompt) ||
                    ('endorsedByUser' in rec && rec?.endorsedByUser)) ? (
                    <Stack
                      maxWidth={`calc(100% - ${oveflowLimit}px)`}
                      spacing="8px"
                      {...(isInline && !isNestedRepostRec && { pb: '8px' })}
                    >
                      {isEndorsedRec ? (
                        <HStack
                          spacing="3px"
                          alignItems="center"
                          opacity={isBackgroundLight ? 0.3 : 0.8}
                          _hover={{ opacity: 1 }}
                        >
                          <PerfImageFromFile
                            alt="endorsements"
                            src={`https://files.pi.fyi/one-endorsed-user-endorsed.png`}
                            width={10}
                            height={10}
                          />
                          <Text
                            paddingRight="0.3em"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            fontSize="11px"
                            textStyle="brand.actionSm"
                          >
                            <em>LIKED by @{rec.endorsedByUser.username}</em>
                          </Text>
                        </HStack>
                      ) : null}
                      {showRecPrompt && rec.prompt ? (
                        <div
                          // spacing="3px"
                          // alignItems="center"
                          // opacity={isBackgroundLight ? 0.3 : 0.8}
                          // _hover={{ opacity: 1 }}
                          className={twMerge(
                            'flex w-fit h-fit items-center',
                            'gap-[3px]',
                            'opacity-30 hover:opacity-100',
                          )}
                        >
                          <span
                            // fontSize="11px"
                            // paddingRight="0.3em"
                            // textStyle="brand.actionSm"
                            // whiteSpace="nowrap"
                            // overflow="hidden"
                            // textOverflow="ellipsis"
                            className={twMerge('typography-action-sm font-size-[11px] pr-[0.3em]')}
                          >
                            <TWLink to={`/ask/$promptId`} params={{ promptId: rec.prompt!.id }}>
                              <em className="overflow-hidden text-ellipsis whitespace-break-spaces leading-relaxed line-clamp-1">
                                Rec'd on {rec.prompt!.emoji} {rec.prompt!.title}
                              </em>
                            </TWLink>
                          </span>
                        </div>
                      ) : null}
                    </Stack>
                  ) : null}
                </div>
              ) : null}
              {title && !hideTitle && (!isRepost || isNestedRepostRec) ? (
                <div
                  className={twMerge('flex flex-row gap-[12px] items-center')}
                  // spacing="12px"
                  // display="flex"
                  // alignItems="center"
                  style={{
                    marginTop: isInline ? '0px' : '6px',
                  }}
                >
                  {(withUrlSameLine || isInline) && linkNode}
                  {withEmojiSameLine && emojiNode}
                  <span className="w-fit max-w-[100%] pr-[16px]">
                    <Text
                      textStyle="brand.headingLg"
                      fontSize={
                        smallTitle
                          ? isInline
                            ? ['16px', '22px']
                            : ['18px', '16px']
                          : ['20px', '32px']
                      }
                      {...(rec.type !== RecType.UnpublishedPostRec
                        ? {
                            as: TWLink,
                            to: `/rec/${targetRec ? targetRec.id : rec.id}`,
                          }
                        : {})}
                      noOfLines={titleNoOfLines}
                      variant="stylizedNoDefaultBorder"
                      color={
                        rec.type === RecType.UnpublishedPostRec && !rec.title
                          ? 'lightgray'
                          : undefined
                      }
                    >
                      {title}
                    </Text>
                  </span>
                </div>
              ) : null}

              {content ? (
                <div className={withEmojiSameLine ? 'mt-[12px]' : undefined}>
                  {contentLexical && typeof window !== 'undefined' ? (
                    <div
                      // textStyle="brand.bodyLg"
                      // fontWeight="normal"
                      // fontSize="20px"
                      // display="inline-flex"
                      className="typography-body-lg font-weight-normal text-[24px] inline-flex"
                    >
                      <BasicEditor
                        key={rec.updatedAt}
                        isReadOnly={true}
                        initialEditorState={contentLexical}
                        maxLength={30}
                        noOfLines={noOfLines}
                        textStyle="brand.bodyLg"
                        editorTheme={recBodyTheme}
                        readOnlyEditorTheme={recBodyTheme}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-[10px]">
                      <ExpandableTextContainer noOfLines={noOfLines} as={Stack}>
                        <span className="typography-body-lg font-weight-normal font-size-[20px] whitespace-pre-line">
                          {rec.content?.replace(/\n{3,}/g, '\n')}
                        </span>
                      </ExpandableTextContainer>
                    </div>
                  )}
                </div>
              ) : null}
              {!isInline && !isRecView && (
                <RecommendationExpandableImage
                  rec={rec}
                  disableExpandImage={disableExpandImage}
                  expandOnMount={isRecView && false && expandImageOnMount}
                  maxHeight={imageMaxHeight}
                  enableEmbed={enableEmbed}
                />
              )}
              {!isInline && isRecView && (
                <div
                  className="flex"
                  onClick={() => {
                    imageModalState.onOpen();
                  }}
                >
                  <RecommendationExpandableImage
                    rec={rec}
                    disableExpandImage={true}
                    expandOnMount={isRecView && false && expandImageOnMount}
                    maxHeight={imageMaxHeight}
                    enableEmbed={enableEmbed}
                  />
                </div>
              )}
              {isRepost && !isNestedRepostRec && (
                <TWLink
                  to={`/rec/$recId`}
                  params={{ recId: targetRec?.id ?? '' }}
                  className={twMerge(
                    'flex flex-col',
                    'gap-[16px]',
                    targetRec ? 'cursor-pointer' : undefined,
                    !isInline ? 'mb-[6px]' : undefined,
                  )}
                >
                  {targetRec !== null ? (
                    <div
                      className={twMerge(
                        'pointer-events-none',
                        !isInline ? 'mt-[12px]' : undefined,
                      )}
                    >
                      <RecommendationItem
                        rec={targetRec}
                        withLessPadding
                        withNoPadding={withNoPadding}
                        noOfLines={isInline ? 1 : isRecView ? undefined : 2}
                        isNestedRepostRec
                        withUrlSameLine={withUrlSameLine}
                        withOnlyBottomBorder={withOnlyBottomBorder}
                        withBottomActions={false}
                        withActionMenu={false}
                        parentRepostRec={rec}
                        hideEmoji={!isPrivateShare && isRepost}
                        showPrompt={isPrivateShare}
                        isInline={isInline}
                      />
                    </div>
                  ) : (
                    <DeletedRec />
                  )}
                </TWLink>
              )}
            </div>
          </div>

          {withBottom ? (
            <div
              // {...extraProps}
              className={twMerge(
                // ...extraClassnames,
                'border-top-width-[0px]',
                'w-full',
                'align-self-flex-end',
                isInline && !isNestedRepostRec ? 'pl-[64px] sm:pl-[82px] md:pl-[102px]' : '',
                ...(withBorder
                  ? [
                      'border-t-[0px]',
                      'border-l-[1px]',
                      'border-b-[1px]',
                      'border-r-[1px]',
                      'border-dashed',
                      'border-brand-main',
                    ]
                  : []),
              )}
              // borderTopWidth="0px"
              // width="full"
              // alignSelf="flex-end"
            >
              <RecommendationItemBottom
                withResponses={withResponses}
                withBottomActions={withBottomActions}
                parentRepostRec={parentRepostRec}
                withBorder={!isInline && withBorder}
                isRecView={isRecView}
                withNoPadding={isInline || withNoPadding}
                isInline={isInline}
              />
            </div>
          ) : null}

          {/* <Flex textStyle="brand.actionLg" justifyContent="flex-end">
          </Flex> */}
          {withActionMenu && <RecommendationActions withLessPadding={withLessPadding} />}
          {withPinnedIcon && rec.isPinned && profile?.id === rec?.user?.id && (
            // <IconButton
            // variant="icon"
            // color="brand.black"
            // _hover={{ color: 'brand.highlight' }}
            // />
            // )}
            <div className="absolute top-[-3px] left-[-2px] rotate-[260deg]">
              {/* <Tooltip label="Pinned"> */}
              {/* <Icon
                as={PiPushPinFill}
                width={{ base: '20px', md: '16px' }}
                height={{ base: '20px', md: '16px' }}
                color="brand.main"
              /> */}
              <PiPushPinFill className="w-[20px] md:w-[16px] h-[20px] md:h-[16px] text-brand-main" />

              {/* </Tooltip> */}
            </div>
          )}
        </div>
      </div>
      {image?.url && image?.width && image?.height && imageModalState.isOpen && (
        <Modal
          isOpen={imageModalState.isOpen}
          onClose={imageModalState.onClose}
          isCentered
          variant="fullWidthImage"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <ModalCloseButton size={'lg'} />
              <Box px={10}>
                <PerfImageFromFile
                  src={image.url}
                  width={image.width}
                  height={image.height}
                  alt="Recommendation image"
                  style={{
                    width: isLandscape
                      ? `${modalImageScale}vw`
                      : `calc(${modalImageScale}dvh * ${aspectRatio})`,
                    height: isLandscape
                      ? `calc(${modalImageScale}vw / ${aspectRatio})`
                      : `${modalImageScale}dvh`,
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </RecommendationContext.Provider>
  );
}
