import { Stack, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = {
  isCentered?: boolean;
  height?: string | object;
  skeletons?: number;
};

function SkeletonFeedItem({ isCentered, height, skeletons = 3 }: Props) {
  const commonProps = {
    fadeDuration: 5,
    borderRadius: 0,
  };
  return (
    <div
      className={twMerge(
        'flex flex-col gap-[12px] bg-theme-background p-[12px] border border-dashed border-theme-highlight-border animate-pulse',
        isCentered ? 'items-center' : '',
        height ? `h-[${height}]` : 'auto',
      )}
    >
      <div className="h-[30px] w-[80%] bg-gray-200 dark:bg-gray-700" />
      <div className="h-[30px] w-[50%] mb-[6px] bg-gray-200 dark:bg-gray-700" />
      {[...Array(skeletons)].map((_, index) => (
        <div className="h-[20px] bg-gray-200 dark:bg-gray-700" key={index} />
      ))}
    </div>
  );
}

export default SkeletonFeedItem;
