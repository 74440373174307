import { createFileRoute } from '@tanstack/react-router';

import { RecCategory } from 'shared/__generated__/graphql';
import { Box, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import {
  REC_CATEGORY_LIST,
  getRecQueryCategoryAsEmoji,
  getRecQueryCategoryAtString,
} from 'shared/data/rec';
import { perfectlyImperfectColors } from 'shared/misc/theme/colors';
import { Link } from '@tanstack/react-router';

export const Route = createFileRoute('/categories/')({
  component: RouteComponent,
});

export default function RouteComponent() {
  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack spacing="24px" width="100%" maxWidth="700px" justifyContent="center">
        <Heading
          as="h1"
          textAlign="center"
          variant="XL"
          textTransform="uppercase"
          mb="24px"
          mt="48px"
          fontStyle="italic"
          fontSize={{ base: '24px', md: '48px' }}
        >
          Browse recs by...
        </Heading>
        {REC_CATEGORY_LIST.map((c) => {
          const title = getRecQueryCategoryAtString(c.toUpperCase() as RecCategory);
          const e = getRecQueryCategoryAsEmoji(c);
          return (
            <HStack
              key={c}
              fontSize={{ base: '48px', md: '72px' }}
              spacing="12px"
              alignItems="center"
            >
              <Text fontSize={{ base: '48px', md: '72px' }}>{e}</Text>
              <Link to={`/search?category=${c}`} className="link-stylized-no-default-border">
                <Text
                  textStyle="brand.headingXL"
                  textTransform="uppercase"
                  fontSize={{ base: '48px', md: '60px' }}
                  color="brand.titleBackground"
                  css={{
                    WebkitTextStroke: '2px',
                    WebkitTextStrokeColor: perfectlyImperfectColors.brand.titleBorder,
                  }}
                  _hover={{
                    textDecoration: 'underline',
                    // border: '0px solid transparent',
                    // color: 'brand.highlight',
                  }}
                >
                  {title}
                </Text>
              </Link>
            </HStack>
          );
        })}
      </Stack>
    </Box>
  );
}
