

import { Box, Button, Flex, HStack, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { Link } from '@tanstack/react-router';
import UserDirectoryItem from 'shared/misc/components/by-type/user/UserDirectoryItem';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up/app/')({
  component: DonePage,
});

function DonePage() {
  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.FOLLOW, null],
  });

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '10px', md: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          // minHeight="100v%"
          p={{ base: '24px', md: '48px' }}
        >
          <Stack spacing={6} w="100%" maxW={{ base: '400px', lg: '800px' }}>
            <Stack spacing={6} w="100%" maxW={{ base: '600px' }} alignSelf="center">
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">Your account is ready :)</Text>
              <Text textStyle="brand.body" fontWeight="normal">
                Just a few things before you dive in...
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                <strong>There's a PI.FYI app now</strong> and it's better than the website in almost
                every way.{' '}
                <Link
                  variant="stylizedBasic"
                  to="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926"
                >
                  Click here to download it!
                </Link>
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                Don't hesitate to{' '}
                <Link to="mailto:tyler@perfectlyimperfect.fyi?subject=Perfectly%20Imperfect">
                  email
                </Link>{' '}
                us with any feedback or if you encounter a bug. Thanks for being a part of this
                project!
              </Text>
              <HStack spacing={3}>
                <Button
                  onClick={async () => {
                    await updateStep(null);
                  }}
                >
                  Let me in!
                </Button>
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await updateStep(USER_SIGN_UP_STEP.THEME);
                  }}
                >
                  Go back
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}
