import React, { ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface PerfInputProps extends InputProps {
  error?: FieldError | undefined;
  label?: string;
  errorColor?: string;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  beforeNode?: ReactNode;
  afterNode?: ReactNode;
  formStyle?: React.CSSProperties;
}

function PerfInput(
  {
    error,
    label,
    errorColor,
    leftNode = null,
    rightNode = null,
    beforeNode,
    afterNode,
    formStyle = {},
    ...inputProps
  }: PerfInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const isError = !!error;

  return (
    <FormControl style={formStyle} isInvalid={isError}>
      {beforeNode}
      {label && <FormLabel textStyle="brand.actionSm">{label}</FormLabel>}
      <InputGroup>
        {leftNode && <InputLeftElement>{leftNode}</InputLeftElement>}
        <Input fontSize="16px" {...inputProps} ref={ref} />
        {rightNode && <InputRightElement>{rightNode}</InputRightElement>}
      </InputGroup>
      {error && (
        <FormErrorMessage fontWeight="normal" color={errorColor}>
          {error?.message}
        </FormErrorMessage>
      )}
      {afterNode}
    </FormControl>
  );
}

export default React.forwardRef(PerfInput);
