import { PerfImageFromFileFragment } from '../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { Portal, useToast } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { gql } from '../../__generated__';
import { Accept, useDropzone } from 'react-dropzone-esm';

export default function useFileUpload({
  onUpload,
  onFileSign,
  accept = {
    'image/*': [
      '.png',
      '.jpg',
      '.jpeg',
      '.gif',
      '.bmp',
      '.svg',
      '.webp',
      '.tiff',
      '.jfif',
      '.heic',
    ],
  },
}: {
  onFileSign?: (file: PerfImageFromFileFragment) => Promise<void> | void;
  onUpload?: (file: PerfImageFromFileFragment) => Promise<void> | void;
  accept?: Accept | undefined;
} = {}) {
  const [getSignedURLForImage, { data, reset }] = useMutation(GET_SIGNED_URL);

  const [markFileAsUploaded] = useMutation(MARK_AS_UPLOADED);

  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const promiseRef = useRef<null | { resolve: any; reject: any }>(null);

  const uploadFile = async (selectedFile: File): Promise<PerfImageFromFileFragment> => {
    return new Promise((resolve, reject) => {
      if (!selectedFile) return;

      const variables = {
        input: {
          name: selectedFile.name,
          type: selectedFile.type,
          size: selectedFile.size,
        },
      };

      setIsUploading(true);

      getSignedURLForImage({
        variables,
        fetchPolicy: 'no-cache',
        onError: (error) => {
          setIsUploading(false);
          console.error('Error getting signed URL:', error);
          toast({
            title: 'An error occurred while uploading the file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          reject(error);

          if (promiseRef.current) {
            promiseRef.current.reject(error);
            promiseRef.current = null;
          }
        },
        onCompleted: async (response) => {
          setIsUploading(false);

          const { signedUrl, file } = response.getSignedUrl;

          await onFileSign?.(file);

          try {
            await fetch(signedUrl, {
              method: 'PUT',
              body: selectedFile,
              headers: {
                'Content-Type': selectedFile.type,
              },
            });

            await markFileAsUploaded({
              variables: {
                fileId: file.id,
                width: selectedFile.width,
                height: selectedFile.height,
              },
              // fetchPolicy: 'no-cache',
              onCompleted: (res) => {
                const finalFile = res.markFileAsUploaded;
                toast({
                  status: 'success',
                  title: 'File uploaded successfully.',
                  duration: 3000,
                  isClosable: true,
                  position: 'top',
                });
                resolve(finalFile);
                if (promiseRef.current) {
                  promiseRef.current.resolve(file);
                  promiseRef.current = null;
                }
              },
            });
          } catch (error) {
            console.error('Error uploading file:', error);
            toast({
              title: 'An error occurred while uploading the file.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            reject(error);
            if (promiseRef.current) {
              promiseRef.current.reject(error);
              promiseRef.current = null;
            }
          }
        },
      });
    });
  };

  const [createFileFromLink] = useMutation(CREATE_FILE_FROM_LINK);

  const uploadFileFromLink = async (url: string): Promise<PerfImageFromFileFragment> => {
    return new Promise((resolve, reject) => {
      createFileFromLink({
        variables: {
          url,
        },
        onError: (error) => {
          console.error('Error getting signed URL:', error);
          toast({
            title: 'An error occurred while uploading the file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          reject(error);
        },
        onCompleted: (response) => {
          const file = response.createFileFromLink;
          if (file) {
            // toast({
            //   status: 'success',
            //   title: 'File uploaded successfully.',
            //   duration: 3000,
            //   isClosable: true,
            //   position: 'top',
            // });
            resolve(file);
            return;
          }
          toast({
            title: 'An error occurred while uploading the file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      });
    });
  };

  const onDrop = useCallback(async ([image]) => {
    if (image) {
      const file = await uploadFile(image);
      await onUpload?.(file);
      return file;
    }
    return null;
  }, []);

  const {
    open: openPicker,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    onError: () => {
      toast({
        title: 'An error occured...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const openPickerPromise = useCallback(() => {
    return new Promise<PerfImageFromFileFragment>((resolve, reject) => {
      promiseRef.current = { resolve, reject };
      openPicker();
    });
  }, [openPicker]);

  const nodes = (
    <Portal>
      <div {...getRootProps()} style={{ display: 'none' }}>
        <input {...getInputProps()} style={{ display: 'none' }} />
      </div>
    </Portal>
  );

  return {
    uploadFile,
    uploadFileFromBlob: onDrop,
    uploadFileFromLink,
    openPicker,
    openPickerPromise,
    nodes,
  };
}

const GET_SIGNED_URL = gql(/* GraphQL */ `
  mutation getSignedUrl($input: SignedURLInput!) {
    getSignedUrl(input: $input) {
      signedUrl
      file {
        id
        ...PerfImageFromFile
      }
    }
  }
`);

const MARK_AS_UPLOADED = gql(/* GraphQL */ `
  mutation markImageAsUploaded($fileId: String!, $width: Int, $height: Int) {
    markFileAsUploaded(fileId: $fileId, width: $width, height: $height) {
      id
      ...PerfImageFromFile
      url
      extension
      name
      contentType
      compressedKey
      isUploaded
      height
      width
    }
  }
`);

const CREATE_FILE_FROM_LINK = gql(/* GraphQL */ `
  mutation createFileFromLink($url: String!) {
    createFileFromLink(url: $url) {
      ...PerfImageFromFile
    }
  }
`);
