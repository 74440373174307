'use client';

import React, { useEffect } from 'react';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { Box } from '@chakra-ui/react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import {
  RecType,
  RecommendationItemFragment,
  RecommendationItemFragmentDoc,
} from '../../../../../../__generated__/graphql';
import { useFragment } from '@apollo/client';
import RecommendationItem from '../../../../by-type/recommendations/RecommendationItem';
import LexicalSelectionHandler from './LexicalSelectionHandler';
import { useDelete } from './hooks/useHelperHooks';
import { usePathname } from 'shared/misc/hooks/routerHooks';
import { useDocumentWrapperContext } from '../../../../../providers/DocumentWrapperContext';

type Props = {
  id: string;
  rec: RecommendationItemFragment;
  nodeKey: string;
  updateRec: (draft: RecommendationItemFragment) => void;
};

function LexicalRecommendationComponent({ rec, updateRec, nodeKey }: Props) {
  const isEditable = useLexicalEditable();
  const [editor] = useLexicalComposerContext();

  const { document } = useDocumentWrapperContext();

  const { data: recFragment, complete } = useFragment({
    fragment: RecommendationItemFragmentDoc,
    fragmentName: 'RecommendationItem',
    from: {
      __typename: 'Rec',
      id: rec.id,
    },
  });

  // console.log({ recFragment, rec });

  useEffect(() => {
    if (recFragment?.id && complete) {
      editor.update(() => {
        updateRec(recFragment);
      });
    }
  }, [recFragment, complete]);

  const finalRec = recFragment?.id && complete ? recFragment : rec;

  const primaryNode = <RecommendationItem rec={finalRec} noOfLines={500} />;

  useDelete(nodeKey);

  const pathname = usePathname();

  const content = isEditable ? (
    <LexicalSelectionHandler nodeKey={nodeKey}>{primaryNode}</LexicalSelectionHandler>
  ) : (
    primaryNode
  );

  const isSecretUrl = pathname.includes(`/p/${document?.id}`) || pathname.includes('draft');

  if (finalRec.type === RecType.UnpublishedPostRec && !isEditable && !isSecretUrl) {
    return null;
  }

  return (
    <Box width="100%" maxWidth="100%" alignSelf="center">
      {content}
    </Box>
  );
}

export default LexicalRecommendationComponent;
