import { createFileRoute } from '@tanstack/react-router';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { TWLink } from '../../components/link';
import { Button as TWButton } from '../../components/button';

export const Route = createFileRoute('/careers/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: `PI.FYI | CAREERS`,
      },
      {
        openGraph: {
          images: [
            'https://files.pi.fyi/cdn-cgi/image/quality=20,format=auto/screenshots-home-page.png',
          ],
        },
      },
    ],
  }),
});

function RouteComponent() {
  useAutoSetTheme(ProfileThemeType.PiClassic);

  const { trackEvent } = useAnalytics();

  const handleApplyClick = (position: string) => {
    trackEvent('Click', 'Apply_TWButton', { position });
  };

  const handleLearnMoreClick = () => {
    trackEvent('Click', 'Learn_More');
  };

  const handleTitleClick = () => {
    trackEvent('Click', 'Title');
  };

  return (
    <div>
      <div className="m-[10px] mt-[36px] md:mt-[64px] mb-[24px] md:mb-[100px] flex items-center w-full flex flex-col">
        <span className="typography-heading-xl text-center px-[24px]">
          <TWLink
            href="https://www.instagram.com/perfectlyimperfect/"
            target="_blank"
            onClick={handleTitleClick}
          >
            we're
          </TWLink>{' '}
          hiring!
        </span>
        <div className="p-6 flex justify-center">
          <div className="space-y-6 w-full max-w-[400px] lg:max-w-[900px]">
            <div className="space-y-6 w-full max-w-[500px] lg:max-w-[700px] self-center">
              <div className="space-y-6 mt-3 md:mt-6">
                <div className="space-y-6 py-12 px-12 border-dashed border-brand-main border-y-[1px] flex flex-col items-center">
                  <TWLink to="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form">
                    <p className="typography-heading-xl text-center text-brand-highlight py-[12px]">
                      Sales
                    </p>
                  </TWLink>
                  <span className="text-xl uppercase text-center font-normal">
                    {'>'} Help us work with brands on our newsletter (not the social site!) and make
                    money to keep this thing alive.
                  </span>
                  <TWButton
                    variant="primary"
                    href="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form"
                    textAlign="center"
                    alignSelf="center"
                    mt="16px"
                    onClick={() => handleApplyClick('Software Engineer')}
                  >
                    APPLY
                  </TWButton>
                </div>
                <div className="space-y-6 py-12 px-12 border-dashed border-brand-main border-b-[1px] flex flex-col items-center">
                  <TWLink to="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form">
                    <p className="typography-heading-xl text-center text-brand-highlight py-[12px]">
                      Writer
                    </p>
                  </TWLink>
                  <span className="text-xl uppercase text-center font-normal">
                    {'>'} Culture. Music. film. commerce. food. fashion. tech. nightlife. etc.
                  </span>
                  <TWButton
                    variant="primary"
                    href="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form"
                    textAlign="center"
                    alignSelf="center"
                    mt="16px"
                    onClick={() => handleApplyClick('Writer')}
                  >
                    APPLY
                  </TWButton>
                </div>
                <div className="space-y-6 py-12 px-12 border-dashed border-brand-main border-b-[1px] flex flex-col items-center">
                  <TWLink to="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form">
                    <p className="typography-heading-xl text-center text-brand-highlight py-[12px]">
                      Booking
                    </p>
                  </TWLink>
                  <span className="text-xl uppercase text-center font-normal">
                    {'>'} Help us bring in high quality talent.
                  </span>
                  <TWButton
                    variant="primary"
                    href="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form"
                    textAlign="center"
                    alignSelf="center"
                    mt="16px"
                    onClick={() => handleApplyClick('General')}
                  >
                    APPLY
                  </TWButton>
                </div>
                <div className="space-y-6 py-12 px-12 border-dashed border-brand-main border-b-[1px] flex flex-col items-center">
                  <TWLink to="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form">
                    <p className="typography-heading-xl text-center text-brand-highlight  py-[12px]">
                      General
                    </p>
                  </TWLink>
                  <span className="text-xl uppercase text-center font-normal">
                    {'>'} Tell us what you're good at.
                  </span>
                  <TWButton
                    variant="primary"
                    href="https://airtable.com/appucmSlNybK0JH5N/pagWflIpRq6uKO3Zk/form"
                    textAlign="center"
                    alignSelf="center"
                    mt="16px"
                    onClick={() => handleApplyClick('General')}
                  >
                    APPLY
                  </TWButton>
                </div>
                <div className="flex flex-col gap-[6px]">
                  <span className="typograpy-action-sm  mt-3 md:mt-6 leading-130 text-center uppercase">
                    We're building a new blend of editorial and social.
                  </span>
                  <span className="typograpy-action-sm  mt-3 md:mt-6 leading-130 text-center uppercase">
                    A place to discover from real people, <em>not</em> algorithms.
                  </span>
                  <span className="typograpy-action-sm  mt-3 md:mt-6 leading-130 text-center uppercase">
                    Learn more about us{' '}
                    <TWLink
                      to="/what-is-this"
                      target="_blank"
                      variant="stylized"
                      onClick={handleLearnMoreClick}
                    >
                      here.
                    </TWLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
