import { useState, useCallback, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone-esm';
import ReactCrop, { type Crop, type PixelCrop } from 'react-image-crop';
import { gql } from 'shared/__generated__/gql';
import {
  centerAspectCrop,
  getScaledCropFromImg,
  processInitialFileUpload,
} from '../components/by-type/files/utils/blobUtils';
import { useAuth } from './useAuth';
import reactCropCss from 'react-image-crop/dist/ReactCrop.css?inline';

export function CssInjector() {
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  if (!isMounted) {
    return null;
  }
  
  return (
    <style
      key="react-crop-css"
      dangerouslySetInnerHTML={{
        __html: reactCropCss,
      }}
    />
  );
}

const INITIAL_CROP: Crop = {
  unit: '%',
  width: 50,
  height: 50,
  x: 25,
  y: 25,
};

export const useUploadProfilePhotos = (userId_ADMIN?: string) => {
  const { user } = useAuth();
  const userId = userId_ADMIN || user?.id;
  const [state, setState] = useState({
    isUploading: false,
    isCropping: false,
    isError: false,
    isDone: false,
    progress: 0,
  });

  const [tempFile, setTempFile] = useState<File | null>(null);
  const [tempFileUrl, setTempFileUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>(INITIAL_CROP);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const completedCropRef = useRef<PixelCrop | null>(null);

  const [getSignedUrlsForUserPhotos] = useMutation(
    userId_ADMIN ? GET_SIGNED_URLS_ADMIN : GET_SIGNED_URLS,
  );

  const [markUserPhotosAsUploadedAndCrop] = useMutation(MARK_AS_UPLOADED_AND_CROP);

  const reset = useCallback(() => {
    setState({ isUploading: false, isCropping: false, isError: false, isDone: false, progress: 0 });
    setTempFile(null);
    setTempFileUrl(null);
    setCrop(INITIAL_CROP);
    setCompletedCrop(null);
  }, []);

  const onDrop = useCallback(async ([file]: File[]) => {
    if (!file) return;

    const processedFile = await processInitialFileUpload(file);
    const url = URL.createObjectURL(processedFile);
    setTempFile(processedFile);
    setTempFileUrl(url);
    setState((prev) => ({ ...prev, isCropping: true }));
  }, []);

  const uploadPhotos = useCallback(async () => {
    if (!tempFile || !completedCropRef.current || !imgRef.current) return;

    const actualCrop = getScaledCropFromImg(imgRef.current, completedCropRef.current);
    const variables = {
      profilePhotoInput: {
        name: tempFile.name,
        type: tempFile.type,
        size: tempFile.size,
      },
      avatarPhotoInput: {
        name: tempFile.name,
        type: tempFile.type,
        size: tempFile.size,
      },
      userId,
    };

    setState((prev) => ({ ...prev, isUploading: true }));

    try {
      const { data } = await getSignedUrlsForUserPhotos({ variables });

      if (!data || !data.getSignedUrlsForUserPhotos) {
        throw new Error('Failed to get signed URLs');
      }
      const { profilePhotoFileSignedUrl, avatarPhotoFileSignedUrl } =
        data.getSignedUrlsForUserPhotos;

      await Promise.all([
        fetch(profilePhotoFileSignedUrl, { method: 'PUT', body: tempFile }),
        fetch(avatarPhotoFileSignedUrl, { method: 'PUT', body: tempFile }),
      ]);

      await markUserPhotosAsUploadedAndCrop({
        variables: {
          profilePhotoFileId: data.getSignedUrlsForUserPhotos.profilePhotoFile.id,
          avatarPhotoFileId: data.getSignedUrlsForUserPhotos.avatarPhotoFile.id,
          crop: actualCrop,
        },
      });

      setState({
        isUploading: false,
        isCropping: false,
        isError: false,
        isDone: true,
        progress: 100,
      });
    } catch (error) {
      console.error(error);
      setState((prev) => ({ ...prev, isError: true, isUploading: false }));
    }
  }, [tempFile, crop, userId_ADMIN, user]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10 MB
    multiple: false,
    accept: { 'image/*': ['.jpeg', '.png', '.jpg', '.webp'] },
  });

  let cropComponent;

  if (tempFileUrl) {
    cropComponent = (
      <ReactCrop
        crop={crop}
        onChange={(_c, p) => setCrop(p)}
        onComplete={(c, _p) => {
          completedCropRef.current = c;
        }}
        aspect={1}
        ruleOfThirds
        overflow="hidden"
        style={{ width: '100%', height: '100%' }}
      >
        <img
          ref={imgRef}
          src={tempFileUrl}
          alt="Crop preview"
          onLoad={(e) => {
            const { width, height } = e.currentTarget;
            const initialCrop = centerAspectCrop(width, height, 1);
            const initialCropPx = centerAspectCrop(width, height, 1, 'px');
            completedCropRef.current = initialCropPx as PixelCrop;

            setCrop(initialCrop);
          }}
        />
      </ReactCrop>
    );
  }

  return {
    state,
    reset,
    uploadPhotos,
    setCompletedCrop,
    crop,
    cropComponent,
    dropzoneProps: { getRootProps, getInputProps, isDragActive },
  };
};

const GET_SIGNED_URLS = gql(/* GraphQL */ `
  mutation getSignedUrlsForUserPhotos(
    $profilePhotoInput: SignedURLInput!
    $avatarPhotoInput: SignedURLInput!
  ) {
    getSignedUrlsForUserPhotos(
      profilePhotoInput: $profilePhotoInput
      avatarPhotoInput: $avatarPhotoInput
    ) {
      profilePhotoFileSignedUrl
      profilePhotoFile {
        id
        url
        extension
        name
        contentType
      }
      avatarPhotoFileSignedUrl
      avatarPhotoFile {
        id
        url
        extension
        name
        contentType
      }
    }
  }
`);

const MARK_AS_UPLOADED_AND_CROP = gql(/* GraphQL */ `
  mutation markProfilePhotoAsUploadedAndCropAvatar(
    $profilePhotoFileId: String!
    $avatarPhotoFileId: String!
    $crop: CropInput!
  ) {
    markProfilePhotoAsUploadedAndCropAvatar(
      profilePhotoFileId: $profilePhotoFileId
      avatarPhotoFileId: $avatarPhotoFileId
      crop: $crop
    ) {
      id
      profilePhotoSrc
      profilePhotoFile {
        id
        url
        extension
        name
        contentType
      }
      avatarPhotoSrc
      avatarPhotoFile {
        id
        url
        extension
        name
        contentType
      }
    }
  }
`);

const GET_SIGNED_URLS_ADMIN = gql(/* GraphQL */ `
  mutation getSignedUrlsForUserPhotos_ADMIN(
    $userId: String!
    $profilePhotoInput: SignedURLInput!
    $avatarPhotoInput: SignedURLInput!
  ) {
    getSignedUrlsForUserPhotos_ADMIN(
      userId: $userId
      profilePhotoInput: $profilePhotoInput
      avatarPhotoInput: $avatarPhotoInput
    ) {
      profilePhotoFileSignedUrl
      profilePhotoFile {
        id
        url
        extension
        name
        contentType
      }
      avatarPhotoFileSignedUrl
      avatarPhotoFile {
        id
        url
        extension
        name
        contentType
      }
    }
  }
`);

const MARK_AS_UPLOADED_ADMIN = gql(/* GraphQL */ `
  mutation markUserPhotosAsUploaded_ADMIN(
    $userId: String!
    $profilePhotoFileId: String!
    $avatarPhotoFileId: String!
  ) {
    markUserPhotosAsUploaded_ADMIN(
      userId: $userId
      profilePhotoFileId: $profilePhotoFileId
      avatarPhotoFileId: $avatarPhotoFileId
    ) {
      id
      profilePhotoSrc
      profilePhotoFile {
        id
        url
        extension
        name
        contentType
      }
      avatarPhotoSrc
      avatarPhotoFile {
        id
        url
        extension
        name
        contentType
      }
    }
  }
`);