import React, { useContext } from 'react';
import { RecommendationItemFragment } from '../../../../../__generated__/graphql';

type ContextProps = {
  rec: RecommendationItemFragment;
};

export const RecommendationContext = React.createContext<ContextProps>({} as ContextProps);

export const useRecommendationContext = () => {
  return useContext(RecommendationContext);
};
