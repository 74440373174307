import _ from 'lodash';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { GetFeedItemData, FeedItemDisplayData, getFeedItemData } from './get-feed-item-data';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';

export function useFeedItemData(args: GetFeedItemData): FeedItemDisplayData | null {
  const auth = useAuth<true>();

  const themeColors = useThemeColors();

  const originUserFallbackLogoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

  return getFeedItemData({
    ...args,
    originUserFallbackLogoColor,
    referenceUserId: auth.user?.id,
  });
}
