// eslint-disable-next-line import/named
import { ApolloLink, HttpLink, NormalizedCacheObject, split } from '@apollo/client';

import { ApolloClient, InMemoryCache } from '@apollo/client-integration-tanstack-start';

import { setContext } from '@apollo/link-context';
import { RetryLink } from '@apollo/client/link/retry';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import {
  getMainDefinition,
  relayStylePagination,
  removeArgumentsFromDocument,
} from '@apollo/client/utilities';
import localForage from 'localforage';
import { persistCache, LocalForageWrapper } from 'apollo3-cache-persist';
import { GRAPHQL_SERVER_URL } from '../../siteconfig';
import { COOKIE_NAME, ironOptions } from '../iron';
import { isInWebView, safeSendMessageToReactNativeFromWebView } from '../../webview/index';
import { CacheSyncMessages } from '../../webview/messages';
import { typePolicies } from './typePolicies';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getAuthCookie } from '../../../../apps/web2/app/utils/cookies';
import { SSRMultipartLink } from '@apollo/client-react-streaming';

if (process.env['NODE_ENV'] !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export let APOLLO_CLIENT: undefined | ApolloClient<NormalizedCacheObject>;
export let SERVER_APOLLO_CLIENT: undefined | ApolloClient<NormalizedCacheObject>;

function getQueryParam(param) {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }
  return null;
}

const sharedHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
  Accept: '*/*',
  'Access-Control-Allow-Credentials': 'true',
};

const getClientHeaders = (): Record<string, string> => {
  const key = getQueryParam('key');
  if (key) return { ...sharedHeaders, Authorization: `Key ${key}` };
  const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
  return token ? { ...sharedHeaders, Authorization: `JWT ${token}` } : {};
};

const getServerHeaders = () => {
  const sessionCookie = getAuthCookie();
  return sessionCookie ? { [ironOptions.cookieName]: sessionCookie } : {};
};

let websocketImpl = globalThis.WebSocket;
if (!websocketImpl) {
  websocketImpl = (await import('ws')).default;
}

export const createOrGetExistingClient = (): ApolloClient<NormalizedCacheObject> => {
  const shouldUseServer = typeof window === 'undefined';

  // if (APOLLO_CLIENT) {
  //   return APOLLO_CLIENT;
  // }

  const uri = GRAPHQL_SERVER_URL;

  const httpLink = new HttpLink({
    uri,
    credentials: 'include',
    fetchOptions: {
      credentials: 'include',
      mode: 'cors',
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 15,
      retryIf: (error, operation) => {
        if (error?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED') return false;
        return !!error;
      },
    },
  });

  const authCtx = setContext((_, { headers }) => {
    const updatedHeaders = {
      ...sharedHeaders,
      ...headers,
      ...(typeof window === 'undefined' ? getServerHeaders() : {}),
      ...(typeof window !== 'undefined' ? getClientHeaders() : {}),
    };
    return {
      headers: updatedHeaders,
    };
  });

  const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;

  const wsLink = new GraphQLWsLink(
    createClient({
      webSocketImpl: websocketImpl,
      url:
        process.env.NODE_ENV === 'development'
          ? 'ws://localhost:9000/api/graphql'
          : 'wss://ws.pi.fyi/api/graphql',
      connectionParams: {
        authentication: token,
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const allLinks = ApolloLink.from([authCtx, retryLink, splitLink]);

  const cache = new InMemoryCache({
    typePolicies: typePolicies,
  });

  if (typeof window !== 'undefined') {
    // persistCache({
    //   cache,
    //   storage: new LocalForageWrapper(localForage),
    // });
  }

  const link = !shouldUseServer
    ? ApolloLink.from([
        allLinks,
        new SSRMultipartLink({
          stripDefer: true,
        }),
      ])
    : allLinks;

  const client =
    typeof window !== 'undefined'
      ? new NextApolloClient({
          cache,
          link,
          credentials: 'include',
        })
      : new ApolloClient({
          cache,
          link,
          credentials: 'include',
        });

  // sneakySneaky(cache, client);

  APOLLO_CLIENT = client;

  // if (!shouldUseServer) {
  //   APOLLO_CLIENT = client;
  // } else {
  //   SERVER_APOLLO_CLIENT = client;
  // }

  return client;
};

export async function persistCacheAsync(cache) {
  return persistCache({
    cache,
    storage: new LocalForageWrapper(localForage),
  });
}

export function sneakySneaky(cache, client) {
  const write = cache.write;
  const evict = cache.evict;
  const modify = cache.modify;

  if (isInWebView()) {
    const mutate = client.mutate;

    client.mutate = (...args: any[]) => {
      console.log('cache event!', 'mutate');

      const result = mutate.apply(client, args);
      if (args?.[0]?.refetchQueries) {
        try {
          safeSendMessageToReactNativeFromWebView(CacheSyncMessages.REFETCH_QUERIES, {
            origin: 'MOBILE',
            refetchQueries: args?.[0]?.refetchQueries,
          });
        } catch (e) {
          console.log('Failed trying to sync refetch queries.');
          console.log(e);
        }
      }
      client._mutate = mutate;
      return result;
    };

    if (!cache._write) {
      cache._write = write.bind(cache);
      cache.write = (...args: any[]) => {
        console.log('cache event!', 'write');

        const result = write.apply(cache, args);
        try {
          const data = args[0];
          if (data?.dataId !== 'ROOT_QUERY') {
            data.IS_CACHE_SYNC = true;
            safeSendMessageToReactNativeFromWebView(CacheSyncMessages.WRITE, {
              origin: 'WEB',
              args,
            });
          }
        } catch (e) {}
        return result;
      };
    }

    if (!cache._evict) {
      cache._evict = evict.bind(cache);
      cache.evict = (...args: any[]) => {
        console.log('cache event!', 'evict');

        const result = evict.apply(cache, args);
        try {
          const data = args[0];
          if (data?.dataId !== 'ROOT_QUERY') {
            data.IS_CACHE_SYNC = true;
            safeSendMessageToReactNativeFromWebView(CacheSyncMessages.EVICT, {
              origin: 'WEB',
              args,
            });
          }
        } catch (e) {}
        return result;
      };
    }

    if (!cache._modify) {
      cache._modify = modify.bind(cache);
      cache.modify = (...args: any[]) => {
        console.log('cache event!', 'modify');

        const result = modify.apply(cache, args);
        try {
          const data = args[0];
          if (data?.dataId !== 'ROOT_QUERY') {
            data.IS_CACHE_SYNC = true;
            safeSendMessageToReactNativeFromWebView(CacheSyncMessages.MODIFY, {
              origin: 'WEB',
              args,
            });
          }
        } catch (e) {}
        return result;
      };
    }
  }
}
