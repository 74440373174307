// app/router.tsx
import { createRouter as createTanStackRouter } from '@tanstack/react-router';

import { routerWithApolloClient } from '@apollo/client-integration-tanstack-start';

import { routeTree } from './routeTree.gen';
import { MakeRouteMatchUnion } from '@tanstack/router-core';
import { getClient } from './utils/apolloClient';
type TypeSafetyRulez = Partial<MakeRouteMatchUnion>;

const specialRoutes: Array<TypeSafetyRulez> = [
  { routeId: '/home/$tab/', params: { tab: 'everyone' } },
  { routeId: '/u/$username/' },
  { routeId: '/search/' },
];

export function createRouter() {
  const apolloClient = getClient();

  const isSSR = typeof window === 'undefined';

  const router = createTanStackRouter({
    defaultPreload: 'intent',
    routeTree,
    scrollRestoration: true,
    scrollToTopSelectors: ['#body-container', 'body'],
    getScrollRestorationKey: (params) => {
      const isSpecialRoute = checkIsSpecialRoute();
      if (isSpecialRoute && isSSR) {
        router.isScrollRestoring = false;
      }
      return params.state.key!;
    },
    // the context properties `apolloClient` and `preloadQuery`
    // will be filled in by calling `routerWithApolloClient` later
    // you should omit them here, which means you have to
    // `as any` this context object
    context: {} as any,
    hydrate: () => {
      const isSpecialRoute = checkIsSpecialRoute();
      if (isSpecialRoute) {
        router.resetNextScroll = false;
      }
    },
  });

  function checkIsSpecialRoute() {
    return router.state.matches.some((match) =>
      specialRoutes.some((route) => {
        const paramEntries = Object.entries(route.params ?? {});
        return (
          match.routeId === route.routeId &&
          paramEntries.every(([key, value]) => match.params?.[key] === value)
        );
      }),
    );
  }

  return routerWithApolloClient(router, apolloClient);
}

declare module '@tanstack/react-router' {
  interface Register {
    router: ReturnType<typeof createRouter>;
  }
}
