

import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { gql } from 'shared/__generated__/gql';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up/invite-code/')({
  component: InviteCode,
});

function InviteCode() {
  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.ADD_RECS],
  });

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '50px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p="48px"
        >
          <Stack spacing="24px" w="100%" maxW="300px">
            <PerfImage
              src="https://files.pi.fyi/blue-star.png"
              alt="star-blue"
              width="50"
              height="50"
            />
            <Text textStyle="brand.headingSm">ENTER INVITE CODE</Text>
            <HStack spacing={3}>
              <Button
                onClick={async () => {
                  await updateStep(null);
                }}
              >
                Dive in!
              </Button>
              <Button
                variant="secondary"
                onClick={async () => {
                  await updateStep(USER_SIGN_UP_STEP.ADD_RECS);
                }}
              >
                Go back
              </Button>
            </HStack>
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}

gql(/* GraphQL */ `
  query syncSubstackStatus {
    syncSubstackStatus {
      id
      isPremium
      substackStatus
    }
  }
`);
