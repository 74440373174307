import { Box, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { HoverCard, HoverCardTrigger, HoverCardContent } from '../../ui/hover-card';
import { UserCoreFragment, UserCoreFragmentDoc } from 'shared/__generated__/graphql';
import PerfImageFromFile from 'shared/misc/components/util/PerfImageFromFile';
import { useFragment, useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__';
import UserDirectoryItem from './user-directory-item';
import { motion } from 'framer-motion';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { TWLink } from '../../link';
import { useEffect, useState } from 'react';

export function UserAvatar({
  user,
  avatarSize = 24,
  withPopover = true,
  wrapWithLink = true,
  withActiveIndicator = false,
}: {
  user?: UserCoreFragment | null;
  avatarSize?: number;
  withPopover?: boolean;
  wrapWithLink?: boolean;
  withActiveIndicator?: boolean;
}) {
  const themeColors = useThemeColors();
  const [isMounted, setIsMounted] = useState(false);

  const logoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

  let imageNode = user?.avatarPhotoFile ? (
    <PerfImageFromFile
      file={user.avatarPhotoFile}
      useFallbackAvatarSrc
      alt="image"
      quality={15}
      width={avatarSize}
      height={avatarSize}
      style={{
        width: `${avatarSize}px`,
        height: `${avatarSize}px`,
        minWidth: `${avatarSize}px`,
        minHeight: `${avatarSize}px`,
      }}
    />
  ) : (
    <PerfImageFromFile
      src={`https://files.pi.fyi/default-profile-photo-${logoColor || 'blue'}-small.png`}
      width={avatarSize}
      height={avatarSize}
      alt="image"
      quality={15}
      style={{
        width: `${avatarSize}px`,
        height: `${avatarSize}px`,
        minWidth: `${avatarSize}px`,
        minHeight: `${avatarSize}px`,
      }}
    />
  );

  if (withActiveIndicator && user?.isOnline) {
    const activeIndicatorSize = Math.min(20, Math.floor(avatarSize / 2.5));
    let activeIndicatorBorderWidth;
    let activeIndicatorOffset;
    switch (true) {
      case activeIndicatorSize <= 10:
        activeIndicatorBorderWidth = 2;
        activeIndicatorOffset = -4;
        break;
      case activeIndicatorSize <= 15:
        activeIndicatorBorderWidth = 3;
        activeIndicatorOffset = -5;
        break;
      default:
        activeIndicatorBorderWidth = 4;
        activeIndicatorOffset = -6;
        break;
    }

    const haloSize = activeIndicatorSize * 1.15;

    imageNode = (
      <div className="relative cursor-pointer">
        {imageNode}
        <div
          className={`absolute rounded-full bg-brand-highlight border-${activeIndicatorBorderWidth} border-brand-background z-10`}
          style={{
            width: `${activeIndicatorSize}px`,
            height: `${activeIndicatorSize}px`,
            top: `${activeIndicatorOffset}px`,
            right: `${activeIndicatorOffset}px`,
          }}
        />
        <motion.div
          className="absolute rounded-full"
          style={{
            width: `${haloSize}px`,
            height: `${haloSize}px`,
            top: `${activeIndicatorOffset - (haloSize - activeIndicatorSize) / 2}px`,
            right: `${activeIndicatorOffset - (haloSize - activeIndicatorSize) / 2}px`,
            backgroundColor: themeColors.highlight,
            opacity: 0.5,
            zIndex: 9,
          }}
          initial={{ scale: 1, opacity: 0 }}
          animate={{
            scale: [1, 1.8, 1],
            opacity: [0.5, 0, 0],
          }}
          transition={{
            duration: 2,
            times: [0, 0.7, 1],
            repeat: Infinity,
            ease: 'easeOut',
          }}
        />
      </div>
    );
  }

  if (wrapWithLink && user) {
    imageNode = (
      <TWLink to="/u/$username" params={{ username: user.username }}>
        {imageNode}
      </TWLink>
    );
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (withPopover && user) {
    return (
      <HoverCard>
        <HoverCardTrigger>{imageNode}</HoverCardTrigger>
        {isMounted && (
          <HoverCardContent className="w-[300px] sm:w-[400px] p-0">
            <LazyUserItem id={user.id} />
          </HoverCardContent>
        )}
      </HoverCard>
    );
  }

  return imageNode;
}

const LazyUserItemQuery = gql(/* GraphQL */ `
  query LazyUserItemQuery($id: String!) {
    user(id: $id) {
      ...UserDirectoryItem
    }
  }
`);

function LazyUserItem({ id }: { id: string }) {
  const { complete, data: userCoreFragment } = useFragment({
    fragment: UserCoreFragmentDoc,
    fragmentName: 'UserCore',
    from: {
      __typename: 'User',
      id,
    },
  });

  const { data, loading } = useQuery(LazyUserItemQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const user = !data && complete ? userCoreFragment : data?.user;

  if (loading) {
    const commonProps = {
      fadeDuration: 5,
      borderRadius: 0,
    };
    return (
      <HStack spacing="17px" p="8px">
        <Skeleton {...commonProps} height="75px" width="75px" />
        <Stack width="fit-content" height="100% ">
          <Skeleton {...commonProps} height="15px" width="200px" mb="3px" />
          <Skeleton {...commonProps} height="15px" width="100px" />
        </Stack>
      </HStack>
    );
  }

  return (
    <Box>
      {user ? (
        <UserDirectoryItem withActiveIndicator user={user} withBorder={false} showMutuals />
      ) : null}
    </Box>
  );
}
