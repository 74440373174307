import React, { useCallback } from 'react';
import { Flex, IconButton, Portal, useDisclosure, useToast } from '@chakra-ui/react';
import {
  DropdownMenu as Menu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuItem as MenuItem,
  DropdownMenuTrigger,
} from '../../../ui/dropdown-menu';
import { useMutation } from '@apollo/client';
import { RiMoreFill } from 'react-icons/ri';
import {
  DELETE_PROMPT_MUTATION,
  MUTE_PROMPT_MUTATION,
  UNMUTE_PROMPT_MUTATION,
} from 'shared/misc/graphql/PromptMutations';
import { copyToClipboard } from 'shared/misc/clipboard';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { useListContext } from 'shared/misc/providers/ListContext';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import UpdatePromptModal from '../../../modals/update-prompt-modal';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { PromptItemFragment, PromptType } from 'shared/__generated__/graphql';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';

type Props = {
  withLessPadding?: boolean;
  withBorder?: boolean;
  isInline?: boolean;
  prompt: PromptItemFragment;
};

function PromptActions({ prompt, withLessPadding, isInline, withBorder }: Props) {
  const { user } = useAuth();
  const { list } = useListContext();
  const { trackEvent } = useAnalytics();
  const toast = useToast();

  const onClickCopyLink = useCallback(() => {
    copyToClipboard(`${BASE_SITE_URL}/ask/${prompt.id}`);
    trackEvent('Click', 'Copy_Ask_Link', {
      askId: prompt.id,
      endorsementCount: prompt.endorsementCount,
      isEndorsed: prompt.isEndorsed,
      recsCount: prompt.recsCount,
      link: `${BASE_SITE_URL}/ask/${prompt.id}`,
    });
    toast({
      title: 'Copied link to ask.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, []);

  const [deletePrompt] = useMutationWithAutoToast(DELETE_PROMPT_MUTATION);

  const onDeletePrompt = useCallback(async () => {
    await deletePrompt({
      variables: { promptId: prompt.id },
      refetchQueries: ['getListView', 'getProfileLowerRecommendations', 'getHomeViewContainer'],
      optimisticResponse: {
        deletePrompt: true,
      },
      update(cache) {
        try {
          const normalizedId = cache.identify({ id: prompt.id, __typename: 'Prompt' });
          cache.evict({ id: normalizedId });
          cache.gc();
        } catch (e) {}
      },
    });
  }, [deletePrompt, list, prompt]);

  const [onMute] = useMutation(MUTE_PROMPT_MUTATION, {
    variables: { promptId: prompt.id },
    optimisticResponse: {
      mutePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        muted: true,
      },
    },
  });

  const [onUnmute] = useMutation(UNMUTE_PROMPT_MUTATION, {
    variables: { promptId: prompt.id },
    optimisticResponse: {
      unmutePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        muted: false,
      },
    },
  });

  const padding = isInline ? '12px' : '24px';

  return (
    <div
      className={`absolute top-0 right-0 ${
        withBorder ? (isInline ? 'p-[12px]' : 'p-[24px]') : 'p-[12px]'
      } ${
        withLessPadding
          ? isInline
            ? 'md:p-[12px]'
            : 'md:p-[24px]'
          : isInline
          ? 'md:p-[12px]'
          : 'md:p-[24px]'
      }`}
    >
      <Menu>
        <DropdownMenuTrigger>
          <RiMoreFill />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {user?.id === prompt.user?.id ||
            (prompt.type === PromptType.UnpublishedPostPrompt && user?.role === 'ADMIN' && (
              <PromptEdit prompt={prompt} />
            ))}
          <MenuItem onSelect={onClickCopyLink}>Copy Link</MenuItem>
          {user?.id === prompt.user?.id && (
            <MenuItem
              onSelect={(e) => {
                e.preventDefault();
                trackEvent('Click', 'Delete_Ask_Dialog', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
                const confirmed = confirm('Delete ask?');
                if (confirmed) onDeletePrompt();
                trackEvent('Click', 'Delete_Ask', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
              }}
            >
              Delete
            </MenuItem>
          )}
          {user && (
            <MenuItem
              onSelect={(e) => {
                e.preventDefault();
                trackEvent('Click', prompt.muted ? 'Unmute_Ask' : 'Mute_Ask', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
                !!prompt.muted ? onUnmute() : onMute();
              }}
            >
              {prompt.muted ? 'Unmute Notifications' : 'Mute Notifications'}
            </MenuItem>
          )}
        </DropdownMenuContent>
      </Menu>
    </div>
  );
}

function PromptEdit({ prompt }: Props) {
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();

  const toggleModal = useCallback(() => {
    const beforeModalState = modalState.isOpen;
    modalState.onToggle();
    trackEvent('Click', `${!beforeModalState ? 'Open' : 'Close'}_Edit_Ask_Modal`, {
      askId: prompt.id,
      endorsementCount: prompt.endorsementCount,
      isEndorsed: prompt.isEndorsed,
      recsCount: prompt.recsCount,
    });
  }, [modalState]);

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={toggleModal}>
        Edit ask
      </MenuItem>
      <Portal>
        {modalState.isOpen && <UpdatePromptModal modalState={modalState} prompt={prompt} />}
      </Portal>
    </>
  );
}

export default PromptActions;
