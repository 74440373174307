import { createFileRoute } from '@tanstack/react-router';
import { TWLink, BaseTWLink } from '../../components/link';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import ThemeAwareStar from 'shared/misc/components/ThemeAwareStar';

export const Route = createFileRoute('/what-is-this/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: `PI.FYI | WHAT IS THIS?`,
      },
      {
        openGraph: {
          images: [
            'https://files.pi.fyi/cdn-cgi/image/quality=20,format=auto/pi-image-for-social.png',
          ],
        },
      },
    ],
  }),
});
function RouteComponent() {
  useAutoSetTheme(ProfileThemeType.PiClassic);
  return (
    <>
      <div
        style={{ backgroundImage: "url('https://files.pi.fyi/star-background.png')" }}
        className="flex justify-center items-center min-h-screen overflow-y-auto"
      >
        <div className="m-2 md:mt-[48px] mb-6 md:mb-24 border border-dashed border-main p-6 md:p-12 ">
          <div className="flex flex-col gap-[12px] w-full lg:max-w-2xl sm:max-w-md">
            <div className="flex flex-col gap-[6px] w-full mx-auto">
              <div className="py-6">
                <ThemeAwareStar />
              </div>
              <span className="typography-heading-lg">What is Perfectly Imperfect?</span>
              <div className="flex flex-col gap-[18px] mt-[12px]">
                <span className="typography-body-lg font-normal">
                  PI is a <strong>social discovery platform</strong> that's built for people (like
                  you!) to share what they love and discover new things from real people, not
                  algorithms.
                </span>
                <span className="typography-body-lg font-normal">
                  We also have <strong>a popular newsletter</strong> that features recommendations
                  from people like...
                </span>
                <span className="typography-heading-sm text-brand-highlight">
                  Olivia Rodrigo, Francis Ford Coppola, Charli XCX, Ayo Edebiri, Michael Imperioli,
                  Emma Chamberlain, Clairo, Molly Ringwald, Weyes Blood, Jeremy O. Harris, Conner
                  O'Malley, Rayne Fisher Quann, Julian Casablancas, Alexa Chung, Mac Demarco, Moses
                  Sumney, Omar Apollo, Suki Waterhouse, Narwuar, and The Dare.
                </span>
                <span className="typography-body-lg">
                  You can find the full list in{' '}
                  <TWLink to="/read" className="text-brand-highlight italic leading-6 underline">
                    the Perfectly Imperfect Archive.
                  </TWLink>
                </span>
              </div>

              <div className="flex flex-col gap-[18px] mt-[12px]">
                <div className="typography-body-lg font-normal">
                  <span className="typography-heading-sm">You can...</span>
                  share and ask for recs, organize for later, follow your friends, browse by
                  category, be featured on the newsletter, and most importantly, stumble upon
                  something new.
                </div>
                <div className="typography-body-lg font-normal">
                  <span className="typography-heading-sm">A PASSION PROJECT...</span> don't worry,
                  this isn't some weird tech industry bullshit. I've always wanted a site like this,
                  so when I got laid off last summer I set out to make it real.
                </div>
                <div className="typography-body-lg font-normal">
                  <span className="typography-heading-sm">IN COLLABORATION WITH...</span> we worked
                  with the design studio{' '}
                  <BaseTWLink
                    href="https://www.specialoffer.inc/"
                    target="_blank"
                    className="underline"
                  >
                    Special Offer
                  </BaseTWLink>{' '}
                  to come up with a visual style that felt refreshing and true to us.
                </div>
                <div className="typography-body-lg font-normal">
                  <span className="typography-heading-sm">FOR PRESS INQUIRIES...</span> please email{' '}
                  <BaseTWLink
                    href="mailto:admin@perfectlyimperfect.fyi"
                    target="_blank"
                    className="underline"
                  >
                    Tyler
                  </BaseTWLink>
                  .
                </div>
                <span className="typography-body-lg font-normal">
                  <span className="typography-heading-sm">There's an app...</span> and it's better
                  than the website in almost every way. If that sounds cool,{' '}
                  <BaseTWLink
                    target="_blank"
                    href="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926"
                    className="underline"
                  >
                    click here to download.
                  </BaseTWLink>
                </span>
              </div>
              <div className="mt-3">
                <img
                  src="https://files.pi.fyi/pi-image-for-social.png"
                  alt="app screenshots"
                  width={500}
                  height={500}
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
