import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/welcome/$code/')({
  component: RouteComponent,
  loader: async ({ params }) => {
    return redirect({ to: '/subscribe/referral/' + params.code });
  },
});

function RouteComponent() {
  return <div>Loading...</div>
}
