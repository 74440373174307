import { ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig, defineStyle } from '@chakra-ui/styled-system';

export const Textarea: ComponentStyleConfig = defineStyleConfig({
  // style object for base or default style
  baseStyle: {},
  variants: {
    default: defineStyle({
      color: 'brand.black',
      width: '100%',
      resize: 'none',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      border: '1px solid transparent',
      borderColor: 'transparent',
      textStyle: 'brand.actionLg',
      paddingLeft: '0px',
      _focus: {
        paddingBottom: '-1px',
        // borderColor: 'brand.highlight',
        // border: '1px solid transparent',
        // borderBottom: '1px solid',
        // borderBottomColor: 'brand.highlight',
        boxShadow: 'none',
      },

      fontFamily: 'brand.arialNarrowBold',
      fontSize: ['22px', '36px'],
      fontWeight: 'bold',
      lineHeight: ['115%', '100%'],
      letterSpacing: '1%',
      textTransform: 'uppercase',
    }),
    admin: defineStyle({
      color: 'black',
      border: '1px solid',
      borderColor: 'brand.black',
      resize: 'none',
      borderRadius: '0',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '120%',
      letterSpacing: '0.5%',
    }),
  },
  defaultProps: {
    variant: 'default',
  },
});
