import { redirect } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/cookies/')({
  component: RouteComponent,
  loader: () => {
    throw redirect({
      href: 'https://files.pi.fyi/cookies.pdf',
    });
  },
});

function RouteComponent() {
  return null;
}
