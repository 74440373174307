// https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/imgPreview.ts:0-830
import { centerCrop, makeAspectCrop } from 'react-image-crop';
import type { PercentCrop, PixelCrop } from 'react-image-crop';
// import heic2any from 'heic2any';
import fileExtension from 'file-extension';
import { canvasPreview } from './canvasPreview';

if (typeof window !== 'undefined') {
  try {
    // await import('blueimp-canvas-to-blob');
  } catch (e) {
    console.log('Failed to load blueimp-canvas-to-blob');
    console.error(e);
  }
}

function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/jpeg');
  });
}

export async function compressImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const extension = fileExtension(file.name);
    resolve(
      new File([file], `temp.${extension}`, {
        type: file.type,
      }),
    );

    // new Compressor(file, {
    //   quality: 0.7,
    //   // The compression process is asynchronous,
    //   // which means you have to access the `result` in the `success` hook function.
    //   success(result) {
    //     resolve(
    //       new File([result], `temp.${extension}`, {
    //         type: file.type,
    //       }),
    //     );
    //   },
    //   error(err) {
    //     reject(err);
    //   },
    // });
  });
}

export function getScaledCropFromImg(image: HTMLImageElement, crop: PixelCrop) {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  return {
    x: Math.floor(crop.x * scaleX),
    y: Math.floor(crop.y * scaleY),
    width: Math.floor(crop.width * scaleX),
    height: Math.floor(crop.height * scaleY),
  };
}

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export async function getCropFileFromImg(
  image: HTMLImageElement,
  extension: string,
  type: string,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
): Promise<File> {
  const canvas = document.createElement('canvas');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  if (ctx) {
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const url = canvas.toDataURL('image/jpeg', 0.8); // can be changed to jpeg/jpg etc
      const blob = await fetch(url).then((res) => res.blob());

      if (!blob) {
        return reject(new Error('Failed to create blob'));
      }

      resolve({
        file: new File([blob], `temp.${extension}`, {
          type,
        }),
        url,
      });
    }, 100);
  });
}

export async function getCompressedFileFromImg(
  image: HTMLImageElement,
  extension: string,
): Promise<File> {
  const canvas = document.createElement('canvas');
  canvasPreview(image, canvas);

  const blob = await toBlob(canvas);

  if (!blob) {
    throw new Error('Failed to create blob');
  }

  return new File([blob], `temp.${extension}`, {
    type: blob.type,
  });
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number, unit: 'px' | '%' = '%') {
  if (unit === '%') {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        } as PercentCrop,
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  } else {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: mediaWidth * 0.9, // 90% of mediaWidth in pixels
        } as PixelCrop,
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }
}

export async function processInitialFileUpload(file: File): Promise<File> {
  const extension = fileExtension(file.name);

  if (extension.toLowerCase() === 'heic' && typeof window !== 'undefined') {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const heic2any = (await import('heic2any')).default;

      const blob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      });

      // @ts-ignore
      return new File([blob], 'temp.jpeg', {
        type: 'image/jpeg',
      });
    } catch (e) {
      return file;
    }
  }

  return file;
}
