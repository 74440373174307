import { createFileRoute, redirect } from '@tanstack/react-router';
import { createServerFn } from '@tanstack/react-start';
import prisma from 'database';

type RequestData = {
  username: string;
};

const getMostRecentPrompt = createServerFn<{ method: 'GET'; data: RequestData }>().handler(
  async (context) => {
    const { username } = context.data;
    if (!username) {
      return null;
    }
    try {
      return await prisma.prompt.findFirstOrThrow({
        orderBy: { date: 'desc' },
        where: { user: { username } },
        include: { user: true },
      });
    } catch (e) {
      return null;
    }
  },
);

export const Route = createFileRoute('/u/$username/latest-ask/')({
  component: RouteComponent,
  loader: async ({ context, params }) => {
    const { username } = params;
    const prompt = await getMostRecentPrompt({ data: { username } });
    if (prompt) {
      redirect({ to: `/ask/${prompt.id}`, throw: true });
    } else {
      redirect({ to: `/u/${username}/asks`, throw: true });
    }
  },
});

function RouteComponent() {
  return null;
}
