export default [
  'Perfectly Imperfect',
  'Instagram',
  'Word of Mouth',
  'New York Times',
  'Vanity Fair',
  'Wired',
  'The Verge',
  'Digital Frontier',
  'TikTok',
  'Friend',
  'Google',
  'A PI Guest',
  'Elsewhere',
];
