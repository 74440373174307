import { createFileRoute } from '@tanstack/react-router';
import { GetArticleViewDocument, GetArticleViewQuery } from 'shared/__generated__/graphql';
import ArticleView from './-article-view';
import { lexicalToBlocksFromDocument } from 'shared/lexical';
import { preloadImageByKey } from '../../../lib/utils';
import _ from 'lodash';
import { genMeta } from '../../utils/meta';

export const Route = createFileRoute('/p/$articleSlugOrId')({
  component: RouteComponent,
  head(ctx) {
    const data = ctx.loaderData as Awaited<ReturnType<typeof Route.loader>>;

    const article = data.articleData.article as GetArticleViewQuery['article'];

    if (!article) {
      return genMeta({
        title: 'NOT FOUND',
      });
    }

    if (article.feature) {
      const f = article.feature;

      const image = f.thumbnailFile?.file?.key
        ? `https://files.pi.fyi/cdn-cgi/image/quality=50,format=auto/${f.thumbnailFile?.file.key}`
        : 'https://files.pi.fyi/cdn-cgi/image/quality=20,format=auto/pi-image-for-social.png';

      return genMeta({
        title: ('PI | ' + f?.title).toUpperCase(),
        description: _.truncate(f?.intro || 'READ MORE', { length: 155 }).toUpperCase(),
        image,
      });
    } else if (article.document) {
      const { document: doc } = article;

      const image = doc.thumbnailFile?.key
        ? `https://files.pi.fyi/cdn-cgi/image/quality=70,format=auto/${doc.thumbnailFile?.key}`
        : 'https://files.pi.fyi/cdn-cgi/image/quality=70,format=auto/pi-image-for-social.png';

      return genMeta({
        title: ('PI | ' + doc.title).toUpperCase(),
        description: doc.subtitle?.toUpperCase() || "A TASTE OF SOMEONE'S TASTE",
        image,
      });
    }
  },
  loader: async ({ context, params }) => {
    const decodedIdOrSlug = decodeURIComponent(params.articleSlugOrId);

    const { data } = await context.apolloClient.query({
      query: GetArticleViewDocument,
      variables: { id_or_slug: decodedIdOrSlug },
    });

    const blocks = data.article?.document
      ? await lexicalToBlocksFromDocument(data.article?.document)
      : [];

    if (data.article?.document) {
      data.article?.document.relatedData.forEach((item) => {
        if (item.targetFile?.key) {
          preloadImageByKey(item.targetFile.key);
        }
        if (item.targetRec) {
          item.targetRec.attachments?.forEach((attachment) => {
            if (attachment?.file?.key) {
              preloadImageByKey(attachment.file.key);
            }
          });
        }
      });
    }

    return {
      decodedIdOrSlug,
      articleData: data,
      blocks,
    };
  },
});

function RouteComponent() {
  const result = Route.useLoaderData();

  return (
    <ArticleView
      id_or_slug={result.decodedIdOrSlug}
      articleData={result.articleData}
      blocks={result.blocks}
    />
  );
}
