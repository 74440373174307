import React, { useEffect } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { Button } from 'web2/app/components/button';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import PremiumModal from '../../../components/modals/premium-modal';
import { TRIAL_FEATURES as FEATURES } from 'shared/data/signup';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { createFileRoute } from '@tanstack/react-router';
const INTRO_TEXT = 'Try Pro for free.';

export const Route = createFileRoute('/sign-up/trial/')({
  component: SignUpTrial,
});

function SignUpTrial() {
  const { updateStep } = useUpdateSetupStep();
  const { setNavigation } = useSignUpNavigation();
  const [displayText] = useTypewriter(INTRO_TEXT, 50);
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();
  const auth = useAuth();
  const completeOnboarding = async () => {
    trackEvent('Click', 'Onboarding_Complete', {
      userId: auth.user?.id,
    });
    await updateStep(null, '/');
  };

  useEffect(() => {
    setNavigation({
      showLeftArrow: false,
      showRightArrow: true,
      onRightPress: completeOnboarding,
      showSkip: true,
      onSkip: async () => {
        await trackEvent('Click', 'Step_Skipped', {
          userId: auth.user?.id,
        });
        await updateStep(null, '/');
      },
      yPosition: 275,
      signupStep: USER_SIGN_UP_STEP.TRIAL,
    });

    return () => {
      setNavigation({
        showLeftArrow: false,
        showRightArrow: false,
        yPosition: 275,
        signupStep: USER_SIGN_UP_STEP.TRIAL,
      });
    };
  }, [setNavigation]);

  return (
    <div className="flex flex-col items-center justify-center min-h-full relative">
      <div
        className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start"
      >
        {displayText}
      </div>
      <div
        className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24"
      >
        Start your 7-day trial to experience the app with enhanced customization and features. After
        that, you'll be billed $6 monthly for your Pro subscription. Cancel anytime.
      </div>
      <div className="mt-[50px] flex flex-col gap-[16px] w-full justify-start">
        <Button className="w-[180px]" onClick={modalState.onOpen} variant="primaryBlackText">
          Start My Free Trial
        </Button>
        <Button className="w-[180px]" onClick={completeOnboarding} variant="secondary">
          No thank you
        </Button>
      </div>
      <div className="mt-[38px]">
        <div
          className="mb-[16px] typography-action-sm font-bold text-none whitespace-pre-line"
        >
          WHAT YOU'LL GET
        </div>
        {FEATURES.map((feature, index) => (
          <div key={index} className="inline-block mb-[16px]">
            <div className="typography-action-sm text-[16px] leading-[24px] whitespace-pre-line">
              ⭐️ {feature.title}
            </div>
            <div className="typography-action-sm text-[16px] leading-[24px] text-none">
              {feature.description}
            </div>
          </div>
        ))}
      </div>
      {modalState.isOpen && <PremiumModal modalState={modalState} />}
    </div>
  );
}
