'use client';

import { useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState } from 'lexical';
import * as apollo from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { useDropzone } from 'react-dropzone-esm';
import { processLexicalState } from 'shared/lexical';
import { gql } from 'shared/__generated__/gql';
import {
  recSlimInlineTitleTheme,
  recSlimInlineBodyTheme,
} from 'shared/misc/theme/selectors/lexicalStyles';
import { safeSendWebViewMessage } from 'shared/webview';
import { GlobalWebViewMessages } from 'shared/webview/messages';
import {
  RecDraftAutoSaveFragment,
  RecommendationAttachmentFragment,
} from 'shared/__generated__/graphql';
import { safeParse } from 'shared/utils';
import useRecForm, { RecFormHandle, RecFormInputs } from './use-rec-form';
import useRecPhoto from './hooks/use-rec-photo';
import useRecDraftAutoSave from './hooks/use-rec-draft-auto-save';
import { motion } from 'framer-motion';
import { cn } from '../../../utils/cn';
import { Button as TWButton } from '../../../components/button';

interface RecItemSlimInlineComposerProps {
  draft?: RecDraftAutoSaveFragment;
  promptId?: string;
  defaultValues?: {
    title?: string | undefined | null;
    emoji?: string | undefined | null;
    url?: string | undefined;
    // As Json
    contentLexicalState?: EditorState | undefined | null;
  };
  titlePlaceholder?: string;
  bodyPlaceholder?: string;
  withInvertedEmojiLink?: boolean;
  withEmojiLinkSameLine?: boolean;
  redirectToRec?: boolean;
  startCollapsed?: boolean;
  oneLineViewMode?: boolean;
  withSolidHighlightBorder?: boolean;
  withRotatingOptions?: boolean;
}

const options = [
  {
    emojiPlaceholder: '⭐',
    titlePlaceholder: 'What have you been into?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🎵',
    titlePlaceholder: 'What have you been listening to?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🍔',
    titlePlaceholder: 'Try any good restaurants lately?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🎥',
    titlePlaceholder: 'What have you been watching?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🛒',
    titlePlaceholder: 'Recent buys?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🎧',
    titlePlaceholder: 'Favorite podcast as of late?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '📺',
    titlePlaceholder: 'Favorite video this week?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '📚',
    titlePlaceholder: 'What are you reading?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🎮',
    titlePlaceholder: 'What are you playing?',
    bodyPlaceholder: 'Share what you love.',
  },
  {
    emojiPlaceholder: '🧠',
    titlePlaceholder: "What's on your mind?",
    bodyPlaceholder: 'Share what you love.',
  },
];

const AnimatedPlaceholder = ({ text }: { text: string }) => (
  <motion.div
    style={{ display: 'inline-block' }}
    key={text}
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.3 }}
  >
    {text}
  </motion.div>
);

export default function RecItemSlimInlineComposer({
  draft,
  promptId,
  defaultValues,
  titlePlaceholder,
  bodyPlaceholder,
  withInvertedEmojiLink = false,
  withEmojiLinkSameLine = false,
  startCollapsed = false,
  oneLineViewMode = false,
  redirectToRec = false,
  withSolidHighlightBorder = false,
  withRotatingOptions = false,
}: RecItemSlimInlineComposerProps) {
  const formRef = useRef<RecFormHandle>(null);

  const toast = useToast();

  const [isClicked, setIsClicked] = useState(false);

  const [placeholderConfigIdx, setPlaceholderConfigIdx] = useState(0);
  const placeholderConfig = options[placeholderConfigIdx];

  const [createRec, createRecState] = apollo.useMutation(CREATE_INLINE_REC, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getProfileView', 'getHomeview', 'getAskViewRecsQuery', 'getAskView'],
  });

  const router = useRouter();

  const { onUpload, attachments: uploadedAttachments, setAttachments, reset } = useRecPhoto();

  const attachments = useMemo(() => {
    return draft?.attachments?.length
      ? draft?.attachments
      : uploadedAttachments
      ? uploadedAttachments
      : [];
  }, [draft?.attachments, uploadedAttachments]);

  useEffect(() => {
    if (withRotatingOptions && !isClicked) {
      const interval = setInterval(() => {
        setPlaceholderConfigIdx((prev) => (prev + 1) % options.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [withRotatingOptions, isClicked]);

  const [otherAttachments, setOtherAttachments] = useState<RecommendationAttachmentFragment[]>([]);

  const [removeAttachmentMap, setRemoveAttachment] = useState({});

  const allAttachments = useMemo(() => {
    return [...attachments, ...otherAttachments].filter(
      (attachment) => attachment && !removeAttachmentMap[attachment.id],
    );
  }, [attachments, otherAttachments, removeAttachmentMap]);

  const clearAttachments = () => {
    setAttachments([]);
    setOtherAttachments([]);
    setRemoveAttachment({});
  };

  const onDrop = useCallback(
    async (files) => {
      if (files?.length > 5 || attachments.length + files.length > 5) {
        toast({
          title: 'Only 5 attachments per rec',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      } else if (files.length) {
        let position = allAttachments.length;
        for (const file of files) {
          await onUpload(file, draft ? { draftRecId: draft?.id } : undefined, position);
          position++;
        }
      }
    },
    [draft],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 5,
    multiple: true,
    noClick: true,
    onError: () => {
      toast({
        title: 'An error occured...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const {
    draftRec,
    onCreateRecFromDraft,
    onSaveDraftDebounced,
    onDeleteRecDraft,
    isSubmittingCreateRec,
  } = useRecDraftAutoSave({
    formRef,
    draftRecId: draft?.id || null,
    warnOnUnload: false,
    autoDelete: false,
  });

  const onCreateRec = useCallback(
    async (values: RecFormInputs) => {
      if (draft) {
        await onCreateRecFromDraft(values);
        return;
      }

      const { titleLexicalState, contentLexicalState = null, emoji = '', url } = values;

      const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
      const { text: title } = processLexicalState(titleLexicalState);

      formRef.current?.reset();

      const saveAttachmentIds = allAttachments.map((attachment) => attachment?.id);

      createRec({
        variables: {
          promptId,
          attachmentIds: saveAttachmentIds,
          input: {
            url,
            title,
            emoji,
            content,
            contentLexical,
          },
        },
        onCompleted: async (data) => {
          clearAttachments();
          // reset();
          if (redirectToRec && data?.createRec) {
            router.navigate({ to: `/rec/${data?.createRec.id}` });
          }
          toast({
            title: promptId ? 'Reply rec shared' : 'Rec shared.',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top',
          });
          try {
            safeSendWebViewMessage(GlobalWebViewMessages.HAPTIC_SUCCESS);
          } catch (e) {}
        },
        onError: () => {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      });
    },
    [draft, onCreateRecFromDraft, redirectToRec, router, toast],
  );

  const { mainFormNode, isOpen, onSubmit, emojiNode, editLinkNode, editImageNode } = useRecForm({
    onSubmit: onCreateRec,
    minBodyEditorHeight: '50px',
    maxBodyEditorHeight: '200px',
    maxBodyEditorWithFileHeight: '200px',
    ref: formRef,
    titleTheme: recSlimInlineTitleTheme,
    bodyTheme: recSlimInlineBodyTheme,
    onChange: onSaveDraftDebounced,
    isSlim: true,
    emojiPlaceholder: placeholderConfig.emojiPlaceholder,
    titlePlaceholder: (
      <AnimatedPlaceholder text={titlePlaceholder || placeholderConfig.titlePlaceholder} />
    ),
    bodyPlaceholder: (
      <AnimatedPlaceholder text={bodyPlaceholder || placeholderConfig.bodyPlaceholder} />
    ),
    withEmojiLinkSameLine,
    hideEmoji: withInvertedEmojiLink,
    hideLink: withInvertedEmojiLink,
    startCollapsed,
    attachments: allAttachments,
    defaultValues: {
      title: draft ? draft.title || '' : defaultValues?.title || '',
      url: draft ? draft.url || '' : defaultValues?.url || '',
      emoji: draft ? draft.emoji || '😃' : defaultValues?.emoji || '',
      contentLexicalState: draft
        ? draft.contentLexical
          ? safeParse(draft.contentLexical)
          : ''
        : defaultValues?.contentLexicalState || '',
    },
    onUpload,
    onOpenFilePicker: () => {
      open();
    },
    onAddAttachment: (attachment) => {
      setOtherAttachments((existing) => [...existing, attachment]);
    },
    onRemoveAttachment: (id: string) => {
      reset();
      if (id) setRemoveAttachment((prev) => ({ ...prev, [id]: true }));
    },
  });

  const buttonsNode = (
    <>
      {draft && (
        <TWButton
          size="sm"
          variant="secondaryDanger"
          onClick={async () => {
            const confirmed = confirm('Delete draft?');
            if (confirmed) onDeleteRecDraft(true);
          }}
        >
          Delete
        </TWButton>
      )}
      <TWButton
        size="sm"
        variant="primary"
        className="bg-brand-highlight text-brand-background"
        isLoading={createRecState.loading || isSubmittingCreateRec}
        isDisabled={createRecState.loading || isSubmittingCreateRec}
        onClick={async () => {
          await onSubmit();
        }}
      >
        Share
      </TWButton>
    </>
  );

  return (
    <div
      className={cn(
        'bg-brand-background border border-solid border-brand-main w-full min-w-full',
        startCollapsed && !isClicked ? 'min-h-[100px]' : 'min-h-[155px]',
        withSolidHighlightBorder ? 'border-solid' : 'border-dashed',
      )}
      {...getRootProps()}
      onClick={() => {
        setIsClicked(true);
      }}
    >
      <div className="space-y-1.5 p-3 md:p-3">
        <div className="flex space-x-3">
          <div className="flex-1">{mainFormNode}</div>
          {oneLineViewMode && !isOpen ? buttonsNode : null}
        </div>
        <input {...getInputProps()} />
        {!oneLineViewMode || isOpen ? (
          <div className="mt-3 flex justify-between items-center h-[36px]">
            {withInvertedEmojiLink ? (
              <div className="flex flex-row gap-[8px] items-center target-empty-children-and-hide">
                {emojiNode}
                {editImageNode}
                {editLinkNode}
              </div>
            ) : null}
            <div className="flex space-x-3">{buttonsNode}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const CREATE_INLINE_REC = gql(/* GraphQL */ `
  mutation createRecInline(
    $input: RecInput!
    $promptId: String
    $attachmentIds: [String!]
    $attachmentId: String
  ) {
    createRec(
      input: $input
      promptId: $promptId
      attachmentIds: $attachmentIds
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`);