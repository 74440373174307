import Spinner from 'web2/app/components/spinner';
import { createFileRoute, redirect } from '@tanstack/react-router';
import React from 'react';
import { useEffect } from 'react';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { ConvoProvider, useConvoEventListHooks } from 'shared/misc/hooks/useConvoHooks';
import { useConvoActions } from 'shared/misc/hooks/useConvoHooksWeb';
import { usePrevious } from 'shared/misc/hooks/usePrevious';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { z } from 'zod';
import { TWLink } from '../../components/link';
import { UserAvatar } from '../../components/by-type/user/user-avatar';
import { InView } from 'react-intersection-observer';
import ConversationEventItem from './-conversation-event-item';
import { useRef } from 'react';
import { useMemo } from 'react';
import { useLayoutEffect } from 'react';
import { getUserStringForMultipleUsers } from 'shared/multipleUsersUtils';
import { getSessionUser } from '../../actions/getSessionUser';
import ConversationMessageComposer from './-conversation-message-composer';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../components/ui/tooltip';

export const Route = createFileRoute('/convo/$convoId')({
  component: RouteComponent,
  validateSearch: z.object({
    thread: z.string().optional(),
  }),
  beforeLoad: async () => {
    const user = await getSessionUser();
    if (!user) {
      throw redirect({ to: '/sign-in' });
    }
  },
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Convo',
      },
    ],
  }),
});

function RouteComponent() {
  const { convoId } = Route.useParams();
  const { thread: threadId } = Route.useSearch();

  const { eventEdges, isFetchingMore, getNextPage, conversation, mergedEventEdges } =
    useConvoEventListHooks({
      convoId,
      threadId: threadId,
      reverse: true,
    });

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const prevEdges = usePrevious(eventEdges);

  // console.log('conversation', conversation);

  useAutoSetTheme(conversation?.theme || ProfileThemeType.Default);

  useEffect(() => {
    if ((!prevEdges && eventEdges) || (prevEdges?.length === 0 && eventEdges.length > 0)) {
      const children = [...(scrollRef.current?.children || [])];
      const node = children[children?.length - 1];
      node?.scrollIntoView();
    }
  }, [prevEdges, eventEdges]);

  useKeepScrollPosition([eventEdges], scrollRef);

  const isGroup = conversation?.users?.length > 2 || false;

  const { menuNode } = useConvoActions({ convoId });

  return (
    <ConvoProvider conversation={conversation!}>
      <div className="flex flex-col h-full w-full h-[calc(100vh-67px)] overflow-hidden">
        <div className="flex h-[80px] border-b-[1px] border-dashed border-brand-main w-full max-w-full bg-brand-background relative items-center justify-between z-10 flex-shrink-0">
          <div className="pl-[24px]">
            <TWLink
              to={threadId ? `/convo/${convoId}` : '/convos'}
              variant="stylizedNoDefaultBorder"
              className="opacity-60 hover:opacity-100"
            >
              {threadId ? 'back' : 'all convos'}
            </TWLink>
          </div>
          <Tooltip>
            <TooltipTrigger>
              <div className="cursor-pointer">
                <p className="typography-heading-lg">
                  {threadId
                    ? 'THREAD'
                    : conversation
                    ? conversation.name ||
                      getUserStringForMultipleUsers(conversation.users.map(({ user }) => user))
                    : 'CONVO'}
                </p>
              </div>
            </TooltipTrigger>
            <TooltipContent
              withPortal
              className="w-[300px] p-[12px] border-[1px] border-dashed border-brand-main z-[1000]"
            >
              <div className="flex flex-col gap-[6px] max-h-[300px] overflow-y-scroll">
                {conversation?.users.map(({ user }) => (
                  <div className="flex flex-row gap-[6px]">
                    <UserAvatar user={user} avatarSize={25} />
                    <TWLink to={user?.siteUrl} variant="stylizedNoDefaultBorder">
                      <p className="typography-heading-sm">@{user?.username}</p>
                    </TWLink>
                  </div>
                ))}
              </div>
            </TooltipContent>
          </Tooltip>

          <div className="pr-[24px]">{menuNode}</div>
        </div>
        <div className="flex-1 flex justify-center overflow-y-auto w-full">
          <div className="max-w-full w-[1000px] h-full flex flex-col overflow-hidden">
            <div className="overflow-y-auto relative w-full flex-1 min-h-0 px-2" ref={scrollRef}>
              <InView
                as="div"
                rootMargin="200px 0px"
                onChange={(inView) => {
                  if (inView) {
                    getNextPage();
                  }
                }}
              />
              {isFetchingMore ? (
                <div className="flex justify-center items-center">
                  <Spinner placement="center" />
                </div>
              ) : null}
              {mergedEventEdges.map((mergedEvent) => (
                <div className="gap-4 my-[12px] mx-0" key={mergedEvent.events?.[0]?.id}>
                  {mergedEvent.events.map((event, i, arr) => (
                    <ConversationEventItem
                      currentThreadId={threadId}
                      isGroup={isGroup}
                      key={event.id}
                      event={event}
                      showImage={i === arr.length - 1}
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className="flex-shrink-0 max-w-[1000px] w-full mx-auto">
              <ConversationMessageComposer
                convoId={convoId}
                onMessageSent={() => {
                  const children = [...(scrollRef.current?.children || [])];
                  const node = children[children?.length - 1];

                  node.scrollIntoView({ behavior: 'smooth' });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ConvoProvider>
  );
}

// https://codesandbox.io/s/keep-scroll-position-on-chat-57ktc?file=/src/useKeepScrollPosition.js:58-709
function useKeepScrollPosition(deps = [] as any[], ref) {
  const containerRef = ref || useRef(null);
  const previousScrollPosition = useRef(0);

  useMemo(() => {
    if (containerRef?.current) {
      const container = containerRef?.current;
      previousScrollPosition.current = container?.scrollHeight - container?.scrollTop;
    }
  }, [...deps]);

  useLayoutEffect(() => {
    if (containerRef?.current) {
      const container = containerRef?.current || {};
      container.scrollTop = container?.scrollHeight - previousScrollPosition.current;
    }
  }, [...deps]);

  return {
    containerRef,
  };
}
