import { createFileRoute } from '@tanstack/react-router';
import { Stack } from '@chakra-ui/react';
import CenterPage from 'shared/misc/components/CenterPage';
import Heading from 'shared/misc/components/heading';
import JobSection from './-sections/JobSection';
import LinksSection from './-sections/LinksSection';

export const Route = createFileRoute('/admin/dashboard/')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <CenterPage axis="horizontal">
      <Stack
        spacing={16}
        width={{ base: '100%', lg: '800px' }}
        mt="100px"
        padding={{ base: '12px', md: '0px' }}
      >
        <Heading as="h1" variant="XL">
          ADMIN DASHBOARD
        </Heading>
        <LinksSection />
        <JobSection />
      </Stack>
    </CenterPage>
  );
}
