'use client';

import { UserCoreFragment } from 'shared/__generated__/graphql';
import React from 'react';
import { HiOutlineStatusOnline, HiOutlineStatusOffline } from 'react-icons/hi';

export const UserOnlineIndicator = ({
  user,
  showOffline = true,
}: {
  user: Pick<UserCoreFragment, 'isOnline'>;
  showOffline?: boolean;
}) =>
  user.isOnline || showOffline ? (
    <span className="flex items-center typography-action-sm text-brand-highlight bg-brand-background border border-dashed border-brand-highlight p-[2px] px-[6px]">
      {user.isOnline ? <HiOutlineStatusOnline /> : <HiOutlineStatusOffline />}
      {user.isOnline ? 'ONLINE NOW' : 'OFFLINE'}
    </span>
  ) : (
    <div className="h-[25px]" />
  );
