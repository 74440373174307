import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Heading,
  useTheme,
  useMediaQuery,
  BoxProps,
} from '@chakra-ui/react';
import { useDrag } from '@use-gesture/react';
import ArrowIcon from './arrow.svg?react';
import DashArrowIcon from './dash-arrow.svg?react';
import useStars from '../hooks/useStars';
import usePrevious from 'use-previous';
import { useThemeColors } from '../hooks/useThemeColors';

const FeedItemCarousel = ({
  children,
  title,
  accent,
  height,
  containerProps = {},
  bottomNode = null,
  withStars = true,
  itemWidth = 341,
}: {
  children: React.ReactNode;
  title: string | React.ReactNode;
  accent?: string;
  height?: any;
  containerProps?: BoxProps;
  bottomNode?: React.ReactNode;
  withStars?: boolean;
  itemWidth?: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const gap = 24;
  const itemCount = React.Children.count(children);
  const [isWideScreen] = useMediaQuery('(min-width: 1280px)', {
    ssr: true,
    fallback: false,
  });
  const stars = useStars(isWideScreen, false, 8, true);

  const handleNext = () => {
    if (currentIndex < itemCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevTitle = usePrevious(title);

  useEffect(() => {
    if (currentIndex !== 0 && prevTitle !== title) {
      setCurrentIndex(0);
    }
  }, [title]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const bind = useDrag(
    ({ down, movement: [mx], direction: [xDir], distance: [dx], cancel, touches }) => {
      if (touches > 1) return;
      if (down && dx > itemWidth / 4) {
        if (xDir < 0 && currentIndex < itemCount - 1) {
          setCurrentIndex(currentIndex + 1);
        } else if (xDir > 0 && currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
        cancel();
      }
    },
  );

  const themeColors = useThemeColors();

  return (
    <Box
      position="relative"
      overflow="hidden"
      backgroundColor={accent}
      transition="background-color 0.5s ease"
      w="100%"
      minWidth="100%"
      maxWidth="100%"
      p={{ base: '24px 12px', sm: '48px' }}
      border="1px solid"
      borderStyle="dashed"
      borderColor="brand.main"
      height={height}
      {...containerProps}
    >
      {withStars && (
        <Box
          pointerEvents="none"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="1000"
        >
          {stars}
        </Box>
      )}
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Heading
          fontSize={{ base: '36px', lg: '64px' }}
          variant="XL"
          transform="scale(1, 1.25)"
          className="mb-4"
          color="brand.titleBackground"
          sx={{
            WebkitTextStroke: '2px',
            WebkitTextStrokeColor: themeColors.titleBorder,
          }}
        >
          {title}
        </Heading>
        <Box display="flex" gap="4px" alignItems="center">
          <IconButton
            background="transparent"
            color="brand.main"
            _hover={currentIndex > 0 ? { color: 'brand.highlight' } : { color: 'brand.main' }}
            icon={
              currentIndex > 0 ? (
                <ArrowIcon fill="currentColor" style={{ transform: 'rotate(180deg)' }} />
              ) : (
                <DashArrowIcon stroke="currentColor" />
              )
            }
            onClick={handlePrev}
            aria-label="Previous"
            disabled={currentIndex === 0}
            _disabled={{
              cursor: 'not-allowed',
              pointerEvents: 'none',
              backgroundColor: 'transparent',
            }}
          />
          <IconButton
            background="transparent"
            color="brand.main"
            _hover={{ color: 'brand.highlight' }}
            icon={
              currentIndex < itemCount - 1 ? (
                <ArrowIcon fill="currentColor" />
              ) : (
                <DashArrowIcon style={{ transform: 'rotate(180deg)' }} stroke="currentColor" />
              )
            }
            onClick={handleNext}
            aria-label="Next"
            disabled={currentIndex === itemCount - 1}
            _disabled={{
              cursor: 'not-allowed',
              pointerEvents: 'none',
              backgroundColor: 'transparent',
            }}
          />
        </Box>
      </Flex>
      <Flex
        {...bind()}
        transform={`translateX(-${currentIndex * (itemWidth + gap)}px)`}
        transition="transform 0.3s ease-in-out"
        width={`${itemCount * (itemWidth + gap)}px`}
        gap={`${gap}px`}
        overflow="visible"
        alignItems={{ base: 'stretch', sm: 'flex-start' }}
      >
        {React.Children.map(children, (child, index) => (
          <Box key={index} flex="0 0 auto" display="flex" flexDir="column" width={`${itemWidth}px`}>
            {child}
          </Box>
        ))}
      </Flex>
      {bottomNode}
    </Box>
  );
};

export default FeedItemCarousel;
