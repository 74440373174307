import React, { useMemo } from 'react';
import { DocumentCoreFragment } from '../../__generated__/graphql';
import { DocumentRenderMode } from '../../types';

interface ContextProps {
  document?: DocumentCoreFragment;
  hideRecommendationUserInfo?: boolean;
  mode?: DocumentRenderMode;
}

export const DocumentWrapperContext = React.createContext<ContextProps>({} as ContextProps);

interface DocumentProviderProps extends ContextProps {
  children: React.ReactNode;
}

const DocumentWrapperProvider = ({ children, ...rest }: DocumentProviderProps) => {
  const memoizedRest = useMemo(
    () => rest,
    [rest.document?.updatedAt, rest.document?.id, rest.hideRecommendationUserInfo, rest.mode],
  );

  return (
    <DocumentWrapperContext.Provider value={memoizedRest}>
      {children}
    </DocumentWrapperContext.Provider>
  );
};

export function useDocumentWrapperContext() {
  return React.useContext(DocumentWrapperContext);
}

export default DocumentWrapperProvider;
