import { createServerFn } from '@tanstack/react-start';
import { getHeaders } from '@tanstack/react-start/server';

const createMapFromCookiesString = (cookiesString: string) => {
  const cookies = cookiesString?.split(';') || [];
  const cookieMap: Record<string, string> = {};
  cookies?.forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookieMap[key.trim()] = value;
  });
  return cookieMap;
};

export const getCookiesMap = createServerFn({ method: 'GET' }).handler(async () => {
  const headers = getHeaders();
  const cookies = headers.cookie!;
  const cookieMap = createMapFromCookiesString(cookies);
  return cookieMap;
});
