import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';

export default function page({ error }: { error: Error }) {
  React.useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              textAlign: 'center',
              fontSize: '48px',
              marginTop: '48px',
              marginBottom: '0',
            }}
          >
            😢
          </h1>
          <h1
            style={{
              textAlign: 'center',
              fontSize: '48px',
              marginTop: '0',
              marginBottom: '12px',
            }}
          >
            ERROR
          </h1>
          <div>
            <p
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              It looks like something went (very) wrong.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
            <Link
              href="/home"
              style={{
                textDecoration: 'none',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: '16px',
              }}
            >
              Go Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
