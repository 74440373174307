import { Text } from '@chakra-ui/react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'web2/app/components/ui/tooltip';
import { GoBell, GoBellFill, GoComment, GoCommentDiscussion } from 'react-icons/go';
import { useMutation } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import LikeStars from 'web2/app/components/by-type/likes/like-stars';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import { PromptItemFragment, PromptType } from 'shared/__generated__/graphql';
import { useAuth } from 'shared/misc/hooks/useAuth';
import {
  SUBSCRIBE_TO_PROMPT_MUTATION,
  UNSUBSCRIBE_FROM_PROMPT_MUTATION,
  ENDORSE_PROMPT_MUTATION,
  UNENDORSE_PROMPT_MUTATION,
} from 'shared/misc/graphql/PromptMutations';
import { getRelativeDateString } from 'shared/dates';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { UserAvatar } from '../../user/user-avatar';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { cn } from '../../../../utils/cn';
import { TWLink } from '../../../../components/link';
import { Button as TWButton } from '../../../../components/button';

interface Props {
  prompt: PromptItemFragment;
  withResponses?: boolean;
  withBottomActions?: boolean;
  withBorder?: boolean;
  isPromptView?: boolean;
  withSpecialBorder?: boolean;
  isInline?: boolean;
}

export default function PromptItemBottom(props: Props) {
  const auth = useAuth();
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const {
    prompt,
    withResponses,
    withBottomActions = true,
    withBorder = props.isInline ? false : props.withBorder,
    isPromptView = false,
    withSpecialBorder = true,
    isInline = false,
  } = props;

  const [endorsePrompt] = useMutationWithAutoToast(ENDORSE_PROMPT_MUTATION, {
    successMessage: 'Liked.',
    variables: {
      targetPromptId: prompt.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      endorsePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        isEndorsed: true,
        endorsementCount: prompt.endorsementCount + 1,
      },
    },
  });

  const [unendorsePrompt] = useMutationWithAutoToast(UNENDORSE_PROMPT_MUTATION, {
    successMessage: 'Unliked.',
    variables: {
      targetPromptId: prompt.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      unendorsePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        isEndorsed: false,
        endorsementCount: prompt.endorsementCount - 1,
      },
    },
  });

  const themeColors = useThemeColors();

  const logoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

  const src = prompt.user?.avatarPhotoSrc || `/default-profile-photo-${logoColor}-small.png`;
  const isBackgroundLight = useIsBackgroundLight();
  const CommentsIcon = prompt.recsCount > 0 ? GoCommentDiscussion : GoComment;

  const dateNode = (
    <div
      // paddingLeft={isInline ? '8px' : undefined}
      // opacity={isBackgroundLight ? 0.2 : 0.7}
      // textTransform="none"
      // whiteSpace="nowrap"
      className={cn(
        'whitespace-nowrap',
        'text-theme-menu-text',
        isInline ? 'pl-[8px]' : undefined,
        isBackgroundLight ? 'opacity-20' : 'opacity-70',
      )}
    >
      {/* {format(new Date(prompt.date), 'MMM dd, yyyy')} */}
      {getRelativeDateString(new Date(prompt.date))}
      {/* <Text as="span"> at {format(new Date(prompt.date), 'h:mm a')}</Text> */}
    </div>
  );

  const usernameTextNode = (
    <div className="max-w-[100%] line-clamp-1">@{prompt.user?.username || 'NO_USER_YET'}</div>
  );

  const usernameNode = (
    <div className="flex flex-row items-end gap-[3px]">
      {prompt.type !== PromptType.UnpublishedPostPrompt || prompt.user ? (
        <TWLink to={'/u/$username'} params={{ username: prompt.user?.username }}>
          {usernameTextNode}
        </TWLink>
      ) : (
        usernameTextNode
      )}
      {/* <Text textStyle="brand.actionSm" fontSize="10px">
        ASKED...
      </Text> */}
    </div>
  );

  const infoNodes = (
    <>
      {usernameNode}
      {dateNode}
    </>
  );

  const subNode = (
    <>
      {isInline && (
        <TWLink
          to={`/ask/${prompt.id}`}
          className={cn(
            'border border-dashed border-brand-ronald p-[4px] mx-[8px]',
            'text-brand-main text-brand-body hover:text-brand-ronald hover:border-brand-ronald',
          )}
        >
          <Text>Answer</Text>
        </TWLink>
      )}
      <Tooltip>
        <TooltipContent className="typography-action-sm">
          {prompt.isViewerSubscribed ? 'unsubscribe' : 'subscribe'}
        </TooltipContent>
        <TooltipTrigger>
          <TWButton
            variant="icon"
            aria-label="notify rec"
            className="text-brand-main hover:text-brand-highlight"
            onClick={async () => {
              if (prompt.isViewerSubscribed) {
                await unsubscribeFromPrompt();
                trackEvent('Click', 'Unsubscribe_Ask', {
                  promptId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  commentsCount: prompt.recsCount,
                });
              } else {
                await subscribeToPrompt();
                trackEvent('Click', 'Subscribe_Ask', {
                  promptId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  commentsCount: prompt.recsCount,
                });
              }
            }}
          >
            {prompt.isViewerSubscribed ? <GoBellFill size="20px" /> : <GoBell size="20px" />}
          </TWButton>
        </TooltipTrigger>
      </Tooltip>
    </>
  );

  const avatarSize = 25;

  const imageNode = <UserAvatar withActiveIndicator user={prompt.user} avatarSize={avatarSize} />;

  const [subscribeToPrompt] = useMutation(SUBSCRIBE_TO_PROMPT_MUTATION, {
    variables: { targetPromptId: prompt.id },
    optimisticResponse: {
      __typename: 'Mutation',
      subscribeToPrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        isViewerSubscribed: true,
      },
    },
  });

  const [unsubscribeFromPrompt] = useMutation(UNSUBSCRIBE_FROM_PROMPT_MUTATION, {
    variables: { targetPromptId: prompt.id },
    optimisticResponse: {
      __typename: 'Mutation',
      unsubscribeFromPrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        isViewerSubscribed: false,
      },
    },
  });

  return (
    <div
      // spacing="14px"
      // paddingTop={{ base: '12px', sm: '0px' }}
      // flexDirection={isInline ? 'row' : 'column'}
      // justifyContent={isInline ? 'space-between' : undefined}
      className={cn(
        'flex flex-col gap-[14px] pt-[12px] sm:pt-0',
        isInline ? 'flex-row justify-between' : 'flex-col',
      )}
    >
      <div
        // justifyContent="space-between"
        // alignItems="center"
        // textStyle="brand.actionSm"
        // px={!isInline ? '16px' : undefined}
        // pb={withBottomActions ? undefined : '16px'}
        className={cn(
          'flex flex-row justify-between items-center typography-action-sm text-brand-main',
          !isInline ? 'px-[16px]' : '',
          withBottomActions ? '' : 'pb-[16px]',
        )}
      >
        <div className="flex flex-row items-center gap-[8px] target-empty-children-and-hide">
          {imageNode}
          {usernameNode}
        </div>
        {dateNode}
      </div>
      {withBottomActions && (
        <div
          className={cn(
            'flex flex-row justify-between items-center typography-action-sm target-empty-children-and-hide',
            withBorder && !isInline ? 'border-t-[1px] border-dashed border-brand-main' : '',
            withSpecialBorder && !isInline ? 'border-t-brand-ronald' : 'border-t-brand-main',
            'p-[14px] pt-[7px] pb-[7px]',
          )}
        >
          {subNode}
          <div
            // left={
            //   isInline
            //     ? {
            //         base: '9px',
            //         sm: '12px',
            //       }
            //     : undefined
            // }
            // position={isInline ? 'absolute' : undefined}
            // spacing={isInline ? ['4px', '8px'] : '12px'}
            // alignSelf="flex-start"
            // alignItems="center"
            className={cn(
              'flex flex-row items-center self-start',
              isInline ? 'absolute left-[9px] sm:left-[12px] bottom-[6px]' : '',
              isInline ? 'gap-[4px] sm:gap-[8px]' : 'gap-[12px]',
            )}
          >
            {withResponses && (
              <Tooltip
                label={prompt.recsCount > 0 ? 'Click to respond.' : 'Be the first to respond.'}
              >
                <TooltipContent className="typography-action-sm">
                  {prompt.recsCount > 0 ? 'Click to respond.' : 'Be the first to respond.'}
                </TooltipContent>
                <TooltipTrigger>
                  <TWLink
                    to={`/ask/${prompt.id}`}
                    className="flex items-center text-brand-main whitespace-nowrap"
                    onClick={() => {
                      trackEvent('Click', 'View_Replies_Ask', {
                        promptId: prompt.id,
                        endorsementCount: prompt.endorsementCount,
                        isEndorsed: prompt.isEndorsed,
                        commentsCount: prompt.recsCount,
                      });
                    }}
                  >
                    <div className="flex flex-row gap-[6px]">
                      {prompt.recsCount > 0 && (
                        <span
                          className={cn(
                            isInline ? 'typography-action-sm' : 'typography-heading-sm',
                            isInline ? 'hidden md:block' : 'block',
                          )}
                        >
                          {prompt.recsCount}
                        </span>
                      )}
                      <CommentsIcon
                        size={20}
                        className={`responsive-icon ${isInline ? 'responsive-icon-inline' : ''}`}
                      />
                    </div>
                  </TWLink>
                </TooltipTrigger>
              </Tooltip>
            )}
            <LikeStars
              isEndorsed={prompt.isEndorsed}
              endorsementCount={prompt.endorsementCount}
              unendorse={unendorsePrompt}
              endorse={endorsePrompt}
              targetPromptId={prompt.id}
              ownerId={prompt?.user?.id as string}
              withText={isPromptView}
              classes={isInline ? 'responsive-icon-lg' : ''}
            />
          </div>
        </div>
      )}
    </div>
  );
}
