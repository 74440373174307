import { HashtagNode } from '@lexical/hashtag';
import { editorTheme } from '../../../theme/selectors/lexicalStyles';
import DefaultNodes from './nodes/defaultNodes';
import { MentionNode } from './nodes/MentionNode';

export const basicEditorConfig = {
  theme: editorTheme,
  // @ts-ignore
  onError(error) {
    throw error;
  },
  nodes: [...DefaultNodes, HashtagNode, MentionNode],
};

export default basicEditorConfig;
