import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/spotlight-apply/')({
  component: RouteComponent,
  loader: () => {
    throw redirect({ href: 'https://airtable.com/app45GQfP8eC6ce5d/pagi3zMfeK1sMg7pT/form' });
  },
});

function RouteComponent() {
  return null;
}
