import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Alert, AlertIcon, AlertTitle, Box, Stack, useToast } from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { gql } from 'shared/__generated__/gql';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { TWLink } from '../../../components/link';
import { z } from 'zod';

export const EMAIL_AUTH_MUTATION = gql(/* GraphQL */ `
  mutation verifyViaEmailLink($id: String, $key: String, $destination: String) {
    verifyViaEmailLink(id: $id, key: $key, destination: $destination) {
      token
      destination
    }
  }
`);

export const Route = createFileRoute('/auth/email-callback/$id')({
  component: RouteComponent,
  validateSearch: z.object({
    destination: z.string().optional(),
    isKey: z.string().optional(),
  }),
});

function RouteComponent() {
  const params = Route.useParams();
  const [isError, setIsError] = useState(false);
  const router = useRouter();
  const { destination, isKey } = Route.useSearch();
  const { id } = params;

  const { user, signIn } = useAuth();

  const { updateStep } = useUpdateSetupStep();

  const toast = useToast();

  const [verifyViaEmailLink, { loading }] = useMutation(EMAIL_AUTH_MUTATION, {
    variables: { ...(isKey ? { key: id } : { id }), destination },
    onCompleted: async (data) => {
      toast({
        title: 'Email verified...',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      if (!user && data.verifyViaEmailLink.token) {
        await signIn(data.verifyViaEmailLink.token);
      }
      const dest = decodeURIComponent(data.verifyViaEmailLink.destination || destination || '');
      if (dest === '/sign-up/premium') {
        await updateStep(USER_SIGN_UP_STEP.PREMIUM);
      } else if (dest) {
        window.location.href = dest;
      } else {
        router.navigate({ to: '/' });
      }
    },
    onError: (e) => {
      console.error(e);
      setIsError(true);
    },
  });

  useEffect(() => {
    if (id) {
      verifyViaEmailLink();
    } else {
      setIsError(true);
    }
  }, [id]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
      {loading && <Spinner />}
      {isError && (
        <Stack spacing="15px">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2} textStyle="brand.headerLg">
              Uh oh! Something went wrong...
            </AlertTitle>
          </Alert>
          <TWLink to="/">Home</TWLink>
        </Stack>
      )}
    </Box>
  );
}
