import { ComponentStyleConfig, defineStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = defineStyleConfig({
  // style object for base or default style
  baseStyle: {
    fontFamily: 'brand.arialNarrowBold',
    fontWeight: 'bold',
    letterSpacing: '1.04px',
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    XL: {
      color: 'brand.titleBackground',
      fontSize: () => ({
        base: '64px',
        lg: '104px',
      }),
      fontWeight: 'bold',
      letterSpacing: '1.04px',
      lineHeight: '90%',
      textTransform: 'uppercase',
      WebkitTextStroke: '2px',
      WebkitTextStrokeColor: 'brand.titleBorder',
      paintOrder: 'markers',
    },
    L: {
      color: 'brand.main',
      fontSize: () => ({
        base: '22px',
        lg: '36px',
      }),
      lineHeight: '115%',
      textTransform: 'uppercase',
    },
    S: {
      color: 'brand.main',
      fontSize: '18px',
      lineHeight: '115%',
      textTransform: 'uppercase',
    },
  },
});
