import { useMutation } from '@apollo/client';
import {
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Wrap,
  WrapItem,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import Link from 'next/link';
import { useAuth } from '../hooks/useAuth';
import { copyToClipboard } from '../clipboard';
import { gql } from '../../__generated__/gql';
import { IsAdmin } from './gates/IsAdmin';

export function AuthPopoverOptions() {
  const toast = useToast();

  const { user, signOut } = useAuth();

  const copyJWT = useCallback((withHeader = false) => {
    copyToClipboard(
      withHeader
        ? `authorization: JWT ${localStorage.getItem('token')}`
        : localStorage.getItem('token') || '',
    );

    toast({
      title: 'Copied to clipboard.',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  }, []);

  const [syncNewRecs] = useMutation(SYNC_NEW_REC_MUTATION, {
    onError: () => {
      toast({
        title: 'An error occured while syncing new recs.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
    onCompleted: () => {
      toast({
        title: 'Started syncing recs...',
        description: 'Check discord for updates.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  return null;

  return (
    <IsAdmin>
      <HStack
        spacing="12px"
        position="fixed"
        bottom="0"
        right="0"
        margin="25px"
        zIndex="500"
        width="400px"
        display="flex"
        justifyContent="flex-end"
        // marginLeft="auto"
        // alignContent="flex-end"
        // flexWrap="nowrap"
      >
        {user != null ? (
          <Popover placement="left-start" id="auth-1" isLazy>
            <PopoverTrigger>
              <Button
                bgColor="brand.highlight"
                color="brand.secondary"
                _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
                borderRadius="0px"
              >
                Signed in
              </Button>
            </PopoverTrigger>
            <PopoverContent borderColor="brand.highlight" color="brand.highlight" borderRadius={0}>
              <PopoverHeader fontWeight="semibold">{user.email}</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody padding="20px">
                <Wrap spacing={4}>
                  <WrapItem>
                    <Button
                      bgColor="brand.highlight"
                      color="brand.secondary"
                      _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
                      borderRadius="0px"
                      variant="ghost"
                      onClick={() => signOut()}
                    >
                      Sign out
                    </Button>
                  </WrapItem>
                  <WrapItem>
                    <Button as={Link} borderRadius="0px" colorScheme="yellow" href="/me/lists">
                      /lists
                    </Button>
                  </WrapItem>
                </Wrap>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Button
            as={Link}
            bgColor="brand.highlight"
            color="brand.secondary"
            _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
            borderRadius="0px"
            mr="10px"
            href="/sign-in"
          >
            Sign in
          </Button>
        )}

        <Popover placement="left-start" id="auth-2" isLazy>
          <PopoverTrigger>
            <Button
              size="md"
              borderRadius="0px"
              bgColor="brand.highlight"
              color="brand.secondary"
              _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
            >
              (ADMIN ONLY) Options
            </Button>
          </PopoverTrigger>

          <PopoverContent borderColor="brand.highlight" color="brand.highlight" borderRadius={0}>
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Admin Options
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody padding="20px">
              <Wrap spacing={4}>
                <WrapItem>
                  <Button borderRadius="0px" colorScheme="orange" onClick={() => syncNewRecs()}>
                    Sync new recs
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button borderRadius="0px" colorScheme="green" onClick={() => copyJWT()}>
                    Copy JWT
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button borderRadius="0px" colorScheme="blue" onClick={() => copyJWT(true)}>
                    Copy JWT for Apollo
                  </Button>
                </WrapItem>
              </Wrap>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Popover placement="left-start" id="auth-2" isLazy>
          <PopoverTrigger>
            <Button
              size="md"
              borderRadius="0px"
              bgColor="brand.highlight"
              color="brand.secondary"
              _hover={{ bg: 'brand.yellow', color: 'brand.highlight' }}
            >
              Helpers
            </Button>
          </PopoverTrigger>

          <PopoverContent borderColor="brand.highlight" color="brand.highlight" borderRadius={0}>
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Routes
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody padding="20px">
              <Wrap spacing={4}>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="green" href="/sign-up">
                    /sign-up
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="green" href="/sign-in">
                    /sign-in
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="yellow" href="/home">
                    /home
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="purple" href="/">
                    /index
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="orange" href="/search">
                    /search
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as="a" borderRadius="0px" colorScheme="purple" href="/api/graphql">
                    /api/graphql
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="yellow" href="/features">
                    /features
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="blue" href="/unlocked-archive">
                    /unlocked-archive
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button as={Link} borderRadius="0px" colorScheme="red" href="/random">
                    /random
                  </Button>
                </WrapItem>
              </Wrap>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </IsAdmin>
  );
}

const SYNC_NEW_REC_MUTATION = gql(/* GraphQL */ `
  mutation syncRecsFromSite {
    syncRecsFromSite
  }
`);

// const GET_GOOGLE_URL = gql`
//   query GoogleUrl {
//     googleURL
//   }
// `;
