export const perfectlyImperfectColors = {
  brand: {
    blue: '#0500AD',
    actualBlue: '#0500AD',
    black: '#000000',
    ronald: '#FFBB00',
    mcdonald: '#DE0000',
    red: '#FF0000',
    green: '#00FF00',
    white: '#FFFFFF',
    yellow: '#FFFF00',
    transparent: 'transparent',

    // Generic
    main: '#000000',
    secondary: '#FFFFFF',
    background: '#FFFFFF',
    highlight: '#0500AD',
    highlightBorder: '#000000',
    logoColor: '#0500AD',

    lightgrey: 'lightgrey',

    titleBackground: '#FFBB00',
    titleBorder: '#DE0000',

    menuBackground: '#0500AD',
    menuLogo: '#FFBB00',
    menuText: '#ffffff',
    menuHighlight: '#FFBB00',
  },
};

export function generateRootCssVariables(colors?: Record<string, string>): string {
  const cssVariables = Object.entries(colors || perfectlyImperfectColors)
    .map(([key, value]) => {
      // Convert camelCase to kebab-case
      const kebabKey = key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
      return `--brand-${kebabKey}: ${value};`;
    })
    .join('\n');

  return `:root {\n${cssVariables}\n}`;
}
