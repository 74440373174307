import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToast } from '@chakra-ui/react';
// import * as Sentry from '@sentry/react;
import { FetchResult, useFragment, useMutation } from '@apollo/client';
import _, { DebouncedFunc } from 'lodash';
import { gql } from 'shared/__generated__';
import { processLexicalState } from 'shared/lexical';
import {
  ConversationCoreFragment,
  CreateRecDraftMutation,
  CreateRecFromRecDraftMutation,
  PromptItemFragment,
  RecDraftAutoSaveFragment,
  RecommendationItemFragment,
  StartConversationWithRecMutation,
  UserCoreFragment,
} from 'shared/__generated__/graphql';
import { useListContext } from 'shared/misc/providers/ListContext';
import { START_CONVERSATION_WITH_REC_MUTATION } from 'shared/misc/graphql/RecommendationMutations';
import { RecFormHandle, RecFormInputs } from '../use-rec-form';

interface BaseDraftRecFields {
  title: string;
  url?: string | null;
  content: string;
  emoji?: string | null;
}

export type RecFormArgs = {
  draftRecId?: string | null;
  warnOnUnload?: boolean;
  formRef: React.MutableRefObject<RecFormHandle | null>;
  ref?: React.MutableRefObject<DraftAutoSaveHandle | null>;
  autoDelete?: boolean;
};

export interface DraftAutoSaveHandle {
  draftRec: RecDraftAutoSaveFragment | null;
  onCreateRecFromDraft: (
    values: RecFormInputs,
    extra?: {
      repostRec?: RecommendationItemFragment;
      targetPrompt?: PromptItemFragment;
      targetUser?: UserCoreFragment;
      targetConversation?: ConversationCoreFragment;
      complexConvoRecipients?: {
        userIds: string[];
        conversationIds: string[];
      };
    },
  ) => Promise<FetchResult<CreateRecFromRecDraftMutation> | undefined>;
  onSaveDraftDebounced: DebouncedFunc<(values: RecFormInputs) => void>;
  onDeleteRecDraft: (confirmToast?: boolean) => Promise<void>;
  onCreateRecDraft: () => Promise<CreateRecDraftMutation['createRecDraft'] | null>;
}

export interface DraftAutoSaveResult {
  ref?: React.MutableRefObject<DraftAutoSaveHandle | null>;
  draftRec: RecDraftAutoSaveFragment | null;
  onCreateRecFromDraft: (
    values: RecFormInputs,
    extra?: {
      repostRec?: RecommendationItemFragment;
      targetPrompt?: PromptItemFragment;
      targetUser?: UserCoreFragment;
      targetConversation?: ConversationCoreFragment;
      complexConvoRecipients?: {
        userIds: string[];
        conversationIds: string[];
      };
    },
  ) => Promise<
    | FetchResult<CreateRecFromRecDraftMutation>
    | FetchResult<StartConversationWithRecMutation>
    | undefined
  >;
  onSaveDraftDebounced: DebouncedFunc<(values: RecFormInputs) => void>;
  onDeleteRecDraft: (confirmToast?: boolean) => Promise<void>;
  onCreateRecDraft: () => Promise<CreateRecDraftMutation['createRecDraft'] | null>;
  isSubmittingCreateRec: boolean;
  isDraftSaveable?: boolean;
}

export default function useDraftAutoSave({
  ref,
  formRef,
  warnOnUnload = true,
  autoDelete = true,
  draftRecId: initialDraftRecId = null,
}: RecFormArgs): DraftAutoSaveResult {
  const [draftRecId, setDraftId] = useState<string | null>(initialDraftRecId);

  const draftRecRef = useRef<RecDraftAutoSaveFragment | null>(null);

  const { list } = useListContext();

  const { complete, data: draftRec } = useFragment({
    fragment: REC_WITH_DRAFT_FRAGMENT,
    fragmentName: 'RecDraftAutoSave',
    from: {
      __typename: 'DraftRec',
      id: draftRecId,
    },
  });

  draftRecRef.current = complete ? draftRec : null;

  const toast = useToast();

  // const _values = formRef.current?.watch();

  const [createRecDraft] = useMutation(CREATE_REC_DRAFT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getDraftsView'],
  });

  const [deleteRecDraft] = useMutation(DELETE_REC_DRAFT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getDraftsView'],
  });

  const [updateRecDraft] = useMutation(UPDATE_REC_DRAFT, {
    fetchPolicy: 'network-only',
  });

  const [startConversationWithRec, startConversationWithRecState] = useMutation(
    START_CONVERSATION_WITH_REC_MUTATION,
    { fetchPolicy: 'network-only' },
  );

  const [createRecFromRecDraft, createRecFromRecDraftState] = useMutation(
    CREATE_REC_FROM_REC_DRAFT,
    {
      refetchQueries: [
        'getProfileLowerRecommendations',
        'getListView',
        'getProfileView',
        'getDraftsView',
        'getSearchView',
        'getAskViewMobile',
        'getAskViewRecsMobileQuery',
        'getDoneViewQuery',
      ],
      fetchPolicy: 'network-only',
    },
  );

  const onSaveDraftDebounced = useCallback(
    _.debounce((values: RecFormInputs) => {
      const { contentLexicalState = null, titleLexicalState = null, ...rest } = values;
      if (!draftRecId) {
        return;
      }
      const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
      const { text: title } = processLexicalState(titleLexicalState);

      const updatedDraftRec = {
        ...rest,
        __typename: 'DraftRec',
        id: draftRecId,
        title,
        content,
        contentLexical,
        updatedAt: draftRec.updatedAt,
        createdAt: draftRec.createdAt,
      };

      if (!_.isEqual(updatedDraftRec, draftRec)) {
        updateRecDraft({
          variables: {
            draftRecId: draftRecId,
            input: {
              ...rest,
              title,
              content,
              contentLexical,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateRecDraft: {
              ...updatedDraftRec,
              __typename: 'DraftRec',
              id: draftRecId,
              title,
              content,
              contentLexical,
              updatedAt: draftRec.updatedAt,
              createdAt: draftRec.createdAt,
              attachments: draftRec.attachments || [],
            },
          },
        });
      }
    }, 2000),
    [draftRec],
  );

  const onCreateRecFromDraft = async (
    values: RecFormInputs,
    {
      repostRec,
      targetPrompt,
      targetUser,
      targetConversation,
      complexConvoRecipients,
    }: {
      repostRec?: RecommendationItemFragment;
      targetPrompt?: PromptItemFragment;
      targetUser?: UserCoreFragment;
      targetConversation?: ConversationCoreFragment;
      complexConvoRecipients?: {
        userIds: string[];
        conversationIds: string[];
      };
    } = {},
  ) => {
    const { contentLexicalState, titleLexicalState, ...rest } = values;

    // If there's no draft somehow or the mutation is loading, don't do anything
    if (
      !draftRecId ||
      createRecFromRecDraftState.loading ||
      startConversationWithRecState.loading
    ) {
      return;
    }

    const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
    const { text: title } = processLexicalState(titleLexicalState);

    // Sending as conversation
    if (targetUser || targetConversation || complexConvoRecipients) {
      return await startConversationWithRec({
        variables: {
          ...(complexConvoRecipients
            ? complexConvoRecipients
            : {
                userIds: targetUser ? [targetUser.id] : undefined,
                conversationIds: targetConversation ? [targetConversation.id] : undefined,
              }),
          newRecInput: {
            ...rest,
            draftRecId: draftRecId,
            title,
            content,
            contentLexical,
            attachmentIds: draftRec.attachments?.map((a) => a.id),
          },
        },
        onCompleted: async () => {
          toast({
            title: 'Recommendation shared.',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top',
          });
        },
        onError: (e) => {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });

          // Sentry.captureException(e, { extra: { values } });

          throw e;
        },
      });
    }

    return await createRecFromRecDraft({
      variables: {
        draftRecId: draftRecId,
        repostRecId: repostRec?.id,
        targetPromptId: targetPrompt?.id,
        // listId: list?.id,
        extraValues: {
          ...rest,
          title,
          content,
          contentLexical,
          ...(repostRec?.id || repostRec?.title
            ? {
                title: repostRec.title,
                url: repostRec.url,
                emoji: repostRec.emoji,
              }
            : {}),
        },
      },
      onCompleted: async () => {
        toast({
          title: 'Recommendation saved.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      },
      onError: (e) => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });

        // Sentry.captureException(e, { extra: { values } });

        throw e;
      },
    });
  };

  const onDeleteRecDraft = async (confirmToast: boolean) => {
    const draft = draftRecRef.current;
    if (draft?.id) {
      await deleteRecDraft({
        variables: {
          draftRecId: draft.id,
        },
        onCompleted: () => {
          if (confirmToast) {
            toast({
              title: 'Draft deleted.',
              status: 'success',
              duration: 2000,
              isClosable: true,
              position: 'top',
            });
          }
        },
      });
      setDraftId(null);
      formRef?.current?.reset();
    } else {
      try {
        throw new Error('No draft present...');
        // eslint-disable-next-line no-empty
      } catch (e) {
        // Sentry.captureException(e);
      }
    }
  };

  const onCreateRecDraft = async (): Promise<CreateRecDraftMutation['createRecDraft'] | null> => {
    const { data } = await createRecDraft();
    if (data) {
      setDraftId(data.createRecDraft.id);
      formRef?.current?.setFocus?.('title');
      return data.createRecDraft;
    }
    return null;
  };

  useEffect(() => {
    function cb(e) {
      if (autoDelete) {
        e.preventDefault();
        if (isDraftEmpty(draftRecRef.current)) {
          onDeleteRecDraft(false);
        } else if (warnOnUnload) {
          e.returnValue = 'Are you sure?';
          return 'Are you sure?';
        }
      }
    }
    window.addEventListener('beforeunload', cb);
    return () => {
      window.removeEventListener('beforeunload', cb);
    };
  }, []);

  const mutualFields = useMemo(
    () => ({
      onCreateRecFromDraft,
      onSaveDraftDebounced,
      onDeleteRecDraft,
      onCreateRecDraft,
    }),
    [onCreateRecFromDraft, onSaveDraftDebounced, onDeleteRecDraft, onCreateRecDraft],
  );

  useImperativeHandle(ref, () => ({ ...mutualFields, draftRec: draftRecRef.current }), [
    mutualFields,
  ]);

  // const isDraftSaveable = useMemo(
  //   () => !!(repostRec?.id || targetPrompt?.id || list?.id),
  //   [repostRec?.id, targetPrompt?.id, list?.id],
  // );

  return {
    draftRec: draftRecRef.current,
    isSubmittingCreateRec:
      createRecFromRecDraftState.loading || startConversationWithRecState.loading,
    ...mutualFields,
  };
}

export function isDraftEmpty(draft?: BaseDraftRecFields | RecFormInputs | null) {
  return !draft || ['content', 'url', 'title'].every((key) => !draft[key]);
}

const REC_WITH_DRAFT_FRAGMENT = gql(/* GraphQL */ `
  fragment RecDraftAutoSave on DraftRec {
    id
    title
    url
    content
    contentLexical
    emoji
    createdAt
    updatedAt

    attachments {
      ...RecommendationAttachment
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecWithDraftForm on Rec {
    ...RecommendationItem
  }
`);

const UPDATE_REC_DRAFT = gql(/* GraphQL */ `
  mutation updateRecDraft($draftRecId: String!, $input: RecInput!) {
    updateRecDraft(draftRecId: $draftRecId, input: $input) {
      ...RecDraftAutoSave
    }
  }
`);

const CREATE_REC_DRAFT = gql(/* GraphQL */ `
  mutation createRecDraft {
    createRecDraft {
      ...RecDraftAutoSave
    }
  }
`);

const DELETE_REC_DRAFT = gql(/* GraphQL */ `
  mutation deleteRecDraft($draftRecId: String!) {
    deleteRecDraft(draftRecId: $draftRecId)
  }
`);

const CREATE_REC_FROM_REC_DRAFT = gql(/* GraphQL */ `
  mutation createRecFromRecDraft(
    $draftRecId: String!
    $repostRecId: String
    $listId: String
    $targetPromptId: String
    $extraValues: RecInput!
  ) {
    createRecFromRecDraft(
      draftRecId: $draftRecId
      repostRecId: $repostRecId
      listId: $listId
      targetPromptId: $targetPromptId
      extraValues: $extraValues
    ) {
      ...RecWithDraftForm
    }
  }
`);
