'use client';

import React from 'react';
import type { ImageProps as NextImageProps } from 'next/image';
import NextImage from 'shared/misc/components/image-wrapper';
import { chakra, Box, ImageProps, useBreakpointValue, BoxProps } from '@chakra-ui/react';

// TYLER: This was stolen from here: https://github.com/chakra-ui/chakra-ui/discussions/2475

// Properties which are forwarded to the underlying next/image component
const FORWARDED_PROPS = [
  'width',
  'height',
  'src',
  'alt',
  'quality',
  'placeholder',
  'blurDataURL',
  'loader',
  'priority',
  'fill',
] as const;

type ForwardedProps = (typeof FORWARDED_PROPS)[number];

// Width and height types which may contain Chakra's responsive array or object
type Width = NonNullable<ImageProps['width']>;
type Height = NonNullable<ImageProps['height']>;
// Width and height types containing only Chakra's responsive array or object
type OnlyResponsiveWidth = Exclude<Width, string | number>;
type OnlyResponsiveHeight = Exclude<Height, string | number>;

// Combine props by taking forwarded property types from next/image and rest
// from Chakra
type CombinedImageProps = Pick<NextImageProps, ForwardedProps> & Omit<ImageProps, ForwardedProps>;

// Replace width and height with Chakra's responsive width/height which is
// mandatory here due to next/image requirement
type ChakraImageProps = Omit<CombinedImageProps, 'width' | 'height'> & {
  width?: Width;
  height?: Height;
  imageProps?: ImageProps;
  boxProps?: BoxProps;
};

export const ChakraNextImage: React.FC<CombinedImageProps> = chakra(NextImage, {
  baseStyle: {},
  shouldForwardProp: (prop) => (FORWARDED_PROPS as ReadonlyArray<string>).includes(prop),
});

const PerfImage: React.FC<ChakraImageProps> = ({
  imageProps: extraImageProps,
  boxProps: extraBoxProps,
  ...props
}: ChakraImageProps) => {
  const { width, height } = props;

  const responsiveWidth = useBreakpointValue(getResponsive(width), { ssr: true });
  const responsiveHeight = useBreakpointValue(getResponsive(height), { ssr: true });

  // Split properties to those for Box and for those which are forwarded
  const boxProps = { ...props };
  const imageProps = { ...props };

  for (const prop of Object.keys(props) as Array<keyof typeof props>) {
    if ((FORWARDED_PROPS as ReadonlyArray<string>).includes(prop)) delete boxProps[prop];
    else delete imageProps[prop];
  }

  return (
    <Box {...boxProps} {...extraBoxProps}>
      <img
        {...imageProps}
        {...extraImageProps}
        width={isResponsive(width) ? responsiveWidth ?? width : width}
        height={isResponsive(height) ? responsiveHeight ?? height : height}
      />
    </Box>
  );
};

function isResponsive(
  val: Width | Height | undefined,
): val is OnlyResponsiveWidth | OnlyResponsiveHeight {
  return typeof val == 'object' && val != null;
}

function getResponsive(val: Width | Height | undefined) {
  return val && isResponsive(val) ? val : [];
}

export default PerfImage;
