import { TWLink } from '../link';

export default function NotFound() {
  return (
    <div className="flex justify-center p-6">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-[24px] justify-center">
          <h1 className="text-center typography-heading-xl mt-12 mb-0">😢</h1>
          <h1 className="text-center typography-heading-xl mt-0 mb-3">NOT FOUND</h1>
          <div>
            <p className="text-center typography-heading-md font-semibold">
              This page does not exist.
            </p>
          </div>
          <div className="flex justify-center gap-4">
            <TWLink
              to="/"
              className="no-underline typography-heading-sm border-color-main border-dashed border-b-[1px] font-semibold uppercase text-base"
            >
              Go Home
            </TWLink>
          </div>
        </div>
      </div>
    </div>
  );
}
