

import { useMutation } from '@apollo/client';
import { Box, Button, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useSearchParams } from 'shared/misc/hooks/routerHooks';
import { useAuth } from 'shared/misc/hooks/useAuth';
import CenterPage from 'shared/misc/components/CenterPage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendEmailCodeSchema, signInViaEmailCodeSchema } from 'shared/validation/schemas';
import PerfImage from 'shared/misc/components/util/PerfImage';
import PerfInput from 'shared/misc/components/util/PerfInput';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { userExistsWithEmail } from 'web2/app/actions/userExistsWithEmail';
import { gql } from 'shared/__generated__';
import { createFileRoute } from '@tanstack/react-router';

type InputsSendEmail = {
  email: string;
};

type InputsSignInWithCode = {
  code: string;
};

export const Route = createFileRoute('/sign-up/add-email/')({
  component: SignUpAddEmailComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Sign Up',
      },
    ],
  }),
});

function SignUpAddEmailComponent() {
  const [isEmailSignIn, setIsEmailSignIn] = useState(true);
  const [isCodeForm, setIsCodeForm] = useState(false);

  const { signIn } = useAuth();

  const toast = useToast();

  const [sendSignInEmail, { loading: sendSignInEmailLoading }] = useMutationWithAutoToast(
    SEND_VERIFY_EMAIL,
    {
      successMessage: 'Email sent.',
    },
  );

  const [verifyEmailViaCode, { loading: verifyEmailViaCodeLoading }] =
    useMutation(SIGN_IN_VIA_CODE);

  const router = useRouter();
  const { updateStep } = useUpdateSetupStep();

  const searchParams = useSearchParams();
  const destination = searchParams.get('destination');

  const loading = sendSignInEmailLoading || verifyEmailViaCodeLoading;

  const signInViaEmailCodeForm = useForm<InputsSignInWithCode>({
    resolver: yupResolver(signInViaEmailCodeSchema),
  });

  const sendSignInEmailCodeForm = useForm<InputsSendEmail>({
    resolver: yupResolver(sendEmailCodeSchema),
  });

  const email = sendSignInEmailCodeForm.watch().email;

  const sendEmail = async () => {
    await sendSignInEmail({
      variables: { email, destination },
      optimisticResponse: {
        sendVerifyEmail: true,
      },
    });

    setIsCodeForm(true);
  };

  const onEmailCodeSubmit: SubmitHandler<InputsSignInWithCode> = async (values, event) => {
    event?.preventDefault();

    await verifyEmailViaCode({
      variables: { code: values.code, resetPrimary: true },
      onCompleted: async () => {
        toast({
          title: 'Email verified...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        router.navigate({ to: '/' });
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const onSendEmailSubmit: SubmitHandler<InputsSendEmail> = async (values, event) => {
    event?.preventDefault();

    const emailExists = await userExistsWithEmail(values);

    if (emailExists) {
      sendSignInEmailCodeForm.setError('email', {
        message: 'Existing account with this email.',
      });
      return;
    }

    await sendEmail();
  };

  const onSubmit = (e, ...args) => {
    e.preventDefault();
    if (isCodeForm) {
      return signInViaEmailCodeForm.handleSubmit(onEmailCodeSubmit)(e, ...args);
    } else if (isEmailSignIn) {
      return sendSignInEmailCodeForm.handleSubmit(onSendEmailSubmit)(e, ...args);
    }
  };

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '50px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p="48px"
        >
          <form onSubmit={onSubmit}>
            <Stack spacing="24px" w="100%" maxW="300px">
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">ADD AN EMAIL</Text>
              <Stack spacing="12px">
                {!isCodeForm ? (
                  <PerfInput
                    key="email"
                    placeholder="Email"
                    {...sendSignInEmailCodeForm.register('email')}
                    error={sendSignInEmailCodeForm.formState.errors.email}
                  />
                ) : (
                  <PerfInput
                    key="code"
                    placeholder="Code"
                    {...signInViaEmailCodeForm.register('code')}
                    error={signInViaEmailCodeForm.formState.errors.code}
                  />
                )}
              </Stack>
              <Stack spacing="24px">
                <HStack spacing="6px">
                  <Button type="submit" variant="primary" isLoading={loading}>
                    Submit
                  </Button>
                  {!isCodeForm && (
                    <Button variant="secondary" onClick={() => setIsCodeForm(true)}>
                      Already Have a code?
                    </Button>
                  )}
                  {isCodeForm && (
                    <Button variant="secondary" onClick={() => setIsCodeForm(false)}>
                      Back to email?
                    </Button>
                  )}
                  {isCodeForm && (
                    <Button variant="secondary" onClick={() => sendEmail()}>
                      Resend
                    </Button>
                  )}
                </HStack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </CenterPage>
    </>
  );
}

const SEND_VERIFY_EMAIL = gql(/* GraphQL */ `
  mutation sendVerifyEmailToReplace($email: String!, $destination: String) {
    sendVerifyEmail(email: $email, destination: $destination)
  }
`);

const SIGN_IN_VIA_CODE = gql(/* GraphQL */ `
  mutation verifyEmailViaCodeToReplace($code: String!, $resetPrimary: Boolean) {
    verifyEmailViaCode(code: $code, resetPrimary: $resetPrimary) {
      token
      destination
    }
  }
`);
