import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { useQuery } from '@apollo/client';
import ListColorBlock from 'web2/app/components/by-type/lists/list-color-block';
import { gql } from 'shared/__generated__/gql';
import PerfSuspense from 'shared/misc/components/util/PerfSuspense';
import { useAuth } from 'shared/misc/hooks/useAuth';
import CreateOrUpdateListModal from 'web2/app/components/modals/create-or-update-list-modal';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { TWLink } from '../../../../components/link';
import { TWButton } from '../../../../components/button';
import NotFound from '../../../../components/not-found';

gql(/* GraphQL */ `
  fragment ProfileListsView on User {
    id
    email
    firstName
    lastName
    username
    listCount
    profileTheme
    lists(orderBy: "recentlyUpdated") {
      ...ListItemSmall
      ...ListColorBlock
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getProfileListsView($username: String!) {
    user(username: $username) {
      ...ProfileListsView
    }
  }
`);

export const Route = createFileRoute('/u/$username/lists/')({
  component: RouteComponent,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Lists',
      },
    ],
  }),
});

// !TODO: come back and test this page because the modal is broken
function RouteComponent() {
  const { username } = Route.useParams();
  const auth = useAuth();
  const createModalState = useDisclosure();

  const { loading, data } = useQuery(PageQuery, {
    variables: { username },
    fetchPolicy: 'cache-and-network',
  });

  const router = useRouter();

  const profile = data?.user;

  useAutoSetTheme(profile?.profileTheme || null);

  if (loading && !profile) {
    return <PerfSuspense />;
  }

  if (!profile) {
    return <NotFound />;
  }

  return (
    <div className="flex justify-center p-[24px] mb-[100px]">
      <div className="max-w-[800px] w-full">
        <div className="flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[12px] justify-center">
            <div className="flex flex-col gap-[12px] pb-[48px]">
              <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
                {profile.firstName || `@${profile.username}`}'s Lists
              </h1>
              {profile.id === auth.user?.id && (
                <>
                  <TWButton
                    variant="primary"
                    onClick={createModalState.onOpen}
                    className="self-center mt-[24px]"
                  >
                    Create a new list
                  </TWButton>
                  {createModalState.isOpen && (
                    <CreateOrUpdateListModal modalState={createModalState} />
                  )}
                </>
              )}
            </div>
            {profile.lists?.map((list) => (
              <TWLink
                to={`/list/${list.id}`}
                className="w-full border hover:border hover:border-brand-main border-dashed border-brand-main p-[12px]"
              >
                <ListColorBlock key={list.id} list={list} showUpdatedAt={false} />
              </TWLink>
            ))}
            {profile.lists.length === 0 && (
              <div className="flex w-full justify-center items-center">
                {profile.id === auth.user?.id ? (
                  <>
                    <p className="typography-heading-sm text-center">
                      You don't have any lists yet.
                    </p>
                  </>
                ) : (
                  <p className="typography-heading-sm text-center">
                    <TWLink to={`/u/${profile.username}`}>@{profile.username}</TWLink> hasn't
                    created any lists yet.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
