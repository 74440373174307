import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { gql } from '../../__generated__/gql';
import { USER_SIGN_UP_STEP } from '../../data/user';
import { useAuth } from './useAuth';
import { usePrefetch } from './usePrefetch';
import { useAnalytics } from '../providers/AnalyticsContext';
import { useRouter, useRouterState } from '@tanstack/react-router';

type Args = { preload?: (USER_SIGN_UP_STEP | null)[] };

export const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

export default function useUpdateSetupStep({ preload: preloadPaths = [] }: Args = {}) {
  const { user } = useAuth();
  const userRef = useRef(user);
  const [updateUserSignUpStepMutation] = useMutation(UPDATE_SIGN_UP_STEP);
  const router = useRouter();
  const { identifyUser } = useAnalytics();

  const currentPathname = usePathname();

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const updateStep = useCallback(
    async (signUpStep, nextPath?: string | null, skipNavigate = false) => {
      const currentUser = userRef.current;
      if (currentUser) {
        updateUserSignUpStepMutation({
          variables: {
            signUpStep,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateUserSignUpStep: {
              __typename: 'User',
              id: currentUser.id,
              signUpStep,
            },
          },
          onError: (e) => console.error(e),
        }).then(() => {
          // with each step, identify changes to user
          identifyUser(currentUser);
        });
      }

      if (skipNavigate) {
        return;
      }
      
      if (!signUpStep && currentPathname !== '/') {
        return router.navigate({
          to: nextPath || '/',
        });
      }

      const path = getPathForStep(signUpStep);

      if (currentPathname !== path && path) {
        return router.navigate({
          to: path,
        });
      }
    },
    [user, currentPathname],
  );

  return { updateStep };
}

export function getPathForStep(step: USER_SIGN_UP_STEP | string | null): string | null {
  switch (step) {
    case USER_SIGN_UP_STEP.VERIFY_ANOTHER:
      return '/verify-another-email';
      
    case USER_SIGN_UP_STEP.PHOTO:
      return '/sign-up/photo';

    case USER_SIGN_UP_STEP.VERIFY:
    case USER_SIGN_UP_STEP.VERIFY_COMPLETE:
    case USER_SIGN_UP_STEP.INITIAL:
    case USER_SIGN_UP_STEP.MORE:
    case USER_SIGN_UP_STEP.MORE_USERNAME:
    case USER_SIGN_UP_STEP.MORE_BIRTHDAY:
      return '/sign-up/more';

    case USER_SIGN_UP_STEP.LOCATION:
      return '/sign-up/location';
      
    case USER_SIGN_UP_STEP.SHARE:
      return '/sign-up/share';
      
    case USER_SIGN_UP_STEP.THEME:
      return '/sign-up/theme';

    case USER_SIGN_UP_STEP.PREMIUM:
    case USER_SIGN_UP_STEP.SUBSTACK:
      return '/sign-up/premium';

    case USER_SIGN_UP_STEP.INVITE_FRIENDS:
    case USER_SIGN_UP_STEP.ANSWER_ASKS:
    case USER_SIGN_UP_STEP.ADD_PASSWORD:
    case USER_SIGN_UP_STEP.ADD_EMAIL:
    case USER_SIGN_UP_STEP.ADD_RECS: // return '/sign-up/add-recs';
    case USER_SIGN_UP_STEP.FOLLOW:
    case USER_SIGN_UP_STEP.DONE: // return '/sign-up/done'; return '/sign-up/follow';
      // return '/sign-up/done';
      return '/home';

    case USER_SIGN_UP_STEP.LOADING:
      return '/sign-up/personalizing';

    case USER_SIGN_UP_STEP.SURVEY:
      return '/sign-up/survey';

    case USER_SIGN_UP_STEP.LIKE_RECS:
      return '/sign-up/like-recs';

    case USER_SIGN_UP_STEP.FEATURES: 
      return '/sign-up/features';

    case USER_SIGN_UP_STEP.CATEGORIES:
      return '/sign-up/categories';
      
    case USER_SIGN_UP_STEP.TRIAL:
      return '/sign-up/trial';
    }

  if (!step) {
    return '/home';
  }

  throw new Error('Invalid step.');
}

const UPDATE_SIGN_UP_STEP = gql(/* GraphQL */ `
  mutation updateUserSignUpStep($signUpStep: UserSignUpStep) {
    updateUserSignUpStep(signUpStep: $signUpStep) {
      id
      signUpStep
    }
  }
`);
