import { Box, Button, HStack, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import { gql } from 'shared/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import UserDirectoryItem from 'shared/misc/components/by-type/user/UserDirectoryItem';
import { Link, useRouter } from '@tanstack/react-router';

import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import ThemeAwareHeader from 'shared/misc/components/ThemeAwareHeader';
import ThemeAwareStar from '../--theme-aware-star';
import { UserFlagType } from '../../../../../../packages/shared/__generated__/graphql';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { copyToClipboard } from 'shared/misc/clipboard';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import AnswerablePromptItem from 'shared/misc/components/by-type/prompts/AnswerablePromptItem';
import { createFileRoute } from '@tanstack/react-router';

export const DoneViewQuery = gql(/* GraphQL */ `
  query getDoneViewQuery($userId: String!) {
    me {
      id
      invites {
        id
        code
        maxSignUpCount
        currentSignUpCount
        invited {
          id
          username
        }
        invitedCount
      }
      invite {
        user {
          id
          username
          followingConnection(first: 10) @connection(key: "topUsersOfWeekConnection") {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                targetUser {
                  ...UserDirectoryItem
                }
              }
            }
          }
        }
      }
    }

    promptConnection(first: 8, userId: $userId) {
      edges {
        node {
          ...AnswerablePromptItem
        }
      }
    }
  }
`);

export const Route = createFileRoute('/sign-up/done/')({
  component: DonePage,
});

function DonePage() {
  const auth = useAuth();

  const { data } = useSuspenseQuery(DoneViewQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
    variables: {
      // PI User Id
      userId: 'clnuqd6bg0000sv9a0zgqoev7',
    },
    notifyOnNetworkStatusChange: true,
  });

  const router = useRouter();

  useAutoSetTheme(auth?.user?.profileTheme || 'DEFAULT');

  const firstInvite = data.me?.invites?.[0];

  const signUpUrl = firstInvite ? `${BASE_SITE_URL}/subscribe/referral/${auth.user!.username}` : '';

  const personWhoInvited = data?.me?.invite?.user;

  const peopleYouMayKnow = [...(data?.me?.invite?.user?.followingConnection?.edges || [])].sort(
    (a, b) =>
      a.node.targetUser.flag.includes(UserFlagType.PerfectlyImperfect)
        ? new Date(a.node?.targetUser?.createdAt) - new Date(b.node?.targetUser?.createdAt)
        : 0,
  );

  const { trackEvent } = useAnalytics();
  const toast = useToast();

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal" overflowY="scroll">
        <Box
          m="10px"
          marginTop={{ base: '24px', md: '100px' }}
          marginBottom={{ base: '24px', md: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p={{ base: '24px', md: '48px' }}
        >
          <Stack spacing={6} w="100%" maxW={{ base: '400px', lg: '800px' }}>
            <Stack spacing={6} w="100%" maxW={{ base: '500px' }} alignSelf="center">
              <ThemeAwareStar />
              <ThemeAwareHeader>Your account is ready!</ThemeAwareHeader>
              <Text textStyle="brand.body" fontWeight="normal" fontStyle="italic">
                Just a few things before you dive in
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                <Text textStyle="brand.headingSm" as="span">
                  There's an app now...
                </Text>{' '}
                and it's better than the website in almost every way. If that sounds cool,{' '}
                <Link
                  className="text-brand-main underline"
                  variant="stylizedBasic"
                  to="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926"
                >
                  click here to download.
                </Link>
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                <Text textStyle="brand.headingSm" as="span">
                  Don't overthink it...
                </Text>{' '}
                recommendations don’t have to be precious or "cool" or interesting or anything
                special really. It's just a tiny piece of you that other people might also connect
                with. Plain and simple.
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                <Text textStyle="brand.headingSm" as="span">
                  PI.FYI is fun with friends...
                </Text>{' '}
                so we{' '}
                <Link className="text-brand-main underline" variant="stylizedBasic" to="/invites">
                  pre-loaded 10 invites
                </Link>{' '}
                to your account to bring some friends along. If at least 5 people join, you get{' '}
                <strong>a free month of PI.FYI pro.</strong>
              </Text>
              {peopleYouMayKnow && personWhoInvited && peopleYouMayKnow.length > 1 && (
                <Text textStyle="brand.body" fontWeight="normal">
                  <Text textStyle="brand.headingSm" as="span">
                    @{personWhoInvited.username.toUpperCase()}
                  </Text>{' '}
                  invited you, so we included a few people they follow below.
                </Text>
              )}
              <HStack spacing={3} alignSelf="center" mt="12px">
                {firstInvite ? (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      copyToClipboard(signUpUrl);
                      toast({
                        title: 'Copied invite link',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: 'top',
                      });
                      trackEvent('Click', 'Invite_Link', {
                        inviteCode: firstInvite?.code,
                      });
                    }}
                  >
                    COPY INVITE LINK
                  </Button>
                ) : null}
                <Button
                  onClick={async () => {
                    router.navigate({ to: '/' });
                  }}
                >
                  SKIP AND LET ME IN
                </Button>
              </HStack>
            </Stack>
            {data.promptConnection ? (
              <Stack spacing="24px" marginTop={{ base: '16px', md: '24px' }} overflowY="scroll">
                <Text textStyle="brand.headingSm" fontSize="16px" alignSelf="center">
                  Here are a few "Asks" you can reply to and start filling out your profile.
                </Text>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 2 }} width="100%" gap="12px">
                  {(data?.promptConnection?.edges || [])
                    .filter((edge) => !edge?.node?.title?.toLowerCase().includes('feedback'))
                    .slice(0, 6)
                    .map(({ node }) => {
                      return <AnswerablePromptItem prompt={node} />;
                    })}
                </SimpleGrid>
              </Stack>
            ) : null}
            {peopleYouMayKnow && peopleYouMayKnow.length > 1 ? (
              <Stack spacing="24px" marginTop={{ base: '16px', md: '24px' }} overflowY="scroll">
                <Text textStyle="brand.headingSm" fontSize="16px" alignSelf="center">
                  People you may know
                </Text>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 2 }} width="100%" gap="12px">
                  {peopleYouMayKnow.map(({ node: { targetUser } }) => {
                    return (
                      <UserDirectoryItem
                        recsWhereBadgeIs
                        user={targetUser}
                        key={targetUser.id}
                        showMutuals={false}
                      />
                    );
                  })}
                </SimpleGrid>
              </Stack>
            ) : null}
            <HStack spacing={3} alignSelf="center" mt="12px">
              <Button
                onClick={async () => {
                  router.navigate({ to: '/' });
                }}
              >
                FINISH SIGN UP
              </Button>
            </HStack>
          </Stack>
        </Box>
      </CenterPage>
    </>
  );
}
