import { useTheme } from '@chakra-ui/react';
import hexToRgb from 'hex-to-rgb';
import { useThemeColors } from './useThemeColors';

function contrast(hex) {
  const rgb = hexToRgb(hex);
  const o = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000,
  );

  return o <= 180 ? 'dark' : 'light';
}

export function useHighlightOrMainForContrast(bg?: string) {
  const themeColors = useThemeColors();

  const mainColor = themeColors.main;
  const background = bg || themeColors.background;

  const highlightColor = themeColors.highlight;

  const isLight = contrast(highlightColor) === 'light';

  if (background.toLowerCase() === '#ffffff' && highlightColor === '#0004ff') {
    return highlightColor;
  }

  return isLight ? highlightColor : mainColor;
}

export function useIsHighlightGoodContrast() {
  const themeColors = useThemeColors();

  const highlightColor = themeColors.highlight;

  const isLight = contrast(highlightColor) === 'light';

  return isLight;
}

export function useIsBackgroundLight(bg?: string) {
  const themeColors = useThemeColors();

  const backgroundColor = bg || themeColors.background;

  const isLight = contrast(backgroundColor) === 'light';

  return isLight;
}
