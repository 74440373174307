import React, { ReactNode, useMemo } from 'react';
import { useRouterState } from '@tanstack/react-router';
import Sidebar from 'shared/misc/components/sidebar/Sidebar';
import Nav from '../../components/nav';
import { useAuth } from 'shared/misc/hooks/useAuth';
import ErrorBoundary from 'shared/misc/wrappers/ErrorBoundary';
import { isInWebView } from 'shared/webview';

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

type Props = { children: ReactNode };

const SPECIAL_PATHNAMES = [
  '/auth',
  // '/sign-in',
  '/sign-up',
  '/sign-up/',
  '/unlocked-archive',
  '/landing',
  '/random-feature',
  '/today',
  '/app-store',
  '/t/',
  '/activate',
  '/welcome/',
  '/invite-for',
  '/mobile',
  '/settings/premium',
  '/what-is-this',
  '/careers',
  '/spotlight-application',
  '/stripe',
  '/privacy',
  '/terms',
  '/cookies',
  '/maintenance',
  '/verify-another-email',
  '/unsubscribe',
  '/referral',
];

export default function WithSidebar({ children }: Props) {
  const pathname = usePathname();
  const auth = useAuth();

  const IS_IN_WEB_VIEW = isInWebView();

  const shouldRenderSidebar = useMemo(() => {
    return !IS_IN_WEB_VIEW && SPECIAL_PATHNAMES.every((p) => !pathname.includes(p));
  }, [pathname]);

  const wrappedChildren = <ErrorBoundary>{children}</ErrorBoundary>;

  return shouldRenderSidebar ? <Nav>{wrappedChildren}</Nav> : wrappedChildren;
}
