import React, { useState, useEffect } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import { SURVEY_STEPS, Answer } from 'shared/data/signup';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'web2/app/components/button';

export const Route = createFileRoute('/sign-up/survey/')({
  component: SignUpSurveyPage,
});

function SignUpSurveyPage() {
  const { updateStep } = useUpdateSetupStep();
  const { setNavigation } = useSignUpNavigation();
  useAutoSetTheme(ProfileThemeType.PiClassic);
  const [answers, setAnswers] = useState<Record<number, Answer>>({});
  const [selectedAnswer, setSelectedAnswer] = useState<Answer>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const currentStepData = SURVEY_STEPS[currentStep];
  const [displayText, skipToEndOfTypewriter, isTyping] = useTypewriter(
    currentStepData?.question,
    currentStep === 0 ? 800 : 30,
    currentStep === 0 ? 'paragraph' : 'character',
  );

  const { user } = useAuth();
  const { trackEvent } = useAnalytics();

  const handleSelect = async (answer: Answer) => {
    setSelectedAnswer(answer);
    await trackEvent('Click', 'Question_Answered', {
      userId: user?.id,
      answer: answer,
      question: currentStepData.question,
    });
    setTimeout(() => {
      onNext();
      setSelectedAnswer(null);
    }, 0);
  };

  const handleDone = () => {
    updateStep(USER_SIGN_UP_STEP.LIKE_RECS);
  };

  const getMinHeight = () => {
    switch (currentStep) {
      case 2:
      case 4:
      case 6:
        return 168;
      default:
        return 270;
    }
  };

  const onNext = () => {
    if (!currentStepData.isInfoScreen && selectedAnswer) {
      setAnswers((prev) => ({
        ...prev,
        [currentStepData.questionKey ?? currentStep]: selectedAnswer,
      }));
      const nextStep =
        currentStepData.nextStep?.[selectedAnswer as keyof typeof currentStepData.nextStep] ??
        currentStepData.nextStep?.default;
      if (nextStep !== undefined) {
        setCurrentStep(nextStep);
      } else {
        updateStep(USER_SIGN_UP_STEP.LIKE_RECS);
      }
    } else if (currentStepData.isInfoScreen) {
      if (currentStep < SURVEY_STEPS.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        handleDone();
      }
    } else {
      setAnswers((prev) => ({ ...prev, [currentStepData.questionKey ?? currentStep]: 'SKIP' }));
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    setNavigation({
      showLeftArrow: currentStep > 1,
      showRightArrow: currentStep > 0 && !currentStepData.isInfoScreen,
      onLeftPress: () => {
        setSelectedAnswer(null);
        if (currentStep > 0) {
          setCurrentStep(currentStep - 1);
        } else {
          updateStep(USER_SIGN_UP_STEP.THEME);
        }
      },
      onRightPress: onNext,
      rightArrowColor: selectedAnswer ? '#ffbb00' : '#FFFFFF',
      rightArrowDisabled: false,
      leftArrowDisabled: false,
      yPosition: currentStepData.isInfoScreen ? getMinHeight() : 263,
      onSkip: async () => {
        await trackEvent('Click', 'Step_Skipped', {
          userId: user?.id,
          question: currentStepData.question,
        });
        handleDone();
      },
      showSkip: true,
      signupStep: USER_SIGN_UP_STEP.SURVEY,
    });

    return () => {
      setNavigation({
        rightArrowDisabled: false,
        leftArrowDisabled: false,
        showLeftArrow: false,
        showRightArrow: false,
        signupStep: USER_SIGN_UP_STEP.SURVEY,
      });
    };
  }, [setNavigation, currentStep, selectedAnswer, currentStepData]);

  return (
    <div className="min-h-[calc(100dvh-236px)] flex flex-col items-start">
      {isTyping && currentStep > 0 && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0 z-100"
          onClick={() => {
            skipToEndOfTypewriter();
          }}
          style={{ opacity: 1 }}
        />
      )}
      <div className="min-h-[50px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start whitespace-pre-line">
        {displayText}
      </div>
      {!isTyping && currentStepData.isInfoScreen ? (
        <Button
          className="pl-0 text-[20px] normal-case z-[100]"
          variant="textOnlyHighlight"
          onClick={async () => {
            await trackEvent('Click', 'Continue', {
              userId: user?.id,
              question: currentStepData.question,
            });
            if (currentStep < SURVEY_STEPS.length - 1) {
              setCurrentStep(currentStep + 1);
            } else {
              handleDone();
            }
          }}
        >
          Tap to continue
        </Button>
      ) : (
        !isTyping &&
        !currentStepData.isInfoScreen && (
          <div className="flex flex-col gap-4 my-[52px] z-[100]">
            <Button
              variant="textOnly"
              className="pl-0 variant-textOnly text-16 sm:text-18 md:text-18 font-normal opacity-100 text-left text-none inline"
              onClick={async () => {
                await handleSelect('yes');
              }}
            >
              Yes
            </Button>
            <Button
              variant="textOnly"
              className="pl-0 variant-textOnly text-16 sm:text-18 md:text-18 font-normal opacity-100 text-left text-none inline"
              onClick={async () => {
                await handleSelect('no');
              }}
            >
              No
            </Button>
            <Button
              variant="textOnly"
              className="pl-0 variant-textOnly text-16 sm:text-18 md:text-18 font-normal opacity-100 text-left text-none inline"
              onClick={async () => {
                await handleSelect('idk');
              }}
            >
              I don't know
            </Button>
          </div>
        )
      )}
    </div>
  );
}
