import React, { useCallback } from 'react';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { RiMoreFill } from 'react-icons/ri';
import { Link } from '@chakra-ui/next-js';
import {
  DELETE_PROMPT_MUTATION,
  MUTE_PROMPT_MUTATION,
  UNMUTE_PROMPT_MUTATION,
} from '../../../../graphql/PromptMutations';
import { copyToClipboard } from '../../../../clipboard';
import { BASE_SITE_URL } from '../../../../../siteconfig';
import { useListContext } from '../../../../providers/ListContext';
import useMutationWithAutoToast from '../../../../hooks/useMutationWithAutoToast';
import UpdatePromptModal from '../../../modals/UpdatePromptModal';
import { useAuth } from '../../../../hooks/useAuth';
import { PromptItemFragment, PromptType } from '../../../../../__generated__/graphql';
import { useAnalytics } from '../../../../providers/AnalyticsContext';

type Props = {
  withLessPadding?: boolean;
  withBorder?: boolean;
  isInline?: boolean;
  prompt: PromptItemFragment;
};

function PromptActions({ prompt, withLessPadding, isInline, withBorder }: Props) {
  const { user } = useAuth();
  const { list } = useListContext();
  const { trackEvent } = useAnalytics();
  const toast = useToast();

  const onClickCopyLink = useCallback(() => {
    copyToClipboard(`${BASE_SITE_URL}/ask/${prompt.id}`);
    trackEvent('Click', 'Copy_Ask_Link', {
      askId: prompt.id,
      endorsementCount: prompt.endorsementCount,
      isEndorsed: prompt.isEndorsed,
      recsCount: prompt.recsCount,
      link: `${BASE_SITE_URL}/ask/${prompt.id}`,
    });
    toast({
      title: 'Copied link to ask.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, []);

  const [deletePrompt] = useMutationWithAutoToast(DELETE_PROMPT_MUTATION);

  const onDeletePrompt = useCallback(async () => {
    await deletePrompt({
      variables: { promptId: prompt.id },
      refetchQueries: ['getListView', 'getProfileLowerRecommendations', 'getHomeViewContainer'],
      optimisticResponse: {
        deletePrompt: true,
      },
      update(cache) {
        try {
          const normalizedId = cache.identify({ id: prompt.id, __typename: 'Prompt' });
          cache.evict({ id: normalizedId });
          cache.gc();
        } catch (e) {}
      },
    });
  }, [deletePrompt, list, prompt]);

  const [onMute] = useMutation(MUTE_PROMPT_MUTATION, {
    variables: { promptId: prompt.id },
    optimisticResponse: {
      mutePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        muted: true,
      },
    },
  });

  const [onUnmute] = useMutation(UNMUTE_PROMPT_MUTATION, {
    variables: { promptId: prompt.id },
    optimisticResponse: {
      unmutePrompt: {
        __typename: 'Prompt',
        id: prompt.id,
        muted: false,
      },
    },
  });

  const padding = isInline ? '12px' : '24px';

  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      p={{
        base: withBorder ? padding : '12px',
        md: withLessPadding ? (withBorder ? padding : '12px') : padding,
      }}
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<RiMoreFill />}
          variant="icon"
          height="fill-content"
        />
        <MenuList zIndex="100">
          {(user?.id === prompt.user?.id ||
            (prompt.type === PromptType.UnpublishedPostPrompt && user?.role === 'ADMIN')) && (
            <PromptEdit prompt={prompt} />
          )}

          <MenuItem closeOnSelect={false} onClick={onClickCopyLink}>
            Copy Link
          </MenuItem>
          {user?.id === prompt.user?.id && (
            <MenuItem
              closeOnSelect
              onClick={() => {
                trackEvent('Click', 'Delete_Ask_Dialog', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
                const confirmed = confirm('Delete ask?');
                if (confirmed) onDeletePrompt();
                trackEvent('Click', 'Delete_Ask', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
              }}
            >
              Delete
            </MenuItem>
          )}
          {user && (
            <MenuItem
              closeOnSelect={false}
              onClick={() => {
                trackEvent('Click', prompt.muted ? 'Unmute_Ask' : 'Mute_Ask', {
                  askId: prompt.id,
                  endorsementCount: prompt.endorsementCount,
                  isEndorsed: prompt.isEndorsed,
                  recsCount: prompt.recsCount,
                });
                !!prompt.muted ? onUnmute() : onMute();
              }}
            >
              {prompt.muted ? 'Unmute Notifications' : 'Mute Notifications'}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}

function PromptEdit({ prompt }: Props) {
  const modalState = useDisclosure();
  const { trackEvent } = useAnalytics();

  const toggleModal = useCallback(() => {
    const beforeModalState = modalState.isOpen;
    modalState.onOpen();
    trackEvent('Click', `${!beforeModalState ? 'Open' : 'Close'}_Edit_Ask_Modal`, {
      askId: prompt.id,
      endorsementCount: prompt.endorsementCount,
      isEndorsed: prompt.isEndorsed,
      recsCount: prompt.recsCount,
    });
  }, [modalState]);

  return (
    <>
      <MenuItem closeOnSelect={false} onClick={toggleModal}>
        Edit ask
      </MenuItem>
      {modalState.isOpen && (
        <Portal>
          <UpdatePromptModal modalState={modalState} prompt={prompt} />
        </Portal>
      )}
    </>
  );
}

export default PromptActions;
