import { createFileRoute } from '@tanstack/react-router';
import { useToast } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { signUpEmailOnlySchema } from 'shared/validation/schemas';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useRouter } from '@tanstack/react-router';
import { useSearchParams } from 'shared/misc/hooks/routerHooks';
import { SIGN_UP_EMAIL_ONLY_STEP_1 } from 'shared/misc/graphql/SignUpFragments';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';

export const Route = createFileRoute('/sign-up/')({
  component: SignUpPage,
});

interface Inputs {
  email: string;
  code?: string;
}

function SignUpPage() {
  const [signUpStep1Mutation] = useMutation(SIGN_UP_EMAIL_ONLY_STEP_1);
  const { signIn, ...auth } = useAuth();
  const router = useRouter();
  const toast = useToast();
  const { setNavigation } = useSignUpNavigation();
  const searchParams = useSearchParams();
  const inviteCode = searchParams.get('code');

  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.MORE] });

  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpEmailOnlySchema),
    defaultValues: {
      email: auth.user?.email || '',
      code: '',
    },
    shouldUnregister: false,
  });

  const values = watch();

  const onSubmit: SubmitHandler<Inputs> = async (input) => {
    await signUpStep1Mutation({
      variables: {
        input: { email: input.email },
        ...(inviteCode ? { inviteCode } : {}),
      },
      onCompleted: async (data) => {
        await trackEvent('Click', 'Email_Entered', {
          email: input.email,
          inviteCode: inviteCode,
        });
        const token = data?.signUpEmailOnly;
        if (token) {
          await signIn(token);
          toast({ title: 'Signed up successfully', status: 'success' });
          await updateStep(USER_SIGN_UP_STEP.MORE);
        } else {
          setError('email', { message: 'Failed to sign up' });
        }
      },
      onError: (e) => {
        setError('email', { message: e.message });
      },
    });
  };

  useEffect(() => {
    setNavigation({
      rightArrowDisabled: !Boolean(values.email.length),
      leftArrowDisabled: true,
      showRightArrow: true,
      showLeftArrow: false,
      onRightPress: handleSubmit(onSubmit),
      yPosition: 225,
      signupStep: USER_SIGN_UP_STEP.INITIAL,
    });
  }, [setNavigation, values.email]);

  useEffect(() => {
    trackEvent('View', 'Splash_Viewed', {
      platform: 'web',
    });
  }, []);

  const [displayText] = useTypewriter("What's your email?", 50);

  return (
    <div className="min-h-full flex flex-col">
      <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case">
        {displayText}
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        By continuing, you agree to Perfectly Imperfect's{' '}
        <a
          className="underline"
          href="https://pi.fyi/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          className="underline"
          href="https://pi.fyi/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <button className="hidden" type="submit">
          submit
        </button>
        <div className="mt-12 space-y-4 flex flex-col items-center">
          <PerfInput
            order={1}
            display="block"
            placeholder="Email"
            variant="underline"
            {...register('email', { required: true })}
            error={errors.email}
            formStyle={{ order: 1, display: 'block' }}
            textStyle={{ base: 'brand.formBody' }}
            marginBottom="20px"
            autoFocus
          />
        </div>
      </form>
      <div className="text-center mt-5 fixed bottom-6 left-0 right-0">
        <button
          className="text-none cursor-pointer text-16 opacity-50 hover:opacity-100"
          onClick={() => {
            router.navigate({ to: '/sign-in' });
          }}
        >
          Already have an account? Sign in.
        </button>
      </div>
    </div>
  );
}
