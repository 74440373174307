import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuth } from 'shared/misc/hooks/useAuth';

export const Route = createFileRoute('/sign-out/')({
  component: RouteComponent,
});

function RouteComponent() {
  const auth = useAuth();
  const router = useRouter();
  useEffect(() => {
    const signOut = async () => {
      await auth.signOut();
      router.navigate({ to: '/welcome' });
    };
    signOut();
  }, []);

  return null;
}
