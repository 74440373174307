import React, { useRef, useEffect } from 'react';
import { Box, Button, Flex, HStack, UseDisclosureReturn, useToast } from '@chakra-ui/react';

import { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '../ui/modal';

import _ from 'lodash';
import usePrevious from 'use-previous';
import { useMutation } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import usePromptForm, {
  PromptFormHandle,
  PromptFormInputs,
} from '../by-type/prompts/use-prompt-form';
import { gql } from 'shared/__generated__/gql';
import { processLexicalState } from 'shared/lexical';
import { isInWebView, safeSendMessageToReactNativeFromWebView } from 'shared/webview';
import { AddPromptMessages } from 'shared/webview/messages';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { Button as TWButton } from '../button';

type Props = {
  modalState?: UseDisclosureReturn;
};

export default function AddPromptModal({ modalState: modalStateOveride }: Props) {
  const IS_IN_WEB_VIEW = isInWebView();

  const { isOpen, onClose, onOpen } = modalStateOveride || useModalState(ModalNames.ADD_ASK);

  const formRef = useRef<PromptFormHandle>(null);

  const toast = useToast();

  const { trackEvent } = useAnalytics();

  const router = useRouter();

  const prevIsOpen = usePrevious(modalStateOveride?.isOpen ? false : isOpen);

  const [createPrompt, createPromptState] = useMutation(CREATE_PROMPT, {
    fetchPolicy: 'network-only',
    refetchQueries: ['getProfileView', 'getHomeview'],
  });

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      trackEvent('Post', 'Ask_Started');
    }
  }, [isOpen, prevIsOpen]);

  const onCreatePrompt = async (values: PromptFormInputs) => {
    const { titleLexicalState, contentLexicalState = null, emoji = '' } = values;

    const { text: content, json: contentLexical } = processLexicalState(contentLexicalState);
    const { text: title } = processLexicalState(titleLexicalState);

    createPrompt({
      variables: {
        input: {
          title,
          emoji,
          content,
          contentLexical,
        },
      },
      onCompleted: async ({ createPrompt }) => {
        toast({
          title: 'Ask shared.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
        if (IS_IN_WEB_VIEW) {
          safeSendMessageToReactNativeFromWebView(
            AddPromptMessages.SUCCESSFULLY_CREATED_ASK,
            createPrompt.id,
          );
        } else {
          router.navigate({
            to: `/ask/$promptId`,
            params: { promptId: createPrompt.id },
          });
        }
        onClose();
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  const { onSubmit, mainFormNode } = usePromptForm({
    onSubmit: onCreatePrompt,
    ref: formRef,
    isSubmitting: createPromptState.loading,
    editorHeight: '320px',
  });

  if (!isOpen) return null;
  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onClose())}>
      <ModalContent
        className={`${IS_IN_WEB_VIEW ? 'border-0 pt-[48px] md:pt-[72px] lg:pt-[124px]' : ''}`}
        dontRenderOverlay={IS_IN_WEB_VIEW}
        hideCloseButton={IS_IN_WEB_VIEW}
      >
        <ModalHeader className="hidden">
          <ModalTitle>Ask for Recommendations</ModalTitle>
        </ModalHeader>
        <div className="p-0 m-0 6px mb-[12px] md:mb-[24px] font-size-[16px] overflow-y-scroll">
          {mainFormNode}
        </div>
        <ModalFooter
          className={`${
            IS_IN_WEB_VIEW ? 'absolute top-0 left-0 w-full bg-brand-background p-[12px] md:p-[24px] lg:p-[48px]' : ''
          }`}
        >
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            {IS_IN_WEB_VIEW ? (
              <Button
                size="sm"
                onClick={async () => {
                  onClose();
                }}
                variant="textOnly"
                color="brand.lightgrey"
                _hover={{ color: 'brand.red' }}
                border="none"
                textStyle="brand.actionSm"
              >
                Cancel
              </Button>
            ) : (
              <Box />
            )}
            <HStack spacing="12px">
              {!IS_IN_WEB_VIEW && (
                <TWButton
                  size="sm"
                  onClick={onClose}
                  variant="textOnly"
                  className="text-[13.5px] typography-action-sm text-brand-lightgrey hover:text-brand-red border-none"
                >
                  Close and delete
                </TWButton>
              )}
              <TWButton
                onClick={onSubmit}
                variant="primary"
                className="text-[13.5px] typography-action-sm"
                isLoading={createPromptState.loading}
                isDisabled={createPromptState.loading}
              >
                SUBMIT ASK
              </TWButton>
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const CREATE_PROMPT = gql(/* GraphQL */ `
  mutation createPrompt($input: CreatePromptInput!) {
    createPrompt(input: $input) {
      id
    }
  }
`);
