import { Link as BasicLink, Box, SimpleGrid, Stack } from '@chakra-ui/react';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { gql } from 'shared/__generated__/gql';
import { useBackButton } from 'shared/misc/hooks/useBackButton';
import PromptItem from '../../components/by-type/prompts/prompt-item';
import { InView } from 'react-intersection-observer';
import { ModalNames, useModalState } from 'shared/misc/wrappers/ModalProvider';
import { useAuth } from 'shared/misc/hooks/useAuth';
import Heading from 'shared/misc/components/heading';
import { createFileRoute } from '@tanstack/react-router';
import { Button as TWButton } from '../../components/button';

const AllAsksViewQuery = gql(/* GraphQL */ `
  query getAllAsksView($first: Int, $after: String) {
    promptConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PromptItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 25;

export const Route = createFileRoute('/asks/')({
  component: AllAsksView,
  head: () => ({
    meta: [
      {
        title: 'PI.FYI | Asks',
      },
    ],
  }),
});

function AllAsksView() {
  const { loading, data, fetchMore } = useQuery(AllAsksViewQuery, {
    variables: { first: PAGE_LENGTH },
    fetchPolicy: 'cache-and-network',
  });

  const { getCanGoBack, goBack } = useBackButton();

  const getNextPage = async () => {
    if (loading || !data?.promptConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.promptConnection.pageInfo.endCursor,
      },
    });
  };

  const addAskModalState = useModalState(ModalNames.ADD_ASK);
  const auth = useAuth();
  const router = useRouter();

  const prompts = data?.promptConnection?.edges || [];

  if (!prompts) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack
        spacing="24px"
        width={{ base: '100%', md: '1000px' }}
        className="w-100 md:w-[1000px]"
        // width="100%"
      >
        <Stack className="flex flex-col items-center ">
          <h1 className="uppercase typography-heading-xl text-brand-highlight text-center mt-[48px]">
            People want...
          </h1>
          <TWButton
            variant="primary"
            className="text-[20px] mb-[24px] mt-[12px]"
            onClick={() =>
              auth.user ? addAskModalState.onOpen() : router.navigate({ to: '/sign-in' })
            }
          >
            Ask for recommendations?
          </TWButton>
          <div className="grid grid-cols-1 gap-[24px] md:grid-cols-2 justify-center">
            {prompts?.map(({ node }) => (
              <PromptItem key={node.id} prompt={node} />
            ))}
          </div>
          {prompts.length > 0 && (
            <>
              <InView
                as="div"
                rootMargin="2000px 0px"
                onChange={(inView) => {
                  if (inView) {
                    getNextPage();
                  }
                }}
              />
              {loading && <Spinner size="lg" placement="center" />}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
