import { createFileRoute } from '@tanstack/react-router';

import { Metadata } from 'next';
import { RecCategory } from 'shared/__generated__/graphql';
import CategoryView from './-category-view';

export const Route = createFileRoute('/categories/$name/')({
  component: CategoryViewPage,
});

export default function CategoryViewPage() {
  const params = Route.useParams();

  return <CategoryView category={params.name} />;
}
