import Spinner from 'web2/app/components/spinner';
import { Modal, ModalContent, ModalHeader, ModalFooter } from '../ui/modal';
import { Button } from '../button';
import React, { useCallback, useRef, useState } from 'react';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import UploadProfilePhotos, {
  Handle,
  UPLOAD_STATE,
  UPLOAD_STEP,
} from 'shared/misc/components/by-type/files/UploadProfilePhotos';
import { useUploadProfilePhotos, CssInjector } from 'shared/misc/hooks/useUploadProfilePhoto';

export default function UpdateProfilePhotosModal() {
  const { isOpen, onOpen, onClose } = useModalState(ModalNames.UPDATE_PROFILE_PHOTO);

  const {
    state: { isCropping, isError, isUploading, isDone },
    reset,
    uploadPhotos,
    cropComponent,
    dropzoneProps,
  } = useUploadProfilePhotos();

  const onFinish = async () => {
    await uploadPhotos();
    onClose();
  };

  return (
    <>
      <CssInjector />
      <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onClose())}>
        <ModalContent className="w-[400px] max-w-[90%]">
          <ModalHeader className="opacity-100 typography-heading-lg mb-[24px]">
            {isCropping ? 'Crop for your avatar' : isDone ? 'Done!' : "Let's add a photo."}
          </ModalHeader>
          <div className="text-center overflow-y-scroll pb-[24px] pt-[24px] min-h-[200px] flex items-center justify-center border border-dashed border-brand-main m spacing-[24px] w-full relative">
            {isCropping ? (
              <div className="w-[calc(100%-16px)] flex justify-center items-center justify-self-center position-relative max-w-[361px] margin-[32px_auto_0_md:0_auto]">
                {isUploading && (
                  <>
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-[999999999998]" />
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[999999999999]">
                      <Spinner />
                    </div>
                  </>
                )}
                <div
                  style={{
                    width: '100%',
                    maxWidth: '361px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    margin: 'auto',
                  }}
                >
                  {isCropping && cropComponent}
                </div>
              </div>
            ) : !isDone ? (
              <div {...dropzoneProps.getRootProps()} className="w-full h-full">
                <input {...dropzoneProps.getInputProps()} />
                <Button variant="primaryBlackText" className="w-full h-full">
                  CHOOSE A PHOTO
                </Button>
              </div>
            ) : null}
          </div>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            {isError && (
              <Button
                variant="secondary"
                onClick={reset}
                isLoading={isUploading}
                isDisabled={isUploading}
              >
                Try again
              </Button>
            )}
            {isCropping && !isDone && (
              <>
                <Button variant="secondary" onClick={reset} isDisabled={isUploading}>
                  Change photo?
                </Button>
                <Button onClick={onFinish} isDisabled={isUploading}>
                  {!isCropping ? 'Confirm' : isUploading ? 'Uploading...' : 'Finish'}
                </Button>
              </>
            )}

            {isDone && (
              <Button
                variant="primary"
                onClick={onClose}
                disabled={isUploading}
                isLoading={isUploading}
              >
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
