import {
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  TextProps,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from '@chakra-ui/next-js';
import { gql } from '../../../../__generated__';
import BasicEditor from '../../rich-text/basic/BasicEditor';
import ExpandableTextContainer from '../text/ExpandableTextContainer';
import { recBodyTheme } from '../../../theme/selectors/lexicalStyles';
import { PromptItemFragment } from '../../../../__generated__/graphql';
import PromptActions from './parts/PromptActions';
import PromptItemBottom from './parts/PromptItemBottom';
import OverlapTitle from 'shared/misc/components/OverlapTitle';
import { cn } from '../../../../../../apps/web2/app/utils/cn';
import { TWLink } from '../../../../../../apps/web2/app/components/link';

gql(/* GraphQL */ `
  fragment PromptItem on Prompt {
    id
    title
    date
    type
    content
    contentLexical
    emoji
    createdAt
    updatedAt
    isHidden
    muted
    recsCount
    isViewerSubscribed
    isEndorsed
    endorsementCount
    user {
      ...UserCore
    }
  }
`);

export type PromptItemProps = {
  prompt: PromptItemFragment;
  withBorder?: boolean;
  noOfLines?: number;
  withResponses?: boolean;
  withLessPadding?: boolean;
  mode?: PromptItemMode;
  overlapTitle?: string;
  verticallyCentered?: boolean;
  withSpecialBorder?: boolean;
  isInline?: boolean;
  titleFontSize?: TextProps['fontSize'];
};

export enum PromptItemMode {
  DEFAULT,
  FULL_VIEW,
}

export default function PromptItem(props: PromptItemProps) {
  const actionsMenuState = useDisclosure();

  const {
    prompt,
    withBorder = true,
    withResponses = true,
    noOfLines = props.isInline ? 1 : 7,
    withLessPadding = false,
    mode = PromptItemMode.DEFAULT,
    overlapTitle,
    verticallyCentered = false,
    withSpecialBorder = false,
    isInline = false,
    titleFontSize,
  } = props;

  const textAlign = isInline ? 'left' : 'center';

  const gap = isInline ? '12px' : '24px';

  let padding: any = {
    base: withBorder ? '12px' : '12px',
    md: withLessPadding ? (withBorder ? '12px' : '12px') : '12px',
  };

  let paddingClass = 'p-[12px]';

  if (isInline) {
    paddingClass = 'p-[12px] pl-[64px] sm:pl-[82px] md:pl-[102px]';
    padding = {
      base: '12px 12px 12px 64px',
      sm: '12px 12px 12px 82px',
      md: '12px 12px 12px 102px',
    };
  }

  const promptEmojiNode = isInline ? (
    <div className="typography-body-xl relative">
      {prompt?.emoji}
      <div className="absolute right-0 top-[24px] sm:top-[32px] typography-heading-lg">?</div>
    </div>
  ) : (
    <>? {prompt?.emoji} ?</>
  );

  const alignment = isInline ? 'flex-start' : 'center';

  return (
    <div
      // {...(withBorder
      //   ? { border: '1px dashed', borderColor: withSpecialBorder ? 'brand.ronald' : 'brand.main' }
      //   : {})}
      // backgroundColor="brand.background"
      // width="100%"
      // minHeight={isInline ? undefined : '200px'}
      // position="relative"
      // display="flex"
      // justifyContent="space-between"
      // flexDirection="column"
      // h="100%"
      className={cn(
        'bg-theme-background w-full relative flex flex-col justify-between h-full',
        !isInline ? 'min-h-[200px]' : '',
        withBorder ? 'border-[1px] border-dashed border-brand-main' : '',
      )}
      onMouseEnter={actionsMenuState.onOpen}
      onMouseLeave={actionsMenuState.onClose}
    >
      <div
        // display="flex"
        // mt={isInline ? '0' : '12px'}
        // p={padding}
        // width="100%"
        // height="100%"
        // flex={1}
        // alignSelf={alignment}
        // justifyContent={alignment}
        // {...(verticallyCentered ? { alignItems: 'center' } : {})}
        // position={isInline ? 'relative' : undefined}
        className={cn(
          'flex mt-[12px] p-12 w-full h-full flex-1 align-self-center justify-center',
          verticallyCentered ? 'items-center' : '',
          isInline ? 'relative mt-0 justify-start' : 'justify-center',
          paddingClass,
        )}
      >
        {overlapTitle && <OverlapTitle>{overlapTitle}</OverlapTitle>}
        <div
          // spacing={gap}
          // mt={isInline ? '0' : '12px'}
          // p={!isInline ? padding : undefined}
          // width={isInline ? 'calc(100% - 46px)' : '100%'}
          className={cn(
            'flex flex-col',
            isInline ? 'w-[calc(100% - 46px)] gap-[12px]' : 'w-full mt-[12px] p-[12px] gap-[24px]',
          )}
        >
          {(mode === PromptItemMode.DEFAULT || mode === PromptItemMode.FULL_VIEW) && (
            <div
              // color={withSpecialBorder ? 'brand.ronald' : 'brand.main'}
              // left={isInline ? ['12px', '14px', '18px'] : undefined}
              // top={isInline ? '50%' : undefined}
              // transform={isInline ? 'translateY(-50%)' : undefined}
              // position={isInline ? 'absolute' : undefined}
              // fontSize={!isInline ? ['22px', '36px'] : ['18px', '24px']}
              // textStyle="brand.headingLg"
              // alignSelf="center"
              className={cn(
                'typography-heading-lg',
                !isInline ? 'text-[22px] sm:text-[36px]' : 'text-[18px] sm:text-[24px]',
                withSpecialBorder ? 'text-brand-ronald' : 'text-brand-main',
                isInline ? 'left-[12px] sm:left-[14px] md:left-[18px]' : '',
                isInline ? 'top-[50%]' : '',
                isInline ? 'translate-y-[-50%]' : '',
                isInline ? 'absolute' : '',
                'flex justify-center items-center',
              )}
            >
              {promptEmojiNode}
            </div>
          )}
          <div
            //  spacing="12px" alignSelf={textAlign}  width="fit-content"
            className={cn(
              'flex flex-row gap-[12px] w-fit items-center',
              isInline ? 'text-left' : 'text-center self-center',
            )}
          >
            <TWLink
              // textStyle="brand.headingLg"
              // as={Link}
              href={`/ask/${prompt.id}`}
              // variant="stylizedNoDefaultBorder"
              // width="fit-content"
              // maxWidth="100%"
              // wordBreak="break-word"
              // fontSize={
              //   titleFontSize ||
              //   (mode === PromptItemMode.FULL_VIEW || true ? ['22px', '36px'] : ['16px', '24px'])
              // }
              // noOfLines={isInline ? 1 : prompt.content ? 3 : 4}
              // textAlign={textAlign}
              className={cn(
                'typography-heading-lg break-words w-fit max-w-full',
                isInline ? 'text-left' : 'text-center self-center',
                isInline
                  ? 'text-[16px] sm:text-[24px] line-clamp-1'
                  : 'text-[22px] sm:text-[36px] line-clamp-3',
              )}
            >
              {prompt.title}
            </TWLink>
          </div>
          {typeof window === 'undefined' && (
            <div className="w-full flex justify-center align-middle">
              <p
                className={cn(
                  'typography-body-lg whitespace-pre-line',
                  isInline ? 'text-left' : 'text-center',
                  isInline ? 'line-clamp-1' : 'line-clamp-7',
                )}
              >
                {prompt.content?.replace(/\n{3,}/g, '\n')}
              </p>
            </div>
          )}
          {prompt.content && (
            <div
              //  alignSelf="center" height="fit-content" width="100%"
              className="flex justify-center items-center h-fit w-full"
            >
              {prompt.contentLexical ? (
                <div className="typography-body-lg font-normal text-[24px]">
                  <BasicEditor
                    key={prompt.updatedAt}
                    isReadOnly={true}
                    initialEditorState={prompt.contentLexical}
                    maxLength={5}
                    textAlign={textAlign}
                    noOfLines={noOfLines}
                    textStyle="brand.bodyLg"
                    editorTheme={recBodyTheme}
                    readOnlyEditorTheme={recBodyTheme}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-[10px]">
                  <ExpandableTextContainer noOfLines={noOfLines} as={Stack}>
                    <Text textStyle="brand.bodyLg" fontWeight="normal" fontSize="20px" color="red">
                      {/* {prompt.content} */}
                    </Text>
                  </ExpandableTextContainer>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        // mt={isInline ? '0' : '24px'}
        // width="full"
        // alignSelf="flex-end"
        // pl={isInline ? ['64px', '82px', '102px'] : undefined}
        className={cn(
          'mt-[24px] w-full align-self-end',
          isInline ? 'mt-0 pl-[64px] sm:pl-[82px] md:pl-[102px]' : 'mt-[24px]',
        )}
      >
        <PromptItemBottom
          prompt={prompt}
          withBorder={mode !== PromptItemMode.FULL_VIEW}
          withResponses={mode !== PromptItemMode.FULL_VIEW}
          isPromptView={mode === PromptItemMode.FULL_VIEW}
          withSpecialBorder={withSpecialBorder}
          isInline={isInline}
        />
      </div>

      {/* <Flex textStyle="brand.actionLg" justifyContent="flex-end">
          </Flex> */}
      <PromptActions
        prompt={prompt}
        withLessPadding={withLessPadding}
        isInline={isInline}
        withBorder={withBorder}
      />
    </div>
  );
}
