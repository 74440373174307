import { gql } from '../../__generated__';
import { CreateConversationMutation } from '../hooks/useConvoHooks';

export const DELETE_REC_MUTATION = gql(/* GraphQL */ `
  mutation deleteRec($recId: String!) {
    deleteRec(recId: $recId)
  }
`);

export const BOOKMARK_REC_MUTATION = gql(/* GraphQL */ `
  mutation addRecToBookmarks($recId: String!) {
    addRecToBookmarks(recId: $recId) {
      id
      isBookmarked
    }
  }
`);

export const UNBOOKMARK_REC_MUTATION = gql(/* GraphQL */ `
  mutation removeRecFromBookmarks($recId: String!) {
    removeRecFromBookmarks(recId: $recId) {
      id
      isBookmarked
    }
  }
`);

export const PIN_REC_MUTATION = gql(/* GraphQL */ `
  mutation pinRec($recId: String!) {
    pinRec(recId: $recId) {
      id
      isPinned
    }
  }
`);

export const UNPIN_REC_MUTATION = gql(/* GraphQL */ `
  mutation unpinRec($recId: String!) {
    unpinRec(recId: $recId) {
      id
      isPinned
    }
  }
`);

export const RE_POST_REC_MUTATION = gql(/* GraphQL */ `
  mutation repostRec($targetRecId: String!) {
    repostRec(targetRecId: $targetRecId) {
      id
      isReposted
      repostTargetRec {
        id
        isReposted
      }
    }
  }
`);

export const REMOVE_RE_POST_REC_MUTATION = gql(/* GraphQL */ `
  mutation removeRepost($targetRecId: String!) {
    removeRepost(targetRecId: $targetRecId) {
      id
      isReposted
    }
  }
`);

export const ENDORSE_REC_MUTATION = gql(/* GraphQL */ `
  mutation endorseRec($targetRecId: String!, $repostRecId: String) {
    endorseRec(targetRecId: $targetRecId, repostRecId: $repostRecId) {
      id
      isEndorsed
      endorsementCount
    }
  }
`);

export const UNENDORSE_REC_MUTATION = gql(/* GraphQL */ `
  mutation unendorseRec($targetRecId: String!) {
    unendorseRec(targetRecId: $targetRecId) {
      id
      isEndorsed
      endorsementCount
    }
  }
`);

export const START_CONVERSATION_WITH_REC_MUTATION = gql(/* GraphQL */ `
  mutation startConversationWithRec(
    $targetRecId: String
    $newRecInput: NewRecInput
    $userIds: [String!]
    $conversationIds: [String!]
  ) {
    startConversationWithRec(
      targetRecId: $targetRecId
      newRecInput: $newRecInput
      userIds: $userIds
      conversationIds: $conversationIds
    ) {
      # Conversations
      id
    }
  }
`);

export const CREATE_CONVERSATION_MUTATION = CreateConversationMutation;

export const MUTE_REC_MUTATION = gql(/* GraphQL */ `
  mutation muteRec($recId: String!) {
    muteRec(recId: $recId) {
      id
      muted
    }
  }
`);

export const UNMUTE_REC_MUTATION = gql(/* GraphQL */ `
  mutation unmuteRec($recId: String!) {
    unmuteRec(recId: $recId) {
      id
      muted
    }
  }
`);

export const GENERATE_IMAGE_FOR_REC_MUTATION = gql(/* GraphQL */ `
  mutation generateImageForRec($recId: String!) {
    generateImageForRec(recId: $recId)
  }
`);

export const GENERATE_FEED_IMAGE_FOR_REC_MUTATION = gql(/* GraphQL */ `
  mutation generateFeedImageForRec($recId: String!) {
    generateFeedImageForRec(recId: $recId)
  }
`);

export const SHADOW_BAN_REC = gql(/* GraphQL */ `
  mutation ShadowBanRec($recId: String!) {
    shadowBanRec(recId: $recId) {
      id
      isShadowBanned
    }
  }
`);

export const UNDO_SHADOW_BAN_REC = gql(/* GraphQL */ `
  mutation undoShadowBan($recId: String!) {
    undoShadowBanRec(recId: $recId) {
      id
      isShadowBanned
    }
  }
`);
