'use client';
import { useThemeContext } from 'shared/misc/providers/ThemeContext';
import { useInsertionEffect } from 'react';

export function SetTheme() {
  const { activeTheme } = useThemeContext();
  // const { user } = useAuth();

  // // Extract relevant path segments
  // const pathSegments = pathname?.split('/') || [];
  // const [section, id] = pathSegments.slice(1);

  // // Profile view query
  // const profileViewQuery = useQuery(GetProfileViewDocument, {
  //   variables: { username: id },
  //   skip: section !== 'u' || !id,
  //   fetchPolicy: 'cache-only',
  // });

  // // Rec view query
  // const recViewQuery = useQuery(GetRecViewDocument, {
  //   variables: { id },
  //   skip: section !== 'rec' || !id,
  //   fetchPolicy: 'cache-only',
  // });

  // // Ask view query
  // const askViewQuery = useQuery(GetAskViewDocument, {
  //   variables: { id },
  //   skip: section !== 'ask' || !id,
  //   fetchPolicy: 'cache-only',
  // });

  // // Determine the theme
  // const theme = (() => {
  //   if (section === 'u' && profileViewQuery.data?.user?.profileTheme) {
  //     return profileViewQuery.data.user.profileTheme;
  //   }
  //   if (section === 'rec' && recViewQuery.data?.rec?.user?.profileTheme) {
  //     return recViewQuery.data.rec.user.profileTheme;
  //   }
  //   if (section === 'ask' && askViewQuery.data?.prompt?.user?.profileTheme) {
  //     return askViewQuery.data.prompt.user.profileTheme;
  //   }
  //   if (!user) return ProfileThemeType.Default;
  //   return user.profileTheme || ProfileThemeType.Default;
  // })();

  useInsertionEffect(() => {
    if (!activeTheme) return;
    // Remove any existing theme classes
    document.body.classList.forEach((className) => {
      if (className.startsWith('theme-')) {
        document.body.classList.remove(className);
      }
    });
    // Add new theme class
    document.body.classList.add(`theme-${activeTheme.toLowerCase()}`);
  }, [activeTheme]);

  return <></>;
}
