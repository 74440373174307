

import { Box, Button, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import CenterPage from 'shared/misc/components/CenterPage';
import PerfImage from 'shared/misc/components/util/PerfImage';
import PerfInput from 'shared/misc/components/util/PerfInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { verifyEmailViaCodeSchema } from 'shared/validation/schemas';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { gql } from 'shared/__generated__/gql';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { createFileRoute } from '@tanstack/react-router';

type Inputs = {
  code: string;
};

export const Route = createFileRoute('/sign-up/verify/')({
  component: SignUpVerifyEmailPage,
});

function SignUpVerifyEmailPage() {
  const toast = useToast();
  const [resendText, setResendText] = useState('Resend code...');

  const [verifyEmailAndSignInViaCode, { loading: verifyEmailAndSignInViaCodeLoading }] =
    useMutation(VERIFY_EMAIL_VIA_CODE);

  const [resendEmailSignIn, { loading: resendEmailSignInLoading }] =
    useMutation(RESEND_LAST_EMAIL_SIGN_UP);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(verifyEmailViaCodeSchema),
  });

  const { updateStep } = useUpdateSetupStep({
    preload: [USER_SIGN_UP_STEP.PREMIUM],
  });

  const onSubmit: SubmitHandler<Inputs> = async (values, event) => {
    event?.preventDefault();

    await verifyEmailAndSignInViaCode({
      variables: { code: values.code },
      onCompleted: async () => {
        toast({
          title: 'Email verified.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        await updateStep(USER_SIGN_UP_STEP.PREMIUM);
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const onResend = async () => {
    await resendEmailSignIn({
      onCompleted: () => {
        toast({
          title: 'Email sent.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
        setResendText('Sent!');
        setTimeout(() => {
          setResendText('Resend code...');
        }, 4000);
      },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const auth = useAuth();

  return (
    <>
      <CenterPage minH="100vh" axis="horizontal">
        <Box
          m="10px"
          marginTop={{ base: '50px', sm: '100px' }}
          border="1px dashed"
          borderColor="brand.main"
          p={{ base: '24px', md: '48px' }}
          w="100%"
          maxW="400px"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6} w="100%">
              <PerfImage
                src="https://files.pi.fyi/blue-star.png"
                alt="star-blue"
                width="50"
                height="50"
              />
              <Text textStyle="brand.headingSm">Verify your email.</Text>
              <Text textStyle="brand.body" fontWeight="normal">
                Be sure to check spam if you don't see the email.
              </Text>
              <Text textStyle="brand.body" fontWeight="normal">
                Verifying "{auth.user?.email}"
              </Text>
              <PerfInput
                placeholder="Enter six digit code"
                {...register('code')}
                error={errors.code}
              />
              <HStack spacing={3}>
                <Button type="submit" isLoading={verifyEmailAndSignInViaCodeLoading}>
                  Submit
                </Button>
                <Button isLoading={resendEmailSignInLoading} onClick={onResend} variant="secondary">
                  {resendText}
                </Button>
              </HStack>
            </Stack>
          </form>
        </Box>
      </CenterPage>
    </>
  );
}

const VERIFY_EMAIL_VIA_CODE = gql(/* GraphQL */ `
  mutation verifyEmailAndSignInViaCode($code: String!) {
    verifyEmailAndSignInViaCode(code: $code)
  }
`);

// const VERIFY_EMAIL_VIA_CODE = gql(/* GraphQL */ `
//   mutation verifyAnotherEmail($code: String!) {
//     verifyAnotherEmail(code: $code)
//   }
// `);

const RESEND_LAST_EMAIL_SIGN_UP = gql(/* GraphQL */ `
  mutation resendEmailSignIn {
    resendEmailSignIn
  }
`);
