import React, { useState } from 'react';

import { gql } from 'shared/__generated__/gql';
import { PerfImageFromFileFragment } from 'shared/__generated__/graphql';
import { nullThrows } from 'shared/utils';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import { genCDN } from 'shared/cdn';
// TYLER: This was stolen from here: https://github.com/chakra-ui/chakra-ui/discussions/2475

gql(/* GraphQL */ `
  fragment PerfImageFromFile on File {
    compressedKey
    compressedUrl
    contentType
    createdAt
    extension
    id
    key
    name
    url
    width
    height
    isUploaded
    isCompressed
    isFailed
    blurHash
    # compressedUrlQuality20: cachedAndCompressedUrl(quality: 20)
    # compressedUrlQuality40: cachedAndCompressedUrl(quality: 40)
    # compressedUrlQuality80: cachedAndCompressedUrl(quality: 80)
  }
`);

export function getCompressedUrlOrDefault(file: PerfImageFromFileFragment, quality: number) {
  return file.url;
}

interface PerfImageFromFileProps {
  file?: PerfImageFromFileFragment | null;
  src?: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
  quality?: number;
  useFallbackAvatarSrc?: boolean;
  priority?: boolean;
  [key: string]: any;
}

const PerfImageFromFile = ({
  file,
  width,
  height,
  backgroundColor,
  useFallbackAvatarSrc,
  quality,
  priority,
  ...props
}: PerfImageFromFileProps) => {
  const [fallbackSrc, setFallbackSrc] = useState<string>();
  const [hideOnError, setHideOnError] = useState(false);

  const themeColors = useThemeColors();

  const isLight = useIsBackgroundLight(backgroundColor);

  const originUserFallbackLogoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' || !isLight ? 'white' : 'blue';

  const fallbackAvatarSrc = `https://files.pi.fyi/default-profile-photo-${
    originUserFallbackLogoColor || 'blue'
  }-small.png`;

  if (hideOnError) {
    return null;
  }

  try {
    let finalUrl = fallbackAvatarSrc;

    if (file && !file?.isFailed) {
      finalUrl = file!.url!;
    }

    if (fallbackSrc || !finalUrl) {
      finalUrl = fallbackSrc || fallbackAvatarSrc;
    }

    if (!file || file.isFailed || !file.isUploaded) {
      if (useFallbackAvatarSrc) {
        finalUrl = fallbackAvatarSrc;
      } else {
        return null;
      }
    }

    if (file?.key) {
      finalUrl = genCDN(file.key, quality, 'webp');
    }

    return (
      <img
        {...props}
        src={finalUrl}
        width={nullThrows(width || file?.width)}
        height={nullThrows(height || file?.height)}
        onError={(e) => {
          if (useFallbackAvatarSrc && finalUrl !== fallbackAvatarSrc) {
            setFallbackSrc(fallbackAvatarSrc);
          } else {
            setHideOnError(true);
          }
        }}
      />
    );
  } catch (e) {
    console.log('failed to load file...', file);
    return null;
  }
};

export default PerfImageFromFile;
