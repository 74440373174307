'use client';

import React, { ReactNode, useEffect, useMemo, useRef } from 'react';

import toastOptions from '../wrappers/util/toastOptions';
import { PerfectlyImperfectTheme } from '../theme/theme';
import { getThemeFromThemeName } from '../theme/custom/utils';
import { useThemeContext } from './ThemeContext';
import { ChakraProvider } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
  useTheme?: boolean;
  themeOveride?: Record<string, any>;
  withCache?: boolean;
}

function updateScopedColors(newColors: Record<string, any>) {
  // Flatten nested color objects
  const flattenColors = (obj: Record<string, any>, prefix = ''): Record<string, string> => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = prefix ? `${prefix}-${key}` : key;
      if (value && typeof value === 'object') {
        Object.assign(acc, flattenColors(value, newKey));
      } else {
        acc[newKey] = value as string;
      }
      return acc;
    }, {} as Record<string, string>);
  };

  const flatColors = flattenColors(newColors);

  // Update CSS variables on body
  Object.entries(flatColors).forEach(([key, value]) => {
    document.body.style.setProperty(`--chakra-colors-${key}`, value);
  });
}

function ChakraProviderWithTheme({ children, useTheme = true }: Props) {
  const { activeTheme } = useThemeContext();

  useEffect(() => {
    if (useTheme && activeTheme !== null) {
      const newTheme = getThemeFromThemeName(activeTheme);
      // Update CSS variables when theme changes
      if (typeof window !== 'undefined') {
        updateScopedColors(newTheme.colors);
      }
    } else {
      // updateScopedColors(PerfectlyImperfectTheme.colors);
    }
  }, [activeTheme]);

  const toast = useMemo(() => toastOptions, []);

  const theme = useMemo(() => {
    if (activeTheme) {
      return getThemeFromThemeName(activeTheme) || PerfectlyImperfectTheme;
    }
    return PerfectlyImperfectTheme;
  }, [activeTheme]);

  return (
    <ChakraProvider
      theme={theme || PerfectlyImperfectTheme}
      toastOptions={toast}
      cssVarsRoot="body"
    >
      {children}
    </ChakraProvider>
  );
}

export default ChakraProviderWithTheme;
