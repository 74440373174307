import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/stripe/cancel/')({
  component: RouteComponent,
  loader: () => {
    throw redirect({ to: '/' });
  },
});

function RouteComponent() {
  return null;
}
