import React, { useEffect } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import Lottie from 'react-lottie';
import Stars from 'shared/animations/Stars.json';
import { createFileRoute } from '@tanstack/react-router';

const INTRO_TEXT = 'Personalizing your experience...';

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: Stars,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Route = createFileRoute('/sign-up/personalizing/')({
  component: SignUpPersonalizing,
});

function SignUpPersonalizing() {
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.TRIAL] });
  const { setNavigation } = useSignUpNavigation();
  const [displayText] = useTypewriter(INTRO_TEXT, 50);

  useEffect(() => {
    setNavigation({
      showLeftArrow: false,
      showRightArrow: false,
      showSkip: false,
      yPosition: 150,
      signupStep: USER_SIGN_UP_STEP.LOADING,
    });

    return () => {
      setNavigation({
        showLeftArrow: false,
        showRightArrow: false,
        yPosition: 150,
        signupStep: USER_SIGN_UP_STEP.LOADING,
      });
    };
  }, [setNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStep(USER_SIGN_UP_STEP.TRIAL);
    }, 3500);

    return () => clearTimeout(timer);
  }, [updateStep]);

  return (
    <>
      <Lottie
        isClickToPauseDisabled
        options={lottieOptions}
        height="100%"
        width="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          width: '100%',
          height: '100%',
        }}
      />
      <div className="min-h-[calc(100dvh-236px)] relative">
        <div
          className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start"
        >
          {displayText}
        </div>
        <div
          className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24"
        >
          This won't take long.
        </div>
      </div>
    </>
  );
}
