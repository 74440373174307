import React from 'react';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { RecommendationItemFragment } from '../../../../__generated__/graphql';
import RecommendationEmbedableContent, {
  getYoutubeVideoId,
  isSpotifyLink,
} from './RecommendationEmbedableContent';

type Props = {
  rec: RecommendationItemFragment;
  expandOnMount?: boolean;
  isThumbnail?: boolean;
  maxHeight?: string | number;
  enableEmbed?: boolean;
  imgCount?: number;
  showOverlay?: boolean;
  maxTallImageHeight?: number;
  isRecView?: boolean;
  onClick?: (idx: number) => void;
  overrideHeight?: number;
};

export default function RecommendationImageGroup({
  rec,
  maxHeight,
  expandOnMount,
  isThumbnail = false,
  enableEmbed = false,
  imgCount = 2,
  showOverlay = true,
  maxTallImageHeight = 400,
  isRecView = false,
  onClick,
}: Props) {
  if (rec.url && (getYoutubeVideoId(rec.url) || isSpotifyLink(rec.url)) && enableEmbed) {
    return (
      <RecommendationEmbedableContent isRecView={isRecView} overrideHeight={maxHeight} rec={rec} />
    );
  }

  const attachments = rec.attachments?.filter((a) => a.type === 'IMAGE');
  const images = attachments?.sort((a, b) => a.position - b.position).map((a) => a.file);

  if (!images || images.length === 0) {
    return null;
  }

  const hasOverlay = showOverlay && images.length > 2;

  return (
    <div
      className={`flex flex-row gap-2 w-auto ${!isThumbnail && 'max-w-[80%] sm:max-w-[100%]'}`}
      style={{ alignItems: 'flex-start' }}
    >
      {images.slice(0, imgCount).map((image, idx) => {
        if (!image || !image.isUploaded) return null;

        const aspectRatio = image?.width && image?.height ? image.width / image.height : 1;
        const naturalHeight = image.height;
        const naturalWidth = image.width;
        const scaleFactor = Math.min(1, maxTallImageHeight / naturalHeight);
        const calculatedHeight = naturalHeight * scaleFactor;
        const calculatedWidth = naturalWidth * scaleFactor;

        return (
          <div
            onClick={() => onClick?.(idx)}
            key={image.id}
            className={`relative mt-3 ${
              isThumbnail
                ? `w-[75px] h-[75px] mx-0 mt-[12px]`
                : idx === 0
                ? 'w-[60%]'
                : images.length > 2
                ? 'w-[160px] h-[160px]'
                : 'w-[40%]'
            } ${
              !isThumbnail && images.length > 1
                ? 'sm:!w-[50%] sm:!h-[auto] sm:!max-h-[350px] sm:!object-cover sm:overflow-hidden'
                : ''
            } cursor-pointer`}
            style={
              isThumbnail
                ? {}
                : {
                    width: idx === 0 && `${calculatedWidth}px`,
                    maxHeight: `${maxTallImageHeight}px`,
                  }
            }
          >
            {showOverlay && idx === 1 && hasOverlay && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 overflow-hidden">
                <span className="text-white text-xl font-bold">+{images.length - 1}</span>
              </div>
            )}
            <PerfImageFromFile
              file={image}
              alt="recommendation image"
              priority={expandOnMount}
              quality={70}
              style={{
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                objectFit: idx === 0 || images.length === 2 ? 'contain' : 'cover',
                aspectRatio: `${aspectRatio}`,
              }}
              className="object-cover"
            />
          </div>
        );
      })}
    </div>
  );
}
