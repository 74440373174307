import { PerfImageFromFileFragment } from './__generated__/graphql';

export function genCDN(name: string, quality = 20, format = 'auto') {
  return `https://files.pi.fyi/cdn-cgi/image/quality=${quality},format=${format}/${name}`;
}

export function getCompressedUrlOrDefault(
  file: PerfImageFromFileFragment,
  quality: number,
  fallback = file.url,
) {
  return file[`compressedUrlQuality${quality}`] || fallback;
}

export function checkCacheDefaultToCdn(file: PerfImageFromFileFragment, quality: number) {
  return getCompressedUrlOrDefault(file, quality, genCDN(file.key!, quality));
}
