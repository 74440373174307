'use client';

import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProfileThemeType } from '../../__generated__/graphql';
import { useAuth } from '../hooks/useAuth';

type ContextProps = {
  activeTheme: ProfileThemeType | null;
  setActiveTheme: React.Dispatch<React.SetStateAction<ProfileThemeType | null>>;
};

export const ThemeContext = React.createContext<ContextProps>({} as ContextProps);

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

interface Props {
  children: ReactNode;
  theme: ProfileThemeType | null;
}

export const useAutoSetTheme = (
  syncTheme: ProfileThemeType | null | undefined | string,
  isManual?: boolean,
) => {
  const { activeTheme, setActiveTheme } = useContext(ThemeContext);

  const pathname = '/';

  useEffect(() => {
    if (!isManual && activeTheme !== syncTheme) {
      setActiveTheme(syncTheme);
    }
  }, [syncTheme, pathname]);

  return setActiveTheme;
};

export function ThemeProvider({ children, theme }: Props) {
  const auth = useAuth();

  const [activeTheme, setActiveTheme] = useState<ProfileThemeType | null>(
    (theme ? theme : auth.user?.profileTheme) || null,
  );

  const memoizedValue = useMemo(() => ({ activeTheme, setActiveTheme }), [activeTheme]);

  return <ThemeContext.Provider value={memoizedValue}>{children}</ThemeContext.Provider>;
}
