import React, { useEffect, useRef } from 'react';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import AddRecommendationModal from './add-recommendation-modal';
import UpdateProfilePhotosModal from './update-profile-photos-modal';
import AddPromptModal from './add-prompt-modal';
import FinishSignUpModal from './finish-sign-up-modal';
import AdminSelectOrCreateUserModal, { ModalPurpose } from './admin-select-or-create-user-modal';
import SimpleSubscribeModal from './simple-subscribe-modal';
import EmailSignInModal from './email-sign-in-modal';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useRouterState } from '@tanstack/react-router';

const usePathname = () => {
  return useRouterState({
    select: (state) => state.location.pathname,
  });
};

const useSearchParams = () => {
  return useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });
};

function GlobalModals() {
  const addRecommendationState = useModalState(ModalNames.ADD_RECOMMENDATION);
  const updateProfilePhotosState = useModalState(ModalNames.UPDATE_PROFILE_PHOTO);
  const addPromptState = useModalState(ModalNames.ADD_ASK);
  const finishSignUpState = useModalState(ModalNames.FINISH_SIGN_UP);
  const simpleSubscribeState = useModalState(ModalNames.SIMPLE_SUBSCRIBE);
  const adminSelectOrCreateUserState = useModalState(ModalNames.ADMIN_SELECT_OR_CREATE_USER);
  return (
    <>
      {addRecommendationState.isOpen && <AddRecommendationModal />}
      {updateProfilePhotosState.isOpen && <UpdateProfilePhotosModal />}
      {addPromptState.isOpen && <AddPromptModal />}
      {finishSignUpState.isOpen && <FinishSignUpModal />}
      {simpleSubscribeState.isOpen && <SimpleSubscribeModal />}
      {adminSelectOrCreateUserState.isOpen && (
        <AdminSelectOrCreateUserModal purpose={ModalPurpose.DocumentDraft} />
      )}

      <WrappedEmailSignInModal />
    </>
  );
}

function WrappedEmailSignInModal() {
  const autoSuggestEmailSignInState = useModalState(ModalNames.AUTO_SUGGEST_EMAIL_SIGN_IN);

  const searchParams = useSearchParams();
  const auth = useAuth();
  const email = searchParams.get('to');
  const hasOpenedRef = useRef<boolean | null>(null);

  useEffect(() => {
    if (email && !auth.user && !hasOpenedRef.current) {
      autoSuggestEmailSignInState.onOpen();
      hasOpenedRef.current = true;
    }
  }, [email, auth, autoSuggestEmailSignInState]);
  if (!autoSuggestEmailSignInState.isOpen) return null;
  return <EmailSignInModal />;
}

export default GlobalModals;
