import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { Modal, ModalContent, ModalTitle, ModalCloseButton } from 'web2/app/components/ui/modal';
import React, { ReactNode, useMemo, useState } from 'react';
import { RiLinkM } from 'react-icons/ri';
import { useRouter, useRouterState } from '@tanstack/react-router';
import normalizeUrl from 'normalize-url';
import _ from 'lodash';
import { PiPushPinFill } from 'react-icons/pi';
import { X } from 'lucide-react';
import { gql } from 'shared/__generated__';
import {
  RecType,
  RecommendationItemFragment,
  RecommendationItemFragmentDoc,
  RecommendationItemWithEndorsedByFragment,
  UserFlagType,
} from 'shared/__generated__/graphql';
import BasicEditor from '../rich-text/basic/basic-editor';
import ExpandableTextContainer from '../text/expandable-text-container';
import { recBodyTheme } from 'shared/misc/theme/selectors/lexicalStyles';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import { useProfileContext } from 'shared/misc/providers/ProfileContext';
import { isContentArchived, isUserGuest } from 'shared/gates';
import BlurredPaywall, { PaywallReason } from 'shared/misc/components/gates/BlurredPaywall';
import { useAuth } from 'shared/misc/hooks/useAuth';
import RecommendationActions from './parts/recommendation-actions';
import { RecommendationContext } from 'shared/misc/components/by-type/recommendations/providers/useRecommendationContext';
import RecommendationItemBottom from './parts/recommendation-item-bottom';
import RecommendationImageGroup from 'shared/misc/components/by-type/recommendations/RecommendationImageGroup';
import RecommendationPrivateContext from 'shared/misc/components/by-type/recommendations/RecommendationPrivateContext';
import OverlapTitle from 'shared/misc/components/OverlapTitle';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import PerfImageFromFile from 'web2/app/components/by-type/images/perf-image-from-file';
import { twMerge } from 'tailwind-merge';
import { Link as TWLink } from '../../link';
import { cn } from '../../../utils/cn';
import { Tooltip, TooltipTrigger, TooltipContent } from 'web2/app/components/ui/tooltip';
import RecommendationImageModal from './recommendation-item-image-modal';
import { useFragment } from '@apollo/client';

gql(/* GraphQL */ `
  fragment RecommendationItemCore on Rec {
    id
    title
    url
    date
    content
    contentHTML
    contentLexical
    emoji
    createdAt
    isHidden
    muted
    updatedAt
    isBookmarked
    isEndorsed
    isReposted
    isPinned
    endorsementCount
    commentsCount
    type
    user {
      ...UserCore
    }
    # guest {
    #   id
    #   name
    # }
    # feature {
    #   id
    #   title
    #   url
    #   list {
    #     id
    #   }
    # }
    prompt {
      ...PromptItem
    }
    attachments {
      ...RecommendationAttachment
    }
    # permissions {
    #   id
    #   role
    #   user {
    #     ...UserCore
    #   }
    # }
    conversation {
      ...ConversationCore
    }
    isShadowBanned
    isCurated
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationAttachment on Attachment {
    id
    type
    position
    file {
      ...PerfImageFromFile
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItem on Rec {
    ...RecommendationItemCore
    repostTargetRec {
      ...RecommendationItemCore
      repostTargetRec {
        ...RecommendationItemCore
      }
    }
    targetRec {
      ...RecommendationItemCore
      targetRec {
        ...RecommendationItemCore
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItemWithEndorsedBy on Rec {
    ...RecommendationItem
    endorsedByUser(userId: $recEndorsedByUserId) {
      id
      username
    }
  }
`);

gql(/* GraphQL */ `
  fragment RecommendationItemList on List {
    id
    title
    entryCount
  }
`);

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

const orZero = (n: number) => (n > 0 ? n : 0);

const DeletedRec = () => (
  <div className="typography-action-sm p-3 border border-dashed border-brand-main">
    Original Recommendation deleted...
  </div>
);

export type RecommendationItemProps = {
  rec: RecommendationItemFragment | RecommendationItemWithEndorsedByFragment;
  parentRepostRec?: RecommendationItemFragment | RecommendationItemWithEndorsedByFragment;
  contextNode?: ReactNode | null;
  withBorder?: boolean;
  noOfLines?: number;
  withLessPadding?: boolean;
  withNoPadding?: boolean;
  hideEmoji?: boolean;
  showPrompt?: boolean;
  withEmojiSameLine?: boolean;
  withResponses?: boolean;
  withMinHeight?: boolean;
  withActionMenu?: boolean;
  disableExpandImage?: boolean;
  withUrlSameLine?: boolean;
  withOnlyBottomBorder?: boolean;
  withBottomActions?: boolean;
  withBottom?: boolean;
  smallTitle?: boolean;
  isNestedRepostRec?: boolean;
  hideTitle?: boolean;
  isRecView?: boolean;
  expandImageOnMount?: boolean;
  withPinnedIcon?: boolean;
  overlapTitle?: string;
  isInline?: boolean;
  enableEmbed?: boolean;
  imageMaxHeight?: string;
  titleNoOfLines?: number;
};

function urlHost(urlString: string): string | null {
  try {
    return `(${new URL(urlString).host})`;
  } catch (error) {
    return null;
  }
}

export default function RecommendationItem(props: RecommendationItemProps) {
  const {
    rec,
    contextNode = null,
    withBorder = true,
    withLessPadding = true,
    withNoPadding = false,
    hideEmoji = false,
    hideTitle = false,
    isRecView = false,
    disableExpandImage = false,
    withUrlSameLine = false,
    withResponses = true,
    withActionMenu = true,
    withBottomActions = true,
    withBottom = true,
    showPrompt = false,
    withMinHeight = false,
    withOnlyBottomBorder = false,
    smallTitle = false,
    isNestedRepostRec = false,
    parentRepostRec = null,
    withPinnedIcon = false,
    expandImageOnMount = false,
    imageMaxHeight,
    withEmojiSameLine = (withPinnedIcon && rec.isPinned) || false,
    noOfLines = props.isInline ? 1 : withPinnedIcon && rec.isPinned ? 2 : 7,
    titleNoOfLines = props.isInline ? 1 : props.titleNoOfLines,
    overlapTitle,
    isInline = false,
    enableEmbed = true,
  } = props;

  const actionsMenuState = useDisclosure();
  const imageModalState = useDisclosure();

  const { profile } = useProfileContext();

  const [startIndex, setStartIndex] = useState(0);

  const auth = useAuth();

  const ctx = useMemo(() => ({ rec }), [rec]);

  if (!rec) return <DeletedRec />;

  let { title, emoji, url, content, contentLexical } = rec || {};

  const isPrivateShare =
    rec.type === RecType.Private ||
    rec.type === RecType.PrivateShareExisting ||
    rec.type === RecType.PrivateShareNew;

  const isPrivateShareRepost = isPrivateShare && rec.type !== RecType.PrivateShareNew;

  const isRepost =
    rec.type === RecType.RePost || rec.type === RecType.RePostWithContent || isPrivateShareRepost;

  const targetRec = rec?.repostTargetRec || rec?.targetRec;

  if ((isRepost || isPrivateShareRepost) && targetRec) {
    emoji = isPrivateShareRepost ? '' : targetRec.emoji;
    title = targetRec.title;
    url = targetRec.url;
  }

  title = title?.trim();

  if (title?.startsWith(':')) {
    title = title.replace(':', '').trim();
  }

  if (!title && rec.type === RecType.UnpublishedPostRec) {
    title = 'PLACEHOLDER TITLE';
  }

  const image = rec.attachments?.find((a) => a.type === 'IMAGE')?.file;
  const imageAttachments = rec.attachments?.filter((a) => a.type === 'IMAGE').map(a => a.file) || [];
  const hasImage = imageAttachments.length > 0 && !!imageAttachments[0]?.isUploaded;

  const emojiNode = (
    <span className="typography-heading-lg">{isInline ? emoji?.slice(0, 2) : emoji}</span>
  );

  const isBackgroundLight = useIsBackgroundLight();

  let normalizedUrl: string | null = null;

  try {
    normalizedUrl = url ? normalizeUrl(url) : null;
  } catch (e) {}

  const linkNode = url ? (
    <Tooltip>
      <TooltipTrigger>
        <TWLink to={url} target="_blank" className="text-brand-highlight">
          <RiLinkM size="24px" color="currentColor" />
        </TWLink>
      </TooltipTrigger>
      <TooltipContent withPortal align="center" className="z-[501]">
        This rec has a link{normalizedUrl ? ` ${urlHost(normalizedUrl)}` : ''}
      </TooltipContent>
    </Tooltip>
  ) : null;

  let extraProps = {};

  let extraClassnames = [];

  let paddingClassnames = ['p-[16px]', 'md:p-[16px]'];

  if (isInline && !isNestedRepostRec) {
    paddingClassnames.push('pl-[64px]', 'sm:pl-[82px]', 'md:pl-[102px]');
  }
  if (withBorder) {
    if (withOnlyBottomBorder) {
      extraClassnames.push(
        'border-bottom-[1px]',
        'border-dashed',
        'border-brand-main',
        'p-[0px]',
        'pb-[16px]',
        'md:pb-[16px]',
      );
      if (isInline && !isNestedRepostRec) {
        extraClassnames.push('pl-[64px]', 'sm:pl-[82px]', 'md:pl-[102px]');
      }
    } else {
      extraClassnames.push(
        'border-t-[1px]',
        'border-l-[1px]',
        'border-r-[1px]',
        'border-dashed',
        'border-brand-main',
      );
    }
  } else if (withNoPadding) {
    paddingClassnames = ['p-[0px]'];
  }

  const overflowLimit = 60 + (url && rec.type !== RecType.RePostWithContent ? 36 : 0);

  let finalContextNode = contextNode;
  let repostContextNode: ReactNode | null = null;

  const themeColors = useThemeColors();

  if ((isRepost || isPrivateShare) && rec.user) {
    const logoColor =
      themeColors.main === '#ffffff' || themeColors.main === 'white' ? 'white' : 'blue';

    const imageSrc = rec.user?.avatarPhotoSrc || `/default-profile-photo-${logoColor}-small.png`;

    let contextLinkNode: ReactNode | null = null;

    if (isPrivateShare && rec.conversation) {
      contextLinkNode = <RecommendationPrivateContext rec={rec} />;
    } else {
      contextLinkNode = !content ? (
        <TWLink to={`/u/$username`} params={{ username: rec.user.username }}>
          <em>RE-REC'D BY @{rec.user.username}</em>
        </TWLink>
      ) : (
        <TWLink to={`/u/$username`} params={{ username: rec.user.username }}>
          <em>RE-REC'D BY @{rec.user.username}</em>
        </TWLink>
      );
    }

    repostContextNode = contextLinkNode && (
      <div
        className={twMerge(
          'flex items-center self-center w-fit gap-[6px]',
          isBackgroundLight ? 'opacity-30' : 'opacity-80',
          'hover:opacity-100',
          `max-w-[calc(100%-${overflowLimit}px)]`,
          isInline && !isNestedRepostRec ? 'pb-2' : '',
        )}
      >
        {!isPrivateShare && (
          <img
            src={imageSrc}
            width={15}
            height={15}
            alt="image"
            style={{
              minWidth: '15px',
              minHeight: '15px',
            }}
          />
        )}
        <span className="whitespace-nowrap overflow-hidden text-ellipsis pr-[0.3em] text-[11px] typography-action-sm text-brand-main opacity-90">
          {contextLinkNode}
        </span>
      </div>
    );

    if (targetRec && !content && !parentRepostRec && !isPrivateShareRepost) {
      return (
        <RecommendationItem
          {...props}
          contextNode={repostContextNode}
          rec={targetRec}
          parentRepostRec={rec}
          showPrompt={!isPrivateShare}
        />
      );
    }

    if (!finalContextNode) {
      finalContextNode = repostContextNode;
    }
  }

  const isStaffPick =
    !profile &&
    (rec.user?.flag.includes(UserFlagType.PerfectlyImperfect) ||
      rec.user?.flag.includes(UserFlagType.Staff));

  const showTop =
    finalContextNode ||
    (((hideEmoji && rec.url) || !hideEmoji) &&
      !(withUrlSameLine && withEmojiSameLine) &&
      (emoji || !!rec.url));

  const isEndorsedRec = !isRepost && rec?.endorsedByUser;
  const showRecPrompt = !isRepost && rec?.prompt && showPrompt;
  const hasAddedContext = finalContextNode || isEndorsedRec || showRecPrompt;
  const extraHeight = isInline && hasAddedContext ? 10 : 0;

  const emojiNodeWithMaybeImage = !hideEmoji && !withEmojiSameLine && emoji && (
    <>
      {isInline && hasImage && (
        <TWLink
          to={'/rec/$recId'}
          params={{ recId: rec.id }}
          className={twMerge(
            'absolute',
            'left-[10px]',
            'sm:left-[12px]',
            'top-[50%]',
            'sm:top-[6px]',
            'cursor-pointer',
            'transform-translate-y-1/2',
            'sm:transform-translate-y-0',
          )}
        >
          <RecommendationImageGroup imgCount={1} rec={rec} isThumbnail />
        </TWLink>
      )}
      <span
        className={twMerge(
          isInline ? 'absolute' : '',
          isInline
            ? hasImage
              ? 'typography-aside'
              : 'typography-body-xl'
            : 'typography-heading-lg',
          ...(isInline
            ? hasImage
              ? ['left-[30px]', 'sm:left-[48px]', 'md:left-[68px]']
              : ['left-[9px]', 'sm:left-[11px]', 'md:left-[16px]']
            : []),
          isInline && hasImage
            ? content
              ? [
                  `top-[calc(52px+${extraHeight}px)]`,
                  `sm:top-[calc(50px+${orZero(extraHeight - 4)}px)]`,
                  `md:top-[calc(68px+${orZero(extraHeight - 4)}px)]`,
                ]
              : [
                  `top-[calc(38px+${extraHeight}px)]`,
                  `sm:top-[calc(50px+${orZero(extraHeight - 4)}px)]`,
                  `md:top-[calc(68px+${orZero(extraHeight - 4)}px)]`,
                ]
            : content
            ? ['top-[16px]']
            : ['top-[4px]', 'md:top-[2px]'],
        )}
      >
        {isInline ? emoji?.slice(0, 2) : emoji}
      </span>
    </>
  );

  const handleOpenImageModal = (idx: number) => {
    setStartIndex(idx);
    imageModalState.onOpen();
  };

  return (
    <RecommendationContext.Provider value={ctx} key={rec.id}>
      <div
        className={cn({
          'bg-brand-background relative flex flex-col gap-[16px] w-[100%]': true,
          'min-h-[200px]': withMinHeight,
          'min-h-[115px]': isInline && !withMinHeight,
          'overflow-visible': withPinnedIcon,
        })}
        style={
          overlapTitle
            ? {}
            : {
                // contentVisibility: 'auto',
                containIntrinsicSize: withMinHeight
                  ? '200px'
                  : isInline && !withMinHeight
                  ? '115px'
                  : '150px',
              }
        }
      >
        {overlapTitle && <OverlapTitle>{overlapTitle}</OverlapTitle>}
        {rec.user &&
          rec.user.id !== auth.user?.id &&
          isUserGuest(rec.user) &&
          isContentArchived(rec.date) &&
          !auth?.user?.isPremium &&
          false && <BlurredPaywall reason={PaywallReason.ARCHIVE} />}
        <div
          onMouseEnter={actionsMenuState.onOpen}
          onMouseLeave={actionsMenuState.onClose}
          className="flex w-full grow-1 shrink-0 justify-between flex-col"
        >
          <div
            className={twMerge(
              'grow-1 border-b-0',
              ...extraClassnames,
              ...paddingClassnames,
              isInline && hasImage ? ['min-h-[0px]', 'sm:min-h-[80px]', 'md:min-h-[100px]'] : '',
              isInline ? 'relative' : '',
              isInline ? 'pb-[12px]' : 'pb-[36px]',
            )}
          >
            {isInline && emojiNodeWithMaybeImage}
            <div className={twMerge('flex flex-col gap-[12px]', withMinHeight ? 'gap-[16px]' : '')}>
              {showTop &&
              !isPrivateShareRepost &&
              !withEmojiSameLine &&
              !withUrlSameLine &&
              (!isInline ||
                finalContextNode ||
                (url &&
                  rec.type !== RecType.RePostWithContent &&
                  !withUrlSameLine &&
                  !isInline)) ? (
                <div className="flex flex-row gap-[12px] items-center">
                  {!isInline && emojiNodeWithMaybeImage}
                  {url &&
                  rec.type !== RecType.RePostWithContent &&
                  !withUrlSameLine &&
                  !isInline ? (
                    <span className="flex typography-heading-lg w-fit h-fit items-center relative">
                      {linkNode}
                    </span>
                  ) : null}
                  {finalContextNode}
                  {!finalContextNode &&
                  ((rec?.prompt && showPrompt) ||
                    ('endorsedByUser' in rec && rec?.endorsedByUser)) ? (
                    <div
                      className={twMerge(
                        `max-w-[calc(100%-${overflowLimit}px)]`,
                        'flex flex-col gap-2',
                        isInline && !isNestedRepostRec ? 'pb-2' : '',
                      )}
                    >
                      {isEndorsedRec ? (
                        <div
                          className={twMerge(
                            'flex items-center gap-[3px]',
                            isBackgroundLight ? 'opacity-30' : 'opacity-80',
                            'hover:opacity-100',
                          )}
                        >
                          <PerfImageFromFile
                            alt="endorsements"
                            src={`https://files.pi.fyi/one-endorsed-user-endorsed.png`}
                            width={10}
                            height={10}
                          />
                          <span className="pr-[0.3em] whitespace-nowrap overflow-hidden text-ellipsis text-[11px] typography-action-sm">
                            <em>LIKED by @{rec.endorsedByUser.username}</em>
                          </span>
                        </div>
                      ) : null}
                      {showRecPrompt && rec.prompt ? (
                        <div
                          className={twMerge(
                            'flex w-fit h-fit items-center',
                            'gap-[3px]',
                            'opacity-30 hover:opacity-100',
                          )}
                        >
                          <span className={twMerge('typography-action-sm text-[11px] pr-[0.3em]')}>
                            <TWLink to={`/ask/$promptId`} params={{ promptId: rec.prompt!.id }}>
                              <em className="overflow-hidden text-ellipsis whitespace-break-spaces leading-relaxed line-clamp-1">
                                Rec'd on {rec.prompt!.emoji} {rec.prompt!.title}
                              </em>
                            </TWLink>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {title && !hideTitle && (!isRepost || isNestedRepostRec) ? (
                <div
                  className={twMerge('flex flex-row gap-3 items-center')}
                  style={{
                    marginTop: isInline ? '0px' : '6px',
                    marginBottom: isInline ? '0px' : '6px',
                  }}
                >
                  {(withUrlSameLine || isInline) && linkNode}
                  {withEmojiSameLine && emojiNode}
                  <span className="w-fit max-w-[100%] pr-[16px]">
                    <TWLink
                      to={'/rec/$recId'}
                      params={{ recId: targetRec ? targetRec.id : rec.id }}
                      className={cn(
                        'typography-heading-lg',
                        isInline ? 'text-[20px]' : 'text-[32px]',
                        isInline
                          ? 'line-clamp-1'
                          : isRecView
                          ? undefined
                          : `line-clamp-${titleNoOfLines}`,
                        rec.type === RecType.UnpublishedPostRec && !rec.title
                          ? 'text-lightgray'
                          : 'text-brand-main',
                        smallTitle
                          ? isInline
                            ? 'text-[16px] sm:text-[22px]'
                            : 'text-[18px] sm:text-[16px]'
                          : 'text-[20px] sm:text-[32px]',
                      )}
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: titleNoOfLines,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {title}
                    </TWLink>
                  </span>
                </div>
              ) : null}

              {content ? (
                <div className={withEmojiSameLine ? 'mt-3' : undefined} suppressHydrationWarning>
                  {contentLexical && typeof window !== 'undefined' ? (
                    <div className="typography-body-lg font-normal text-[24px] inline-flex">
                      <BasicEditor
                        key={rec.updatedAt}
                        isReadOnly={true}
                        initialEditorState={contentLexical}
                        maxLength={30}
                        noOfLines={noOfLines}
                        textStyle="brand.bodyLg"
                        editorTheme={recBodyTheme}
                        readOnlyEditorTheme={recBodyTheme}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-[10px]">
                      <ExpandableTextContainer
                        noOfLines={noOfLines}
                        as="div"
                        className="flex flex-col"
                      >
                        <span className="typography-body-lg font-normal text-[20px] whitespace-pre-line">
                          {rec.content?.replace(/\n{3,}/g, '\n')}
                        </span>
                      </ExpandableTextContainer>
                    </div>
                  )}
                </div>
              ) : null}
              {!isInline && (
                <RecommendationImageGroup
                  rec={rec}
                  isRecView={isRecView}
                  maxHeight={imageMaxHeight}
                  enableEmbed={enableEmbed}
                  onClick={handleOpenImageModal}
                />
              )}
              {isRepost && !isNestedRepostRec && (
                <TWLink
                  to={targetRec ? '/rec/$recId' : undefined}
                  params={targetRec ? { recId: targetRec.id } : undefined}
                  className={twMerge(
                    'flex flex-col',
                    'gap-[16px]',
                    targetRec ? 'cursor-pointer' : undefined,
                    !isInline ? 'mb-[6px]' : undefined,
                  )}
                >
                  {targetRec !== null ? (
                    <div
                      className={twMerge(
                        'pointer-events-none',
                        !isInline ? 'mt-[12px]' : undefined,
                      )}
                    >
                      <RecommendationItem
                        rec={targetRec}
                        withLessPadding
                        withNoPadding={withNoPadding}
                        noOfLines={isInline ? 1 : isRecView ? undefined : 2}
                        isNestedRepostRec
                        withUrlSameLine={withUrlSameLine}
                        withOnlyBottomBorder={withOnlyBottomBorder}
                        withBottomActions={false}
                        withActionMenu={false}
                        parentRepostRec={rec}
                        hideEmoji={!isPrivateShare && isRepost}
                        showPrompt={isPrivateShare}
                        isInline={isInline}
                      />
                    </div>
                  ) : (
                    <DeletedRec />
                  )}
                </TWLink>
              )}
            </div>
          </div>

          {withBottom ? (
            <div
              className={twMerge(
                'border-top-width-[0px]',
                'w-full',
                'self-end',
                isInline && !isNestedRepostRec ? 'pl-[64px] sm:pl-[82px] md:pl-[102px]' : '',
                ...(withBorder
                  ? [
                      'border-t-[0px]',
                      'border-l-[1px]',
                      'border-b-[1px]',
                      'border-r-[1px]',
                      'border-dashed',
                      'border-brand-main',
                    ]
                  : []),
              )}
            >
              <RecommendationItemBottom
                withResponses={withResponses}
                withBottomActions={withBottomActions}
                parentRepostRec={parentRepostRec}
                withBorder={!isInline && withBorder}
                isRecView={isRecView}
                withNoPadding={isInline || withNoPadding}
                isInline={isInline}
              />
            </div>
          ) : null}

          {withActionMenu && <RecommendationActions withLessPadding={withLessPadding} />}
          {withPinnedIcon && rec.isPinned && profile?.id === rec?.user?.id && (
            <div className="absolute top-[-8px] left-[-8px] rotate-[260deg]">
              <PiPushPinFill className="w-[20px] md:w-[16px] h-[20px] md:h-[16px] text-brand-main" />
            </div>
          )}
        </div>
      </div>
      {imageAttachments.length > 0 && imageAttachments[0]?.url && imageModalState.isOpen && (
        <RecommendationImageModal
          images={imageAttachments}
          isOpen={imageModalState.isOpen}
          onClose={imageModalState.onClose}
          startIndex={startIndex}
        />
      )}
    </RecommendationContext.Provider>
  );
}

export function RecommendationItemWithFragment(props: RecommendationItemProps) {
  const { complete, data } = useFragment({
    fragment: RecommendationItemFragmentDoc,
    fragmentName: 'RecommendationItem',
    from: {
      __typename: 'Rec',
      id: props.rec.id,
    },
  });

  return <RecommendationItem {...props} rec={complete ? data : props.rec} />;
}
