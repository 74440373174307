import { createFileRoute } from '@tanstack/react-router';
import ProfileView from './-profile-view';
import {
  GetGenericRecScrollViewDocument,
  GetProfileViewDocument,
} from 'shared/__generated__/graphql';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { genCDN } from 'shared/cdn';
import { preloadImageByKey } from '../../../../lib/utils';
import { ProfileLowerRecommendationsVariables } from './-profile-main-lower-content';
import { genMeta } from 'web2/app/utils/meta';

export const Route = createFileRoute('/u/$username/')({
  component: RouteComponent,
  staleTime: 60 * 1000,
  head(ctx) {
    const data = ctx.loaderData as Awaited<ReturnType<typeof Route.loader>>;
    const image = genCDN(data?.user?.profilePhotoFile?.key, 70, 'webp');

    return genMeta({
      title: `@${ctx.params.username} on pi.fyi`.toUpperCase(),
      description: `WHAT @${ctx.params.username} IS INTO`.toUpperCase(),
      image,
    })
  },
  loader: async ({ params, context }) => {
    const username = params.username;
    const result = await context.apolloClient.query({
      query: GetProfileViewDocument,
      variables: { username: username },
    });

    if (typeof window !== 'undefined' && result.data?.user?.profilePhotoFile) {
      preloadImageByKey(result.data?.user?.profilePhotoFile?.key!);
    }

    // Don't await, just return the promise.
    const recConnectionQueryRef = result.data?.user?.id
      ? context.apolloClient.query({
          query: GetGenericRecScrollViewDocument,
          variables: {
            ...ProfileLowerRecommendationsVariables,
            originUserId: result.data?.user?.id,
          },
        })
      : null;

    return {
      profileData: result.data,
      recConnectionQueryRef,
    };
  },
});

function RouteComponent() {
  const params = Route.useParams();

  const { profileData } = Route.useLoaderData();

  const { username } = params;

  return (
    <ProfileView
      username={username}
      theme={
        profileData?.user?.isShadowBanned === false ? profileData?.user?.profileTheme || null : null
      }
      initialProfileData={profileData?.user}
    />
  );
}
