import React, { useContext } from 'react';
import { ProfileFragment } from '../../__generated__/graphql';

type ContextProps = {
  profile: ProfileFragment;
};

export const ProfileContext = React.createContext<ContextProps>({} as ContextProps);

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
