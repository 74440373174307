import React, { HTMLAttributes, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { RecommendationItemFragment } from '../../../../__generated__/graphql';
import { InView } from 'react-intersection-observer';

type Props = {
  rec: RecommendationItemFragment;
  isRecView?: boolean;
  overrideHeight?: string | number;
};

export function getYoutubeVideoId(url) {
  let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  return regex.exec(url)?.[3];
}

export function isSpotifyLink(url: string) {
  return /^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/.test(url);
}

export function isEmbeddable(rec: RecommendationItemFragment) {
  return rec.url && (getYoutubeVideoId(rec.url) || isSpotifyLink(rec.url));
}

export default function RecommendationEmbedableContent({ rec, isRecView, overrideHeight }: Props) {
  const [isInView, setIsInView] = useState(false);

  if (!rec.url) {
    return null;
  }

  const videoID = getYoutubeVideoId(rec.url);
  const isSpotify = isSpotifyLink(rec.url);

  if (!videoID && !isSpotify) {
    return null;
  }

  let node: React.ReactNode = null;

  if (isSpotify) {
    node = (
      <SpotifyEmbed link={rec.url} wide height={overrideHeight || 400} isRecView={isRecView} />
    );
  } else if (videoID) {
    node = (
      <YouTubeEmbed
        videoId={videoID}
        height={overrideHeight || (isRecView ? 600 : 450)}
        isRecView={isRecView}
      />
    );
  }

  return (
    <Box
      mt="12px"
      position="relative"
      width="100%"
      objectFit="contain"
      cursor="pointer"
      maxHeight={isRecView ? '600px' : '450px'}
    >
      <InView
        as="div"
        width="100%"
        rootMargin="3000px 0px"
        onChange={(inView) => {
          if (!isInView) {
            setIsInView(inView);
          }
        }}
      />
      {isInView && node}
    </Box>
  );
}

function YouTubeEmbed({
  videoId,
  isRecView,
  height,
}: {
  videoId: string;
  isRecView: boolean;
  height: number;
}) {
  return (
    <iframe
      width={'100%'}
      height={height}
      src={`https://www.youtube-nocookie.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      title="YouTube video"
    />
  );
}

interface SpotifyProps extends HTMLAttributes<HTMLIFrameElement> {
  [key: string]: any;
  link: string;
  wide?: boolean;
  width?: number | string;
  height?: number | string;
  frameBorder?: number | string;
  allow?: string;
}

export const SpotifyEmbed = ({
  link,
  isRecView,
  style = {},
  wide = false,
  width = '100%',
  height = isRecView ? 200 : 80,
  frameBorder = 0,
  allow = 'encrypted-media',
  ...props
}: SpotifyProps) => {
  const url = new URL(link);
  // https://open.spotify.com/track/1KFxcj3MZrpBGiGA8ZWriv?si=f024c3aa52294aa1
  // Remove any additional path segments
  url.pathname = url.pathname.replace(/\/intl-\w+\//, '/');
  return (
    <iframe
      title="Spotify Web Player"
      src={`https://open.spotify.com/embed${url.pathname}`}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allow={allow}
      style={{
        borderRadius: 0,
        ...style,
      }}
      {...props}
    />
  );
};
