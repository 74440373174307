import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/link-in-bio/')({
  component: RouteComponent,
  loader: async () => {
    if (typeof window !== 'undefined') {
      window.location.href = 'https://linkin.bio/perfectlyimperfect/';
    }
    // Optionally return a value or handle the case when window is not defined
  },
});

function RouteComponent() {
  return null;
}
