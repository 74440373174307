/*

main text
background
name background
name border
highlight (some links and buttons)

*/

import { ProfileThemeType } from '../../../__generated__/graphql';
import { perfectlyImperfectColors } from '../colors';

export type ThemeColors = {
  background: string;
  titleBackground: string;
  titleBorder: string;
  highlight: string;
  highlightBorder?: string;
  menuBackground: string;
  menuLogo?: string;
  menuText?: string;
  menuHighlight?: string;
  main: string;
  logoColor?: string;
};

export const DARK_COLORS = {
  main: '#FFFFFF',
  secondary: '#000000',
  background: '#000000',
  menuBackground: '#FFBB00',
  menuLogo: '#000000',
  menuText: '#000000',
  menuHighlight: '#ffffff',
  highlight: '#ededed',
  highlightBorder: '#FFBB00',
  logoColor: '#ffffff',
  lightgrey: 'lightgrey',
  titleBackground: '#FFBB00',
  titleBorder: '#DE0000',
  id: 'dark',
};

export const GREEN_RED_COLORS = {
  background: '#00A726',
  menuBackground: '#00A726',
  titleBackground: '#000000',
  titleBorder: '#DE0000',
  highlight: '#FFD700',
  main: '#FFFFFF',
  logoColor: '#0004ff',
  menuLogo: '#0004ff',
  menuText: '#000000',
  menuHighlight: '#0004ff',
};

export const WHITE_PINK_BLUE_COLORS = {
  background: '#FBFAF5',
  titleBackground: '#E0FFFF',
  titleBorder: '#FFB6C1',
  highlight: '#FFB6C1',
  highlightBorder: '#FFB6C1',
  menuBackground: '#DE0000',
  main: '#FFB6C1',
  secondary: '#ffffff',
  lightgrey: '#fafafa',
  logoColor: '#FFB6C1',
  menuLogo: '#FFB6C1',
  menuText: '#FFB6C1',
  menuHighlight: '#ffffff',
};

export const UPS_COLORS = {
  background: '#7B3F00',
  titleBackground: '#ffffff',
  titleBorder: '#FFA500',
  highlight: '#FFA500',
  highlightBorder: '#FFA500',
  main: '#ffffff',
  menuBackground: '#7b3f00',
  secondary: '#ffffff',
  lightgrey: '#fafafa',
  logoColor: '#FFA500',
  menuLogo: '#FFA500',
  menuText: '#ffffff',
  menuHighlight: '#FFA500',
};

export const DONUT_COLORS = {
  background: '#ffffff',
  titleBackground: '#FF671F',
  titleBorder: '#653819',
  highlight: '#DA1884',
  highlightBorder: '#653819',
  main: '#000000',
  menuBackground: '#FF671F',
  secondary: '#653819',
  lightgrey: '#fafafa',
  logoColor: '#653819',
  menuLogo: '#653819',
  menuText: '#ffffff',
  menuHighlight: '#653819',
};

export const PI_CLASSIC_COLORS = {
  background: '#0500AD',
  titleBackground: '#FFBB00',
  logoColor: '#FFBB00',
  titleBorder: 'red',
  highlight: '#FFBB00',
  highlightBorder: '#FFBB00',
  main: '#ffffff',
  secondary: '#0500AD',
  lightgrey: 'lightgrey',
  menuLogo: '#FFBB00',
  menuText: '#ffffff',
  menuHighlight: '#FFBB00',
  menuBackground: '#0500AD',
};

export const JOHANNA_COLORS = {
  background: '#ccfffe',
  titleBackground: '#63f7f5',
  titleBorder: '#4657f2',
  highlight: '#2c47c9',
  highlightBorder: '#2c47c9',
  menuBackground: '#FFBB00',
  main: '#2c47c9',
  logoColor: '#2c47c9',
  menuLogo: '#2c47c9',
  menuText: '#2c47c9',
  menuHighlight: '#ffffff',
};

export const RED_PINK_COLORS = {
  background: '#DE0000',
  titleBackground: '#FF10F0',
  titleBorder: '#ffffff',
  red: '#ffffff',
  highlight: '#FFB6C1',
  highlightBorder: '#FFB6C1',
  menuBackground: '#FFB6C1',
  main: '#ffffff',
  secondary: '#ffffff',
  lightgrey: '#fafafa',
  logoColor: '#FFB6C1',
  menuLogo: '#ffffff',
  menuText: '#ffffff',
  menuHighlight: '#ffffff',
};

export const REGGAE_COLORS = {
  background: '#00A726',
  main: '#000000',
  titleBackground: '#FFBB00',
  titleBorder: '#DE0000',
  highlight: '#DE0000',
  highlightBorder: '#DE0000',
  logoColor: '#FFBB00',
  menuBackground: '#00A726',
  menuLogo: '#FFBB00',
  menuText: '#FFBB00',
  menuHighlight: '#DE0000',
};

export const IRIS_COLORS = {
  background: '#f211c1',
  titleBackground: '#ffffff',
  titleBorder: '#1511f2',
  highlight: '#f7f70a',
  highlightBorder: '#f7f70a',
  menuBackground: '#f211c1',
  main: '#ffffff',
  logoColor: '#f7f70a',
  menuLogo: '#f7f70a',
  menuText: '#ffffff',
  menuHighlight: '#f7f70a',
};

export const BEN_COLORS = {
  background: '#000000',
  titleBackground: '#224c70',
  titleBorder: '#ffffff',
  highlight: '#3185cf',
  highlightBorder: '#3185cf',
  menuBackground: '#000000',
  main: '#ffffff',
  logoColor: '#1511f2',
  menuLogo: '#3185cf',
  menuText: '#ffffff',
  menuHighlight: '#3185cf',
};

export const FIONA_COLORS = {
  background: '#333d2f',
  titleBackground: '#9aaed2',
  titleBorder: '#e9340f',
  highlight: '#e5f4f0',
  highlightBorder: '#e5f4f0',
  menuBackground: '#333d2f',
  main: '#ffffff',
  logoColor: '#e9340f',
  menuLogo: '#e9340f',
  menuText: '#ffffff',
  menuHighlight: '#e9340f',
};

export const WILL_COLORS = {
  background: '#171711',
  titleBackground: '#7bad07',
  titleBorder: '#dce317',
  highlight: '#cf0c0c',
  highlightBorder: '#cf0c0c',
  menuBackground: '#171711',
  main: '#7bad07',
  logoColor: '#cf0c0c',
  menuLogo: '#cf0c0c',
  menuText: '#7bad07',
  menuHighlight: '#cf0c0c',
};

export const MICHAEL_COLORS = {
  background: '#FFFFFF',
  titleBackground: '#FFFFFF',
  titleBorder: '#DE0000',
  highlight: '#DE0000',
  highlightBorder: '#DE0000',
  menuBackground: '#FFBB00',
  main: PI_CLASSIC_COLORS.background,
  logoColor: '#DE0000',
  menuLogo: '#DE0000',
  menuText: PI_CLASSIC_COLORS.background,
  menuHighlight: '#DE0000',
};

export const JENNIE_COLORS = {
  background: '#000000',
  titleBackground: '#E0115F',
  titleBorder: '#FFFFFF',
  highlight: '#E0115F',
  highlightBorder: '#E0115F',
  menuBackground: '#000000',
  main: '#FFFFFF',
  logoColor: '#E0115F',
  menuLogo: '#E0115F',
  menuText: '#FFFFFF',
  menuHighlight: '#E0115F',
};

export const RICH_COLORS = {
  background: '#DE0000',
  titleBackground: '#FFBB00',
  titleBorder: '#FFBB00',
  highlight: '#FFBB00',
  highlightBorder: '#FFBB00',
  menuBackground: '#DE0000',
  main: '#FFBB00',
  logoColor: '#FFA500',
  menuLogo: '#FFA500',
  menuText: '#FFBB00',
  menuHighlight: '#ffffff',
};

export const BLAKE_COLORS = {
  background: '#6F4E37',
  titleBackground: '#5EFF33',
  titleBorder: '#5EFF33',
  highlight: '#FF33A1',
  highlightBorder: '#FF33A1',
  menuBackground: '#6F4E37',
  main: '#FF33A1',
  logoColor: '#FF33A1',
  menuLogo: '#FF33A1',
  menuText: '#FF33A1',
  menuHighlight: '#5EFF33',
};

export const PETER_COLORS = {
  main: '#6600cc',
  background: '#00cca3',
  titleBackground: '#e600e6',
  titleBorder: '#ffff66',
  highlight: '#ff0066',
  highlightBorder: '#ff0066',
  logoColor: '#e600e6',
  menuBackground: '#00cca3',
  menuLogo: '#e600e6',
  menuText: '#6600cc',
  menuHighlight: '#ff0066',
};

export const VIOLETTE_COLORS = {
  main: '#DE0000',
  background: '#000000',
  titleBackground: '#DE0000',
  titleBorder: '#00007f',
  highlight: '#ffffff',
  highlightBorder: '#ffffff',
  logoColor: '#ffffff',
  menuBackground: '#000000',
  menuLogo: '#ffffff',
  menuText: '#DE0000',
  menuHighlight: '#ffffff',
};

export const BIBLE_COLORS = {
  main: '#ffffff',
  background: '#c9c9c9',
  titleBackground: '#ffeedd',
  titleBorder: '#ffe8ea',
  highlight: '#ffe8ea',
  highlightBorder: '#ffe8ea',
  logoColor: '#ffe8ea',
  menuBackground: '#FFBB00',
  menuLogo: '#ffe8ea',
  menuText: '#ffffff',
  menuHighlight: '#ffe8ea',
};

export const CAMRON_COLORS = {
  main: '#000000',
  background: '#F0EAD6',
  titleBackground: '#FFC300',
  titleBorder: '#000000',
  highlight: '#e8ad30',
  highlightBorder: '#ffffff',
  logoColor: '#000000',
  menuBackground: '#F0EAD6',
  menuLogo: '#000000',
  menuText: '#000000',
  menuHighlight: '#e8ad30',
};

export const DAKOTA_COLORS = {
  main: '#ffffff',
  background: '#1B003F',
  titleBackground: '#A4FF00',
  titleBorder: '#000000',
  highlight: '#A4FF00',
  highlightBorder: '#ffffff',
  logoColor: '#A4FF00',
  menuBackground: '#1B003F',
  menuLogo: '#A4FF00',
  menuText: '#ffffff',
  menuHighlight: '#A4FF00',
};

export const CAROLINE_COLORS = {
  main: '#161925',
  background: '#8C1C13',
  titleBackground: '#D1CCDC',
  titleBorder: '#F5EDF0',
  highlight: '#BFDBF7',
  highlightBorder: '#BFDBF7',
  logoColor: '#BFDBF7',
  menuBackground: '#8C1C13',
  menuLogo: '#BFDBF7',
  menuText: '#161925',
  menuHighlight: '#BFDBF7',
};

export const CALLOWAY_COLORS = {
  main: '#ffffff',
  background: '#3185cf',
  titleBackground: '#DE0000',
  titleBorder: '#FFBB00',
  highlight: '#FFBB00',
  highlightBorder: '#FFBB00',
  menuBackground: '#3185cf',
  menuLogo: '#DE0000',
  menuText: '#ffffff',
  menuHighlight: '#FFBB00',
};

export const DEFAULT_COLORS = {
  main: '#000000',
  secondary: '#FFFFFF',
  background: '#FFFFFF',
  highlight: '#0500AD',
  highlightBorder: '#000000',
  logoColor: '#0500AD',
  titleBackground: '#FFBB00',
  titleBorder: '#DE0000',
  menuBackground: '#0500AD',
  menuLogo: '#FFBB00',
  menuText: '#ffffff',
  menuHighlight: '#FFBB00',
};

export const THEME_CONFIG = {
  [ProfileThemeType.Default]: {
    name: 'Default',
    isPremium: false,
    boxes: [
      { bg: 'white', border: '1px solid', borderColor: perfectlyImperfectColors.brand.blue },
      { bg: perfectlyImperfectColors.brand.black },
      { bg: perfectlyImperfectColors.brand.blue },
    ],
  },
  [ProfileThemeType.Dark]: {
    name: 'Dark',
    isPremium: false,
    boxes: [
      { bg: DARK_COLORS.background },
      { bg: DARK_COLORS.titleBackground },
      { bg: DARK_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.PiClassic]: {
    name: 'PI Classic',
    isPremium: false,
    boxes: [
      { bg: PI_CLASSIC_COLORS.background },
      { bg: PI_CLASSIC_COLORS.highlight },
      { bg: '#fff', border: '1px solid', borderColor: PI_CLASSIC_COLORS.background },
    ],
  },
  [ProfileThemeType.PinkAndRed]: {
    name: 'Red and Pink',
    isPremium: false,
    boxes: [
      { bg: RED_PINK_COLORS.background },
      { bg: RED_PINK_COLORS.highlight },
      { bg: '#fff', border: '1px solid', borderColor: RED_PINK_COLORS.highlight },
    ],
  },
  [ProfileThemeType.GreenAndRed]: {
    name: 'Green',
    isPremium: false,
    boxes: [
      { bg: GREEN_RED_COLORS.background },
      { bg: GREEN_RED_COLORS.titleBackground },
      { bg: GREEN_RED_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.WhiteLightBluePink]: {
    name: 'Soft white',
    isPremium: true,
    boxes: [
      {
        bg: WHITE_PINK_BLUE_COLORS.background,
        border: '1px solid',
        borderColor: WHITE_PINK_BLUE_COLORS.titleBorder,
      },
      { bg: WHITE_PINK_BLUE_COLORS.titleBackground },
      { bg: WHITE_PINK_BLUE_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.BrownOrange]: {
    name: 'UPS',
    isPremium: true,
    boxes: [
      { bg: UPS_COLORS.background },
      { bg: UPS_COLORS.highlight },
      { bg: '#fff', border: '1px solid', borderColor: UPS_COLORS.background },
    ],
  },
  [ProfileThemeType.Johanna]: {
    name: 'Johanna',
    isPremium: true,
    boxes: [
      { bg: JOHANNA_COLORS.background },
      { bg: JOHANNA_COLORS.titleBorder },
      { bg: JOHANNA_COLORS.highlight },
    ],
  },
  [ProfileThemeType.Reggae]: {
    name: 'Robbie',
    isPremium: true,
    boxes: [
      { bg: REGGAE_COLORS.background },
      { bg: REGGAE_COLORS.titleBackground },
      { bg: REGGAE_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Iris]: {
    name: 'Iris',
    isPremium: true,
    boxes: [
      { bg: IRIS_COLORS.background },
      { bg: IRIS_COLORS.titleBorder },
      { bg: IRIS_COLORS.highlight },
    ],
  },
  [ProfileThemeType.Ben]: {
    name: 'Ben',
    isPremium: true,
    boxes: [
      { bg: BEN_COLORS.background },
      { bg: BEN_COLORS.titleBackground },
      { bg: BEN_COLORS.highlight },
    ],
  },
  [ProfileThemeType.Fiona]: {
    name: 'Fiona',
    isPremium: true,
    boxes: [
      { bg: FIONA_COLORS.background },
      { bg: FIONA_COLORS.titleBackground },
      { bg: FIONA_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Will]: {
    // WILL_COLORS
    name: 'Will',
    isPremium: true,
    boxes: [
      { bg: WILL_COLORS.highlight },
      { bg: WILL_COLORS.background },
      { bg: WILL_COLORS.titleBackground },
    ],
  },
  [ProfileThemeType.Jennie]: {
    // JENNIE_COLORS
    name: 'Jennie',
    isPremium: true,
    boxes: [
      { bg: JENNIE_COLORS.background },
      { bg: JENNIE_COLORS.main, border: '1px solid', borderColor: JENNIE_COLORS.background },
      { bg: JENNIE_COLORS.highlight },
    ],
  },
  [ProfileThemeType.Michael]: {
    // MICHAEL_COLORS
    name: 'Michael',
    isPremium: true,
    boxes: [
      {
        bg: MICHAEL_COLORS.background,
        border: '1px solid',
        borderColor: MICHAEL_COLORS.background,
      },
      { bg: MICHAEL_COLORS.main },
      { bg: MICHAEL_COLORS.highlight },
    ],
  },
  [ProfileThemeType.Rich]: {
    name: 'Rich',
    isPremium: true,
    boxes: [
      { bg: RICH_COLORS.background },
      { bg: RICH_COLORS.titleBackground },
      { bg: RICH_COLORS.background },
    ],
  },
  [ProfileThemeType.Peter]: {
    name: 'Peter',
    isPremium: true,
    boxes: [
      { bg: PETER_COLORS.background },
      { bg: PETER_COLORS.titleBackground },
      { bg: PETER_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Violette]: {
    name: 'Violette',
    isPremium: true,
    boxes: [
      { bg: VIOLETTE_COLORS.background },
      { bg: VIOLETTE_COLORS.titleBackground },
      { bg: VIOLETTE_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Bible]: {
    name: 'Bible',
    isPremium: true,
    boxes: [
      { bg: BIBLE_COLORS.background },
      { bg: BIBLE_COLORS.titleBackground },
      { bg: BIBLE_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Calloway]: {
    name: 'Calloway',
    isPremium: true,
    boxes: [
      { bg: CALLOWAY_COLORS.background },
      { bg: CALLOWAY_COLORS.titleBackground },
      { bg: CALLOWAY_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Donut]: {
    name: 'Donut',
    isPremium: true,
    boxes: [
      { bg: DONUT_COLORS.background, border: '1px solid', borderColor: DONUT_COLORS.background },
      { bg: DONUT_COLORS.titleBackground },
      { bg: DONUT_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Camron]: {
    name: 'Camron',
    isPremium: true,
    boxes: [
      { bg: CAMRON_COLORS.background },
      { bg: CAMRON_COLORS.titleBackground },
      { bg: CAMRON_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Dakota]: {
    name: 'Dakota',
    isPremium: true,
    boxes: [
      { bg: DAKOTA_COLORS.background },
      { bg: DAKOTA_COLORS.titleBackground },
      { bg: DAKOTA_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Caroline]: {
    name: 'Caroline',
    isPremium: true,
    boxes: [
      { bg: CAROLINE_COLORS.background },
      { bg: CAROLINE_COLORS.titleBackground },
      { bg: CAROLINE_COLORS.titleBorder },
    ],
  },
  [ProfileThemeType.Blake]: {
    name: 'Blake',
    isPremium: true,
    boxes: [
      { bg: BLAKE_COLORS.background },
      { bg: BLAKE_COLORS.titleBackground },
      { bg: BLAKE_COLORS.highlight },
    ],
  },
};

export const ThemeColorMap: Record<ProfileThemeType, ThemeColors> = {
  [ProfileThemeType.Default]: DEFAULT_COLORS,
  [ProfileThemeType.GreenAndRed]: GREEN_RED_COLORS,
  [ProfileThemeType.PiClassic]: PI_CLASSIC_COLORS,
  [ProfileThemeType.Dark]: DARK_COLORS,
  [ProfileThemeType.PinkAndRed]: RED_PINK_COLORS,
  [ProfileThemeType.BrownOrange]: UPS_COLORS,
  [ProfileThemeType.WhiteLightBluePink]: WHITE_PINK_BLUE_COLORS,
  [ProfileThemeType.Reggae]: REGGAE_COLORS,
  [ProfileThemeType.Johanna]: JOHANNA_COLORS,
  [ProfileThemeType.Iris]: IRIS_COLORS,
  [ProfileThemeType.Ben]: BEN_COLORS,
  [ProfileThemeType.Fiona]: FIONA_COLORS,
  [ProfileThemeType.Will]: WILL_COLORS,
  [ProfileThemeType.Michael]: MICHAEL_COLORS,
  [ProfileThemeType.Jennie]: JENNIE_COLORS,
  [ProfileThemeType.Rich]: RICH_COLORS,
  [ProfileThemeType.Peter]: PETER_COLORS,
  [ProfileThemeType.Violette]: VIOLETTE_COLORS,
  [ProfileThemeType.Bible]: BIBLE_COLORS,
  [ProfileThemeType.Camron]: CAMRON_COLORS,
  [ProfileThemeType.Caroline]: CAROLINE_COLORS,
  [ProfileThemeType.Dakota]: DAKOTA_COLORS,
  [ProfileThemeType.Donut]: DONUT_COLORS,
  [ProfileThemeType.Blake]: BLAKE_COLORS,
  [ProfileThemeType.Calloway]: CALLOWAY_COLORS,
};

export function generateThemeClasses(): string {
  const cssClasses: string[] = [];

  // Helper to convert camelCase to kebab-case for CSS vars
  const toKebabCase = (str: string) => str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

  // Map of theme color properties to CSS variable names
  const variableMap: Record<string, string> = {
    background: '--theme-background',
    titleBackground: '--theme-title-background',
    titleBorder: '--theme-title-border',
    highlight: '--theme-highlight',
    highlightBorder: '--theme-highlight-border',
    menuBackground: '--theme-menu-background',
    menuLogo: '--theme-menu-logo',
    menuText: '--theme-menu-text',
    menuHighlight: '--theme-menu-highlight',
    main: '--theme-main',
    logoColor: '--theme-logo-color',
  };

  // Generate a class for each theme, merging with DEFAULT_COLORS
  Object.entries(ThemeColorMap).forEach(([themeKey, themeColors]) => {
    const className = `.theme-${toKebabCase(themeKey)}`;

    // Merge default colors with theme-specific colors, with theme colors taking precedence
    const mergedColors = { ...DEFAULT_COLORS, ...themeColors };

    const cssVars = Object.entries(mergedColors)
      .map(([colorKey, value]) => {
        const cssVarName = variableMap[colorKey];
        return cssVarName ? `  ${cssVarName}: ${value};` : null;
      })
      .filter(Boolean)
      .join('\n');

    if (cssVars) {
      cssClasses.push(`${className} {\n${cssVars}\n}`);
    }
  });

  return cssClasses.join('\n\n');
}

export function chakraToTailwind(chakraStyle: string) {
  if (chakraStyle === 'grey') {
    return 'gray-500';
  }
  // brand.highlight -> text-brand-highlight
  return chakraStyle.replace(/brand\./, 'brand-');
}
