import { useFragment, useMutation, useApolloClient } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useEffect, useState, useMemo } from 'react';
import { gql } from 'shared/__generated__/gql';
import { safeParse } from 'shared/utils';
import { AddOrUpdateRecommendationMessages } from 'shared/webview/messages';
import useRecAttachments from './use-rec-attachments';
import { RecommendationAttachmentFragmentDoc } from 'shared/__generated__/graphql';

export default function useRecPhoto() {
  const [attachments, setAttachments] = useState([]);

  const [getSignedURLsForRecOrDraftRecImage, { data, reset }] = useMutation(GET_SIGNED_URLS);
  const [markUserPhotosAsUploaded] = useMutation(MARK_AS_UPLOADED, { fetchPolicy: 'network-only' });

  const toast = useToast();

  const onUpload = async (
    fullFile: File,
    { recId, draftRecId }: { recId?: string; draftRecId?: string } = {},
    position?: number,
  ) => {
    return new Promise((resolve, reject) => {
      if (!fullFile) return;

      const variables = {
        recId,
        draftRecId,
        ...(typeof position === 'number' ? { position } : {}),
        fileInput: {
          name: fullFile.name || 'RecTemp.jpg',
          type: fullFile.type,
          size: fullFile.size,
        },
      };

      getSignedURLsForRecOrDraftRecImage({
        variables,
        onError: (e) => {
          console.error('here1', e);
          toast({
            title: 'An error occurred...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          reject(e);
        },
        onCompleted: async (res) => {
          const {
            getSignedURLsForRecOrDraftRecImage: { newFileSignedUrl, newFile, attachment },
          } = res;

          try {
            fetch(newFileSignedUrl, {
              method: 'PUT',
              body: fullFile,
              headers: {
                'Access-Control-Allow-Headers': 'Content-Type',
                'Content-Type': fullFile.type,
              },
            })
              .then(() => {
                markUserPhotosAsUploaded({
                  variables: {
                    fileId: newFile.id,
                    width: fullFile.width,
                    height: fullFile.height,
                  },
                }).then((res) => {
                  toast({
                    status: 'success',
                    title: 'PHOTO UPLOADED.',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                  });

                  const newAttachment = {
                    ...attachment,
                    file: {
                      ...newFile,
                      ...res.data.markFileAsUploaded,
                    },
                  };

                  setAttachments((prev) => [...prev, newAttachment]);
                  resolve(newFile);
                });
              })
              .catch((e) => {
                console.error('here2', e);
                toast({
                  title: 'An error occurred...',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  position: 'top',
                });
                reject(e);
              });
          } catch (e) {
            console.error('here3', e);
            toast({
              title: 'An error occurred...',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            reject(e);
          }
        },
      });
    });
  };

  useEffect(() => {
    const cb = (event) => {
      const { msg, value } = safeParse(event.data) || { msg: event.data, value: null };
      if (msg === AddOrUpdateRecommendationMessages.SYNC_ATTACHMENT) {
        setAttachments((prev) => [...prev, value]);
      }
    };
    const target = window.IS_IOS ? window : document;
    target.addEventListener('message', cb);
    return () => target.removeEventListener('message', cb);
  }, []);

  const { onRemoveAttachment } = useRecAttachments();

  const { complete, data: attachmentFragment } = useFragment({
    fragment: gql(/* GraphQL */ `
      fragment RecAttachment on Attachment {
        ...RecommendationAttachment
      }
    `),
    fragmentName: 'RecommendationAttachment',
    from: {
      __typename: 'Attachment',
      id: data?.getSignedURLsForRecOrDraftRecImage?.rec?.attachments?.map((att) => att.id) || [],
    },
  });

  const client = useApolloClient();

  const attachmentsFragment = useMemo(() => {
    return attachments.map((a) => {
      const fragmentData = client.readFragment({
        id: `Attachment:${a.id}`,
        fragmentName: 'RecommendationAttachment',
        fragment: RecommendationAttachmentFragmentDoc,
      });
      return fragmentData?.id ? fragmentData : a;
    });
  }, [attachments]);

  return {
    onUpload,
    onRemoveAttachment,
    attachments: attachmentsFragment,
    setAttachments,
    reset,
  };
}

const GET_SIGNED_URLS = gql(/* GraphQL */ `
  mutation getSignedURLsForRecOrDraftRecImageWeb(
    $fileInput: SignedURLInput!
    $recId: String
    $draftRecId: String
    $position: Int
  ) {
    getSignedURLsForRecOrDraftRecImage(
      fileInput: $fileInput
      recId: $recId
      draftRecId: $draftRecId
      position: $position
    ) {
      newFileSignedUrl
      newFile {
        id
        url
        extension
        name
        contentType
        compressedKey
      }

      attachment {
        id
        ...RecommendationAttachment
      }

      attachments {
        id
        ...RecommendationAttachment
      }

      rec {
        id
        attachments {
          ...RecommendationAttachment
        }
      }

      draftRec {
        id
        attachments {
          ...RecommendationAttachment
        }
      }
    }
  }
`);

const MARK_AS_UPLOADED = gql(/* GraphQL */ `
  mutation markFileAsUploadedOriginalWeb($fileId: String!) {
    markFileAsUploaded(fileId: $fileId) {
      id
      url
      extension
      name
      contentType
      compressedKey
      isUploaded
      height
      width
    }
  }
`);
