'use client';
import {
  InputGroup,
  Input,
  InputRightAddon,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
  useToast,
  Stack,
  Link,
  useDisclosure,
  BoxProps,
  Box,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { subscribeSchema } from '../../validation/schemas';

import { useMutation, gql } from '@apollo/client';
import { useAuth } from '../hooks/useAuth';
import { useRouter } from '@tanstack/react-router';
import { ModalNames, useModalState } from '../../../../apps/web2/app/misc/wrappers/modal-provider';
import { useEffect } from 'react';
import { isBefore, sub } from 'date-fns';
import SimpleSubscribeModal from '../../../../apps/web2/app/components/modals/simple-subscribe-modal';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import useUpdateSetupStep from '../hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { usePathname } from '../hooks/routerHooks';

type Inputs = {
  email: string;
};

type Props = {
  ctaText?: string;
  confirmationText?: string;
  promptToInstallApp?: boolean;
  submitText?: string;
  inviteCode?: string | null;
  redirectTo?: string | null;
  autoOpenModalVersion?: boolean;
  autoOpenTimeout?: number;
  showForAuthenticatedUsers?: boolean;
  onlyInput?: boolean;
  containerProps?: BoxProps;
  withBorder?: boolean;
  withPadding?: boolean;
  withAutoFocus?: boolean;
  eventId?: string | null;
  eventTitle?: string | null;
  enableRedirectToEmailSignIn?: boolean;
  updateSignUpStep?: boolean;
};

const SUBSCRIBE_LAST_NOTICE_DATE = 'SUBSCRIBE_LAST_NOTICE_DATE';

let HAS_SEEN_SIGN_UP_MODAL = false;

export default function InlineSubscribeToSignUp({
  ctaText = 'a taste of someone’s taste, right in your inbox.',
  submitText = 'Subscribe',
  confirmationText = 'Subscribed!',
  redirectTo = '/sign-up/more',
  autoOpenModalVersion = false,
  autoOpenTimeout = 10 * 1000,
  promptToInstallApp = true,
  showForAuthenticatedUsers = false,
  inviteCode,
  onlyInput,
  containerProps,
  withBorder = true,
  withPadding = true,
  withAutoFocus = false,
  eventId = null,
  eventTitle = null,
  enableRedirectToEmailSignIn = false,
  updateSignUpStep = true,
}: Props) {
  const { signIn, ...auth } = useAuth();
  const toast = useToast();
  const finishSignUpModalState = useModalState(ModalNames.FINISH_SIGN_UP);
  const simpleSubscribeModalState = useDisclosure();
  const { updateStep } = useUpdateSetupStep();

  const { handleSubmit, register, formState, watch } = useForm<Inputs>({
    resolver: yupResolver(subscribeSchema),
  });

  const { errors } = formState;

  const values = watch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (autoOpenModalVersion && !token && !auth.user) {
      const date = localStorage.getItem(SUBSCRIBE_LAST_NOTICE_DATE);

      let hasEnoughTimePassed = false;

      if (date) {
        try {
          hasEnoughTimePassed = isBefore(new Date(date), sub(new Date(), { days: 3 }));
        } catch (e) {}
      }

      if (!HAS_SEEN_SIGN_UP_MODAL && (!date || (date && hasEnoughTimePassed && !values.email))) {
        const timeoutRef = setTimeout(() => {
          if (!formState.isDirty) {
            localStorage.setItem(SUBSCRIBE_LAST_NOTICE_DATE, new Date().toString());
            HAS_SEEN_SIGN_UP_MODAL = true;
            simpleSubscribeModalState.onOpen();
          }
        }, autoOpenTimeout);

        return () => clearTimeout(timeoutRef);
      }
    }
  }, [values?.email, autoOpenTimeout, autoOpenModalVersion]);

  const [mutation, mutationInfo] = useMutation(SIGN_UP_EMAIL_ONLY);
  const [markAsAttendingEvent, markAsAttendingEventInfo] = useMutation(MARK_AS_ATTENDING_EVENT);

  const router = useRouter();

  const { trackEvent } = useAnalytics();

  const pathname = usePathname();

  const onSubmit: SubmitHandler<Inputs> = async ({ email }, event) => {
    event?.preventDefault();
    await trackEvent('Click', 'Subscribe_Button', { email, inviteCode });
    await mutation({
      variables: {
        input: { email },
        inviteCode,
        shouldConfirm: true,
      },
      onCompleted: async (data) => {
        const token = data?.signUpEmailOnly;
        if (token) {
          await signIn(token);
          if (updateSignUpStep && !eventId && !eventTitle) {
            await updateStep(USER_SIGN_UP_STEP.MORE);
          }
        }
        toast({
          title: confirmationText,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        if (redirectTo) {
          router.navigate({ to: redirectTo });
        }
        if (eventId || eventTitle) {
          await markAsAttendingEvent({
            variables: { eventId, eventTitle },
          });
        }
      },
      onError: (e) => {
        // console.log('error', e.graphQLErrors[0]?.extensions?.surfaceToUser);
        if (e.graphQLErrors[0]?.extensions?.surfaceToUser) {
          const { field, redirectToEmailSignIn } = e.graphQLErrors[0].extensions;

          if (redirectToEmailSignIn && enableRedirectToEmailSignIn) {
            toast({
              title: 'You already have an account, please sign in.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            router.navigate({
              to: `/sign-in?destination=${encodeURIComponent(pathname)}`,
            });
          }

          if (field === 'email') {
            // console.log('error with email', e.message);
            return;
          }
        }
      },
    });
  };

  const onMarkAsAttendingEvent = async () => {
    await markAsAttendingEvent({
      variables: { eventId, eventTitle },
    });
  };

  if (auth.user && (eventId || eventTitle)) {
    return (
      <Stack
        border={withBorder ? (onlyInput ? undefined : '1px dashed') : undefined}
        borderColor={withBorder ? (onlyInput ? undefined : 'brand.main') : undefined}
        p={withPadding ? (onlyInput ? undefined : { base: '18px', md: '24px' }) : undefined}
        spacing="12px"
        backgroundColor="brand.background"
        {...containerProps}
      >
        <Stack spacing={{ base: '18px', md: '24px' }}>
          {markAsAttendingEventInfo.data ? (
            <>
              <Text
                textStyle="brand.headingLg"
                color="brand.highlight"
                fontSize={{ base: '20px', md: '24px' }}
                alignSelf="center"
                textAlign={{ base: 'center' }}
              >
                Thanks for letting us know.
              </Text>
              <div className="flex self-center flex-row gap-[12px]">
                <Button variant="secondary" onClick={() => router.navigate({ to: '/' })}>
                  Go home
                </Button>
                <Button variant="primary" onClick={() => router.navigate({ to: '/sign-up/more' })}>
                  Complete your profile
                </Button>
              </div>
            </>
          ) : (
            <>
              <Text
                textStyle="brand.headingLg"
                color="brand.highlight"
                fontSize={{ base: '20px', md: '24px' }}
                alignSelf="center"
                textAlign={{ base: 'center' }}
              >
                {ctaText}
              </Text>
              <Button
                variant="primary"
                onClick={async () => {
                  await onMarkAsAttendingEvent();
                  toast({
                    title: confirmationText,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                  });
                }}
                w="100%"
                isDisabled={markAsAttendingEventInfo.loading}
                isLoading={markAsAttendingEventInfo.loading}
                fontSize={{ base: submitText.length < 5 ? '20px' : '16px', md: '20px' }}
              >
                {submitText}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    );
  }

  // Signed in from the subscribe flow.
  if (auth.user && mutationInfo.data?.signUpEmailOnly) {
    return (
      <Stack
        border={withBorder ? (onlyInput ? undefined : '1px dashed') : undefined}
        borderColor={withBorder ? (onlyInput ? undefined : 'brand.main') : undefined}
        p={withPadding ? (onlyInput ? undefined : { base: '18px', md: '24px' }) : undefined}
        spacing="12px"
        {...containerProps}
      >
        <Text
          textStyle="brand.headingLg"
          color="brand.highlight"
          fontSize="24px"
          alignSelf="center"
        >
          {confirmationText}
        </Text>
      </Stack>
    );
  }

  return (
    <Box
      {...containerProps}
      border={withBorder ? (onlyInput ? undefined : '1px dashed') : undefined}
      borderColor={withBorder ? (onlyInput ? undefined : 'brand.main') : undefined}
      p={withPadding ? (onlyInput ? undefined : { base: '12px', md: '24px' }) : undefined}
      backgroundColor="brand.background"
    >
      <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={{ base: '18px', md: '24px' }}>
        {onlyInput ? null : (
          <Text
            textStyle="brand.headingLg"
            color="brand.highlight"
            fontSize={{ base: '20px', md: '24px' }}
            alignSelf="center"
            textAlign={{ base: 'center' }}
          >
            {ctaText}
          </Text>
        )}
        <FormControl
          isInvalid={false}
          alignSelf="center"
          maxWidth={{ base: undefined, md: !withBorder || onlyInput ? undefined : '90%' }}
        >
          <InputGroup size="xl">
            <Input
              placeholder="ENTER YOUR EMAIL"
              fontSize={{ base: '16px', md: '20px' }}
              fontWeight="bold"
              padding="6px"
              autoFocus={withAutoFocus}
              {...register('email')}
              borderRight="0px"
              _focus={{
                borderRight: '0px',
                border: '1px solid',
                borderColor: 'brand.main',
              }}
              _placeholder={{
                fontSize: { base: '16px', md: '20px' },
                fontFamily: 'brand.arialNarrowBold',
                fontWeight: 'bold',
              }}
              borderRightWidth={0}
            />
            <InputRightAddon
              bg="none"
              borderLeft="0px"
              borderRadius={0}
              borderColor="brand.main"
              p="4px"
            >
              <Button
                onClick={() => {}}
                variant="primary"
                type="submit"
                p="4px"
                pl="8px"
                pr="8px"
                isDisabled={mutationInfo.loading}
                isLoading={mutationInfo.loading}
                fontSize={{ base: submitText.length < 5 ? '20px' : '16px', md: '20px' }}
              >
                {submitText}
              </Button>
            </InputRightAddon>
          </InputGroup>
          {errors.email && (
            <FormErrorMessage fontWeight="normal">{errors.email?.message}</FormErrorMessage>
          )}
        </FormControl>
      </Stack>
      {simpleSubscribeModalState.isOpen && (
        <SimpleSubscribeModal redirectTo={redirectTo} modalState={simpleSubscribeModalState} />
      )}
    </Box>
  );
}

const SIGN_UP_EMAIL_ONLY = gql(/* GraphQL */ `
  mutation signUpEmailOnly(
    $input: SignUpEmailOnlyInput!
    $inviteCode: String
    $shouldConfirm: Boolean
  ) {
    signUpEmailOnly(input: $input, inviteCode: $inviteCode, shouldConfirm: $shouldConfirm)
  }
`);

const MARK_AS_ATTENDING_EVENT = gql(/* GraphQL */ `
  mutation markAsAttendingEvent($eventId: String, $eventTitle: String) {
    markAsAttendingEvent(eventId: $eventId, eventTitle: $eventTitle)
  }
`);
