import { createFileRoute } from '@tanstack/react-router';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';

export const Route = createFileRoute('/privacy/')({
  component: RouteComponent,
});

function RouteComponent() {
  useAutoSetTheme(ProfileThemeType.Default);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '700px', maxWidth: '100%', padding: '12px', marginTop: '24px' }}>
        <p
          className="s1"
          style={{
            paddingTop: '4pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'left',
          }}
        >
          <u>PRIVACY POLICY</u> <u>PERFECTLY IMPERFECT INC</u>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          Last Updated: January 18th, 2024
        </p>
        <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <ol id="l1">
          <li data-list-text={1}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              ​ 1. INTRODUCTION:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Welcome to Perfectly Imperfect!
            </p>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Whether you are a user or visitor to our websites and/or applications, your privacy is
              of the upmost importance to us. Please carefully read this Privacy Policy—it addresses
              how Perfectly Imperfect and/or our designees, affiliates, assigns, and other third
              parties (individually and collectively, “<b>we</b>”, “<b>us</b>”, “<b>our</b>”, or “
              <b>PI</b>
              ”) collect and/or process your Personal Data, with whom we may share such data, what
              we do to protect such data, and how you can exercise your rights regarding such data
              when using our Services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Given the global nature of the Internet, we may process the Personal Data of residents
              of the European Union (“<b>EU</b>”), and to that end, this Privacy Policy will provide
              all information required by the Regulation (EU) 2016/679 of 27 April 2016 (the “
              <b>General Data Protection Regulation</b>” or “<b>GDPR</b>”). In addition, as we are
              subject to the provisions of the California Consumer Privacy Act of 2018 (“<b>CCPA</b>
              ”), this Privacy Policy will also take into consideration the specificities of this
              Act for the benefit California residents.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              This Privacy Policy applies to the Personal Data that we may collect and/or process
              via the Services (i.e., using our Platforms and/or Applications). This Privacy Policy
              does not apply to Services that may be provided by third parties you may use, access,
              and/or otherwise be redirected to while using the Services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              This Privacy Policy will remain in full force and effect during the duration of your
              use of and/or access to the Services, and for as long as we may continue to collect
              and/or process your Personal Data.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={2}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              2.​ DEFINITIONS:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Capitalized terms have the meaning given to them in this Section 2, whether used in
              plural or singular form:
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              “<b>Applicable Data Protection Laws</b>” means any laws and/or regulations applicable
              to the Parties hereunder according to the nature of the Personal Data processed and/or
              the location of the User.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              “<b>Application(s) / App(s)</b>” means the PI-branded mobile, web, tablet, desktop,
              and/or other applications made available to User by PI through any media or method now
              known or hereinafter devised.
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <b>"Platform(s)"</b>
              means PI’s owned and/or operated websites and other digital products and/or services,
              such as its main platform located at{' '}
              <a
                href="https://www.perfectlyimperfect.fyi"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                www.perfectlyimperfect.fyi
              </a>
              , and all other corresponding pages.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              “<b>GDPR</b>” means the above-referenced regulation concerning the protection of
              natural persons with regard to the processing of personal data and on the free
              movement of such data.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              “<b>Parties</b>
              <a
                href="https://pi.fyi/terms"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                ” means the parties to this Privacy Policy and/or{' '}
              </a>
              <a
                href="/terms"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                Terms of Use
              </a>
              , i.e., PI and User.
            </p>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              “<b>Personal Data</b>” means any data and/or information relating to an
              Identified/Identifiable Natural Person; it being understood that an “
              <b>Identified/Identifiable Natural Person</b>” is one who can be identified, directly
              or indirectly, in particular by reference to an identifier such as a name, an
              identification number,
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              location data, an online identifier such as a social media handle, or to one or more
              factors specific to the physical, physiological, genetic, mental, economic, cultural,
              and/or social identity of that natural person.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              “<b>Profile</b>” means User’s dedicated account and/or environment when registered on
              the Platform and/or App.
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '216%',
                textAlign: 'left',
              }}
            >
              “<b>Services</b>” means all functionalities/features made available to User through
              the Platforms and/or Apps. “<b>User</b>” means any visitor and/or registered
              individual on and/or using the Platforms and/or Apps.
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '13pt',
                textAlign: 'left',
              }}
            >
              <a
                href="https://gdpr-info.eu/art-4-gdpr/"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                All other terms used but not defined herein shall have the meaning set forth in{' '}
              </a>
              <a
                href="https://gdpr-info.eu/art-4-gdpr/"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                Article 4 of the GDPR
              </a>
              .
            </p>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={3}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              3.​ DATA CONTROLLER:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              The Platforms and Apps are edited by Perfectly Imperfect INC, company organized and
              existing under the laws of the United States, with an office located at 191 President
              St., #2, Brooklyn, New York 11231. We act as Controllers for the processing of
              Personal Data.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={4}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              4.​ PERSONAL DATA THAT WE COLLECT:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Depending on your status and/or activities on the Platforms and/or Apps, we may
              collect different categories of Personal Data directly from you and/or from
              third-party websites, for example, when you choose to register on the Platforms and/or
              Apps with one of your social networks’ accounts.
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.5pt' }} cellSpacing={0}>
              <tbody>
                <tr style={{ height: '15pt' }}>
                  <td style={{ width: '499pt' }} colSpan={2} bgcolor="#CCCCCC">
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '2pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      Visitors/Users (Global)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '2pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Activity
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '3pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Categories of Personal Data
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '41pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      1. Using/visiting the Platforms
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l2">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Browsing data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '14pt',
                            textAlign: 'left',
                          }}
                        >
                          Data collected by the use of cookies (if/when you consent to their use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      2. Using cookies according to the{' '}
                      <a href="https://pi.fyi/cookies" target="_blank">
                        Cookie Policy
                      </a>
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l2">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Browsing data.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      3. Contacting PI support team
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l4">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Contact data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'left',
                          }}
                        >
                          Content of your request.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '55pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      4. Users exercising their data protection rights
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l5">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Identification data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Contact data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Content of the request.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Data necessary to reply to the request.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      5. Establishing statistics related to the performance of the Platforms and/or
                      Apps
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    {' '}
                    <ul id="l6">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Aggregate data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'left',
                          }}
                        >
                          Browsing data.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '251pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '6pt',
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      6. Complying with legal requests/litigation
                    </p>
                  </td>
                  <td
                    style={{
                      width: '248pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul>
                      <li>
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '0pt',
                            lineHeight: '12pt',
                            textAlign: 'left',
                          }}
                        >
                          Data necessary to prove PI’s compliance to its obligations and/or manage
                          legal proceedings.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.5pt' }} cellSpacing={0}>
              <tbody>
                <tr style={{ height: '15pt' }}>
                  <td style={{ width: '499pt' }} colSpan={2} bgcolor="#CCCCCC">
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Users (Global)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                  <td
                    style={{
                      width: '249pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Activity
                    </p>
                  </td>
                  <td
                    style={{
                      width: '250pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Categories of Personal Data
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '68pt' }}>
                  <td
                    style={{
                      width: '249pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      1. Register on the Platforms and/or Apps
                    </p>
                  </td>
                  <td
                    style={{
                      width: '250pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l8">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'justify',
                          }}
                        >
                          Personal Data collected and processed by third-party websites if you
                          register with a third-party account (e.g., Instagram, Spotify, etc.).
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'justify',
                          }}
                        >
                          Email address.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '55pt' }}>
                  <td
                    style={{
                      width: '249pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      2. Manage and complete your Profile
                    </p>
                  </td>
                  <td
                    style={{
                      width: '250pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    {' '}
                    <ul id="l9">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Identification data: first and last name, profile picture, social media
                          handle, etc.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Contact details: phone number, email address.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Professional data: company name.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '55pt' }}>
                  <td
                    style={{
                      width: '249pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      3. Publish posts, content, comments, and messages
                    </p>
                  </td>
                  <td
                    style={{
                      width: '250pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    {' '}
                    <ul id="l10">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Identification data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Content of the post (e.g., written works, works of art, ideas/concepts,
                          photographs, links, etc.).
                        </p>
                      </li>{' '}
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Target recipients of the message.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '249pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '18pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      4. Subscribe or consent to newsletters from PI and/or your followed friends
                    </p>
                  </td>
                  <td
                    style={{
                      width: '250pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l11">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'left',
                          }}
                        >
                          Identification data.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '1pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Contact details.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={5}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              5.​ PURPOSE AND LEGAL BASES OF DATA COLLECTION:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              The purpose and legal basis of the processing of your Personal Data differs depending
              on your activities.
            </p>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.5pt' }} cellSpacing={0}>
              <tbody>
                <tr style={{ height: '27pt' }}>
                  <td style={{ width: '499pt' }} colSpan={3} bgcolor="#CCCCCC">
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Visitors/Users (Global)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '14pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '1pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Activity
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '1pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Purpose
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '1pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Legal Basis
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      1
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Editing and allowing access to a public Platform and/or App.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l12">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '7pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to edit a public Platform.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '41pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      2
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '108%',
                        textAlign: 'left',
                      }}
                    >
                      Offering a means of communication between PI and any Users/visitors.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    {' '}
                    <ul id="l13">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '7pt',
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to offer its Users/visitors an efficient way to
                          communicate with PI.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      3
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      See Cookie Policy
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l14">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest for necessary or exempted cookies (e.g., cookies
                          enabling the operation of the Platform).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '54pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      4
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '108%',
                        textAlign: 'justify',
                      }}
                    >
                      Responding to your request for information related to the Services or request
                      for assistance.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l15">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '7pt',
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to offer you a means to contact relevant support
                          teams.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      5
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '14pt',
                        textAlign: 'left',
                      }}
                    >
                      Manage request(s) relating to your data protection rights.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    {' '}
                    <ul id="l15">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '7pt',
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to offer you a means to contact relevant support
                          teams.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '41pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      6
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '108%',
                        textAlign: 'left',
                      }}
                    >
                      Enhance and improve the Services, Platforms and/or Apps.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l16">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '14pt',
                            textAlign: 'left',
                          }}
                        >
                          Legal obligation of PI regarding GDPR and CCPA compliance.
                        </p>
                      </li>
                    </ul>
                    <ul id="l17">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interests of PI to offer optimized Services.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '68pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      7
                    </p>
                  </td>
                  <td
                    style={{
                      width: '144pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '108%',
                        textAlign: 'justify',
                      }}
                    >
                      Justify and demonstrate PI’s compliance with legal obligations in case of
                      legal request and/or legal proceedings.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '283pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l18">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to demonstrate its compliance.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.5pt' }} cellSpacing={0}>
              <tbody>
                <tr style={{ height: '14pt' }}>
                  <td style={{ width: '499pt' }} colSpan={3} bgcolor="#CCCCCC">
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Users
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '14pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      Activity
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      Purpose
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                    bgcolor="#EFEFEF"
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      Legal basis
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      1
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Allow access to the Services.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l19">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '17pt',
                            paddingRight: '63pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'right',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '41pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      2
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Allow you to complete your Profile.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l20">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-18pt',
                            lineHeight: '108%',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to provide you with the possibility to amend and
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '6pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      3
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      Allow you to use the Services, get personalized
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingTop: '1pt',
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      content, share content with friends, etc.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l21">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '6pt',
                            paddingLeft: '17pt',
                            paddingRight: '63pt',
                            textIndent: '-17pt',
                            textAlign: 'right',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      4
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '12pt',
                        textAlign: 'left',
                      }}
                    >
                      Allow you to publish and share content.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l22">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '17pt',
                            paddingRight: '63pt',
                            textIndent: '-17pt',
                            lineHeight: '12pt',
                            textAlign: 'right',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '7pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      6
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p className="s2" style={{ textIndent: '0pt', textAlign: 'left' }}>
                      Communicate with you depending on your settings on news and events, updates,
                      etc.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l23">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingTop: '7pt',
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            textAlign: 'left',
                          }}
                        >
                          User consent.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '14pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      7
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        textIndent: '0pt',
                        lineHeight: '13pt',
                        textAlign: 'left',
                      }}
                    >
                      Allow you to share content, photos, etc.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l24">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '17pt',
                            paddingRight: '63pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'right',
                          }}
                        >
                          Contract (Terms of Use).
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                  <td
                    style={{
                      width: '72pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '6pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      8
                    </p>
                  </td>
                  <td
                    style={{
                      width: '216pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: '6pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      Allow you to make payments on the Platform.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '211pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#cccccc',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#cccccc',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#cccccc',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#cccccc',
                    }}
                  >
                    <ul id="l25">
                      <li data-list-text="●">
                        <p
                          className="s2"
                          style={{
                            paddingLeft: '6pt',
                            textIndent: '-17pt',
                            lineHeight: '13pt',
                            textAlign: 'left',
                          }}
                        >
                          Legitimate interest of PI to improve the User experience on the Platform.
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li data-list-text={6}>
            <h1
              style={{
                paddingTop: '12pt',
                paddingLeft: '13pt',
                textIndent: '-8pt',
                textAlign: 'left',
              }}
            >
              6.​ RECIPIENTS OF YOUR PERSONAL DATA:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              PI remains responsible for processing that it carries out in connection with your
              Personal Data, as the Controller of such Personal Data. Where your Personal Data is
              communicated to recipients described below, PI undertakes to ensure that these
              recipients comply with data protection regulations and respects your right to privacy.
              For instance, with respect to our designees, affiliates, assigns, and other third
              parties, PI enters into data processing agreements (where necessary) to safeguard the
              processing of your Personal Data, and in doing so, these recipients are liable to us
              regarding the security and confidentiality of your Personal Data at all stages of the
              processing that they carry out. However, please note that for activities carried out
              outside of the Platforms and/or Apps (e.g., if you are redirected on a third-party
              platform or application), PI will no longer act as Controller of your Personal Data.
              Therefore, you must refer to such third-party’s data protection policy to know more
              about their commitments and your rights.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <ol>
              <li>
                <p
                  className="s1"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  <u>6.1.​ Internal Recipients:</u>
                  <span className="p">
                    Your Personal Data will only be disclosed to our authorized designees,
                    affiliates, assigns, employees, owned entities, etc. that may require access to
                    fulfill their goals/missions (e.g., support teams, developers, etc.). Such
                    parties are specifically trained and made aware of the sensitivity of your
                    Personal Data and the requirements necessary to ensure the protection of your
                    right to privacy.
                  </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
              <li data-list-text="6.2.">
                <p
                  className="s1"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  <u>6.2. ​ Processors:</u>
                  <span className="p">
                    In order to provide the Services, PI may communicate your Personal Data
                    (including payment details) to other entities acting as data processors:
                  </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <ul id="l27">
                  <li data-list-text="●">
                    <p
                      className="s2"
                      style={{
                        textIndent: '-17pt',
                        textAlign: 'left',
                      }}
                    >
                      Amazon Web Services, Render.com, Cloudflare, Substack – website/newsletter
                      hosting services;
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      className="s2"
                      style={{
                        textIndent: '-17pt',
                        textAlign: 'left',
                      }}
                    >
                      Google Analytics – capturing User analytics;
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingTop: '2pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      <br />
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      className="s2"
                      style={{
                        textIndent: '-17pt',
                        textAlign: 'left',
                      }}
                    >
                      Sentry – bug/error tracking software;
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      className="s2"
                      style={{
                        textIndent: '-17pt',
                        textAlign: 'left',
                      }}
                    >
                      Stripe – payment/credit card processing.
                    </p>
                  </li>
                </ul>
              </li>
              <br />
              <li data-list-text="6.3.">
                <h1
                  style={{
                    paddingLeft: '21pt',
                    textIndent: '-16pt',
                    textAlign: 'left',
                  }}
                >
                  6.3. Partners and Third Parties:
                </h1>
                <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  In order to provide the Services, PI may communicate your Personal Data to third
                  party service providers (e.g., Stripe), and direct you via third-party links to
                  certain third-party websites.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className="s1"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  These partners and third parties are acting as Controllers and have their own
                  terms of services and privacy policies, which you should carefully read if using
                  their services<span className="p">.</span>
                </p>
                <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  Examples of such partners and third parties include: social or music networks
                  (e.g., Instagram, Spotify) offering you the ability to register on the Platform
                  with your account on such networks and/or sync your accounts with your Profile;
                  and payment processors, like Strip, that may be linked to your account which store
                  and process your payment details, offering you the ability to access premium
                  content on the Platform.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
              <li data-list-text="6.4.">
                <p
                  className="s1"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  <u>6.4. ​ Other Users:</u>
                  <span className="p">
                    By sharing your Personal Data publicly or otherwise on the Platforms and/or
                    Apps, other Users may have access to your Profile and any Personal Data you may
                    share thereon, such as posts.
                  </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className="s1"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  <u>6.5. Judicial, Administrative, and Other Authorities</u>:
                  <span className="p">
                    PI may share or disclose your Personal Data if it is required to do so by law,
                    by a request from a competent authority, to comply with a court order, to obtain
                    legal remedies or defend PI’s rights, to contribute with investigations (e.g.
                    fraud, identity theft, etc.), etc.
                  </span>
                </p>
                <p
                  className="s1"
                  style={{
                    paddingTop: '3pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  <u>6.6. Corporate Transactions or Events:</u>
                  <span className="p">
                    PI may disclose some or all of your Personal Data in connection with, or during
                    negotiations of, any merger, sale of company assets, financing or acquisition,
                    or in any other situation where Personal Data may be disclosed or transferred as
                    one of our business assets.
                  </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text={7}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              7.​ DATA PRIVACY FRAMEWORK:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              className="s4"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <a
                href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                PI is based in the United States of America, and we process and store information on
                servers located in the United States. We may store information on servers and
                equipment in other countries depending on a variety of factors, including, but not
                limited to, the locations of Users and other third party service providers. By
                accessing and/or using the Services and/or otherwise providing information to us,
                you consent to the processing, transfer, and storage of information in and to the
                United States and other countries, where you may not have the same rights as you do
                under local law. When we transfer the Personal Data of Users in the EEA, UK, and/or
                Switzerland, we rely on the{' '}
              </a>{' '}
              <a
                href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                className="a"
                target="_blank"
              >
                Standard Contractual{' '}
              </a>
              <span
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                Clauses
              </span>
              <span style={{ color: '#000' }}>
                approved by the European Commission for such transfers or other transfer mechanisms
                deemed ‘adequate' under applicable laws.
              </span>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={8}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              8.​ DISPUTE RESOLUTION &amp; BINDING ARBITRATION:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <a
                href="mailto:privacy@perfectlyimperfect.fyi"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                If you have any inquiries or complaints about our privacy practices, please contact
                us at:{' '}
              </a>{' '}
              <a
                href="privacy@perfectlyimperfect.fyi"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                privacy@perfectlyimperfect.fyi
              </a>
              . We will respond to your inquiry promptly.
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Within the scope of this privacy policy, if a privacy complaint or dispute cannot be
              resolved through our internal processes, we agree to participate in the VeraSafe Data
              Privacy Framework (“<b>DPF</b>
              <a
                href="https://www.verasafe.com/public-resources/dispute-resolution/submit-dispute/"
                style={{
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                ”) Dispute Resolution Program, an alternative dispute resolution provider based in
                the United States. Subject to the terms of the VeraSafe DPF Dispute Resolution
                Program, VeraSafe will provide appropriate recourse free of charge to you. To file a
                complaint with VeraSafe, please submit the required information to VeraSafe here:{' '}
              </a>{' '}
              <a
                href="https://www.verasafe.com/public-resources/dispute-resolution/submit-dispute"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                https://www.verasafe.com/public-resources/dispute-resolution/submit-dispute/
              </a>
              .
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <a
                href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                If neither PI nor VeraSafe resolves your complaint, you may have the right to
                require that we enter into binding arbitration with you through the ICDR-AAA under
                the EU-U.S. DPF Principles under the terms and conditions listed on{' '}
              </a>{' '}
              <a
                href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf"
                className="a"
                target="_blank"
              >
                Annex I: Arbitral Model of the EU-U.S.{' '}
              </a>{' '}
              <span
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                DPF
              </span>
              .
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={9}>
            <h1 style={{ paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>
              9.​ U.S. REGULATORY OVERSIGHT:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              You are hereby informed that your Personal Data shared on the Platforms and/or Apps
              are processed by PI and stored in the United States of America. PI and its affiliated
              entities at all times comply to the EU-U.S. Data Privacy Framework (“
              <b>EU-U.S. DPF</b>
              ”) as set forth by the U.S. Department of Commerce. We may be required to disclose
              personal information that we handle under the EU-U.S. DPF in response to lawful
              requests by public authorities, including to meet national security or law enforcement
              requirements.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={10}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              10.​ RIGHTS REGARDING YOUR PERSONAL DATA:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              The GDPR and the CCPA give Users certain rights related to the use of their Personal
              Data by PI, such as:
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <ul id="l28">
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-18pt',
                    lineHeight: '108%',
                    textAlign: 'left',
                  }}
                >
                  The right to access your Personal Data, meaning the right to be informed on which
                  of your Personal Data is processed by PI and request a copy of such Personal Data.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-17pt',
                    lineHeight: '13pt',
                    textAlign: 'left',
                  }}
                >
                  The right to rectify your Personal Data held by PI.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  The right to ask for the erasure of your Personal Data held by PI.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '4pt',
                    textIndent: '-17pt',
                    textAlign: 'justify',
                  }}
                >
                  The right to restrict the processing of your Personal Data by PI.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-17pt',
                    textAlign: 'justify',
                  }}
                >
                  The right to object to any processing of your Personal Data carried out by PI.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-18pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  The right to withdraw your consent to any processing of your Personal Data freely
                  and at any time.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-18pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  The right to ‘data portability’, meaning you can request a copy of your Personal
                  Data in a readable format and the right to ask for your Personal Data to be
                  transferred to another party’s service.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-18pt',
                    lineHeight: '108%',
                    textAlign: 'justify',
                  }}
                >
                  The right not to be subject to ‘automated decision-making’, meaning the right not
                  to be subject to a decision based solely on automated decision-making, including,
                  without limitation, profiling, where the decision would have a legal effect on you
                  or produces similarly significant effects.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-17pt',
                    lineHeight: '13pt',
                    textAlign: 'justify',
                  }}
                >
                  The right to opt-out of the sale of your Personal Data.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    textIndent: '-17pt',
                    textAlign: 'justify',
                  }}
                >
                  Please note that PI does not and will not sale your Personal Data to any
                  third-party.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-17pt',
                    textAlign: 'justify',
                  }}
                >
                  The right to non-discrimination when you exercise one of the above-mentioned
                  rights.
                </p>
              </li>
            </ul>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>
              The above-mentioned rights may be limited in scope by legal restrictions.
            </p>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              At any time, whenever a processing of your Personal Data is based on your consent
              (such as your consent to receive news and/or other marketing communications), you may
              withdraw such consent at any time and free of charge. When receiving marketing
              communications and/or other news, you may directly “<i>unsubscribe</i>” at the end of
              each communication. Also, you may personalize your choices regarding marketing
              communications directly via your Profile and choose different settings regarding your
              privacy.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <a
                href="mailto:privacy@perfectlyimperfect.fyi"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                If you wish to exercise your rights, please contact us directly:{' '}
              </a>{' '}
              <a
                href="mailto:privacy@perfectlyimperfect.fyi"
                style={{
                  color: '#0000ff',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '11pt',
                }}
              >
                privacy@perfectlyimperfect.fyi
              </a>
              . Please mention the subject of your request and other necessary details to allow for
              its management by our teams.
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              If you are a California resident, you have additional rights under the CCPA,
              including, but not limited to, the right to opt-out of any sales or sharing of your
              personal information, to request access to and information about our data practices,
              and to request deletion or correction of your personal information, as well as the
              right not to be discriminated against for exercising your privacy rights. PI does not
              “sell” or “share” personal information as those terms are defined under the CCPA. We
              do not use or disclose sensitive personal information except to provide you with the
              Services, or as otherwise permitted by the CCPA.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              In the last twelve (12) months, we collected the following categories of personal
              information from California residents, depending on the Services used:
              <b>(1) </b>identifiers, such as your PI username, email address, IP address, and
              cookie information; <b>(2) </b>Internet or other electronic network activity
              information, such as information about your activity on our Services, and (if
              applicable) limited information about your activity on the services of advertisers who
              may use our advertising technology;
              <b>(3) </b>geolocation information based on your IP address, or more specific location
              information if you authorize your device to provide it to us; <b>(4) </b>your messages
              with other Users (e.g., private messages and chats); <b>(5) </b>audiovisual
              information in pictures, audio, or video content submitted to PI; and <b>(6) </b>
              inferences that we may make based on other collected data, for purposes such as
              recommending content, advertising, and analytics.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              If you are a California resident and wish to request information about how to exercise
              your third-party disclosure choices, please send a request as set forth herein. All
              requests must be labeled “<i>Your California Privacy Rights</i>” on the form. For all
              requests, please clearly state that the request is related to “
              <i>Your California Privacy Rights</i>”, include your name, street address, city,
              state, zip code, and e-mail address (your street address is optional if you wish to
              receive a response to your request via email) and indicate your preference on how our
              response to your request should be sent (email or postal mail). We are not responsible
              for notices that are not labeled or sent properly, or do not have complete
              information.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              When sending a request to exercise your rights, PI may need to ask for a means to
              verify your identity before actually processing your request. PI undertakes to take
              all appropriate measures to ensure that it responds to your requests as efficiently as
              possible and under legal time limits set by the GDPR.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              When sending a request to exercise your rights, PI may require you to pay reasonable
              fees related to the administrative costs incurred to answer your request if it is
              deemed to be manifestly unfounded or excessive.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={11}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              11.​ APPLICABLE DATA RETENTION PERIODS:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              It is not possible for PI to retain your Personal Data indefinitely. We have worked on
              establishing proportionate data retention periods for your Personal Data, adapted to
              the processing that we carry out.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              In general, we will keep your Personal Data related to your Profile on the Platforms
              and/or Apps as long as you use our Services and in order to ensure their performance.
              We may also keep your Personal Data for adequate periods of time to ensure that we
              comply with our legal obligations.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Your Personal Data used for marketing purposes (such as sending you newsletters,
              marketing content, etc.) is kept for three (3) years from your last contact with PI or
              until you withdraw your consent or object to such reception of marketing
              communications. After the retention period, your Personal Data will be destroyed or
              anonymized according to Applicable Data Protection Laws. Your Personal Data related to
              the proof of validity of consent will be kept for five (5) years from the collection
              of your consent and in intermediate archiving (i.e., meaning that the access to such
              data is subject to authorization or specific access rights). Finally, some of your
              Personal Data may also be kept for a longer period of time, in intermediate archiving,
              to allow for the management of claims and/or litigations related to PI.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={12}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              12.​ SECURITY AND CONFIDENTIALITY:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              PI has implemented organizational and technical security measures to ensure the
              confidentiality and integrity of your Personal Data. These include administrative,
              organizational, technical, and physical measures designed to protect your Personal
              Data from loss, theft, unauthorized access, unauthorized transmission, modification,
              or destruction. In all cases, PI will assess the nature of the security measures to
              implement depending on the nature of the data processed, and the risks incurred by the
              processing.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              All collected Personal Data is stored in a confidential manner and protected at a very
              high level of security. The servers where this Personal Data is stored complies with
              security standards currently in force. They are protected against cyber-attacks and
              physical attacks. However, despite these efforts, PI cannot guarantee the
              infallibility of this protection because risks may inevitably arise during the
              transmission of Personal Data. No method of transmission over the Internet or via
              mobile devices, or method of electronic storage, is 100% secure, so we cannot
              guarantee its absolute security. You should take certain steps to protect your Profile
              and Personal Data as well, such as by choosing a strong password and keeping such
              password private, and logging out of your Profile when you are finished using it.
              Please read your applicable{' '}
              <a href="https://pi.fyi/terms" className="a" target="_blank">
                Terms of User{' '}
              </a>{' '}
              <span style={{ color: '#000' }}>
                to learn more about the steps you should take to ensure the security of your
                Profile.
              </span>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              PI will not be responsible for any actual or consequential damages that result from a
              lapse in compliance with this Privacy Policy because of a technical malfunction or a
              breach in security.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={13}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              13.​ LINKS TO THIRD-PARTY WEBSITES AND SERVICES:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              The Services may be linked to, rely on and/or be integrated with websites,
              applications, interfaces, services, and platforms operated by other companies,
              including, but not limited to, third-party advertising
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              providers and third-party platforms. The Services may also feature advertisements from
              other third parties. PI is not responsible for the privacy practices or the content of
              such third-party services. For example, Users may be redirected to third-party
              websites when clicking on smart links inserted on messages/posts published on the
              Platforms and/or Apps, etc. Any information you provide via those services is subject
              to the applicable privacy policies of such third-party services and is not covered by
              this Privacy Policy.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={14}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              14.​ USE OF COOKIES:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              When you visit our Platforms and/or Apps, use our Services, or visit a third-party
              website for which we provide certain services (such as artists websites), we and our
              business partners may use cookies and other tracking technologies to customize and
              improve your user experience, the Services you use, and other online content and
              advertising, measure the performances of promotions and establish analytics, etc.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              Certain aspects and features of the Services are only available through the use of
              cookies and trackers, so if you choose to disable or decline them, your use of the
              Platforms, Apps and Services may be limited.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              className="s4"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              <a
                href="https://pi.fyi/cookies"
                style={{
                  color: 'black',
                  fontFamily: '"Times New Roman", serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'none',
                  fontSize: '11pt',
                }}
                target="_blank"
              >
                Please review our{' '}
              </a>
              <a href="https://pi.fyi/cookies" className="a" target="_blank">
                Cookie Policy
              </a>{' '}
              <span style={{ color: '#000' }}>
                to learn more about how we use such cookies, why we use them and how you may change
                your privacy settings regarding such use.
              </span>
            </p>
            <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={15}>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              15.​ OUR PRACTICES REGARDING MINORS:
            </h1>
          </li>
        </ol>
        <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          Depending on your country of residence, the age of majority to use information society
          services, such as the Platforms and/or Apps, may differ from 13 years old to 16 years old.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className="s4"
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          <a
            href="https://pi.fyi/terms"
            style={{
              color: 'black',
              fontFamily: '"Times New Roman", serif',
              fontStyle: 'normal',
              fontWeight: 'normal',
              textDecoration: 'none',
              fontSize: '11pt',
            }}
            target="_blank"
          >
            The Platforms, Apps, and Services are not directed towards minors. Specifically for
            Users, the{' '}
          </a>
          <a href="https://pi.fyi/terms" className="a" target="_blank">
            Terms of Use
          </a>{' '}
          <span style={{ color: '#000' }}>
            and the registration process provide for such prohibition, and Users confirm that they
            have reached the legal age of majority to use information society services in compliance
            with their country of residence.
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          If PI is made aware that it processes the Personal Data of a minor, we undertake to
          promptly delete such Personal Data. If you have reason to believe that we process Personal
          Data from a minor, please complete the Privacy Request form so we can acknowledge this
          situation and react adequately.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className="s4"
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          <a
            href="https://consumer.ftc.gov/"
            style={{
              color: 'black',
              fontFamily: '"Times New Roman", serif',
              fontStyle: 'normal',
              fontWeight: 'normal',
              textDecoration: 'none',
              fontSize: '11pt',
            }}
            target="_blank"
          >
            For users in the United States, please also visit{' '}
          </a>
          <a
            href="https://consumer.ftc.gov"
            style={{
              color: '#0000ff',
              fontFamily: '"Times New Roman", serif',
              fontStyle: 'normal',
              fontWeight: 'normal',
              textDecoration: 'underline',
              fontSize: '11pt',
            }}
          >
            consumer.ftc.gov
          </a>{' '}
          <span style={{ color: '#000' }}>
            for information from the Federal Trade Commission about protecting children’s privacy
            online.
          </span>
        </p>
        <p style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <ol>
          <li>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              16. ​ USEFUL CONTACTS:
            </h1>
            <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '108%',
                textAlign: 'justify',
              }}
            >
              If you have any questions, concerns, criticisms or feedback about our privacy
              practices or any of the terms or conditions of this Privacy Policy, please feel free
              to contact us via the below means. Although we urge you to contact us to find a
              solution for every concern you may have, you always have the right to lodge a
              complaint with your competent data protection authority.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Contacts:</p>
            <ul id="l30">
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  <a
                    href="mailto:privacy@perfectlyimperfect.fyi"
                    style={{
                      color: 'black',
                      fontFamily: '"Times New Roman", serif',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      textDecoration: 'none',
                      fontSize: '11pt',
                    }}
                    target="_blank"
                  >
                    Email:{' '}
                  </a>{' '}
                  <a href="mailto:privacy@perfectlyimperfect.fyi" target="_blank">
                    privacy@perfectlyimperfect.fyi
                  </a>
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s2"
                  style={{
                    paddingTop: '1pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  Mail: 191 President St., #2, Brooklyn, New York 11231
                </p>
                <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h1 style={{ paddingLeft: '18pt', textIndent: '-13pt', textAlign: 'left' }}>
              18.​ MODIFICATION OF THIS PRIVACY POLICY:
            </h1>
          </li>
        </ol>
        <p style={{ paddingTop: '2pt', textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          From time to time, we may modify this Privacy Policy to reflect industry initiatives,
          third-party requirements or changes in the law, technology, our practices regarding the
          collection of Personal Data or the addition of new features and Services. Such
          modifications shall be effective upon posting.
        </p>
        <p
          style={{
            paddingTop: '3pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'justify',
          }}
        >
          If we substantially change our Privacy Policy, we will notify you of such changes by
          posting them on the Platform or by sending you an email or other notification to Users. We
          advise you to regularly review this Privacy Policy in order to have a full understanding
          of how your Personal Data is used.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className="s1"
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '108%',
            textAlign: 'left',
          }}
        >
          PERFECTLY IMPERFECT INC 2024
        </p>
      </div>
    </div>
  );
}
