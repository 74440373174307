import prisma from 'database';
import { createServerFn } from '@tanstack/react-start';

export const isUniqueUsername = createServerFn({ method: 'GET' }).handler(
  async ({ username }: { username: string }) => {
    const user = await prisma.user.findFirst({
      where: {
        username: {
          equals: username,
          mode: 'insensitive',
        },
      },
    });

    return !user;
  },
);

export const userExistsWithUsername = createServerFn({ method: 'GET' }).handler(
  async ({ username }: { username: string }) => {
    const user = await prisma.user.findFirst({
      where: {
        username: {
          equals: username,
          mode: 'insensitive',
        },
      },
    });

    return !!user;
  },
);
