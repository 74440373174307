import ResendWebhookJob, { WebhookEvent } from 'shared/jobs/ResendWebhookJob';
import * as Sentry from '@sentry/node';
import { z } from 'zod';
import { createServerFn } from '@tanstack/start';

export const handleLinkTracking = createServerFn({ method: 'GET' })
  .validator(
    z.object({
      url: z.string(),
      userId: z.string(),
      emailId: z.string().optional(),
    }),
  )
  .handler<string>(async ({ data }) => {
    const {
      url,
      userId,
      emailId: sendEmailLogId,
    } = data as any as {
      url: string;
      userId: string;
      emailId: string;
    };

    try {
      if (userId) {
        let sanitizedUrl = url;

        if (sanitizedUrl.includes('to=') || sanitizedUrl.includes('key=')) {
          sanitizedUrl = sanitizedUrl.split('?')[0];
        }

        // Mock webhook event.
        const event: WebhookEvent = {
          created_at: new Date().toISOString(),
          data: {
            created_at: new Date().toISOString(),
            send_email_log_id: sendEmailLogId,
            tags: {
              userId,
            },
            click: {
              link: sanitizedUrl,
            },
          },
          type: 'email.clicked',
        };

        await ResendWebhookJob.queue('webhook_event', {
          payload: event,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error processing webhook event', error);
    }

    return url;
  });
