import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalContent,
  ModalTitle,
  ModalCloseButton,
  ModalPortal,
} from 'web2/app/components/ui/modal';
import ArrowIcon from 'shared/misc/components/arrow.svg?react';
import DisabledArrow from 'shared/misc/components/dash-arrow.svg?react';
import PerfImageFromFile from 'web2/app/components/by-type/images/perf-image-from-file';
import { X } from 'lucide-react';
import { useDrag } from '@use-gesture/react';

export default function RecommendationImageModal({
  images = [],
  isOpen,
  onClose,
  startIndex = 0,
}: {
  images: any[];
  isOpen: boolean;
  onClose: () => void;
  startIndex?: number;
}) {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const cycleIndex = (direction: 1 | -1) => {
    setCurrentIndex((prev) => {
      const nextIndex = prev + direction;
      if (nextIndex < 0) return images.length - 1;
      if (nextIndex >= images.length) return 0;
      return nextIndex;
    });
  };

  const elementTypes = ['BUTTON', 'IMG', 'SVG', 'PATH'];

  const bind = useDrag(({ movement: [mx], direction: [xDir], distance, cancel }) => {
    if (distance > 50) {
      if (xDir < 0) cycleIndex(1);
      else if (xDir > 0) cycleIndex(-1);
      cancel();
    }
  });

  if (!isOpen || images.length === 0) return null;

  return (
    <>
      <Modal open={isOpen} onOpenChange={onClose}>
        <ModalContent
          hideCloseButton
          onClick={(e) => {
            if (elementTypes.includes(e.target?.tagName.toUpperCase())) return;
            else onClose();
          }}
          className="m-0 bg-transparent p-0 border-none !w-[100dvw] !h-[100dvh] !max-w-[100dvw] !max-h-[100dvh] !top-[50%] !left-[50%] !translate-x-[-50%] !translate-y-[-50%]"
        >
          <ModalTitle className="hidden">Recommendation Image</ModalTitle>
          <ModalCloseButton className="absolute z-10 top-[16px] right-[32px] sm:top-[32px] sm:right-[64px] md:top-[32px] md:right-[96px]">
            <X className="w-8 h-8 bg-white text-black cursor-pointer" />
          </ModalCloseButton>
          <div className="relative flex flex-col items-center w-full overflow-visible justify-center">
            {images.map((image, index) => {
              const isLandscape = image.width > image.height;
              return (
                <div
                  key={index}
                  {...bind()}
                  className="absolute w-full h-full p-[32px] sm:p-[64px] md:p-[128px] pb-[64px] sm:pb-[96px] md:pb-[160px] transition-all duration-300 ease-in-out flex justify-center items-center"
                  style={{
                    left: index === currentIndex ? '50%' : index < currentIndex ? '-100%' : '100%',
                    transform: 'translateX(-50%)',
                    opacity: index === currentIndex ? 1 : 0,
                    pointerEvents: index === currentIndex ? 'auto' : 'none',
                  }}
                >
                  <PerfImageFromFile
                    className={`object-contain ${
                      isLandscape ? 'w-full h-auto max-h-full' : 'w-auto max-w-full h-full'
                    }`}
                    file={image}
                    width={image.width}
                    height={image.height}
                    quality={70}
                    alt="Recommendation image"
                  />
                </div>
              );
            })}
            {images.length > 1 && (
              <div className="mt-4 flex gap-4 w-[220px] justify-between flex-row items-center text-brand-highlight absolute bottom-[16px] sm:bottom-[64px] md:bottom-[96px]">
                <button
                  className="px-4 py-2 bg-white"
                  onClick={() => cycleIndex(-1)}
                  aria-label="Previous image"
                  disabled={currentIndex === 0}
                >
                  {currentIndex === 0 ? (
                    <DisabledArrow className="text-black" />
                  ) : (
                    <ArrowIcon fill="black" style={{ transform: 'rotate(180deg)' }} />
                  )}
                </button>
                <button
                  className="px-4 py-2 bg-white"
                  onClick={() => cycleIndex(1)}
                  aria-label="Next image"
                  disabled={currentIndex === images.length - 1}
                >
                  {currentIndex === images.length - 1 ? (
                    <DisabledArrow className="text-black" style={{ transform: 'rotate(180deg)' }} />
                  ) : (
                    <ArrowIcon fill="black" />
                  )}
                </button>
              </div>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
