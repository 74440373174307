import { extendTheme } from '@chakra-ui/react';
import localFont from 'next/font/local';
import { Textarea } from './components/textarea';
import { Button } from './components/button';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Select } from './components/select';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Menu } from './components/menu';
import { Progress } from './components/progress';
import { Drawer } from './components/drawer';

import { Popover } from './components/popover';
import { Tooltip } from './components/tooltip';
import { FormError } from './components/form-error';
import lexicalStyles from './selectors/lexicalStyles';
import emojiPickerStyles from './selectors/emojiPickerStyles';
import { Tabs } from './components/tabs';
import { perfectlyImperfectColors } from './colors';

export const PerfectlyImperfectTheme = extendTheme({
  useSystemColorMode: false,
  initialColorMode: 'light',
  components: {
    Button,
    Heading,
    Input,
    Select,
    Link,
    Modal,
    Menu,
    Progress,
    Drawer,
    Popover,
    Tooltip,
    FormError,
    Textarea,
    Tabs,
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'brand.highlight',
          },
          bg: '#ababab',
          borderRadius: '0',
        },
        thumb: {
          bg: 'white',
          borderRadius: '0',
        },
      },
    },
  },
  colors: perfectlyImperfectColors,
  fonts: {
    heading: `var(--font-abc-favorite)`,
    body: "'Arial', sans-serif",
    brand: {
      arial: `'Arial', sans-serif`,
      arialNarrow: `var(--font-arial-narrow)`,
      arialNarrowBold: `var(--font-arial-narrow-bold)`,
      abcFavorite: `var(--font-abc-favorite)`,
      times: `'Times New Roman', serif`,
    },
  },
  fontSizes: {
    // xs: '13.5px',
    // sm: '13.3px',
    // md: '13.5px',
    // lg: '18px',
    // xl: '20px',
  },
  textStyles: {
    brand: {
      headingXL: {
        fontFamily: 'brand.abcFavorite',
        fontSize: ['64px', '104px'],
        fontWeight: 'bold',
        lineHeight: '90%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      headingLg: {
        fontFamily: 'brand.abcFavorite',
        fontSize: ['22px', '36px'],
        fontWeight: 'bold',
        lineHeight: ['115%', '100%'],
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      headingMd: {
        fontFamily: 'brand.abcFavorite',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '115%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      headingSm: {
        fontFamily: 'brand.abcFavorite',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '115%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      aside: {
        fontFamily: 'brand.abcFavorite',
        fontSize: ['20px', '26px'],
        fontWeight: 'bold',
        lineHeight: ['115%', '100%'],
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      bodyXl: {
        fontFamily: 'brand.times',
        fontSize: ['38px', '54px', '64px'],
        lineHeight: '120%',
        letterSpacing: '1%',
      },
      bodyLg: {
        fontFamily: 'brand.times',
        fontSize: ['20px', '28px'],
        lineHeight: '120%',
        letterSpacing: '1%',
      },
      body: {
        fontFamily: 'brand.arial',
        fontSize: '13.5px',
        fontWeight: 'bold',
        lineHeight: '115%',
        letterSpacing: '1%',
        textTransform: 'none',
      },
      editor: {
        fontFamily: 'brand.times',
        fontSize: '13.5px',
        fontWeight: 'normal',
        lineHeight: '115%',
        letterSpacing: '1%',
        textTransform: 'none',
      },
      actionLg: {
        fontFamily: 'brand.arial',
        fontSize: '13.5px',
        fontWeight: 'bold',
        lineHeight: '100%',
        letterSpacing: '1%',
      },
      actionSm: {
        fontFamily: 'brand.arialNarrowBold',
        fontSize: '13.5px',
        fontWeight: 'bold',
        lineHeight: '110%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      formTitle: {
        fontFamily: 'brand.abcFavorite',
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '115%',
        letterSpacing: '1%',
      },
      formSubtitle: {
        fontFamily: 'Times New Roman, serif',
        fontSize: '24px',
        fontWeight: 'normal',
        lineHeight: '100%',
        letterSpacing: '1%',
      },
      formBody: {
        fontFamily: 'brand.abcFavorite',
        fontSize: '24px',
        fontWeight: 'normal',
        lineHeight: '115%',
        letterSpacing: '1%',
        textTransform: 'none',
      },
      formError: {
        color: 'brand.red',
        fontFamily: 'brand.arialNarrowBold',
        fontSize: '13.5px',
        fontWeight: 'bold',
        lineHeight: '100%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
      secondaryBodyLink: {
        color: 'brand.lightgrey;',
        fontFamily: 'brand.arialNarrowBold',
        fontSize: '13.5px',
        fontWeight: 'bold',
        lineHeight: '100%',
        letterSpacing: '1%',
        textTransform: 'uppercase',
      },
    },
  },

  layerStyles: {
    secondaryText: {
      color: 'brand.lightgrey;',
    },
    stretched: {
      // fontStretch: 'condensed',
      transform: 'scale(1, 3)',
      fontSize: '36px',
    },
  },

  styles: {
    global: () => ({
      'html, body': {
        fontFamily: 'brand.arial',
        color: 'brand.main',
      },
      html: {
        textStyle: 'brand.body',
        fontSize: '13.5px',
      },
      '::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
        // scrollbarColor: 'red orange',
        // scrollbarWidth: 'thin',
      },
      body: {
        textStyle: 'brand.body',
        fontSize: '13.5px',
      },
      '.chakra-alert': {
        borderRadius: '0px !important',
        textStyle: 'brand.body !important',
        fontSize: '13.5px !important',
        textTransform: 'uppercase',
        backgroundColor: 'white !important',
        bgColor: 'white !important',
        border: '3px solid',
        color: 'black !important',
      },
      '.chakra-alert[data-status="success"]': {
        borderColor: 'brand.green',
      },
      '.chakra-alert__icon .chakra-icon': {
        color: 'black !important',
      },
      '.chakra-alert[data-status="error"]': {
        borderColor: 'brand.red',
      },
      '.ReactCrop': {
        zIndex: 99999999999,
        position: 'relative',
      },
      ...lexicalStyles,
      ...emojiPickerStyles,
    }),
  },
});
