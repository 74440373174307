import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { gql } from 'shared/__generated__/gql';

export default function useRecAttachments() {
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT);

  const toast = useToast();

  const onRemoveAttachment = useCallback(
    ({ draftRecId, recId, attachmentId }) => {
      removeAttachment({
        variables: {
          draftRecId,
          recId,
          attachmentId,
        },
        optimisticResponse: {
          removeAttachment: {
            rec: recId
              ? {
                  id: recId,
                  attachments: [],
                }
              : null,
            draftRec: draftRecId
              ? {
                  id: draftRecId,
                  attachments: [],
                }
              : null,
          },
        },
        onCompleted: () => {
          toast({
            title: 'Attachment removed',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
        onError: () => {
          toast({
            title: 'An error occured...',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      });
    },
    [removeAttachment],
  );

  return { onRemoveAttachment };
}

const REMOVE_ATTACHMENT = gql(/* GraphQL */ `
  mutation removeAttachment($attachmentId: String!, $recId: String, $draftRecId: String) {
    removeAttachment(attachmentId: $attachmentId, recId: $recId, draftRecId: $draftRecId) {
      rec {
        id
        attachments {
          ...RecommendationAttachment
        }
      }
      draftRec {
        id
        attachments {
          ...RecommendationAttachment
        }
      }
    }
  }
`);
