import { gql } from '../../__generated__';

export const SIGN_UP_PART_ONE = gql(/* GraphQL */ `
  mutation signUpBasicInfo($input: SignUpBasicInfoInput!) {
    signUpBasicInfo(input: $input)
  }
`);

export const SIGN_UP_EMAIL_ONLY_STEP_2 = gql(/* GraphQL */ `
  mutation signUpEmailOnlyStep2($input: SignUpEmailOnlyStep2Input!) {
    signUpEmailOnlyStep2(input: $input) {
      ...UserCore
    }
  }
`);

export const SIGN_UP_EMAIL_ONLY_STEP_1 = gql(/* GraphQL */ `
  mutation SignUpEmailOnlyStep1($input: SignUpEmailOnlyInput!, $inviteCode: String, $shouldConfirm: Boolean) {
    signUpEmailOnly(input: $input, inviteCode: $inviteCode, shouldConfirm: $shouldConfirm)
  }
`);

export const VERIFY_EMAIL_VIA_CODE = gql(/* GraphQL */ `
  mutation VerifyEmailViaCodeStep1($code: String!, $resetPrimary: Boolean, $withToken: Boolean) {
    verifyEmailViaCode(code: $code, resetPrimary: $resetPrimary, withToken: $withToken) {
      token
      destination
    }
  }
`);

export const SEND_VERIFY_EMAIL_CODE = gql(/* GraphQL */ `
  mutation SendVerifyEmailCode($email: String!, $destination: String) {
    sendVerifyEmail(email: $email, destination: $destination)
  }
`);

export const SIGN_UP_MORE_INFO = gql(/* GraphQL */ `
  mutation signUpMoreInfo($input: SignUpMoreInfoInput!) {
    signUpMoreInfo(input: $input) {
      id
      username
      signUpStep
      location
      instagram
      twitter
      website
      bio
      bioLexical
    }
  }
`);

export const SIGN_UP_THEME = gql(/* GraphQL */ `
  mutation updateProfileTheme($profileTheme: ProfileThemeType!) {
    updateProfileTheme(profileTheme: $profileTheme) {
      id
      signUpStep
      profileTheme
    }
  }
`);

export const SYNC_SUBSTACK_STATUS = gql(/* GraphQL */ `
  query syncSubstackStatusOriginal {
    me {
      id
      isPremium
      premiumTier
    }
    syncSubstackStatus {
      id
      isPremium
      substackStatus
    }
  }
`);

export const USE_INVITE_CODE = gql(/* GraphQL */ `
  mutation useInviteCode($code: String!) {
    useInviteCode(code: $code) {
      id
      isPremium
      substackStatus
      invite {
        id
      }
    }
  }
`);

export const SIGN_UP_USER_META = gql(/* GraphQL */ `
  mutation SignUpUserMeta($input: SignUpUserMetaInput!) {
    signUpUserMeta(input: $input) {
      user {
        id
        firstName
        lastName
        username
        birthday
      }
      suggestedUsername
    }
  }
`);