// export function nullThrows<T>(v?: T | null | undefined): T {
//   if (v == null) {
//     throw new Error('This thing shouldnt be null');
//   }

//   return v;
// }

export function nullThrows<T>(v: T | null | undefined, msg?: string | undefined): T {
  if (v != null) {
    return v;
  }
  const error = new Error(msg || 'This thing shouldnt be null');
  error.framesToPop = 1; // Skip nullthrows's own stack frame.
  throw error;
}

export function safeParse(maybeJson?: string | null) {
  if (typeof maybeJson === 'object') {
    return maybeJson;
  }
  try {
    // @ts-ignore
    return JSON.parse(maybeJson);
  } catch (e) {
    return null;
  }
}
