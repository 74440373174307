import React from 'react';
import { useDisclosure } from 'web2/app/misc/wrappers/use-disclosure';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../ui/modal';
import { Button } from '../button';
import { useForm } from 'react-hook-form';
import { type EditorState } from 'lexical';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFragment } from '@apollo/client';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import PerfInput from 'shared/misc/components/util/PerfInput';

import { createListSchema } from 'shared/validation/schemas';
import { gql } from 'shared/__generated__/gql';
import {
  ListColorBlockFragment,
  RecommendationItemFragmentDoc,
} from 'shared/__generated__/graphql';

type CreateOrUpdateListModalProps = {
  recId?: string;
  list?: ListColorBlockFragment;
  modalState?: ReturnType<typeof useDisclosure>;
};

type Inputs = {
  title: string;
  color?: string;
  introLexicalState?: EditorState | undefined | null | {};
};

export default function CreateOrUpdateListModal({
  modalState: _modalState,
  list,
  recId,
}: CreateOrUpdateListModalProps) {
  const modalState = _modalState || useDisclosure();

  const [createList, createListState] = useMutationWithAutoToast(CREATE_LIST_MUTATION, {
    refetchQueries: ['getUserLists', 'getProfileListsView', 'getViewerLists'],
    successMessage: 'List created.',
    onCompleted: () => {
      modalState.onClose();
    },
  });

  const [updateList, updateListState] = useMutationWithAutoToast(UPDATE_LIST_MUTATION, {
    successMessage: 'List updated.',
    onCompleted: () => {
      modalState.onClose();
    },
  });

  const submitting = createListState.loading || updateListState.loading;

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(createListSchema),
    defaultValues: {
      title: list?.title || '',
      // introLexicalState: safeParse(list?.contentLexical),
    },
  });

  const { data: rec } = useFragment({
    fragment: RecommendationItemFragmentDoc,
    fragmentName: 'RecommendationItem',
    from: {
      __typename: 'Rec',
      id: recId,
    },
  });

  const onSubmit = async (values: any, e) => {
    e.preventDefault();

    const { title, ...rest } = values;

    if (list) {
      updateList({
        variables: {
          input: {
            title,
            ...rest,
          },
          listId: list.id,
        },
        optimisticResponse: {
          updateList: {
            __typename: 'List',
            id: list.id,
            title,
            ...rest,
          },
        },
      });
      return;
    }

    await createList({
      variables: {
        input: {
          title,
        },
      },
      // refetchQueries: ['getUserLists', 'getProfileListsView', 'getViewerLists'],
      optimisticResponse: {
        createListAndAddRec: {
          __typename: 'UpdateListResponse',
          list: {
            __typename: 'List',
            id: '123',
            title: rest.title,
          },
          rec: {
            __typename: 'Rec',
            id: recId,
            lists: [
              {
                id: '123',
                title: rest.title,
              },
              ...(rec.lists || []),
            ],
          },
        },
      },
    });
  };

  return (
    <Modal open={modalState.isOpen} onOpenChange={modalState.onToggle}>
      <ModalContent className="p-[24px] max-h-full max-w-full w-[600px]">
        <ModalHeader className="opacity-100 typography-heading-md m-0">{list ? 'Edit' : 'Create'} List</ModalHeader>
        <form className="flex flex-col gap-[16px]" onSubmit={handleSubmit(onSubmit)}>
          <div className="typography-heading-md text-[16px]">
            <PerfInput
              alignSelf="center"
              placeholder="What's your list about?"
              {...register('title')}
              error={errors.title}
            />
          </div>
          <ModalFooter>
            <div className="flex gap-[6px]">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  modalState.onClose();
                }}
                isDisabled={submitting}
                variant="secondary"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={submitting}
                isLoading={submitting}
                variant="primary"
              >
                {list ? 'Save' : 'Create'}
                {recId && !list ? ' and add rec' : ''}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

const CREATE_LIST_MUTATION = gql(/* GraphQL */ `
  mutation createListAndAddRec($input: CreateListAndAddRecInput!) {
    createListAndAddRec(input: $input) {
      list {
        id
        title
      }
      rec {
        id
        lists {
          id
          title
        }
      }
    }
  }
`);

const UPDATE_LIST_MUTATION = gql(/* GraphQL */ `
  mutation updateList($input: CreateListAndAddRecInput!, $listId: String!) {
    updateList(input: $input, listId: $listId) {
      id
      title
    }
  }
`);
