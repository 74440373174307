import React, { useEffect, useState, useRef } from 'react';
import { useSignUpNavigation } from 'shared/misc/providers/SignUpNavigationContext';
import { useTypewriter } from 'shared/misc/hooks/useTypewriter';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { Box, Text, useToast } from '@chakra-ui/react';
import { Button } from 'web2/app/components/button';
import { BASE_SITE_URL } from 'shared/siteconfig';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import { createFileRoute } from '@tanstack/react-router';

const INTRO_TEXT = 'Who do you usually share your taste with?';

export const Route = createFileRoute('/sign-up/share/')({
  component: SignUpShare,
});

function SignUpShare() {
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.LOADING] });
  const { setNavigation } = useSignUpNavigation();
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();
  const [displayText] = useTypewriter(INTRO_TEXT, 50);
  const toast = useToast();

  useEffect(() => {
    setNavigation({
      showLeftArrow: false,
      showRightArrow: true,
      leftArrowDisabled: false,
      rightArrowDisabled: false,
      yPosition: 170,
      onLeftPress: () => updateStep(USER_SIGN_UP_STEP.CATEGORIES),
      onRightPress: handleDone,
      showSkip: true,
      onSkip: async () => {
        await trackEvent('Click', 'Step_Skipped', {
          userId: user?.id,
        });
        await handleDone();
      },
      signupStep: USER_SIGN_UP_STEP.SHARE,
    });

    return () => {
      setNavigation({
        showLeftArrow: false,
        showRightArrow: false,
        yPosition: 170,
        leftArrowDisabled: false,
        rightArrowDisabled: false,
        signupStep: USER_SIGN_UP_STEP.SHARE,
      });
    };
  }, [setNavigation]);

  const handleDone = async () => {
    updateStep(USER_SIGN_UP_STEP.LOADING);
  };

  const PersonalLink = `${BASE_SITE_URL}/subscribe/referral/${user?.username}`;

  const copy = async () => {
    await trackEvent('Click', 'Link_Copied', {
      userId: user?.id,
      inviteLink: PersonalLink,
    });
    navigator.clipboard.writeText(PersonalLink);
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <div className="min-h-full">
      <div className="min-h-[34px] mb-4 typography-heading-md font-bold text-24 sm:text-32 md:text-32 normal-case self-start">
        {displayText}
      </div>
      <div className="mb-1 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        Copy your personal invite link below and share with your friends.
      </div>
      <div className="mb-4 typography-form-subtitle text-gray-300 text-16 sm:text-18 md:text-24">
        Invite 10 friends and get a free t-shirt.
      </div>
      <div className="flex justify-start items-start flex-col mt-[80px] max-w-full">
        <div className="typography-heading-sm text-16px sm:text-18 md:text-32 w-full whitespace-wrap text-none normal-case">
          {PersonalLink}
        </div>
        <Button variant="primaryBlackText" className="mt-[32px]" onClick={copy}>
          Copy Invite Link
        </Button>
      </div>
    </div>
  );
}
