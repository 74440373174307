import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  body: {
    bg: 'brand.background', // change the background of the body to gray.800\
    opacity: 1,
    border: '2px solid brand.highlight',
    borderRadius: '0px',
  },
  content: {
    bg: 'brand.background',
    padding: 3, // change the padding of the content
    border: '1px dashed',
    borderColor: 'brand.main',
    textStyle: 'brand.body',
    borderRadius: '0px',
  },
  header: {
    textStyle: 'brand.actionSm',
    border: 'none',
  },
  popper: {
    bg: 'brand.background',
    opacity: 1,
    zIndex: 'popover',
    border: '2px solid brand.highlight',
    borderRadius: '0px',
  },
  footer: {
    border: 'none',
  },
});

export const Popover = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    closeOnBlur: true,
    gutter: 8,
    lazyMount: true,
    isLazy: true,
    unmountOnExit: true,
  },
});
