'use client';

import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  FocusLock,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import {
  PerfImageFromFileFragment,
  PerfImageFromFileFragmentDoc,
} from '../../../../../../__generated__/graphql';
import { useFragment } from '@apollo/client';
import PerfImageFromFile from '../../../../util/PerfImageFromFile';
import LexicalSelectionHandler from './LexicalSelectionHandler';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import _ from 'lodash';
import { mergeRegister } from '@lexical/utils';
import { $getNodeByKey, COMMAND_PRIORITY_HIGH, KEY_BACKSPACE_COMMAND } from 'lexical';
import { useDelete } from './hooks/useHelperHooks';
import { text } from 'body-parser';
import { url } from 'inspector';
import { $isImageNode } from '../LexicalImageNode';

type Props = {
  id: string;
  image: PerfImageFromFileFragment;
  link?: string;
  nodeKey: string;
  setImage: (image: PerfImageFromFileFragment) => void;
  setLink: (link: string) => void;
};

function LexicalImageComponent({ image, link, setImage, setLink, nodeKey }: Props) {
  const [editor] = useLexicalComposerContext();
  const [url, setUrl] = React.useState(link || '');
  const popoverState = useDisclosure();

  const { data: imageFragment, complete } = useFragment({
    fragment: PerfImageFromFileFragmentDoc,
    fragmentName: 'PerfImageFromFile',
    from: {
      __typename: 'File',
      id: image.id,
    },
  });

  const isEditable = useLexicalEditable();

  useDelete(nodeKey);

  useEffect(() => {
    if (isEditable && complete) {
      if (!_.isEqual(imageFragment, image)) {
        editor.update(() => {
          setImage(imageFragment);
        });
      }
    }
  }, [imageFragment, isEditable, complete]);

  const updateLinkDebounced = React.useMemo(
    () =>
      _.debounce((nextData) => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey);
          if ($isImageNode(node)) {
            node.setLink(nextData);
          }
        });
      }, 500),
    [],
  );

  useEffect(() => {
    if (isEditable) {
      updateLinkDebounced(url);
    }
  }, [isEditable, url]);

  const img = complete ? imageFragment : image;

  let imageNode = (
    <LexicalSelectionHandler nodeKey={nodeKey}>
      {(img.isUploaded || !isEditable) && (
        <PerfImageFromFile
          file={img}
          alt="uploaded file"
          quality={70}
          style={{
            width: '100%',
            aspectRatio: img.width && img.height ? img.width / img.height : undefined,
          }}
          onClick={() => {
            if (isEditable) {
              popoverState.onOpen();
            }
          }}
        />
      )}
      {!img.url && !img.isUploaded && !img.isFailed && isEditable && (
        <Flex height="200px" justifyContent="center" alignItems="center">
          <Spinner size="lg" />
        </Flex>
      )}
      {img.url && !img.id && (
        <Image
          src={img.url}
          alt="uploaded file"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      )}
    </LexicalSelectionHandler>
  );

  if (link && !isEditable) {
    imageNode = (
      <a href={link} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
        {imageNode}
      </a>
    );
  }

  if (isEditable) {
    imageNode = (
      <Popover placement="bottom-start" {...popoverState}>
        <PopoverTrigger>{imageNode}</PopoverTrigger>
        <PopoverContent>
          <FocusLock persistentFocus={false}>
            <PopoverCloseButton />
            <PopoverHeader>Change link</PopoverHeader>
            <PopoverBody>
              <Stack>
                <Input
                  value={url}
                  type="url"
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Button URL"
                />
              </Stack>
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Flex width="100%" maxWidth="100%" justifyContent="center">
      {imageNode}
    </Flex>
  );
}

export default LexicalImageComponent;
