import React from 'react';
import HomeContainerNew from '../-home-container-new';

import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/home/$tab/')({
  component: RouteComponent,
});

export function RouteComponent() {
  const { tab } = Route.useParams();

  if (!tab) {
    throw redirect({ to: '/' });
  }

  return <HomeContainerNew tab={tab} />;
}
