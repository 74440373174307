import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  list: {
    borderRadius: '0px',
    padding: '0px',
    border: '1px solid',
    borderColor: 'brand.main',
    bg: 'brand.background',
  },
  divider: {
    margin: '0px',
  },
  item: {
    padding: { base: '12px', md: '8px' },
    bg: 'brand.background',
    color: 'brand.main',
    textStyle: 'brand.actionSm',
    fontSize: '13.5px',
    _hover: {
      // backgroundColor: 'rgba(0, 4, 255, 0.5)',
      backgroundColor: 'brand.highlight',
      color: 'brand.secondary',
    },
    _focus: {
      backgroundColor: 'brand.highlight',
      color: 'brand.secondary',
    },
  },
  header: {
    textStyle: 'brand.headingXL',
    fontSize: '36px',
    borderBottom: '1px solid',
    borderBottomColor: 'brand.main',
    mb: '10px',
  },
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    closeOnEsc: false,
    closeOnOverlayClick: false,
  },
});
