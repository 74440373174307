'use client';
import React, { use, Suspense, ReactElement } from 'react';
import { gql } from 'shared/__generated__/gql';
import { useQuery, useSuspenseQuery } from '@apollo/client';
import { Link as TWLink } from '../../../../../../apps/web2/app/components/link';
import { RelatedRecsQuery } from '../../../../__generated__/graphql';
import { useLoaderData } from '@tanstack/react-router';

import RecommendationItem from './RecommendationItem';
import SkeletonFeedItem from 'shared/misc/components/SkeletonFeedItem';
import { RecommendationItemFragment } from 'shared/__generated__/graphql';

export const RelatedRecsQueryDocument = gql(/* GraphQL */ `
  query relatedRecs($recId: String!, $userId: String!) {
    similarRecs: recConnection(relatedToId: $recId, first: 3) {
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
    user(id: $userId) {
      username
    }
    topRecsFromUser: recConnection(userId: $userId, first: 3, orderBy: TOP, excludeIds: [$recId]) {
      edges {
        node {
          ...RecommendationItem
        }
      }
    }
  }
`);

type RelatedRecsProps = {
  recId: string;
  userId: string;
  initialRelatedRecsData?: RelatedRecsQuery;
};

export default function RecViewRelatedRecs({
  recId,
  userId,
  initialRelatedRecsData,
}: RelatedRecsProps) {
  // const { similarRecsQueryRef, topRecsFromUserQueryRef, username } = useLoaderData({
  //   from: '/rec/$recId',
  // });

  const { data } = useSuspenseQuery(RelatedRecsQueryDocument, {
    fetchPolicy: 'cache-first',
    variables: { recId, userId },
  });

  const { similarRecs, topRecsFromUser, user } = data;

  return (
    <div>
      <h2 className={'px-4 md:px-0 typography-action-sm md:text-xl font-semibold pb-6'}>
        Related Recs
      </h2>
      <div className="flex flex-col gap-[12px] px-4 md:px-0 mb-3">
        {similarRecs.edges.map((edge) => (
          <RecommendationItem isInline smallTitle key={edge.node.id} rec={edge.node} showPrompt />
        ))}
      </div>
      <div className={'mb-16 text-secondary-body-link'} />
      <h2 className={'px-4 md:px-0 typography-action-sm md:text-xl font-semibold pb-6'}>
        Top Recs from{' '}
        <TWLink to={`/u/$username`} params={{ username: user?.username }}>
          @{user?.username}
        </TWLink>
      </h2>
      <div className="flex flex-col gap-[12px] px-4 md:px-0 mb-3">
        {topRecsFromUser.edges.map((edge) => (
          <RecommendationItem isInline smallTitle key={edge.node.id} rec={edge.node} showPrompt />
        ))}
      </div>
    </div>
  );
}

type RelatedRecSectionProps = {
  recsPromise: Promise<RecommendationItemFragment[]>;
  title: ReactElement | string;
  recs: RecommendationItemFragment[];
};

function RelatedRecSection(props: RelatedRecSectionProps) {
  const { recs, title } = props;

  if (recs.length <= 1) {
    return null;
  }

  return (
    <>
      <h2 className={'px-4 md:px-0 typography-action-sm md:text-xl font-semibold pb-6'}>{title}</h2>
      <div className="flex flex-col gap-[12px] px-4 md:px-0 mb-3">
        {recs.map((rec) => (
          <RecommendationItem isInline smallTitle key={rec.id} rec={rec} showPrompt />
        ))}
      </div>
    </>
  );
}
