import { gql } from '../../__generated__';

export const CREATE_REPORT_MUTATION = gql(/* GraphQL */ `
  mutation createReport(
    $targetId: String!
    $type: String
    $targetType: String!
    $context: String!
  ) {
    createReport(targetId: $targetId, targetType: $targetType, type: $type, context: $context) {
      id

      targetRec {
        id
        isHidden
      }

      targetComment {
        id
        isHidden
      }

      targetPrompt {
        id
        isHidden
      }
    }
  }
`);
