import { type ClassNameValue, extendTailwindMerge } from 'tailwind-merge';
import { clsx } from 'clsx';

// Create a custom twMerge instance that knows about our typography classes
const twMerge = extendTailwindMerge<'typography' | 'link'>({
  extend: {
    classGroups: {
      typography: [
        'typography-heading-xl',
        'typography-heading-lg',
        'typography-heading-md',
        'typography-heading-sm',
        'typography-aside',
        'typography-body-xl',
        'typography-body-lg',
        'typography-body',
        'typography-editor',
        'typography-action-lg',
        'typography-action-sm',
        'typography-form-error',
        'typography-secondary-body-link',
      ],
      link: [
        'link-default',
        'link-stylized',
        'link-stylized-basic',
        'link-fancy',
        'link-stylized-no-default-border',
        'link-basic',
        'link-basic-with-blue',
        'link-basic-light',
        'link-btn',
        'link-list',
      ],
    },
  },
});

export function cn(...inputs: ClassNameValue[]) {
  return twMerge(clsx(inputs));
}
