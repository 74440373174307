import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// default base style from the Input theme
const baseStyle = definePartsStyle({
  field: {
    fontWeight: 'normal',
    fontSize: '16px',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    border: '1px solid',
    borderRadius: 'none',
    borderColor: 'brand.main',
    textStyle: 'brand.actionLg',
    _placeholder: {
      color: 'brand.lightgrey',
      opacity: '50%',
    },
  },
  icon: {
    _hover: {
      color: 'brand.highlight',
    },
  },
});

const variantOutline = definePartsStyle(() => {
  return {
    field: {
      color: 'brand.main',
      width: '100%',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      border: '1px solid',
      borderColor: 'brand.main',
      borderRadius: 'none',
      textStyle: 'brand.actionLg',
      _hover: {
        borderColor: 'brand.highlight',
        color: 'brand.highlight',
      },
      _focus: {
        borderColor: 'brand.main',
        outlineOffset: '-1px',
        outline: '1px solid',
        outlineColor: 'brand.main',
        boxShadow: 'none',
        color: 'brand.main',
      },
      focusBorderColor: 'brand.main',
    },
  };
});

const variants = {
  outline: variantOutline,
};

export const Select = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline',
    // focusBorderColor: 'brand.highlight',
  },
});
