import { createFileRoute } from '@tanstack/react-router';
import Spinner from 'web2/app/components/spinner';
import { useQuery } from '@apollo/client';
import { gql } from 'shared/__generated__/gql';
import { InView } from 'react-intersection-observer';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import UserDirectoryItem from '../../../../components/by-type/user/user-directory-item';

export const Route = createFileRoute('/u/$username/followers/')({
  component: RouteComponent,
});

gql(/* GraphQL */ `
  fragment ProfileFollowersView on User {
    id
    email
    firstName
    lastName
    username
    profileTheme
    followersCount
    followersConnection(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          originUser {
            ...UserDirectoryItem
          }
        }
      }
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getProfileFollowersView($username: String!, $first: Int, $after: String) {
    user(username: $username) {
      ...ProfileFollowersView
    }
  }
`);

const PAGE_LENGTH = 30;
function RouteComponent() {
  const { username } = Route.useParams();
  const {
    variables,
    previousData,
    data = previousData,
    fetchMore,
    loading,
  } = useQuery(PageQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      username,
      first: PAGE_LENGTH,
    },
  });

  const getNextPage = async () => {
    if (loading || !data?.user?.followersConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        ...variables,
        first: PAGE_LENGTH,
        after: data?.user?.followersConnection.pageInfo.endCursor,
      },
    });
  };

  const auth = useAuth();

  const profile = data?.user;

  useAutoSetTheme(profile?.profileTheme || null);

  const followers = profile?.followersConnection?.edges || [];

  if (!profile) {
    return null;
  }

  return (
    <div className="flex justify-center p-6 mb-[100px]">
      <div className="flex flex-col gap-6 w-full md:w-[600px] lg:w-[700px]">
        <h1 className="typography-heading-xl text-brand-highlight mb-6 mt-12 uppercase text-center">
          {profile.username}'s FOLLOWERS
        </h1>
        {(!auth.user || profile.id !== auth.user?.id) && false ? (
          <>
            <p className="typography-action-sm text-center">
              You don't have permission to view this.
            </p>
          </>
        ) : (
          <>
            {profile?.id === auth.user?.id && (
              <p className="typography-action-sm text-center mb-3">Only you can see this.</p>
            )}
            {followers.length === 0 && (
              <div className="space-y-3 flex flex-col w-full justify-center items-center">
                {profile?.id === auth.user?.id ? (
                  <>
                    <div>
                      <p className="typography-action-sm text-center">
                        No one is following you...(yet)
                      </p>
                    </div>
                  </>
                ) : (
                  <div>
                    <p className="typography-action-sm text-center">Nobody...</p>
                  </div>
                )}
              </div>
            )}
            {followers?.map(({ node }) => (
              <UserDirectoryItem key={node.id} noLink user={node.originUser} />
            ))}
            <InView
              as="div"
              rootMargin="2000px 0px"
              onChange={(inView) => {
                if (inView) {
                  getNextPage();
                }
              }}
            />
            {loading && <Spinner />}
          </>
        )}
      </div>
    </div>
  );
}
