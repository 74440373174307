import React, { useState } from 'react';

import { chakra, Box, ImageProps, BoxProps, useTheme } from '@chakra-ui/react';
import { gql } from '../../../__generated__';
import { PerfImageFromFileFragment } from '../../../__generated__/graphql';
import { nullThrows } from '../../../utils';
import { useIsBackgroundLight } from '../../hooks/useContrastHighlight';
import { useThemeColors } from '../../hooks/useThemeColors';
import { decode } from 'blurhash';
import { ChakraNextImage } from './PerfImage';
import { genCDN } from 'shared/cdn';
// TYLER: This was stolen from here: https://github.com/chakra-ui/chakra-ui/discussions/2475

// Properties which are forwarded to the underlying next/image component
const FORWARDED_PROPS = [
  'width',
  'height',
  'src',
  'alt',
  'quality',
  'placeholder',
  'blurDataURL',
  'loader',
  'priority',
  'fill',
] as const;

type ForwardedProps = (typeof FORWARDED_PROPS)[number];

// Width and height types which may contain Chakra's responsive array or object
type Width = NonNullable<ImageProps['width']>;
type Height = NonNullable<ImageProps['height']>;

// Combine props by taking forwarded property types from next/image and rest
// from Chakra
type CombinedImageProps = Pick<NextImageProps, ForwardedProps> & Omit<ImageProps, ForwardedProps>;

// Replace width and height with Chakra's responsive width/height which is
// mandatory here due to next/image requirement
type ChakraImageProps = Omit<CombinedImageProps, 'width' | 'height'> & {
  width?: Width;
  height?: Height;
  file: PerfImageFromFileFragment;
};

gql(/* GraphQL */ `
  fragment PerfImageFromFile on File {
    compressedKey
    compressedUrl
    contentType
    createdAt
    extension
    id
    key
    name
    url
    width
    height
    isUploaded
    isCompressed
    isFailed
    blurHash
    # compressedUrlQuality20: cachedAndCompressedUrl(quality: 20)
    # compressedUrlQuality40: cachedAndCompressedUrl(quality: 40)
    # compressedUrlQuality80: cachedAndCompressedUrl(quality: 80)
  }
`);

export function getCompressedUrlOrDefault(file: PerfImageFromFileFragment, quality: number) {
  return file.url;
  return file[`compressedUrlQuality${quality}`] || file.url;
}

interface PerfImageFromFileProps extends Omit<NextImageProps, 'src'> {
  file?: PerfImageFromFileFragment | null;
  src?: NextImageProps['src'];
  backgroundColor?: string;
  fileKey?: string;
  width?: number;
  height?: number;
  quality?: number;
  useFallbackAvatarSrc?: boolean;
  srcIsKey?: boolean;
}

function blurHashToDataURL(blurHash: string, width = 32, height = 32): string {
  const pixels = decode(blurHash, width, height);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  const imageData = ctx!.createImageData(width, height);
  imageData.data.set(pixels);
  ctx!.putImageData(imageData, 0, 0);
  return canvas.toDataURL('image/jpeg');
}

const PerfImageFromFile = ({
  file,
  width,
  height,
  backgroundColor,
  useFallbackAvatarSrc,
  quality,
  priority,
  src,
  fileKey,
  srcIsKey,
  ...props
}: PerfImageFromFileProps) => {
  const [fallbackSrc, setFallbackSrc] = useState<string>();
  const [hideOnError, setHideOnError] = useState(false);

  const themeColors = useThemeColors();

  const isLight = useIsBackgroundLight(backgroundColor);

  const originUserFallbackLogoColor =
    themeColors.main === '#ffffff' || themeColors.main === 'white' || !isLight ? 'white' : 'blue';

  const fallbackAvatarSrc = `https://files.pi.fyi/default-profile-photo-${
    originUserFallbackLogoColor || 'blue'
  }-small.png`;

  if (hideOnError) {
    return null;
  }

  try {
    let finalUrl = src || fileKey || fallbackAvatarSrc;
    let blurDataUrl: string | undefined;

    if (!src && !fileKey && file) {
      if (file?.blurHash && typeof window !== 'undefined') {
        // blurDataUrl = blurHashToDataURL(file.blurHash);
      }

      if (file && !file?.isFailed) {
        finalUrl = file!.url!;
      }

      if (fallbackSrc || !finalUrl) {
        finalUrl = fallbackSrc || fallbackAvatarSrc;
      }

      if (!src && (!file || file.isFailed || !file.isUploaded)) {
        if (useFallbackAvatarSrc) {
          finalUrl = fallbackAvatarSrc;
        } else {
          return null;
        }
      }
    }

    if ((file?.key || srcIsKey || fileKey) && fallbackAvatarSrc !== finalUrl) {
      finalUrl = genCDN(file?.key || fileKey || src, quality, 'webp');
    }

    return (
      <img
        {...props}
        // key={finalUrl}
        src={finalUrl}
        width={width || file?.width}
        height={height || file?.height}
        onError={() => {
          if (useFallbackAvatarSrc && finalUrl !== fallbackSrc) {
            setFallbackSrc(fallbackAvatarSrc);
          } else {
            setHideOnError(true);
          }
        }}
      />
    );
  } catch (e) {
    console.error(e);
    console.log('failed to load file...', file);
    // Sentry.captureException(e, { tags: { fileId: file.id } });

    // if (useFallbackAvatarSrc && file.width) {
    //   return (
    //     <NextImage
    //       {...props}
    //       src={fallbackAvatarSrc}
    //       // width={nullThrows(width || file.width)}
    //       // height={nullThrows(height || file.height)}
    //     />
    //   );
    // }

    return null;
  }
};

function isResponsive(
  val: Width | Height | undefined,
): val is OnlyResponsiveWidth | OnlyResponsiveHeight {
  return typeof val == 'object' && val != null;
}

function getResponsive(val: Width | Height | undefined) {
  return val && isResponsive(val) ? val : [];
}

export default PerfImageFromFile;
