import { useState, useCallback } from 'react';

export function useDisclosure(defaultIsOpen: boolean = false) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onToggle = useCallback(() => {
    setIsOpen((prev) => {
      return !prev;
    });
  }, []);

  return { isOpen, onOpen, onClose, onToggle };
}
