'use client';

import React, { useEffect, useMemo, useState, useRef, type JSX, useCallback } from 'react';
import { useMutation, useApolloClient, useQuery, useFragment } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import usePrevious from 'use-previous';
import * as Sentry from '@sentry/nextjs';
import { AuthContext } from '../hooks/useAuth';

import { AuthUserFragmentDoc, SignedInUserFragment } from '../../__generated__/graphql';
import { getPathForStep } from '../hooks/useUpdateSetupStep';
import { usePrefetch } from '../hooks/usePrefetch';
import { DESTROY_SESSION_MUTATION, GET_AUTH_USER } from '../graphql/AuthUserFragments';
import { gql } from '../../__generated__/gql';
import { useAnalytics } from '../providers/AnalyticsContext';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { safeParse } from '../../utils';
import { useRouter, useRouterState, redirect } from '@tanstack/react-router';

const destroySessionAction = () => {
  console.log('destroySessionAction');
};

const getSessionUserTokenAction = () => {
  console.log('getSessionUserTokenAction');
};

const signInAction = () => {
  console.log('signInAction');
};

const signOutAction = () => {
  console.log('signOutAction');
};

export const WEB_TOKEN_CONFIG = {
  set: (token: string) => typeof window !== 'undefined' && localStorage.setItem('token', token),
  get: () => typeof window !== 'undefined' && localStorage.getItem('token'),
  remove: () => typeof window !== 'undefined' && localStorage.removeItem('token'),
};

export const AUTH_USER_LOCAL_STORAGE = 'auth-user';

function getAuthUserLocalStorage() {
  if (typeof globalThis?.window === 'undefined') {
    return null;
  }
  if (!localStorage) {
    return null;
  }
  if (localStorage.getItem('token')) {
    return safeParse(localStorage.getItem(AUTH_USER_LOCAL_STORAGE));
  }
  return null;
}

type AuthProps = {
  children: JSX.Element | JSX.Element[];
  tokenConfig?: {
    set: any;
    get: any;
    remove: any;
  };
  sessionUser: SignedInUserFragment | null;
};

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

export const Auth = ({
  children,
  tokenConfig = WEB_TOKEN_CONFIG,
  sessionUser: initialSessionUser,
}: AuthProps) => {
  const client = useApolloClient();
  const toast = useToast();

  const { complete, data: fragmentData } = useFragment({
    fragment: AuthUserFragmentDoc,
    fragmentName: 'AuthUser',
    from: {
      __typename: 'User',
      ...(initialSessionUser || {}),
    },
  });

  const { identifyUser } = useAnalytics();

  const { refetch, data, loading, error } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    pollInterval: 120000,
    onError: (e) => console.log(e),
  });

  const userFromStorage = useMemo(() => getAuthUserLocalStorage(), []);

  const sessionUser =
    (complete ? fragmentData?.me : data ? data.me : null) || initialSessionUser || userFromStorage;

  const [isFullyLoaded] = useState<boolean>(!!sessionUser);

  const prevSessionUserRef = useRef<SignedInUserFragment | null>(null);

  // if the only change is the dates, we can skip the identifyUser call
  const isUserChanged = (
    newUser: SignedInUserFragment | null,
    oldUser: SignedInUserFragment | null,
  ) => {
    if (!newUser || !oldUser) return newUser !== oldUser;
    const newUserWithoutDates = omit(newUser, ['createdAt', 'updatedAt']);
    const oldUserWithoutDates = omit(oldUser, ['createdAt', 'updatedAt']);
    return !isEqual(newUserWithoutDates, oldUserWithoutDates);
  };

  useEffect(() => {
    if (sessionUser && isUserChanged(sessionUser?.id, prevSessionUserRef.current?.id)) {
      prevSessionUserRef.current = sessionUser;
    }
    if (sessionUser) {
      try {
        localStorage.setItem(AUTH_USER_LOCAL_STORAGE, JSON.stringify(sessionUser));
      } catch (e) {
        console.log(e);
      }
    }
  }, [sessionUser]);

  const [signOutMutation] = useMutation(DESTROY_SESSION_MUTATION, {
    onCompleted: () => {
      toast({
        title: 'Signed out.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const signOut = useCallback(() => {
    const signOutPipeline = async () => {
      console.log('whatever');
      try {
        try {
          await tokenConfig.remove();
        } catch (e) {
          console.log(e);
        }
        try {
          localStorage.removeItem(AUTH_USER_LOCAL_STORAGE);
        } catch (e) {
          console.log(e);
        }
        try {
          await signOutMutation();
        } catch (e) {
          console.log(e);
        }
        try {
          await client.resetStore();
        } catch (e) {
          console.log(e);
        }
        await refetch();
      } catch (e) {
        Sentry.captureException(e);
        console.log(e);
      }
    };
    signOutPipeline();
  }, [signOutMutation]);

  const signIn = useCallback(
    async (newToken: string) => {
      await tokenConfig.set(newToken);
      const { data } = await refetch();
      if (data.me && typeof window !== 'undefined') {
        identifyUser(data.me);
        try {
          localStorage.setItem(AUTH_USER_LOCAL_STORAGE, JSON.stringify(data.me));
        } catch (e) {
          console.log(e);
        }
      }
      return data.me!;
    },
    [tokenConfig],
  );

  // This could be a partial, but let's pretend it isn't for performance.
  // We know that all the core fields will be there from the sessionUser.
  // @ts-ignore
  const user: SignedInUserFragment | null = useMemo(
    () => (sessionUser ? sessionUser : null),
    [sessionUser, data],
  );

  const preloadPaths = useMemo(
    () => ['/home', '/feed', '/search', '/profile', '/sign-in', '/sign-up'],
    [],
  );

  // usePrefetch(preloadPaths);
  const router = useRouter();

  const pathname = usePathname();
  const prevSessionUserUser = usePrevious(sessionUser);

  const checkRenavigation = useCallback(() => {
    if (
      !prevSessionUserUser &&
      user &&
      user?.signUpStep &&
      !pathname.includes('/auth') &&
      pathname !== '/sign-out' &&
      pathname !== '/sign-up/done' &&
      pathname !== '/verify-another-email' &&
      pathname !== '/settings/premium' &&
      pathname !== '/what-is-this' &&
      pathname !== '/mobile/add-recommendation' &&
      pathname !== '/settings/manage/email' &&
      !pathname.includes('/unsubscribe/') &&
      !pathname.includes('/sign-up/invite')
    ) {
      const targetPath = getPathForStep(user?.signUpStep);
      if (targetPath && pathname !== targetPath) {
        return router.navigate({ to: targetPath });
      }
    }
  }, [user?.signUpStep, pathname, prevSessionUserUser]);

  useEffect(() => {
    checkRenavigation();
  }, [user?.signUpStep, pathname, prevSessionUserUser]);

  const memoizedValue = useMemo(
    () => ({
      // This could be a partial, but let's pretend it isn't for performance.
      // We know that all the core fields will be there from the sessionUser.
      // @ts-ignore
      user,
      signIn,
      signOut,
      token: null,
      refetchUser: refetch,
      isFullyLoaded,
      // isInMaintenance: !!data?.isInMaintenance,
    }),
    [user, signIn, signOut, refetch, isFullyLoaded],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
