import { createFileRoute } from '@tanstack/react-router';
import HomeContainerNew from './home/-home-container-new';

import { GetHomeMainContentTopDocument } from 'shared/__generated__/graphql';
import { preloadImageByKey } from '../../lib/utils';
import { redirect } from '@tanstack/react-router';
import { getSessionUser } from '../actions/getSessionUser';

export const Route = createFileRoute('/')({
  component: HomeComponent,
  staleTime: 5 * 1000,
  loader: async ({ context }) => {
    try {
      const user = await getSessionUser().catch((e) => {
        console.log(e);
        return null;
      });

      console.log('user', user);

      if (!user) {
        throw redirect({ to: '/welcome' });
        return;
      }

      const result = await context.apolloClient.query({
        query: GetHomeMainContentTopDocument,
      });

      if (result.data?.homePageTop.articleOne) {
        if (result.data?.homePageTop.articleOne.document?.thumbnailFile?.key) {
          preloadImageByKey(result.data?.homePageTop.articleOne.document?.thumbnailFile.key);
        }
      }

      return { result };
    } catch (error) {
      console.error(error);
      return {};
    }
  },
});

function HomeComponent() {
  const { result } = Route.useLoaderData();

  return (
    <div className="p-2">
      <HomeContainerNew tab="top" prefetchedHomePageTopQueryData={result?.data} />
    </div>
  );
}
