import { startsWith } from 'lodash';
import emojiRegex from 'emoji-regex';

const regex = emojiRegex();

export function getEmojisAsArrayFromString(str) {
  const emojiMatch = str.matchAll(regex);
  const emojis = Array.from(emojiMatch);
  return emojis;
}

export function startsWithEmoji(str: string) {
  const s = str.trim();
  return s ? s.match(regex) : false;
}

export function removeEmojisFromString(str: string) {
  return str.replace(regex, '').trim();
}
