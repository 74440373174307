import { useMemo, useState, useEffect } from 'react';
import { chakra, ChakraComponent } from '@chakra-ui/react';
import StarSVG from 'shared/misc/components/star.svg?react';
import _ from 'lodash';

const StyledStar: ChakraComponent<'svg'> = chakra(StarSVG);

const STAR_SIZES = [50, 50, 50, 50, 35, 35, 35];

interface Star {
  size: number;
  top: number;
  left: number;
  rotate: number;
}

const useStars = (
  isWideScreen: boolean,
  twinkle: boolean = false,
  numStars: number = 9,
  aroundEdges: boolean = false,
) => {
  const [twinkleState, setTwinkleState] = useState<'left' | 'right'>('left');

  const stars = useMemo(() => {
    if (!isWideScreen || typeof window === 'undefined') {
      return [];
    }

    const rows = Math.ceil(Math.sqrt(numStars));
    const cols = Math.ceil(numStars / rows);
    const margin = 5;

    return _.times(numStars, (index) => {
      const size = _.sample(STAR_SIZES) as number;
      let top, left;

      if (aroundEdges) {
        const edge = Math.floor(Math.random() * 4);
        switch (edge) {
          case 0: // top edge
            top = Math.random() * margin;
            left = Math.random() * (100 - (size / window.innerWidth) * 100 - margin);
            break;
          case 1: // right edge
            top = Math.random() * (100 - (size / window.innerHeight) * 100 - margin);
            left = 100 - (size / window.innerWidth) * 100 - margin;
            break;
          case 2: // bottom edge
            top = 100 - (size / window.innerHeight) * 100 - margin;
            left = Math.random() * (100 - (size / window.innerWidth) * 100 - margin);
            break;
          case 3: // left edge
            top = Math.random() * (100 - (size / window.innerHeight) * 100 - margin);
            left = Math.random() * margin;
            break;
        }
      } else {
        const row = Math.floor(index / cols);
        const col = index % cols;
        top =
          (row / rows) * 100 +
          Math.random() * (100 / rows - (size / window.innerHeight) * 100 - margin);
        left =
          (col / cols) * 100 +
          Math.random() * (100 / cols - (size / window.innerWidth) * 100 - margin);
      }

      top = Math.max(margin, Math.min(top, 100 - (size / window.innerHeight) * 100 - margin));
      left = Math.max(margin, Math.min(left, 100 - (size / window.innerWidth) * 100 - margin));

      return { size, top, left, rotate: Math.floor(Math.random() * 360) };
    });
  }, [isWideScreen, aroundEdges]);

  useEffect(() => {
    if (twinkle) {
      const interval = setInterval(() => {
        setTwinkleState((v) => (v === 'left' ? 'right' : 'left'));
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [twinkle]);

  const twinkledStars = useMemo(
    () =>
      stars.map((star) => ({
        ...star,
        rotate: star.rotate + (twinkleState === 'left' ? -10 : 10),
      })),
    [stars, twinkleState],
  );

  return (twinkle ? twinkledStars : stars).map((star, index) => (
    <StyledStar
      key={index}
      fill="brand.ronald"
      pointerEvents="none"
      w={star.size}
      h={star.size}
      style={{
        position: 'absolute',
        top: `${star.top}%`,
        left: `${star.left}%`,
        transform: `rotate(${star.rotate}deg)`,
      }}
    />
  ));
};

export default useStars;
